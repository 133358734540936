import React from "react"

import { useSession, useModals } from "@app/contexts"
import { PageContainer, Title, TagScanner } from "@app/components"

export const ScanPage: React.FC = () => {
	const { t } = useSession()
	const { setModal, dismissModal } = useModals()

	React.useEffect(() => {
		setModal(
			"TagScanner",
			<TagScanner
				onClose={() => {
					dismissModal("TagScanner")
				}}
			/>,
		)
	}, [])

	return (
		<PageContainer>
			<Title>{t("scan.title")}</Title>
		</PageContainer>
	)
}
