import _ from "lodash"

import { rpc, Batch, Cache, compactIDs } from "@app/api"
import {
	Entity,
	Assembly,
	AssemblyType,
	AssemblyPendingFileType,
	newAssembly,
	Pagination,
} from "@app/domain"

import type { Result } from "@app/api"
import type { GridRequest, CageRequest } from "@app/grpc/visualization"

export type SaveAssemblyParams = {
	id?: string
	jobID?: string

	assemblyType: AssemblyType
	metric: boolean
	weight?: number
	name: string
	configuratorParams?: {
		"W1-Cage"?: CageRequest
		"W2-Grid"?: GridRequest
	}
	configuratorVersion?: string
	stpMask?: string
	stpMaskFileName?: string
}

export const newSaveAssemblyParams = (assembly?: Assembly): SaveAssemblyParams => {
	return {
		..._.omit(
			_.assign(newAssembly(), _.cloneDeep(assembly)),
			"created",
			"customerID",
			"referenceID",
			"updated",
		),
	}
}

export type SaveAssemblyResult = {
	assemblies: Assembly[]
}

export const SaveAssembly = async (
	params: SaveAssemblyParams,
): Promise<Result<SaveAssemblyResult>> => {
	const data = await rpc("SaveAssembly", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveAssemblyResult>
}

export type GetAssembliesParams = {
	ids?: string[]
	includeIDs?: string[]

	customerID?: string
	jobID?: string
	releaseID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetAssembliesResult = {
	assemblies: Assembly[]
	pagination: Pagination
}

export const GetAssemblies = async (
	params: GetAssembliesParams,
): Promise<Result<GetAssembliesResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetAssemblies", "assemblies", id).then((assembly?: Entity) => {
			return {
				ok: true,
				result: { assemblies: _.compact([assembly as Assembly]) },
			} as Result<GetAssembliesResult>
		}) as Promise<Result<GetAssembliesResult>>
	}
	const data = await rpc("GetAssemblies", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetAssembliesResult>
}

export type GetAssemblyPendingFilesResult = {
	assemblyPendingFiles: {
		pendingFiles: { [key in AssemblyPendingFileType]?: boolean }
	}
}

export const GetAssemblyPendingFiles = async (
	assembly: Assembly | undefined,
): Promise<Result<GetAssemblyPendingFilesResult>> => {
	if (!assembly) {
		return {
			ok: false,
			result: { assemblyPendingFiles: { pendingFiles: {} } },
		} as Result<GetAssemblyPendingFilesResult>
	}
	const data = await rpc("GetAssemblyPendingFiles", { id: assembly.id })
	// Cache update work around
	// @ts-expect-error
	data.result.assemblies = [
		{
			...assembly,
			pendingFiles: (data as Result<GetAssemblyPendingFilesResult>)?.result?.assemblyPendingFiles
				?.pendingFiles,
		},
	]
	Cache.scan(data)
	return data as Result<GetAssemblyPendingFilesResult>
}

export type ExportAssemblyParams = {
	id: string
}
export type ExportAssemblyResult = {
	bytes: string
}

export const ExportAssembly = async (
	params: ExportAssemblyParams,
): Promise<Result<ExportAssemblyResult>> => {
	return (await rpc("ExportAssembly", params, { minDuration: 300 })) as Result<ExportAssemblyResult>
}

export type ImportAssemblyParams = {
	base64: string
}
export type ImportAssemblyResult = {
	assemblies: Assembly[]
}

export const ImportAssembly = async (
	params: ImportAssemblyParams,
): Promise<Result<ImportAssemblyResult>> => {
	return (await rpc("ImportAssembly", params, { minDuration: 300 })) as Result<ImportAssemblyResult>
}
