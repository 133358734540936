import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Icon, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { Material, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IMaterialHeaderProps {
	material?: Material
	view: string
	section?: string
}

export const MaterialHeader: React.FC<IMaterialHeaderProps> = (props) => {
	const { material, view, section } = props
	const { id, controlCode, created, updated } = _.assign(
		{ id: "", controlCode: "...", created: "", updated: "" },
		material,
	)

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Material, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo(`materials${_.isEmpty(section) ? "" : `/${section}`}`, id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("materials", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("materials/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.edit"),
			to: urlTo(`materials/edit${_.isEmpty(section) ? "" : `/${section}`}`, id),
			active: view === "edit",
		},

		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("material.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { controlCode }),
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("material.titles.new")
	} else if (view === "edit") {
		title = t("material.titles.edit", { controlCode })
	} else {
		title = t("material.titles.show", { controlCode })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs
				entity={material}
				entityType={EntityType.Material}
				view={view}
				section={section}
			/>

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{controlCode}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
