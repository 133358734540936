import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { Customer, EntityType } from "@app/domain"
import { GetCustomers } from "@app/api"
import { useRelation } from "@app/hooks"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface ICustomerSelectProps {
	autoFocus?: boolean
	defaultValue?: string
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	onChange: (v: OptionType[]) => void
}

export const CustomerSelect: React.FC<ICustomerSelectProps> = (props) => {
	const { handleError } = useSession()
	const { autoFocus, defaultValue = "", onChange, disabled, name, label, hint, error } = props

	const [customerID, setCustomerID] = React.useState<string>(defaultValue)
	const [customer, loadingCustomer] = useRelation<Customer>(EntityType.Customer, defaultValue)

	const getCustomers = (inputValue: string) => {
		const includeIDs = _.filter([customerID], (v) => !_.isEmpty(v)) as string[]
		return new Promise<OptionType[]>((resolve) => {
			GetCustomers({
				includeIDs,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const customers = _.get(resp, "result.customers", []).map((c: Customer) => {
							return {
								label: c.name,
								value: c.id,
							}
						})
						resolve(customers as OptionType[])
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	const defaultOption = {
		label: customer?.name ?? defaultValue,
		value: customerID,
	}

	if (loadingCustomer) {
		return null
	}

	return (
		<AsyncSelect
			autoFocus={autoFocus}
			cacheOptions={true}
			defaultOptions={true}
			defaultValue={_.isEmpty(defaultValue) ? null : defaultOption}
			disabled={disabled}
			hint={hint}
			error={error}
			isSearchable={true}
			label={label}
			loadOptions={getCustomers}
			menuPlacement="auto"
			name={name}
			onChange={(v) => {
				setCustomerID(_.get(v, "value", ""))
				onChange(_.compact([v]))
			}}
		/>
	)
}
