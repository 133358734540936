import React from "react"
import _ from "lodash"
import clsx from "clsx"
import { useLocation } from "react-router-dom"
import { Transition } from "@headlessui/react"
import { Helmet } from "react-helmet-async"

import { Icon, Link, Notifications, SearchBar, Logo } from "@app/components"
import { useSession } from "@app/contexts"
import { urlTo } from "@app/util"

interface IAuthedLayoutProps {
	children: React.ReactNode
}

type MenuItem = {
	key: string
	icon?: string
	href?: string
	children?: MenuItem[]
}

const menuItems: MenuItem[] = [
	{ icon: "Building", key: "customers", href: "/customers" },
	{ icon: "Jobs", key: "jobs", href: "/jobs" },
	{
		icon: "Assemblies",
		key: "assemblies",
		href: "/assemblies",
	},
	{ icon: "Releases", key: "releases", href: "/releases" },
	{ icon: "Shipments", key: "shipments", href: "/shipments" },
	{ icon: "Tag", key: "tags", href: "/tags" },
	{ icon: "Document", key: "documents", href: "/documents" },
	{ icon: "Materials", key: "materials", href: "/materials" },
	{ icon: "Contacts", key: "contacts", href: "/contacts" },
	{ icon: "Hammer", key: "workActions", href: "/work-actions" },
	{ icon: "QRCode", key: "trackingSheets", href: "/tracking-sheets/new" },
	{ icon: "IDBadge", key: "workerBadge", href: "/worker-badge/new" },
	{
		icon: "WorkFacilities",
		key: "workFacilities",
		href: "/work-facilities",
	},
	{ icon: "Workers", key: "workers", href: "/workers" },
	{ icon: "Users", key: "users", href: "/users" },
	{ icon: "Tracking", key: "reports", href: "/reports" },
	{
		icon: "Sliders",
		key: "settings",
		href: "/settings",
	},
	{ icon: "Trash", key: "trash", href: "/trash" },
	{ icon: "SignOut", key: "logout", href: "/logout" },
]

export const AuthedLayout: React.FC<IAuthedLayoutProps> = (props) => {
	const { t, user, offline, oldBuild } = useSession()
	const { pathname } = useLocation()
	const { children } = props

	const [open, setOpen] = React.useState<boolean>(false)
	const [blink, setBlink] = React.useState<boolean>(false)
	const [hover, setHover] = React.useState<boolean>(false)

	React.useEffect(() => {
		let id: number
		if (hover) {
			id = _.delay(() => {
				setOpen(true)
			}, 2000)
		} else {
			id = _.delay(() => {
				setOpen(false)
			}, 150)
		}
		return () => {
			clearTimeout(id)
		}
	}, [hover])

	React.useEffect(() => {
		let id: number | undefined
		if (offline || oldBuild) {
			id = window.setInterval(() => {
				setBlink((blink) => !blink)
			}, 500)
		} else {
			window.clearInterval(id)
		}
		return () => {
			window.clearInterval(id)
		}
	}, [offline, oldBuild])

	const fullName = `${user?.firstName || ""} ${user?.lastName || ""}`

	const toggleSidebar = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		setOpen(!open)
	}

	const createMenuItem = (
		item: MenuItem,
		i: number,
		mobile: boolean,
		open: boolean,
	): React.ReactNode => {
		const [expanded, setExpanded] = React.useState<boolean>(true)
		const transitionProps = {
			show: expanded,
			enter: "transition-all ease-in-out duration-150",
			enterFrom: "max-h-0 overflow-hidden",
			enterTo: "max-h-96 overflow-hidden",
			leave: "transition-all ease-in-out duration-150 delay-150",
			leaveFrom: "max-h-96 overflow-hidden",
			leaveTo: "max-h-0 overflow-hidden",
		}

		const content = (
			<>
				<span className="mr-5 w-7 inline-block text-center">
					<Icon name={item.icon || ""} size="lg" fixedWidth />
				</span>
				{t(`sidebar.${item.key}`)}
				{item.children && (
					<span
						className="float-right w-7 inline-block text-center transition-all"
						onClick={(event) => {
							if (mobile) {
								event.stopPropagation()
								event.preventDefault()
								setExpanded((e) => !e)
							}
						}}
					>
						<Icon name="ChevronDown" size="sm" fixedWidth />
					</span>
				)}
			</>
		)

		const itemPath = item.href

		const liClasses = clsx(
			`cursor-pointer block pl-2 pr-2 py-1.5 hover:text-black`,
			_.startsWith(pathname, itemPath) ? "bg-white text-black" : "hover:bg-yellow-500",
		)
		return (
			<li
				key={item.key + i}
				onMouseEnter={() => {
					if (!mobile) {
						setExpanded(true)
					}
				}}
				onMouseLeave={() => {
					if (!mobile) {
						setExpanded(false)
					}
				}}
			>
				{item.href ? (
					<Link
						to={item.href}
						className={liClasses}
						onClick={() => {
							setOpen(false)
						}}
					>
						{content}
					</Link>
				) : (
					<div className={liClasses}>{content}</div>
				)}
				{!item.children ? null : (
					<Transition {...transitionProps}>
						<ul className="overflow-y-hidden text-sm leading-4 divide-y divide-gray-800">
							{item.children.map((item, i) => createMenuItem(item, i, mobile, open))}
						</ul>
					</Transition>
				)}
			</li>
		)
	}

	const sidebarMobile = (
		<Transition show={open}>
			<div id="sidebar-mobile" className="lg:hidden">
				<div className="fixed inset-0 flex z-40">
					<div className="fixed inset-0">
						<div className="absolute inset-0 bg-black opacity-75"></div>
					</div>
					<Transition.Child
						enter="transition ease-in-out duration-150 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<div className="relative flex flex-col w-56 bg-black min-h-[100vh]">
							<div className="absolute top-0 right-0 -mr-14 p-1 text-white mt-1">
								<button
									className="flex items-center justify-center h-12 w-11 rounded-full focus:outline-none focus:bg-gray-700"
									aria-label="Close sidebar"
									onClick={toggleSidebar}
								>
									<Icon name="Times" size="lg" fixedWidth />
								</button>
							</div>
							<div className="flex-1 h-0 pt-0 overflow-y-auto text-white">
								<nav className="mt-3 space-y-1 text-sm border-b border-t border-gray-800">
									<ul className="divide-gray-800 divide-y">
										{menuItems.map((item, i) => createMenuItem(item, i, true, open))}
									</ul>
								</nav>
							</div>
							<div className="flex-shrink-0 flex bg-gray-800 px-5 py-4">
								<Link
									to={urlTo("account/edit")}
									onClick={() => {
										setOpen(false)
									}}
									className="flex-shrink-0 w-full group block"
								>
									<div className="flex items-center">
										<div className="inline-block text-white">
											<Icon name="UserCircle" size="lg" />
										</div>
										<div className="ml-5">
											<p className="text-sm leading-5 font-medium text-white">{fullName}</p>
										</div>
									</div>
								</Link>
							</div>
						</div>
					</Transition.Child>
					<div className="flex-shrink-0 w-14" />
				</div>
			</div>
		</Transition>
	)

	const sidebarDesktop = (
		<div
			id="sidebar-desktop"
			className="hidden lg:flex lg:flex-shrink-0 fixed h-screen top-0	z-50"
			onMouseEnter={() => {
				setHover(true)
			}}
			onMouseLeave={() => {
				setHover(false)
			}}
		>
			<div
				className={clsx(
					"flex flex-col absolute left-0 h-full z-30 group transition-all ",
					open ? "w-52 shadow-xl overflow-y-scroll" : "w-11 overflow-x-hidden overflow-y-hidden",
				)}
			>
				<div className="flex flex-col h-0 flex-1 bg-black">
					<div className="flex-1 flex flex-col pt-3">
						<nav className="w-52 flex-1 bg-black text-white space-y-1 text-sm">
							<ul className="divide-y divide-gray-800 border-b border-gray-800">
								<li className="block pl-3 pr-2 pt-2.5 mb-7 h-6">
									<Link to={"/"}>
										<span className={clsx(open ? "opacity-0" : "opacity-100", "transition")}>
											<Icon name="Toggle" size="lg" />
										</span>
										<Logo className="inline-block ml-4" width={100} />
									</Link>
								</li>
								{menuItems.map((item, i) => createMenuItem(item, i, false, open))}
							</ul>
						</nav>
						<div className="flex-shrink-0 flex bg-gray-800 py-4 w-52">
							<Link
								to={urlTo("account/edit")}
								onClick={() => {
									setOpen(false)
								}}
								className="flex-shrink-0 w-full group block"
							>
								<div className="flex items-center">
									<div className="ml-3 inline-block text-white">
										<Icon name="UserCircle" size="lg" />
									</div>
									<div className="ml-4">
										<p className="text-sm leading-5 font-medium text-white">{fullName}</p>
									</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)

	return (
		<>
			<Helmet>
				<meta name="viewport" content="width=device-width,initial-scale=1" />
			</Helmet>
			<Transition
				className="inline"
				show={offline || oldBuild}
				enter="transition-opacity ease-linear duration-150"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity ease-linear duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div className="z-40 sticky top-0 w-full text-center bg-red-700 font-bold text-white text-xs py-1">
					<Icon
						className={`mr-2 ${blink ? "text-red-300" : "text-red-200"}`}
						name={offline ? "Wifi" : "Sync"}
					/>
					{offline ? t("common.errors.pingFailed") : t("common.errors.oldBuild")}
				</div>
			</Transition>
			<div id="authedLayout" className="flex bg-white">
				{sidebarMobile}
				{sidebarDesktop}
				<div className="flex flex-col w-0 flex-1 ml-0 lg:ml-11">
					<div className="z-10 flex-shrink-0 flex items-center h-16 bg-white shadow top-0 w-screen lg:w-full-sidebar">
						<button
							className="px-4 focus:outline-none lg:hidden"
							aria-label="Open sidebar"
							onClick={toggleSidebar}
						>
							<Icon name="Bars" size="lg" fixedWidth />
						</button>
						<SearchBar />
					</div>
					<main className="flex-1 relative z-0 focus:outline-none" tabIndex={0}>
						{children}
					</main>
				</div>
				<Notifications />
			</div>
		</>
	)
}
