import React from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"

import { useSession } from "@app/contexts"
import { AlertLevel, AssemblyType, Assembly, GetFileNameFromURL } from "@app/domain"
import { AssemblyForm } from "@app/forms"
import { Await, Title } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useRedirect } from "@app/hooks"
import { AssemblyHeader } from "@app/headers"
import { GetAssemblies, SaveAssembly, SaveAssemblyParams, newSaveAssemblyParams } from "@app/api"

const quantityAtSpacingConversion = (assembly: Assembly) => {
	const convertRebarParams = (spacings: number[], quantities: number[]) => {
		// Merge the two arrays into a single array of quantity@spacing pairs.
		const len = Math.min(spacings.length, quantities.length)
		const res = []
		for (let i = 0; i < len; i++) {
			if (i === len && quantities[i] === 0) {
				break
			}
			res.push(String(quantities[i] === 0 ? 1 : quantities[i] - 1) + "@" + String(spacings[i]))
		}
		return res
	}
	const removeRebarParams = (key: string) => {
		if (assembly?.configuratorParams?.["W2-Grid"]?.[key]) {
			delete assembly.configuratorParams["W2-Grid"][key]
		} else if (assembly?.configuratorParams?.["W1-Cage"]?.[key]) {
			delete assembly.configuratorParams["W1-Cage"][key]
		}
	}
	if (
		assembly.configuratorParams?.["W2-Grid"] &&
		!["rebar_a_quantity_at_spacing", "rebar_b_quantity_at_spacing"].every(
			(key) => assembly.configuratorParams?.["W2-Grid"]?.[key],
		)
	) {
		assembly.configuratorParams["W2-Grid"].rebar_a_quantity_at_spacing = convertRebarParams(
			assembly.configuratorParams?.["W2-Grid"]?.rebar_a_spacing ?? [],
			assembly.configuratorParams?.["W2-Grid"]?.rebar_a_quantity ?? [],
		)
		removeRebarParams("rebar_a_spacing")
		removeRebarParams("rebar_a_quantity")
		assembly.configuratorParams["W2-Grid"].rebar_b_quantity_at_spacing = convertRebarParams(
			assembly.configuratorParams?.["W2-Grid"]?.rebar_b_spacing ?? [],
			assembly.configuratorParams?.["W2-Grid"]?.rebar_b_quantity ?? [],
		)
		removeRebarParams("rebar_b_spacing")
		removeRebarParams("rebar_b_quantity")
	}
	if (
		assembly.configuratorParams?.["W1-Cage"] &&
		!assembly.configuratorParams["W1-Cage"].stirrup_quantity_at_spacing
	) {
		assembly.configuratorParams["W1-Cage"].stirrup_quantity_at_spacing = convertRebarParams(
			assembly.configuratorParams?.["W1-Cage"]?.stirrup_spacing ?? [],
			assembly.configuratorParams?.["W1-Cage"]?.stirrup_quantity ?? [],
		)
		removeRebarParams("stirrup_spacing")
		removeRebarParams("stirrup_quantity")
	}
}

export const AssemblyEditPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const params = useParams<{ id: string }>()

	const [assembly, setAssembly] = React.useState<Assembly | undefined>(undefined)
	const { id, name } = _.assign({ id: "", name: "...", referenceID: "" }, assembly)
	const [stpFileLoading, setSTPFileLoading] = React.useState<boolean>(true)
	const [stpFileMaskName, setSTPFileMaskName] = React.useState<string>("")
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	React.useEffect(() => {
		const getAssembly = async () => {
			const resp = await GetAssemblies({ ids: [toUUID(params.id)] })
			if (!resp.ok) {
				handleError(resp.errors)
				return
			}
			setAssembly(resp.result.assemblies[0])
		}
		getAssembly()
	}, [params])

	React.useEffect(() => {
		if (!assembly) {
			return
		}
		const loadSTPFileMask = async () => {
			if (assembly.assemblyType !== AssemblyType.W2Grid || !assembly.stpFileURL) {
				setSTPFileLoading(false)
				return
			}
			setSTPFileMaskName(decodeURIComponent(GetFileNameFromURL(assembly.stpFileURL)))
			let tries = 0
			let fileResp
			while (tries < 3) {
				fileResp = await fetch(assembly?.stpFileURL)
				if (fileResp.ok && fileResp.body) {
					break
				}
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: "Error loading STP mask file.",
					subtitle: "Trying again...",
				})
				tries += 1
				await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait 1s.
			}
			const bodyReader = fileResp?.body?.getReader()
			const chunks = []
			let length = 0
			let done = false
			while (!done) {
				const chunk = await bodyReader?.read()
				done = chunk?.done ?? true
				chunk?.value !== undefined && chunks.push(chunk.value)
				length += chunk?.value?.length ?? 0
			}
			if (chunks.length > 0 && assembly?.configuratorParams?.["W2-Grid"]) {
				const stp = new Uint8Array(length)
				let offset = 0
				chunks.forEach((chunk) => {
					stp.set(chunk, offset)
					offset += chunk.length
				})
				assembly.configuratorParams["W2-Grid"].mask_stp = stp ?? new Uint8Array()
			}
			setSTPFileLoading(false)
		}
		loadSTPFileMask()
		quantityAtSpacingConversion(assembly)
	}, [assembly])

	const saveHandler = async (params: SaveAssemblyParams, stpMaskFileName?: string) => {
		setBusy(true)
		try {
			const stpFileMask = params?.configuratorParams?.["W2-Grid"]?.mask_stp
			if (params?.configuratorParams?.["W2-Grid"]) {
				// @ts-expect-error -- All fields are optional in protobuf v3.
				delete params.configuratorParams["W2-Grid"].mask_stp
			}
			if (stpFileMask && stpFileMask.length > 0) {
				params.stpMask = btoa(new TextDecoder("utf8").decode(stpFileMask))
				params.stpMaskFileName = stpMaskFileName
			}
			const assemblyResp = await SaveAssembly({ ...params, id: toUUID(id) })
			if (!assemblyResp.ok) {
				setErrors(assemblyResp.errors)
			} else {
				const saved = assemblyResp.result.assemblies[0]
				setRedirect(urlTo("assemblies", saved), {
					alertLevel: AlertLevel.Success,
					title: t("assembly.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<>
			<div className="pt-6 px-6">
				<AssemblyHeader assembly={assembly} view="edit" />
			</div>
			<Title>{t("assembly.titles.edit", { name })}</Title>
			<Await
				loading={!assembly || stpFileLoading}
				then={() => (
					<AssemblyForm
						assemblyParams={newSaveAssemblyParams(assembly)}
						stpFileMaskName={stpFileMaskName}
						errors={errors}
						loading={busy}
						onSave={saveHandler}
						assemblyType={_.get(assembly, "assemblyType", AssemblyType.W1Cage)}
						maxHeight="calc(100vh - 14.75rem)"
					/>
				)}
			/>
		</>
	)
}
