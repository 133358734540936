import React from "react"
import _ from "lodash"
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	rectSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable"
import clsx from "clsx"

import { Await, Button, ButtonIcon, FormActions, Icon, Input, Link } from "@app/components"
import { TagSearchForm, AttributesForm } from "@app/forms"
import { urlTo } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"
import { AttributeParams, SaveShipmentItems, SaveShipmentItemsParams } from "@app/api"
import {
	AlertLevel,
	Attribute,
	EntityType,
	groupShipmentItems,
	newShipmentItem,
	newShipmentItemGroup,
	ScanEvent,
	Shipment,
	ShipmentItem,
	ShipmentItemGroup,
	Tag,
} from "@app/domain"

interface IShipmentItemsFormProps {
	shipment?: Shipment
}

const unitValid = (unit: number) => unit !== undefined && unit !== null && unit > 0
const groupUnitsValid = (shipmentItemGroup: ShipmentItemGroup) =>
	unitValid(shipmentItemGroup.unitEnd) &&
	unitValid(shipmentItemGroup.unitStart) &&
	shipmentItemGroup.unitEnd >= shipmentItemGroup.unitStart

export const ShipmentItemsForm: React.FC<IShipmentItemsFormProps> = (props) => {
	const { t, handleError, addNotification } = useSession()
	const { shipment } = props

	const shipmentID = _.get(shipment, "id", "")
	const jobID = _.get(shipment, "jobID", "")

	const { redirect, setRedirect } = useRedirect()
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [removeIDs, setRemoveIDs] = React.useState<string[]>([])
	const [saving, setSaving] = React.useState<boolean>(false)
	const [shipmentItems, shipmentablesLoading] = useEntity<ShipmentItem[]>(EntityType.ShipmentItem)
	const [shipmentItemGroups, setShipmentItemGroups] = React.useState<ShipmentItemGroup[]>([])
	React.useEffect(() => {
		if (!shipmentItems) {
			return
		}
		setShipmentItemGroups(groupShipmentItems(shipmentItems))
	}, [shipmentItems])

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over && active.id !== over.id && shipmentItemGroups) {
			const oldIndex = shipmentItemGroups.findIndex((shipmentItem) => shipmentItem.id === active.id)
			const newIndex = shipmentItemGroups.findIndex((shipmentItem) => shipmentItem.id === over.id)
			swap(oldIndex, newIndex)
		}
	}

	const swap = (oldIndex: number, newIndex: number) => {
		setShipmentItemGroups((shipmentItemGroups) => {
			return arrayMove(
				shipmentItemGroups?.map((r, i) => ({
					...r,
					sortOrder: i === oldIndex ? newIndex : i === newIndex ? oldIndex : r.sortOrder,
				})) ?? [],
				oldIndex,
				newIndex,
			)
		})
	}

	React.useEffect(() => {
		if (_.size(shipmentItemGroups) === 0) {
			setShipmentItemGroups([newShipmentItemGroup()])
		}
	}, [shipmentItemGroups])

	if (redirect) {
		return redirect()
	}

	const saveHandler = async (params: SaveShipmentItemsParams) => {
		setSaving(true)
		setErrors({})
		try {
			const resp = await SaveShipmentItems({ ...params, shipmentID })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const next = _.get(resp, "result.shipmentItems", [])
				addNotification({
					alertLevel: AlertLevel.Success,
					title: t("shipmentItem.notifications.success.updated", {
						smart_count: _.size(next),
					}),
				})
				setRedirect(urlTo("shipment/items", shipmentID))
			}
		} catch (err) {
			handleError(err)
		}
		setSaving(false)
	}

	const addHandler = (r: ShipmentItemGroup) => {
		return () => {
			setShipmentItemGroups((prev) => {
				const next: ShipmentItemGroup[] = []
				_.each(prev, (item) => {
					next.push(item)
					if (item.id === r.id) {
						next.push(newShipmentItemGroup())
					}
				})
				return next
			})
		}
	}

	const removeHandler = (r: ShipmentItemGroup) => {
		return () => {
			setRemoveIDs((prev) => {
				return [...prev].concat(r.itemIDMap ? Object.values(r.itemIDMap) : [])
			})
			setShipmentItemGroups((prev) => {
				const next: ShipmentItemGroup[] = []
				_.each(prev, (item) => {
					if (item.id !== r.id) {
						next.push(item)
					}
				})
				if (_.isEmpty(next)) {
					next.push(newShipmentItemGroup())
				}
				return next
			})
		}
	}

	const attributesHandler = (r: ShipmentItemGroup): ((attrs: AttributeParams[]) => void) => {
		return (attrs) => {
			setShipmentItemGroups((prev) => {
				const next: ShipmentItemGroup[] = []
				_.each(prev, (item) => {
					if (item.id === r.id) {
						next.push(_.set(item, "attributes", attrs))
					} else {
						next.push(item)
					}
				})
				return next
			})
		}
	}

	const inputHandler = (
		r: ShipmentItemGroup,
		key: string,
		ref?: React.RefObject<HTMLInputElement>,
	): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			let value: string | number | null | undefined = target.value || ""
			if (key === "item") {
				const num = parseInt(value.replace(/[^0-9]/g, ""), 10)
				value = isNaN(num) ? null : num
				if (ref && ref.current) {
					ref.current.value = value ? value.toString() : ""
				}
			}
			setShipmentItemGroups((prev) => {
				const next: ShipmentItemGroup[] = []
				_.each(prev, (item) => {
					if (item.id === r.id) {
						next.push(_.set(item, key, value))
					} else {
						next.push(item)
					}
				})
				return next
			})
		}
	}

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		if (shipmentItemGroups.some((g) => !groupUnitsValid(g))) {
			const g = shipmentItemGroups.find((g) => !groupUnitsValid(g))
			addNotification({
				alertLevel: AlertLevel.Warn,
				subtitle: "Must satisfy 1 <= start <= end",
				title: `Invalid unit range for ${g?.description}`,
			})
			return true
		}
		const params = {
			shipmentID,
			shipmentItems: shipmentItemGroups.flatMap((g) =>
				[...Array(g.unitEnd - g.unitStart + 1).keys()]
					.filter((item) => !g?.itemIDMap?.[item + 1])
					.map((item) => {
						item += 1
						return {
							...newShipmentItem(),
							assemblyID: g.assemblyID,
							customerID: g.customerID,
							jobID,
							releaseID: g.releaseID,
							shipmentID,
							releaseItemID: g.releaseItemID,
							tagID: g.tagID,
							attributes: g.attributes.map((a: Attribute) => ({
								name: a.name,
								value: a.value + (a.name === "tag-id" ? `-${item}` : ""),
							})),
							description: g.description,
							item,
						}
					}),
			),
			removeShipmentItems: removeIDs.concat(
				shipmentItemGroups.flatMap((g) =>
					g.itemIDMap
						? Object.entries(g.itemIDMap)
								.filter(([k]) => Number(k) > g.unitEnd || Number(k) < g.unitStart)
								.map(([, v]) => v)
						: [],
				),
			),
		}
		saveHandler(params)
		return false
	}

	const scanEntityMap: { [key: string]: string } = {}
	scanEntityMap[EntityType.Shipment] = shipmentID
	scanEntityMap[EntityType.Job] = jobID

	return (
		<Await
			loading={shipmentablesLoading}
			then={() => (
				<form onSubmit={submitHandler} noValidate>
					<div className="flex flex-col md:flex-row md:gap-x-4">
						<div className="w-full md:w-2/3">
							<div className="divide-y divide-gray-300 border-t border-b border-gray-300">
								<div className="flex flex-wrap gap-x-2 md:gap-x-4 px-4 pt-2 pb-2 text-gray-700">
									<div className="hidden md:block flex-none w-4 text-right"></div>
									<div className="flex-none w-4 text-right"></div>
									<div className="flex-grow text-left">{t("shipmentItem.labels.description")}</div>
									<div className="flex-none w-16 md:w-20 text-left">
										{t("shipmentItem.labels.unitStart")}
									</div>
									<div className="flex-none w-16 md:w-20 text-left">
										{t("shipmentItem.labels.unitEnd")}
									</div>
									<div className="flex-none w-16 md:w-20 text-left">
										{t("shipmentItem.labels.unitTotal")}
									</div>
									<div className="hidden md:block flex-none w-16 py-3 text-right"></div>
								</div>
								<DndContext
									sensors={sensors}
									collisionDetection={closestCenter}
									onDragEnd={handleDragEnd}
								>
									<SortableContext items={shipmentItemGroups ?? []} strategy={rectSortingStrategy}>
										{_.map(shipmentItemGroups, (r, i) => (
											<ShipmentItemGroupForm
												addHandler={addHandler}
												attributesHandler={attributesHandler}
												errors={errors}
												key={r.id}
												i={i}
												inputHandler={inputHandler}
												last={i === (shipmentItemGroups?.length ?? 1) - 1}
												onMoveUp={() => swap(i, i - 1)}
												onMoveDown={() => swap(i, i + 1)}
												removeHandler={removeHandler}
												saving={saving}
												shipmentItemGroup={r}
											/>
										))}
									</SortableContext>
								</DndContext>
							</div>
						</div>
						<div className="w-full md:w-1/3">
							<TagSearchForm
								jobID={jobID}
								busy={saving}
								scanEntityMap={scanEntityMap}
								scanEvent={ScanEvent.Load}
								addTag={(tag: Tag, item?: number) => {
									setShipmentItemGroups((prev) => {
										if (item && _.find(prev, { tagID: tag.id, item })) {
											addNotification({
												alertLevel: AlertLevel.Warn,
												title: t("shipmentItem.notifications.warning.duplicate", {
													referenceID: `${tag.referenceID}-${item}`,
												}),
											})
											return prev
										}
										const shipmentItemGroup = newShipmentItemGroup()
										shipmentItemGroup.tagID = tag.id
										shipmentItemGroup.description = tag.description
										shipmentItemGroup.attributes = [{ name: "tag-id", value: tag.referenceID }]
										if (prev) {
											const next = _.reduce(
												prev,
												(acc: ShipmentItemGroup[], v: ShipmentItemGroup) => {
													if (!_.isEmpty(v.description)) {
														acc.push(v)
													}
													return acc
												},
												[],
											)
											return next.concat([shipmentItemGroup])
										} else {
											return [shipmentItemGroup]
										}
									})
								}}
							/>
						</div>
					</div>
					<FormActions>
						<Button type="submit" label={"Save shipment items"} busy={saving} />
					</FormActions>
				</form>
			)}
		/>
	)
}

interface IShipmentItemGroupFormProps {
	addHandler: (r: ShipmentItemGroup) => () => void
	attributesHandler: (r: ShipmentItemGroup) => (attrs: AttributeParams[]) => void
	errors: { [key: string]: string }
	i: number
	inputHandler: (
		r: ShipmentItemGroup,
		key: string,
		ref?: React.RefObject<HTMLInputElement>,
	) => (e: React.FormEvent<HTMLInputElement>) => void
	last: boolean
	onMoveUp: () => void
	onMoveDown: () => void
	removeHandler: (r: ShipmentItemGroup) => () => void
	saving: boolean
	shipmentItemGroup: ShipmentItemGroup
}

export const ShipmentItemGroupForm: React.FC<IShipmentItemGroupFormProps> = (props) => {
	const itemRef = React.createRef<HTMLInputElement>()
	const {
		addHandler,
		attributesHandler,
		errors,
		i,
		inputHandler,
		last,
		onMoveUp,
		onMoveDown,
		removeHandler,
		saving,
		shipmentItemGroup,
	} = props
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: shipmentItemGroup.id,
	})

	const unitTotal = groupUnitsValid(shipmentItemGroup)
		? shipmentItemGroup.unitEnd - shipmentItemGroup.unitStart + 1
		: 0
	return (
		<div
			className={clsx("bg-white", isDragging ? "relative z-10 shadow-lg" : "z-0")}
			ref={setNodeRef}
			style={{
				transform: !transform ? undefined : `translateY(${Math.ceil(transform.y)}px)`,
				transition,
			}}
		>
			<div className="flex flex-row gap-x-2 md:gap-x-4 pt-4 px-4">
				<div className="flex flex-col gap-1 place-items-center w-4 pt-3 text-right">
					<Icon
						name="GripLines"
						className="text-gray-500 cursor-move focus:outline-none"
						{...listeners}
						{...attributes}
					/>
					{i !== 0 && (
						<Icon
							onClick={() => onMoveUp()}
							name="ChevronUp"
							className="mt-1 text-gray-500 cursor-pointer"
						/>
					)}
					{!last && (
						<Icon
							onClick={() => onMoveDown()}
							name="ChevronDown"
							className="mt-1 text-gray-500 cursor-pointer"
						/>
					)}
				</div>
				<div className="flex-none w-4 pt-4 text-right text-xs text-gray-700 ">{i + 1}</div>
				<div className="flex-grow">
					{_.isEmpty(shipmentItemGroup.tagID) ? (
						<Input
							disabled={saving}
							defaultValue={shipmentItemGroup.description}
							name={`description[${i}]`}
							onChange={inputHandler(shipmentItemGroup, "description")}
							type="text"
						/>
					) : (
						<div className="my-3">
							<Link styled to={urlTo("tag", shipmentItemGroup.tagID)}>
								{shipmentItemGroup.description}
							</Link>
						</div>
					)}
				</div>
				<div className="flex-none w-16 md:w-20">
					<Input
						ref={itemRef}
						name={`item[${i}]`}
						disabled={saving}
						className="text-right"
						defaultValue={shipmentItemGroup.unitStart || ""}
						error={_.get(errors, `releaseItems[${i}].item`)}
						onChange={inputHandler(shipmentItemGroup, "unitStart", itemRef)}
						type="text"
					/>
				</div>
				<div className="flex-none w-16 md:w-20">
					<Input
						ref={itemRef}
						name={`item[${i}]`}
						disabled={saving}
						className="text-right"
						defaultValue={shipmentItemGroup.unitEnd || ""}
						error={_.get(errors, `releaseItems[${i}].item`)}
						onChange={inputHandler(shipmentItemGroup, "unitEnd", itemRef)}
						type="text"
					/>
				</div>
				<div className="flex-none w-16 md:w-20 text-center pt-2">{unitTotal}</div>
				<div className="hidden md:block flex-none w-16 pt-3 text-right space-x-1">
					<ButtonIcon icon="Plus" onClick={addHandler(shipmentItemGroup)} busy={saving} />
					<ButtonIcon icon="Minus" onClick={removeHandler(shipmentItemGroup)} busy={saving} />
				</div>
			</div>
			<div className="flex flex-row gap-x-2 md:gap-x-4 px-4">
				<div className="hidden md:block flex-none w-4 pb-3 text-right"></div>
				<div className="flex-none w-4 pb-4 text-right text-xs text-gray-700 "></div>
				<div className="flex-grow">
					<AttributesForm
						busy={saving}
						defaultParams={_.get(shipmentItemGroup, "attributes")}
						errors={errors}
						errorsKey={`shipmentItems[${i}].attributes`}
						onChange={attributesHandler(shipmentItemGroup)}
					/>
				</div>
				<div className="w-16 md:w-40" />
			</div>
		</div>
	)
}
