import React from "react"
import _ from "lodash"
import { Redirect, useParams } from "react-router-dom"

import { GetTrashedItems, UntrashItems } from "@app/api"
import { useSession } from "@app/contexts"
import {
	Await,
	Badge,
	BreadcrumbDelimiter,
	Breadcrumbs,
	DateTime,
	Icon,
	Link,
	PageContainer,
	Tabs,
	Title,
} from "@app/components"
import { toUUID, urlTo } from "@app/util"
import { EntityType, Entity, AlertLevel, TrashedItem } from "@app/domain"

import { useEntityDetails } from "@app/hooks"

export const TrashShowPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const id = _.get(useParams(), "id", "")
	const uuid = toUUID(id)

	const [item, setItem] = React.useState<TrashedItem | undefined>()
	const [restored, setRestored] = React.useState<boolean>(false)

	const loading = _.isEmpty(item)

	React.useEffect(() => {
		let canceled = false
		const getTrashItem = async () => {
			try {
				const resp = await GetTrashedItems({ ids: [uuid] })
				if (canceled) {
					return
				}
				if (resp.ok) {
					setItem(_.get(resp, "result.trashedItems[0]"))
				}
			} catch (err) {
				handleError(err)
			}
		}
		getTrashItem()
		return () => {
			canceled = true
		}
	}, [id])

	let entityType: EntityType | undefined
	let entity: Entity | undefined

	if (item) {
		entityType = item.entityType
		entity = item.meta
	}

	const [, entityName, entityRoute, entityDetails] = useEntityDetails(entityType, entity)

	if (restored && item) {
		return <Redirect to={entityRoute} />
	}

	const restoreHandler = async () => {
		try {
			const resp = await UntrashItems({
				ids: [toUUID(id)],
			})
			if (resp.ok && item) {
				const entityType = t(`entity.${item.entitySubtype}`)
				const title = t("trash.notifications.success.restored", { name: item.name, entityType })
				addNotification({
					alertLevel: AlertLevel.Success,
					title: title,
				})
				setRestored(true)
			}
		} catch (err) {
			handleError(err)
		}
	}

	const tabs = [
		{ label: t("common.tabs.show"), to: urlTo("trash", id), active: true },
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="restore">
							<Icon className="mr-2" name="TrashRestore" />
							{t("common.buttons.restore")}
						</span>
					),
					value: "restore",
				},
			],
			onOptionClick: restoreHandler,
		},
	]

	const { deleted = "", name = entityName } = item || {}

	return (
		<PageContainer>
			<Title>{t("trash.title")}</Title>

			<Breadcrumbs>
				<Link to="/trash">{t("trash.title")}</Link>
				<BreadcrumbDelimiter />
				{name}
			</Breadcrumbs>

			<h2 className="text-2xl mb-0 font-medium line-through">{name}</h2>

			<Await
				loading={loading}
				then={() => (
					<>
						<div className="mb-4">
							<Badge label={t("common.labels.entityType")}>
								{t(`entity.${_.get(item, "entitySubtype", "")}`)}
							</Badge>
							&nbsp;
							<Badge label={t("common.labels.deleted")}>
								<DateTime time={deleted} />
							</Badge>
						</div>

						<Tabs tabs={tabs} className="mb-4" />

						{entityDetails}
					</>
				)}
			></Await>
		</PageContainer>
	)
}
