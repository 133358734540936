import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Icon, Badge, EntityBreadcrumbs, DateTime, Link, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { WorkFacility, EntityType } from "@app/domain"

interface IWorkFacilityHeaderProps {
	workFacility?: WorkFacility
	section?: string
	view: string
}

export const WorkFacilityHeader: React.FC<IWorkFacilityHeaderProps> = (props) => {
	const { workFacility, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: "...", created: "", updated: "" },
		workFacility,
	)

	const { t, tenantID } = useSession()

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("workFacilities/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workFacilities", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workFacilities/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.contacts"),
			to: urlTo("workFacilities/contacts", id),
			active: view === "contacts",
		},
		{
			label: t("common.tabs.jobs"),
			to: urlTo("workFacilities/jobs", id),
			active: view === "jobs",
		},
		{
			label: t("common.tabs.releases"),
			to: urlTo("workFacilities/releases", id),
			active: view === "releases",
		},
		{
			label: t("common.tabs.shipments"),
			to: urlTo("workFacilities/shipments", id),
			active: view === "shipments",
		},
		{
			label: t("common.tabs.workEvents"),
			to: urlTo("workFacilities/workEvents", id),
			active: view === "workEvents",
		},
		{
			label: t("common.tabs.workDevices"),
			to: urlTo("workFacilities/workDevices", id),
			active: view === "workDevices",
		},
		{
			label: t("common.tabs.workCells"),
			to: urlTo("workFacilities/workCells", id),
			active: view === "workCells",
		},
		{
			label: t("common.tabs.workResources"),
			to: urlTo("workFacilities/workResources", id),
			active: view === "workResources",
		},
		{
			label: t("common.tabs.workCellServers"),
			to: urlTo("workFacilities/workCellServers", id),
			active: view === "workCellServers",
		},
		{ label: t("common.tabs.edit"), to: urlTo("workFacilities/edit", id), active: view === "edit" },
	]

	let title
	if (view === "new") {
		title = t("workFacility.titles.new")
	} else if (view === "edit") {
		title = t("workFacility.titles.edit", { name })
	} else {
		title = t("workFacility.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={workFacility} entityType={EntityType.WorkFacility} view={view} />

			{view !== "new" ? (
				<>
					<div className="flex items-center">
						<h2 className="text-2xl mb-0 font-medium">{name}</h2>
						<div className="flex-grow">&nbsp;</div>
						<div className="ml-4 justify-self-end">
							<Link
								className="text-sm text-gray-700 hover:text-black hover:border-black border border-gray-400 px-4 py-1 rounded-full"
								to={urlTo("work-facilities/dashboard", id) + `/${tenantID}`}
							>
								Production dashboard
								<Icon className="ml-2" name="NewWindow" />
							</Link>
						</div>
					</div>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
