import React from "react"
import { startCase, isEqual } from "lodash"
import {
	add,
	compareAsc,
	format,
	formatRFC3339,
	isPast,
	isSameDay,
	isToday,
	isWithinInterval,
	startOfISOWeek,
	endOfISOWeek,
	differenceInCalendarDays,
} from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { useInterval } from "usehooks-ts"
import { useHistory } from "react-router-dom"
import clsx from "clsx"
import queryString from "query-string"

import {
	GetWorkFacilities,
	GetWorkCells,
	GetWorkEvents,
	GetReleases,
	GetReleaseUnits,
} from "@app/api"
import {
	AlertLevel,
	WorkEventType,
	WorkFacility,
	EntityType,
	WorkCell,
	Release,
	Job,
	Customer,
} from "@app/domain"
import { useSession } from "@app/contexts"
import { PageContainer, DateTimeInput, Select, Icon, Badge, Toggle, Await } from "@app/components"
import { ReportsHeader } from "@app/headers"
import { useQuery } from "@app/hooks"
import { urlTo, browserTimezone } from "@app/util"
import En from "@app/translations/en.json"

type CalendarEntry = {
	begin: Date
	end: Date
	name: string
	canceled: boolean
	url: string
	type: EntityType
	typeDetail: string
	job?: string
	customer?: string
}

const ReleaseDates = ["received", "deliverBy", "shipped"]

const isHappeningNow = (e: CalendarEntry) => {
	if (compareAsc(e.begin, e.end) >= 0) {
		return false
	}
	return isWithinInterval(new Date(), {
		start: e.begin,
		end: e.end,
	})
}

export const ReportsCalendarPage: React.FC = () => {
	const { addNotification } = useSession()

	const [workFacilities, setWorkFacilities] = React.useState<WorkFacility[]>([])
	const [selectedWorkFacility, setSelectedWorkFacility] = React.useState<WorkFacility>()
	const [workCells, setWorkCells] = React.useState<WorkCell[]>([])
	const [selectedWorkCell, setSelectedWorkCell] = React.useState<WorkCell>()
	const [calendarEntries, setCalendarEntries] = React.useState<CalendarEntry[]>([])
	const [showWorkEvents, setShowWorkEvents] = React.useState(true)
	const defaultWorkEventTypes = Object.values(WorkEventType)
	const [workEventTypes, setWorkEventTypes] = React.useState<string[]>(defaultWorkEventTypes)
	const [showReleases, setShowReleases] = React.useState(true)
	const defaultReleaseTypes = ReleaseDates
	const [releaseTypes, setReleaseTypes] = React.useState<string[]>(defaultReleaseTypes)
	const [showReleaseUnits, setShowReleaseUnits] = React.useState(true)
	const defaultReleaseUnitTypes = Object.entries(En["releaseUnitStatusTypes"]).map(([val]) => val)
	const [releaseUnitTypes, setReleaseUnitTypes] = React.useState<string[]>(defaultReleaseUnitTypes)

	const [calendarEntriesLoading, setCalendarEntriesLoading] = React.useState(true)
	const [queryParamsloading, setQueryParamsLoading] = React.useState(true)
	const [now, setNow] = React.useState(new Date())
	const [fromDate, setFromDate] = React.useState<Date>(startOfISOWeek(now))
	const [toDate, setToDate] = React.useState<Date>(endOfISOWeek(now))
	const history = useHistory()
	const query = useQuery()

	useInterval(
		() => {
			setNow(new Date())
		},
		1000 * 60 * 60,
	) // Once an hour.

	React.useEffect(() => {
		const getWorkFacility = async (id: string) => {
			const resp = await GetWorkFacilities({ ids: [id] })
			if (!resp?.ok) {
				return
			}
			setSelectedWorkFacility(resp.result.workFacilities[0])
		}
		const getWorkCell = async (id: string) => {
			const resp = await GetWorkCells({ ids: [id] })
			if (!resp?.ok) {
				return
			}
			setSelectedWorkCell(resp.result.workCells[0])
		}
		Object.entries(query).forEach(([k, v]) => {
			switch (k) {
				case "selectedWorkFacility":
					getWorkFacility(v as string)
					break
				case "selectedWorkCell":
					getWorkCell(v as string)
					break
				case "showWorkEvents":
					setShowWorkEvents(v === "true")
					break
				case "showReleases":
					setShowReleases(v === "true")
					break
				case "showReleaseUnits":
					setShowReleaseUnits(v === "true")
					break
				case "workEventTypes":
					setWorkEventTypes(v as string[])
					break
				case "releaseTypes":
					setReleaseTypes(v as string[])
					break
				case "releaseUnitTypes":
					setReleaseUnitTypes(v as string[])
					break
				default:
					break
			}
		})
		setQueryParamsLoading(false)
	}, [])

	React.useEffect(() => {
		// Update URL query string.
		history.replace({
			search: queryString.stringify({
				selectedWorkFacility: selectedWorkFacility?.id,
				selectedWorkCell: selectedWorkCell?.id,
				showWorkEvents: showWorkEvents ? undefined : showWorkEvents,
				showReleases: showReleases ? undefined : showReleases,
				showReleaseUnits: showReleaseUnits ? undefined : showReleaseUnits,
				workEventTypes: isEqual(workEventTypes, defaultWorkEventTypes) ? undefined : workEventTypes,
				releaseTypes: isEqual(releaseTypes, defaultReleaseTypes) ? undefined : releaseTypes,
				releaseUnitTypes: isEqual(releaseUnitTypes, defaultReleaseUnitTypes)
					? undefined
					: releaseUnitTypes,
			}),
		})
	}, [
		selectedWorkFacility,
		selectedWorkCell,
		fromDate,
		toDate,
		showWorkEvents,
		showReleases,
		showReleaseUnits,
		workEventTypes,
		releaseTypes,
		releaseUnitTypes,
	])

	React.useEffect(() => {
		const getWorkFacilities = async () => {
			const resp = await GetWorkFacilities({})
			if (resp.ok) {
				setWorkFacilities(resp.result.workFacilities)
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work facilities.`,
				})
			}
		}
		getWorkFacilities()
	}, [])

	React.useEffect(() => {
		const getWorkCells = async () => {
			const resp = await GetWorkCells({ workFacilityID: selectedWorkFacility?.id })
			if (resp.ok) {
				setWorkCells(resp.result.workCells)
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work cells.`,
				})
			}
		}
		getWorkCells()
	}, [selectedWorkFacility])

	React.useEffect(() => {
		const getEvents = async () => {
			if (!showWorkEvents) {
				setCalendarEntries((prev) => prev.filter((e) => e.type !== EntityType.WorkEvent))
				return
			}
			const timeBeginString = formatRFC3339(fromDate)
			const timeEndString = formatRFC3339(toDate)
			const params = {
				timeBeginString,
				timeEndString,
				workEventType: WorkEventType.DeployWorkCellProgram,
				workFacilityID: selectedWorkFacility?.id,
				workCellID: selectedWorkCell?.id,
			}
			const resp = await GetWorkEvents(params)
			if (resp.ok) {
				setCalendarEntries((prev) => [
					...prev.filter((e) => e.type !== EntityType.WorkEvent),
					...resp.result.workEvents
						.filter((we) => workEventTypes.includes(we.workEventType))
						.map(
							(we): CalendarEntry => ({
								begin: utcToZonedTime(
									we.timeBegin,
									selectedWorkFacility?.timezone ?? browserTimezone,
								),
								end: utcToZonedTime(we.timeEnd, selectedWorkFacility?.timezone ?? browserTimezone),
								name: we.description,
								canceled: we.canceled,
								url: urlTo("workEvent/show", we.id),
								type: EntityType.WorkEvent,
								typeDetail: we.workEventType,
							}),
						),
				])
			} else if (!resp.ok) {
				addNotification({
					title: "Error",
					subtitle: "Retrieving work events failed",
					alertLevel: AlertLevel.Danger,
				})
			}
		}
		const getReleases = async () => {
			if (!showReleases) {
				setCalendarEntries((prev) => prev.filter((e) => e.type !== EntityType.Release))
				return
			}
			const resp = await GetReleases({
				workFacilityID: selectedWorkFacility?.id,
				timeBegin: fromDate,
				timeEnd: toDate,
				limit: 100,
				includeCustomers: true,
				includeJobs: true,
			})
			if (resp.ok) {
				const jobIDtoJob = new Map<string, Job>()
				for (const job of resp.result?.jobs ?? []) {
					jobIDtoJob.set(job.id, job)
				}
				const customerIDtoCustomer = new Map<string, Customer>()
				for (const customer of resp.result?.customers ?? []) {
					customerIDtoCustomer.set(customer.id, customer)
				}
				for (const dateKey of ReleaseDates) {
					if (!releaseTypes.includes(dateKey)) {
						setCalendarEntries((prev) =>
							prev.filter((e) => e.type !== EntityType.Release || e.typeDetail !== dateKey),
						)
						continue
					}
					const key = dateKey as keyof Release
					setCalendarEntries((prev) => [
						...prev.filter((e) => e.type !== EntityType.Release || e.typeDetail !== dateKey),
						...resp.result.releases.map(
							(r): CalendarEntry => ({
								begin: utcToZonedTime(
									r.keyDates[key],
									selectedWorkFacility?.timezone ?? browserTimezone,
								),
								end: utcToZonedTime(
									r.keyDates[key],
									selectedWorkFacility?.timezone ?? browserTimezone,
								),
								name: r.name,
								canceled: false,
								url: urlTo("release/show", r.id),
								type: EntityType.Release,
								typeDetail: dateKey,
								job: jobIDtoJob.get(r.jobID)?.name,
								customer: customerIDtoCustomer.get(r.customerID)?.name,
							}),
						),
					])
				}
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work cells.`,
				})
			}
		}
		const getReleaseUnits = async () => {
			if (!showReleaseUnits) {
				setCalendarEntries((prev) => prev.filter((e) => e.type !== EntityType.ReleaseUnit))
				return
			}
			const resp = await GetReleaseUnits({
				workFacilityID: selectedWorkFacility?.id,
				workCellID: selectedWorkCell?.id,
				timeBegin: fromDate,
				timeEnd: toDate,
				limit: 100,
				includeCustomers: true,
				includeJobs: true,
			})
			if (resp.ok) {
				const jobIDtoJob = new Map<string, Job>()
				for (const job of resp.result?.jobs ?? []) {
					jobIDtoJob.set(job.id, job)
				}
				const customerIDtoCustomer = new Map<string, Customer>()
				for (const customer of resp.result?.customers ?? []) {
					customerIDtoCustomer.set(customer.id, customer)
				}
				setCalendarEntries((prev) => [
					...prev.filter((e) => e.type !== EntityType.ReleaseUnit),
					...resp.result.releaseUnits
						.filter((ru) => ru?.scheduled !== undefined && releaseUnitTypes.includes(ru.status))
						.map(
							(ru): CalendarEntry => ({
								begin: utcToZonedTime(
									ru.scheduled as string,
									selectedWorkFacility?.timezone ?? browserTimezone,
								),
								end: utcToZonedTime(
									ru.scheduled as string,
									selectedWorkFacility?.timezone ?? browserTimezone,
								),
								name: `${ru.description} #${ru.unit}`,
								canceled: false,
								url: urlTo("release-units/show", ru.id),
								type: EntityType.ReleaseUnit,
								typeDetail: ru.status,
								job: jobIDtoJob.get(ru?.jobID ?? "")?.name,
								customer: customerIDtoCustomer.get(ru?.customerID ?? "")?.name,
							}),
						),
				])
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work cells.`,
				})
			}
		}
		const getCalendarEntries = async () => {
			setCalendarEntriesLoading(true)
			await getEvents()
			await getReleases()
			await getReleaseUnits()
			setCalendarEntriesLoading(false)
		}
		getCalendarEntries()
	}, [
		selectedWorkFacility,
		selectedWorkCell,
		fromDate,
		toDate,
		now,
		showWorkEvents,
		showReleases,
		showReleaseUnits,
		workEventTypes,
		releaseTypes,
		releaseUnitTypes,
	])

	return (
		<PageContainer>
			<ReportsHeader view="calendar" />
			<Await
				loading={calendarEntriesLoading && queryParamsloading}
				then={() => (
					<div className="w-full flex gap-4 mt-4">
						<div className="min-w-[12rem] max-w-[20rem]">
							<div className="flex items-center place-content-center order-last md:order-first text-gray-600 text-sm">
								<button
									className="flex gap-2 items-center p-2 rounded-l-full bg-gray-400 hover:text-gray-700 hover:bg-gray-500 border-r-[1px] border-gray-500"
									onClick={() => {
										const periodLength = differenceInCalendarDays(toDate, fromDate)
										setToDate((p) => add(p, { days: -periodLength - 1 }))
										setFromDate((p) => add(p, { days: -periodLength - 1 }))
									}}
								>
									<Icon name="ChevronLeft" size="lg" />
									Prev {differenceInCalendarDays(toDate, fromDate) + 1} days
								</button>
								<button
									className="flex gap-2 items-center p-2 rounded-r-full bg-gray-400 hover:text-gray-700 hover:bg-gray-500 border-l-[1px] border-gray-500"
									onClick={() => {
										const periodLength = differenceInCalendarDays(toDate, fromDate)
										setToDate((p) => add(p, { days: periodLength + 1 }))
										setFromDate((p) => add(p, { days: periodLength + 1 }))
									}}
								>
									Next {differenceInCalendarDays(toDate, fromDate) + 1} days
									<Icon name="ChevronRight" size="lg" />
								</button>
							</div>
							<DateTimeInput
								clearable={false}
								defaultValue={JSON.stringify(fromDate)}
								value={fromDate}
								name="fromDate"
								label="From"
								disabled={calendarEntriesLoading}
								onChange={(date) => {
									setFromDate((p) => date ?? p)
								}}
								timezone={selectedWorkFacility?.timezone}
							/>
							<DateTimeInput
								clearable={false}
								defaultValue={JSON.stringify(toDate)}
								value={toDate}
								name="toDate"
								label="To"
								disabled={calendarEntriesLoading}
								onChange={(date) => {
									setToDate((p) => date ?? p)
								}}
								timezone={selectedWorkFacility?.timezone}
								endOfDay
							/>
							<Select
								disabled={calendarEntriesLoading}
								name="workFacilities"
								label="Work Facilities"
								placeholder="All"
								value={
									selectedWorkFacility
										? { value: selectedWorkFacility.id, label: selectedWorkFacility.name }
										: null
								}
								options={workFacilities.map((workFacility) => ({
									value: workFacility.id,
									label: workFacility.name,
								}))}
								onChange={(v) => {
									const ids = v.map((o) => o.value)
									setSelectedWorkFacility(
										workFacilities.filter((workFacility) => ids.includes(workFacility.id))?.[0],
									)
									setSelectedWorkCell((prev) =>
										!ids.length || ids.includes(prev?.workFacilityID ?? "") ? prev : undefined,
									)
								}}
							/>
							<Select
								disabled={calendarEntriesLoading}
								name="workCells"
								label="Work Cells"
								placeholder="All"
								value={
									selectedWorkCell
										? { value: selectedWorkCell.id, label: selectedWorkCell.name }
										: null
								}
								options={workCells.map((workCell) => ({
									value: workCell.id,
									label: workCell.name,
								}))}
								onChange={(v) => {
									const ids = v.map((o) => o.value)
									setSelectedWorkCell(
										workCells.filter((workCell) => ids.includes(workCell.id))?.[0],
									)
								}}
							/>
							<Toggle
								disabled={calendarEntriesLoading}
								name="releaseUnits"
								label="Release Units"
								defaultChecked={showReleaseUnits}
								onChange={(v) => {
									setShowReleaseUnits(v)
								}}
							/>
							{showReleaseUnits && (
								<Select
									disabled={calendarEntriesLoading}
									name="releaseUnitTypes"
									label="Release Unit Types"
									placeholder="Select..."
									isMulti
									defaultValue={Object.entries(En["releaseUnitStatusTypes"])
										.filter(([val]) => releaseUnitTypes.includes(val))
										.map(([val, key]) => ({
											value: val,
											label: key,
										}))}
									options={Object.entries(En["releaseUnitStatusTypes"]).map(([val, key]) => ({
										value: val,
										label: key,
									}))}
									onChange={(v) => {
										setReleaseUnitTypes(v.map((o) => o.value))
									}}
								/>
							)}
							<Toggle
								disabled={calendarEntriesLoading}
								name="releases"
								label="Releases"
								defaultChecked={showReleases}
								onChange={(v) => {
									setShowReleases(v)
								}}
							/>
							{showReleases && (
								<Select
									disabled={calendarEntriesLoading}
									name="releaseTypes"
									label="Release Types"
									placeholder="Select..."
									isMulti
									defaultValue={ReleaseDates.filter((opt) => releaseTypes.includes(opt)).map(
										(opt) => ({
											value: opt,
											label: startCase(opt),
										}),
									)}
									options={ReleaseDates.map((opt) => ({
										value: opt,
										label: startCase(opt),
									}))}
									onChange={(v) => {
										setReleaseTypes(v.map((o) => o.value))
									}}
								/>
							)}
							<Toggle
								disabled={calendarEntriesLoading}
								name="workEvents"
								label="Events"
								defaultChecked={showWorkEvents}
								onChange={(v) => {
									setShowWorkEvents(v)
								}}
							/>
							{showWorkEvents && (
								<Select
									disabled={calendarEntriesLoading}
									name="workEventTypes"
									label="Event Types"
									placeholder="Select..."
									isMulti
									defaultValue={Object.values(WorkEventType)
										.filter((opt) => workEventTypes.includes(opt))
										.map((opt) => ({
											value: opt,
											label: startCase(opt),
										}))}
									options={Object.values(WorkEventType).map((opt) => ({
										value: opt,
										label: startCase(opt),
									}))}
									onChange={(v) => {
										setWorkEventTypes(v.map((o) => o.value))
									}}
								/>
							)}
						</div>
						<div className="pl-4 border-gray-300 border-l">
							<div className="grid grid-cols-[min-content,auto] gap-3">
								{[...Array(differenceInCalendarDays(toDate, fromDate)), undefined]
									.map((_, i) =>
										add(fromDate, { days: differenceInCalendarDays(toDate, fromDate) - i }),
									)
									.reverse()
									.map((d, i) => {
										return (
											<React.Fragment key={i + d.toString()}>
												<span className="gap-x-1 font-mono inline-flex items-center place-content-center col-start-1">
													<p
														className={clsx(
															isToday(d) && "bg-yellow-500 text-white rounded-full",
															"inline-block mx-2 pr-2.5 py-0.5 w-10 text-right",
														)}
													>
														{format(d, "d")}
													</p>
													<p className="inline text-xs whitespace-nowrap">
														{format(d, "MMM, eee")}
													</p>
												</span>
												<div>
													{calendarEntries
														.filter((e) => isSameDay(e.begin, d))
														.sort((e1, e2) => compareAsc(e1.begin, e2.begin))
														.map((e, i) => {
															let entryBegin = format(e.begin, "h:mm aa")
															let entryEnd = format(e.end, "h:mm aa")
															if (entryBegin.slice(-4, -2) === "00") {
																entryBegin = entryBegin.slice(0, -5) + entryBegin.slice(-2)
															}
															if (entryEnd.slice(-4, -2) === "00") {
																entryEnd = entryEnd.slice(0, -5) + entryEnd.slice(-2)
															}
															if (entryBegin.slice(-2) !== entryEnd.slice(-2)) {
																entryBegin = entryBegin.slice(0, -3)
															}
															if (entryBegin !== entryEnd) {
																entryBegin += "-"
															} else {
																entryEnd = ""
															}
															return (
																<button
																	data-test-id="work-event-button"
																	key={i + e.url}
																	onClick={() => {
																		if (e.canceled) {
																			return
																		}
																		history.push(e.url)
																	}}
																	className={clsx(
																		isPast(e.begin) && "opacity-70",
																		isHappeningNow(e) && "bg-yellow-100",
																		e.canceled ? "line-through" : "hover:border-gray-300",
																		"text-sm text-left px-4 py-1 border border-white cursor-pointer rounded-full flex gap-2 items-baseline",
																	)}
																>
																	<p
																		className={clsx(
																			e.canceled && "line-through",
																			"inline-block min-w-[8rem] mr-2 whitespace-nowrap truncate",
																		)}
																	>
																		{e.name}
																	</p>
																	<Badge
																		label={startCase(
																			e.type === EntityType.WorkEvent ? "Event" : e.type,
																		)}
																	>
																		<p className="inline truncate">{startCase(e.typeDetail)}</p>
																	</Badge>
																	{e.job && (
																		<Badge label={"Job"}>
																			<p className="inline truncate">{e.job}</p>
																		</Badge>
																	)}
																	{e.customer && (
																		<Badge label={"Customer"}>
																			<p className="inline truncate">{e.customer}</p>
																		</Badge>
																	)}
																</button>
															)
														})}
												</div>
											</React.Fragment>
										)
									})}
							</div>
						</div>
					</div>
				)}
			/>
		</PageContainer>
	)
}
