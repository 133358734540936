import React from "react"
import _ from "lodash"

import { newJob, AlertLevel } from "@app/domain"
import { JobForm } from "@app/forms"
import { JobHeader } from "@app/headers"
import { SaveJob, SaveJobParams, newSaveJobParams } from "@app/api"
import { PageContainer } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useRedirect, useQuery } from "@app/hooks"
import { useSession } from "@app/contexts"

export const JobNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { customer = "" } = useQuery()
	const { redirect, setRedirect } = useRedirect()

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: SaveJobParams) => {
		setBusy(true)
		try {
			const resp = await SaveJob({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.jobs[0]")
				setRedirect(urlTo("jobs", saved), {
					alertLevel: AlertLevel.Success,
					title: t("job.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	const defaultParams = newSaveJobParams(newJob({ customerID: toUUID(customer) }))

	return (
		<PageContainer>
			<JobHeader view="new" />
			<div className="max-w-auto">
				<JobForm defaultParams={defaultParams} errors={errors} onSave={saveHandler} busy={busy} />
			</div>
		</PageContainer>
	)
}
