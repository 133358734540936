import React from "react"

import { useSession } from "@app/contexts"
import { Title, Tabs, Link, BreadcrumbDelimiter, Breadcrumbs } from "@app/components"
import { urlTo } from "@app/util"
import { AccountHeaderView } from "."

interface IAccountHeaderProps {
	view: AccountHeaderView
}

export const AccountHeader: React.FC<IAccountHeaderProps> = (props) => {
	const { t } = useSession()

	const { view } = props

	const tabs = [
		{ label: "Edit", to: urlTo("account/edit"), active: view === AccountHeaderView.edit },
		{
			label: "Change Password",
			to: urlTo("account/password"),
			active: view === AccountHeaderView.password,
		},
		{
			label: "Change PIN",
			to: urlTo("account/pin"),
			active: view === AccountHeaderView.PIN,
		},
	]

	let title = ""
	switch (view) {
		case AccountHeaderView.edit:
			title = t("account.titles.edit")
			break
		case AccountHeaderView.password:
			title = t("account.titles.password")
			break
		case AccountHeaderView.PIN:
			title = t("account.titles.PIN")
			break
	}

	return (
		<>
			<Title>{title}</Title>

			<Breadcrumbs>
				<Link to={urlTo("account/edit")}>{t("account.titles.account")}</Link>
				<BreadcrumbDelimiter />
				{title}
			</Breadcrumbs>

			<Tabs tabs={tabs} className="mb-4" />
		</>
	)
}
