import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Icon, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { Job, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IJobHeaderProps {
	job?: Job
	section?: string
	view: string
}

export const JobHeader: React.FC<IJobHeaderProps> = (props) => {
	const { job, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: "...", created: "", updated: "" },
		job,
	)

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Job, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("jobs/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("jobs", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("jobs/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.assemblies"),
			to: urlTo("jobs/assemblies", id),
			active: view === "assemblies",
		},
		{
			label: t("common.tabs.contacts"),
			to: urlTo("jobs/contacts", id),
			active: view === "contacts",
		},
		{
			label: t("common.tabs.documents"),
			to: urlTo("jobs/documents", id),
			active: view === "documents",
		},
		{
			label: t("common.tabs.materials"),
			to: urlTo("jobs/materials", id),
			active: view === "materials",
		},
		{
			label: t("common.tabs.releases"),
			to: urlTo("jobs/releases", id),
			active: view === "releases",
		},
		{
			label: t("common.tabs.tags"),
			to: urlTo("jobs/tags", id),
			active: view === "tags",
		},
		{
			label: t("common.tabs.shipments"),
			to: urlTo("jobs/shipments", id),
			active: view === "shipments",
		},
		{
			label: t("common.tabs.workCellPrograms"),
			to: urlTo("jobs/workCellPrograms", id),
			active: view === "workCellPrograms",
		},
		{ label: t("common.tabs.edit"), to: urlTo("jobs/edit", id), active: view === "edit" },
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("job.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { name }),
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("job.titles.new")
	} else if (view === "edit") {
		title = t("job.titles.edit", { name })
	} else {
		title = t("job.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={job} entityType={EntityType.Job} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
