import React from "react"
import _ from "lodash"

import { Property, TextField } from "@app/components"
import { useSession } from "@app/contexts"

import { WorkCellServer, EntityType } from "@app/domain"

interface IWorkCellServerDetailsProps {
	workCellServer?: WorkCellServer
}

export const WorkCellServerDetails: React.FC<IWorkCellServerDetailsProps> = (props) => {
	const { t } = useSession()

	const { workCellServer } = props

	if (_.isNil(workCellServer)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("workCellServer.labels.name")} value={workCellServer.name} />
			<Property label={t("workCellServer.labels.description")} value={workCellServer.description} />
			<Property label={t("workCellServer.labels.ipAddress")} value={workCellServer.ipAddress} />
			<Property label={t("workCellServer.labels.id")} id={workCellServer.id} />
			<Property
				label={t("workCellServer.labels.secret")}
				id={_.truncate(workCellServer.secret, { length: 11 })}
			/>
			<Property
				label={t("workCellServer.labels.workFacilityID")}
				relation={EntityType.WorkFacility}
				value={workCellServer.workFacilityID}
			/>
			<Property
				label={t("workCellServer.labels.workCellID")}
				relation={EntityType.WorkCell}
				value={workCellServer.workCellID}
			/>
			<Property label={t("workCellServer.labels.disabled")} value={workCellServer.disabled} />
			<TextField
				onFocus={(evt) => {
					evt.target.select()
				}}
				name="configString"
				label={t("workCellServer.labels.configString")}
				hint={t("workCellServer.hints.configString")}
				value={workCellServer.configString}
				readOnly
			/>
		</div>
	)
}
