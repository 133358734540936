import React from "react"
import _ from "lodash"

export type Modal = {
	id: string
	component: JSX.Element
}

export type ModalsContext = {
	active: boolean
	dismissModal: (id: string) => void
	modals: Modal[]
	setModal: (id: string, component: JSX.Element) => void
}

export const modalsContext = React.createContext<ModalsContext>({
	active: false,
	dismissModal: _.noop,
	modals: [],
	setModal: _.noop,
})

export const useModals = (): ModalsContext => React.useContext(modalsContext)
