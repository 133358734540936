import React from "react"
import _ from "lodash"
import { utcToZonedTime } from "date-fns-tz"
import { format } from "date-fns"
import { Link } from "react-router-dom"
import { Card, Title, Tracker, Flex, Text, Color } from "@tremor/react"

import { RunHistory } from "@app/api"
import { browserTimezone, urlTo } from "@app/util"
import { EntityType } from "@app/domain"

interface IWorkCellStatusCardProps {
	runHistory: RunHistory
	className?: string
}

export const WorkCellStatusCard: React.FC<IWorkCellStatusCardProps> = (props) => {
	const { runHistory, className = "" } = props
	const data = _.map(runHistory.history, (h) => {
		const color: Color = h.runs > 0 ? "green" : "red"
		const localTime = utcToZonedTime(h.from, browserTimezone)
		const tooltip = `${h.runs} ${h.runs === 1 ? "run" : "runs"} at ${format(localTime, "haa")} `
		return { color, tooltip }
	})
	return (
		<Link className="w-[20rem]" to={urlTo(EntityType.WorkCell, runHistory) + "/run-reports"}>
			<Card className={className}>
				<Title>{runHistory.name}</Title>
				<Text>{runHistory.workFacilityName}</Text>
				<Flex justifyContent="end" className="mt-4">
					<Text>{`${runHistory.count} ${
						runHistory.count === 1 ? "run" : "runs"
					} in 24 hours`}</Text>
				</Flex>
				<Tracker data={data} className="mt-2" />
			</Card>
		</Link>
	)
}
