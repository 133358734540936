import React from "react"
import _ from "lodash"

import { Icon } from "@app/components"

interface IAwaitProps {
	children?: React.ReactNode
	loading?: boolean
	then: () => JSX.Element
}

export const Await: React.FC<IAwaitProps> = (props) => {
	const { loading, then, children } = props
	if (children) {
		throw new Error("Await: unexpected children, use `then`")
	}
	const [show, setShow] = React.useState<boolean>(false)
	const [loaded, setLoaded] = React.useState<boolean>(false)

	React.useEffect(() => {
		if (loading) {
			const timerID = _.delay(() => {
				setShow(true)
			}, 300)
			return () => clearTimeout(timerID)
		} else {
			setLoaded(true)
		}
	}, [loading])

	if (!loaded) {
		if (show && loading) {
			return (
				<div className="w-full overflow-hidden mt-5 flex items-center justify-center text-gray-300">
					<Icon name="Cog" size="2x" spin={true} fixedWidth />
				</div>
			)
		} else if (loading) {
			return null
		}
	}
	return then()
}
