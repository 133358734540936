import React from "react"

import { Icon } from "@app/components"
import { useSession } from "@app/contexts"
import { Entity } from "@app/domain"
import { Result } from "@app/api"
import clsx from "clsx"

type EntitySearchSelectProps<ET, ETR> = {
	getResults: (query: string) => Promise<Result<ETR>>
	respKey: string
	getEntityName: (entity: ET) => string
	onSelect: (entity: ET) => void
	selectedIDs: string[]
	label?: string
	placeholder?: string
	disabled?: boolean
	busy?: boolean
	autocomplete?: boolean
}

export function EntitySearchSelect<ET, ETR>(
	props: EntitySearchSelectProps<ET, ETR>,
): React.ReactElement<EntitySearchSelectProps<ET, ETR>> {
	const {
		getResults,
		respKey,
		getEntityName,
		onSelect,
		selectedIDs,
		label = "",
		placeholder = "",
		disabled = false,
		busy = false,
		autocomplete = false,
	} = props

	const { handleError } = useSession()

	const nameInputRef = React.useRef<HTMLInputElement>(null)
	const [nameInput, setNameInput] = React.useState<string>("")
	const [results, setResults] = React.useState<ET[]>([])
	const [showResults, setShowResults] = React.useState<boolean>(false)
	const [highlightIndex, setHighlightIndex] = React.useState<number>(0)

	React.useEffect(() => {
		if (nameInput.length === 0) {
			setResults([])
			return
		}
		getResults(nameInput).then((resp: Result<ETR>) => {
			if (!resp.ok) {
				handleError("Error retrieving entities. Try again.")
				return
			}
			const entities = (resp.result[respKey] ?? []) as never[]
			setResults(
				entities.filter(
					(entity: Entity) =>
						selectedIDs.length === 0 || selectedIDs.every((id) => id !== entity.id),
				),
			)
			setHighlightIndex(Math.min(highlightIndex, entities.length - 1))
		})
	}, [nameInput, selectedIDs])

	// If not typing and nameInput isn't empty, prompt user to finish selection.
	const finishSelection = document.activeElement !== nameInputRef.current && nameInput.length > 0

	const selectEntity = () => {
		onSelect(results[highlightIndex])
		setNameInput("")
	}
	return (
		<div className="flex flex-col relative">
			{label && (
				<label htmlFor="name-input" className="text-md text-gray-700">
					{label}
				</label>
			)}
			<input
				value={nameInput}
				autoComplete={autocomplete ? "on" : "off"}
				placeholder={placeholder}
				ref={nameInputRef}
				disabled={disabled || busy}
				name="name-input"
				id="name-input"
				onFocus={() => {
					setShowResults(true)
				}}
				onBlur={() => {
					// Timeout is to allow for click event to fire on entity results.
					setTimeout(() => setShowResults(false), 150)
				}}
				onChange={(e) => {
					setNameInput(e.target.value)
					setHighlightIndex(0)
				}}
				onKeyDown={(e) => {
					if (e.key === "ArrowDown") {
						e.preventDefault()
						setHighlightIndex(Math.min(highlightIndex + 1, results.length - 1))
					} else if (e.key === "ArrowUp") {
						e.preventDefault()
						setHighlightIndex(Math.max(highlightIndex - 1, 0))
					} else if (e.key === "Enter" && results.length > 0) {
						e.preventDefault()
						selectEntity()
					} else if (e.key === "Escape") {
						e.preventDefault()
						setHighlightIndex(0)
						setResults([])
						const target = e.target as HTMLInputElement
						target.blur()
					}
				}}
				className="w-full border border-gray-400 placeholder:text-gray-600 p-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
			/>
			{nameInput.length > 0 && showResults && results.length > 0 && (
				<div
					className={clsx(
						label ? "top-16" : "top-10",
						"absolute bg-white flex flex-col flex-1 w-full border-t-2 z-10",
					)}
				>
					{results.map((entity, i) => (
						<button
							key={i}
							className={`text-left border-2 border-t-0 p-2 flex w-full items-center justify-between ${
								i === highlightIndex ? "bg-yellow-500" : "bg-white"
							}`}
							onClick={() => {
								selectEntity()
								if (nameInputRef && nameInputRef.current) {
									nameInputRef.current.focus()
									// Work around for input OnBlur timeout.
									setTimeout(() => setShowResults(true), 151)
								}
							}}
							onMouseOver={() => {
								setHighlightIndex(i)
							}}
						>
							{getEntityName(entity)}
							<Icon
								name="Plus"
								className={clsx("ml-2 text-gray-500", i === highlightIndex && "text-gray-200")}
							/>
						</button>
					))}
				</div>
			)}
			<p className="text-sm text-red-500 h-0">
				{finishSelection && "Please select an item from the list."}
			</p>
		</div>
	)
}
