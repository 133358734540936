import React from "react"
import _ from "lodash"
import { DiffPatcher, formatters } from "jsondiffpatch-rc"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"
import { EventLog, EntityType } from "@app/domain"
import { fromUUID } from "@app/util"

interface IEventLogDetailsProps {
	eventLog?: EventLog
}

import En from "@app/translations/en.json"

const entityMap = {
	addresses: "address",
}

const getEntityName = (name: string): string => {
	const key = _.camelCase(name)
	return entityMap[key] || key
}

const getLabel = (path: string[], key: string): string => {
	let entityName: string = _.last(path) || ""
	_.eachRight(path, (p) => {
		if (_.isString(p)) {
			entityName = p
			return false
		}
	})
	if (_.endsWith(key, "ID")) {
		return _.startCase(key)
	} else if (key === "id") {
		return "ID"
	}
	const label = _.get(En, `${getEntityName(entityName)}.labels.${key}`)
	return _.isString(label) ? label : _.startCase(key)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const processLabels = (path: string[], obj?: any): any => {
	if (obj === null) {
		return null
	}
	const clone = {}
	if (_.isArray(obj)) {
		return _.map(obj, (v) => (_.isObject(v) ? processLabels(path, v) : v))
	}
	Object.keys(obj).forEach((key) => {
		const label = getLabel(path, key)
		let val = obj[key]
		if (_.endsWith(label, "ID") && _.size(val) === 36) {
			val = fromUUID(val)
		}
		if (_.endsWith(key, "IDs") && _.isArray(val)) {
			val = _.map(val, fromUUID)
		}
		clone[label] = _.isObject(val) ? processLabels(path.concat(key), val) : val
	})
	return clone
}

const patcher = new DiffPatcher()

export const EventLogDetails: React.FC<IEventLogDetailsProps> = (props) => {
	const { t } = useSession()
	const { eventLog } = props
	if (!eventLog) {
		return null
	}

	const before = processLabels([eventLog.entityType], eventLog.before) || undefined
	const after = processLabels([eventLog.entityType], eventLog.after) || undefined

	const delta = patcher.diff(before, after)
	const out = delta ? formatters.html.format(delta, before) : ""

	return (
		<div className="flex flex-col md:flex-col">
			<div className="pt-1 px-2 max-w-lg">
				<Property label={t("eventLog.labels.id")} value={fromUUID(eventLog.id)} />
				<Property
					label={t("eventLog.labels.user")}
					relation={EntityType.User}
					value={eventLog.userID}
				/>
				<Property
					label={t("eventLog.labels.created")}
					date={eventLog.created}
					dateExplicit={true}
				/>
				<Property
					label={t("eventLog.labels.action")}
					value={_.upperFirst(t(`eventLog.actions.${_.camelCase(eventLog.action)}`))}
				/>
				<Property
					label={t("eventLog.labels.entityType")}
					value={t(`entity.${_.camelCase(eventLog.entityType)}`)}
				/>
				<Property group="Associations" />
				{_.chain(eventLog.entityIDs)
					.map((ids: string[], entityType: string) => {
						return _.map(ids, (id) => {
							if (_.some(["Contactable", "ShipmentItem", "ReleaseItem"], (v) => v === entityType)) {
								return null
							}
							const label = t(`entity.${_.camelCase(entityType)}`)
							return (
								<Property key={id} label={label} relation={EntityType[entityType]} value={id} />
							)
						})
					})
					.compact()
					.value()}
				<Property group="Changes" />
			</div>
			<div className="max-w-full overflow-auto">
				<div dangerouslySetInnerHTML={{ __html: out }} />
			</div>
		</div>
	)
}
