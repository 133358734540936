import React from "react"
import _ from "lodash"
import { v4 } from "uuid"

import { FormActions, Button, TagPreview, ReleaseDetails, ColorSelect } from "@app/components"
import { Attribute, ReleaseItem, AlertLevel, EntityType, newTag, Customer, Job } from "@app/domain"
import {
	AttributeParams,
	TemplateFieldParams,
	CreateTags,
	CreateTagsParams,
	newCreateTagsParams,
} from "@app/api"
import { TemplateFieldsForm } from "@app/forms"
import { useRelation, useRedirect, useFormHelpers } from "@app/hooks"
import { useSession } from "@app/contexts"
import { urlTo } from "@app/util"

import type { Release } from "@app/domain"

interface ITagCreateFormProps {
	release?: Release
	section?: string
}

export const TagCreateForm: React.FC<ITagCreateFormProps> = (props) => {
	const { t, handleError } = useSession()
	const { release } = props
	const uuid = _.get(release, "id", "")

	const [job, jobLoading] = useRelation<Job>(EntityType.Job, _.get(release, "jobID"))
	const [customer, customerLoading] = useRelation<Customer>(
		EntityType.Customer,
		_.get(release, "customerID"),
	)
	const [releaseItems, releaseItemsLoading] = useRelation<ReleaseItem[]>(
		EntityType.ReleaseItem,
		uuid,
	)
	const { redirect, setRedirect } = useRedirect()

	const { params, setParams, valueHandler } = useFormHelpers<CreateTagsParams>(
		newCreateTagsParams(),
	)
	const [busy, setBusy] = React.useState<boolean>(false)

	const loading = jobLoading || customerLoading || releaseItemsLoading

	if (_.isNil(release)) {
		return null
	}

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		try {
			const resp = await CreateTags({ ...params, releaseID: uuid })
			if (!resp.ok) {
				throw t("common.errors.unexpected")
			} else {
				const count = _.size(_.get(resp, "result.tags", []))
				setRedirect(urlTo("release/tags", release), {
					alertLevel: AlertLevel.Success,
					title: t("tag.notifications.success.generated", count),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	if (loading) {
		return null
	}

	if (redirect) {
		return redirect()
	}

	const labels = {}
	const templateFields = []
	let attributes: AttributeParams[] = []
	const quantity = _.get(releaseItems, "[0].quantity", 1)

	const newTemplateField = (
		label: string,
		value: string,
		options: string[] = [],
	): TemplateFieldParams | null => {
		if (labels[label]) {
			return null
		}
		labels[label] = true
		return { label, value, options: options.length ? options : ["Top", "Bottom"] }
	}
	// generation start
	templateFields.push(newTemplateField(t("tag.labels.customer"), customer?.name ?? ""))
	templateFields.push(newTemplateField(t("tag.labels.jobLocation"), ""))
	templateFields.push(newTemplateField(t("tag.labels.jobName"), job?.name ?? ""))
	templateFields.push(newTemplateField(t("tag.labels.drawingShort"), "DWG "))
	templateFields.push(newTemplateField(t("tag.labels.controlCodes"), "cc={{control-codes}}"))
	templateFields.push(newTemplateField("Space 1", "\n", ["Top"]))
	templateFields.push(
		newTemplateField(t("tag.labels.description"), "{{description}}", [
			"Top",
			"Bottom",
			"FontSizeLarge",
		]),
	)
	templateFields.push(newTemplateField("Space 2", " \n", ["Top"]))
	if (quantity > 0) {
		templateFields.push(
			newTemplateField(t("tag.labels.quantity"), "Qty #{{item}} of #{{quantity}}"),
		)
	}
	templateFields.push(newTemplateField(t("tag.labels.weight"), "{{weight}}"))

	_.each(_.get(release, "attributes", []), (attr) => {
		templateFields.push(newTemplateField(_.startCase(attr.name), `{{${attr.name}}}`))
	})

	_.each(_.get(job, "attributes", []), (attr) => {
		templateFields.push(newTemplateField(_.startCase(attr.name), `{{${attr.name}}}`))
	})

	const itemAttrs: { [key: string]: string } = {}

	_.each(releaseItems, (r: ReleaseItem) => {
		_.each(r.attributes, (attr: Attribute) => {
			itemAttrs[attr.name] = t("tag.hints.itemValue", { name: attr.name })
		})
	})

	_.each(itemAttrs, (value, name) => {
		const label = `item-${name}`
		attributes.push({ name: label, value })
		templateFields.push(newTemplateField(label, `{{${label}}}`))
	})

	attributes = _.uniqBy(attributes, "name")

	const tag = _.assign(newTag(), {
		id: v4(),
		color: params.color,
		referenceID: "TXXXXXX",
		quantity,
		templateFields: params.templateFields,
		attributes,
		description: t("tag.hints.itemValue", { name: "description" }),
	})

	const disabled = _.isEmpty(params.releaseItemIDs)

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row gap-x-4">
				<div className="sm:w-1/2 lg:w-1/3">
					<h2 className="mb-2 text-lg">Release details</h2>
					<ReleaseDetails release={release} section={"details"} />
					<h2 className="my-4 text-lg">Release items</h2>
					<ReleaseDetails
						release={release}
						section={"items"}
						selectable
						onChangeIDs={(releaseItemIDs: string[]) => {
							setParams((prev) => {
								return { ...prev, releaseItemIDs }
							})
						}}
					/>
				</div>
				<div className="sm:w-1/2 lg:w-1/3">
					<ColorSelect
						label={t("tag.labels.color")}
						defaultValue={params.color}
						disabled={busy}
						onChange={valueHandler("color")}
						error={""}
					/>
					<TemplateFieldsForm
						busy={busy}
						defaultParams={_.compact(templateFields)}
						disabled={loading}
						documentType={"tag"}
						errors={{}}
						errorsKey="templateFields"
						onChange={valueHandler("templateFields")}
					/>
				</div>
				<div className="sm:w-1/2 lg:w-1/3">
					<TagPreview tag={tag} editing={true} />
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("tag.buttons.create")}
					busy={busy}
					disabled={loading || disabled}
				/>
			</FormActions>
		</form>
	)
}
