import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Icon, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { Contact, EntityType, ContactUtils } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IContactHeaderProps {
	contact?: Contact
	section?: string
	view: string
}

export const ContactHeader: React.FC<IContactHeaderProps> = (props) => {
	const { contact, section = "", view } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: ContactUtils.name(contact) || "...", created: "", updated: "" },
		contact,
	)

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Contact, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("contacts/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("contacts", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("contacts/log", id),
					active: view === "log",
				},
			],
		},

		{ label: t("common.tabs.edit"), to: urlTo("contacts/edit", id), active: view === "edit" },
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("contact.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { name }),
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("contact.titles.new")
	} else if (view === "edit") {
		title = t("contact.titles.edit", { name })
	} else {
		title = t("contact.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={contact} entityType={EntityType.Contact} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
