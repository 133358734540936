import _ from "lodash"

export type Worker = {
	id: string

	firstName: string
	lastName: string
	disabled: boolean

	created: string
	updated: string
}

export const newWorker = (): Worker => {
	return {
		id: "",

		firstName: "",
		lastName: "",
		disabled: false,

		created: "",
		updated: "",
	}
}

export const WorkerUtils = {
	name: (v?: Worker): string => {
		if (v) {
			return _.trim([v.firstName, v.lastName].join(" "))
		}
		return ""
	},
}
