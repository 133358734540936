/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import _ from "lodash"
import { ContactableParams } from "@app/api"

import type { OptionType } from "@app/components"

export type FormHelpers<T> = {
	contactablesHandler: (contactables: ContactableParams[], removeContactables: string[]) => void
	dateHandler: (key: string, includeTime?: boolean, timezone?: string) => (date?: Date) => void
	floatInputHandler: (key: string) => (e: React.FormEvent<HTMLInputElement>) => void
	inputHandler: (
		key: string,
		callback?: (params: T) => T,
	) => (e: React.FormEvent<HTMLInputElement>) => void
	intInputHandler: (key: string) => (e: React.FormEvent<HTMLInputElement>) => void
	lengthHandler: (key: string) => (v: number | undefined) => void
	params: T
	selectHandler: (
		key: string,
		isMulti?: boolean,
		callback?: (params: T) => T,
	) => (v: OptionType[]) => void
	setParams: (next: T | ((params: T) => T)) => void
	sliderHandler: (key: string) => (v: number) => void
	textFieldHandler: (key: string) => (e: React.FormEvent<HTMLTextAreaElement>) => void
	valueHandler: <T1>(key: string) => (v: T1) => void
}

export const useFormHelpers = <T,>(defaultParams: T | (() => T)): FormHelpers<T> => {
	const [params, setParams] = React.useState<T>(defaultParams)

	if (import.meta.env.DEV) {
		// React.useEffect(() => {
		// 	console.log("useFormHelper", JSON.stringify(params, null, "  "))
		// }, [params])
	}

	const valueHandler = <T1,>(key: string): ((v: T1) => void) => {
		return (v: T1) => {
			setParams((params) => _.set(_.cloneDeep(params) as any, key, v) as T)
		}
	}

	const sliderHandler = (key: string): ((v: number) => void) => {
		return (v) => {
			setParams((params) => _.set(_.cloneDeep(params) as any, key, v) as T)
		}
	}

	const lengthHandler = (key: string): ((v: number | undefined) => void) => {
		return (v: number | undefined) => {
			setParams((params) => _.set(_.cloneDeep(params) as any, key, v) as T)
		}
	}

	const inputHandler = (
		key: string,
		callback?: (params: T) => T,
	): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const v = (e.target as HTMLInputElement).value
			setParams((params) => {
				let next = _.set(_.cloneDeep(params) as any, key, v) as T
				if (callback) {
					next = callback(next)
				}
				return next
			})
		}
	}

	const textFieldHandler = (key: string): ((e: React.FormEvent<HTMLTextAreaElement>) => void) => {
		return (e) => {
			const v = (e.target as HTMLTextAreaElement).value
			setParams((params) => _.set(_.cloneDeep(params) as any, key, v) as T)
		}
	}

	const floatInputHandler = (key: string): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			const v = parseFloat(target.value)
			if (!isNaN(v)) {
				setParams((params) => _.set(_.cloneDeep(params) as any, key, v) as T)
			}
		}
	}

	const intInputHandler = (key: string): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			const v = parseInt(target.value, 10)
			if (!isNaN(v)) {
				setParams((params) => _.set(_.cloneDeep(params) as any, key, v) as T)
			}
		}
	}

	const dateHandler = (key: string): ((date?: Date) => void) => {
		return (date?: Date) => {
			setParams((params) => {
				const value = date?.toISOString() || null
				return _.set(_.cloneDeep(params) as any, key, value) as T
			})
		}
	}

	const contactablesHandler = (contactables: ContactableParams[], removeContactables: string[]) => {
		setParams((params) => {
			return { ..._.cloneDeep(params), contactables, removeContactables }
		})
	}

	const selectHandler = (
		key: string,
		isMulti?: boolean,
		callback?: (params: T) => T,
	): ((v: OptionType[]) => void) => {
		return (v: OptionType[]) => {
			let val: string | string[] | undefined = undefined
			if (isMulti) {
				val = _.map(v, "value")
			} else {
				val = _.get(v, "[0].value")
			}
			setParams((params) => {
				let next = _.set(_.cloneDeep(params) as any, key, val) as T
				if (callback) {
					next = callback(next)
				}
				return next
			})
		}
	}

	return {
		contactablesHandler,
		dateHandler,
		floatInputHandler,
		inputHandler,
		intInputHandler,
		lengthHandler,
		params,
		selectHandler,
		setParams,
		sliderHandler,
		textFieldHandler,
		valueHandler,
	}
}
