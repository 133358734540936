import _ from "lodash"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result, SaveWorkResourceParams } from "@app/api"
import type { WorkCell, WorkResource, Entity, Pagination } from "@app/domain"

export type SaveWorkCellParams = {
	id?: string
	workFacilityID?: string

	capabilities: string[]
	description: string
	disabled: boolean
	logoURL: string
	name: string
	tagsDisabled: boolean
	workCellType: string

	workResources: SaveWorkResourceParams[]
	removeWorkResources: string[]
}

export const newSaveWorkCellParams = (workCell?: WorkCell): SaveWorkCellParams => {
	return {
		id: _.get(workCell, "id", ""),
		workFacilityID: _.get(workCell, "workFacilityID", ""),

		capabilities: _.get(workCell, "capabilities", []),
		description: _.get(workCell, "description", ""),
		disabled: _.get(workCell, "disabled", false),
		logoURL: _.get(workCell, "logoURL", ""),
		name: _.get(workCell, "name", ""),
		tagsDisabled: _.get(workCell, "tagsDisabled", false),
		workCellType: _.get(workCell, "workCellType", ""),

		workResources: [],
		removeWorkResources: [],
	}
}

export type SaveWorkCellResult = {
	workCells: WorkCell[]
	workResources: WorkResource[]
}

export const SaveWorkCell = async (
	params: SaveWorkCellParams,
): Promise<Result<SaveWorkCellResult>> => {
	const data = await rpc("SaveWorkCell", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkCellResult>(data)
}

export type GetWorkCellsParams = {
	tenantID?: number

	ids?: string[]
	includeIDs?: string[]
	workFacilityID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkCellsResult = {
	workCells: WorkCell[]
	pagination?: Pagination
}

export const GetWorkCells = async (
	params: GetWorkCellsParams,
): Promise<Result<GetWorkCellsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkCells", "workCells", id).then((workCell?: Entity) => {
			return {
				ok: true,
				result: { workCells: _.compact([workCell as WorkCell]) },
			} as Result<GetWorkCellsResult>
		}) as Promise<Result<GetWorkCellsResult>>
	}
	const data = await rpc("GetWorkCells", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkCellsResult>(data)
}
