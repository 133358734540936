import React from "react"

import { AccountPasswordForm } from "@app/forms"
import { AlertLevel } from "@app/domain"
import { PageContainer } from "@app/components"
import { AccountHeader, AccountHeaderView } from "@app/headers"
import { useSession } from "@app/contexts"
import { ChangeAccountPassword, ChangeAccountPasswordParams } from "@app/api"

export const AccountPasswordPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: ChangeAccountPasswordParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await ChangeAccountPassword({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				addNotification({
					alertLevel: AlertLevel.Success,
					title: t("account.notifications.success.changePassword"),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	return (
		<PageContainer>
			<AccountHeader view={AccountHeaderView.password} />
			<div className="max-w-auto px-2">
				<AccountPasswordForm onSave={saveHandler} busy={busy} errors={errors} />
			</div>
		</PageContainer>
	)
}
