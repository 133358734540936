import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"

import { Await, Title, PageContainer } from "@app/components"
import { newSaveMaterialParams, SaveMaterial, SaveMaterialParams } from "@app/api"
import { Material, AlertLevel, EntityType } from "@app/domain"
import { MaterialForm } from "@app/forms"
import { MaterialHeader } from "@app/headers"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const MaterialEditPage: React.FC = () => {
	const { pathname } = useLocation()
	const segments = pathname.split("/")
	let section = ""
	if (_.size(segments) === 5) {
		section = _.last(segments) || ""
	}

	const { t, handleError } = useSession()
	const [material, materialLoading] = useEntity<Material>(EntityType.Material)
	const { id, controlCode } = _.assign({ id: "", controlCode: "..." }, material)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveMaterialParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveMaterial({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.materials?.[0]
				setRedirect(urlTo("materials", saved), {
					alertLevel: AlertLevel.Success,
					title: t("material.notifications.success.updated", { controlCode: saved?.controlCode }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("material.titles.edit", { controlCode })}</Title>
			<MaterialHeader material={material} section={section} view="edit" />
			<Await
				loading={materialLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<MaterialForm
							defaultParams={newSaveMaterialParams(material)}
							id={id}
							errors={errors}
							busy={busy}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
