import React from "react"
import _ from "lodash"

import { Button, FormActions, Select, TextField } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkCellProgramFeedbackParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IWorkCellProgramFeedbackFormProps {
	busy?: boolean
	defaultParams: SaveWorkCellProgramFeedbackParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkCellProgramFeedbackParams) => void
}

export const WorkCellProgramFeedbackForm: React.FC<IWorkCellProgramFeedbackFormProps> = (props) => {
	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { params, selectHandler, textFieldHandler } =
		useFormHelpers<SaveWorkCellProgramFeedbackParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workCellProgramFeedback.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const workCellProgramFeedbackOptions = _.map(En.workCellProgramFeedback.types, (label, value) => {
		return { label, value: _.upperFirst(value) }
	}).sort((left, right) => {
		return left.label < right.label ? -1 : 1
	})
	let defaultWorkCellTypeOption
	_.each(workCellProgramFeedbackOptions, (opt) => {
		if (opt.value === _.get(params, "workCellProgramFeedback")) {
			defaultWorkCellTypeOption = opt
			return false
		}
	})

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Select
						disabled={disabled || busy}
						defaultValue={defaultWorkCellTypeOption}
						isSearchable={true}
						label={t("workCellProgramFeedback.labels.feedbackType")}
						name="feedbackType"
						error={_.get(errors, "feedbackType")}
						onChange={selectHandler("feedbackType")}
						options={workCellProgramFeedbackOptions}
					/>
					<TextField
						disabled={disabled || busy}
						defaultValue={params.note}
						label={t("workCellProgramFeedback.labels.note")}
						name="note"
						hint={t("workCellProgramFeedback.hints.note")}
						error={_.get(errors, "note")}
						onChange={textFieldHandler("note")}
						type="text"
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workCellProgramFeedback.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
