import _ from "lodash"
import { useParams } from "react-router-dom"

import { EntityType } from "@app/domain"
import { useRelation } from "@app/hooks"

export const useEntity = <T,>(
	entityType: EntityType,
	entityID?: string | null,
): [
	T | undefined,
	boolean,
	(e: T | undefined | ((p: T | undefined) => T | undefined)) => void,
	boolean,
] => {
	const id = entityID || _.get(useParams(), "id", "")
	const [result, loading, setResult, notFound] = useRelation<T>(entityType, id, {
		setHTTPStatus: true,
	})
	return [result as T | undefined, loading, setResult, notFound]
}
