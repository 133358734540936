import _ from "lodash"

import { Attribute } from "@app/domain"

export type AttributeParams = {
	name: string
	value: string
}

export const newAttributeParams = (attr?: Attribute): AttributeParams => {
	return {
		name: _.get(attr, "name", ""),
		value: _.get(attr, "value", ""),
	}
}
