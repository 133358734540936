import _ from "lodash"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkCellServer, Entity, Pagination } from "@app/domain"

export type SaveWorkCellServerParams = {
	id?: string
	workCellID?: string
	workFacilityID?: string

	configString?: string
	description: string
	disabled: boolean
	ipAddress?: string
	name: string
	newSecret?: boolean
	secret?: string
}

export const newSaveWorkCellServerParams = (
	workCellServer?: WorkCellServer,
): SaveWorkCellServerParams => {
	return {
		id: _.get(workCellServer, "id", ""),
		workCellID: _.get(workCellServer, "workCellID", ""),
		workFacilityID: _.get(workCellServer, "workFacilityID", ""),

		configString: _.get(workCellServer, "configString"),
		description: _.get(workCellServer, "description", ""),
		disabled: _.get(workCellServer, "disabled", false),
		ipAddress: _.get(workCellServer, "ipAddress"),
		name: _.get(workCellServer, "name", ""),
		secret: _.get(workCellServer, "secret"),
	}
}

export type SaveWorkCellServerResult = {
	workCellServers: WorkCellServer[]
}

export const SaveWorkCellServer = async (
	params: SaveWorkCellServerParams,
): Promise<Result<SaveWorkCellServerResult>> => {
	const data = await rpc("SaveWorkCellServer", params, { minDuration: 300 })
	return ReceivedResult<GetWorkCellServersResult>(data)
}

export type GetWorkCellServersParams = {
	ids?: string[]
	includeIDs?: string[]
	workCellID?: string
	workFacilityID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkCellServersResult = {
	workCellServers: WorkCellServer[]
	pagination?: Pagination
}

export const GetWorkCellServers = async (
	params: GetWorkCellServersParams,
): Promise<Result<GetWorkCellServersResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkCellServers", "workCellServers", id).then((workCellServer?: Entity) => {
			return {
				ok: true,
				result: { workCellServers: _.compact([workCellServer as WorkCellServer]) },
			} as Result<GetWorkCellServersResult>
		}) as Promise<Result<GetWorkCellServersResult>>
	}
	const data = await rpc("GetWorkCellServers", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkCellServersResult>(data)
}
