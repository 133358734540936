import _ from "lodash"

type Entity = {
	id: string
	type: string
	description?: string
}

export type TrackingCode = {
	action?: Entity

	actors?: Entity[]
	locations?: Entity[]
	subjects?: Entity[]
}

type EncodedEntity = {
	id: string // id
	t: string // type
	d?: string // description
}

export type EncodedTrackingCode = {
	z?: EncodedEntity // action

	a?: EncodedEntity[] // actors
	l?: EncodedEntity[] // locations
	s?: EncodedEntity[] // subjects
}

export const encodeEntity = (entity: Entity | undefined): EncodedEntity | undefined => {
	if (!entity) {
		return undefined
	}
	const { id, type, description } = entity
	return { id, t: type, d: description }
}

export const encode = (entities: Entity[] | undefined): EncodedEntity[] | undefined => {
	if (_.isEmpty(entities)) {
		return undefined
	}
	return _.compact(_.map(entities, encodeEntity))
}

export const decodeEntity = (entity: EncodedEntity | undefined): Entity | undefined => {
	if (!entity) {
		return undefined
	}
	const { id, t, d } = entity
	return { id, type: t, description: d }
}

export const decodeEntities = (entities: EncodedEntity[] | undefined): Entity[] | undefined => {
	if (_.isEmpty(entities)) {
		return undefined
	}
	return _.compact(_.map(entities, decodeEntity))
}

export const decodeTrackingCode = (tc: EncodedTrackingCode): TrackingCode | undefined => {
	if (_.isEmpty(tc)) {
		return undefined
	}
	return {
		action: decodeEntity(tc.z),
		actors: decodeEntities(tc.a),
		locations: decodeEntities(tc.l),
		subjects: decodeEntities(tc.s),
	}
}
