import React from "react"
import _ from "lodash"
import { useTranslate } from "react-polyglot"
import * as Sentry from "@sentry/browser"

import { HTTPError, ServerError, UnauthorizedError } from "@app/api"
import { AlertLevel, UserUtils } from "@app/domain"

import type { UserSession, Notification, UserPermission } from "@app/domain"

import { sessionContext, INotificationProps } from "."

type NotificationAction =
	| { type: "remove"; id: string }
	| { type: "add"; notification: INotificationProps }
	| { type: "reset" }

const INotificationDefaultProps = {
	alertLevel: AlertLevel.Info,
	autoCloseTimeout: 5000,
}

const notificationsReducer = (state: Notification[], action: NotificationAction) => {
	switch (action.type) {
		case "remove":
			return state.reduce((acc: Notification[], n) => {
				if (action.id !== n.id) {
					acc.push(n)
				}
				return acc
			}, [])
		case "add":
			return [
				{
					...INotificationDefaultProps,
					...action.notification,
					...{ id: _.uniqueId(), created: Date.now() },
				},
			].concat(state)
		case "reset":
			return []
		default:
			throw new Error()
	}
}

type SessionProviderProps = {
	children: React.ReactNode
}

export const SessionProvider: React.FC<SessionProviderProps> = (props) => {
	const { children } = props
	const t = useTranslate()

	const [loading, setLoading] = React.useState<boolean>(true)
	const [notifications, notificationsDispatch] = React.useReducer(notificationsReducer, [])
	const [offline, setOffline] = React.useState<boolean>(false)
	const [oldBuild, setOldBuild] = React.useState<boolean>(false)
	const [session, setSession] = React.useState<UserSession | undefined>(undefined)

	React.useEffect(() => {
		const { user } = session || {}
		if (user) {
			Sentry.setUser({
				id: user.id,
				username: UserUtils.name(user),
				email: user.email,
				ip_address: "{{auto}}",
			})
		}
	}, [session])

	const addNotification = (props: INotificationProps) => {
		notificationsDispatch({ type: "add", notification: props })
	}

	const removeNotification = (id: string) => {
		notificationsDispatch({ type: "remove", id })
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleError = (err: any) => {
		if (err.constructor === UnauthorizedError) {
			_.defer(() => {
				setSession(undefined)
			})
			return
		}
		if (import.meta.env.DEV) {
			console.error(err)
		}
		if (err.constructor === TypeError) {
			addNotification({
				alertLevel: AlertLevel.Danger,
				title: t("common.errors.httpNetwork"),
				subtitle: t("common.errors.offline"),
			})
		} else if (err.constructor === HTTPError) {
			const httpErr = err as HTTPError
			addNotification({
				alertLevel: AlertLevel.Danger,
				title: t("common.errors.httpNetwork"),
				subtitle: t("common.errors.httpResponse", { status: httpErr.status }),
			})
		} else if (err.constructor === ServerError) {
			const serverErr = err as ServerError
			addNotification({
				alertLevel: AlertLevel.Danger,
				title: t("common.errors.serverUnexpected"),
				subtitle: serverErr.message,
			})
		} else {
			addNotification({
				alertLevel: AlertLevel.Danger,
				title: t("common.errors.unexpected"),
				subtitle: err.toString(),
			})
		}
	}

	const { tenants = [], user, workFacility, tenantName = "", tenantID = 0 } = session || {}

	return (
		<sessionContext.Provider
			value={{
				addNotification,
				handleError,
				loading,
				notifications,
				offline,
				oldBuild,
				removeNotification,
				setLoading,
				setOffline,
				setOldBuild,
				setSession,
				t,
				tenantID,
				tenantName,
				tenants,
				user,
				userHas: (...permissions: [UserPermission]): boolean => {
					if (_.isEmpty(permissions)) {
						throw new Error("invalid use with 0 permissions")
					}
					let result = true
					_.each(permissions, (p) => {
						const { permissions: userPermissions = [] } = user || {}
						if (_.isEmpty(userPermissions) || !userPermissions.includes(p)) {
							result = false
							return false
						}
					})
					return result
				},
				workFacility,
			}}
		>
			{children}
		</sessionContext.Provider>
	)
}
