import React from "react"
import _ from "lodash"

import {
	BrowserRouter,
	Switch,
	Route,
	Redirect,
	RouteProps,
	withRouter,
	useParams,
	useLocation,
} from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { urlTo } from "@app/util"

import config from "@app/config"
import { AuthedLayout } from "@app/layouts"
import {
	AccountEditPage,
	AccountPasswordPage,
	AccountPINPage,
	AssemblyEditPage,
	AssemblyImportPage,
	AssemblyNewPage,
	AssemblyShowPage,
	ContactEditPage,
	ContactNewPage,
	ContactShowPage,
	CustomerEditPage,
	CustomerNewPage,
	CustomerShowPage,
	DashboardPage,
	DocumentEditPage,
	DocumentNewPage,
	DocumentShowPage,
	EntityListPage,
	EventLogShowPage,
	FileShowPage,
	FinancePage,
	HTTPErrorPage,
	InventoryListPage,
	JobEditPage,
	JobNewPage,
	JobShowPage,
	LoginByTokenPage,
	LoginPage,
	LogoutPage,
	MaterialEditPage,
	MaterialNewPage,
	MaterialShowPage,
	ProductionDashboardPage,
	ProductionPage,
	RelationListPage,
	ReleaseEditPage,
	ReleaseNewPage,
	ReleaseShowPage,
	ReleaseUnitCreatePage,
	ReleaseUnitEditPage,
	ReleaseUnitShowPage,
	ReportsCalendarPage,
	ReportsWorkCellSessionsPage,
	ReportsWorkCellStatusesPage,
	ReportsWorkTrackingTablePage,
	ReportsWorkTrackingCalendarPage,
	ScanPage,
	SettingEditPage,
	SettingNewPage,
	SettingShowPage,
	ShipmentEditPage,
	ShipmentNewPage,
	ShipmentShowPage,
	TagCreatePage,
	TagEditPage,
	TagPrintPage,
	TagShowPage,
	TagShowPublicPage,
	TrackingCodeShowPublicPage,
	TrackingSheetNewPage,
	TrashShowPage,
	UserEditPage,
	UserNewPage,
	UserNewPINPage,
	UserShowPage,
	WorkActionEditPage,
	WorkActionNewPage,
	WorkActionShowPage,
	WorkCellEditPage,
	WorkCellNewPage,
	WorkCellProgramEditPage,
	WorkCellProgramFeedbackPage,
	WorkCellProgramNewPage,
	WorkCellProgramPathVisualizerPage,
	WorkCellProgramProgramsPage,
	WorkCellProgramShowPage,
	WorkCellServerEditPage,
	WorkCellServerNewPage,
	WorkCellServerShowPage,
	WorkCellShowPage,
	WorkDeviceEditPage,
	WorkDeviceNewPage,
	WorkDeviceShowPage,
	WorkerBadgeNewPage,
	WorkerEditPage,
	WorkerNewPage,
	WorkerShowPage,
	WorkEventEditPage,
	WorkEventNewPage,
	WorkEventShowPage,
	WorkFacilityEditPage,
	WorkFacilityNewPage,
	WorkFacilityShowPage,
	WorkResourceEditPage,
	WorkResourceNewPage,
	WorkResourceShowPage,
} from "@app/pages"
import { useSession, useStream, ErrorProvider, ModalsProvider } from "@app/contexts"
import { WhoAmI, Cache, GetBuildDetails } from "@app/api"
import { EntityType, StreamMessage } from "@app/domain"
import { Button, DropDownButton } from "@app/components"
import { tx, startTx } from "@app/tx"
import { ReportsWorkCellRunReports } from "./pages/Reports/ReportsWorkCellRunReports"

const redirects: { [key: string]: string } = {
	"/releases/:id/generate-tags": "/releases/:id",
}

type PrivateRouteProps = {
	layout?: boolean
}

const PublicRoute: React.FC<RouteProps> = (props) => {
	startTx(`public#${props.path}`)
	const rendered = <Route {...props} />
	tx?.finish()
	return rendered
}

const PrivateRoute: React.FC<RouteProps & PrivateRouteProps> = (props) => {
	startTx(`private#${props.path}`)
	const rendered = <AuthedRoute {...props} />
	tx?.finish()
	return rendered
}

const AuthedRoute: React.FC<RouteProps & PrivateRouteProps> = (props) => {
	const { component: Component, layout = true, ...rest } = props
	const { loading, user, setSession, setLoading, handleError } = useSession()

	React.useEffect(() => {
		const whoAmI = async () => {
			try {
				const resp = await WhoAmI()
				if (_.get(resp, "ok")) {
					setSession(resp.result.session)
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		whoAmI()
	}, [])

	if (!Component) {
		return null
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				!loading ? (
					user ? (
						layout ? (
							<AuthedLayout>
								<Component {...props} />
							</AuthedLayout>
						) : (
							<Component {...props} />
						)
					) : (
						<Redirect to={"/login"} />
					)
				) : null
			}
		/>
	)
}

const ServerPage: React.FC = () => {
	React.useEffect(() => {
		document.location.reload()
	}, [])
	return null
}

const Observer = withRouter((props) => {
	const { history } = props
	const { setOffline, setOldBuild, user } = useSession()
	const { pathname } = useLocation()
	const { open, disabled, pushLocation, addEventListener, removeEventListener } = useStream()

	const [lastPath, setLastPath] = React.useState<string>("")
	const [unauthenticated, setUnauthenticated] = React.useState<boolean>(false)

	React.useEffect(() => {
		const checkBuild = setInterval(
			async () => {
				const resp = await GetBuildDetails()
				if (!resp.ok) {
					return
				}
				setOldBuild(resp.result["build"].commit !== config.buildCommit)
			},
			5 * 60 * 1000,
		) // 5 minutes.
		return () => clearInterval(checkBuild)
	}, [config])

	React.useEffect(() => {
		if (user) {
			setOffline(!open && !disabled)
		}
		Cache.extendedTTL(!_.isNil(user) && open && !disabled)
	}, [user, open, disabled])

	React.useEffect(() => {
		if (!open) {
			return
		}
		const handler = () => {
			setUnauthenticated(true)
		}
		addEventListener("Unauthenticated", handler)
		return () => {
			removeEventListener("Unauthenticated", handler)
		}
	}, [open])

	React.useEffect(() => {
		if (!open) {
			return
		}
		const handler = (message: StreamMessage) => {
			Cache.unset(_.get(message, "e", ""))
		}
		addEventListener("Action", handler)
		return () => {
			removeEventListener("Action", handler)
		}
	}, [open])

	React.useEffect(() => {
		if (unauthenticated) {
			document.location.reload()
		}
	}, [unauthenticated])

	React.useEffect(() => {
		if (!open) {
			return
		}

		let pingTimeout: number | undefined

		const schedulePing = () => {
			clearTimeout(pingTimeout)
			pingTimeout = _.delay(async () => {
				pushLocation(pathname, "")
			}, 10000)
		}

		if (pathname !== lastPath || lastPath === "") {
			pushLocation(pathname, lastPath)
			setLastPath(pathname)
		}

		schedulePing()

		return () => {
			clearTimeout(pingTimeout)
		}
	}, [pathname, user, open])

	React.useEffect(() => {
		const unlisten = history.listen(() => {
			window.scrollTo(0, 0)
		})
		return () => {
			unlisten()
		}
	}, [])

	return unauthenticated ? <Redirect to={"/login"} /> : null
})

const makeRoutes = (route: string, pages: { [subpath: string]: React.FC }) => {
	return _.map(pages, (page: React.FC, subpath: string) => {
		const segments = ["", route]
		if (!_.isEmpty(subpath)) {
			segments.push(subpath)
		}
		const path = segments.join("/")
		return <PrivateRoute key={path} exact={true} path={path} component={page} />
	})
}

const routes = (
	<Switch>
		{_.map(redirects, (to, from) => (
			<PublicRoute
				key={to}
				exact={true}
				path={from}
				component={() => {
					const id = _.get(useParams(), "id", "")
					return <Redirect to={to.replace("/:id", "/" + id)} />
				}}
			/>
		))}
		<PublicRoute exact={true} path="/login" component={LoginPage} />
		<PublicRoute exact={true} path="/login/:id/:token" component={LoginByTokenPage} />
		<PublicRoute exact={true} path="/logout" component={LogoutPage} />

		<PublicRoute exact={true} path="/f/:docType/:file" component={ServerPage} />
		<PublicRoute exact={true} path="/i/:docType/:identifier" component={ServerPage} />
		<PublicRoute exact={true} path="/d/:identifier" component={ServerPage} />
		<PublicRoute exact={true} path="/t/:identifier" component={TagShowPublicPage} />
		<PublicRoute exact={true} path="/pt/:trackingCode" component={TrackingCodeShowPublicPage} />

		<PrivateRoute exact={true} path="/" component={DashboardPage} />
		<PrivateRoute exact={true} path="/finance" component={FinancePage} />
		<PrivateRoute exact={true} path="/inventory" component={InventoryListPage} />
		<PrivateRoute exact={true} path="/production" component={ProductionPage} />
		<PrivateRoute exact={true} path="/scan" component={ScanPage} />

		{makeRoutes("account", {
			edit: AccountEditPage,
			password: AccountPasswordPage,
			pin: AccountPINPage,
		})}

		{makeRoutes("tracking-sheets", {
			new: TrackingSheetNewPage,
		})}

		{makeRoutes("assemblies", {
			"": EntityListPage(
				EntityType.Assembly,
				<>
					<Button label="Import assembly" className="z-20" add to={urlTo("assemblies/import")} />
					<DropDownButton
						label="New assembly"
						className="z-20"
						add
						subButtons={[
							{ label: "W1 Cage", to: "assemblies/w1-cage/new" },
							{ label: "W2 Grid", to: "assemblies/w2-grid/new" },
						]}
					/>
				</>,
			),
			"new": AssemblyNewPage,
			"import": AssemblyImportPage,
			":id": AssemblyShowPage,
			":id/edit": AssemblyEditPage,
			":id/logs": RelationListPage(EntityType.Assembly, EntityType.EventLog),
			":id/tags": RelationListPage(EntityType.Assembly, EntityType.Tag),
			":id/files": RelationListPage(EntityType.Assembly, EntityType.File),
			":id/work-cell-programs": RelationListPage(EntityType.Assembly, EntityType.WorkCellProgram),
			"w1-cage/new": AssemblyNewPage,
			"w2-grid/new": AssemblyNewPage,
		})}

		{makeRoutes("contacts", {
			"": EntityListPage(
				EntityType.Contact,
				<Button add={true} label={"New contact"} to={urlTo("contacts/new")} />,
			),
			"new": ContactNewPage,
			":id": ContactShowPage,
			":id/edit": ContactEditPage,
			":id/logs": RelationListPage(EntityType.Contact, EntityType.EventLog),
		})}

		{makeRoutes("customers", {
			"": EntityListPage(
				EntityType.Customer,
				<Button add={true} label={"New customer"} to={urlTo("customers/new")} />,
			),
			"new": CustomerNewPage,
			":id": CustomerShowPage,
			":id/edit": CustomerEditPage,
			":id/assemblies": RelationListPage(EntityType.Customer, EntityType.Assembly),
			":id/contacts": RelationListPage(EntityType.Customer, EntityType.Contact),
			":id/documents": RelationListPage(EntityType.Customer, EntityType.Document),
			":id/jobs": RelationListPage(EntityType.Customer, EntityType.Job),
			":id/logs": RelationListPage(EntityType.Customer, EntityType.EventLog),
			":id/materials": RelationListPage(EntityType.Customer, EntityType.Material),
			":id/releases": RelationListPage(EntityType.Customer, EntityType.Release),
			":id/shipments": RelationListPage(EntityType.Customer, EntityType.Shipment),
			":id/tags": RelationListPage(EntityType.Customer, EntityType.Tag),
		})}

		{makeRoutes("documents", {
			"": EntityListPage(EntityType.Document),
			"new": DocumentNewPage,
			":id": DocumentShowPage,
			":id/edit": DocumentEditPage,
			":id/logs": RelationListPage(EntityType.Document, EntityType.EventLog),
		})}

		{makeRoutes("files", {
			"": EntityListPage(EntityType.File),
			":id": FileShowPage,
		})}

		{makeRoutes("jobs", {
			"": EntityListPage(
				EntityType.Job,
				<Button add={true} label={"New job"} to={urlTo("jobs/new")} />,
			),
			"new": JobNewPage,
			":id": JobShowPage,
			":id/edit": JobEditPage,
			":id/assemblies": RelationListPage(EntityType.Job, EntityType.Assembly),
			":id/contacts": RelationListPage(EntityType.Job, EntityType.Contact),
			":id/documents": RelationListPage(EntityType.Job, EntityType.Document),
			":id/logs": RelationListPage(EntityType.Job, EntityType.EventLog),
			":id/materials": RelationListPage(EntityType.Job, EntityType.Material),
			":id/releases": RelationListPage(EntityType.Job, EntityType.Release),
			":id/shipments": RelationListPage(EntityType.Job, EntityType.Shipment),
			":id/tags": RelationListPage(EntityType.Job, EntityType.Tag),
			":id/work-cell-programs": RelationListPage(EntityType.Job, EntityType.WorkCellProgram),
		})}

		{makeRoutes("logs", {
			":id": EventLogShowPage,
		})}

		{makeRoutes("materials", {
			"": EntityListPage(EntityType.Material),
			"new": MaterialNewPage,
			":id": MaterialShowPage,
			":id/files": RelationListPage(EntityType.Material, EntityType.File),
			":id/edit": MaterialEditPage,
			":id/edit/items": MaterialEditPage,
			":id/items": MaterialShowPage,
			":id/logs": RelationListPage(EntityType.Material, EntityType.EventLog),
			":id/shipments": RelationListPage(EntityType.Material, EntityType.Shipment),
			":id/tags": RelationListPage(EntityType.Material, EntityType.Tag),
		})}

		{makeRoutes("releases", {
			"": EntityListPage(EntityType.Release),
			"new": ReleaseNewPage,
			":id": ReleaseShowPage,
			":id/contacts": RelationListPage(EntityType.Release, EntityType.Contact),
			":id/documents": RelationListPage(EntityType.Release, EntityType.Document),
			":id/edit": ReleaseEditPage,
			":id/edit/generate-tags": ReleaseEditPage,
			":id/edit/items": ReleaseEditPage,
			":id/items": ReleaseShowPage,
			":id/logs": RelationListPage(EntityType.Release, EntityType.EventLog),
			":id/shipments": RelationListPage(EntityType.Release, EntityType.Shipment),
			":id/release-units": RelationListPage(EntityType.Release, EntityType.ReleaseUnit),
			":id/release-units/create": ReleaseUnitCreatePage,
			":id/tags/create": TagCreatePage,
			":id/tags": RelationListPage(EntityType.Release, EntityType.Tag),
		})}

		{makeRoutes("reports", {
			"": ReportsCalendarPage,
			"work-cell-run-reports": ReportsWorkCellRunReports,
			"work-cell-sessions": ReportsWorkCellSessionsPage,
			"work-cell-statuses": ReportsWorkCellStatusesPage,
			"work-tracking-calendar": ReportsWorkTrackingCalendarPage,
			"work-tracking-table": ReportsWorkTrackingTablePage,
		})}

		{makeRoutes("release-units", {
			"": EntityListPage(EntityType.ReleaseUnit),
			":id": ReleaseUnitShowPage,
			":id/edit": ReleaseUnitEditPage,
			":id/logs": RelationListPage(EntityType.ReleaseUnit, EntityType.EventLog),
		})}

		{makeRoutes("settings", {
			"": EntityListPage(
				EntityType.Setting,
				<Button add={true} label={"New setting"} to={urlTo("settings/new")} />,
			),
			"new": SettingNewPage,
			":id": SettingShowPage,
			":id/edit": SettingEditPage,
			":id/edit/items": SettingEditPage,
			":id/items": SettingShowPage,
			":id/logs": RelationListPage(EntityType.Setting, EntityType.EventLog),
			":id/tags": RelationListPage(EntityType.Setting, EntityType.Tag),
		})}

		{makeRoutes("shipments", {
			"": EntityListPage(EntityType.Shipment),
			"new": ShipmentNewPage,
			":id": ShipmentShowPage,
			":id/contacts": RelationListPage(EntityType.Shipment, EntityType.Contact),
			":id/documents": RelationListPage(EntityType.Shipment, EntityType.Document),
			":id/edit": ShipmentEditPage,
			":id/edit/items": ShipmentEditPage,
			":id/items": ShipmentShowPage,
			":id/logs": RelationListPage(EntityType.Shipment, EntityType.EventLog),
			":id/tags": RelationListPage(EntityType.Shipment, EntityType.Tag),
		})}

		{makeRoutes("tags", {
			"": EntityListPage(EntityType.Tag),
			":id": TagShowPage,
			":id/print": TagPrintPage,
			":id/edit": TagEditPage,
			":id/logs": RelationListPage(EntityType.Tag, EntityType.EventLog),
			":id/release-units": RelationListPage(EntityType.Tag, EntityType.ReleaseUnit),
		})}

		{makeRoutes("trash", {
			"": EntityListPage(EntityType.TrashItem),
			":id": TrashShowPage,
		})}

		{makeRoutes("users", {
			"": EntityListPage(
				EntityType.User,
				<Button add={true} label={"New user"} to={urlTo("users/new")} />,
			),
			"new": UserNewPage,
			":id": UserShowPage,
			":id/edit": UserEditPage,
			":id/logs": RelationListPage(EntityType.User, EntityType.EventLog),
			":id/new-pin": UserNewPINPage,
		})}

		{makeRoutes("work-devices", {
			"": EntityListPage(
				EntityType.WorkDevice,
				<Button add={true} label={"New device"} to={urlTo("worker-devices/new")} />,
			),
			"new": WorkDeviceNewPage,
			":id": WorkDeviceShowPage,
			":id/edit": WorkDeviceEditPage,
			":id/logs": RelationListPage(EntityType.WorkDevice, EntityType.EventLog),
		})}

		{makeRoutes("workers", {
			"": EntityListPage(
				EntityType.Worker,
				<Button add={true} label={"New worker"} to={urlTo("worker/new")} />,
			),
			"new": WorkerNewPage,
			":id": WorkerShowPage,
			":id/edit": WorkerEditPage,
			":id/logs": RelationListPage(EntityType.Worker, EntityType.EventLog),
		})}

		{makeRoutes("work-actions", {
			"": EntityListPage(
				EntityType.WorkAction,
				<Button add={true} label={"New work action"} to={urlTo("work-actions/new")} />,
			),
			"new": WorkActionNewPage,
			":id": WorkActionShowPage,
			":id/edit": WorkActionEditPage,
			":id/logs": RelationListPage(EntityType.WorkAction, EntityType.EventLog),
		})}

		{makeRoutes("work-cells", {
			"": EntityListPage(EntityType.WorkCell),
			"new": WorkCellNewPage,
			":id": WorkCellShowPage,
			":id/edit": WorkCellEditPage,
			":id/logs": RelationListPage(EntityType.WorkCell, EntityType.EventLog),
			":id/work-events": RelationListPage(EntityType.WorkCell, EntityType.WorkEvent),
			":id/work-resources": RelationListPage(EntityType.WorkCell, EntityType.WorkResource),
			":id/run-reports": RelationListPage(EntityType.WorkCell, EntityType.WorkCellRunReport),
			":id/work-cell-servers": RelationListPage(EntityType.WorkCell, EntityType.WorkCellServer),
		})}

		{makeRoutes("work-cell-programs", {
			"": EntityListPage(EntityType.WorkCellProgram),
			"new": WorkCellProgramNewPage,
			":id": WorkCellProgramShowPage,
			":id/programs/:programID": WorkCellProgramProgramsPage,
			":id/programs": WorkCellProgramProgramsPage,
			":id/feedback": WorkCellProgramFeedbackPage,
			":id/work-events": RelationListPage(EntityType.WorkCellProgram, EntityType.WorkEvent),
			":id/path-visualizer": WorkCellProgramPathVisualizerPage,
			":id/path-visualizer/:programID": WorkCellProgramPathVisualizerPage,
			":id/edit": WorkCellProgramEditPage,
			":id/logs": RelationListPage(EntityType.WorkCellProgram, EntityType.EventLog),
		})}

		{makeRoutes("work-cell-servers", {
			"": EntityListPage(EntityType.WorkCellServer),
			"new": WorkCellServerNewPage,
			":id": WorkCellServerShowPage,
			":id/edit": WorkCellServerEditPage,
			":id/logs": RelationListPage(EntityType.WorkCellServer, EntityType.EventLog),
		})}

		{makeRoutes("work-events", {
			"": EntityListPage(
				EntityType.WorkEvent,
				<Button add={true} label={"New event"} to={urlTo("work-events/new")} />,
			),
			"new": WorkEventNewPage,
			":id": WorkEventShowPage,
			":id/edit": WorkEventEditPage,
			":id/logs": RelationListPage(EntityType.WorkEvent, EntityType.EventLog),
		})}

		{makeRoutes("work-facilities", {
			"": EntityListPage(
				EntityType.WorkFacility,
				<Button add={true} label={"New facility"} to={urlTo("work-facilities/new")} />,
			),
			"new": WorkFacilityNewPage,
			":id": WorkFacilityShowPage,
			":id/contacts": RelationListPage(EntityType.WorkFacility, EntityType.Contact),
			":id/edit": WorkFacilityEditPage,
			":id/logs": RelationListPage(EntityType.WorkFacility, EntityType.EventLog),
			":id/releases": RelationListPage(EntityType.WorkFacility, EntityType.Release),
			":id/jobs": RelationListPage(EntityType.WorkFacility, EntityType.Job),
			":id/shipments": RelationListPage(EntityType.WorkFacility, EntityType.Shipment),
			":id/work-events": RelationListPage(EntityType.WorkFacility, EntityType.WorkEvent),
			":id/work-cells": RelationListPage(EntityType.WorkFacility, EntityType.WorkCell),
			":id/work-devices": RelationListPage(EntityType.WorkFacility, EntityType.WorkDevice),
			":id/work-cell-servers": RelationListPage(EntityType.WorkFacility, EntityType.WorkCellServer),
			":id/work-resources": RelationListPage(EntityType.WorkFacility, EntityType.WorkResource),
		})}

		<PublicRoute
			exact={true}
			path="/work-facilities/:id/dashboard/:tenantID"
			component={ProductionDashboardPage}
		/>

		{makeRoutes("work-resources", {
			"": EntityListPage(EntityType.WorkResource),
			"new": WorkResourceNewPage,
			":id": WorkResourceShowPage,
			":id/work-events": RelationListPage(EntityType.WorkResource, EntityType.WorkEvent),
			":id/edit": WorkResourceEditPage,
			":id/logs": RelationListPage(EntityType.WorkResource, EntityType.EventLog),
		})}

		{makeRoutes("worker-badge", {
			new: WorkerBadgeNewPage,
		})}

		<PublicRoute>
			<HTTPErrorPage code={404} error="Page not found" />
		</PublicRoute>
	</Switch>
)

export const Router: React.FC = () => {
	return (
		<BrowserRouter>
			<ErrorProvider>
				<ModalsProvider>
					<Observer />
					<Helmet>
						<title>Toggle OS</title>
					</Helmet>
					{routes}
				</ModalsProvider>
			</ErrorProvider>
		</BrowserRouter>
	)
}
