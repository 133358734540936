import _ from "lodash"

import type { Address, Email, Contactable, PhoneNumber } from "@app/domain"

export type Contact = {
	id: string

	addresses: Address[]
	emails: Email[]
	firstName: string
	lastName: string
	phoneNumbers: PhoneNumber[]
	title: string

	contactables: Contactable[]

	created: string
	updated: string
}

export const newContact = (): Contact => {
	return {
		id: "",

		addresses: [],
		emails: [],
		firstName: "",
		lastName: "",
		phoneNumbers: [],
		title: "",

		contactables: [],

		created: "",
		updated: "",
	}
}

export const ContactUtils = {
	name: (v?: Contact): string => {
		if (v) {
			return _.trim([v.lastName, v.firstName].join(", "), ", ")
		}
		return ""
	},
}
