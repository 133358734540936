import React from "react"
import _ from "lodash"
import { Channel, createChannel, createClient } from "nice-grpc-web"

import {
	createBaseGridRequest,
	createBaseCageRequest,
	GridRequest,
	CageRequest,
	VisualizationClient,
	VisualizationDefinition,
	TaskClient,
	TaskDefinition,
} from "@app/grpc/visualization"
import { FormClient, FormDefinition, Workspaces } from "@app/grpc/form"

import { SaveAssemblyParams } from "@app/api"
import {
	BreadcrumbDelimiter,
	Breadcrumbs,
	Button,
	Fieldset,
	Input,
	JobSelect,
	Link,
	Tabs,
	Toggle,
} from "@app/components"
import { useSession } from "@app/contexts"
import { AssemblyType, newAssembly } from "@app/domain"
import { useConfiguratorForm } from "@app/hooks"
import Config from "@app/config"

interface IAssemblyFormProps {
	assemblyType: AssemblyType
	assemblyParams: SaveAssemblyParams
	onSave: (params: SaveAssemblyParams, stpFileMaskName: string) => void
	stpFileMaskName?: string
	errors: { [key: string]: string }
	loading?: boolean
	referenceID?: string
	maxHeight?: string
}

export const AssemblyForm: React.FC<IAssemblyFormProps> = (props) => {
	const {
		assemblyParams: assemblyParamsIn,
		assemblyType,
		stpFileMaskName: stpFileMaskNameProp,
		errors: errorsProp,
		loading: busy = false,
		maxHeight,
		onSave,
	} = props
	const { t } = useSession()
	const isNew = _.isEmpty(assemblyParamsIn.id)

	const [channel] = React.useState<Channel>(createChannel(Config.internalConfiguratorGRPCWebURL))
	const visualizationClient: VisualizationClient = createClient(VisualizationDefinition, channel)
	const taskClient: TaskClient = createClient(TaskDefinition, channel)
	const formClient: FormClient = createClient(FormDefinition, channel)

	const [workspace, setWorkspace] = React.useState(Workspaces.DESIGN)
	const [metric, setMetric] = React.useState(isNew ? true : assemblyParamsIn.metric)
	const [weight, setWeight] = React.useState(isNew ? 0 : assemblyParamsIn?.weight ?? 0)
	const [name, setName] = React.useState<string>(assemblyParamsIn.name || assemblyType)
	const [jobID, setJobID] = React.useState<string>(assemblyParamsIn.jobID || "")
	const [viewCollisionObjects, setViewCollisionObjects] = React.useState(false)
	const [connectionEditing, setConnectionEditing] = React.useState(false)
	const [requestParams, setRequestParams] = React.useState<GridRequest | CageRequest>(
		assemblyType === AssemblyType.W2Grid
			? _.assign(
					createBaseGridRequest(),
					assemblyParamsIn?.configuratorParams?.["W2-Grid"] ?? {
						metric,
						workspace,
					},
			  )
			: _.assign(
					createBaseCageRequest(),
					assemblyParamsIn?.configuratorParams?.["W1-Cage"] ?? {
						metric,
						workspace,
					},
			  ),
	)
	const [viewURDF, setViewURDF] = React.useState(requestParams.view_robot)
	const [stpFileMaskName, setSTPFileMaskName] = React.useState(stpFileMaskNameProp ?? "")
	const [prevRequestParams, setPrevRequestParams] = React.useState<GridRequest | CageRequest>(
		requestParams,
	) // Parameters that generated the current view. Used to know whether to update view.

	const {
		gltfViewer,
		form,
		initialized,
		renderInput,
		viewUnits,
		loading,
		setViewUnits,
		info,
		onBlur,
		errors,
		hiddenByDependency,
	} = useConfiguratorForm({
		urdfURL:
			assemblyType === AssemblyType.W2Grid
				? "/assets/robots/w2/urdf/w2.urdf"
				: "/assets/robots/abb_irb6700_220_265_leanid/urdf/abb_irb6700_220_265_leanid.urdf",
		requestParams,
		setRequestParams,
		prevRequestParams,
		setPrevRequestParams,
		busy: busy || connectionEditing,
		useDefaultParams: _.isEmpty(assemblyParamsIn.id),
		getForm: assemblyType === AssemblyType.W2Grid ? formClient.grid : formClient.cage,
		getHashes:
			assemblyType === AssemblyType.W2Grid
				? visualizationClient.gridHashes
				: visualizationClient.cageHashes,
		getLayers:
			assemblyType === AssemblyType.W2Grid ? visualizationClient.grid : visualizationClient.cage,
		getTask: taskClient.task,
		setViewCollisionObjects,
		viewCollisionObjects,
		connectionEditing,
		simulate: prevRequestParams?.["workspace"] === Workspaces.SIMULATION,
		viewURDF,
		metric,
		setWeight,
	})

	React.useEffect(() => {
		setViewURDF(workspace !== Workspaces.DESIGN)
		setRequestParams((prev) => ({
			...prev,
			view_robot: workspace === Workspaces.SIMULATION,
			workspace,
		}))
		onBlur()
	}, [workspace])

	const onInputChange = (inputName: string, value: string | boolean | number | File) => {
		switch (inputName) {
			case "view_robot":
				setViewURDF(value as boolean)
				break
			case "mask_stp":
				setSTPFileMaskName((value as File).name)
				break
			case "mask_id":
				if (value !== "import_step" && (requestParams as GridRequest)?.mask_stp?.length > 0) {
					setRequestParams((prev) => ({
						...prev,
						mask_stp: new Uint8Array(),
					}))
				}
				break
		}
	}

	const stpFileMaskInput = document.querySelector(
		'input[type="file"]#assembly-mask_stp',
	) as HTMLInputElement
	React.useEffect(() => {
		if (
			!stpFileMaskInput ||
			!stpFileMaskNameProp ||
			!assemblyParamsIn.configuratorParams?.["W2-Grid"] ||
			!("mask_stp" in assemblyParamsIn.configuratorParams["W2-Grid"])
		) {
			return
		}
		const file = new File(
			new Array(assemblyParamsIn.configuratorParams?.["W2-Grid"].mask_stp),
			stpFileMaskName,
		)
		const dataTransfer = new DataTransfer()
		dataTransfer.items.add(file)
		stpFileMaskInput.files = dataTransfer.files

		// Safari workaround to display file name.
		if (stpFileMaskInput.webkitEntries.length) {
			stpFileMaskInput.dataset.file = stpFileMaskName
		}
	}, [stpFileMaskInput])

	return (
		<div
			className="grid grid-rows-[100px_1fr] md:grid-rows-[1fr] md:grid-cols-[24rem_1fr] w-full"
			style={{
				maxHeight: maxHeight ?? "calc(100vh - 65px)", // 65px is the height of the searchbox.
			}}
		>
			<div className="w-full border-gray-400 border-r overflow-auto pt-6 px-6 h-full md:w-96">
				{isNew && assemblyType ? (
					<Breadcrumbs>
						<Link to="/assemblies">{t("assembly.titles.list")}</Link>
						<BreadcrumbDelimiter />
						{t("common.tabs.new")} {t(`assemblyTypes.${assemblyType}`)}
					</Breadcrumbs>
				) : null}
				<div className="md:hidden relative flex flex-row md:flex-col flex-1 w-full h-1/2 overflow-hidden mb-4">
					{gltfViewer}
				</div>
				<Tabs
					className="mb-4"
					tabs={[Workspaces.DESIGN, Workspaces.FABRICATION, Workspaces.SIMULATION].map((ws) => ({
						label: t(`assembly.configurator.workspace_${ws}`),
						active: workspace === ws,
						onOptionClick: () => {
							setWorkspace(ws)
						},
						disabled: busy || !initialized || loading || connectionEditing,
					}))}
				/>
				{workspace === Workspaces.DESIGN && (
					<Fieldset defaultOpen={true} className="mt-1 mb-3 pb-1" label="Primary">
						<JobSelect
							autoFocus={jobID === ""}
							defaultValue={jobID}
							error={_.get(errorsProp, "jobID")}
							label={t("release.labels.jobID")}
							name="jobID"
							onChange={(v) => setJobID(v[0].value)}
						/>
						<Input
							defaultValue={name}
							autoComplete="off"
							error={_.get(errorsProp, "name")}
							label={t("assembly.labels.name")}
							name="name"
							onChange={(e) => setName(e.currentTarget.value)}
							type="text"
						/>
						<Toggle
							defaultChecked={metric}
							disabled={busy || !initialized || loading || connectionEditing}
							fieldInfo="assembly-metric"
							label="Metric"
							onChange={(e) => {
								setMetric(e)
								setRequestParams((prev) => ({ ...prev, metric: e }))
							}}
						/>
					</Fieldset>
				)}
				{form &&
					initialized &&
					form.groups
						.filter((group) => group.workspaces.includes(workspace))
						.filter((group) => !hiddenByDependency(group.dependencies, true))
						.map((group, i) => (
							<Fieldset
								key={i + group.label}
								defaultOpen={!group.collapsed}
								className="mt-1 mb-3 pb-1"
								label={group.label}
							>
								{group.inputs.map((input, j) => (
									<React.Fragment key={j + input.name}>
										{renderInput(input, j, onInputChange)}
										{input.name === "view_assembly_dimensions" &&
											requestParams["view_assembly_dimensions"] &&
											workspace !== Workspaces.SIMULATION && (
												<Toggle
													defaultChecked={viewUnits}
													disabled={busy || !initialized || loading || connectionEditing}
													fieldInfo="assembly-view-dimension-units"
													label="View dimension units"
													onChange={(e) => setViewUnits(e)}
												/>
											)}
									</React.Fragment>
								))}
								{workspace === Workspaces.SIMULATION && group.label === "View" && (
									<Toggle
										defaultChecked={false}
										value={viewCollisionObjects}
										disabled={busy || !initialized || loading || connectionEditing}
										fieldInfo="assembly-view-collision-objects"
										label="View simulation collision objects"
										onChange={(e) => setViewCollisionObjects(e)}
									/>
								)}
								{workspace === Workspaces.DESIGN && group.label === "Connections" && (
									<Toggle
										defaultChecked={false}
										value={connectionEditing}
										disabled={busy || !initialized || loading}
										fieldInfo="assembly-connection-editing"
										label="Enable connection editing"
										onChange={(e) => {
											setConnectionEditing(e)
											if (!e) {
												// Only update when done editing. No changes exist when enabling the bool.
												onBlur()
											}
										}}
									/>
								)}
							</Fieldset>
						))}
				{info && (
					<Fieldset defaultOpen={true} className="mt-1 mb-3 pb-1" label="Info">
						<p className="mb-3 whitespace-pre-line">{info}</p>
					</Fieldset>
				)}
				<Button
					type="submit"
					className="mt-4"
					label={t("assembly.buttons.save")}
					errors={errorsProp}
					disabled={!_.isEmpty(errorsProp)}
					busy={busy || connectionEditing}
					onClick={() => {
						onSave(
							_.assign(newAssembly(), {
								assemblyType,
								configuratorParams: { [assemblyType]: requestParams },
								configuratorVersion: form?.version,
								id: assemblyParamsIn.id || "",
								weight,
								jobID,
								metric,
								name,
								errors,
							}),
							stpFileMaskName,
						)
					}}
				/>
				{form && (
					<div className="text-center text-xs text-gray-500 py-2 mb-6 bg-white">
						{`${assemblyType} Configurator v${form?.version}`}
					</div>
				)}
			</div>
			<div className="hidden md:block relative w-full h-full">{gltfViewer}</div>
		</div>
	)
}
