import React from "react"

interface ILabelProps {
	name?: string
	fieldInfo?: string
	className?: string
	children: React.ReactNode
}

export const Label: React.FC<ILabelProps> = (props) => {
	const { children, className = "", name } = props

	return (
		<>
			<label htmlFor={name} className={`text-md text-gray-700 ${className}`}>
				{children}
			</label>
		</>
	)
}
