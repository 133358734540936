import React from "react"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import clsx from "clsx"

import { useSession } from "@app/contexts"
import { Link, Icon } from "@app/components"
import { formatTimezone, getBrowserTimezoneOffset, urlTo } from "@app/util"
import { Entity, EntityColumn, EntityColumnType } from "@app/domain"

import type { EntityTableChildType } from "./EntityTable"

const filterKeyCols = (cols: EntityColumn[]) =>
	cols.filter(
		(c: EntityColumn) =>
			![
				EntityColumnType.PrimaryKey,
				EntityColumnType.ForeignKey,
				EntityColumnType.ForeignKeyArray,
			].includes(c.type),
	)

export const EntityColumnarTable: React.FC<EntityTableChildType> = (
	props: EntityTableChildType,
) => {
	const history = useHistory()
	const {
		columns,
		entities,
		entityType,
		fullHeight,
		fullWidth,
		params,
		pendingFiles = false,
		renderEntityColumn,
		selectable,
		setColumns,
		setParams,
		onSelectionChange,
		unclickable = false,
		timezone,
	} = props

	const { t } = useSession()
	const groupColumn = _.find(columns, (c) => c.options.group)
	const grouped = _.groupBy(entities, groupColumn?.name) as { [key: string]: Entity[] }
	const groups = _.keys(grouped).sort()

	const [groupSelected, setGroupSelected] = React.useState({})
	const [selectAll, setSelectAll] = React.useState(false)
	const [selected, setSelected] = React.useState({})
	const timezoneString =
		getBrowserTimezoneOffset(timezone) === 0 ? "" : ` (${formatTimezone(timezone, true)})`

	React.useEffect(() => {
		setSelected({})
	}, [entities])

	React.useEffect(() => {
		if (onSelectionChange) {
			onSelectionChange(selected)
		}
	}, [selected])

	React.useEffect(() => {
		const groupCol = groupColumn?.name
		if (groupCol) {
			setParams((prev) => ({ ...prev, groupCol }))
		}
	}, [groupColumn?.name])

	const selectAllHandler = () => {
		const checked = !selectAll
		setSelectAll(checked)
		if (checked) {
			const next = _.reduce(
				entities,
				(acc, e) => {
					acc[e.id] = true
					return acc
				},
				{},
			)
			setSelected(next)
		} else {
			setSelected({})
		}
	}

	const selectGroupHandler = (group: string) => {
		return () => {
			const checked = !groupSelected[group]
			setGroupSelected((prev) => {
				const next = { ...prev }
				next[group] = checked
				return next
			})
			setSelected((prev) => {
				const next = { ...prev }
				_.each(entities, (entity) => {
					if (groupColumn && entity[groupColumn.name] === group) {
						if (checked) {
							next[entity.id] = checked
						} else {
							delete next[entity.id]
						}
					}
				})
				return next
			})
		}
	}

	const ellipses = filterKeyCols(columns).some((c) => c.options.hidden) ? (
		<Icon name="Ellipsis" className="p-0 text-gray-500" />
	) : (
		<div className="w-5">&nbsp;</div>
	)

	return (
		<div
			className={clsx(
				"z-0 w-auto relative overflow-auto scroll-shadows",
				fullWidth ? "w-full" : "w-fit",
				fullHeight ? "h-usable-md" : "h-usable-xs",
			)}
		>
			<table className="w-full border-collapse">
				<thead className="sticky bg-white z-20 top-0">
					<tr>
						{selectable && (
							<td className="pl-2">
								<input type="checkbox" checked={selectAll} onChange={selectAllHandler} />
							</td>
						)}
						{columns
							.filter((column) => !column.options.hidden)
							.map((column, i) => (
								<th
									key={i}
									className={clsx(
										[EntityColumnType.DownloadLink].includes(column.type)
											? "text-center"
											: "text-left",
										"pl-4 pr-6 h-8 transition-all min-w-[90px] duration-300",
									)}
								>
									<p
										className={clsx(
											"flex items-center",
											column.options.sortable && "cursor-pointer",
											column.name === params.sortCol ? "font-medium text-black" : "font-normal",
										)}
										onClick={() => {
											if (!column.options.sortable) {
												return
											}
											setParams((prev) => ({
												...prev,
												sortCol: column.name,
												sortDir: prev.sortCol !== column.name || prev.sortDir !== -1 ? -1 : 1,
												offset: 0,
											}))
										}}
									>
										{[EntityColumnType.Timestamp, EntityColumnType.Date].includes(column?.type)
											? column?.label + timezoneString
											: column?.label}
										{column.options.sortable ? (
											<Icon
												name={params.sortDir > 0 ? "ArrowUp" : "ArrowDown"}
												className={clsx(
													"ml-1 nohover-btn hidden md:inline",
													params.sortCol === column.name ? "text-gray-500" : "opacity-0",
												)}
											/>
										) : null}
									</p>
								</th>
							))}
						<th className="">
							<div
								id="visButton"
								className="cursor-pointer text-gray-600 bg-gray-300 h-8 w-8 rounded p-1 md:inline float-right hover:bg-gray-400"
							>
								<Icon size="sm" name="EyeSlash" />
							</div>
							<div
								id="visBox"
								className={`absolute right-0 mt-7 shadow-xl bg-white rounded-lg flex-col font-normal p-3 space-y-1`}
								style={{ display: "none" }}
							>
								{filterKeyCols(columns).map((column, i) => (
									<div
										key={i}
										className={clsx(
											"flex justify-between items-center",
											column.options.hidden && "line-through text-gray-500",
										)}
									>
										<span className="mr-10">{column.label}</span>
										<div>
											<Icon
												className={clsx(
													"cursor-pointer",
													column.options.hidden ? "text-gray-500" : "text-black",
												)}
												name="Eye"
												size="xs"
												onClick={() => {
													setColumns((prev) =>
														prev.map((c) => {
															const hidden =
																c.name === column.name ? !c.options.hidden : c.options.hidden
															return {
																...c,
																options: {
																	...c.options,
																	hidden,
																},
															}
														}),
													)
												}}
											/>
											{column.options.groupable ? (
												<Icon
													className={clsx(
														"cursor-pointer ml-2",
														column.options.group ? "text-black" : "text-gray-500",
													)}
													name="Group"
													onClick={() => {
														setColumns((prev) => {
															const value =
																groupColumn?.name === column.name ? !column.options.group : true
															return prev.map((c) => {
																const group = c.name === column.name ? value : false
																return {
																	...c,
																	options: {
																		...c.options,
																		group,
																	},
																}
															})
														})
													}}
												/>
											) : (
												<Icon className="ml-2 text-white" name="Blank" />
											)}
										</div>
									</div>
								))}
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					{pendingFiles && (
						<tr className="">
							<td className="w-3" colSpan={_.size(columns)}>
								<div className="flex place-content-center items-center text-center p-4 bg-gray-200">
									<p>{_.startCase(t("common.headers.filesPending"))}</p>
									<Icon
										name="Cog"
										size="lg"
										fixedWidth
										spin={true}
										className="ml-1 text-gray-600"
									/>
								</div>
							</td>
						</tr>
					)}
					{groups.map((group) => {
						const entity = {}
						if (groupColumn) {
							if (groupColumn.type === EntityColumnType.Boolean) {
								entity[groupColumn.name] = group === "true"
							} else if (
								groupColumn.type === EntityColumnType.Integer ||
								groupColumn.type === EntityColumnType.Float
							) {
								entity[groupColumn.name] = Number(group)
							} else {
								entity[groupColumn.name] = group
							}
						}
						return [
							groupColumn && (
								<tr
									key={`${group}-header`}
									className={clsx("sticky text-white bg-gray-700 z-10 top-8")}
								>
									{selectable && (
										<td className="pl-2">
											<input
												type="checkbox"
												checked={!!groupSelected[group]}
												onChange={selectGroupHandler(group)}
											/>
										</td>
									)}
									<td colSpan={_.size(columns)} className="pl-4 py-2">
										{renderEntityColumn(entity as Entity, groupColumn, 0, false)}
									</td>
								</tr>
							),
							grouped[group]?.map((entity, i) => (
								<tr
									key={`${group}-${i}`}
									onClick={() =>
										unclickable ? null : history.push(urlTo(entityType, entity as Entity))
									}
									className={clsx(
										"border-gray-300 border-b-[1px] duration-300 transition-all",
										!unclickable && "hover:bg-yellow-100 cursor-pointer",
									)}
								>
									{selectable && (
										<td className="pl-2">
											<input
												type="checkbox"
												checked={!!selected[entity.id]}
												onClick={(evt) => evt.stopPropagation()}
												onChange={() => {
													setSelected((prev) => {
														const next = { ...prev }
														const checked = !next[entity.id]
														if (checked) {
															next[entity.id] = true
														} else {
															delete next[entity.id]
														}
														return next
													})
												}}
											/>
										</td>
									)}
									{columns
										?.filter((column) => !column.options.hidden)
										.map((column, j) => renderEntityColumn(entity, column, j))}
									<td className="w-3 pl-[10px] text-gray-500 font-bold">
										{unclickable ? (
											<>{ellipses}</>
										) : (
											<Link to={urlTo(entityType, entity)} className="contents">
												{ellipses}
											</Link>
										)}
									</td>
								</tr>
							)),
						]
					})}
				</tbody>
			</table>
			{entities?.length === 0 && !pendingFiles && (
				<div className="text-center p-4 bg-gray-200">No results found</div>
			)}
		</div>
	)
}
