import _ from "lodash"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { Setting, Entity, Pagination } from "@app/domain"

export type SaveSettingParams = {
	id?: string

	name: string
	group: string
	value: string
}

export const newSaveSettingParams = (setting?: Setting): SaveSettingParams => {
	return {
		id: _.get(setting, "id", ""),

		group: _.get(setting, "group", ""),
		name: _.get(setting, "name", ""),
		value: _.get(setting, "value", ""),
	}
}

export type SaveSettingResult = {
	settings: Setting[]
}

export const SaveSetting = async (
	params: SaveSettingParams,
): Promise<Result<SaveSettingResult>> => {
	const data = await rpc("SaveSetting", params, { minDuration: 300 })
	return ReceivedResult<GetSettingsResult>(data)
}

export type GetSettingsParams = {
	ids?: string[]
	includeIDs?: string[]

	query?: string
	group?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetSettingsResult = {
	settings: Setting[]
	pagination?: Pagination
}

export const GetSettings = async (
	params: GetSettingsParams,
): Promise<Result<GetSettingsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetSettings", "settings", id).then((setting?: Entity) => {
			return {
				ok: true,
				result: { settings: _.compact([setting as Setting]) },
			} as Result<GetSettingsResult>
		}) as Promise<Result<GetSettingsResult>>
	}
	const data = await rpc("GetSettings", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetSettingsResult>(data)
}
