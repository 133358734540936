import { rpc } from "@app/api"

import type { Result } from "@app/api"
import type { UserSession } from "@app/domain"

export type LoginParams = {
	email: string
	password: string
}

export type LoginResult = {
	session: UserSession
}

export const Login = async (params: LoginParams): Promise<Result<LoginResult>> => {
	const data = await rpc("Login", params, { minDuration: 300 })
	return data as Result<LoginResult>
}

export const Logout = async (): Promise<Result<unknown>> => {
	const data = await rpc("Logout", {}, { minDuration: 1500 })
	return data as Result<unknown>
}

export type WhoAmIResult = {
	session: UserSession
}

export const WhoAmI = async (): Promise<Result<WhoAmIResult>> => {
	const data = await rpc("WhoAmI")
	return data as Result<WhoAmIResult>
}

export type LoginByEmailParams = {
	email: string
}

export type LoginByEmailResult = Record<string, never>

export const LoginByEmail = async (
	params: LoginByEmailParams,
): Promise<Result<LoginByEmailResult>> => {
	const data = await rpc("LoginByEmail", params, { minDuration: 300 })
	return data as Result<LoginByEmailResult>
}

export type LoginByTokenParams = {
	id: string
	token: string
}

export const LoginByToken = async (params: LoginByTokenParams): Promise<Result<LoginResult>> => {
	const data = await rpc("LoginByToken", params)
	return data as Result<LoginResult>
}

export type SwitchTenantParams = {
	id: number
}

export type SwitchTenantResult = {
	session: UserSession
}

export const SwitchTenant = async (
	params: SwitchTenantParams,
): Promise<Result<SwitchTenantResult>> => {
	const data = await rpc("SwitchTenant", params)
	return data as Result<SwitchTenantResult>
}
