import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import { EntityType, Contactable } from "@app/domain"

interface IContactableDetailsProps {
	contactable?: Contactable
}

export const ContactableDetails: React.FC<IContactableDetailsProps> = (props) => {
	const { t } = useSession()

	const { contactable } = props

	if (_.isNil(contactable)) {
		return null
	}

	let relation
	const relationLabel = _.camelCase(contactable.contactableType)
	switch (contactable.contactableType) {
		case "Customer":
			relation = EntityType.Customer
			break
		case "Job":
			relation = EntityType.Job
			break
		case "Release":
			relation = EntityType.Release
			break
		case "Shipment":
			relation = EntityType.Shipment
			break
		case "WorkFacility":
			relation = EntityType.WorkFacility
			break
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			{relation ? (
				<Property
					label={t(`entity.${relationLabel}`)}
					relation={relation}
					value={contactable.contactableID}
				/>
			) : null}
			<Property
				label={t("contactable.labels.contactID")}
				relation={EntityType.Contact}
				value={contactable.contactID}
			/>
			<Property
				label={t("contactable.labels.labels")}
				value={_.get(contactable, "labels", []).sort().join(", ")}
			/>
		</div>
	)
}
