import _ from "lodash"
import { v4 as uuidv4 } from "uuid"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { Material, Pagination, Entity } from "@app/domain"

export type GenerateMaterialFromJobParams = {
	jobID: string
}

export const GenerateMaterialFromJob = async (
	params: GenerateMaterialFromJobParams,
): Promise<Result<SaveMaterialResult>> => {
	const data = await rpc("GenerateMaterialFromJob", params)
	return ReceivedResult<GetMaterialsResult>(data)
}

export type SaveMaterialParams = {
	id?: string
	customerID: string
	jobID: string
	workFacilityID: string

	controlCode: string
	items: MaterialItemParams[]
	metric: boolean
	received: string | null
	status: string
	supplierName: string
	supplierReferenceID: string
}

export const newSaveMaterialParams = (material?: Material): SaveMaterialParams => {
	return {
		id: _.get(material, "id", ""),
		customerID: _.get(material, "customerID", ""),
		jobID: _.get(material, "jobID", ""),
		workFacilityID: _.get(material, "workFacilityID", ""),

		controlCode: _.get(material, "controlCode", ""),
		items: _.get(material, "items", []),
		metric: _.get(material, "metric", false),
		received: _.get(material, "received", null),
		status: _.get(material, "status", "Available"),
		supplierName: _.get(material, "supplierName", ""),
		supplierReferenceID: _.get(material, "supplierReferenceID", ""),
	}
}

export type SaveMaterialResult = {
	materials: Material[]
}

export type MaterialItemParams = {
	id: string
	rebarMaterialItem: RebarMaterialItemParams | null | undefined
}

export const newMaterialItemParams = (): MaterialItemParams => {
	return {
		id: uuidv4(),
		rebarMaterialItem: newRebarMaterialItemParams(),
	}
}

export type RebarMaterialItemParams = {
	length: string
	mark: string
	quantity?: number | null
	shape: string
	size?: number | null
	weight?: number | null

	A: string
	B: string
	C: string
	D: string
	E: string
	F: string
	G: string
	H: string
	J: string
	K: string
	O: string
	R: string
}

export const newRebarMaterialItemParams = (): RebarMaterialItemParams => {
	return {
		length: "",
		mark: "",
		quantity: null,
		shape: "",
		size: null,
		weight: null,
		A: "",
		B: "",
		C: "",
		D: "",
		E: "",
		F: "",
		G: "",
		H: "",
		J: "",
		K: "",
		O: "",
		R: "",
	}
}

export const SaveMaterial = async (
	params: SaveMaterialParams,
): Promise<Result<SaveMaterialResult>> => {
	const data = await rpc("SaveMaterial", params, { minDuration: 300 })
	return ReceivedResult<GetMaterialsResult>(data)
}

export type GetMaterialsParams = {
	ids?: string[]
	includeIDs?: string[]

	customerID?: string
	jobID?: string
	workFacilityID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetMaterialsResult = {
	materials: Material[]
	pagination: Pagination
}

export const GetMaterials = async (
	params: GetMaterialsParams,
): Promise<Result<GetMaterialsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetMaterials", "materials", id).then((material?: Entity) => {
			return {
				ok: true,
				result: { materials: _.compact([material as Material]) },
			} as Result<GetMaterialsResult>
		})
	}
	const data = await rpc("GetMaterials", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetMaterialsResult>(data)
}
