import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { WorkResource, EntityType } from "@app/domain"
import { GetWorkResources } from "@app/api"
import { useRelation } from "@app/hooks"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface IWorkResourceSelectProps {
	autoFocus?: boolean
	defaultValue?: string
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	workFacilityID?: string
	onChange: (v: OptionType[]) => void
}

export const WorkResourceSelect: React.FC<IWorkResourceSelectProps> = (props) => {
	const { handleError } = useSession()
	const {
		autoFocus,
		defaultValue = "",
		disabled,
		error,
		hint,
		label,
		name,
		onChange,
		workFacilityID,
	} = props

	const [workResourceID, setWorkResourceID] = React.useState<string>(defaultValue)
	const [reload, setReload] = React.useState<boolean>(false)
	const [workResource, loadingWorkResource] = useRelation<WorkResource>(
		EntityType.WorkResource,
		defaultValue,
	)

	React.useEffect(() => {
		setReload(true)
		_.defer(() => {
			setReload(false)
		})
	}, [workFacilityID])

	const getWorkResources = (inputValue: string) => {
		const includeIDs = _.compact([workResourceID])
		return new Promise<OptionType[]>((resolve) => {
			GetWorkResources({
				includeIDs,
				workFacilityID,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const workResources = resp.result.workResources.map((c: WorkResource) => {
							return {
								label: c.name,
								value: c.id,
							}
						})
						resolve(workResources)
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	const defaultOption = {
		label: workResource?.name ?? defaultValue,
		value: workResourceID,
	}

	if (loadingWorkResource) {
		return null
	}
	if (reload) {
		return <div />
	}
	return (
		<AsyncSelect
			autoFocus={autoFocus}
			cacheOptions={true}
			defaultOptions={true}
			defaultValue={_.isEmpty(defaultValue) ? null : defaultOption}
			disabled={disabled}
			hint={hint}
			error={error}
			isSearchable={true}
			label={label}
			loadOptions={getWorkResources}
			menuPlacement="auto"
			name={name}
			onChange={(v) => {
				const value = _.get(v, "value", "")
				setWorkResourceID(value)
				onChange(_.compact([v]))
			}}
		/>
	)
}
