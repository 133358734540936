import React from "react"
import _ from "lodash"

import { PageContainer, Await, Title } from "@app/components"
import { Worker, AlertLevel, EntityType, WorkerUtils } from "@app/domain"
import { WorkerForm } from "@app/forms"
import { WorkerHeader } from "@app/headers"
import { newSaveWorkerParams, SaveWorker, SaveWorkerParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkerEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [worker, workerLoading] = useEntity<Worker>(EntityType.Worker)
	const { id, name } = _.assign({ id: "", name: "..." }, worker)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const onSave = async (params: SaveWorkerParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorker({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workers?.[0]
				setRedirect(urlTo("workers", saved), {
					alertLevel: AlertLevel.Success,
					title: t("worker.notifications.success.updated", { name: WorkerUtils.name(saved) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("worker.titles.edit", { name })}</Title>
			<WorkerHeader worker={worker} view="edit" />
			<Await
				loading={workerLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkerForm
							busy={busy}
							defaultParams={newSaveWorkerParams(worker)}
							errors={errors}
							onSave={onSave}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
