import React from "react"
import { Helmet } from "react-helmet-async"

import { Logo, Notifications, Link } from "@app/components"

type DialogLayoutProps = {
	children: React.ReactNode
}

export const DialogLayout: React.FC<DialogLayoutProps> = (props) => {
	const { children } = props

	return (
		<>
			<Helmet>
				<meta name="viewport" content="width=device-width,initial-scale=1" />
			</Helmet>
			<Notifications />
			<div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 bg-gray-100 text-center sm:block sm:p-0">
				<span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
				<div className="inline-block align-bottom px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
					<div className="block text-center">
						<div className="inline-block text-black">
							<Link className="hover:text-gray-700" to="/">
								<Logo width={160} />
							</Link>
						</div>
					</div>
					{children}
				</div>
			</div>
		</>
	)
}
