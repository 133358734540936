import React from "react"
import _ from "lodash"
import clsx from "clsx"

import { Icon } from "@app/components"

interface IInputSearchProps {
	initialValue?: string
	busy?: boolean
	paginate?: boolean
	disabled?: boolean
	onFocus?: (e: React.FormEvent<HTMLInputElement>) => void
	onInputChange?: (query: string) => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void | boolean
	placeholder?: string
	onScanClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
	onClear?: React.MouseEventHandler<SVGSVGElement>
}

export const InputSearch: React.FC<IInputSearchProps> = (props) => {
	const {
		initialValue = "",
		busy = false,
		disabled = false,
		onFocus,
		paginate = false,
		onInputChange,
		onKeyDown,
		onScanClick,
		onClear,
		placeholder,
	} = props

	const node = React.useRef<HTMLInputElement>(null)

	const [query, setQuery] = React.useState<string>(initialValue)
	const [active, setActive] = React.useState<boolean>(false)

	const focusHandler = (e: React.FormEvent<HTMLInputElement>): void => {
		const input = e.target as HTMLInputElement
		input.select()
		setActive(true)
		if (onFocus) {
			return onFocus(e)
		}
	}

	const changeHandler = (input: string) => {
		setQuery(_.trim(input))
	}

	React.useEffect(() => {
		if (onInputChange) {
			onInputChange(query)
		}
	}, [query])

	return (
		<div className="relative w-full focus-within:text-gray-600">
			<div
				className={`absolute inset-y-0 left-2 flex items-center pointer-events-none ${
					active ? "text-yellow-500" : "text-gray-500"
				}`}
			>
				<Icon name={busy ? "Cog" : "Search"} size="1x" spin={busy} fixedWidth />
			</div>
			{_.isNil(onScanClick) ? null : (
				<button
					type="button"
					onClick={onScanClick}
					className="absolute right-8 top-2 hover:bg-yellow-500 text-gray-900 text-sm py-0.5 bg-gray-400 px-2 rounded-md font-bold tracking-tighter uppercase"
				>
					<Icon name="QRCode" className="mr-1" /> Scan
				</button>
			)}
			<input
				ref={node}
				autoComplete="off"
				className={clsx(
					paginate ? "" : "rounded-r-full",
					"rounded-l-full appearance-none inline-block form-input bg-gray-800 text-white border-none p-1.5 pl-9 w-full placeholder-gray-500 disabled:text-gray-500 disabled:bg-gray-300 focus:ring-0",
				)}
				disabled={disabled}
				placeholder={placeholder}
				defaultValue={initialValue}
				type="search"
				onKeyDown={onKeyDown}
				onFocus={focusHandler}
				onBlur={() => {
					setActive(false)
				}}
				onChange={(e: React.FormEvent<HTMLInputElement>) => {
					changeHandler((e.target as HTMLInputElement).value)
				}}
			/>
			{query !== "" ? (
				<button
					type="button"
					className="text-gray-500 absolute inset-y-0 right-2 flex items-center hover:text-white"
					onClick={() => {
						if (node && node.current) {
							node.current.value = ""
							setQuery("")
						}
					}}
				>
					<Icon onClick={onClear} name="Times" size="1x" fixedWidth />
				</button>
			) : null}
		</div>
	)
}
