import React from "react"
import _ from "lodash"

import { Input, Toggle, Select, OptionType } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveUserParams, GetTenants, GetTenantToUsersByUserID } from "@app/api"
import { useTimezones, useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"
import { Tenant, TenantToUser, UserPermission } from "@app/domain"

interface IUserFormProps {
	defaultParams: SaveUserParams
	errors: { [key: string]: string }
	disabled?: boolean
	busy?: boolean
	onChange?: (params: SaveUserParams) => void
	referenceID?: string
}

export const UserForm: React.FC<IUserFormProps> = (props) => {
	const { defaultParams, errors, disabled = false, busy = false, onChange, referenceID } = props

	const { t, userHas } = useSession()
	const { params, setParams, inputHandler, selectHandler, valueHandler } =
		useFormHelpers<SaveUserParams>(defaultParams)

	const canModifyTenants = userHas(UserPermission.ModifyUserTenants)

	const [tenants, setTenants] = React.useState<Tenant[]>([])
	const [defaultTenantOption, setDefaultTenantOption] = React.useState<OptionType>()
	const [tenantToUsers, setTenantToUsers] = React.useState<TenantToUser[]>([])
	const [loadedTenantToUsers, setLoadedTenantToUsers] = React.useState(false)
	const { timezoneOptions, defaultTimezoneOption } = useTimezones(_.get(params, "timezone", ""))

	React.useEffect(() => {
		if (onChange) {
			onChange(_.cloneDeep(params))
		}
	}, [params])

	React.useEffect(() => {
		const fetch = async () => {
			if (canModifyTenants) {
				const resp = await GetTenants()
				if (resp.ok) {
					setTenants(_.sortBy(_.get(resp, "result.tenants", []), "name"))
				}
			}
		}
		fetch()
	}, [])

	React.useEffect(() => {
		const fetch = async () => {
			const { id } = params
			if (!id) {
				setLoadedTenantToUsers(true)
				return
			}
			const resp = await GetTenantToUsersByUserID({ id })
			if (resp.ok) {
				const result = _.get(resp, "result.tenantToUsers", [])
				const tenantIDs = _.map(result, "tenantID")
				let defaultTenantID = 0
				setTenantToUsers(result)
				const defaultTenantToUser = _.find(result, { isDefault: true })
				if (defaultTenantToUser) {
					defaultTenantID = defaultTenantToUser.tenantID
					const defaultTenant = _.find(tenants, { id: defaultTenantToUser.tenantID })
					if (defaultTenant) {
						const { name: label, id } = defaultTenant
						setDefaultTenantOption({ label, value: id.toString() })
					}
				}
				setParams((prev) => ({ ...prev, tenantIDs, defaultTenantID }))
				setLoadedTenantToUsers(true)
			}
		}
		if (!_.isEmpty(tenants) && canModifyTenants) {
			fetch()
		}
	}, [params.id, tenants])

	const tenantOptions = _.map(tenants, (t) => {
		return { label: t.name, value: t.id.toString() }
	})

	return (
		<>
			{referenceID ? (
				<Input
					disabled={disabled || busy}
					readOnly={true}
					defaultValue={referenceID}
					hint={t("common.hints.referenceID")}
					label={t("common.labels.referenceID")}
					name="referenceID"
					error={_.get(errors, "referenceID")}
					onChange={inputHandler("referenceID")}
					type="text"
				/>
			) : null}
			<Input
				autoComplete="off"
				disabled={disabled || busy}
				autoFocus={params.id === ""}
				defaultValue={params.firstName}
				label={t("user.labels.firstName")}
				name="firstName"
				error={_.get(errors, "firstName")}
				onChange={inputHandler("firstName")}
				type="text"
			/>
			<Input
				autoComplete="off"
				disabled={disabled || busy}
				defaultValue={params.lastName}
				label={t("user.labels.lastName")}
				name="lastName"
				error={_.get(errors, "lastName")}
				onChange={inputHandler("lastName")}
				type="text"
			/>
			<Input
				autoComplete="off"
				disabled={disabled || busy}
				defaultValue={params.email}
				label={t("user.labels.email")}
				name="email"
				error={_.get(errors, "email")}
				onChange={inputHandler("email")}
				type="text"
			/>
			<Select
				defaultValue={defaultTimezoneOption}
				disabled={disabled || busy}
				isSearchable
				label={t("user.labels.timezone")}
				name="timezone"
				onChange={selectHandler("timezone")}
				options={timezoneOptions}
			/>
			<Select
				defaultValue={params?.permissions?.map((permission) => ({
					label: En.userPermission.types[_.camelCase(permission)],
					value: permission,
				}))}
				disabled={disabled || busy}
				isMulti
				isSearchable
				closeMenuOnSelect={false}
				error={_.get(errors, "permissions")}
				label={t("user.labels.permissions")}
				name="permissions"
				onChange={selectHandler("permissions", true, (v) => ({
					...v,
					permissions: v.permissions.map((p) => _.upperFirst(p)),
				}))}
				options={Object.entries(En.userPermission.types)
					.filter((v) => !params?.permissions?.includes(_.upperFirst(v[0])))
					.map((v) => ({
						label: v[1],
						value: v[0],
					}))}
			/>
			{loadedTenantToUsers && !_.isEmpty(tenants) ? (
				<>
					<Select
						defaultValue={_.map(tenantToUsers, (ttu) => {
							const label = _.get(_.find(tenants, { id: ttu.tenantID }), "name", "")
							return { label, value: ttu.tenantID.toString() }
						})}
						disabled={disabled || busy || !canModifyTenants}
						isMulti
						isSearchable
						error={_.get(errors, "tenantIDs")}
						label={t("user.labels.tenantIDs")}
						name="tenantIDs"
						onChange={selectHandler("tenantIDs", true, (v) => ({
							...v,
							tenantIDs: v.tenantIDs.map((p) => Number(p)),
						}))}
						options={tenantOptions}
					/>
					<Select
						defaultValue={defaultTenantOption}
						disabled={disabled || busy || !canModifyTenants}
						isSearchable
						error={_.get(errors, "defaultTenantID")}
						label={t("user.labels.defaultTenantID")}
						name="defaultTenantID"
						onChange={selectHandler("defaultTenantID", false, (v) => {
							const defaultTenantID = Number(v.defaultTenantID) || 0
							return { ...v, defaultTenantID }
						})}
						options={tenantOptions}
					/>
				</>
			) : null}
			<Toggle
				label={t("user.labels.disabled")}
				hint={t("user.hints.disabled")}
				defaultChecked={params.disabled}
				disabled={disabled || busy}
				error={_.get(errors, "disabled")}
				onChange={valueHandler("disabled")}
			/>
		</>
	)
}
