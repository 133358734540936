import React, { ReactNode } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import queryString from "query-string"
import prettyBytes from "pretty-bytes"

import {
	Await,
	Link,
	DateTime,
	DateTimeInput,
	Icon,
	InputSearch,
	EntityImageTable,
	EntityColumnarTable,
	Button,
	Select,
} from "@app/components"
import {
	DefaultEntityQueryParams,
	EntityQuery,
	EntityQueryParams,
	EntityQueryResult,
	EntityTypeIDMap,
	GetWorkCells,
	GetWorkFacilities,
	NullDate,
	Result,
	TrashItems,
} from "@app/api"
import { useSession, useEntity } from "@app/contexts"
import { pluralize, humanize, urlTo, browserTimezone } from "@app/util"
import {
	AlertLevel,
	Entity,
	EntityType,
	EntityColumn,
	EntityColumnType,
	Assembly,
	assemblyPendingFiles,
	AssemblyPendingFileType,
	WorkCell,
	WorkFacility,
} from "@app/domain"
import { useDebouncedEffect, useQuery, useRedirect, useTimezones } from "@app/hooks"
import { useInterval } from "usehooks-ts"

const intl = new Intl.NumberFormat()

interface ITableProps {
	buttons?: ReactNode | ReactNode[]
	sideEffects?: (resp: Result<EntityQueryResult>) => void
	entityType: EntityType
	filterableEntityTypes?: Set<EntityType>
	fullHeight?: boolean
	fullWidth?: boolean
	parentEntityID?: string | null
	parentEntityType?: EntityType | null
	pendingFiles?: boolean
	selectable?: boolean
	unclickable?: boolean
	unsearchable?: boolean
	downloadable?: boolean
	refreshable?: boolean
	autoUpdateMinutes?: number
}

export const EntityTable: React.FC<ITableProps> = (props: ITableProps) => {
	const {
		sideEffects = async () => null,
		buttons = null,
		entityType,
		filterableEntityTypes = new Set<EntityType>([]),
		fullHeight = false,
		fullWidth = true,
		parentEntityID = null,
		parentEntityType = null,
		unclickable = false,
		unsearchable = false,
		downloadable = false,
		refreshable = false,
		autoUpdateMinutes,
		selectable,
	} = props

	const { t, handleError, addNotification } = useSession()
	const history = useHistory()
	const { entity: parentEntity } = useEntity(parentEntityID)

	const relevantFiles = entityType === EntityType.File ? [] : [AssemblyPendingFileType.RobotProgram]
	const pendingFiles =
		parentEntityType === EntityType.Assembly
			? assemblyPendingFiles(parentEntity as Assembly, relevantFiles)
			: false

	const defaultParams = DefaultEntityQueryParams(entityType, parentEntityType, parentEntityID)
	const [params, setParams] = React.useState<EntityQueryParams>(defaultParams)
	const [columns, setColumns] = React.useState<EntityColumn[]>([])
	const [defaultHiddenCols, setDefaultHiddenCols] = React.useState<string[]>([])
	const [entities, setEntities] = React.useState<Entity[]>([])
	const [firstRequest, setFirstRequest] = React.useState(true)
	const [loading, setLoading] = React.useState<boolean>(true)
	const [imageLayout, setImageLayout] = React.useState<boolean>(true)
	const [totalEntities, setTotalEntities] = React.useState(0)
	const [pageSize, setPageSize] = React.useState(0)
	const [selected, setSelected] = React.useState<{ [id: string]: boolean }>({})
	const [downloading, setDownloading] = React.useState<boolean>(false)

	const [workFacilities, setWorkFacilities] = React.useState<WorkFacility[]>([])
	const [selectedWorkFacility, setSelectedWorkFacility] = React.useState<WorkFacility>()
	const [workCells, setWorkCells] = React.useState<WorkCell[]>([])
	const [selectedWorkCell, setSelectedWorkCell] = React.useState<WorkCell>()
	const { timezoneLookup } = useTimezones()

	const [now, setNow] = React.useState(new Date())
	useInterval(
		() => {
			setNow(new Date())
		},
		autoUpdateMinutes ? 1000 * 60 * autoUpdateMinutes : null,
	)

	const query = useQuery()
	const queryParamIncludeSet = new Set([
		"groupCol",
		"page",
		"pageSize",
		"query",
		"sortCol",
		"sortDir",
		"timeEnd",
		"timeStart",
		"entityTypeIDMap",
	])
	const entityTypeIDMapQueryParam: EntityTypeIDMap =
		typeof query?.entityTypeIDMap === "string" ? JSON.parse(query?.entityTypeIDMap) : {}

	const sendRequest = React.useCallback(async () => {
		setLoading(true)
		if (firstRequest) {
			setFirstRequest(false)
			// Set URL query params on first load.
			setParams((params) => ({
				...params,
				...Object.fromEntries(
					Object.entries(query)
						.filter((kv) => queryParamIncludeSet.has(kv[0]))
						.map((kv) => {
							const decoded = decodeURIComponent(kv[1] as string)
							switch (kv[0]) {
								case "page":
									return [kv[0], Math.max(1, Number(decoded) || 1)]
								case "pageSize":
									return [kv[0], Number(decoded)]
								case "sortDir":
									return [kv[0], Number(decoded)]
								case "timeStart":
								case "timeEnd":
									return [kv[0], new Date(decoded)]
								case "entityTypeIDMap":
									return [kv[0], entityTypeIDMapQueryParam]
								case "query":
								case "sortCol":
								case "groupCol":
								default:
									return [kv[0], decoded]
							}
						}),
				),
			}))
			return
		}
		const resp = await EntityQuery(params)
		if (!resp?.ok) {
			handleError("Error fetching data")
			return
		}
		if (resp.result.pagination.Total > 0 && resp.result.rows.length === 0) {
			setParams((params) => ({
				...params,
				page: Math.ceil(resp.result.pagination.Total / resp.result.pagination.Limit),
			}))
			return
		}
		await sideEffects(resp)
		setPageSize(resp.result.pagination.Limit)
		setImageLayout(resp.result.imageLayout)
		setEntities(resp.result.rows)
		setTotalEntities(resp.result.pagination.Total)
		setColumns(
			resp.result.columns.map((c: EntityColumn) => ({
				...c,
				options: {
					...c.options,
					hidden:
						!(query?.show?.includes(c.name) || !c.options.hidden) ||
						!(!query?.hide?.includes(c.name) || c.options.hidden) ||
						_.camelCase(parentEntityType + "Name") === c.name,
				},
			})),
		)
		setDefaultHiddenCols(resp.result.columns.filter((c) => c.options.hidden).map((c) => c.name))
		setLoading(false)
	}, [params, columns, entities, firstRequest, query, pendingFiles, now])

	React.useEffect(() => {
		// Wait for data, params, etc to be loaded
		// (this effect tries to run before the RPC).
		if (!defaultHiddenCols.length) {
			return
		}
		const shownColString = columns
			.filter((c) => !c.options.hidden && defaultHiddenCols.includes(c.name))
			.map((c) => c.name)
			.join(",")
		const groupCol = columns.find((c) => c.options.group)
		const groupColString = groupCol?.name
		const hiddenColString = columns
			.filter((c) => c.options.hidden && !defaultHiddenCols.includes(c.name))
			.map((c) => c.name)
			.join(",")
		// Update URL query string.
		history.replace({
			search: queryString.stringify({
				...Object.fromEntries(
					Object.entries(params)
						.filter(
							(kv) =>
								queryParamIncludeSet.has(kv[0]) &&
								(kv[0] !== "timeStart"
									? JSON.stringify(kv[1]) !== JSON.stringify(defaultParams[kv[0]])
									: // More than an hour from default timeStart.
									  Math.abs((kv[1] as Date).getTime() - (defaultParams[kv[0]] as Date).getTime()) >
									  60 * 60 * 1000),
						)
						.map(([k, v]) => (k === "entityTypeIDMap" ? [k, JSON.stringify(v)] : [k, v]))
						.concat(shownColString ? [["show", shownColString]] : [])
						.concat(groupColString ? [["group", groupColString]] : [])
						.concat(hiddenColString ? [["hide", hiddenColString]] : [])
						.filter((e) => e && e.length),
				),
			}),
		})
	}, [columns, params])

	useDebouncedEffect(sendRequest, 200, [params, pendingFiles, now])

	React.useEffect(() => {
		const dismissVisBox = (e: MouseEvent) => {
			const button = document.getElementById("visButton")
			const container = document.getElementById("visBox")
			if (!container || !button) {
				return
			}
			if (button.contains(e.target as Node)) {
				container.style.display = container.style.display === "none" ? "block" : "none"
			} else if (!container.contains(e.target as Node) && !button.contains(e.target as Node)) {
				container.style.display = "none"
			} else {
				container.style.display = "block"
			}
		}
		document.addEventListener("mouseup", dismissVisBox)
		return () => {
			document.removeEventListener("mouseup", dismissVisBox)
		}
	}, [])

	const { redirect, setRedirect } = useRedirect()

	const trashSelectionHandler = async () => {
		type TrashItem = { id: string; entityType: EntityType }
		const items = _.reduce(
			selected,
			(acc: TrashItem[], v: boolean, id: string) => {
				if (v) {
					acc.push({ id, entityType })
				}
				return acc
			},
			[],
		)
		const resp = await TrashItems({ items })
		if (resp.ok) {
			const entity = humanize(entityType)
			const entityPlural = pluralize(entityType)
			setRedirect(`/trash`, {
				alertLevel: AlertLevel.Success,
				title: t(`common.batch.notifications.success.deleted`, {
					smart_count: _.size(items),
					entity,
					entityPlural,
				}),
			})
		}
	}

	const [timezone, setTimezone] = React.useState<string>(browserTimezone)
	React.useEffect(() => {
		if (parentEntity && parentEntityType === EntityType.WorkFacility) {
			setTimezone((parentEntity as WorkFacility)?.timezone)
			return
		}
		const entityTypesWithWorkFacilityID = [
			EntityType.Material,
			EntityType.Release,
			EntityType.ReleaseUnit,
			EntityType.Shipment,
			EntityType.WorkCell,
			EntityType.WorkCellServer,
			EntityType.WorkDevice,
			EntityType.WorkEvent,
			EntityType.WorkResource,
		]
		if (
			!parentEntity ||
			!parentEntityID ||
			!parentEntityType ||
			!entityTypesWithWorkFacilityID.includes(parentEntityType)
		) {
			return
		}
		const getTimezone = async (workFacilityID?: string) => {
			if (!workFacilityID) {
				return
			}
			const resp = await GetWorkFacilities({ ids: [workFacilityID] })
			if (!resp.ok) {
				return
			}
			setTimezone(resp.result.workFacilities[0]?.timezone)
		}
		getTimezone(parentEntity?.["workFacilityID"])
	}, [parentEntity, parentEntityID, parentEntityType])

	React.useEffect(() => {
		const getWorkFacilities = async () => {
			setLoading(true)
			const resp = await GetWorkFacilities({})
			if (resp.ok) {
				if (
					!workFacilities.length &&
					resp.result.workFacilities.length &&
					entityTypeIDMapQueryParam?.[EntityType.WorkFacility]
				) {
					setSelectedWorkFacility(
						resp.result.workFacilities.find(
							(wf) => wf.id === entityTypeIDMapQueryParam[EntityType.WorkFacility],
						),
					)
				}
				setWorkFacilities(resp.result.workFacilities)
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work facilities.`,
				})
			}
			setLoading(false)
		}
		if (filterableEntityTypes.has(EntityType.WorkFacility)) {
			getWorkFacilities()
		}
	}, [])

	React.useEffect(() => {
		const getWorkCells = async () => {
			setLoading(true)
			const resp = await GetWorkCells({ workFacilityID: selectedWorkFacility?.id })
			if (resp.ok) {
				if (
					!workCells.length &&
					resp.result.workCells.length &&
					entityTypeIDMapQueryParam?.[EntityType.WorkCell]
				) {
					setSelectedWorkCell(
						resp.result.workCells.find(
							(wc) => wc.id === entityTypeIDMapQueryParam[EntityType.WorkCell],
						),
					)
				}
				setWorkCells(resp.result.workCells)
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work cells.`,
				})
			}
			setLoading(false)
		}
		if (filterableEntityTypes.has(EntityType.WorkCell)) {
			getWorkCells()
		}
	}, [selectedWorkFacility])

	React.useEffect(() => {
		setParams((params) => ({
			...params,
			entityTypeIDMap: {
				[EntityType.WorkFacility]: selectedWorkFacility?.id,
				[EntityType.WorkCell]: selectedWorkCell?.id,
			},
		}))
	}, [selectedWorkCell, selectedWorkFacility])

	if (redirect) {
		return redirect()
	}
	const headerInputs = (
		<div className="flex flex-col pl-2">
			<div className="flex flex-col md:flex-row-reverse my-3">
				<span
					className={clsx(
						"w-full mb-3 md:mb-0 md:w-1/2 flex gap-[2px]",
						!_.isEmpty(selected) && "hidden",
						unsearchable && "justify-end",
					)}
				>
					{refreshable && (
						<button
							onClick={() => setNow(new Date())}
							className={clsx(
								"relative gap-2 inline-flex rounded-full mr-2 items-center px-3 py-2 text-sm leading-5 font-medium disabled:text-gray-500 disabled:bg-gray-300 focus:outline-none transition ease-in-out duration-150",
								loading
									? "cursor-wait bg-gray-500"
									: "cursor-pointer bg-yellow-500 hover:bg-yellow-300 focus:ring-yellow-500 focus:border-yellow-500 ",
							)}
							disabled={loading}
						>
							<Icon name={loading ? "Cog" : "Sync"} size="1x" spin={loading} />
							<div className="min-w-max">
								<DateTime time={now.toISOString()} />
							</div>
						</button>
					)}
					{downloadable && (
						<button
							onClick={async () => {
								setDownloading(true)
								const resp = await EntityQuery({ ...params, pageSize: -1 })
								if (!resp.ok) {
									addNotification({
										alertLevel: AlertLevel.Danger,
										title: "Failed to download",
										subtitle: "Try again",
									})
									setDownloading(false)
									return
								}
								const columns = resp.result.columns
								const entities = resp.result.rows
								const columnNameOrder = Object.fromEntries(columns.map((c, i) => [c.name, i]))
								const csvContent =
									columns.map((c) => c.label).join("\t") +
									"\n" +
									entities
										.map((e) =>
											Object.entries(e)
												.filter(([k]) => k in columnNameOrder)
												.sort((a, b) => {
													const aIndex = columnNameOrder[a[0]]
													const bIndex = columnNameOrder[b[0]]
													return aIndex - bIndex
												})
												.map(([, v]) => v)
												.join("\t"),
										)
										.join("\n")
								const link = document.createElement("a")
								link.href = URL.createObjectURL(new Blob([csvContent], { type: "text/csv" }))
								link.download = `${entityType}.csv`
								link.click()
								link.remove()
								window.URL.revokeObjectURL(link.href)
								setDownloading(false)
							}}
							className={clsx(
								"relative inline-flex rounded-full mr-2 items-center px-3 py-2 text-sm leading-5 font-medium disabled:text-gray-500 disabled:bg-gray-300 focus:outline-none transition ease-in-out duration-150",
								downloading
									? "cursor-wait bg-gray-500"
									: "cursor-pointer bg-yellow-500 hover:bg-yellow-300 focus:ring-yellow-500 focus:border-yellow-500 ",
							)}
							disabled={downloading}
						>
							<Icon name={downloading ? "Cog" : "Download"} size="1x" spin={downloading} />
						</button>
					)}
					{!unsearchable && (
						<InputSearch
							initialValue={(query.query as string) ?? ""}
							placeholder="Search"
							paginate={true}
							onInputChange={(query) =>
								setParams((params) =>
									query === params.query ? params : { ...params, page: 1, query },
								)
							}
						/>
					)}
					<button
						disabled={params.page === 1}
						onClick={() => {
							setParams((params) => ({
								...params,
								page: params.page - 1,
							}))
						}}
						className={clsx(
							unsearchable && "rounded-l-full",
							"relative inline-flex items-center px-4 py-2 text-sm leading-5 font-medium disabled:text-gray-500 disabled:bg-gray-300 bg-yellow-500 hover:bg-yellow-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 transition ease-in-out duration-150",
						)}
					>
						<Icon name="ChevronLeft" size="1x" />
					</button>
					<button
						disabled={params.page * pageSize >= totalEntities}
						onClick={() => {
							setParams((params) => ({
								...params,
								page: params.page + 1,
							}))
						}}
						className="relative rounded-r-full inline-flex items-center px-4 py-2 text-sm leading-5 font-medium disabled:text-gray-500 disabled:bg-gray-300 bg-yellow-500 hover:bg-yellow-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 transition ease-in-out duration-150"
					>
						<Icon name="ChevronRight" size="1x" />
					</button>
				</span>
				{_.isEmpty(selected) ? (
					<span className="gap-x-0 pr-2 w-full flex justify-between flex-row-reverse">
						<span className="gap-x-2 flex items-center">{buttons}</span>
						<span className="self-center">
							<b>{entities.length === 0 ? 0 : (params.page - 1) * pageSize + 1}</b>
							{" to "}
							<b>{Math.min(totalEntities, params.page * pageSize)}</b>
							{" of "}
							<b>{totalEntities === 1000 ? "many" : totalEntities}</b>{" "}
							{t(`${_.camelCase(entityType)}.name`, pageSize)}
						</span>
					</span>
				) : (
					<span className="gap-x-0 w-full flex justify-between flex-row-reverse">
						<span>
							<Button icon="Trash" label="Trash" onClick={trashSelectionHandler} />
						</span>
						<span className="self-center">
							<b>{_.size(selected)}</b>
							{" of "}
							<b>{totalEntities === 1000 ? "many" : totalEntities}</b>{" "}
							{t(`${_.camelCase(entityType)}.name`, pageSize)}
							{" selected"}
						</span>
					</span>
				)}
			</div>
			<div className="flex place-items-center flex-wrap md:flex-nowrap items-end gap-2">
				{columns.some((c) => c.options.timeStart || c.options.timeEnd) && (
					<>
						<span className="w-full">
							<DateTimeInput
								withTime
								clearable
								defaultValue={JSON.stringify(params.timeStart)}
								name="timeBegin"
								onChange={(date) =>
									setParams((prev) =>
										date === prev.timeStart ? prev : { ...params, timeStart: date ?? NullDate },
									)
								}
								timezone={timezone}
							/>
						</span>
						<p className="px-2 place-self-center">to</p>
						<span className="w-11/12 md:w-full">
							<DateTimeInput
								withTime
								clearable
								error={
									params.timeEnd !== NullDate &&
									params.timeStart !== NullDate &&
									params.timeEnd < params.timeStart
										? "End date must be after start date"
										: ""
								}
								name="timeEnd"
								onChange={(date) =>
									setParams((prev) =>
										date === prev.timeEnd ? prev : { ...params, timeEnd: date ?? NullDate },
									)
								}
								timezone={timezone}
							/>
						</span>
					</>
				)}
				{filterableEntityTypes.has(EntityType.WorkFacility) && (
					<div className="w-full">
						<Select
							disabled={loading}
							name="workFacilities"
							label="Work Facilities"
							placeholder="All"
							value={
								selectedWorkFacility
									? { value: selectedWorkFacility?.id, label: selectedWorkFacility?.name }
									: undefined
							}
							options={workFacilities
								.filter((wf) =>
									!selectedWorkCell ? true : wf.id === selectedWorkCell?.workFacilityID,
								)
								.map((wf) => ({
									value: wf.id,
									label: wf.name,
								}))}
							onChange={(v) => {
								const ids = v.map((o) => o.value)
								setSelectedWorkFacility(
									workFacilities.filter((workFacility) => ids.includes(workFacility.id))?.[0],
								)
							}}
						/>
					</div>
				)}
				{filterableEntityTypes.has(EntityType.WorkCell) && (
					<div className="w-full">
						<Select
							disabled={loading}
							name="workCells"
							label="Work Cells"
							placeholder="All"
							value={
								selectedWorkCell
									? { value: selectedWorkCell?.id, label: selectedWorkCell?.name }
									: undefined
							}
							options={workCells.map((workCell) => ({
								value: workCell.id,
								label: workCell.name,
							}))}
							onChange={(v) => {
								const ids = v.map((o) => o.value)
								setSelectedWorkCell(workCells.filter((workCell) => ids.includes(workCell.id))?.[0])
							}}
						/>
					</div>
				)}
			</div>
		</div>
	)
	const renderEntityColumn = (entity: Entity, column: EntityColumn, j: number, columnar = true) => {
		const missing = <span className="text-gray-500 text-sm">n/a</span>
		let entityData = entity?.[column?.name]
		const wrap = (children: JSX.Element) =>
			!columnar ? (
				children
			) : (
				<td key={j} className="py-4 pl-4 first:text-lg relative">
					{children}
					{!unclickable && (
						<Link
							to={urlTo(entityType, entity as Entity)}
							className="absolute top-0 right-0 left-0 bottom-0"
							onClick={(e) => e.stopPropagation()} // Disable double navigation via <tr> onClick handler.
						></Link>
					)}
				</td>
			)
		if (
			(column.type !== EntityColumnType.Boolean && (!entityData || entityData.length === 0)) ||
			(column.type === EntityColumnType.Timestamp && entityData === "0001-01-01T00:00:00Z")
		) {
			return wrap(missing)
		}
		const wrapDownload = (href: string) => (
			<td key={j} className="py-4 first:text-lg relative">
				<div className="flex justify-center items-center" onClick={(e) => e.stopPropagation()}>
					<a
						href={href}
						className={`inline-flex items-center justify-center text-black h-8 w-8 rounded-full focus:outline-none bg-yellow-500 hover:bg-yellow-400`}
					>
						<Icon name="Download" className="m-auto" />
					</a>
				</div>
			</td>
		)
		switch (column.type) {
			case EntityColumnType.DownloadLink:
				return wrapDownload(entityData)
			case EntityColumnType.Timestamp:
				return wrap(
					<span className="whitespace-nowrap">
						<DateTime
							time={entityData}
							explicit={!column.options.relativeTime}
							// Following line hides seconds.
							options={{ hour: "numeric", minute: "numeric" }}
							timezone={timezone}
							hideTimezone
						/>
					</span>,
				)
			case EntityColumnType.Date:
				return wrap(
					<span className="whitespace-nowrap">
						<DateTime time={entityData} dateOnly={true} timezone={timezone} hideTimezone />
					</span>,
				)
			case EntityColumnType.ObjectArray:
				if (column?.name.toLowerCase().includes("address")) {
					return wrap(
						entityData.map((e: { [key: string]: string }, i: number) => (
							<div key={i} className={`text-sm ${i !== entityData.length - 1 ? "pb-2" : ""}`}>
								{e.Line1}
								<br />
								{e.City}, {e.State}
							</div>
						)),
					)
				}
				return wrap(
					entityData.map((e: { [key: string]: string }, i: number) => (
						<div key={i} className="text-sm">
							{/* @ts-expect-error */}
							{e[column.objectDetails[0].key]}
						</div>
					)),
				)
			case EntityColumnType.Timezone:
				return wrap(<span>{_.get(timezoneLookup, entityData) || missing}</span>)
			case EntityColumnType.StringArray:
				return wrap(<>{_.truncate(entityData.join(", "), { length: 40 })}</>)
			case EntityColumnType.StrikethroughString:
				return wrap(<s>{_.truncate(String(entityData), { length: 40 })}</s>)
			case EntityColumnType.Boolean:
				entityData = entityData ? "Yes" : "No"
			// fallthrough
			case EntityColumnType.Float:
			// fallthrough
			case EntityColumnType.Integer:
			// fallthrough
			case EntityColumnType.ReferenceID:
			// fallthrough
			case EntityColumnType.Seconds:
			// fallthrough
			case EntityColumnType.NanoSeconds:
			// fallthrough
			case EntityColumnType.String:
				return wrap(
					<span title={entityData}>{_.truncate(String(entityData), { length: 40 })}</span>,
				)
			case EntityColumnType.FileSize:
				return wrap(
					<span title={`${intl.format(entityData)} bytes`}>{prettyBytes(entityData)}</span>,
				)
			case EntityColumnType.FileType:
				return wrap(<span>{t(`fileTypes.${entityData}`)}</span>)
			case EntityColumnType.FileName:
				return wrap(<span>{entityData}</span>)
			default:
				return wrap(<span>{`unhandled type (${column.type})`}</span>)
		}
	}

	return (
		<div className="text-gray-800">
			{headerInputs}
			<Await
				loading={loading}
				then={() =>
					imageLayout ? (
						<EntityImageTable
							columns={columns}
							entities={entities}
							entityType={entityType}
							fullHeight={fullHeight}
							fullWidth={fullWidth}
							params={params}
							renderEntityColumn={renderEntityColumn}
							setColumns={setColumns}
							setParams={setParams}
							timezone={timezone}
						/>
					) : (
						<EntityColumnarTable
							columns={columns}
							entities={entities}
							entityType={entityType}
							fullHeight={fullHeight}
							fullWidth={fullWidth}
							params={params}
							pendingFiles={pendingFiles}
							renderEntityColumn={renderEntityColumn}
							setColumns={setColumns}
							setParams={setParams}
							selectable={selectable}
							onSelectionChange={setSelected}
							unclickable={unclickable}
							timezone={timezone}
						/>
					)
				}
			/>
		</div>
	)
}

export type EntityTableChildType = {
	columns: EntityColumn[]
	entities: Entity[]
	entityType: EntityType
	fullHeight?: boolean
	fullWidth?: boolean
	params: EntityQueryParams
	pendingFiles?: boolean
	renderEntityColumn: (
		entity: Entity,
		column: EntityColumn,
		j: number,
		columnar?: boolean,
	) => JSX.Element
	selectable?: boolean
	unclickable?: boolean
	setColumns: React.Dispatch<React.SetStateAction<EntityColumn[]>>
	setParams: React.Dispatch<React.SetStateAction<EntityQueryParams>>
	onSelectionChange?: (selection: { [id: string]: boolean }) => void
	timezone: string
}
