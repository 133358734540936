import { rpc } from "@app/api"
import type { Result } from "@app/api"

export type GetBuildDetailsResult = {
	build: {
		commit: string
		time: string
		version: string
	}
}

export const GetBuildDetails = async (): Promise<Result<GetBuildDetailsResult>> => {
	const data = await rpc("GetBuildDetails")
	return { ok: true, result: data as unknown, errors: data.errors } as Result<GetBuildDetailsResult>
}
