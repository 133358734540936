import React from "react"
import _ from "lodash"

import { StreamListener, SessionLocations } from "@app/domain"

export type StreamContext = {
	addEventListener: (name: string, fn: StreamListener) => void
	disabled: boolean
	locations: SessionLocations
	open: boolean
	pushLocation: (curr: string, prev: string) => void
	removeEventListener: (name: string, fn: StreamListener) => void
}

export const streamContext = React.createContext<StreamContext>({
	addEventListener: _.noop,
	disabled: false,
	locations: {},
	open: false,
	pushLocation: _.noop,
	removeEventListener: _.noop,
})

export const useStream = (): StreamContext => React.useContext(streamContext)
