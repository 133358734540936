import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { WorkResource, AlertLevel, EntityType } from "@app/domain"
import { WorkResourceForm } from "@app/forms"
import { WorkResourceHeader } from "@app/headers"
import { newSaveWorkResourceParams, SaveWorkResource, SaveWorkResourceParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkResourceEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workResource, workResourceLoading] = useEntity<WorkResource>(EntityType.WorkResource)
	const { id, name } = _.assign({ id: "", name: "..." }, workResource)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveWorkResourceParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkResource({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workResources[0]")
				setRedirect(urlTo("workResources", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workResource.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workResource.titles.edit", { name })}</Title>
			<WorkResourceHeader workResource={workResource} view="edit" />
			<Await
				loading={workResourceLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkResourceForm
							busy={busy}
							defaultParams={newSaveWorkResourceParams(workResource)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
