import { rpc } from "@app/api"

import type { Result } from "@app/api"
import type { EntityType, SearchResult } from "@app/domain"
import { urlTo } from "@app/util"

export type SearchAllParams = {
	query?: string

	limit: number
	offset: number
	entityTypes?: EntityType[]
}

export type SearchAllResult = {
	search: SearchResult[]
}

export const SearchAll = async (params: SearchAllParams): Promise<Result<SearchAllResult>> => {
	const data = await rpc("SearchAll", params)
	const resp = data as Result<SearchAllResult>
	resp.result.search = resp.result.search.map((r) => {
		switch (r.type) {
			case "assembly":
				return { ...r, url: urlTo("assemblies/show", r.id), iconName: "Assemblies" }
			case "contact":
				return { ...r, url: urlTo("contacts/show", r.id), iconName: "User" }
			case "customer":
				return { ...r, url: urlTo("customers/show", r.id), iconName: "Building" }
			case "document":
				return { ...r, url: urlTo("documents/show", r.id), iconName: "Document" }
			case "job":
				return { ...r, url: urlTo("jobs/show", r.id), iconName: "Jobs" }
			case "release":
				return { ...r, url: urlTo("releases/show", r.id), iconName: "Releases" }
			case "shipment":
				return { ...r, url: urlTo("shipments/show", r.id), iconName: "Shipments" }
			case "tag":
				return { ...r, url: urlTo("tags/show", r.id), iconName: "Tag" }
			case "user":
				return { ...r, url: urlTo("users/show", r.id), iconName: "Users" }
			case "workcell":
				return { ...r, type: "workCell", url: urlTo("workCells/show", r.id), iconName: "WorkCell" }
			case "workcellserver":
				return {
					...r,
					type: "workCellServer",
					url: urlTo("workCellServers/show", r.id),
					iconName: "WorkCellServer",
				}
			case "workfacility":
				return {
					...r,
					type: "workFacility",
					url: urlTo("workFacilities/show", r.id),
					iconName: "WorkFacilities",
				}
			case "workresource":
				return {
					...r,
					type: "workResource",
					url: urlTo("workResources/show", r.id),
					iconName: "WorkResource",
				}
			case "worker":
				return { ...r, url: urlTo("workers/show", r.id), iconName: "Workers" }
			default:
				throw new Error(`unknown search result type '${r.type}'`)
		}
	})
	return resp
}
