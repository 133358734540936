import React from "react"
import _ from "lodash"

import { FormActions, PageContainer, Await, Button } from "@app/components"
import { Tag, TagUtils, AlertLevel, EntityType } from "@app/domain"
import { TagForm } from "@app/forms"
import { TagHeader } from "@app/headers"
import { SaveTag, SaveTagParams, newSaveTagParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useSession } from "@app/contexts"
import { useRedirect, useEntity } from "@app/hooks"

export const TagEditPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const [tag, tagLoading, setTag] = useEntity<Tag>(EntityType.Tag)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveTagParams>(newSaveTagParams(tag))
	const [busy, setBusy] = React.useState<boolean>(false)

	const { id, locked, referenceID } = _.assign({ id: "", referenceID: "", locked: false }, tag)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("tag.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveTag({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.tags[0]")
				setRedirect(urlTo("tags/show", saved), {
					alertLevel: AlertLevel.Success,
					title: t("tag.notifications.success.updated", { name: TagUtils.id(saved) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<TagHeader tag={tag} setTag={setTag} view="edit" />
			{locked ? (
				<div className="w-full bg-red-200 py-2 mb-4">
					<div className="mx-auto max-w-md text-sm text-red-800 text-center">
						{t("tag.notifications.locked")}
					</div>
				</div>
			) : null}
			<Await
				loading={tagLoading}
				then={() => (
					<form onSubmit={submitHandler} noValidate>
						<div className="px-2">
							<TagForm
								referenceID={referenceID}
								disabled={locked}
								defaultParams={newSaveTagParams(tag)}
								errors={errors}
								busy={busy}
								onChange={(nextParams) => {
									setParams(nextParams)
								}}
							/>
						</div>
						<FormActions>
							<Button
								errors={errors}
								label={t("tag.buttons.save")}
								busy={busy}
								disabled={busy || locked}
								type="submit"
							/>
						</FormActions>
					</form>
				)}
			/>
		</PageContainer>
	)
}
