import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import { WorkEvent, EntityType, WorkFacility } from "@app/domain"
import { useRelation } from "@app/hooks"

interface IWorkEventDetailsProps {
	workEvent?: WorkEvent
}

export const WorkEventDetails: React.FC<IWorkEventDetailsProps> = (props) => {
	const { t } = useSession()

	const { workEvent } = props

	const [workFacility] = useRelation<WorkFacility>(
		EntityType.WorkFacility,
		workEvent?.workFacilityID,
	)

	if (_.isNil(workEvent)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property
				label={t("workEvent.labels.workEventType")}
				value={
					workEvent.workEventType ? t(`workEventTypes.${_.camelCase(workEvent.workEventType)}`) : ""
				}
			/>
			<Property label={t("workEvent.labels.description")} value={workEvent.description} />
			<Property
				label={t("workEvent.labels.workFacilityID")}
				relation={EntityType.WorkFacility}
				value={workEvent.workFacilityID}
			/>
			<Property
				label={t("workEvent.labels.workCellID")}
				relation={EntityType.WorkCell}
				value={workEvent.workCellID}
			/>
			<Property
				label={t("workEvent.labels.workResourceID")}
				relation={EntityType.WorkResource}
				value={workEvent.workResourceID}
			/>
			<Property
				label={t("workEvent.labels.workCellProgramID")}
				relation={EntityType.WorkCellProgram}
				value={workEvent.workCellProgramID}
			/>
			<Property
				label={t("workEvent.labels.timeBegin")}
				date={workEvent.timeBegin}
				dateExplicit
				dateTimezone={workFacility?.timezone}
			/>
			<Property
				label={t("workEvent.labels.timeEnd")}
				date={workEvent.timeEnd}
				dateExplicit
				dateTimezone={workFacility?.timezone}
			/>
			<Property label={t("workEvent.labels.canceled")} value={workEvent.canceled} />
		</div>
	)
}
