import React from "react"

import { Await, PageContainer, TagPreview } from "@app/components"
import { Tag, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const TagPrintPage: React.FC = () => {
	const [tag, tagLoading] = useEntity<Tag>(EntityType.Tag)

	return (
		<PageContainer padding={false}>
			<Await
				loading={tagLoading}
				then={() => (
					<div className="px-0">
						<TagPreview tag={tag} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
