import React from "react"
import _ from "lodash"

import { TrackingQRCode, Property, Label } from "@app/components"
import { useSession } from "@app/contexts"
import { fromUUID } from "@app/util"
import { EntityType, WorkFacility } from "@app/domain"

interface IWorkFacilityDetailsProps {
	workFacility?: WorkFacility
}

export const WorkFacilityDetails: React.FC<IWorkFacilityDetailsProps> = (props) => {
	const { t } = useSession()

	const { workFacility } = props

	if (_.isNil(workFacility)) {
		return null
	}

	const trackingCode = {
		locations: [
			{
				id: fromUUID(workFacility.id),
				description: `${workFacility.name}`,
				type: "WorkFacility",
			},
		],
	}

	return (
		<>
			<div className="pt-1 sm:px-2 max-w-lg">
				<Property label={t("workFacility.labels.name")} value={workFacility.name} />
				<Property label={t("workFacility.labels.timezone")} timezone={workFacility.timezone} />
				{_.size(workFacility.addresses) > 0 ? <Property group={t("address.header")} /> : null}
				{_.map(workFacility.addresses, (v, i) => {
					let label = t("address.defaultLabel")
					if (_.size(v.labels)) {
						label = `${v.labels.sort().join(", ")}`
					}
					return <Property key={i} label={label} address={v} />
				})}
				{_.size(workFacility.contactables) > 0 ? (
					<Property group={t("contactable.header")} />
				) : null}
				{_.map(workFacility.contactables, (v, i) => {
					let label = t("contactable.defaultLabel")
					if (_.size(v.labels)) {
						label = `${v.labels.sort().join(", ")}`
					}
					return (
						<Property key={i} label={label} relation={EntityType.Contact} value={v.contactID} />
					)
				})}
			</div>
			<div className="pl-2 inline-block">
				<Label className="block">{t("workAction.labels.trackingQRCodes")}</Label>
				<div className="space-x-4">
					<div className="p-4 border border-gray-400 inline-block text-center">
						<TrackingQRCode trackingCode={trackingCode} />
						<div className="font-bold pt-2">{trackingCode.locations[0].description}</div>
					</div>
				</div>
			</div>
		</>
	)
}
