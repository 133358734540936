import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"

import { Await, Title, PageContainer } from "@app/components"
import { newSaveReleaseParams, SaveRelease, SaveReleaseParams } from "@app/api"
import { Release, AlertLevel, EntityType } from "@app/domain"
import { ReleaseGeneralForm, ReleaseItemsForm } from "@app/forms"
import { ReleaseHeader } from "@app/headers"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const ReleaseEditPage: React.FC = () => {
	const { pathname } = useLocation()
	const segments = pathname.split("/")
	let section = ""
	if (_.size(segments) === 5) {
		section = _.last(segments) || ""
	}

	const { t, handleError } = useSession()
	const [release, releaseLoading] = useEntity<Release>(EntityType.Release)
	const { id, name, referenceID } = _.assign({ id: "", name: "...", referenceID: "" }, release)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveReleaseParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveRelease({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.releases[0]")
				setRedirect(urlTo("releases", saved), {
					alertLevel: AlertLevel.Success,
					title: t("release.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	const form = () => {
		if (section === "items") {
			return <ReleaseItemsForm release={release} />
		} else {
			return (
				<ReleaseGeneralForm
					defaultParams={newSaveReleaseParams(release)}
					referenceID={referenceID}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			)
		}
	}

	return (
		<PageContainer>
			<Title>{t("release.titles.edit", { name })}</Title>
			<ReleaseHeader release={release} section={section} view="edit" />
			<Await
				loading={releaseLoading}
				then={() => <div className="max-w-auto px-2">{form()}</div>}
			/>
		</PageContainer>
	)
}
