import React from "react"

interface IFormActionProps {
	children: React.ReactNode
}

export const FormActions: React.FC<IFormActionProps> = (props) => {
	const { children } = props
	return <div className="bg-gray-300 p-4 -mx-2 text-center mt-6">{children}</div>
}
