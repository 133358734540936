import _ from "lodash"

import { rpc, Cache, JoinRelations } from "@app/api"

import type { Entity } from "@app/domain"

const batches = {}
const timers = {}

export const Batch = (rpcMethod: string, key: string, id?: string): Promise<Entity> => {
	if (_.isEmpty(id)) {
		throw new Error("missing or invalid id")
	}
	let resolve, reject
	const promise = new Promise((res, rej) => {
		resolve = res
		reject = rej
	})
	if (!batches[rpcMethod]) {
		batches[rpcMethod] = {}
	}
	if (batches[rpcMethod][id]) {
		batches[rpcMethod][id].resolves.push(resolve)
		batches[rpcMethod][id].rejects.push(reject)
	} else {
		batches[rpcMethod][id] = {
			resolves: [resolve],
			rejects: [reject],
		}
	}
	clearTimeout(timers[rpcMethod])
	timers[rpcMethod] = _.delay(() => {
		const ids = _.map(batches[rpcMethod], (_v, k) => k)
		const currentBatch = batches[rpcMethod]
		batches[rpcMethod] = {}
		rpc(rpcMethod, {
			ids: ids,
		})
			.then((data) => {
				if (data.ok) {
					data = JoinRelations(data)
					Cache.scan(data)
					_.each(_.get(data, `result.${key}`, []), (entity: Entity) => {
						if (currentBatch[entity.id]) {
							_.each(currentBatch[entity.id].resolves, (resolve) => {
								resolve(entity as Entity)
							})
						}
						delete currentBatch[entity.id]
					})
					_.each(currentBatch, (v, k) => {
						_.each(v.resolves, (resolve) => {
							// Not found.
							resolve(undefined)
						})
						delete currentBatch[k]
					})
				}
			})
			.catch((err) => {
				_.each(currentBatch, (batch) => {
					_.each(batch.rejects, (reject) => {
						reject(err)
					})
				})
			})
	}, 50)
	return promise as Promise<Entity>
}
