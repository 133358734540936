import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"

import { Await, PageContainer, MaterialDetails } from "@app/components"
import { MaterialHeader } from "@app/headers"
import { Material, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const MaterialShowPage: React.FC = () => {
	const { pathname } = useLocation()
	const segments = pathname.split("/")
	const section = _.size(segments) === 4 ? _.last(segments) || "" : ""
	const [material, materialLoading] = useEntity<Material>(EntityType.Material)

	return (
		<PageContainer>
			<MaterialHeader material={material} view="show" section={section} />
			<Await
				loading={materialLoading}
				then={() => (
					<>
						<div className="px-2 sm:px-0">
							<MaterialDetails material={material} section={section} />
						</div>
					</>
				)}
			/>
		</PageContainer>
	)
}
