import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"
import queryString from "query-string"

export const useQuery = (): { [key: string]: string | string[] | null } => {
	const { search } = useLocation()
	const parsed = React.useMemo(() => {
		return _.reduce(
			queryString.parse(search),
			(acc, v, k) => {
				acc[k] = _.isString(v) ? v : _.compact(v)
				return acc
			},
			{},
		)
	}, [search])
	return { ...parsed }
}
