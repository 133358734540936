import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Tabs, Title, Icon } from "@app/components"
import { urlTo } from "@app/util"
import { WorkEvent, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IWorkEventHeaderProps {
	workEvent?: WorkEvent
	section?: string
	view: string
}

export const WorkEventHeader: React.FC<IWorkEventHeaderProps> = (props) => {
	const { workEvent, view, section = "" } = props
	const { id, description, created, updated } = _.assign(
		{ id: "", description: "...", created: "", updated: "" },
		workEvent,
	)

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.WorkEvent, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("workEvents/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workEvents", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workEvents/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("workEvents/edit", id), active: view === "edit" },
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("workEvent.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { description }),
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("workEvent.titles.new")
	} else if (view === "edit") {
		title = t("workEvent.titles.edit", { description })
	} else {
		title = t("workEvent.titles.show", { description })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={workEvent} entityType={EntityType.WorkEvent} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{description}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
