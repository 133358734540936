import React from "react"
import _ from "lodash"

import { modalsContext } from "."
import type { Modal } from "."

type ModalsProviderProps = {
	children: React.ReactNode
}

export const ModalsProvider: React.FC<ModalsProviderProps> = (props) => {
	const { children } = props

	const [modals, setModals] = React.useState<Modal[]>([])

	const setModal = (id: string, component: JSX.Element) => {
		setModals((prev) => {
			return _.reduce(
				prev,
				(acc: Modal[], modal) => {
					if (modal.id !== id) {
						acc.push(modal)
					}
					return acc
				},
				[{ id, component }],
			)
		})
	}

	const dismissModal = (id: string) => {
		setModals((prev) => {
			return _.reduce(
				prev,
				(acc: Modal[], modal) => {
					if (modal.id !== id) {
						acc.push(modal)
					}
					return acc
				},
				[],
			)
		})
	}

	const active = !_.isEmpty(modals)

	return (
		<modalsContext.Provider
			value={{
				active,
				dismissModal,
				modals,
				setModal,
			}}
		>
			<>
				{children}
				{_.isEmpty(modals) ? null : (
					<div
						className="fixed left-0 top-0 w-full h-full z-50"
						style={{ backgroundColor: "rgba(0,0,0,0.80)" }}
					>
						{modals.map((modal) => (
							<div key={modal.id}>{modal.component}</div>
						))}
					</div>
				)}
			</>
		</modalsContext.Provider>
	)
}
