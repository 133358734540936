import _ from "lodash"

import {
	AddressParams,
	AttributeParams,
	Batch,
	compactIDs,
	ContactableParams,
	ReceivedResult,
	rpc,
} from "@app/api"

import type { Result } from "@app/api"
import type { Release, Pagination, Entity, Customer, Job } from "@app/domain"

export type GenerateReleaseFromJobParams = {
	jobID: string
}

export const GenerateReleaseFromJob = async (
	params: GenerateReleaseFromJobParams,
): Promise<Result<SaveReleaseResult>> => {
	const data = await rpc("GenerateReleaseFromJob", params)
	return ReceivedResult<GetReleasesResult>(data)
}

export type SaveReleaseParams = {
	id?: string
	customerID: string
	jobID: string
	workFacilityID?: string

	addresses: AddressParams[]
	attributes: AttributeParams[]
	metric: boolean
	name: string
	keyDates: {
		received: string | null
		deliverBy: string | null
		produced: string | null
		shipped: string | null
	}

	contactables: ContactableParams[]
	removeContactables: string[]
}

export const newSaveReleaseParams = (release?: Release): SaveReleaseParams => {
	return {
		id: _.get(release, "id", ""),
		customerID: _.get(release, "customerID", ""),
		jobID: _.get(release, "jobID", ""),
		workFacilityID: _.get(release, "workFacilityID") || "",

		addresses: _.get(release, "addresses", []),
		attributes: _.get(release, "attributes", []),
		metric: _.get(release, "metric", false),
		name: _.get(release, "name", ""),
		keyDates: _.get(release, "keyDates", {
			received: new Date().toISOString(),
			deliverBy: null,
			produced: null,
			shipped: null,
		}),

		contactables: _.get(release, "contactables", []),
		removeContactables: [],
	}
}

export type SaveReleaseResult = {
	releases: Release[]
}

export const SaveRelease = async (
	params: SaveReleaseParams,
): Promise<Result<SaveReleaseResult>> => {
	const data = await rpc("SaveRelease", params, { minDuration: 300 })
	return ReceivedResult<GetReleasesResult>(data)
}

export type GetReleasesParams = {
	ids?: string[]
	includeIDs?: string[]

	customerID?: string
	contactID?: string
	jobID?: string
	workFacilityID?: string

	includeCustomers?: boolean
	includeJobs?: boolean

	timeBegin?: Date
	timeEnd?: Date
	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetReleasesResult = {
	customers?: Customer[]
	jobs?: Job[]
	releases: Release[]
	pagination: Pagination
}

export const GetReleases = async (
	params: GetReleasesParams,
): Promise<Result<GetReleasesResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetReleases", "releases", id).then((release?: Entity) => {
			return {
				ok: true,
				result: { releases: _.compact([release as Release]) },
			} as Result<GetReleasesResult>
		})
	}
	const data = await rpc("GetReleases", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetReleasesResult>(data)
}
