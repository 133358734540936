import React from "react"
import _ from "lodash"

import { Icon, Label } from "@app/components"

const check = (
	<svg className="h-4 w-4" fill="currentColor" viewBox="0 0 12 12">
		<path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
	</svg>
)

const cross = (
	<svg className="h-4 w-4" fill="none" viewBox="0 0 12 12">
		<path
			d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

interface IToggleProps {
	alt?: string
	value?: boolean
	defaultChecked?: boolean
	disabled?: boolean
	error?: string
	fieldInfo?: string
	hint?: string
	label?: string
	name?: string
	onChange?: (v: boolean) => void
}

export const Toggle: React.FC<IToggleProps> = (props) => {
	const {
		alt = "",
		value,
		defaultChecked = false,
		disabled = false,
		fieldInfo,
		hint,
		label,
		name,
		onChange = _.noop,
	} = props

	const [checked, setChecked] = React.useState<boolean>(defaultChecked)
	const [firstChange, setFirstChange] = React.useState<boolean>(true)

	React.useEffect(() => {
		if (firstChange) {
			setFirstChange(false)
			return
		}
		onChange(checked)
	}, [checked])

	React.useEffect(() => {
		if (value === undefined) {
			return
		}
		setChecked(value)
	}, [value])

	const outsideClass =
		"group bg-gray-300 relative inline-flex align-middle h-8 rounded-full w-12 focus:ring-yellow-500"
	let insideClass = `${
		checked ? "translate-x-5" : "translate-x-0"
	} inline-block w-8 h-8 shadow transform inset-0 flex items-center justify-center rounded-full`

	let labelClass = "ml-3"
	if (disabled) {
		insideClass += " text-gray-500 bg-gray-300 cursor-default"
		labelClass += " text-gray-500"
	} else {
		insideClass += (checked ? " bg-yellow-500" : " bg-gray-500") + " group-hover:bg-yellow-300"
	}

	return (
		<div className="mb-2">
			{disabled ? (
				<div className={outsideClass}>
					<span className={insideClass}>{checked ? check : cross}</span>
				</div>
			) : (
				<div onClick={() => setChecked(!checked)} className={`cursor-pointer ${outsideClass}`}>
					<span className={insideClass}>{checked ? check : cross}</span>
				</div>
			)}
			<Label className={labelClass} name={name} fieldInfo={fieldInfo}>
				{label}
				{alt && (
					<span title={alt} className="ml-1">
						<Icon name="InfoCircle" fixedWidth className="text-gray-500" />
					</span>
				)}
			</Label>
			<p className="ml-14 text-xs text-gray-500 mt-1 pl-1">{hint}&#8203;</p>
		</div>
	)
}
