import React from "react"
import _ from "lodash"

import { Icon, Label } from "@app/components"
import { useSession } from "@app/contexts"

interface IInputProps {
	alt?: string
	autoFocus?: boolean
	error?: string
	fieldInfo?: string
	label?: string
	name: string
	type: string
	hint?: string
	collapse?: boolean
}

type InputProps = IInputProps &
	React.HTMLProps<HTMLInputElement> &
	React.HTMLAttributes<HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
	const { t } = useSession()

	const {
		alt = "",
		autoFocus = false,
		collapse = false,
		className = "",
		error = "",
		fieldInfo,
		hint,
		label,
		name,
		readOnly,
		type = "text",
		...rest
	} = props
	const errorMessage = error?.replace("$field", label || t("validation.this"))

	let classes = "bg-white p-2 block w-full disabled:text-gray-500 disabled:bg-gray-300 focus:z-10 "
	if (_.isEmpty(error)) {
		classes +=
			"border border-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
	} else {
		classes +=
			"border border-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
	}

	if (readOnly) {
		classes += " text-gray-500"
	}

	return (
		<div className="relative">
			{_.isNil(label) || collapse ? null : (
				<Label name={name} fieldInfo={fieldInfo} className="flex gap-1">
					{label}&#8203;
					{alt && (
						<span title={alt}>
							<Icon name="InfoCircle" fixedWidth className="text-gray-500" />
						</span>
					)}
				</Label>
			)}
			<input
				ref={ref}
				aria-label={label}
				autoFocus={autoFocus}
				className={`${classes} ${className}`}
				key={name}
				name={name}
				readOnly={readOnly}
				noValidate
				type={type}
				{...rest}
			/>

			{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
				<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203;</p>
			) : null}
			{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
				<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203;</p>
			) : null}
		</div>
	)
})

Input.displayName = "Input"

export { Input }
