import React from "react"

import { useSession } from "@app/contexts"
import { PageContainer, Title, Breadcrumbs } from "@app/components"

export const FinancePage: React.FC = () => {
	const { t } = useSession()

	return (
		<PageContainer>
			<Title>{t("finance.title")}</Title>
			<Breadcrumbs>{t("finance.title")}</Breadcrumbs>
		</PageContainer>
	)
}
