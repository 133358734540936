import _ from "lodash"

export type Address = {
	city: string
	country: string
	labels: string[]
	line1: string
	line2: string
	postalCode: string
	state: string
}

export const newAddress = (): Address => {
	return {
		city: "",
		country: "",
		labels: [],
		line1: "",
		line2: "",
		postalCode: "",
		state: "",
	}
}

export const AddressUtils = {
	lines: (v: Address | undefined, t: (key: string) => string): string[] => {
		if (v) {
			let countryName = ""
			if (!_.isEmpty(v.country) && v.country !== "USA") {
				countryName = t(`countries.${v.country}`)
			}
			let cityState = v.city
			if (cityState) {
				cityState += `, ${v.state}`
			}
			cityState += ` ${v.postalCode}`
			const lines = [v.line1, v.line2, _.trim(cityState), countryName].filter((line) => {
				return !(line === "" || line.startsWith(",") || line.startsWith(" "))
			})
			return lines
		}
		return []
	},
}
