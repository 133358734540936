import React from "react"
import _ from "lodash"

import { ContactForm } from "@app/forms"
import { ContactHeader } from "@app/headers"
import { FormActions, PageContainer, Await, Title, Button } from "@app/components"
import { Contact, ContactUtils, AlertLevel, EntityType } from "@app/domain"
import { SaveContact, SaveContactParams, newSaveContactParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const ContactEditPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()

	const [contact, contactLoading] = useEntity<Contact>(EntityType.Contact)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveContactParams>(newSaveContactParams(contact))
	const [saving, setSaving] = React.useState<boolean>(false)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("contact.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setSaving(true)
		setErrors({})
		try {
			const resp = await SaveContact({ ...params, id: toUUID(_.get(contact, "id")) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.contacts[0]")
				setRedirect(urlTo("contacts", saved), {
					alertLevel: AlertLevel.Success,
					title: t("contact.notifications.success.updated", { name: ContactUtils.name(saved) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setSaving(false)
		return false
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("contact.titles.edit", { name: ContactUtils.name(contact) })}</Title>
			<ContactHeader contact={contact} view="edit" />
			<Await
				loading={contactLoading}
				then={() => (
					<form onSubmit={submitHandler} noValidate>
						<div className="max-w-md px-2">
							<ContactForm
								defaultParams={newSaveContactParams(contact)}
								errors={errors}
								disabled={saving}
								onChange={(nextParams) => {
									setParams(nextParams)
								}}
							/>
						</div>
						<FormActions>
							<Button
								errors={errors}
								label={t("contact.buttons.save")}
								disabled={saving}
								type="submit"
							/>
						</FormActions>
					</form>
				)}
			/>
		</PageContainer>
	)
}
