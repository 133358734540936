import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { WorkFacility } from "@app/domain"
import { GetWorkFacilities } from "@app/api"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface IWorkFacilitySelectProps {
	value?: string | string[]
	autoFocus?: boolean
	defaultValue?: string | string[]
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	isMulti?: boolean
	name: string
	onChange: (v: OptionType[]) => void
}

export const WorkFacilitySelect: React.FC<IWorkFacilitySelectProps> = (props) => {
	const { handleError } = useSession()
	const { autoFocus, disabled, error, hint, isMulti, label, name, onChange, value: valueIn } = props

	let defaultValue: string[] = []
	if (_.isArray(props.defaultValue)) {
		defaultValue = _.compact(props.defaultValue)
	} else if (_.isString(props.defaultValue)) {
		defaultValue = [props.defaultValue]
	}

	const [value, setValue] = React.useState<OptionType | undefined>(undefined)
	const [allOptions, setAllOptions] = React.useState<OptionType[] | undefined>()
	const [defaultOptions, setDefaultOptions] = React.useState<OptionType[] | undefined>()
	const [workFacilityOptions, setWorkFacilityOptions] = React.useState<OptionType[]>(() => {
		return _.map(defaultValue, (v) => {
			return { value: v, label: "" }
		})
	})

	const getWorkFacilities = (inputValue?: string): Promise<OptionType[]> => {
		const includeIDs = _.chain(workFacilityOptions).map("value").compact().value()
		return new Promise<OptionType[]>((resolve) => {
			GetWorkFacilities({
				includeIDs,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const workFacilities = _.get(resp, "result.workFacilities", []).map(
							(c: WorkFacility) => {
								return {
									label: c.name,
									value: c.id,
								}
							},
						)
						resolve(workFacilities)
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	React.useEffect(() => {
		onChange(workFacilityOptions)
	}, [workFacilityOptions])

	React.useEffect(() => {
		if (_.isEmpty(defaultValue)) {
			setDefaultOptions([])
			getWorkFacilities().then((facilities: OptionType[]) => setAllOptions(facilities))
			return
		}
		getWorkFacilities().then((facilities: OptionType[]) => {
			setAllOptions(facilities)
			setDefaultOptions(
				_.map(defaultValue, (v) => {
					let label = ""
					_.each(facilities, (f) => {
						if (f.value === v) {
							label = f.label
							return false
						}
					})
					return { value: v, label }
				}),
			)
		})
	}, [])

	React.useEffect(() => {
		if (valueIn) {
			setValue({
				label: allOptions?.find((f) => f.value === valueIn)?.label ?? "",
				value: valueIn,
			} as OptionType)
		}
	}, [valueIn])

	return (
		<AsyncSelect
			value={valueIn && value}
			autoFocus={autoFocus}
			cacheOptions={true}
			defaultOptions={true}
			defaultValue={defaultOptions}
			disabled={disabled}
			error={error}
			hint={hint}
			isMulti={isMulti}
			isSearchable={true}
			label={label}
			loading={!defaultOptions}
			loadOptions={getWorkFacilities}
			menuPlacement="auto"
			name={name}
			onChange={(v, op) => {
				const { option, action, removedValue } = op
				setWorkFacilityOptions((prev) => {
					const next = _.map(prev || [], (v) => _.pick(v, ["label", "value"]))
					if (action === "select-option") {
						const selected = option || v
						if (selected) {
							if (isMulti) {
								return next.concat([
									{
										label: _.get(selected, "label", ""),
										value: _.get(selected, "value", ""),
									},
								])
							} else {
								return [
									{
										label: _.get(selected, "label", ""),
										value: _.get(selected, "value", ""),
									},
								]
							}
						}
					} else if (action === "remove-value" && removedValue) {
						return _.filter(next, (v) => v && v.value !== removedValue.value)
					} else if (action === "clear") {
						return []
					}
					return next
				})
			}}
		/>
	)
}
