export type PhoneNumberParams = {
	phoneNumber: string
	labels: string[]
}

export const newPhoneNumberParams = (): PhoneNumberParams => {
	return {
		phoneNumber: "",
		labels: [],
	}
}
