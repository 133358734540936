import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { Release, EntityType } from "@app/domain"
import { GetReleases } from "@app/api"
import { useRelation } from "@app/hooks"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface IReleaseSelectProps {
	autoFocus?: boolean
	defaultValue?: string
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	onChange: (v: OptionType[]) => void
}

export const ReleaseSelect: React.FC<IReleaseSelectProps> = (props) => {
	const { handleError } = useSession()
	const { autoFocus, defaultValue = "", onChange, disabled, name, label, hint, error } = props

	const [releaseID, setReleaseID] = React.useState<string>(defaultValue)
	const [release, loadingRelease] = useRelation<Release>(EntityType.Release, defaultValue)

	const getReleases = (inputValue: string) => {
		const includeIDs = _.filter([releaseID], (v) => !_.isEmpty(v))
		return new Promise<OptionType[]>((resolve) => {
			GetReleases({
				includeIDs,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const releases = _.get(resp, "result.releases", []).map((c: Release) => {
							return {
								label: c.name,
								value: c.id,
							}
						})
						resolve(releases)
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	const defaultOption = {
		label: release?.name ?? defaultValue,
		value: releaseID,
	}

	if (loadingRelease) {
		return null
	}
	return (
		<AsyncSelect
			autoFocus={autoFocus}
			cacheOptions={true}
			defaultOptions={true}
			defaultValue={_.isEmpty(defaultValue) ? null : defaultOption}
			disabled={disabled}
			hint={hint}
			error={error}
			isSearchable={true}
			label={label}
			loadOptions={getReleases}
			menuPlacement="auto"
			name={name}
			onChange={(v) => {
				setReleaseID(_.get(v, "value", ""))
				onChange(_.compact([v]))
			}}
		/>
	)
}
