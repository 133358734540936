import React from "react"
import _ from "lodash"

import { Label, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType, Material, RebarMaterialItemBendFields, WorkFacility } from "@app/domain"
import { useRelation } from "@app/hooks"

interface IMaterialDetailsProps {
	material?: Material
	section?: string
}

export const MaterialDetails: React.FC<IMaterialDetailsProps> = (props) => {
	const { t } = useSession()
	const { material, section } = props
	const [workFacility] = useRelation<WorkFacility>(
		EntityType.WorkFacility,
		material?.workFacilityID,
	)

	if (_.isNil(material)) {
		return null
	}

	const units = material.metric ? "metric" : "imperial"

	if (section === "items") {
		return (
			<div className="divide-y divide-gray-300 border-t border-b border-gray-300">
				<div className="flex flex-row gap-x-2 md:gap-4 py-2 px-4 text-gray-700">
					<div className="flex-none w-4 text-center">&nbsp;</div>
					<div className="flex-grow text-left">{t("materialItem.labels.description")}</div>
					<div className="flex-none w-16 md:w-28 text-left">
						{t("materialItem.labels.controlCodes")}
					</div>
					<div className="flex-none w-16 md:w-16 text-right">{t("materialItem.labels.weight")}</div>
					<div className="flex-none w-16 md:w-16 text-right">
						{t("materialItem.labels.quantity")}
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="flex gap-x-4">
			<div className="pt-1 max-w-lg">
				<Property label={t("material.labels.controlCode")} value={material.controlCode} />
				<Property label={t("material.labels.supplierName")} value={material.supplierName} />
				<Property
					label={t("material.labels.supplierReferenceID")}
					value={material.supplierReferenceID}
				/>
				<Property label={t("common.labels.metric")} value={material.metric} />
				<Property label={t("material.labels.items")} value={_.size(material.items)} />
				<Property
					label={t("material.labels.received")}
					date={material.received}
					dateTimezone={workFacility?.timezone}
				/>
				<Property
					label={t("material.labels.status")}
					value={t(`materialStatuses.${material.status}`)}
				/>
				<Property group={t("common.headers.associations")} />
				<Property
					label={t("material.labels.customerID")}
					relation={EntityType.Customer}
					value={material.customerID}
				/>
				<Property
					label={t("material.labels.jobID")}
					relation={EntityType.Job}
					value={material.jobID}
				/>
				<Property
					label={t("material.labels.workFacilityID")}
					relation={EntityType.WorkFacility}
					value={material.workFacilityID}
				/>
			</div>
			<div className="flex-grow space-y-8 pb-4">
				{_.map(material.items, (item, i) => (
					<div key={item.id} className="flex flex-row">
						<div className="inline-flex items-center text-sm w-8">{i + 1}</div>
						<div className="space-y-2">
							<div className="w-full max-w-4xl grid grid-flow-col auto-cols-max gap-x-1">
								{_.map(["quantity", "size", "length", "mark", "shape", "weight"], (field) => {
									let label = t(`materialItem.labels.${field}`)
									if (field === "weight") {
										label = `${t("materialItem.labels.weight")} (${t(`units.${units}.weightAbbr`)})`
									}
									return (
										<div key={field} className="border border-gray-400 py-1 px-2 w-32">
											<Label className="text-sm uppercase text-gray-600">{label}</Label>
											<div>{_.get(item, `rebarMaterialItem.${field}`) || "\u00A0"}</div>
										</div>
									)
								})}
							</div>
							<div className="w-full max-w-4xl grid grid-flow-col auto-cols-max gap-x-1">
								{_.map(RebarMaterialItemBendFields, (field) => {
									return (
										<div key={field} className="border border-gray-400 py-1 px-2 w-16">
											<Label className="text-sm uppercase text-gray-600">{field}</Label>
											<div>{_.get(item, `rebarMaterialItem.${field}`) || "\u00A0"}</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
