import { EntityType } from "@app/domain"

export type WorkAction = {
	id: string

	actorIsSubject: boolean
	description: string
	disabled: boolean
	entityTypes: EntityType[]
	maxDurationSeconds: number
	resolveReleaseUnitStatus: boolean
	name: string

	created: string
	updated: string
}

export const WorkActionMaxDurationSecondsOptions = [
	{ label: "Timestamp (no duration)", value: "0" },
	{ label: "30 min", value: "1800" },
	{ label: "1 hour", value: "3600" },
	{ label: "2 hours", value: "7200" },
	{ label: "4 hours", value: "14400" },
	{ label: "8 hours", value: "28800" },
	{ label: "12 hours", value: "43200" },
]

export const newWorkAction = (): WorkAction => {
	return {
		id: "",

		actorIsSubject: false,
		description: "",
		disabled: false,
		entityTypes: [],
		maxDurationSeconds: 0,
		resolveReleaseUnitStatus: false,
		name: "",

		created: "",
		updated: "",
	}
}
