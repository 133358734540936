import React from "react"
import _ from "lodash"

import { ColorOption, Label } from "@app/components"
import { useSession } from "@app/contexts"
import { TagColor } from "@app/domain"

interface IColorSelect {
	defaultValue?: TagColor
	onChange: (color: TagColor) => void
	error?: string
	label?: string
	hint?: string
	disabled?: boolean
}

export const ColorSelect: React.FC<IColorSelect> = (props) => {
	const { disabled, hint, defaultValue = TagColor.None, onChange, label, error } = props

	const { t } = useSession()
	const [selected, setSelected] = React.useState<TagColor>(defaultValue)
	const errorMessage = error?.replace("$field", label || t("validation.this"))

	React.useEffect(() => {
		onChange(selected)
	}, [selected])

	return (
		<div>
			<Label name="color">{t("tag.labels.color")}</Label>
			<div className={`space-x-1 border border-gray-400 p-2 ${disabled ? "bg-gray-300" : ""}`}>
				{_.map(TagColor, (color) => (
					<ColorOption
						key={color}
						checked={selected === color}
						color={color}
						disabled={disabled}
						onChange={() => {
							setSelected(color)
						}}
					/>
				))}
			</div>
			{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
				<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203;</p>
			) : null}
			{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
				<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203;</p>
			) : null}
		</div>
	)
}
