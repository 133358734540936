/* eslint-disable */
/* This is a generated file. DO NOT EDIT. Edit grpc-ts-mods.sh instead. */
/* @ts-nocheck */
import type { CallContext, CallOptions } from "nice-grpc-common";
import * as _m0 from "protobufjs/minimal";
import { Value } from "./form";

export const protobufPackage = "";

export enum TaskStatus {
  TaskStatusUnknown = 0,
  TaskStatusRunning = 1,
  TaskStatusSuccess = 2,
  TaskStatusFailure = 3,
  UNRECOGNIZED = -1,
}

export enum ModelType {
  STP = 0,
  IGES = 1,
  IFC = 2,
  UNRECOGNIZED = -1,
}

export enum ImageType {
  ASSEMBLY = 0,
  GRID_WELDS = 1,
  FABRICATION_TOP_DOWN = 2,
  UNRECOGNIZED = -1,
}

export enum Robot {
  RobotW1Loader = 0,
  RobotW2Welder1 = 1,
  RobotW2Welder2 = 2,
  RobotW3Connector = 3,
  RobotW3Handler = 4,
  RobotW3Loader = 5,
  UNRECOGNIZED = -1,
}

export enum Layer {
  LayerW1Cage = 0,
  LayerW1CageClamps = 1,
  LayerW1CageFixture = 2,
  LayerW2Grid = 10,
  LayerW2GridFixture = 11,
  LayerLines = 20,
  LayerLabels = 21,
  LayerSpheres = 22,
  LayerInfo = 23,
  LayerOrigins = 24,
  LayerCollision = 25,
  LayerAnimation = 26,
  LayerAnimationBoundaries = 27,
  LayerRobotJointData = 28,
  LayerWeight = 29,
  UNRECOGNIZED = -1,
}

export interface Empty {
}

export interface TaskRequest {
  id: string;
}

export interface TaskResponse {
  status: TaskStatus;
  csv?: CSVResponse | undefined;
  layers?: LayerResponse | undefined;
  hashes?: HashResponse | undefined;
  simulation_check?: SimulationCheckResponse | undefined;
  bytes?: Uint8Array | undefined;
}

export interface TaskIDResponse {
  id: string;
}

export interface SimulationCheckResponse {
  success: boolean;
}

export interface GridModelRequest {
  grid: GridRequest | undefined;
  model_type: ModelType;
}

export interface CageModelRequest {
  cage: CageRequest | undefined;
  model_type: ModelType;
}

export interface GridImageRequest {
  grid: GridRequest | undefined;
  format: string;
  height: number;
  width: number;
  image_type: ImageType;
  failures: string[];
  successes: string[];
}

export interface CageImageRequest {
  cage: CageRequest | undefined;
  format: string;
  height: number;
  width: number;
  image_type: ImageType;
}

/** CSV format, sent as a string. */
export interface CSVResponse {
  csvs: { [key: number]: string };
}

export interface CSVResponse_CsvsEntry {
  key: number;
  value: string;
}

export interface HashResponse {
  hashes: { [key: number]: string };
  messages: Messages | undefined;
  modified_params: { [key: string]: Value };
}

export interface HashResponse_HashesEntry {
  key: number;
  value: string;
}

export interface HashResponse_ModifiedParamsEntry {
  key: string;
  value: Value | undefined;
}

export interface Point {
  coords: number[];
}

export interface Line {
  points: Point[];
  color: number;
  opacity: number;
  width: number;
}

export interface Lines {
  lines: Line[];
}

export interface Label {
  point: number[];
  label: string;
  length: boolean;
  color: string;
  font_size: number;
}

export interface Labels {
  labels: Label[];
}

export interface Sphere {
  point: number[];
  radius: number;
  color: number;
  opacity: number;
  disabled: boolean;
  immutable_identifier: number;
}

export interface Spheres {
  spheres: Sphere[];
}

export interface Origin {
  point: number[];
  x_axis: number[];
  y_axis: number[];
  z_axis: number[];
}

export interface Origins {
  origins: Origin[];
}

export interface RobotJointData {
  robot_joint_data: { [key: string]: number };
}

export interface RobotJointData_RobotJointDataEntry {
  key: string;
  value: number;
}

export interface Messages {
  warnings: Message[];
  errors: Message[];
}

export interface Message {
  param: string;
  message: string;
}

/** GLTF/GLB format, sent as a string. */
export interface LayerResponse {
  gltfs: { [key: number]: Uint8Array };
  lines: Lines[];
  labels: Labels[];
  spheres: Spheres[];
  info: string;
  origins: Origins[];
  collisions: number[];
  robot_joint_data: RobotJointData[];
  weight: number;
}

export interface LayerResponse_GltfsEntry {
  key: number;
  value: Uint8Array;
}

export interface ProgramPathLayerRequest {
  params: ProgramPathRequest | undefined;
  layers: Layer[];
}

/** Path generation parameters. */
export interface ProgramPathRequest {
  program_csvs: string[];
  view_connections: boolean;
  view_connections_sequence: boolean;
  view_connections_grouping: boolean;
  view_connections_frames: boolean;
  view_battleship_coordinate: boolean;
  view_robot_work_objects: boolean;
  view_world_work_object: boolean;
  workcell_type: string;
  view_sequence: boolean;
  view_coordinates: boolean;
}

export interface GridLayerRequest {
  params: GridRequest | undefined;
  layers: Layer[];
}

/** Grid generation parameters. */
export interface GridRequest {
  rebar_a_asa_type: string;
  rebar_a_diameter: number;
  rebar_a_pin_diameter: number;
  rebar_a_A: number;
  rebar_a_B: number;
  rebar_a_C: number;
  rebar_a_D: number;
  rebar_a_E: number;
  rebar_a_F: number;
  rebar_a_G: number;
  rebar_a_H: number;
  rebar_a_J: number;
  rebar_a_K: number;
  rebar_a_O: number;
  rebar_a_R: number;
  rebar_a_rotation: number;
  rebar_a_bar_size_key: string;
  rebar_b_asa_type: string;
  rebar_b_diameter: number;
  rebar_b_pin_diameter: number;
  rebar_b_A: number;
  rebar_b_B: number;
  rebar_b_C: number;
  rebar_b_D: number;
  rebar_b_E: number;
  rebar_b_F: number;
  rebar_b_G: number;
  rebar_b_H: number;
  rebar_b_J: number;
  rebar_b_K: number;
  rebar_b_O: number;
  rebar_b_R: number;
  rebar_b_rotation: number;
  rebar_b_bar_size_key: string;
  /** list */
  rebar_a_quantity_at_spacing: string[];
  /** list */
  rebar_b_quantity_at_spacing: string[];
  /** list */
  rebar_a_quantity: number[];
  /** list */
  rebar_a_spacing: number[];
  rebar_a_on_top: boolean;
  /** list */
  rebar_b_quantity: number[];
  /** list */
  rebar_b_spacing: number[];
  clear_disconnected_bars: boolean;
  assembly_additional_weight: number;
  connections_connect_perimeter: boolean;
  connections_connect_rows: boolean;
  connections_connect_every_n_row_x: number;
  connections_connect_every_n_row_y: number;
  connect_specific_row_x: string;
  connect_specific_row_y: string;
  connections_connect_diagonal_x: boolean;
  connections_connect_diagonal_y: boolean;
  connections_connect_every_n_diagonal: number;
  connections_shift_on_x: number;
  connections_shift_on_y: number;
  connections_center_on_x: boolean;
  connections_center_on_y: boolean;
  connections_sorting_welder_1: string;
  connections_sorting_welder_2: string;
  min_connection_clearance: number;
  connections_add_individual_connections: string[];
  interactively_selected_connections: { [key: number]: boolean };
  geometry_source_stp: string;
  geometry_source_ifc: string;
  workspace: number;
  view_connections: boolean;
  view_connections_sequence: boolean;
  view_assembly_dimensions: boolean;
  view_dimensions_type: string;
  grid_preview_mesh_quality: string;
  view_part_naming: boolean;
  view_robot: boolean;
  view_axes_widget: boolean;
  view_connectivity: boolean;
  metric: boolean;
  view_battleship_coordinate: boolean;
  view_connections_frames: boolean;
  view_robot_work_objects: boolean;
  view_connections_grouping: boolean;
  fabrication_weaver: string;
  fabrication_welder_1: boolean;
  fabrication_welder_2: boolean;
  fabrication_inserts: string;
  fabrication_assembly_rotation: number;
  fabrication_x_center_grid: boolean;
  fabrication_y_center_grid: boolean;
  fabrication_x_shift_grid: number;
  fabrication_y_shift_grid: number;
  fabrication_z_shift_grid: number;
  fabrication_x_bar_assembly: string;
  fabrication_y_bar_assembly: string;
  fabrication_x_bar_naming: string[];
  fabrication_y_bar_naming: string[];
  /** list */
  fabrication_welder_1_PYR: number[];
  /** list */
  fabrication_welder_1_coordinates_shift: number[];
  /** list */
  fabrication_welder_2_PYR: number[];
  /** list */
  fabrication_welder_2_coordinates_shift: number[];
  fabrication_welding_time_seconds: number;
  fabrication_movement_time_seconds: number;
  connections_shifting_welder_1: number;
  connections_shifting_welder_2: number;
  fabrication_notes: string;
  fabrication_assembly_flip: boolean;
  mask_id: string;
  /** list */
  mask_origin_point: number[];
  mask_dimension_x: number;
  mask_dimension_y: number;
  mask_diameter: number;
  mask_angle_start: number;
  mask_angle_end: number;
  mask_rotation: number;
  mask_stp: Uint8Array;
  nesting_n_rows: number;
  nesting_n_columns: number;
  nesting_rows_offset: number;
  nesting_columns_offset: number;
  nesting_stagger_columns: boolean;
  nesting_replicator_units: string;
  nesting_assign_welds_by_unit: boolean;
  nesting_replicator_pattern: string;
  imported_assembly_geometry_unit: string;
  imported_assembly_geometry_up: string;
}

export interface GridRequest_InteractivelySelectedConnectionsEntry {
  key: number;
  value: boolean;
}

export interface CageLayerRequest {
  params: CageRequest | undefined;
  layers: Layer[];
}

/** Cage generation parameters. */
export interface CageRequest {
  stirrup_asa_type: string;
  stirrup_diameter: number;
  stirrup_pin_diameter: number;
  stirrup_A: number;
  stirrup_B: number;
  stirrup_C: number;
  stirrup_D: number;
  stirrup_E: number;
  stirrup_F: number;
  stirrup_G: number;
  stirrup_H: number;
  stirrup_J: number;
  stirrup_K: number;
  stirrup_O: number;
  stirrup_R: number;
  stirrup_bar_size_key: string;
  bar_asa_type: string;
  bar_diameter: number;
  bar_pin_diameter: number;
  bar_A: number;
  bar_B: number;
  bar_C: number;
  bar_D: number;
  bar_E: number;
  bar_F: number;
  bar_G: number;
  bar_H: number;
  bar_J: number;
  bar_K: number;
  bar_O: number;
  bar_R: number;
  bar_rotation: number;
  bar_bar_size_key: string;
  /** list */
  stirrup_quantity_at_spacing: string[];
  stirrup_quantity: number[];
  stirrup_spacing: number[];
  stirrup_rotation: number[];
  rebar_quantity_total: number;
  rebar_quantity_length: number;
  rebar_quantity_width: number;
  rebar_rotate_half_sector: boolean;
  assembly_additional_weight: number;
  geometry_source_stp: string;
  geometry_source_ifc: string;
  workspace: number;
  view_fabrication_setup_dimensions: boolean;
  view_dimensions_type: string;
  cage_preview_mesh_quality: string;
  view_part_naming: boolean;
  view_robot: boolean;
  view_bar_sequence: boolean;
  view_bar_path: boolean;
  view_stirrups_grouping: boolean;
  view_assembly_dimensions: boolean;
  view_axes_widget: boolean;
  metric: boolean;
  fabrication_weaver: string;
  fabrication_jig_foot_spacing: number;
  fabrication_assembly_shift_x: number;
  fabrication_assembly_rotation_x: number;
  fabrication_assembly_flip_on_z: boolean;
  fabrication_naming_stirrups: string[];
  fabrication_naming_bars: string[];
  fabrication_assembly_stirrups: string;
  fabrication_assembly_bars: string;
  bar_preplace_offset: number;
  loader_coordinate_grip_offset: number;
  loader_connection_PYR: number[];
  loader_track_position: number;
  loader_add_approach_point: boolean;
  loader_approach_coordinates: number[];
  loader_coordinate_shift: number[];
  bars_sorting: string;
  automatic_cage_positioning: boolean;
  fabrication_notes: string;
  select_insertion_point: number;
  fabrication_holders_flaps: string;
  fabrication_bar_insertion_time_seconds: number;
  fabrication_tie_time_seconds: number;
  fabrication_tie_percentage: number;
  imported_assembly_geometry_unit: string;
  imported_assembly_geometry_up: string;
}

function createBaseEmpty(): Empty {
  return {};
}

export const Empty = {
  encode(_: Empty, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Empty {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEmpty();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Empty>): Empty {
    return Empty.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<Empty>): Empty {
    const message = createBaseEmpty();
    return message;
  },
};

function createBaseTaskRequest(): TaskRequest {
  return { id: "" };
}

export const TaskRequest = {
  encode(message: TaskRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TaskRequest>): TaskRequest {
    return TaskRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TaskRequest>): TaskRequest {
    const message = createBaseTaskRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseTaskResponse(): TaskResponse {
  return {
    status: 0,
    csv: undefined,
    layers: undefined,
    hashes: undefined,
    simulation_check: undefined,
    bytes: undefined,
  };
}

export const TaskResponse = {
  encode(message: TaskResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.csv !== undefined) {
      CSVResponse.encode(message.csv, writer.uint32(34).fork()).ldelim();
    }
    if (message.layers !== undefined) {
      LayerResponse.encode(message.layers, writer.uint32(42).fork()).ldelim();
    }
    if (message.hashes !== undefined) {
      HashResponse.encode(message.hashes, writer.uint32(50).fork()).ldelim();
    }
    if (message.simulation_check !== undefined) {
      SimulationCheckResponse.encode(message.simulation_check, writer.uint32(58).fork()).ldelim();
    }
    if (message.bytes !== undefined) {
      writer.uint32(66).bytes(message.bytes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.csv = CSVResponse.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.layers = LayerResponse.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.hashes = HashResponse.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.simulation_check = SimulationCheckResponse.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.bytes = reader.bytes();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TaskResponse>): TaskResponse {
    return TaskResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TaskResponse>): TaskResponse {
    const message = createBaseTaskResponse();
    message.status = object.status ?? 0;
    message.csv = (object.csv !== undefined && object.csv !== null) ? CSVResponse.fromPartial(object.csv) : undefined;
    message.layers = (object.layers !== undefined && object.layers !== null)
      ? LayerResponse.fromPartial(object.layers)
      : undefined;
    message.hashes = (object.hashes !== undefined && object.hashes !== null)
      ? HashResponse.fromPartial(object.hashes)
      : undefined;
    message.simulation_check = (object.simulation_check !== undefined && object.simulation_check !== null)
      ? SimulationCheckResponse.fromPartial(object.simulation_check)
      : undefined;
    message.bytes = object.bytes ?? undefined;
    return message;
  },
};

function createBaseTaskIDResponse(): TaskIDResponse {
  return { id: "" };
}

export const TaskIDResponse = {
  encode(message: TaskIDResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TaskIDResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTaskIDResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<TaskIDResponse>): TaskIDResponse {
    return TaskIDResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<TaskIDResponse>): TaskIDResponse {
    const message = createBaseTaskIDResponse();
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseSimulationCheckResponse(): SimulationCheckResponse {
  return { success: false };
}

export const SimulationCheckResponse = {
  encode(message: SimulationCheckResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.success === true) {
      writer.uint32(8).bool(message.success);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SimulationCheckResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSimulationCheckResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.success = reader.bool();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SimulationCheckResponse>): SimulationCheckResponse {
    return SimulationCheckResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SimulationCheckResponse>): SimulationCheckResponse {
    const message = createBaseSimulationCheckResponse();
    message.success = object.success ?? false;
    return message;
  },
};

function createBaseGridModelRequest(): GridModelRequest {
  return { grid: undefined, model_type: 0 };
}

export const GridModelRequest = {
  encode(message: GridModelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.grid !== undefined) {
      GridRequest.encode(message.grid, writer.uint32(10).fork()).ldelim();
    }
    if (message.model_type !== 0) {
      writer.uint32(16).int32(message.model_type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GridModelRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGridModelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.grid = GridRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.model_type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GridModelRequest>): GridModelRequest {
    return GridModelRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GridModelRequest>): GridModelRequest {
    const message = createBaseGridModelRequest();
    message.grid = (object.grid !== undefined && object.grid !== null)
      ? GridRequest.fromPartial(object.grid)
      : undefined;
    message.model_type = object.model_type ?? 0;
    return message;
  },
};

function createBaseCageModelRequest(): CageModelRequest {
  return { cage: undefined, model_type: 0 };
}

export const CageModelRequest = {
  encode(message: CageModelRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cage !== undefined) {
      CageRequest.encode(message.cage, writer.uint32(10).fork()).ldelim();
    }
    if (message.model_type !== 0) {
      writer.uint32(16).int32(message.model_type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CageModelRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCageModelRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.cage = CageRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.model_type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CageModelRequest>): CageModelRequest {
    return CageModelRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CageModelRequest>): CageModelRequest {
    const message = createBaseCageModelRequest();
    message.cage = (object.cage !== undefined && object.cage !== null)
      ? CageRequest.fromPartial(object.cage)
      : undefined;
    message.model_type = object.model_type ?? 0;
    return message;
  },
};

function createBaseGridImageRequest(): GridImageRequest {
  return { grid: undefined, format: "", height: 0, width: 0, image_type: 0, failures: [], successes: [] };
}

export const GridImageRequest = {
  encode(message: GridImageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.grid !== undefined) {
      GridRequest.encode(message.grid, writer.uint32(10).fork()).ldelim();
    }
    if (message.format !== "") {
      writer.uint32(18).string(message.format);
    }
    if (message.height !== 0) {
      writer.uint32(24).int32(message.height);
    }
    if (message.width !== 0) {
      writer.uint32(32).int32(message.width);
    }
    if (message.image_type !== 0) {
      writer.uint32(40).int32(message.image_type);
    }
    for (const v of message.failures) {
      writer.uint32(50).string(v!);
    }
    for (const v of message.successes) {
      writer.uint32(58).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GridImageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGridImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.grid = GridRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.format = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.height = reader.int32();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.width = reader.int32();
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.image_type = reader.int32() as any;
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.failures.push(reader.string());
          continue;
        case 7:
          if (tag != 58) {
            break;
          }

          message.successes.push(reader.string());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GridImageRequest>): GridImageRequest {
    return GridImageRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GridImageRequest>): GridImageRequest {
    const message = createBaseGridImageRequest();
    message.grid = (object.grid !== undefined && object.grid !== null)
      ? GridRequest.fromPartial(object.grid)
      : undefined;
    message.format = object.format ?? "";
    message.height = object.height ?? 0;
    message.width = object.width ?? 0;
    message.image_type = object.image_type ?? 0;
    message.failures = object.failures?.map((e) => e) || [];
    message.successes = object.successes?.map((e) => e) || [];
    return message;
  },
};

function createBaseCageImageRequest(): CageImageRequest {
  return { cage: undefined, format: "", height: 0, width: 0, image_type: 0 };
}

export const CageImageRequest = {
  encode(message: CageImageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.cage !== undefined) {
      CageRequest.encode(message.cage, writer.uint32(10).fork()).ldelim();
    }
    if (message.format !== "") {
      writer.uint32(18).string(message.format);
    }
    if (message.height !== 0) {
      writer.uint32(24).int32(message.height);
    }
    if (message.width !== 0) {
      writer.uint32(32).int32(message.width);
    }
    if (message.image_type !== 0) {
      writer.uint32(40).int32(message.image_type);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CageImageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCageImageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.cage = CageRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.format = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.height = reader.int32();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.width = reader.int32();
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.image_type = reader.int32() as any;
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CageImageRequest>): CageImageRequest {
    return CageImageRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CageImageRequest>): CageImageRequest {
    const message = createBaseCageImageRequest();
    message.cage = (object.cage !== undefined && object.cage !== null)
      ? CageRequest.fromPartial(object.cage)
      : undefined;
    message.format = object.format ?? "";
    message.height = object.height ?? 0;
    message.width = object.width ?? 0;
    message.image_type = object.image_type ?? 0;
    return message;
  },
};

function createBaseCSVResponse(): CSVResponse {
  return { csvs: {} };
}

export const CSVResponse = {
  encode(message: CSVResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.csvs).forEach(([key, value]) => {
      CSVResponse_CsvsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CSVResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCSVResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          const entry1 = CSVResponse_CsvsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.csvs[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CSVResponse>): CSVResponse {
    return CSVResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CSVResponse>): CSVResponse {
    const message = createBaseCSVResponse();
    message.csvs = Object.entries(object.csvs ?? {}).reduce<{ [key: number]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[Number(key)] = String(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseCSVResponse_CsvsEntry(): CSVResponse_CsvsEntry {
  return { key: 0, value: "" };
}

export const CSVResponse_CsvsEntry = {
  encode(message: CSVResponse_CsvsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CSVResponse_CsvsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCSVResponse_CsvsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CSVResponse_CsvsEntry>): CSVResponse_CsvsEntry {
    return CSVResponse_CsvsEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CSVResponse_CsvsEntry>): CSVResponse_CsvsEntry {
    const message = createBaseCSVResponse_CsvsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseHashResponse(): HashResponse {
  return { hashes: {}, messages: undefined, modified_params: {} };
}

export const HashResponse = {
  encode(message: HashResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.hashes).forEach(([key, value]) => {
      HashResponse_HashesEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.messages !== undefined) {
      Messages.encode(message.messages, writer.uint32(18).fork()).ldelim();
    }
    Object.entries(message.modified_params).forEach(([key, value]) => {
      HashResponse_ModifiedParamsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HashResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHashResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          const entry1 = HashResponse_HashesEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.hashes[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.messages = Messages.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          const entry3 = HashResponse_ModifiedParamsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.modified_params[entry3.key] = entry3.value;
          }
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<HashResponse>): HashResponse {
    return HashResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<HashResponse>): HashResponse {
    const message = createBaseHashResponse();
    message.hashes = Object.entries(object.hashes ?? {}).reduce<{ [key: number]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[Number(key)] = String(value);
      }
      return acc;
    }, {});
    message.messages = (object.messages !== undefined && object.messages !== null)
      ? Messages.fromPartial(object.messages)
      : undefined;
    message.modified_params = Object.entries(object.modified_params ?? {}).reduce<{ [key: string]: Value }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Value.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseHashResponse_HashesEntry(): HashResponse_HashesEntry {
  return { key: 0, value: "" };
}

export const HashResponse_HashesEntry = {
  encode(message: HashResponse_HashesEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HashResponse_HashesEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHashResponse_HashesEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<HashResponse_HashesEntry>): HashResponse_HashesEntry {
    return HashResponse_HashesEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<HashResponse_HashesEntry>): HashResponse_HashesEntry {
    const message = createBaseHashResponse_HashesEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseHashResponse_ModifiedParamsEntry(): HashResponse_ModifiedParamsEntry {
  return { key: "", value: undefined };
}

export const HashResponse_ModifiedParamsEntry = {
  encode(message: HashResponse_ModifiedParamsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HashResponse_ModifiedParamsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHashResponse_ModifiedParamsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<HashResponse_ModifiedParamsEntry>): HashResponse_ModifiedParamsEntry {
    return HashResponse_ModifiedParamsEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<HashResponse_ModifiedParamsEntry>): HashResponse_ModifiedParamsEntry {
    const message = createBaseHashResponse_ModifiedParamsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBasePoint(): Point {
  return { coords: [] };
}

export const Point = {
  encode(message: Point, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.coords) {
      writer.float(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Point {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePoint();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 13) {
            message.coords.push(reader.float());
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.coords.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Point>): Point {
    return Point.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Point>): Point {
    const message = createBasePoint();
    message.coords = object.coords?.map((e) => e) || [];
    return message;
  },
};

function createBaseLine(): Line {
  return { points: [], color: 0, opacity: 0, width: 0 };
}

export const Line = {
  encode(message: Line, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.points) {
      Point.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.color !== 0) {
      writer.uint32(16).int32(message.color);
    }
    if (message.opacity !== 0) {
      writer.uint32(29).float(message.opacity);
    }
    if (message.width !== 0) {
      writer.uint32(37).float(message.width);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Line {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLine();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.points.push(Point.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.color = reader.int32();
          continue;
        case 3:
          if (tag != 29) {
            break;
          }

          message.opacity = reader.float();
          continue;
        case 4:
          if (tag != 37) {
            break;
          }

          message.width = reader.float();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Line>): Line {
    return Line.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Line>): Line {
    const message = createBaseLine();
    message.points = object.points?.map((e) => Point.fromPartial(e)) || [];
    message.color = object.color ?? 0;
    message.opacity = object.opacity ?? 0;
    message.width = object.width ?? 0;
    return message;
  },
};

function createBaseLines(): Lines {
  return { lines: [] };
}

export const Lines = {
  encode(message: Lines, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lines) {
      Line.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Lines {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLines();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.lines.push(Line.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Lines>): Lines {
    return Lines.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Lines>): Lines {
    const message = createBaseLines();
    message.lines = object.lines?.map((e) => Line.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLabel(): Label {
  return { point: [], label: "", length: false, color: "", font_size: 0 };
}

export const Label = {
  encode(message: Label, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.point) {
      writer.float(v);
    }
    writer.ldelim();
    if (message.label !== "") {
      writer.uint32(18).string(message.label);
    }
    if (message.length === true) {
      writer.uint32(24).bool(message.length);
    }
    if (message.color !== "") {
      writer.uint32(34).string(message.color);
    }
    if (message.font_size !== 0) {
      writer.uint32(40).int32(message.font_size);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Label {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabel();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 13) {
            message.point.push(reader.float());
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.point.push(reader.float());
            }

            continue;
          }

          break;
        case 2:
          if (tag != 18) {
            break;
          }

          message.label = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.length = reader.bool();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.color = reader.string();
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.font_size = reader.int32();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Label>): Label {
    return Label.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Label>): Label {
    const message = createBaseLabel();
    message.point = object.point?.map((e) => e) || [];
    message.label = object.label ?? "";
    message.length = object.length ?? false;
    message.color = object.color ?? "";
    message.font_size = object.font_size ?? 0;
    return message;
  },
};

function createBaseLabels(): Labels {
  return { labels: [] };
}

export const Labels = {
  encode(message: Labels, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.labels) {
      Label.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Labels {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabels();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.labels.push(Label.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Labels>): Labels {
    return Labels.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Labels>): Labels {
    const message = createBaseLabels();
    message.labels = object.labels?.map((e) => Label.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSphere(): Sphere {
  return { point: [], radius: 0, color: 0, opacity: 0, disabled: false, immutable_identifier: 0 };
}

export const Sphere = {
  encode(message: Sphere, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.point) {
      writer.float(v);
    }
    writer.ldelim();
    if (message.radius !== 0) {
      writer.uint32(21).float(message.radius);
    }
    if (message.color !== 0) {
      writer.uint32(24).int32(message.color);
    }
    if (message.opacity !== 0) {
      writer.uint32(37).float(message.opacity);
    }
    if (message.disabled === true) {
      writer.uint32(40).bool(message.disabled);
    }
    if (message.immutable_identifier !== 0) {
      writer.uint32(48).int32(message.immutable_identifier);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Sphere {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSphere();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 13) {
            message.point.push(reader.float());
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.point.push(reader.float());
            }

            continue;
          }

          break;
        case 2:
          if (tag != 21) {
            break;
          }

          message.radius = reader.float();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.color = reader.int32();
          continue;
        case 4:
          if (tag != 37) {
            break;
          }

          message.opacity = reader.float();
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 6:
          if (tag != 48) {
            break;
          }

          message.immutable_identifier = reader.int32();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Sphere>): Sphere {
    return Sphere.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Sphere>): Sphere {
    const message = createBaseSphere();
    message.point = object.point?.map((e) => e) || [];
    message.radius = object.radius ?? 0;
    message.color = object.color ?? 0;
    message.opacity = object.opacity ?? 0;
    message.disabled = object.disabled ?? false;
    message.immutable_identifier = object.immutable_identifier ?? 0;
    return message;
  },
};

function createBaseSpheres(): Spheres {
  return { spheres: [] };
}

export const Spheres = {
  encode(message: Spheres, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.spheres) {
      Sphere.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Spheres {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSpheres();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.spheres.push(Sphere.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Spheres>): Spheres {
    return Spheres.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Spheres>): Spheres {
    const message = createBaseSpheres();
    message.spheres = object.spheres?.map((e) => Sphere.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOrigin(): Origin {
  return { point: [], x_axis: [], y_axis: [], z_axis: [] };
}

export const Origin = {
  encode(message: Origin, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.point) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(18).fork();
    for (const v of message.x_axis) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(26).fork();
    for (const v of message.y_axis) {
      writer.float(v);
    }
    writer.ldelim();
    writer.uint32(34).fork();
    for (const v of message.z_axis) {
      writer.float(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Origin {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrigin();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag == 13) {
            message.point.push(reader.float());
            continue;
          }

          if (tag == 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.point.push(reader.float());
            }

            continue;
          }

          break;
        case 2:
          if (tag == 21) {
            message.x_axis.push(reader.float());
            continue;
          }

          if (tag == 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.x_axis.push(reader.float());
            }

            continue;
          }

          break;
        case 3:
          if (tag == 29) {
            message.y_axis.push(reader.float());
            continue;
          }

          if (tag == 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.y_axis.push(reader.float());
            }

            continue;
          }

          break;
        case 4:
          if (tag == 37) {
            message.z_axis.push(reader.float());
            continue;
          }

          if (tag == 34) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.z_axis.push(reader.float());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Origin>): Origin {
    return Origin.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Origin>): Origin {
    const message = createBaseOrigin();
    message.point = object.point?.map((e) => e) || [];
    message.x_axis = object.x_axis?.map((e) => e) || [];
    message.y_axis = object.y_axis?.map((e) => e) || [];
    message.z_axis = object.z_axis?.map((e) => e) || [];
    return message;
  },
};

function createBaseOrigins(): Origins {
  return { origins: [] };
}

export const Origins = {
  encode(message: Origins, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.origins) {
      Origin.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Origins {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOrigins();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.origins.push(Origin.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Origins>): Origins {
    return Origins.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Origins>): Origins {
    const message = createBaseOrigins();
    message.origins = object.origins?.map((e) => Origin.fromPartial(e)) || [];
    return message;
  },
};

function createBaseRobotJointData(): RobotJointData {
  return { robot_joint_data: {} };
}

export const RobotJointData = {
  encode(message: RobotJointData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.robot_joint_data).forEach(([key, value]) => {
      RobotJointData_RobotJointDataEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotJointData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotJointData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          const entry1 = RobotJointData_RobotJointDataEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.robot_joint_data[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RobotJointData>): RobotJointData {
    return RobotJointData.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RobotJointData>): RobotJointData {
    const message = createBaseRobotJointData();
    message.robot_joint_data = Object.entries(object.robot_joint_data ?? {}).reduce<{ [key: string]: number }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = Number(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseRobotJointData_RobotJointDataEntry(): RobotJointData_RobotJointDataEntry {
  return { key: "", value: 0 };
}

export const RobotJointData_RobotJointDataEntry = {
  encode(message: RobotJointData_RobotJointDataEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== 0) {
      writer.uint32(17).double(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RobotJointData_RobotJointDataEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRobotJointData_RobotJointDataEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag != 17) {
            break;
          }

          message.value = reader.double();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<RobotJointData_RobotJointDataEntry>): RobotJointData_RobotJointDataEntry {
    return RobotJointData_RobotJointDataEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<RobotJointData_RobotJointDataEntry>): RobotJointData_RobotJointDataEntry {
    const message = createBaseRobotJointData_RobotJointDataEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? 0;
    return message;
  },
};

function createBaseMessages(): Messages {
  return { warnings: [], errors: [] };
}

export const Messages = {
  encode(message: Messages, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.warnings) {
      Message.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.errors) {
      Message.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Messages {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessages();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.warnings.push(Message.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.errors.push(Message.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Messages>): Messages {
    return Messages.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Messages>): Messages {
    const message = createBaseMessages();
    message.warnings = object.warnings?.map((e) => Message.fromPartial(e)) || [];
    message.errors = object.errors?.map((e) => Message.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMessage(): Message {
  return { param: "", message: "" };
}

export const Message = {
  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.param !== "") {
      writer.uint32(10).string(message.param);
    }
    if (message.message !== "") {
      writer.uint32(18).string(message.message);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.param = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.message = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Message>): Message {
    return Message.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Message>): Message {
    const message = createBaseMessage();
    message.param = object.param ?? "";
    message.message = object.message ?? "";
    return message;
  },
};

function createBaseLayerResponse(): LayerResponse {
  return {
    gltfs: {},
    lines: [],
    labels: [],
    spheres: [],
    info: "",
    origins: [],
    collisions: [],
    robot_joint_data: [],
    weight: 0,
  };
}

export const LayerResponse = {
  encode(message: LayerResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.gltfs).forEach(([key, value]) => {
      LayerResponse_GltfsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    for (const v of message.lines) {
      Lines.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.labels) {
      Labels.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    for (const v of message.spheres) {
      Spheres.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.info !== "") {
      writer.uint32(42).string(message.info);
    }
    for (const v of message.origins) {
      Origins.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    writer.uint32(58).fork();
    for (const v of message.collisions) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.robot_joint_data) {
      RobotJointData.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.weight !== 0) {
      writer.uint32(73).double(message.weight);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LayerResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLayerResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          const entry1 = LayerResponse_GltfsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.gltfs[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.lines.push(Lines.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.labels.push(Labels.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.spheres.push(Spheres.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.info = reader.string();
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.origins.push(Origins.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag == 56) {
            message.collisions.push(reader.int32());
            continue;
          }

          if (tag == 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.collisions.push(reader.int32());
            }

            continue;
          }

          break;
        case 8:
          if (tag != 66) {
            break;
          }

          message.robot_joint_data.push(RobotJointData.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag != 73) {
            break;
          }

          message.weight = reader.double();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LayerResponse>): LayerResponse {
    return LayerResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<LayerResponse>): LayerResponse {
    const message = createBaseLayerResponse();
    message.gltfs = Object.entries(object.gltfs ?? {}).reduce<{ [key: number]: Uint8Array }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[Number(key)] = value;
      }
      return acc;
    }, {});
    message.lines = object.lines?.map((e) => Lines.fromPartial(e)) || [];
    message.labels = object.labels?.map((e) => Labels.fromPartial(e)) || [];
    message.spheres = object.spheres?.map((e) => Spheres.fromPartial(e)) || [];
    message.info = object.info ?? "";
    message.origins = object.origins?.map((e) => Origins.fromPartial(e)) || [];
    message.collisions = object.collisions?.map((e) => e) || [];
    message.robot_joint_data = object.robot_joint_data?.map((e) => RobotJointData.fromPartial(e)) || [];
    message.weight = object.weight ?? 0;
    return message;
  },
};

function createBaseLayerResponse_GltfsEntry(): LayerResponse_GltfsEntry {
  return { key: 0, value: new Uint8Array() };
}

export const LayerResponse_GltfsEntry = {
  encode(message: LayerResponse_GltfsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value.length !== 0) {
      writer.uint32(18).bytes(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LayerResponse_GltfsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLayerResponse_GltfsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = reader.bytes();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<LayerResponse_GltfsEntry>): LayerResponse_GltfsEntry {
    return LayerResponse_GltfsEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<LayerResponse_GltfsEntry>): LayerResponse_GltfsEntry {
    const message = createBaseLayerResponse_GltfsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? new Uint8Array();
    return message;
  },
};

function createBaseProgramPathLayerRequest(): ProgramPathLayerRequest {
  return { params: undefined, layers: [] };
}

export const ProgramPathLayerRequest = {
  encode(message: ProgramPathLayerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.params !== undefined) {
      ProgramPathRequest.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.layers) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProgramPathLayerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProgramPathLayerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.params = ProgramPathRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag == 16) {
            message.layers.push(reader.int32() as any);
            continue;
          }

          if (tag == 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.layers.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ProgramPathLayerRequest>): ProgramPathLayerRequest {
    return ProgramPathLayerRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ProgramPathLayerRequest>): ProgramPathLayerRequest {
    const message = createBaseProgramPathLayerRequest();
    message.params = (object.params !== undefined && object.params !== null)
      ? ProgramPathRequest.fromPartial(object.params)
      : undefined;
    message.layers = object.layers?.map((e) => e) || [];
    return message;
  },
};

export function createBaseProgramPathRequest(): ProgramPathRequest {
  return {
    program_csvs: [],
    view_connections: false,
    view_connections_sequence: false,
    view_connections_grouping: false,
    view_connections_frames: false,
    view_battleship_coordinate: false,
    view_robot_work_objects: false,
    view_world_work_object: false,
    workcell_type: "",
    view_sequence: false,
    view_coordinates: false,
  };
}

export const ProgramPathRequest = {
  encode(message: ProgramPathRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.program_csvs) {
      writer.uint32(10).string(v!);
    }
    if (message.view_connections === true) {
      writer.uint32(16).bool(message.view_connections);
    }
    if (message.view_connections_sequence === true) {
      writer.uint32(24).bool(message.view_connections_sequence);
    }
    if (message.view_connections_grouping === true) {
      writer.uint32(32).bool(message.view_connections_grouping);
    }
    if (message.view_connections_frames === true) {
      writer.uint32(40).bool(message.view_connections_frames);
    }
    if (message.view_battleship_coordinate === true) {
      writer.uint32(48).bool(message.view_battleship_coordinate);
    }
    if (message.view_robot_work_objects === true) {
      writer.uint32(56).bool(message.view_robot_work_objects);
    }
    if (message.view_world_work_object === true) {
      writer.uint32(64).bool(message.view_world_work_object);
    }
    if (message.workcell_type !== "") {
      writer.uint32(74).string(message.workcell_type);
    }
    if (message.view_sequence === true) {
      writer.uint32(80).bool(message.view_sequence);
    }
    if (message.view_coordinates === true) {
      writer.uint32(88).bool(message.view_coordinates);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProgramPathRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProgramPathRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.program_csvs.push(reader.string());
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.view_connections = reader.bool();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.view_connections_sequence = reader.bool();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.view_connections_grouping = reader.bool();
          continue;
        case 5:
          if (tag != 40) {
            break;
          }

          message.view_connections_frames = reader.bool();
          continue;
        case 6:
          if (tag != 48) {
            break;
          }

          message.view_battleship_coordinate = reader.bool();
          continue;
        case 7:
          if (tag != 56) {
            break;
          }

          message.view_robot_work_objects = reader.bool();
          continue;
        case 8:
          if (tag != 64) {
            break;
          }

          message.view_world_work_object = reader.bool();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.workcell_type = reader.string();
          continue;
        case 10:
          if (tag != 80) {
            break;
          }

          message.view_sequence = reader.bool();
          continue;
        case 11:
          if (tag != 88) {
            break;
          }

          message.view_coordinates = reader.bool();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<ProgramPathRequest>): ProgramPathRequest {
    return ProgramPathRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<ProgramPathRequest>): ProgramPathRequest {
    const message = createBaseProgramPathRequest();
    message.program_csvs = object.program_csvs?.map((e) => e) || [];
    message.view_connections = object.view_connections ?? false;
    message.view_connections_sequence = object.view_connections_sequence ?? false;
    message.view_connections_grouping = object.view_connections_grouping ?? false;
    message.view_connections_frames = object.view_connections_frames ?? false;
    message.view_battleship_coordinate = object.view_battleship_coordinate ?? false;
    message.view_robot_work_objects = object.view_robot_work_objects ?? false;
    message.view_world_work_object = object.view_world_work_object ?? false;
    message.workcell_type = object.workcell_type ?? "";
    message.view_sequence = object.view_sequence ?? false;
    message.view_coordinates = object.view_coordinates ?? false;
    return message;
  },
};

function createBaseGridLayerRequest(): GridLayerRequest {
  return { params: undefined, layers: [] };
}

export const GridLayerRequest = {
  encode(message: GridLayerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.params !== undefined) {
      GridRequest.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.layers) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GridLayerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGridLayerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.params = GridRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag == 16) {
            message.layers.push(reader.int32() as any);
            continue;
          }

          if (tag == 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.layers.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GridLayerRequest>): GridLayerRequest {
    return GridLayerRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GridLayerRequest>): GridLayerRequest {
    const message = createBaseGridLayerRequest();
    message.params = (object.params !== undefined && object.params !== null)
      ? GridRequest.fromPartial(object.params)
      : undefined;
    message.layers = object.layers?.map((e) => e) || [];
    return message;
  },
};

export function createBaseGridRequest(): GridRequest {
  return {
    rebar_a_asa_type: "",
    rebar_a_diameter: 0,
    rebar_a_pin_diameter: 0,
    rebar_a_A: 0,
    rebar_a_B: 0,
    rebar_a_C: 0,
    rebar_a_D: 0,
    rebar_a_E: 0,
    rebar_a_F: 0,
    rebar_a_G: 0,
    rebar_a_H: 0,
    rebar_a_J: 0,
    rebar_a_K: 0,
    rebar_a_O: 0,
    rebar_a_R: 0,
    rebar_a_rotation: 0,
    rebar_a_bar_size_key: "",
    rebar_b_asa_type: "",
    rebar_b_diameter: 0,
    rebar_b_pin_diameter: 0,
    rebar_b_A: 0,
    rebar_b_B: 0,
    rebar_b_C: 0,
    rebar_b_D: 0,
    rebar_b_E: 0,
    rebar_b_F: 0,
    rebar_b_G: 0,
    rebar_b_H: 0,
    rebar_b_J: 0,
    rebar_b_K: 0,
    rebar_b_O: 0,
    rebar_b_R: 0,
    rebar_b_rotation: 0,
    rebar_b_bar_size_key: "",
    rebar_a_quantity_at_spacing: [],
    rebar_b_quantity_at_spacing: [],
    rebar_a_quantity: [],
    rebar_a_spacing: [],
    rebar_a_on_top: false,
    rebar_b_quantity: [],
    rebar_b_spacing: [],
    clear_disconnected_bars: false,
    assembly_additional_weight: 0,
    connections_connect_perimeter: false,
    connections_connect_rows: false,
    connections_connect_every_n_row_x: 0,
    connections_connect_every_n_row_y: 0,
    connect_specific_row_x: "",
    connect_specific_row_y: "",
    connections_connect_diagonal_x: false,
    connections_connect_diagonal_y: false,
    connections_connect_every_n_diagonal: 0,
    connections_shift_on_x: 0,
    connections_shift_on_y: 0,
    connections_center_on_x: false,
    connections_center_on_y: false,
    connections_sorting_welder_1: "",
    connections_sorting_welder_2: "",
    min_connection_clearance: 0,
    connections_add_individual_connections: [],
    interactively_selected_connections: {},
    geometry_source_stp: "",
    geometry_source_ifc: "",
    workspace: 0,
    view_connections: false,
    view_connections_sequence: false,
    view_assembly_dimensions: false,
    view_dimensions_type: "",
    grid_preview_mesh_quality: "",
    view_part_naming: false,
    view_robot: false,
    view_axes_widget: false,
    view_connectivity: false,
    metric: false,
    view_battleship_coordinate: false,
    view_connections_frames: false,
    view_robot_work_objects: false,
    view_connections_grouping: false,
    fabrication_weaver: "",
    fabrication_welder_1: false,
    fabrication_welder_2: false,
    fabrication_inserts: "",
    fabrication_assembly_rotation: 0,
    fabrication_x_center_grid: false,
    fabrication_y_center_grid: false,
    fabrication_x_shift_grid: 0,
    fabrication_y_shift_grid: 0,
    fabrication_z_shift_grid: 0,
    fabrication_x_bar_assembly: "",
    fabrication_y_bar_assembly: "",
    fabrication_x_bar_naming: [],
    fabrication_y_bar_naming: [],
    fabrication_welder_1_PYR: [],
    fabrication_welder_1_coordinates_shift: [],
    fabrication_welder_2_PYR: [],
    fabrication_welder_2_coordinates_shift: [],
    fabrication_welding_time_seconds: 0,
    fabrication_movement_time_seconds: 0,
    connections_shifting_welder_1: 0,
    connections_shifting_welder_2: 0,
    fabrication_notes: "",
    fabrication_assembly_flip: false,
    mask_id: "",
    mask_origin_point: [],
    mask_dimension_x: 0,
    mask_dimension_y: 0,
    mask_diameter: 0,
    mask_angle_start: 0,
    mask_angle_end: 0,
    mask_rotation: 0,
    mask_stp: new Uint8Array(),
    nesting_n_rows: 0,
    nesting_n_columns: 0,
    nesting_rows_offset: 0,
    nesting_columns_offset: 0,
    nesting_stagger_columns: false,
    nesting_replicator_units: "",
    nesting_assign_welds_by_unit: false,
    nesting_replicator_pattern: "",
    imported_assembly_geometry_unit: "",
    imported_assembly_geometry_up: "",
  };
}

export const GridRequest = {
  encode(message: GridRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.rebar_a_asa_type !== "") {
      writer.uint32(10).string(message.rebar_a_asa_type);
    }
    if (message.rebar_a_diameter !== 0) {
      writer.uint32(17).double(message.rebar_a_diameter);
    }
    if (message.rebar_a_pin_diameter !== 0) {
      writer.uint32(25).double(message.rebar_a_pin_diameter);
    }
    if (message.rebar_a_A !== 0) {
      writer.uint32(33).double(message.rebar_a_A);
    }
    if (message.rebar_a_B !== 0) {
      writer.uint32(41).double(message.rebar_a_B);
    }
    if (message.rebar_a_C !== 0) {
      writer.uint32(49).double(message.rebar_a_C);
    }
    if (message.rebar_a_D !== 0) {
      writer.uint32(57).double(message.rebar_a_D);
    }
    if (message.rebar_a_E !== 0) {
      writer.uint32(65).double(message.rebar_a_E);
    }
    if (message.rebar_a_F !== 0) {
      writer.uint32(73).double(message.rebar_a_F);
    }
    if (message.rebar_a_G !== 0) {
      writer.uint32(81).double(message.rebar_a_G);
    }
    if (message.rebar_a_H !== 0) {
      writer.uint32(89).double(message.rebar_a_H);
    }
    if (message.rebar_a_J !== 0) {
      writer.uint32(97).double(message.rebar_a_J);
    }
    if (message.rebar_a_K !== 0) {
      writer.uint32(105).double(message.rebar_a_K);
    }
    if (message.rebar_a_O !== 0) {
      writer.uint32(113).double(message.rebar_a_O);
    }
    if (message.rebar_a_R !== 0) {
      writer.uint32(121).double(message.rebar_a_R);
    }
    if (message.rebar_a_rotation !== 0) {
      writer.uint32(129).double(message.rebar_a_rotation);
    }
    if (message.rebar_a_bar_size_key !== "") {
      writer.uint32(138).string(message.rebar_a_bar_size_key);
    }
    if (message.rebar_b_asa_type !== "") {
      writer.uint32(170).string(message.rebar_b_asa_type);
    }
    if (message.rebar_b_diameter !== 0) {
      writer.uint32(177).double(message.rebar_b_diameter);
    }
    if (message.rebar_b_pin_diameter !== 0) {
      writer.uint32(185).double(message.rebar_b_pin_diameter);
    }
    if (message.rebar_b_A !== 0) {
      writer.uint32(193).double(message.rebar_b_A);
    }
    if (message.rebar_b_B !== 0) {
      writer.uint32(201).double(message.rebar_b_B);
    }
    if (message.rebar_b_C !== 0) {
      writer.uint32(209).double(message.rebar_b_C);
    }
    if (message.rebar_b_D !== 0) {
      writer.uint32(217).double(message.rebar_b_D);
    }
    if (message.rebar_b_E !== 0) {
      writer.uint32(225).double(message.rebar_b_E);
    }
    if (message.rebar_b_F !== 0) {
      writer.uint32(233).double(message.rebar_b_F);
    }
    if (message.rebar_b_G !== 0) {
      writer.uint32(241).double(message.rebar_b_G);
    }
    if (message.rebar_b_H !== 0) {
      writer.uint32(249).double(message.rebar_b_H);
    }
    if (message.rebar_b_J !== 0) {
      writer.uint32(257).double(message.rebar_b_J);
    }
    if (message.rebar_b_K !== 0) {
      writer.uint32(265).double(message.rebar_b_K);
    }
    if (message.rebar_b_O !== 0) {
      writer.uint32(273).double(message.rebar_b_O);
    }
    if (message.rebar_b_R !== 0) {
      writer.uint32(281).double(message.rebar_b_R);
    }
    if (message.rebar_b_rotation !== 0) {
      writer.uint32(289).double(message.rebar_b_rotation);
    }
    if (message.rebar_b_bar_size_key !== "") {
      writer.uint32(298).string(message.rebar_b_bar_size_key);
    }
    for (const v of message.rebar_a_quantity_at_spacing) {
      writer.uint32(306).string(v!);
    }
    for (const v of message.rebar_b_quantity_at_spacing) {
      writer.uint32(314).string(v!);
    }
    writer.uint32(322).fork();
    for (const v of message.rebar_a_quantity) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(330).fork();
    for (const v of message.rebar_a_spacing) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.rebar_a_on_top === true) {
      writer.uint32(336).bool(message.rebar_a_on_top);
    }
    writer.uint32(346).fork();
    for (const v of message.rebar_b_quantity) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(354).fork();
    for (const v of message.rebar_b_spacing) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.clear_disconnected_bars === true) {
      writer.uint32(360).bool(message.clear_disconnected_bars);
    }
    if (message.assembly_additional_weight !== 0) {
      writer.uint32(369).double(message.assembly_additional_weight);
    }
    if (message.connections_connect_perimeter === true) {
      writer.uint32(400).bool(message.connections_connect_perimeter);
    }
    if (message.connections_connect_rows === true) {
      writer.uint32(408).bool(message.connections_connect_rows);
    }
    if (message.connections_connect_every_n_row_x !== 0) {
      writer.uint32(416).int32(message.connections_connect_every_n_row_x);
    }
    if (message.connections_connect_every_n_row_y !== 0) {
      writer.uint32(424).int32(message.connections_connect_every_n_row_y);
    }
    if (message.connect_specific_row_x !== "") {
      writer.uint32(434).string(message.connect_specific_row_x);
    }
    if (message.connect_specific_row_y !== "") {
      writer.uint32(442).string(message.connect_specific_row_y);
    }
    if (message.connections_connect_diagonal_x === true) {
      writer.uint32(448).bool(message.connections_connect_diagonal_x);
    }
    if (message.connections_connect_diagonal_y === true) {
      writer.uint32(456).bool(message.connections_connect_diagonal_y);
    }
    if (message.connections_connect_every_n_diagonal !== 0) {
      writer.uint32(464).int32(message.connections_connect_every_n_diagonal);
    }
    if (message.connections_shift_on_x !== 0) {
      writer.uint32(472).int32(message.connections_shift_on_x);
    }
    if (message.connections_shift_on_y !== 0) {
      writer.uint32(480).int32(message.connections_shift_on_y);
    }
    if (message.connections_center_on_x === true) {
      writer.uint32(488).bool(message.connections_center_on_x);
    }
    if (message.connections_center_on_y === true) {
      writer.uint32(496).bool(message.connections_center_on_y);
    }
    if (message.connections_sorting_welder_1 !== "") {
      writer.uint32(506).string(message.connections_sorting_welder_1);
    }
    if (message.connections_sorting_welder_2 !== "") {
      writer.uint32(514).string(message.connections_sorting_welder_2);
    }
    if (message.min_connection_clearance !== 0) {
      writer.uint32(521).double(message.min_connection_clearance);
    }
    for (const v of message.connections_add_individual_connections) {
      writer.uint32(530).string(v!);
    }
    Object.entries(message.interactively_selected_connections).forEach(([key, value]) => {
      GridRequest_InteractivelySelectedConnectionsEntry.encode({ key: key as any, value }, writer.uint32(538).fork())
        .ldelim();
    });
    if (message.geometry_source_stp !== "") {
      writer.uint32(562).string(message.geometry_source_stp);
    }
    if (message.geometry_source_ifc !== "") {
      writer.uint32(570).string(message.geometry_source_ifc);
    }
    if (message.workspace !== 0) {
      writer.uint32(640).int32(message.workspace);
    }
    if (message.view_connections === true) {
      writer.uint32(648).bool(message.view_connections);
    }
    if (message.view_connections_sequence === true) {
      writer.uint32(656).bool(message.view_connections_sequence);
    }
    if (message.view_assembly_dimensions === true) {
      writer.uint32(664).bool(message.view_assembly_dimensions);
    }
    if (message.view_dimensions_type !== "") {
      writer.uint32(674).string(message.view_dimensions_type);
    }
    if (message.grid_preview_mesh_quality !== "") {
      writer.uint32(682).string(message.grid_preview_mesh_quality);
    }
    if (message.view_part_naming === true) {
      writer.uint32(688).bool(message.view_part_naming);
    }
    if (message.view_robot === true) {
      writer.uint32(696).bool(message.view_robot);
    }
    if (message.view_axes_widget === true) {
      writer.uint32(704).bool(message.view_axes_widget);
    }
    if (message.view_connectivity === true) {
      writer.uint32(712).bool(message.view_connectivity);
    }
    if (message.metric === true) {
      writer.uint32(728).bool(message.metric);
    }
    if (message.view_battleship_coordinate === true) {
      writer.uint32(736).bool(message.view_battleship_coordinate);
    }
    if (message.view_connections_frames === true) {
      writer.uint32(744).bool(message.view_connections_frames);
    }
    if (message.view_robot_work_objects === true) {
      writer.uint32(752).bool(message.view_robot_work_objects);
    }
    if (message.view_connections_grouping === true) {
      writer.uint32(760).bool(message.view_connections_grouping);
    }
    if (message.fabrication_weaver !== "") {
      writer.uint32(802).string(message.fabrication_weaver);
    }
    if (message.fabrication_welder_1 === true) {
      writer.uint32(808).bool(message.fabrication_welder_1);
    }
    if (message.fabrication_welder_2 === true) {
      writer.uint32(816).bool(message.fabrication_welder_2);
    }
    if (message.fabrication_inserts !== "") {
      writer.uint32(826).string(message.fabrication_inserts);
    }
    if (message.fabrication_assembly_rotation !== 0) {
      writer.uint32(841).double(message.fabrication_assembly_rotation);
    }
    if (message.fabrication_x_center_grid === true) {
      writer.uint32(848).bool(message.fabrication_x_center_grid);
    }
    if (message.fabrication_y_center_grid === true) {
      writer.uint32(856).bool(message.fabrication_y_center_grid);
    }
    if (message.fabrication_x_shift_grid !== 0) {
      writer.uint32(865).double(message.fabrication_x_shift_grid);
    }
    if (message.fabrication_y_shift_grid !== 0) {
      writer.uint32(873).double(message.fabrication_y_shift_grid);
    }
    if (message.fabrication_z_shift_grid !== 0) {
      writer.uint32(1033).double(message.fabrication_z_shift_grid);
    }
    if (message.fabrication_x_bar_assembly !== "") {
      writer.uint32(882).string(message.fabrication_x_bar_assembly);
    }
    if (message.fabrication_y_bar_assembly !== "") {
      writer.uint32(890).string(message.fabrication_y_bar_assembly);
    }
    for (const v of message.fabrication_x_bar_naming) {
      writer.uint32(898).string(v!);
    }
    for (const v of message.fabrication_y_bar_naming) {
      writer.uint32(906).string(v!);
    }
    writer.uint32(914).fork();
    for (const v of message.fabrication_welder_1_PYR) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(938).fork();
    for (const v of message.fabrication_welder_1_coordinates_shift) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(946).fork();
    for (const v of message.fabrication_welder_2_PYR) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(970).fork();
    for (const v of message.fabrication_welder_2_coordinates_shift) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.fabrication_welding_time_seconds !== 0) {
      writer.uint32(985).double(message.fabrication_welding_time_seconds);
    }
    if (message.fabrication_movement_time_seconds !== 0) {
      writer.uint32(993).double(message.fabrication_movement_time_seconds);
    }
    if (message.connections_shifting_welder_1 !== 0) {
      writer.uint32(1000).int32(message.connections_shifting_welder_1);
    }
    if (message.connections_shifting_welder_2 !== 0) {
      writer.uint32(1008).int32(message.connections_shifting_welder_2);
    }
    if (message.fabrication_notes !== "") {
      writer.uint32(1018).string(message.fabrication_notes);
    }
    if (message.fabrication_assembly_flip === true) {
      writer.uint32(1024).bool(message.fabrication_assembly_flip);
    }
    if (message.mask_id !== "") {
      writer.uint32(1122).string(message.mask_id);
    }
    writer.uint32(1130).fork();
    for (const v of message.mask_origin_point) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.mask_dimension_x !== 0) {
      writer.uint32(1137).double(message.mask_dimension_x);
    }
    if (message.mask_dimension_y !== 0) {
      writer.uint32(1145).double(message.mask_dimension_y);
    }
    if (message.mask_diameter !== 0) {
      writer.uint32(1153).double(message.mask_diameter);
    }
    if (message.mask_angle_start !== 0) {
      writer.uint32(1161).double(message.mask_angle_start);
    }
    if (message.mask_angle_end !== 0) {
      writer.uint32(1169).double(message.mask_angle_end);
    }
    if (message.mask_rotation !== 0) {
      writer.uint32(1177).double(message.mask_rotation);
    }
    if (message.mask_stp.length !== 0) {
      writer.uint32(1186).bytes(message.mask_stp);
    }
    if (message.nesting_n_rows !== 0) {
      writer.uint32(1200).int32(message.nesting_n_rows);
    }
    if (message.nesting_n_columns !== 0) {
      writer.uint32(1208).int32(message.nesting_n_columns);
    }
    if (message.nesting_rows_offset !== 0) {
      writer.uint32(1217).double(message.nesting_rows_offset);
    }
    if (message.nesting_columns_offset !== 0) {
      writer.uint32(1225).double(message.nesting_columns_offset);
    }
    if (message.nesting_stagger_columns === true) {
      writer.uint32(1232).bool(message.nesting_stagger_columns);
    }
    if (message.nesting_replicator_units !== "") {
      writer.uint32(1242).string(message.nesting_replicator_units);
    }
    if (message.nesting_assign_welds_by_unit === true) {
      writer.uint32(1248).bool(message.nesting_assign_welds_by_unit);
    }
    if (message.nesting_replicator_pattern !== "") {
      writer.uint32(1258).string(message.nesting_replicator_pattern);
    }
    if (message.imported_assembly_geometry_unit !== "") {
      writer.uint32(1282).string(message.imported_assembly_geometry_unit);
    }
    if (message.imported_assembly_geometry_up !== "") {
      writer.uint32(1290).string(message.imported_assembly_geometry_up);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GridRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGridRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.rebar_a_asa_type = reader.string();
          continue;
        case 2:
          if (tag != 17) {
            break;
          }

          message.rebar_a_diameter = reader.double();
          continue;
        case 3:
          if (tag != 25) {
            break;
          }

          message.rebar_a_pin_diameter = reader.double();
          continue;
        case 4:
          if (tag != 33) {
            break;
          }

          message.rebar_a_A = reader.double();
          continue;
        case 5:
          if (tag != 41) {
            break;
          }

          message.rebar_a_B = reader.double();
          continue;
        case 6:
          if (tag != 49) {
            break;
          }

          message.rebar_a_C = reader.double();
          continue;
        case 7:
          if (tag != 57) {
            break;
          }

          message.rebar_a_D = reader.double();
          continue;
        case 8:
          if (tag != 65) {
            break;
          }

          message.rebar_a_E = reader.double();
          continue;
        case 9:
          if (tag != 73) {
            break;
          }

          message.rebar_a_F = reader.double();
          continue;
        case 10:
          if (tag != 81) {
            break;
          }

          message.rebar_a_G = reader.double();
          continue;
        case 11:
          if (tag != 89) {
            break;
          }

          message.rebar_a_H = reader.double();
          continue;
        case 12:
          if (tag != 97) {
            break;
          }

          message.rebar_a_J = reader.double();
          continue;
        case 13:
          if (tag != 105) {
            break;
          }

          message.rebar_a_K = reader.double();
          continue;
        case 14:
          if (tag != 113) {
            break;
          }

          message.rebar_a_O = reader.double();
          continue;
        case 15:
          if (tag != 121) {
            break;
          }

          message.rebar_a_R = reader.double();
          continue;
        case 16:
          if (tag != 129) {
            break;
          }

          message.rebar_a_rotation = reader.double();
          continue;
        case 17:
          if (tag != 138) {
            break;
          }

          message.rebar_a_bar_size_key = reader.string();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.rebar_b_asa_type = reader.string();
          continue;
        case 22:
          if (tag != 177) {
            break;
          }

          message.rebar_b_diameter = reader.double();
          continue;
        case 23:
          if (tag != 185) {
            break;
          }

          message.rebar_b_pin_diameter = reader.double();
          continue;
        case 24:
          if (tag != 193) {
            break;
          }

          message.rebar_b_A = reader.double();
          continue;
        case 25:
          if (tag != 201) {
            break;
          }

          message.rebar_b_B = reader.double();
          continue;
        case 26:
          if (tag != 209) {
            break;
          }

          message.rebar_b_C = reader.double();
          continue;
        case 27:
          if (tag != 217) {
            break;
          }

          message.rebar_b_D = reader.double();
          continue;
        case 28:
          if (tag != 225) {
            break;
          }

          message.rebar_b_E = reader.double();
          continue;
        case 29:
          if (tag != 233) {
            break;
          }

          message.rebar_b_F = reader.double();
          continue;
        case 30:
          if (tag != 241) {
            break;
          }

          message.rebar_b_G = reader.double();
          continue;
        case 31:
          if (tag != 249) {
            break;
          }

          message.rebar_b_H = reader.double();
          continue;
        case 32:
          if (tag != 257) {
            break;
          }

          message.rebar_b_J = reader.double();
          continue;
        case 33:
          if (tag != 265) {
            break;
          }

          message.rebar_b_K = reader.double();
          continue;
        case 34:
          if (tag != 273) {
            break;
          }

          message.rebar_b_O = reader.double();
          continue;
        case 35:
          if (tag != 281) {
            break;
          }

          message.rebar_b_R = reader.double();
          continue;
        case 36:
          if (tag != 289) {
            break;
          }

          message.rebar_b_rotation = reader.double();
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.rebar_b_bar_size_key = reader.string();
          continue;
        case 38:
          if (tag != 306) {
            break;
          }

          message.rebar_a_quantity_at_spacing.push(reader.string());
          continue;
        case 39:
          if (tag != 314) {
            break;
          }

          message.rebar_b_quantity_at_spacing.push(reader.string());
          continue;
        case 40:
          if (tag == 320) {
            message.rebar_a_quantity.push(reader.int32());
            continue;
          }

          if (tag == 322) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rebar_a_quantity.push(reader.int32());
            }

            continue;
          }

          break;
        case 41:
          if (tag == 329) {
            message.rebar_a_spacing.push(reader.double());
            continue;
          }

          if (tag == 330) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rebar_a_spacing.push(reader.double());
            }

            continue;
          }

          break;
        case 42:
          if (tag != 336) {
            break;
          }

          message.rebar_a_on_top = reader.bool();
          continue;
        case 43:
          if (tag == 344) {
            message.rebar_b_quantity.push(reader.int32());
            continue;
          }

          if (tag == 346) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rebar_b_quantity.push(reader.int32());
            }

            continue;
          }

          break;
        case 44:
          if (tag == 353) {
            message.rebar_b_spacing.push(reader.double());
            continue;
          }

          if (tag == 354) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.rebar_b_spacing.push(reader.double());
            }

            continue;
          }

          break;
        case 45:
          if (tag != 360) {
            break;
          }

          message.clear_disconnected_bars = reader.bool();
          continue;
        case 46:
          if (tag != 369) {
            break;
          }

          message.assembly_additional_weight = reader.double();
          continue;
        case 50:
          if (tag != 400) {
            break;
          }

          message.connections_connect_perimeter = reader.bool();
          continue;
        case 51:
          if (tag != 408) {
            break;
          }

          message.connections_connect_rows = reader.bool();
          continue;
        case 52:
          if (tag != 416) {
            break;
          }

          message.connections_connect_every_n_row_x = reader.int32();
          continue;
        case 53:
          if (tag != 424) {
            break;
          }

          message.connections_connect_every_n_row_y = reader.int32();
          continue;
        case 54:
          if (tag != 434) {
            break;
          }

          message.connect_specific_row_x = reader.string();
          continue;
        case 55:
          if (tag != 442) {
            break;
          }

          message.connect_specific_row_y = reader.string();
          continue;
        case 56:
          if (tag != 448) {
            break;
          }

          message.connections_connect_diagonal_x = reader.bool();
          continue;
        case 57:
          if (tag != 456) {
            break;
          }

          message.connections_connect_diagonal_y = reader.bool();
          continue;
        case 58:
          if (tag != 464) {
            break;
          }

          message.connections_connect_every_n_diagonal = reader.int32();
          continue;
        case 59:
          if (tag != 472) {
            break;
          }

          message.connections_shift_on_x = reader.int32();
          continue;
        case 60:
          if (tag != 480) {
            break;
          }

          message.connections_shift_on_y = reader.int32();
          continue;
        case 61:
          if (tag != 488) {
            break;
          }

          message.connections_center_on_x = reader.bool();
          continue;
        case 62:
          if (tag != 496) {
            break;
          }

          message.connections_center_on_y = reader.bool();
          continue;
        case 63:
          if (tag != 506) {
            break;
          }

          message.connections_sorting_welder_1 = reader.string();
          continue;
        case 64:
          if (tag != 514) {
            break;
          }

          message.connections_sorting_welder_2 = reader.string();
          continue;
        case 65:
          if (tag != 521) {
            break;
          }

          message.min_connection_clearance = reader.double();
          continue;
        case 66:
          if (tag != 530) {
            break;
          }

          message.connections_add_individual_connections.push(reader.string());
          continue;
        case 67:
          if (tag != 538) {
            break;
          }

          const entry67 = GridRequest_InteractivelySelectedConnectionsEntry.decode(reader, reader.uint32());
          if (entry67.value !== undefined) {
            message.interactively_selected_connections[entry67.key] = entry67.value;
          }
          continue;
        case 70:
          if (tag != 562) {
            break;
          }

          message.geometry_source_stp = reader.string();
          continue;
        case 71:
          if (tag != 570) {
            break;
          }

          message.geometry_source_ifc = reader.string();
          continue;
        case 80:
          if (tag != 640) {
            break;
          }

          message.workspace = reader.int32();
          continue;
        case 81:
          if (tag != 648) {
            break;
          }

          message.view_connections = reader.bool();
          continue;
        case 82:
          if (tag != 656) {
            break;
          }

          message.view_connections_sequence = reader.bool();
          continue;
        case 83:
          if (tag != 664) {
            break;
          }

          message.view_assembly_dimensions = reader.bool();
          continue;
        case 84:
          if (tag != 674) {
            break;
          }

          message.view_dimensions_type = reader.string();
          continue;
        case 85:
          if (tag != 682) {
            break;
          }

          message.grid_preview_mesh_quality = reader.string();
          continue;
        case 86:
          if (tag != 688) {
            break;
          }

          message.view_part_naming = reader.bool();
          continue;
        case 87:
          if (tag != 696) {
            break;
          }

          message.view_robot = reader.bool();
          continue;
        case 88:
          if (tag != 704) {
            break;
          }

          message.view_axes_widget = reader.bool();
          continue;
        case 89:
          if (tag != 712) {
            break;
          }

          message.view_connectivity = reader.bool();
          continue;
        case 91:
          if (tag != 728) {
            break;
          }

          message.metric = reader.bool();
          continue;
        case 92:
          if (tag != 736) {
            break;
          }

          message.view_battleship_coordinate = reader.bool();
          continue;
        case 93:
          if (tag != 744) {
            break;
          }

          message.view_connections_frames = reader.bool();
          continue;
        case 94:
          if (tag != 752) {
            break;
          }

          message.view_robot_work_objects = reader.bool();
          continue;
        case 95:
          if (tag != 760) {
            break;
          }

          message.view_connections_grouping = reader.bool();
          continue;
        case 100:
          if (tag != 802) {
            break;
          }

          message.fabrication_weaver = reader.string();
          continue;
        case 101:
          if (tag != 808) {
            break;
          }

          message.fabrication_welder_1 = reader.bool();
          continue;
        case 102:
          if (tag != 816) {
            break;
          }

          message.fabrication_welder_2 = reader.bool();
          continue;
        case 103:
          if (tag != 826) {
            break;
          }

          message.fabrication_inserts = reader.string();
          continue;
        case 105:
          if (tag != 841) {
            break;
          }

          message.fabrication_assembly_rotation = reader.double();
          continue;
        case 106:
          if (tag != 848) {
            break;
          }

          message.fabrication_x_center_grid = reader.bool();
          continue;
        case 107:
          if (tag != 856) {
            break;
          }

          message.fabrication_y_center_grid = reader.bool();
          continue;
        case 108:
          if (tag != 865) {
            break;
          }

          message.fabrication_x_shift_grid = reader.double();
          continue;
        case 109:
          if (tag != 873) {
            break;
          }

          message.fabrication_y_shift_grid = reader.double();
          continue;
        case 129:
          if (tag != 1033) {
            break;
          }

          message.fabrication_z_shift_grid = reader.double();
          continue;
        case 110:
          if (tag != 882) {
            break;
          }

          message.fabrication_x_bar_assembly = reader.string();
          continue;
        case 111:
          if (tag != 890) {
            break;
          }

          message.fabrication_y_bar_assembly = reader.string();
          continue;
        case 112:
          if (tag != 898) {
            break;
          }

          message.fabrication_x_bar_naming.push(reader.string());
          continue;
        case 113:
          if (tag != 906) {
            break;
          }

          message.fabrication_y_bar_naming.push(reader.string());
          continue;
        case 114:
          if (tag == 913) {
            message.fabrication_welder_1_PYR.push(reader.double());
            continue;
          }

          if (tag == 914) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.fabrication_welder_1_PYR.push(reader.double());
            }

            continue;
          }

          break;
        case 117:
          if (tag == 937) {
            message.fabrication_welder_1_coordinates_shift.push(reader.double());
            continue;
          }

          if (tag == 938) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.fabrication_welder_1_coordinates_shift.push(reader.double());
            }

            continue;
          }

          break;
        case 118:
          if (tag == 945) {
            message.fabrication_welder_2_PYR.push(reader.double());
            continue;
          }

          if (tag == 946) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.fabrication_welder_2_PYR.push(reader.double());
            }

            continue;
          }

          break;
        case 121:
          if (tag == 969) {
            message.fabrication_welder_2_coordinates_shift.push(reader.double());
            continue;
          }

          if (tag == 970) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.fabrication_welder_2_coordinates_shift.push(reader.double());
            }

            continue;
          }

          break;
        case 123:
          if (tag != 985) {
            break;
          }

          message.fabrication_welding_time_seconds = reader.double();
          continue;
        case 124:
          if (tag != 993) {
            break;
          }

          message.fabrication_movement_time_seconds = reader.double();
          continue;
        case 125:
          if (tag != 1000) {
            break;
          }

          message.connections_shifting_welder_1 = reader.int32();
          continue;
        case 126:
          if (tag != 1008) {
            break;
          }

          message.connections_shifting_welder_2 = reader.int32();
          continue;
        case 127:
          if (tag != 1018) {
            break;
          }

          message.fabrication_notes = reader.string();
          continue;
        case 128:
          if (tag != 1024) {
            break;
          }

          message.fabrication_assembly_flip = reader.bool();
          continue;
        case 140:
          if (tag != 1122) {
            break;
          }

          message.mask_id = reader.string();
          continue;
        case 141:
          if (tag == 1129) {
            message.mask_origin_point.push(reader.double());
            continue;
          }

          if (tag == 1130) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.mask_origin_point.push(reader.double());
            }

            continue;
          }

          break;
        case 142:
          if (tag != 1137) {
            break;
          }

          message.mask_dimension_x = reader.double();
          continue;
        case 143:
          if (tag != 1145) {
            break;
          }

          message.mask_dimension_y = reader.double();
          continue;
        case 144:
          if (tag != 1153) {
            break;
          }

          message.mask_diameter = reader.double();
          continue;
        case 145:
          if (tag != 1161) {
            break;
          }

          message.mask_angle_start = reader.double();
          continue;
        case 146:
          if (tag != 1169) {
            break;
          }

          message.mask_angle_end = reader.double();
          continue;
        case 147:
          if (tag != 1177) {
            break;
          }

          message.mask_rotation = reader.double();
          continue;
        case 148:
          if (tag != 1186) {
            break;
          }

          message.mask_stp = reader.bytes();
          continue;
        case 150:
          if (tag != 1200) {
            break;
          }

          message.nesting_n_rows = reader.int32();
          continue;
        case 151:
          if (tag != 1208) {
            break;
          }

          message.nesting_n_columns = reader.int32();
          continue;
        case 152:
          if (tag != 1217) {
            break;
          }

          message.nesting_rows_offset = reader.double();
          continue;
        case 153:
          if (tag != 1225) {
            break;
          }

          message.nesting_columns_offset = reader.double();
          continue;
        case 154:
          if (tag != 1232) {
            break;
          }

          message.nesting_stagger_columns = reader.bool();
          continue;
        case 155:
          if (tag != 1242) {
            break;
          }

          message.nesting_replicator_units = reader.string();
          continue;
        case 156:
          if (tag != 1248) {
            break;
          }

          message.nesting_assign_welds_by_unit = reader.bool();
          continue;
        case 157:
          if (tag != 1258) {
            break;
          }

          message.nesting_replicator_pattern = reader.string();
          continue;
        case 160:
          if (tag != 1282) {
            break;
          }

          message.imported_assembly_geometry_unit = reader.string();
          continue;
        case 161:
          if (tag != 1290) {
            break;
          }

          message.imported_assembly_geometry_up = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<GridRequest>): GridRequest {
    return GridRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<GridRequest>): GridRequest {
    const message = createBaseGridRequest();
    message.rebar_a_asa_type = object.rebar_a_asa_type ?? "";
    message.rebar_a_diameter = object.rebar_a_diameter ?? 0;
    message.rebar_a_pin_diameter = object.rebar_a_pin_diameter ?? 0;
    message.rebar_a_A = object.rebar_a_A ?? 0;
    message.rebar_a_B = object.rebar_a_B ?? 0;
    message.rebar_a_C = object.rebar_a_C ?? 0;
    message.rebar_a_D = object.rebar_a_D ?? 0;
    message.rebar_a_E = object.rebar_a_E ?? 0;
    message.rebar_a_F = object.rebar_a_F ?? 0;
    message.rebar_a_G = object.rebar_a_G ?? 0;
    message.rebar_a_H = object.rebar_a_H ?? 0;
    message.rebar_a_J = object.rebar_a_J ?? 0;
    message.rebar_a_K = object.rebar_a_K ?? 0;
    message.rebar_a_O = object.rebar_a_O ?? 0;
    message.rebar_a_R = object.rebar_a_R ?? 0;
    message.rebar_a_rotation = object.rebar_a_rotation ?? 0;
    message.rebar_a_bar_size_key = object.rebar_a_bar_size_key ?? "";
    message.rebar_b_asa_type = object.rebar_b_asa_type ?? "";
    message.rebar_b_diameter = object.rebar_b_diameter ?? 0;
    message.rebar_b_pin_diameter = object.rebar_b_pin_diameter ?? 0;
    message.rebar_b_A = object.rebar_b_A ?? 0;
    message.rebar_b_B = object.rebar_b_B ?? 0;
    message.rebar_b_C = object.rebar_b_C ?? 0;
    message.rebar_b_D = object.rebar_b_D ?? 0;
    message.rebar_b_E = object.rebar_b_E ?? 0;
    message.rebar_b_F = object.rebar_b_F ?? 0;
    message.rebar_b_G = object.rebar_b_G ?? 0;
    message.rebar_b_H = object.rebar_b_H ?? 0;
    message.rebar_b_J = object.rebar_b_J ?? 0;
    message.rebar_b_K = object.rebar_b_K ?? 0;
    message.rebar_b_O = object.rebar_b_O ?? 0;
    message.rebar_b_R = object.rebar_b_R ?? 0;
    message.rebar_b_rotation = object.rebar_b_rotation ?? 0;
    message.rebar_b_bar_size_key = object.rebar_b_bar_size_key ?? "";
    message.rebar_a_quantity_at_spacing = object.rebar_a_quantity_at_spacing?.map((e) => e) || [];
    message.rebar_b_quantity_at_spacing = object.rebar_b_quantity_at_spacing?.map((e) => e) || [];
    message.rebar_a_quantity = object.rebar_a_quantity?.map((e) => e) || [];
    message.rebar_a_spacing = object.rebar_a_spacing?.map((e) => e) || [];
    message.rebar_a_on_top = object.rebar_a_on_top ?? false;
    message.rebar_b_quantity = object.rebar_b_quantity?.map((e) => e) || [];
    message.rebar_b_spacing = object.rebar_b_spacing?.map((e) => e) || [];
    message.clear_disconnected_bars = object.clear_disconnected_bars ?? false;
    message.assembly_additional_weight = object.assembly_additional_weight ?? 0;
    message.connections_connect_perimeter = object.connections_connect_perimeter ?? false;
    message.connections_connect_rows = object.connections_connect_rows ?? false;
    message.connections_connect_every_n_row_x = object.connections_connect_every_n_row_x ?? 0;
    message.connections_connect_every_n_row_y = object.connections_connect_every_n_row_y ?? 0;
    message.connect_specific_row_x = object.connect_specific_row_x ?? "";
    message.connect_specific_row_y = object.connect_specific_row_y ?? "";
    message.connections_connect_diagonal_x = object.connections_connect_diagonal_x ?? false;
    message.connections_connect_diagonal_y = object.connections_connect_diagonal_y ?? false;
    message.connections_connect_every_n_diagonal = object.connections_connect_every_n_diagonal ?? 0;
    message.connections_shift_on_x = object.connections_shift_on_x ?? 0;
    message.connections_shift_on_y = object.connections_shift_on_y ?? 0;
    message.connections_center_on_x = object.connections_center_on_x ?? false;
    message.connections_center_on_y = object.connections_center_on_y ?? false;
    message.connections_sorting_welder_1 = object.connections_sorting_welder_1 ?? "";
    message.connections_sorting_welder_2 = object.connections_sorting_welder_2 ?? "";
    message.min_connection_clearance = object.min_connection_clearance ?? 0;
    message.connections_add_individual_connections = object.connections_add_individual_connections?.map((e) => e) || [];
    message.interactively_selected_connections = Object.entries(object.interactively_selected_connections ?? {}).reduce<
      { [key: number]: boolean }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[Number(key)] = Boolean(value);
      }
      return acc;
    }, {});
    message.geometry_source_stp = object.geometry_source_stp ?? "";
    message.geometry_source_ifc = object.geometry_source_ifc ?? "";
    message.workspace = object.workspace ?? 0;
    message.view_connections = object.view_connections ?? false;
    message.view_connections_sequence = object.view_connections_sequence ?? false;
    message.view_assembly_dimensions = object.view_assembly_dimensions ?? false;
    message.view_dimensions_type = object.view_dimensions_type ?? "";
    message.grid_preview_mesh_quality = object.grid_preview_mesh_quality ?? "";
    message.view_part_naming = object.view_part_naming ?? false;
    message.view_robot = object.view_robot ?? false;
    message.view_axes_widget = object.view_axes_widget ?? false;
    message.view_connectivity = object.view_connectivity ?? false;
    message.metric = object.metric ?? false;
    message.view_battleship_coordinate = object.view_battleship_coordinate ?? false;
    message.view_connections_frames = object.view_connections_frames ?? false;
    message.view_robot_work_objects = object.view_robot_work_objects ?? false;
    message.view_connections_grouping = object.view_connections_grouping ?? false;
    message.fabrication_weaver = object.fabrication_weaver ?? "";
    message.fabrication_welder_1 = object.fabrication_welder_1 ?? false;
    message.fabrication_welder_2 = object.fabrication_welder_2 ?? false;
    message.fabrication_inserts = object.fabrication_inserts ?? "";
    message.fabrication_assembly_rotation = object.fabrication_assembly_rotation ?? 0;
    message.fabrication_x_center_grid = object.fabrication_x_center_grid ?? false;
    message.fabrication_y_center_grid = object.fabrication_y_center_grid ?? false;
    message.fabrication_x_shift_grid = object.fabrication_x_shift_grid ?? 0;
    message.fabrication_y_shift_grid = object.fabrication_y_shift_grid ?? 0;
    message.fabrication_z_shift_grid = object.fabrication_z_shift_grid ?? 0;
    message.fabrication_x_bar_assembly = object.fabrication_x_bar_assembly ?? "";
    message.fabrication_y_bar_assembly = object.fabrication_y_bar_assembly ?? "";
    message.fabrication_x_bar_naming = object.fabrication_x_bar_naming?.map((e) => e) || [];
    message.fabrication_y_bar_naming = object.fabrication_y_bar_naming?.map((e) => e) || [];
    message.fabrication_welder_1_PYR = object.fabrication_welder_1_PYR?.map((e) => e) || [];
    message.fabrication_welder_1_coordinates_shift = object.fabrication_welder_1_coordinates_shift?.map((e) => e) || [];
    message.fabrication_welder_2_PYR = object.fabrication_welder_2_PYR?.map((e) => e) || [];
    message.fabrication_welder_2_coordinates_shift = object.fabrication_welder_2_coordinates_shift?.map((e) => e) || [];
    message.fabrication_welding_time_seconds = object.fabrication_welding_time_seconds ?? 0;
    message.fabrication_movement_time_seconds = object.fabrication_movement_time_seconds ?? 0;
    message.connections_shifting_welder_1 = object.connections_shifting_welder_1 ?? 0;
    message.connections_shifting_welder_2 = object.connections_shifting_welder_2 ?? 0;
    message.fabrication_notes = object.fabrication_notes ?? "";
    message.fabrication_assembly_flip = object.fabrication_assembly_flip ?? false;
    message.mask_id = object.mask_id ?? "";
    message.mask_origin_point = object.mask_origin_point?.map((e) => e) || [];
    message.mask_dimension_x = object.mask_dimension_x ?? 0;
    message.mask_dimension_y = object.mask_dimension_y ?? 0;
    message.mask_diameter = object.mask_diameter ?? 0;
    message.mask_angle_start = object.mask_angle_start ?? 0;
    message.mask_angle_end = object.mask_angle_end ?? 0;
    message.mask_rotation = object.mask_rotation ?? 0;
    message.mask_stp = object.mask_stp ?? new Uint8Array();
    message.nesting_n_rows = object.nesting_n_rows ?? 0;
    message.nesting_n_columns = object.nesting_n_columns ?? 0;
    message.nesting_rows_offset = object.nesting_rows_offset ?? 0;
    message.nesting_columns_offset = object.nesting_columns_offset ?? 0;
    message.nesting_stagger_columns = object.nesting_stagger_columns ?? false;
    message.nesting_replicator_units = object.nesting_replicator_units ?? "";
    message.nesting_assign_welds_by_unit = object.nesting_assign_welds_by_unit ?? false;
    message.nesting_replicator_pattern = object.nesting_replicator_pattern ?? "";
    message.imported_assembly_geometry_unit = object.imported_assembly_geometry_unit ?? "";
    message.imported_assembly_geometry_up = object.imported_assembly_geometry_up ?? "";
    return message;
  },
};

export function createBaseGridRequest_InteractivelySelectedConnectionsEntry(): GridRequest_InteractivelySelectedConnectionsEntry {
  return { key: 0, value: false };
}

export const GridRequest_InteractivelySelectedConnectionsEntry = {
  encode(
    message: GridRequest_InteractivelySelectedConnectionsEntry,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.key !== 0) {
      writer.uint32(8).int32(message.key);
    }
    if (message.value === true) {
      writer.uint32(16).bool(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GridRequest_InteractivelySelectedConnectionsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGridRequest_InteractivelySelectedConnectionsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.key = reader.int32();
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.value = reader.bool();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(
    base?: DeepPartial<GridRequest_InteractivelySelectedConnectionsEntry>,
  ): GridRequest_InteractivelySelectedConnectionsEntry {
    return GridRequest_InteractivelySelectedConnectionsEntry.fromPartial(base ?? {});
  },

  fromPartial(
    object: DeepPartial<GridRequest_InteractivelySelectedConnectionsEntry>,
  ): GridRequest_InteractivelySelectedConnectionsEntry {
    const message = createBaseGridRequest_InteractivelySelectedConnectionsEntry();
    message.key = object.key ?? 0;
    message.value = object.value ?? false;
    return message;
  },
};

function createBaseCageLayerRequest(): CageLayerRequest {
  return { params: undefined, layers: [] };
}

export const CageLayerRequest = {
  encode(message: CageLayerRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.params !== undefined) {
      CageRequest.encode(message.params, writer.uint32(10).fork()).ldelim();
    }
    writer.uint32(18).fork();
    for (const v of message.layers) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CageLayerRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCageLayerRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.params = CageRequest.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag == 16) {
            message.layers.push(reader.int32() as any);
            continue;
          }

          if (tag == 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.layers.push(reader.int32() as any);
            }

            continue;
          }

          break;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CageLayerRequest>): CageLayerRequest {
    return CageLayerRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CageLayerRequest>): CageLayerRequest {
    const message = createBaseCageLayerRequest();
    message.params = (object.params !== undefined && object.params !== null)
      ? CageRequest.fromPartial(object.params)
      : undefined;
    message.layers = object.layers?.map((e) => e) || [];
    return message;
  },
};

export function createBaseCageRequest(): CageRequest {
  return {
    stirrup_asa_type: "",
    stirrup_diameter: 0,
    stirrup_pin_diameter: 0,
    stirrup_A: 0,
    stirrup_B: 0,
    stirrup_C: 0,
    stirrup_D: 0,
    stirrup_E: 0,
    stirrup_F: 0,
    stirrup_G: 0,
    stirrup_H: 0,
    stirrup_J: 0,
    stirrup_K: 0,
    stirrup_O: 0,
    stirrup_R: 0,
    stirrup_bar_size_key: "",
    bar_asa_type: "",
    bar_diameter: 0,
    bar_pin_diameter: 0,
    bar_A: 0,
    bar_B: 0,
    bar_C: 0,
    bar_D: 0,
    bar_E: 0,
    bar_F: 0,
    bar_G: 0,
    bar_H: 0,
    bar_J: 0,
    bar_K: 0,
    bar_O: 0,
    bar_R: 0,
    bar_rotation: 0,
    bar_bar_size_key: "",
    stirrup_quantity_at_spacing: [],
    stirrup_quantity: [],
    stirrup_spacing: [],
    stirrup_rotation: [],
    rebar_quantity_total: 0,
    rebar_quantity_length: 0,
    rebar_quantity_width: 0,
    rebar_rotate_half_sector: false,
    assembly_additional_weight: 0,
    geometry_source_stp: "",
    geometry_source_ifc: "",
    workspace: 0,
    view_fabrication_setup_dimensions: false,
    view_dimensions_type: "",
    cage_preview_mesh_quality: "",
    view_part_naming: false,
    view_robot: false,
    view_bar_sequence: false,
    view_bar_path: false,
    view_stirrups_grouping: false,
    view_assembly_dimensions: false,
    view_axes_widget: false,
    metric: false,
    fabrication_weaver: "",
    fabrication_jig_foot_spacing: 0,
    fabrication_assembly_shift_x: 0,
    fabrication_assembly_rotation_x: 0,
    fabrication_assembly_flip_on_z: false,
    fabrication_naming_stirrups: [],
    fabrication_naming_bars: [],
    fabrication_assembly_stirrups: "",
    fabrication_assembly_bars: "",
    bar_preplace_offset: 0,
    loader_coordinate_grip_offset: 0,
    loader_connection_PYR: [],
    loader_track_position: 0,
    loader_add_approach_point: false,
    loader_approach_coordinates: [],
    loader_coordinate_shift: [],
    bars_sorting: "",
    automatic_cage_positioning: false,
    fabrication_notes: "",
    select_insertion_point: 0,
    fabrication_holders_flaps: "",
    fabrication_bar_insertion_time_seconds: 0,
    fabrication_tie_time_seconds: 0,
    fabrication_tie_percentage: 0,
    imported_assembly_geometry_unit: "",
    imported_assembly_geometry_up: "",
  };
}

export const CageRequest = {
  encode(message: CageRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.stirrup_asa_type !== "") {
      writer.uint32(10).string(message.stirrup_asa_type);
    }
    if (message.stirrup_diameter !== 0) {
      writer.uint32(17).double(message.stirrup_diameter);
    }
    if (message.stirrup_pin_diameter !== 0) {
      writer.uint32(25).double(message.stirrup_pin_diameter);
    }
    if (message.stirrup_A !== 0) {
      writer.uint32(33).double(message.stirrup_A);
    }
    if (message.stirrup_B !== 0) {
      writer.uint32(41).double(message.stirrup_B);
    }
    if (message.stirrup_C !== 0) {
      writer.uint32(49).double(message.stirrup_C);
    }
    if (message.stirrup_D !== 0) {
      writer.uint32(57).double(message.stirrup_D);
    }
    if (message.stirrup_E !== 0) {
      writer.uint32(65).double(message.stirrup_E);
    }
    if (message.stirrup_F !== 0) {
      writer.uint32(73).double(message.stirrup_F);
    }
    if (message.stirrup_G !== 0) {
      writer.uint32(81).double(message.stirrup_G);
    }
    if (message.stirrup_H !== 0) {
      writer.uint32(89).double(message.stirrup_H);
    }
    if (message.stirrup_J !== 0) {
      writer.uint32(97).double(message.stirrup_J);
    }
    if (message.stirrup_K !== 0) {
      writer.uint32(105).double(message.stirrup_K);
    }
    if (message.stirrup_O !== 0) {
      writer.uint32(113).double(message.stirrup_O);
    }
    if (message.stirrup_R !== 0) {
      writer.uint32(121).double(message.stirrup_R);
    }
    if (message.stirrup_bar_size_key !== "") {
      writer.uint32(130).string(message.stirrup_bar_size_key);
    }
    if (message.bar_asa_type !== "") {
      writer.uint32(170).string(message.bar_asa_type);
    }
    if (message.bar_diameter !== 0) {
      writer.uint32(177).double(message.bar_diameter);
    }
    if (message.bar_pin_diameter !== 0) {
      writer.uint32(185).double(message.bar_pin_diameter);
    }
    if (message.bar_A !== 0) {
      writer.uint32(193).double(message.bar_A);
    }
    if (message.bar_B !== 0) {
      writer.uint32(201).double(message.bar_B);
    }
    if (message.bar_C !== 0) {
      writer.uint32(209).double(message.bar_C);
    }
    if (message.bar_D !== 0) {
      writer.uint32(217).double(message.bar_D);
    }
    if (message.bar_E !== 0) {
      writer.uint32(225).double(message.bar_E);
    }
    if (message.bar_F !== 0) {
      writer.uint32(233).double(message.bar_F);
    }
    if (message.bar_G !== 0) {
      writer.uint32(241).double(message.bar_G);
    }
    if (message.bar_H !== 0) {
      writer.uint32(249).double(message.bar_H);
    }
    if (message.bar_J !== 0) {
      writer.uint32(257).double(message.bar_J);
    }
    if (message.bar_K !== 0) {
      writer.uint32(265).double(message.bar_K);
    }
    if (message.bar_O !== 0) {
      writer.uint32(273).double(message.bar_O);
    }
    if (message.bar_R !== 0) {
      writer.uint32(281).double(message.bar_R);
    }
    if (message.bar_rotation !== 0) {
      writer.uint32(289).double(message.bar_rotation);
    }
    if (message.bar_bar_size_key !== "") {
      writer.uint32(298).string(message.bar_bar_size_key);
    }
    for (const v of message.stirrup_quantity_at_spacing) {
      writer.uint32(322).string(v!);
    }
    writer.uint32(330).fork();
    for (const v of message.stirrup_quantity) {
      writer.int32(v);
    }
    writer.ldelim();
    writer.uint32(338).fork();
    for (const v of message.stirrup_spacing) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(346).fork();
    for (const v of message.stirrup_rotation) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.rebar_quantity_total !== 0) {
      writer.uint32(352).int32(message.rebar_quantity_total);
    }
    if (message.rebar_quantity_length !== 0) {
      writer.uint32(360).int32(message.rebar_quantity_length);
    }
    if (message.rebar_quantity_width !== 0) {
      writer.uint32(368).int32(message.rebar_quantity_width);
    }
    if (message.rebar_rotate_half_sector === true) {
      writer.uint32(376).bool(message.rebar_rotate_half_sector);
    }
    if (message.assembly_additional_weight !== 0) {
      writer.uint32(385).double(message.assembly_additional_weight);
    }
    if (message.geometry_source_stp !== "") {
      writer.uint32(394).string(message.geometry_source_stp);
    }
    if (message.geometry_source_ifc !== "") {
      writer.uint32(802).string(message.geometry_source_ifc);
    }
    if (message.workspace !== 0) {
      writer.uint32(400).int32(message.workspace);
    }
    if (message.view_fabrication_setup_dimensions === true) {
      writer.uint32(408).bool(message.view_fabrication_setup_dimensions);
    }
    if (message.view_dimensions_type !== "") {
      writer.uint32(418).string(message.view_dimensions_type);
    }
    if (message.cage_preview_mesh_quality !== "") {
      writer.uint32(426).string(message.cage_preview_mesh_quality);
    }
    if (message.view_part_naming === true) {
      writer.uint32(432).bool(message.view_part_naming);
    }
    if (message.view_robot === true) {
      writer.uint32(440).bool(message.view_robot);
    }
    if (message.view_bar_sequence === true) {
      writer.uint32(448).bool(message.view_bar_sequence);
    }
    if (message.view_bar_path === true) {
      writer.uint32(456).bool(message.view_bar_path);
    }
    if (message.view_stirrups_grouping === true) {
      writer.uint32(464).bool(message.view_stirrups_grouping);
    }
    if (message.view_assembly_dimensions === true) {
      writer.uint32(472).bool(message.view_assembly_dimensions);
    }
    if (message.view_axes_widget === true) {
      writer.uint32(480).bool(message.view_axes_widget);
    }
    if (message.metric === true) {
      writer.uint32(496).bool(message.metric);
    }
    if (message.fabrication_weaver !== "") {
      writer.uint32(562).string(message.fabrication_weaver);
    }
    if (message.fabrication_jig_foot_spacing !== 0) {
      writer.uint32(568).int32(message.fabrication_jig_foot_spacing);
    }
    if (message.fabrication_assembly_shift_x !== 0) {
      writer.uint32(577).double(message.fabrication_assembly_shift_x);
    }
    if (message.fabrication_assembly_rotation_x !== 0) {
      writer.uint32(585).double(message.fabrication_assembly_rotation_x);
    }
    if (message.fabrication_assembly_flip_on_z === true) {
      writer.uint32(592).bool(message.fabrication_assembly_flip_on_z);
    }
    for (const v of message.fabrication_naming_stirrups) {
      writer.uint32(602).string(v!);
    }
    for (const v of message.fabrication_naming_bars) {
      writer.uint32(610).string(v!);
    }
    if (message.fabrication_assembly_stirrups !== "") {
      writer.uint32(618).string(message.fabrication_assembly_stirrups);
    }
    if (message.fabrication_assembly_bars !== "") {
      writer.uint32(626).string(message.fabrication_assembly_bars);
    }
    if (message.bar_preplace_offset !== 0) {
      writer.uint32(633).double(message.bar_preplace_offset);
    }
    if (message.loader_coordinate_grip_offset !== 0) {
      writer.uint32(641).double(message.loader_coordinate_grip_offset);
    }
    writer.uint32(650).fork();
    for (const v of message.loader_connection_PYR) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.loader_track_position !== 0) {
      writer.uint32(657).double(message.loader_track_position);
    }
    if (message.loader_add_approach_point === true) {
      writer.uint32(664).bool(message.loader_add_approach_point);
    }
    writer.uint32(674).fork();
    for (const v of message.loader_approach_coordinates) {
      writer.double(v);
    }
    writer.ldelim();
    writer.uint32(682).fork();
    for (const v of message.loader_coordinate_shift) {
      writer.double(v);
    }
    writer.ldelim();
    if (message.bars_sorting !== "") {
      writer.uint32(690).string(message.bars_sorting);
    }
    if (message.automatic_cage_positioning === true) {
      writer.uint32(696).bool(message.automatic_cage_positioning);
    }
    if (message.fabrication_notes !== "") {
      writer.uint32(706).string(message.fabrication_notes);
    }
    if (message.select_insertion_point !== 0) {
      writer.uint32(712).int32(message.select_insertion_point);
    }
    if (message.fabrication_holders_flaps !== "") {
      writer.uint32(722).string(message.fabrication_holders_flaps);
    }
    if (message.fabrication_bar_insertion_time_seconds !== 0) {
      writer.uint32(729).double(message.fabrication_bar_insertion_time_seconds);
    }
    if (message.fabrication_tie_time_seconds !== 0) {
      writer.uint32(737).double(message.fabrication_tie_time_seconds);
    }
    if (message.fabrication_tie_percentage !== 0) {
      writer.uint32(745).double(message.fabrication_tie_percentage);
    }
    if (message.imported_assembly_geometry_unit !== "") {
      writer.uint32(882).string(message.imported_assembly_geometry_unit);
    }
    if (message.imported_assembly_geometry_up !== "") {
      writer.uint32(890).string(message.imported_assembly_geometry_up);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CageRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCageRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.stirrup_asa_type = reader.string();
          continue;
        case 2:
          if (tag != 17) {
            break;
          }

          message.stirrup_diameter = reader.double();
          continue;
        case 3:
          if (tag != 25) {
            break;
          }

          message.stirrup_pin_diameter = reader.double();
          continue;
        case 4:
          if (tag != 33) {
            break;
          }

          message.stirrup_A = reader.double();
          continue;
        case 5:
          if (tag != 41) {
            break;
          }

          message.stirrup_B = reader.double();
          continue;
        case 6:
          if (tag != 49) {
            break;
          }

          message.stirrup_C = reader.double();
          continue;
        case 7:
          if (tag != 57) {
            break;
          }

          message.stirrup_D = reader.double();
          continue;
        case 8:
          if (tag != 65) {
            break;
          }

          message.stirrup_E = reader.double();
          continue;
        case 9:
          if (tag != 73) {
            break;
          }

          message.stirrup_F = reader.double();
          continue;
        case 10:
          if (tag != 81) {
            break;
          }

          message.stirrup_G = reader.double();
          continue;
        case 11:
          if (tag != 89) {
            break;
          }

          message.stirrup_H = reader.double();
          continue;
        case 12:
          if (tag != 97) {
            break;
          }

          message.stirrup_J = reader.double();
          continue;
        case 13:
          if (tag != 105) {
            break;
          }

          message.stirrup_K = reader.double();
          continue;
        case 14:
          if (tag != 113) {
            break;
          }

          message.stirrup_O = reader.double();
          continue;
        case 15:
          if (tag != 121) {
            break;
          }

          message.stirrup_R = reader.double();
          continue;
        case 16:
          if (tag != 130) {
            break;
          }

          message.stirrup_bar_size_key = reader.string();
          continue;
        case 21:
          if (tag != 170) {
            break;
          }

          message.bar_asa_type = reader.string();
          continue;
        case 22:
          if (tag != 177) {
            break;
          }

          message.bar_diameter = reader.double();
          continue;
        case 23:
          if (tag != 185) {
            break;
          }

          message.bar_pin_diameter = reader.double();
          continue;
        case 24:
          if (tag != 193) {
            break;
          }

          message.bar_A = reader.double();
          continue;
        case 25:
          if (tag != 201) {
            break;
          }

          message.bar_B = reader.double();
          continue;
        case 26:
          if (tag != 209) {
            break;
          }

          message.bar_C = reader.double();
          continue;
        case 27:
          if (tag != 217) {
            break;
          }

          message.bar_D = reader.double();
          continue;
        case 28:
          if (tag != 225) {
            break;
          }

          message.bar_E = reader.double();
          continue;
        case 29:
          if (tag != 233) {
            break;
          }

          message.bar_F = reader.double();
          continue;
        case 30:
          if (tag != 241) {
            break;
          }

          message.bar_G = reader.double();
          continue;
        case 31:
          if (tag != 249) {
            break;
          }

          message.bar_H = reader.double();
          continue;
        case 32:
          if (tag != 257) {
            break;
          }

          message.bar_J = reader.double();
          continue;
        case 33:
          if (tag != 265) {
            break;
          }

          message.bar_K = reader.double();
          continue;
        case 34:
          if (tag != 273) {
            break;
          }

          message.bar_O = reader.double();
          continue;
        case 35:
          if (tag != 281) {
            break;
          }

          message.bar_R = reader.double();
          continue;
        case 36:
          if (tag != 289) {
            break;
          }

          message.bar_rotation = reader.double();
          continue;
        case 37:
          if (tag != 298) {
            break;
          }

          message.bar_bar_size_key = reader.string();
          continue;
        case 40:
          if (tag != 322) {
            break;
          }

          message.stirrup_quantity_at_spacing.push(reader.string());
          continue;
        case 41:
          if (tag == 328) {
            message.stirrup_quantity.push(reader.int32());
            continue;
          }

          if (tag == 330) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.stirrup_quantity.push(reader.int32());
            }

            continue;
          }

          break;
        case 42:
          if (tag == 337) {
            message.stirrup_spacing.push(reader.double());
            continue;
          }

          if (tag == 338) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.stirrup_spacing.push(reader.double());
            }

            continue;
          }

          break;
        case 43:
          if (tag == 345) {
            message.stirrup_rotation.push(reader.double());
            continue;
          }

          if (tag == 346) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.stirrup_rotation.push(reader.double());
            }

            continue;
          }

          break;
        case 44:
          if (tag != 352) {
            break;
          }

          message.rebar_quantity_total = reader.int32();
          continue;
        case 45:
          if (tag != 360) {
            break;
          }

          message.rebar_quantity_length = reader.int32();
          continue;
        case 46:
          if (tag != 368) {
            break;
          }

          message.rebar_quantity_width = reader.int32();
          continue;
        case 47:
          if (tag != 376) {
            break;
          }

          message.rebar_rotate_half_sector = reader.bool();
          continue;
        case 48:
          if (tag != 385) {
            break;
          }

          message.assembly_additional_weight = reader.double();
          continue;
        case 49:
          if (tag != 394) {
            break;
          }

          message.geometry_source_stp = reader.string();
          continue;
        case 100:
          if (tag != 802) {
            break;
          }

          message.geometry_source_ifc = reader.string();
          continue;
        case 50:
          if (tag != 400) {
            break;
          }

          message.workspace = reader.int32();
          continue;
        case 51:
          if (tag != 408) {
            break;
          }

          message.view_fabrication_setup_dimensions = reader.bool();
          continue;
        case 52:
          if (tag != 418) {
            break;
          }

          message.view_dimensions_type = reader.string();
          continue;
        case 53:
          if (tag != 426) {
            break;
          }

          message.cage_preview_mesh_quality = reader.string();
          continue;
        case 54:
          if (tag != 432) {
            break;
          }

          message.view_part_naming = reader.bool();
          continue;
        case 55:
          if (tag != 440) {
            break;
          }

          message.view_robot = reader.bool();
          continue;
        case 56:
          if (tag != 448) {
            break;
          }

          message.view_bar_sequence = reader.bool();
          continue;
        case 57:
          if (tag != 456) {
            break;
          }

          message.view_bar_path = reader.bool();
          continue;
        case 58:
          if (tag != 464) {
            break;
          }

          message.view_stirrups_grouping = reader.bool();
          continue;
        case 59:
          if (tag != 472) {
            break;
          }

          message.view_assembly_dimensions = reader.bool();
          continue;
        case 60:
          if (tag != 480) {
            break;
          }

          message.view_axes_widget = reader.bool();
          continue;
        case 62:
          if (tag != 496) {
            break;
          }

          message.metric = reader.bool();
          continue;
        case 70:
          if (tag != 562) {
            break;
          }

          message.fabrication_weaver = reader.string();
          continue;
        case 71:
          if (tag != 568) {
            break;
          }

          message.fabrication_jig_foot_spacing = reader.int32();
          continue;
        case 72:
          if (tag != 577) {
            break;
          }

          message.fabrication_assembly_shift_x = reader.double();
          continue;
        case 73:
          if (tag != 585) {
            break;
          }

          message.fabrication_assembly_rotation_x = reader.double();
          continue;
        case 74:
          if (tag != 592) {
            break;
          }

          message.fabrication_assembly_flip_on_z = reader.bool();
          continue;
        case 75:
          if (tag != 602) {
            break;
          }

          message.fabrication_naming_stirrups.push(reader.string());
          continue;
        case 76:
          if (tag != 610) {
            break;
          }

          message.fabrication_naming_bars.push(reader.string());
          continue;
        case 77:
          if (tag != 618) {
            break;
          }

          message.fabrication_assembly_stirrups = reader.string();
          continue;
        case 78:
          if (tag != 626) {
            break;
          }

          message.fabrication_assembly_bars = reader.string();
          continue;
        case 79:
          if (tag != 633) {
            break;
          }

          message.bar_preplace_offset = reader.double();
          continue;
        case 80:
          if (tag != 641) {
            break;
          }

          message.loader_coordinate_grip_offset = reader.double();
          continue;
        case 81:
          if (tag == 649) {
            message.loader_connection_PYR.push(reader.double());
            continue;
          }

          if (tag == 650) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.loader_connection_PYR.push(reader.double());
            }

            continue;
          }

          break;
        case 82:
          if (tag != 657) {
            break;
          }

          message.loader_track_position = reader.double();
          continue;
        case 83:
          if (tag != 664) {
            break;
          }

          message.loader_add_approach_point = reader.bool();
          continue;
        case 84:
          if (tag == 673) {
            message.loader_approach_coordinates.push(reader.double());
            continue;
          }

          if (tag == 674) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.loader_approach_coordinates.push(reader.double());
            }

            continue;
          }

          break;
        case 85:
          if (tag == 681) {
            message.loader_coordinate_shift.push(reader.double());
            continue;
          }

          if (tag == 682) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.loader_coordinate_shift.push(reader.double());
            }

            continue;
          }

          break;
        case 86:
          if (tag != 690) {
            break;
          }

          message.bars_sorting = reader.string();
          continue;
        case 87:
          if (tag != 696) {
            break;
          }

          message.automatic_cage_positioning = reader.bool();
          continue;
        case 88:
          if (tag != 706) {
            break;
          }

          message.fabrication_notes = reader.string();
          continue;
        case 89:
          if (tag != 712) {
            break;
          }

          message.select_insertion_point = reader.int32();
          continue;
        case 90:
          if (tag != 722) {
            break;
          }

          message.fabrication_holders_flaps = reader.string();
          continue;
        case 91:
          if (tag != 729) {
            break;
          }

          message.fabrication_bar_insertion_time_seconds = reader.double();
          continue;
        case 92:
          if (tag != 737) {
            break;
          }

          message.fabrication_tie_time_seconds = reader.double();
          continue;
        case 93:
          if (tag != 745) {
            break;
          }

          message.fabrication_tie_percentage = reader.double();
          continue;
        case 110:
          if (tag != 882) {
            break;
          }

          message.imported_assembly_geometry_unit = reader.string();
          continue;
        case 111:
          if (tag != 890) {
            break;
          }

          message.imported_assembly_geometry_up = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<CageRequest>): CageRequest {
    return CageRequest.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<CageRequest>): CageRequest {
    const message = createBaseCageRequest();
    message.stirrup_asa_type = object.stirrup_asa_type ?? "";
    message.stirrup_diameter = object.stirrup_diameter ?? 0;
    message.stirrup_pin_diameter = object.stirrup_pin_diameter ?? 0;
    message.stirrup_A = object.stirrup_A ?? 0;
    message.stirrup_B = object.stirrup_B ?? 0;
    message.stirrup_C = object.stirrup_C ?? 0;
    message.stirrup_D = object.stirrup_D ?? 0;
    message.stirrup_E = object.stirrup_E ?? 0;
    message.stirrup_F = object.stirrup_F ?? 0;
    message.stirrup_G = object.stirrup_G ?? 0;
    message.stirrup_H = object.stirrup_H ?? 0;
    message.stirrup_J = object.stirrup_J ?? 0;
    message.stirrup_K = object.stirrup_K ?? 0;
    message.stirrup_O = object.stirrup_O ?? 0;
    message.stirrup_R = object.stirrup_R ?? 0;
    message.stirrup_bar_size_key = object.stirrup_bar_size_key ?? "";
    message.bar_asa_type = object.bar_asa_type ?? "";
    message.bar_diameter = object.bar_diameter ?? 0;
    message.bar_pin_diameter = object.bar_pin_diameter ?? 0;
    message.bar_A = object.bar_A ?? 0;
    message.bar_B = object.bar_B ?? 0;
    message.bar_C = object.bar_C ?? 0;
    message.bar_D = object.bar_D ?? 0;
    message.bar_E = object.bar_E ?? 0;
    message.bar_F = object.bar_F ?? 0;
    message.bar_G = object.bar_G ?? 0;
    message.bar_H = object.bar_H ?? 0;
    message.bar_J = object.bar_J ?? 0;
    message.bar_K = object.bar_K ?? 0;
    message.bar_O = object.bar_O ?? 0;
    message.bar_R = object.bar_R ?? 0;
    message.bar_rotation = object.bar_rotation ?? 0;
    message.bar_bar_size_key = object.bar_bar_size_key ?? "";
    message.stirrup_quantity_at_spacing = object.stirrup_quantity_at_spacing?.map((e) => e) || [];
    message.stirrup_quantity = object.stirrup_quantity?.map((e) => e) || [];
    message.stirrup_spacing = object.stirrup_spacing?.map((e) => e) || [];
    message.stirrup_rotation = object.stirrup_rotation?.map((e) => e) || [];
    message.rebar_quantity_total = object.rebar_quantity_total ?? 0;
    message.rebar_quantity_length = object.rebar_quantity_length ?? 0;
    message.rebar_quantity_width = object.rebar_quantity_width ?? 0;
    message.rebar_rotate_half_sector = object.rebar_rotate_half_sector ?? false;
    message.assembly_additional_weight = object.assembly_additional_weight ?? 0;
    message.geometry_source_stp = object.geometry_source_stp ?? "";
    message.geometry_source_ifc = object.geometry_source_ifc ?? "";
    message.workspace = object.workspace ?? 0;
    message.view_fabrication_setup_dimensions = object.view_fabrication_setup_dimensions ?? false;
    message.view_dimensions_type = object.view_dimensions_type ?? "";
    message.cage_preview_mesh_quality = object.cage_preview_mesh_quality ?? "";
    message.view_part_naming = object.view_part_naming ?? false;
    message.view_robot = object.view_robot ?? false;
    message.view_bar_sequence = object.view_bar_sequence ?? false;
    message.view_bar_path = object.view_bar_path ?? false;
    message.view_stirrups_grouping = object.view_stirrups_grouping ?? false;
    message.view_assembly_dimensions = object.view_assembly_dimensions ?? false;
    message.view_axes_widget = object.view_axes_widget ?? false;
    message.metric = object.metric ?? false;
    message.fabrication_weaver = object.fabrication_weaver ?? "";
    message.fabrication_jig_foot_spacing = object.fabrication_jig_foot_spacing ?? 0;
    message.fabrication_assembly_shift_x = object.fabrication_assembly_shift_x ?? 0;
    message.fabrication_assembly_rotation_x = object.fabrication_assembly_rotation_x ?? 0;
    message.fabrication_assembly_flip_on_z = object.fabrication_assembly_flip_on_z ?? false;
    message.fabrication_naming_stirrups = object.fabrication_naming_stirrups?.map((e) => e) || [];
    message.fabrication_naming_bars = object.fabrication_naming_bars?.map((e) => e) || [];
    message.fabrication_assembly_stirrups = object.fabrication_assembly_stirrups ?? "";
    message.fabrication_assembly_bars = object.fabrication_assembly_bars ?? "";
    message.bar_preplace_offset = object.bar_preplace_offset ?? 0;
    message.loader_coordinate_grip_offset = object.loader_coordinate_grip_offset ?? 0;
    message.loader_connection_PYR = object.loader_connection_PYR?.map((e) => e) || [];
    message.loader_track_position = object.loader_track_position ?? 0;
    message.loader_add_approach_point = object.loader_add_approach_point ?? false;
    message.loader_approach_coordinates = object.loader_approach_coordinates?.map((e) => e) || [];
    message.loader_coordinate_shift = object.loader_coordinate_shift?.map((e) => e) || [];
    message.bars_sorting = object.bars_sorting ?? "";
    message.automatic_cage_positioning = object.automatic_cage_positioning ?? false;
    message.fabrication_notes = object.fabrication_notes ?? "";
    message.select_insertion_point = object.select_insertion_point ?? 0;
    message.fabrication_holders_flaps = object.fabrication_holders_flaps ?? "";
    message.fabrication_bar_insertion_time_seconds = object.fabrication_bar_insertion_time_seconds ?? 0;
    message.fabrication_tie_time_seconds = object.fabrication_tie_time_seconds ?? 0;
    message.fabrication_tie_percentage = object.fabrication_tie_percentage ?? 0;
    message.imported_assembly_geometry_unit = object.imported_assembly_geometry_unit ?? "";
    message.imported_assembly_geometry_up = object.imported_assembly_geometry_up ?? "";
    return message;
  },
};

/** Health generator service definition. */
export type HealthDefinition = typeof HealthDefinition;
export const HealthDefinition = {
  name: "Health",
  fullName: "Health",
  methods: {
    check: {
      name: "Check",
      requestType: Empty,
      requestStream: false,
      responseType: Empty,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface HealthServiceImplementation<CallContextExt = {}> {
  check(request: Empty, context: CallContext & CallContextExt): Promise<DeepPartial<Empty>>;
}

export interface HealthClient<CallOptionsExt = {}> {
  check(request: DeepPartial<Empty>, options?: CallOptions & CallOptionsExt): Promise<Empty>;
}

/** Task generator service definition. */
export type TaskDefinition = typeof TaskDefinition;
export const TaskDefinition = {
  name: "Task",
  fullName: "Task",
  methods: {
    task: {
      name: "Task",
      requestType: TaskRequest,
      requestStream: false,
      responseType: TaskResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface TaskServiceImplementation<CallContextExt = {}> {
  task(request: TaskRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskResponse>>;
}

export interface TaskClient<CallOptionsExt = {}> {
  task(request: DeepPartial<TaskRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskResponse>;
}

/** SimulationCheck generator service definition. */
export type SimulationCheckDefinition = typeof SimulationCheckDefinition;
export const SimulationCheckDefinition = {
  name: "SimulationCheck",
  fullName: "SimulationCheck",
  methods: {
    grid: {
      name: "Grid",
      requestType: GridRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    cage: {
      name: "Cage",
      requestType: CageRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface SimulationCheckServiceImplementation<CallContextExt = {}> {
  grid(request: GridRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  cage(request: CageRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
}

export interface SimulationCheckClient<CallOptionsExt = {}> {
  grid(request: DeepPartial<GridRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  cage(request: DeepPartial<CageRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
}

/** Model generator service definition. */
export type ModelDefinition = typeof ModelDefinition;
export const ModelDefinition = {
  name: "Model",
  fullName: "Model",
  methods: {
    grid: {
      name: "Grid",
      requestType: GridModelRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    cage: {
      name: "Cage",
      requestType: CageModelRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface ModelServiceImplementation<CallContextExt = {}> {
  grid(request: GridModelRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  cage(request: CageModelRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
}

export interface ModelClient<CallOptionsExt = {}> {
  grid(request: DeepPartial<GridModelRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  cage(request: DeepPartial<CageModelRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
}

/** Image generator service definition. */
export type ImageDefinition = typeof ImageDefinition;
export const ImageDefinition = {
  name: "Image",
  fullName: "Image",
  methods: {
    grid: {
      name: "Grid",
      requestType: GridImageRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    cage: {
      name: "Cage",
      requestType: CageImageRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface ImageServiceImplementation<CallContextExt = {}> {
  grid(request: GridImageRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  cage(request: CageImageRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
}

export interface ImageClient<CallOptionsExt = {}> {
  grid(request: DeepPartial<GridImageRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  cage(request: DeepPartial<CageImageRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
}

/** RobotProgram generator service definition. */
export type RobotProgramDefinition = typeof RobotProgramDefinition;
export const RobotProgramDefinition = {
  name: "RobotProgram",
  fullName: "RobotProgram",
  methods: {
    grid: {
      name: "Grid",
      requestType: GridRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    cage: {
      name: "Cage",
      requestType: CageRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface RobotProgramServiceImplementation<CallContextExt = {}> {
  grid(request: GridRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  cage(request: CageRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
}

export interface RobotProgramClient<CallOptionsExt = {}> {
  grid(request: DeepPartial<GridRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  cage(request: DeepPartial<CageRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
}

/** Visualization generator service definition. */
export type VisualizationDefinition = typeof VisualizationDefinition;
export const VisualizationDefinition = {
  name: "Visualization",
  fullName: "Visualization",
  methods: {
    /** Generates a grid. */
    grid: {
      name: "Grid",
      requestType: GridLayerRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    gridHashes: {
      name: "GridHashes",
      requestType: GridRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    cage: {
      name: "Cage",
      requestType: CageLayerRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    cageHashes: {
      name: "CageHashes",
      requestType: CageRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    programPath: {
      name: "ProgramPath",
      requestType: ProgramPathLayerRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
    programPathHashes: {
      name: "ProgramPathHashes",
      requestType: ProgramPathRequest,
      requestStream: false,
      responseType: TaskIDResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface VisualizationServiceImplementation<CallContextExt = {}> {
  /** Generates a grid. */
  grid(request: GridLayerRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  gridHashes(request: GridRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  cage(request: CageLayerRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  cageHashes(request: CageRequest, context: CallContext & CallContextExt): Promise<DeepPartial<TaskIDResponse>>;
  programPath(
    request: ProgramPathLayerRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<TaskIDResponse>>;
  programPathHashes(
    request: ProgramPathRequest,
    context: CallContext & CallContextExt,
  ): Promise<DeepPartial<TaskIDResponse>>;
}

export interface VisualizationClient<CallOptionsExt = {}> {
  /** Generates a grid. */
  grid(request: DeepPartial<GridLayerRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  gridHashes(request: DeepPartial<GridRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  cage(request: DeepPartial<CageLayerRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  cageHashes(request: DeepPartial<CageRequest>, options?: CallOptions & CallOptionsExt): Promise<TaskIDResponse>;
  programPath(
    request: DeepPartial<ProgramPathLayerRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<TaskIDResponse>;
  programPathHashes(
    request: DeepPartial<ProgramPathRequest>,
    options?: CallOptions & CallOptionsExt,
  ): Promise<TaskIDResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
