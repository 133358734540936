import React from "react"
import _ from "lodash"

import { Property, Badge, Icon, DateTime } from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType, WorkCellProgram, WorkCellProgramFeedbackType } from "@app/domain"

interface IWorkCellProgramDetailsProps {
	workCellProgram?: WorkCellProgram
}

const FeedbackTypeBadge = (props: { feedbackType: string }) => {
	const { feedbackType } = props
	const { t } = useSession()

	let icon = "Comment",
		color = "bg-gray-500"
	switch (feedbackType) {
		case WorkCellProgramFeedbackType.Approved:
			color = "bg-green-500"
			icon = "Check"
			break
		case WorkCellProgramFeedbackType.Unapproved:
			color = "bg-red-500"
			icon = "Times"
			break
	}

	return (
		<Badge color={`text-white ${color}`}>
			<span>
				<Icon name={icon} className="mr-1" />
				{t(`workCellProgramFeedback.types.${_.camelCase(feedbackType)}`)}
			</span>
		</Badge>
	)
}

export const WorkCellProgramDetails: React.FC<IWorkCellProgramDetailsProps> = (props) => {
	const { t } = useSession()
	const { workCellProgram } = props

	if (_.isNil(workCellProgram)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("workCellProgram.labels.name")} value={workCellProgram.name} />
			<Property
				label={t("workCellProgram.labels.workCellType")}
				value={t(`workCellTypes.${workCellProgram.workCellType}`)}
			/>
			<Property
				label={t("workCellProgram.labels.assemblyID")}
				relation={EntityType.Assembly}
				value={workCellProgram.assemblyID}
			/>
			<Property label={t("workCellProgram.labels.version")} value={workCellProgram.version} />
			<Property
				label={t("workCellProgram.labels.parentWorkCellID")}
				relation={EntityType.WorkCellProgram}
				value={workCellProgram.parentWorkCellProgramID}
			/>
			<Property
				label={t("workCellProgram.labels.description")}
				value={workCellProgram.description}
			/>
			{!_.isEmpty(workCellProgram.feedback) && (
				<Property group={t("workCellProgram.labels.feedback")} />
			)}
			<div className="divide-y divide-gray-400 border-b border-gray-400">
				{_.map(workCellProgram.feedback, (v) => {
					return (
						<div key={v.id} className="py-4">
							<Property
								label={t("workCellProgramFeedback.labels.note")}
								value={v.note}
								lineBreaks={true}
							/>
							<Property
								label={t("workCellProgramFeedback.labels.userID")}
								relation={EntityType.User}
								value={v.userID}
							/>
							<div className="space-x-1 text-right">
								<FeedbackTypeBadge feedbackType={v.feedbackType} />
								<Badge label={t("common.labels.created")}>
									<DateTime time={v.created} />
								</Badge>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
