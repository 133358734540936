import { rpc } from "@app/api"

import { Result } from "@app/api"
import { Tenant } from "@app/domain"

export type RunHistory = {
	id: string
	name: string
	count: number
	workFacilityName: string
	workFacilityID: string
	tenantID: number
	history: {
		from: string
		to: string
		runs: number
	}[]
}

export type GetWorkCellRunHistoriesResult = {
	runHistory: RunHistory[]
	tenants: Tenant[]
}

export type GetWorkCellRunHistoriesParams = {
	tenantID?: number
	showAll?: boolean
}

export const GetWorkCellRunHistories = async (
	params: GetWorkCellRunHistoriesParams = {},
): Promise<Result<GetWorkCellRunHistoriesResult>> => {
	const data = await rpc("GetWorkCellRunHistories", params)
	return data as Result<GetWorkCellRunHistoriesResult>
}
