import _ from "lodash"

import { Translator } from "@app/contexts"
import { fromUUID } from "@app/util"
import Config from "@app/config"

export type WorkDevice = {
	id: string
	workFacilityID: string

	description: string
	disabled: boolean
	inventoryTag: string
	secret: string
	tenantID: number
	workDeviceType: string

	created: string
	updated: string
}

export const newWorkDevice = (opts?: { [key: string]: string | boolean | number }): WorkDevice => {
	return _.assign(
		{
			id: "",
			workFacilityID: "",

			description: "",
			disabled: false,
			inventoryTag: "",
			secret: "",
			tenantID: 0,
			workDeviceType: "AndroidScanner",

			created: "",
			updated: "",
		},
		opts,
	)
}

export const WorkDeviceUtils = {
	name: (t: Translator, v?: WorkDevice): string => {
		if (v) {
			return `${t(`workDeviceTypes.${v.workDeviceType}`)} (${v.inventoryTag})`
		}
		return ""
	},

	configURL: (v?: WorkDevice): string => {
		if (v) {
			return `${Config.baseURL}/pt/config/${fromUUID(v.id)}/${v.secret}/${v.tenantID}`
		}
		return ""
	},
}
