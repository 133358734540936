import React from "react"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"
import { File, EntityType } from "@app/domain"

interface IFileDetailsProps {
	file?: File
}

export const FileDetails: React.FC<IFileDetailsProps> = (props) => {
	const { t } = useSession()
	const { file } = props
	if (!file) {
		return null
	}

	return (
		<div className="flex flex-col md:flex-col">
			<div className="pt-1 px-2 max-w-lg">
				<Property label={t("file.labels.name")} value={file.name} />
				<Property label={t("file.labels.type")} value={t(`fileTypes.${file.type}`)} />
				<Property label={t("file.labels.size")} fileSize={file.size} />
				{file?.fileableID && (
					<Property
						label={`Parent ${file.fileableType}`}
						relation={file.fileableType as EntityType}
						value={file?.fileableID}
					/>
				)}
			</div>
		</div>
	)
}
