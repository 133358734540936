import React from "react"
import _ from "lodash"
import format from "date-fns-tz/format"
import formatInTimeZone from "date-fns-tz/formatInTimeZone"

import { useSession } from "@app/contexts"
import { Address, TagColor, Entity, EntityType, AddressUtils, Contact } from "@app/domain"
import { Link, DateTime, Icon, ColorOption } from "@app/components"
import { useRelation, useEntityDetails } from "@app/hooks"
import { toFixed, browserTimezone } from "@app/util"
import prettyBytes from "pretty-bytes"

interface IPropertyProps {
	address?: Address
	color?: TagColor
	date?: string | null
	dateExplicit?: boolean
	dateOnly?: boolean
	dateTimezone?: string
	email?: string
	fileSize?: number
	group?: string
	id?: string
	label?: string
	length?: number
	lineBreaks?: boolean
	metric?: boolean
	nolink?: boolean
	phoneNumber?: string
	relation?: EntityType
	timezone?: string
	url?: string
	value?: string | number | boolean | null
	weight?: number | null
}

export const Property: React.FC<IPropertyProps> = (props) => {
	const {
		address,
		color,
		date,
		dateExplicit = false,
		dateOnly = true,
		dateTimezone = browserTimezone,
		email,
		fileSize,
		group,
		id,
		label,
		length,
		lineBreaks,
		metric,
		nolink,
		phoneNumber,
		relation,
		timezone,
		url,
		value,
		weight,
	} = props

	const { t } = useSession()

	const [display, setDisplay] = React.useState<React.ReactNode | undefined>()
	const [open, setOpen] = React.useState<boolean>(false)
	const relationID = relation ? (value || "").toString() : ""
	const [entity, entityLoading] = useRelation<Entity>(relation, relationID)
	const [, name, route] = useEntityDetails(relation, entity)

	React.useEffect(() => {
		let timeout: ReturnType<typeof setTimeout> | undefined = undefined
		const update = () => {
			const timeZone = timezone ? timezone : "US/Eastern"
			const now = Date.now()
			let offset = format(now, "XXX", { timeZone })
			if (offset === "Z") {
				offset = "+00:00"
			}
			const current = formatInTimeZone(now, timeZone, "ccc PPpp")
			setDisplay(
				<span>
					{`GMT${offset} ${timezone}`}
					<br />
					<span className="text-gray-600">{`(current time: ${current})`}</span>
				</span>,
			)
			const ms = 1000 - (now % 1000)
			timeout = setTimeout(update, ms)
		}
		if (_.isString(timezone)) {
			update()
		}
		return () => {
			if (timeout) {
				clearTimeout(timeout)
			}
		}
	}, [timezone])

	React.useEffect(() => {
		if (relation && entity) {
			if (nolink) {
				setDisplay(<span>{name}</span>)
			} else {
				setDisplay(
					<Link to={route} relation>
						{name}
					</Link>,
				)
			}
		}
	}, [entityLoading])

	let out: string | number | undefined | React.ReactNode[] | React.ReactNode = (
		<span className="text-gray-500">n/a</span>
	)

	if (relation) {
		out = _.isEmpty(value) ? out : "..."
	} else if (_.isString(timezone)) {
		out = "..."
	} else if (fileSize) {
		out = prettyBytes(fileSize)
	} else if (color) {
		if (color !== TagColor.None) {
			out = <ColorOption color={color} readonly={true} checked={true} />
		}
	} else if (_.isNumber(length)) {
		if (metric) {
			out = `${toFixed(length, 3)} mm`
		} else {
			const inches = length / 25.4
			const feet = Math.floor(inches / 12)
			out = `${feet}-${toFixed(inches % 12, 3)}`
		}
	} else if (weight) {
		const units = metric ? "metric" : "imperial"
		out = `${weight} ${t(`units.${units}.weightAbbr`)}`
	} else if (group) {
		return (
			<div className="py-1 my-2 font-medium text-sm border-b border-gray-400">
				<h3>{group}</h3>
			</div>
		)
	} else if (url) {
		const linkable = _.some(["http:", "https:", "tel:"], (prefix) => _.startsWith(url, prefix))
		if (linkable) {
			out = (
				<a href={url} className="text-blue-500 font-medium hover:underline hover:text-blue-light">
					{_.truncate(value ? value.toString() : url, { length: 30 })}
				</a>
			)
		} else {
			out = <span className="font-mono text-sm">{url}</span>
		}
	} else if (email) {
		out = <Link to={`mailto:${email}`}>{email}</Link>
	} else if (phoneNumber) {
		out = <Link to={`tel:${phoneNumber}`}>{phoneNumber}</Link>
	} else if (date) {
		out = (
			<DateTime
				time={date}
				dateOnly={dateOnly && !dateExplicit}
				explicit={dateExplicit}
				timezone={dateTimezone}
			/>
		)
	} else if (_.isBoolean(value)) {
		out = value ? t("common.yes") : t("common.no")
	} else if (_.isNumber(value)) {
		out = new Intl.NumberFormat().format(value)
	} else if (value) {
		out = value
		if (lineBreaks && _.isString(value)) {
			out = _.reduce(
				value.split("\n"),
				(acc: React.ReactNode[], line: string, i: number) => {
					acc.push(<div key={i}>{line}</div>)
					return acc
				},
				[],
			)
		}
	} else if (id) {
		out = <code className="select-all text-sm">{id}</code>
	} else if (address) {
		const lines = AddressUtils.lines(address, t)
		if (_.size(lines) > 0) {
			const place = lines.join(" ").replace(/\s+/g, "+")
			out = (
				<a
					className="inline-block hover:text-blue-500 hover:underline"
					href={`https://www.google.com/maps/place/${place}`}
					rel="noreferrer"
					target="_blank"
				>
					{lines.map((line: string, i: number) => (
						<span key={i}>
							{line}
							<br />
						</span>
					))}
				</a>
			)
		}
	}

	if (display) {
		out = display
	}

	if (relation === EntityType.Contact && entity) {
		const contact = entity as Contact
		out = (
			<>
				<div>
					<button
						className="mr-2 bg-yellow-500 text-black inline-flex items-center justify-center h-6 w-6 rounded-full text-sm "
						type="button"
						onClick={() => setOpen(!open)}
					>
						{!open ? <Icon name="ChevronDown" /> : <Icon name="ChevronUp" />}
					</button>

					<Link to={route} relation>
						{name}
					</Link>
				</div>
				{open ? (
					<div className="pl-8">
						{!_.isEmpty(contact.title) ? (
							<div className="text-gray-700 text-sm">{contact.title}</div>
						) : null}
						{_.map(contact.emails, (v) => (
							<div key={v.email}>
								<Link className="text-sm text-blue-500" to={`mailto:${v.email}`}>
									{v.email}
								</Link>
								{!_.isEmpty(v.labels) ? (
									<span className="text-sm ml-1 text-gray-500">({v.labels.join(", ")})</span>
								) : null}
							</div>
						))}
						{_.map(contact.phoneNumbers, (v) => (
							<div key={v.phoneNumber}>
								<Link className="text-sm text-blue-500" to={`tel:${v.phoneNumber}`}>
									{v.phoneNumber}
								</Link>
								{!_.isEmpty(v.labels) ? (
									<span className="text-sm ml-1 text-gray-500">({v.labels.join(", ")})</span>
								) : null}
							</div>
						))}
					</div>
				) : null}
			</>
		)
	}

	if (_.isEmpty(label)) {
		return <span>{out}</span>
	}
	return (
		<div className="w-full grid grid-cols-1 sm:grid-cols-3 sm:gap-4 sm:mb-1">
			<div className="text-gray-700">{label}</div>
			<div className="mb-2 sm:mb-0 sm:col-span-2">{out}</div>
		</div>
	)
}
