import _ from "lodash"

export type WorkResource = {
	id: string
	workCellID: string | null
	workFacilityID: string

	description: string
	disabled: boolean
	name: string
	url: string
	workResourceType: string

	created: string
	updated: string
}

export const newWorkResource = (opts?: {
	[key: string]: string | boolean | number
}): WorkResource => {
	return _.assign(
		{
			id: "",
			workCellID: null,
			workFacilityID: "",

			description: "",
			disabled: false,
			name: "",
			url: "",
			workResourceType: "",

			created: "",
			updated: "",
		},
		opts,
	)
}
