import _ from "lodash"

import {
	AddressParams,
	AttributeParams,
	Batch,
	compactIDs,
	ContactableParams,
	ReceivedResult,
	rpc,
} from "@app/api"

import type { Result } from "@app/api"
import type { Shipment, Pagination, Entity } from "@app/domain"

export type GenerateShipmentFromReleaseParams = {
	releaseID: string
}

export const GenerateShipmentFromRelease = async (
	params: GenerateShipmentFromReleaseParams,
): Promise<Result<SaveShipmentResult>> => {
	const data = await rpc("GenerateShipmentFromRelease", params)
	return ReceivedResult<GetShipmentsResult>(data)
}

export type GenerateShipmentFromJobParams = {
	jobID: string
}

export const GenerateShipmentFromJob = async (
	params: GenerateShipmentFromJobParams,
): Promise<Result<SaveShipmentResult>> => {
	const data = await rpc("GenerateShipmentFromJob", params)
	return ReceivedResult<GetShipmentsResult>(data)
}

export type SaveShipmentParams = {
	id?: string
	customerID: string
	jobID: string
	releaseID: string
	workFacilityID: string

	addresses: AddressParams[]
	attributes: AttributeParams[]
	delivered: string | null
	metric: boolean
	name: string
	referenceID?: string
	shipped: string | null

	contactables: ContactableParams[]
	removeContactables: string[]
}

export const newSaveShipmentParams = (shipment?: Shipment): SaveShipmentParams => {
	return {
		id: _.get(shipment, "id", ""),
		customerID: _.get(shipment, "customerID", ""),
		jobID: _.get(shipment, "jobID", ""),
		releaseID: _.get(shipment, "releaseID", ""),
		workFacilityID: _.get(shipment, "workFacilityID") || "",

		addresses: _.get(shipment, "addresses", []),
		attributes: _.get(shipment, "attributes", []),
		metric: _.get(shipment, "metric", false),
		name: _.get(shipment, "name", ""),
		shipped: _.get(shipment, "shipped", null),
		delivered: _.get(shipment, "delivered", null),

		contactables: _.get(shipment, "contactables", []),
		removeContactables: [],
	}
}

export type SaveShipmentResult = {
	shipments: Shipment[]
}

export const SaveShipment = async (
	params: SaveShipmentParams,
): Promise<Result<SaveShipmentResult>> => {
	const data = await rpc("SaveShipment", params, { minDuration: 300 })
	return ReceivedResult<GetShipmentsResult>(data)
}

export type GetShipmentsParams = {
	ids?: string[]
	includeIDs?: string[]

	customerID?: string
	contactID?: string
	jobID?: string
	releaseID?: string
	workFacilityID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetShipmentsResult = {
	shipments: Shipment[]
	pagination: Pagination
}

export const GetShipments = async (
	params: GetShipmentsParams,
): Promise<Result<GetShipmentsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetShipments", "shipments", id).then((shipment?: Entity) => {
			return {
				ok: true,
				result: { shipments: _.compact([shipment as Shipment]) },
			} as Result<GetShipmentsResult>
		})
	}
	const data = await rpc("GetShipments", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetShipmentsResult>(data)
}
