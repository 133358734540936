import React from "react"
import { useInterval } from "usehooks-ts"

interface IImageProps {
	src: string
	fallback: (error: any) => JSX.Element // eslint-disable-line @typescript-eslint/no-explicit-any
}

type ImageProps = IImageProps &
	React.ImgHTMLAttributes<HTMLImageElement> &
	React.ImgHTMLAttributes<HTMLImageElement>

export const Image: React.FC<ImageProps> = (props) => {
	const { fallback, src, ...rest } = props

	const [loading, setLoading] = React.useState(true)
	const [err, setErr] = React.useState(false)
	const [tries, setTries] = React.useState(0)

	// Try to load the image immediately, then retry with exponential backoff.
	// Intervals of 400, 800, 1600, 3200, 6400 (max).
	const delay = !loading || err ? null : tries === 0 ? 0 : Math.pow(2, Math.min(tries, 5)) * 200

	useInterval(
		() => {
			const img = new window.Image()
			img.onload = () => setLoading(false)
			img.onerror = () => {
				if (tries >= 8) {
					setErr(true)
				}
				setTries(tries + 1)
			}
			img.src = src
		},
		// Delay in milliseconds or null to stop it.
		delay,
	)

	return loading ? fallback(err) : <img src={src} {...rest} />
}
