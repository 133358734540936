import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, DateTime, Tabs, Title, EntityBreadcrumbs } from "@app/components"
import { urlTo } from "@app/util"
import { User, EntityType, UserUtils, UserPermission } from "@app/domain"

interface IUserHeaderProps {
	user?: User
	section?: string
	view: string
}

export const UserHeader: React.FC<IUserHeaderProps> = (props) => {
	const { user, section = "", view } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: UserUtils.name(user) || "...", created: "", updated: "" },
		user,
	)

	const { t, userHas } = useSession()

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("users/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("users", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("users/log", id),
					active: view === "log",
				},
			],
		},

		{ label: t("common.tabs.edit"), to: urlTo("users/edit", id), active: view === "edit" },
		{ label: t("common.tabs.newPIN"), to: urlTo("users/new-pin", id), active: view === "newPIN" },
	]
	// Remove edit tab from showing if user lacks ModifyUsers permission (user can
	// still manually go to .../users/{user-short-id}/edit, but saving will fail
	// on the backend).
	tabs.splice(1, userHas(UserPermission.ModifyUsers) ? 0 : 2)

	let title = ""
	switch (view) {
		case "show":
			title = t("user.titles.show", { name })
			break
		case "edit":
			title = t("user.titles.edit", { name })
			break
		case "new":
			title = t("user.titles.new")
			break
		case "newPIN":
			title = t("user.titles.newPIN", { name })
			break
		case "log":
			title = t("user.titles.log", { name })
			break
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={user} entityType={EntityType.User} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
