import { Link as RouterLink } from "react-router-dom"
import _ from "lodash"

import React from "react"

export interface ILinkProps {
	className?: string
	disabled?: boolean
	relation?: boolean
	styled?: boolean
	to?: string
}

export const Link: React.FC<ILinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = (
	props,
) => {
	const { styled = false, relation = false, disabled, to, className, ...rest } = props
	const isEmail = _.startsWith(to, "mailto:")
	const isPhone = _.startsWith(to, "tel:")

	let classes = ""

	if (disabled) {
		return <span {...rest} className={`select-none ${className}`} />
	}

	if (styled || relation || isEmail || isPhone) {
		classes = "text-blue-500 font-medium hover:underline hover:text-blue-light "
		if (isEmail || isPhone) {
			return <a className={`${classes} ${className}`} href={to} {...rest} />
		}
	}
	if (_.isNil(to)) {
		return <a className={`${classes}${className || ""}`} href="" {...rest} />
	}
	return <RouterLink className={`${classes}${className || ""}`} to={to} {...rest} />
}
