import React from "react"
import ReactAsyncSelect, { AsyncProps } from "react-select/async"
import ReactSelect, { Props, GroupBase } from "react-select"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Label } from "@app/components"

interface IAsyncSelectProps {
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	loading?: boolean
	name: string
}

export const AsyncSelect = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>,
>(
	props: IAsyncSelectProps & Props<Option, IsMulti, Group> & AsyncProps<Option, IsMulti, Group>,
) => {
	const { t } = useSession()
	const { disabled, loading, name, label, hint, error, ...rest } = props
	const errorMessage = error?.replace("$field", label || t("validation.this"))

	return (
		<>
			{_.isNil(label) ? null : <Label name={name}>{label}&#8203;</Label>}
			{loading ? (
				<ReactSelect
					isClearable={true}
					isDisabled={disabled}
					isLoading={true}
					placeholder=""
					className="react-select"
					classNamePrefix="rs"
					name={name}
					options={[]}
					onChange={_.noop}
					{...rest}
				/>
			) : (
				<ReactAsyncSelect
					isLoading={loading}
					isClearable={true}
					isDisabled={disabled}
					placeholder="Search..."
					className="react-select"
					classNamePrefix="rs"
					name={name}
					{...rest}
				/>
			)}
			{!_.isEmpty(errorMessage) ? (
				<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
						<path
							fillRule="evenodd"
							d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
							clipRule="evenodd"
						/>
					</svg>
				</div>
			) : null}
			{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
				<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203;</p>
			) : null}
			{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
				<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203;</p>
			) : null}
		</>
	)
}
