import _ from "lodash"

import type { TemplateField } from "@app/domain"

export type TemplateFieldParams = {
	label: string
	value: string
	options: string[]
}

export const newTemplateFieldParams = (templateField?: TemplateField): TemplateFieldParams => {
	return {
		label: _.get(templateField, "label", ""),
		value: _.get(templateField, "value", ""),
		options: _.get(templateField, "options", []),
	}
}
