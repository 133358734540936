import { rpc, Cache } from "@app/api"

import type { Result } from "@app/api"
import type { ScanLog, ScanEvent } from "@app/domain"

export type ScanLogParams = {
	rawData: string
	scanEvent: ScanEvent
}

export type SaveScanLogsParams = {
	workFacilityID: string
	scanLogs: ScanLogParams[]
	entityMap?: { [key: string]: string }
}

export const SaveScanLogs = async (
	params: SaveScanLogsParams,
): Promise<Result<SaveScanLogsResult>> => {
	const data = await rpc("SaveScanLogs", {
		...params,
	})
	Cache.scan(data)
	return data as Result<SaveScanLogsResult>
}

export type SaveScanLogsResult = {
	scanLogs: ScanLog[]
}
