import React from "react"
import clsx from "clsx"

interface IPageContainerProps {
	flex?: boolean
	padding?: boolean
	children?: React.ReactNode
}

export const PageContainer: React.FC<IPageContainerProps> = (props) => {
	const { flex = false, padding = true, children } = props

	return (
		<div
			id="pageContainer"
			className={clsx(
				"min-h-screen-searchbar",
				flex && "flex",
				padding && "pt-4 px-2 lg:p-6 lg:pb-0",
			)}
		>
			{children}
		</div>
	)
}
