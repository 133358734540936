import React from "react"
import { formatDistance, differenceInMilliseconds, parseJSON } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

import { useSession } from "@app/contexts"
import { browserTimezone, formatTimezone, getBrowserTimezoneOffset } from "@app/util"

const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE

interface IDateTimeProps {
	time: string
	dateOnly?: boolean
	prefix?: string
	explicit?: boolean
	timezone?: string
	hideTimezone?: boolean
	options?: { [key: string]: string }
}

export const DaysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

export const DateTime: React.FC<IDateTimeProps> = (props) => {
	const {
		dateOnly,
		prefix,
		time,
		explicit = false,
		timezone = browserTimezone,
		hideTimezone = false,
		options = {},
	} = props
	const [now, setNow] = React.useState(new Date())

	const { t } = useSession()

	const [at, setAt] = React.useState(utcToZonedTime(parseJSON(time), timezone))
	React.useEffect(() => {
		setAt(utcToZonedTime(parseJSON(time), timezone))
		setNow(new Date())
	}, [time, timezone])

	const displayTimezone =
		dateOnly || hideTimezone || getBrowserTimezoneOffset(timezone) === 0
			? ""
			: ` (${formatTimezone(timezone, true)})`

	const formattedDate = (date?: Date): string => {
		if (!date) {
			return ""
		}
		let year = ""
		if (date.getFullYear() !== now.getFullYear()) {
			year = ` ${date.getFullYear()}`
		}
		const month = t(`common.months.${months[date.getMonth()]}`).substring(0, 3)
		const dayOfWeek = t(`common.days.${DaysOfWeek[date.getDay()]}`).substring(0, 3)
		return `${dayOfWeek} ${date.getDate()}-${month}${year}` + (dateOnly ? displayTimezone : "")
	}

	React.useEffect(() => {
		if (isNaN(at.getTime()) || dateOnly) {
			return undefined
		}
		const diff = Math.abs(differenceInMilliseconds(now, at))

		let delay = 0
		if (diff < 5 * MINUTE) {
			delay = 5 * SECOND
		} else if (diff < HOUR) {
			delay = MINUTE
		}
		if (delay !== 0) {
			const timer = setTimeout(() => {
				setNow(new Date())
			}, delay)
			return () => {
				clearTimeout(timer)
			}
		}
		return undefined
	}, [now, at, dateOnly])

	if (isNaN(at.getTime())) {
		return <span>n/a</span>
	}

	if (dateOnly) {
		return <time>{formattedDate(at)}</time>
	}

	let distance = formatDistance(at, now, { addSuffix: true }).replace(/^about /, "")
	if (distance === "less than a minute ago") {
		distance = "just now"
	}
	if (explicit) {
		const time = at.toLocaleTimeString(undefined, options)
		const date = at.toLocaleDateString(undefined, options)

		return (
			<time title={`${date} (${distance})`}>
				{/* Sets a preferred line break */}
				<span className="inline-block">{formattedDate(at)}</span>{" "}
				<span className="inline-block">{time}</span>{" "}
				<span className="inline-block">{displayTimezone}</span>
			</time>
		)
	}
	if (prefix) {
		distance = `${prefix} ${distance}`
	}
	return <time title={at.toUTCString()}>{distance}</time>
}
