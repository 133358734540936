import React from "react"

import { Breadcrumbs, EntityTable, PageContainer, Title } from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType } from "@app/domain"
import _ from "lodash"

export const EntityListPage = (entityType: EntityType, buttons: JSX.Element = <></>): React.FC => {
	const EntityListing = () => {
		const { t } = useSession()
		const entityTranslation = _.camelCase(entityType)

		return (
			<PageContainer>
				<Title>{t(`${entityTranslation}.titles.list`)}</Title>

				<Breadcrumbs>{t(`${entityTranslation}.titles.list`)}</Breadcrumbs>

				<EntityTable fullHeight={true} entityType={entityType} buttons={buttons} />
			</PageContainer>
		)
	}
	return EntityListing
}
