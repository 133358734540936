import React from "react"

import { useSession } from "@app/contexts"
import { AlertLevel } from "@app/domain"
import { Icon } from "@app/components"

interface IHeaderDownloadButtonProps {
	url: string
	label: string
}

export const HeaderDownloadButton: React.FC<IHeaderDownloadButtonProps> = (props) => {
	const { url, label } = props
	const { t, addNotification } = useSession()
	const [loading, setLoading] = React.useState(false)

	React.useEffect(() => {
		if (!loading) {
			return
		}
		const fetchDownload = async () => {
			const resp = await fetch(url)
			if (resp.ok) {
				window?.open(url, "_blank")?.focus()
				setLoading(false)
				return
			}
			if (resp.status !== 408) {
				addNotification({
					alertLevel: AlertLevel.Warn,
					title: t("common.errors.download"),
					subtitle: t("common.errors.temporary"),
				})
				console.error(resp)
				setLoading(false)
				return
			}
			window.setTimeout(fetchDownload, 1000) // Wait 1s before reattempting.
		}
		fetchDownload()
	}, [loading])

	return (
		<span key="download" onClick={() => setLoading(true)}>
			<Icon className="mr-2" name={loading ? "Cog" : "Print"} spin={loading} fixedWidth />
			{label}
		</span>
	)
}
