import React from "react"
import _ from "lodash"

import { SaveContact, SaveContactParams, ContactableParams, newSaveContactParams } from "@app/api"
import { JobSelect, CustomerSelect, ReleaseSelect, Select } from "@app/components"
import { useSession } from "@app/contexts"
import {
	newContact,
	ContactUtils,
	Customer,
	Job,
	Release,
	Contact,
	EntityType,
	AlertLevel,
} from "@app/domain"
import { ContactForm } from "@app/forms"
import {
	Button,
	PageContainer,
	Link,
	BreadcrumbDelimiter,
	Breadcrumbs,
	Title,
} from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useQuery, useRelation, useRedirect } from "@app/hooks"

import En from "@app/translations/en.json"

import type { OptionType } from "@app/components"

export const ContactNewPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const query = useQuery()

	const [contact] = React.useState<Contact>(newContact())
	const [contactable, setContactable] = React.useState<ContactableParams | undefined>()
	const [labels, setLabels] = React.useState<string[]>([])
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveContactParams>(newSaveContactParams(contact))

	const [customer, customerLoading] = useRelation<Customer>(
		EntityType.Customer,
		toUUID(query.customer),
	)
	const [job, jobLoading] = useRelation<Job>(EntityType.Job, toUUID(query.job))
	const [release, releaseLoading] = useRelation<Release>(EntityType.Release, toUUID(query.release))

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("contact.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	React.useEffect(() => {
		if (customer) {
			setContactable({
				contactableID: customer.id,
				contactableType: "Customer",
			})
		} else if (job) {
			setContactable({
				contactableID: job.id,
				contactableType: "Job",
			})
		} else if (release) {
			setContactable({
				contactableID: release.id,
				contactableType: "Release",
			})
		}
	}, [customerLoading, jobLoading, releaseLoading])

	const labelOptions = _.map(En.contactable.labelOptions, (_val, key) =>
		t(`contactable.labelOptions.${key}`),
	)
		.sort()
		.map((v) => {
			return { value: v, label: v }
		})

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		setErrors({})
		try {
			const saveParams = { ...params }
			if (!_.isEmpty(contactable)) {
				saveParams.contactables = [{ ...contactable, labels }]
			}
			const resp = await SaveContact(saveParams)
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.contacts[0]")
				setRedirect(urlTo("contacts", saved), {
					alertLevel: AlertLevel.Success,
					title: t("contact.notifications.success.created", { name: ContactUtils.name(saved) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	const contactableHandler = (key: string): ((v: OptionType[]) => void) => {
		return (v: OptionType[]) => {
			setContactable((prev) => {
				const next = _.assign({}, prev)
				const id = _.get(v, "[0].value")
				if (id) {
					next.contactableID = id
					next.contactableType = key
				} else {
					return undefined
				}
				return next
			})
		}
	}

	const labelHandler = (v: OptionType[]) => {
		setLabels(_.chain(v).map("value").compact().value())
	}

	if (redirect) {
		return redirect()
	}

	if (customerLoading || jobLoading || releaseLoading) {
		return null
	}

	const hasParent = _.some([customer, job, release], (v) => !_.isEmpty(v))

	return (
		<PageContainer>
			<Title>{t("contact.titles.new")}</Title>

			<Breadcrumbs>
				<Link to="/contacts">{t("contact.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-md">
				{customer ? (
					<CustomerSelect
						defaultValue={_.get(customer, "id")}
						disabled={busy}
						error={_.get(errors, "customerID")}
						label={t("entity.customer")}
						name="customerID"
						onChange={contactableHandler("Customer")}
					/>
				) : null}

				{job ? (
					<JobSelect
						defaultValue={_.get(job, "id")}
						disabled={busy}
						error={_.get(errors, "jobID")}
						label={t("entity.job")}
						name="jobID"
						onChange={contactableHandler("Job")}
					/>
				) : null}

				{release ? (
					<ReleaseSelect
						defaultValue={_.get(release, "id")}
						disabled={busy}
						error={_.get(errors, "releaseID")}
						label={t("entity.release")}
						name="releaseID"
						onChange={contactableHandler("Release")}
					/>
				) : null}

				{hasParent ? (
					<Select
						disabled={busy}
						isMulti
						error={_.get(errors, "labels")}
						isSearchable={true}
						label={t("contactable.labels.labels")}
						name="labels"
						onChange={labelHandler}
						options={labelOptions}
					/>
				) : null}

				<form onSubmit={submitHandler} noValidate>
					<ContactForm
						defaultParams={newSaveContactParams(contact)}
						disabled={busy}
						errors={errors}
						onChange={(nextParams) => {
							setParams((prev) => _.assign({}, prev, nextParams))
						}}
					/>
					<div className="max-w-md mt-6">
						<Button errors={errors} label={t("contact.buttons.save")} busy={busy} type="submit" />
					</div>
				</form>
			</div>
		</PageContainer>
	)
}
