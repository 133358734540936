import React from "react"

import { Entity } from "@app/domain"

export type EntityContext = {
	entity: { [key: string]: Entity }
	setEntity: (id: string | undefined, entity: Entity) => void
}

export const entityContext = React.createContext<EntityContext>({
	entity: {},
	setEntity: () => null,
})

export const useEntity = (
	id: string | null | undefined,
): {
	entity: Entity
	setEntity?: (id: string | undefined, entity: Entity) => void
} => {
	const { entity, setEntity } = React.useContext(entityContext)
	return { entity: entity[id ?? ""], setEntity }
}
