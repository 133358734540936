import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { WorkCell, EntityType } from "@app/domain"
import { GetWorkCells } from "@app/api"
import { useRelation } from "@app/hooks"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface IWorkCellSelectProps {
	autoFocus?: boolean
	defaultValue?: string
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	workFacilityID?: string
	onChange: (v: OptionType[]) => void
}

export const WorkCellSelect: React.FC<IWorkCellSelectProps> = (props) => {
	const { handleError } = useSession()
	const {
		autoFocus,
		defaultValue = "",
		disabled,
		error,
		hint,
		label,
		name,
		onChange,
		workFacilityID,
	} = props

	const [workCellID, setWorkCellID] = React.useState<string>(defaultValue)
	const [reload, setReload] = React.useState<boolean>(false)
	const [workCell, loadingWorkCell] = useRelation<WorkCell>(EntityType.WorkCell, defaultValue)

	React.useEffect(() => {
		setReload(true)
		_.defer(() => {
			setReload(false)
		})
	}, [workFacilityID])

	const getWorkCells = (inputValue: string) => {
		const includeIDs = _.compact([workCellID])
		return new Promise<OptionType[]>((resolve) => {
			GetWorkCells({
				includeIDs,
				workFacilityID,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const workCells = _.get(resp, "result.workCells", []).map((c: WorkCell) => {
							return {
								label: c.name,
								value: c.id,
							}
						})
						resolve(workCells as OptionType[])
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	const defaultOption = {
		label: workCell?.name ?? defaultValue,
		value: workCellID,
	}

	if (reload || loadingWorkCell) {
		return <div className="h-20 w-full" />
	}
	return (
		<AsyncSelect
			autoFocus={autoFocus}
			cacheOptions={true}
			defaultOptions={true}
			defaultValue={_.isEmpty(defaultValue) ? null : defaultOption}
			disabled={disabled}
			hint={hint}
			error={error}
			isSearchable={true}
			label={label}
			loadOptions={getWorkCells}
			menuPlacement="auto"
			name={name}
			onChange={(v) => {
				const value = _.get(v, "value", "")
				setWorkCellID(value)
				onChange(_.compact([v]))
			}}
		/>
	)
}
