import React from "react"

import { Await, PageContainer, SettingDetails } from "@app/components"
import { SettingHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, Setting } from "@app/domain"

export const SettingShowPage: React.FC = () => {
	const [setting, settingLoading] = useEntity<Setting>(EntityType.Setting)

	return (
		<PageContainer>
			<SettingHeader setting={setting} view="show" />
			<Await
				loading={settingLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<SettingDetails setting={setting} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
