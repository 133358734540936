import React from "react"
import _ from "lodash"
import { Badge, Select, Button, Input, Icon, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { PhoneNumberParams, newPhoneNumberParams } from "@app/api"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IPhoneNumberFormProps {
	busy?: boolean
	defaultOpen?: boolean
	defaultParams: PhoneNumberParams
	disabled?: boolean
	errors: { [key: string]: string }
	onChange: (params?: PhoneNumberParams) => void
}

const PhoneNumberForm: React.FC<IPhoneNumberFormProps> = (props) => {
	const {
		busy = false,
		defaultOpen = false,
		defaultParams,
		disabled = false,
		errors,
		onChange,
	} = props

	const { t } = useSession()
	const { params, inputHandler, selectHandler } = useFormHelpers<PhoneNumberParams>(defaultParams)
	const [open, setOpen] = React.useState<boolean>(defaultOpen)

	const removeHandler = () => {
		onChange(undefined)
	}

	React.useEffect(() => {
		if (!open) {
			if (_.every([params.phoneNumber, params.labels], _.isEmpty)) {
				removeHandler()
			}
		}
	}, [open])

	const labels = _.map(En.phoneNumber.labelOptions, (val) => {
		return { name: val, code: val }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	const defaultLabelsOption = _.map(_.get(params, "labels", []), (label) => {
		return { value: label, label: label }
	})
	const labelOptions = labels.map((c) => {
		return { value: c.code, label: c.name }
	})

	React.useEffect(() => {
		onChange(params)
	}, [params])

	React.useEffect(() => {
		if (!_.isEmpty(errors) && !open) {
			setOpen(true)
		}
	}, [errors])

	return (
		<div className="border-t border-gray-300 py-5 mb-2">
			<div className={`p-4 bg-gray-200 border border-gray-300 ${open ? "" : "hidden"}`}>
				<Input
					autoComplete="off"
					defaultValue={params.phoneNumber}
					disabled={disabled || busy}
					error={_.get(errors, "phoneNumber")}
					label={t("phoneNumber.labels.phoneNumber")}
					name="phoneNumber"
					onChange={inputHandler("phoneNumber")}
					placeholder={t("phoneNumber.placeholders.phoneNumber")}
					type="text"
				/>
				<Select
					defaultValue={defaultLabelsOption}
					isMulti
					isSearchable={true}
					label={t("phoneNumber.labels.labels")}
					name="labels"
					onChange={selectHandler("labels", true)}
					options={labelOptions}
					disabled={disabled || busy}
				/>
				<div className="text-right">
					<Button
						destroy
						disabled={disabled}
						busy={busy}
						label={t("phoneNumber.buttons.remove")}
						onClick={removeHandler}
						small
						type="button"
					/>
				</div>
			</div>

			<div className={`flex justify-between ${open ? "hidden" : ""}`}>
				<div>
					<Property phoneNumber={params.phoneNumber} />
				</div>
				<div className="text-right">
					{_.map(_.isEmpty(params.labels) ? [] : params.labels, (label, i) => {
						return (
							<Badge key={i} className="ml-1">
								{label}
							</Badge>
						)
					})}
				</div>
			</div>

			<button
				type="button"
				className="w-full mt-4 bg-gray-300 text-center text-gray-500 hover:text-yellow-700 hover:bg-yellow-300"
				onClick={() => setOpen(!open)}
			>
				<Icon name={open ? "ChevronUp" : "ChevronDown"} />
			</button>
		</div>
	)
}

interface IPhoneNumbersFormProps {
	busy?: boolean
	defaultParams: PhoneNumberParams[]
	disabled?: boolean
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (params: PhoneNumberParams[]) => void
}

export const PhoneNumbersForm: React.FC<IPhoneNumbersFormProps> = (props) => {
	const { t } = useSession()

	const { defaultParams, onChange, disabled = false, busy = false, errors, errorsKey } = props
	const [params, setParams] = React.useState<PhoneNumberParams[]>(defaultParams)
	const [keys, setKeys] = React.useState<string[]>(_.map(params, () => _.uniqueId()))

	const changeHandler = (index: number): ((nextParams?: PhoneNumberParams) => void) => {
		return (nextParams?: PhoneNumberParams): void => {
			if (_.isNil(nextParams)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				setParams((params) => {
					const result = [...params]
					result.splice(index, 1)
					return result
				})
			} else {
				setParams((params) => {
					const result = [...params]
					result[index] = nextParams
					return result
				})
			}
		}
	}

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const addHandler = (): void => {
		setKeys((keys) => {
			return [_.uniqueId()].concat([...keys])
		})
		setParams((params) => {
			return [newPhoneNumberParams()].concat([...params])
		})
	}

	return (
		<>
			<h2 className="text-md mb-4 text-gray-700">
				{t("phoneNumber.header")} <Badge quantity>{_.size(params)}</Badge>
				<Button
					disabled={disabled}
					busy={busy}
					className="float-right"
					type="button"
					label={t("phoneNumber.buttons.add")}
					onClick={addHandler}
					small
					add
				/>
			</h2>

			{_.map(params, (p: PhoneNumberParams, i: number) => (
				<PhoneNumberForm
					key={keys[i]}
					disabled={disabled}
					busy={busy}
					defaultParams={p}
					defaultOpen={_.get(p, "phoneNumber", "") === ""}
					errors={_.reduce(
						errors,
						(acc, err: string, k: string) => {
							const prefix = `${errorsKey}[${i}].`
							if (_.startsWith(k, prefix)) {
								acc[k.slice(_.size(prefix))] = err
							}
							return acc
						},
						{},
					)}
					onChange={changeHandler(i)}
				/>
			))}
		</>
	)
}
