import _ from "lodash"

import { rpc, Batch, ReceivedResult, DocumentableParams, compactIDs } from "@app/api"
import { Document, DocumentType, Entity, Pagination, TagColor } from "@app/domain"

import type { Result, AttributeParams, TemplateFieldParams } from "@app/api"

export type GenerateBillOfLadingFromShipmentParams = {
	shipmentID: string
}

export const GenerateBillOfLadingFromShipment = async (
	params: GenerateBillOfLadingFromShipmentParams,
): Promise<Result<SaveDocumentResult>> => {
	const data = await rpc("GenerateBillOfLadingFromShipment", params)
	return ReceivedResult<SaveDocumentResult>(data)
}

export type BillOfLadingLineItemParams = {
	color: TagColor
	part: string
	description: string
	quantity: string
	weight?: number
}

export const newBillOfLadingLineItemParams = (): BillOfLadingLineItemParams => {
	return {
		color: TagColor.None,
		description: "",
		part: "",
		quantity: "",
		weight: undefined,
	}
}

export type SaveDocumentParams = {
	id?: string

	documentables: DocumentableParams[]

	attributes: AttributeParams[]
	documentType: DocumentType
	name: string
	templateFields: TemplateFieldParams[]

	billOfLading?: {
		metric: boolean
		lineItems: BillOfLadingLineItemParams[]
	}
}

export const newSaveDocumentParams = (document?: Document): SaveDocumentParams => {
	return {
		id: _.get(document, "id", ""),

		name: _.get(document, "name", ""),
		documentType: _.get(document, "documentType", DocumentType.File),

		attributes: _.get(document, "attributes", []),
		documentables: _.get(document, "documentables", []),
		templateFields: _.get(document, "templateFields", []),

		billOfLading: _.get(document, "billOfLading", undefined),
	}
}

export type SaveDocumentResult = {
	documents: Document[]
}

export const SaveDocument = async (
	params: SaveDocumentParams,
): Promise<Result<SaveDocumentResult>> => {
	const data = await rpc("SaveDocument", params, { minDuration: 300 })
	return ReceivedResult<SaveDocumentResult>(data)
}

export type GetDocumentsParams = {
	ids?: string[]
	includeIDs?: string[]
	excludeIDs?: string[]

	customerID?: string
	jobID?: string
	shipmentID?: string

	query?: string
	withoutCount?: boolean
	withoutDocumentables?: boolean

	limit?: number
	offset?: number
}

export type GetDocumentsResult = {
	documents: Document[]
	pagination?: Pagination
}

export const GetDocuments = async (
	params: GetDocumentsParams,
): Promise<Result<GetDocumentsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetDocuments", "documents", id).then((document?: Entity) => {
			return {
				ok: true,
				result: { documents: _.compact([document as Document]) },
			} as Result<GetDocumentsResult>
		}) as Promise<Result<GetDocumentsResult>>
	}
	const data = await rpc("GetDocuments", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetDocumentsResult>(data)
}

export type GetDocumentByIDParams = {
	id: string
}

export type GetDocumentByIDResult = {
	documents: Document[]
}

export const GetDocumentByID = async (
	params: GetDocumentByIDParams,
): Promise<Result<GetDocumentByIDResult>> => {
	const data = await rpc("GetDocumentByID", {
		...params,
	})
	return ReceivedResult<GetDocumentsResult>(data)
}

export type GetDocumentImagesResult = {
	imageData: string[]
}

export const GetDocumentImages = async (
	documentID: string,
): Promise<Result<GetDocumentImagesResult>> => {
	const data = await rpc("GetDocumentImages", { id: documentID })
	return data as Result<GetDocumentImagesResult>
}

export type GetDocumentPreviewResult = {
	imageData: string[]
}

export const GetDocumentPreview = async (
	params: SaveDocumentParams,
): Promise<Result<GetDocumentPreviewResult>> => {
	const data = await rpc("GetDocumentPreview", { ...params })
	return data as Result<GetDocumentPreviewResult>
}
