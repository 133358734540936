import _ from "lodash"

import type { GridRequest, CageRequest } from "@app/grpc/visualization"

export enum AssemblyType {
	W1Cage = "W1-Cage",
	W2Grid = "W2-Grid",
}

// Mirrors domain/assembly.go AssemblyPendingFileType
export enum AssemblyPendingFileType {
	RobotProgram = "AssemblyRobotProgram",
}

export enum ShapeDiverInputType {
	Default = "Default",
	Hidden = "Hidden",
	Length = "Length",
	Select = "Select",
	List = "List",
	LengthList = "Length-List",
	IntList = "Int-List",
	FloatList = "Float-List",
	StringList = "String-List",
	QuantityAtSpacingList = "QuantityAtSpacing-List",
}

export type ShapeDiverProps = {
	shapeDiverData: ShapeDiverData
	shapeDiverParams: {
		[name: string]: string | number | boolean
	}
	shapeDiverVersion: string
}

export type ShapeDiverParam = {
	decimalplaces?: number
	defval: string | number | boolean
	hidden: boolean
	id: string
	max?: number
	min?: number
	name: string
	order: number
	tooltip: string
	type: string
	value: string | boolean | number
}

export type ShapeDiverUpdate = {
	_name: string
	_param: ShapeDiverParam
	_was: string | boolean | number
	id: string
	value: string | boolean | number
}

export type ShapeDiverInput = {
	args: string
	cleanedName: string
	defaultValue: string | boolean | number
	group: string
	id: string
	inputType: ShapeDiverInputType
	label: string
	max: number | undefined
	min: number | undefined
	name: string
	range: number[][] | undefined
	selectOptions: { label: string; value: string }[]
	defaultOptionValue: { label: string; value: string }
	type: string
	unit: string
	value: string | boolean | number
}

export type ShapeDiverData = {
	groups: string[]
	inputsByGroup: { [name: string]: ShapeDiverInput[] }
}

export type Assembly = {
	id: string
	customerID: string
	jobID: string
	stpFileID?: string
	stpFileURL?: string

	assemblyType: AssemblyType
	configuratorParams?: {
		"W1-Cage"?: CageRequest
		"W2-Grid"?: GridRequest
	}
	configuratorVersion?: string
	metric: boolean
	name: string
	weight?: number
	pendingFiles?: { [key in AssemblyPendingFileType]?: boolean }
	referenceID: string
	shapeDiverData?: ShapeDiverData
	shapeDiverExports?: { [name: string]: string }
	shapeDiverParams?: { [name: string]: string | number | boolean }
	shapeDiverVersion?: string
	errors: { [key: string]: string[] }

	created: string
	updated: string
}

export const newAssembly = (opts?: {
	[key: string]: string | boolean | number | object
}): Assembly => {
	return _.assign(
		{
			id: "",
			customerID: "",
			jobID: "",

			assemblyType: AssemblyType.W2Grid,
			configuratorParams: {},
			configuratorVersion: "",
			pendingFiles: {},
			shapeDiverExports: {},
			shapeDiverParams: {},
			shapeDiverVersion: "",
			shapeDiverData: {
				groups: [],
				inputsByGroup: {},
			},
			errors: {},

			metric: false,
			weight: 0,
			name: "",
			referenceID: "",

			created: "",
			updated: "",
		},
		opts,
	)
}

export const assemblyPendingFiles = (
	assembly: Assembly | undefined,
	fileTypes: AssemblyPendingFileType[] = [],
): boolean => {
	if (fileTypes.length === 0) {
		return (assembly?.pendingFiles && _.valuesIn(assembly?.pendingFiles).some((v) => v)) ?? false
	} else {
		return (
			(assembly?.pendingFiles &&
				_.entries(assembly?.pendingFiles)?.some(
					([key, value]) => value && fileTypes.includes(key as AssemblyPendingFileType),
				)) ??
			false
		)
	}
}
