import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import {
	Badge,
	EntityBreadcrumbs,
	DateTime,
	Icon,
	Tabs,
	Title,
	HeaderDownloadButton,
} from "@app/components"
import { urlTo } from "@app/util"
import { Document, DocumentUtils, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IDocumentHeaderProps {
	document?: Document
	section?: string
	view: string
}

export const DocumentHeader: React.FC<IDocumentHeaderProps> = (props) => {
	const { document, section = "", view } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: "...", created: "", updated: "" },
		document,
	)

	const { t, tenantID } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Document, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("documents/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("documents", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("documents/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("documents/edit", id), active: view === "edit" },
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<HeaderDownloadButton
							key="download"
							url={DocumentUtils.pdfURL(document, tenantID)}
							label={t("document.buttons.download")}
						/>
					),
					value: "download",
				},

				{
					label: (
						<span key="delete">
							<Icon className="mr-2" fixedWidth name="Trash" />
							{t("document.buttons.delete")}
						</span>
					),
					value: "delete",
					onOptionClick: () => trashHandler(id, { name }),
				},
			],
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("document.titles.new")
	} else if (view === "edit") {
		title = t("document.titles.edit", { name })
	} else {
		title = t("document.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={document} entityType={EntityType.Document} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
