import React from "react"

import { useSession } from "@app/contexts"
import { PageContainer, Title, Breadcrumbs } from "@app/components"

export const InventoryListPage: React.FC = () => {
	const { t } = useSession()

	return (
		<PageContainer>
			<Title>{t("inventory.titles.list")}</Title>
			<Breadcrumbs>{t("inventory.titles.list")}</Breadcrumbs>
		</PageContainer>
	)
}
