import _ from "lodash"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkTrackingState, Entity, Pagination, Job, Customer, EntityType } from "@app/domain"

export type GetWorkTrackingStatesParams = {
	ids?: string[]
	includeIDs?: string[]
	workActionIDs?: string[]
	workDeviceFinishIDs?: string[]
	workDeviceStartIDs?: string[]
	assemblyIDs?: string[]
	customerIDs?: string[]
	jobIDs?: string[]
	releaseIDs?: string[]
	releaseItemIDs?: string[]
	releaseUnitIDs?: string[]
	shipmentIDs?: string[]
	tagIDs?: string[]
	workCellIDs?: string[]
	workCellServerIDs?: string[]
	workerIDs?: string[]
	workFacilityIDs?: string[]
	workResourceIDs?: string[]

	actorIDs?: string[]
	actorEntityType?: EntityType
	locationIDs?: string[]
	locationEntityType?: EntityType
	subjectIDs?: string[]
	subjectEntityType?: EntityType

	timeBegin?: Date
	timeEnd?: Date

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkTrackingStatesResult = {
	workTrackingStates: WorkTrackingState[]
	jobs?: Job[]
	customers?: Customer[]
	pagination?: Pagination
}

export const GetWorkTrackingStates = async (
	params: GetWorkTrackingStatesParams,
): Promise<Result<GetWorkTrackingStatesResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkTrackingStates", "workTrackingStates", id).then(
			(workTrackingState?: Entity) => {
				return {
					ok: true,
					result: { workTrackingStates: _.compact([workTrackingState as WorkTrackingState]) },
				} as Result<GetWorkTrackingStatesResult>
			},
		) as Promise<Result<GetWorkTrackingStatesResult>>
	}
	const data = await rpc("GetWorkTrackingStates", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkTrackingStatesResult>(data)
}
