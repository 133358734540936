import React from "react"
import clsx from "clsx"

import { Icon } from "@app/components"

interface IFieldsetProps {
	defaultOpen?: boolean
	children?: React.ReactNode
	className?: string
	label: string
	small?: boolean
}

export const Fieldset: React.FC<IFieldsetProps> = (props) => {
	const { defaultOpen = true, children, className = "", label, small = false } = props

	const [open, setOpen] = React.useState<boolean>(defaultOpen)

	return (
		<>
			<div
				className={clsx(
					small && "items-center",
					"group flex flex-row border-b border-gray-400 cursor-pointer",
					className,
				)}
				onClick={() => setOpen(!open)}
			>
				<h2 className={clsx(small ? " !text-base" : "text-lg", "flex-1 text-lg font-medium")}>
					{label}
				</h2>
				<span
					className={clsx(
						small ? "w-5 h-5 text-xs " : "w-7 h-7",
						"flex-initial inline-flex items-center justify-center bg-yellow-500 rounded-full text-black text-center group-hover:bg-yellow-300",
					)}
				>
					<Icon name={open ? "ChevronDown" : "ChevronUp"} />
				</span>
			</div>
			<div className={open ? "" : "overflow-hidden h-0"}>{children}</div>
		</>
	)
}
