/* eslint-disable */
/* This is a generated file. DO NOT EDIT. Edit grpc-ts-mods.sh instead. */
/* @ts-nocheck */
import type { CallContext, CallOptions } from "nice-grpc-common";
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "";

export enum InputType {
  LENGTH = 0,
  INTEGER = 1,
  DOUBLE = 2,
  STRING = 3,
  BOOL = 4,
  FILE = 5,
  QUANTITY_AT_SPACING = 6,
  UNRECOGNIZED = -1,
}

export enum Workspaces {
  DESIGN = 0,
  FABRICATION = 1,
  SIMULATION = 2,
  UNRECOGNIZED = -1,
}

export interface FormRequest {
}

export interface Struct {
  /** Unordered map of dynamically typed values. */
  fields: { [key: string]: Value };
}

export interface Struct_FieldsEntry {
  key: string;
  value: Value | undefined;
}

export interface Value {
  number?: number | undefined;
  string?: string | undefined;
  bool?: boolean | undefined;
  struct?: Struct | undefined;
  list?: Values | undefined;
  bytes?: Uint8Array | undefined;
}

/** `Values` is a wrapper around a repeated field of dynamically typed values. */
export interface Values {
  values: Value[];
}

export interface Dependency {
  /** snake_case parameter name from visualization.proto, eg. rebar_a_asa_type. */
  param: string;
  /** When the parameter matches a value, the input is enabled (True). */
  values_enable: boolean;
  values: Value[];
}

export interface SelectOption {
  label: string;
  value: Value | undefined;
}

export interface Options {
  disabled: boolean;
  optional: boolean;
  list: boolean;
  select_options: SelectOption[];
  min: number;
  max: number;
  list_length_min: number;
  list_length_max: number;
  /** hidden */
  dependencies: Dependency[];
  /** disabled, not hidden */
  disable_dependencies: Dependency[];
  alt: string;
  file_types: string;
  textarea: boolean;
}

export interface FormInput {
  name: string;
  label: string;
  description: string;
  type: InputType;
  placeholder: string;
  default: Value | undefined;
  options: Options | undefined;
}

export interface FormGroup {
  label: string;
  description: string;
  collapsed: boolean;
  inputs: FormInput[];
  workspaces: Workspaces[];
  dependencies: Dependency[];
}

export interface FormResponse {
  groups: FormGroup[];
  metric: boolean;
  version: string;
}

function createBaseFormRequest(): FormRequest {
  return {};
}

export const FormRequest = {
  encode(_: FormRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FormRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFormRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<FormRequest>): FormRequest {
    return FormRequest.fromPartial(base ?? {});
  },

  fromPartial(_: DeepPartial<FormRequest>): FormRequest {
    const message = createBaseFormRequest();
    return message;
  },
};

function createBaseStruct(): Struct {
  return { fields: {} };
}

export const Struct = {
  encode(message: Struct, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.fields).forEach(([key, value]) => {
      Struct_FieldsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Struct {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStruct();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          const entry1 = Struct_FieldsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.fields[entry1.key] = entry1.value;
          }
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Struct>): Struct {
    return Struct.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Struct>): Struct {
    const message = createBaseStruct();
    message.fields = Object.entries(object.fields ?? {}).reduce<{ [key: string]: Value }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = Value.fromPartial(value);
      }
      return acc;
    }, {});
    return message;
  },
};

function createBaseStruct_FieldsEntry(): Struct_FieldsEntry {
  return { key: "", value: undefined };
}

export const Struct_FieldsEntry = {
  encode(message: Struct_FieldsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Struct_FieldsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseStruct_FieldsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Struct_FieldsEntry>): Struct_FieldsEntry {
    return Struct_FieldsEntry.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Struct_FieldsEntry>): Struct_FieldsEntry {
    const message = createBaseStruct_FieldsEntry();
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseValue(): Value {
  return {
    number: undefined,
    string: undefined,
    bool: undefined,
    struct: undefined,
    list: undefined,
    bytes: undefined,
  };
}

export const Value = {
  encode(message: Value, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== undefined) {
      writer.uint32(9).double(message.number);
    }
    if (message.string !== undefined) {
      writer.uint32(18).string(message.string);
    }
    if (message.bool !== undefined) {
      writer.uint32(24).bool(message.bool);
    }
    if (message.struct !== undefined) {
      Struct.encode(message.struct, writer.uint32(34).fork()).ldelim();
    }
    if (message.list !== undefined) {
      Values.encode(message.list, writer.uint32(42).fork()).ldelim();
    }
    if (message.bytes !== undefined) {
      writer.uint32(50).bytes(message.bytes);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Value {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 9) {
            break;
          }

          message.number = reader.double();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.string = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.bool = reader.bool();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.struct = Struct.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag != 42) {
            break;
          }

          message.list = Values.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag != 50) {
            break;
          }

          message.bytes = reader.bytes();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Value>): Value {
    return Value.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Value>): Value {
    const message = createBaseValue();
    message.number = object.number ?? undefined;
    message.string = object.string ?? undefined;
    message.bool = object.bool ?? undefined;
    message.struct = (object.struct !== undefined && object.struct !== null)
      ? Struct.fromPartial(object.struct)
      : undefined;
    message.list = (object.list !== undefined && object.list !== null) ? Values.fromPartial(object.list) : undefined;
    message.bytes = object.bytes ?? undefined;
    return message;
  },
};

function createBaseValues(): Values {
  return { values: [] };
}

export const Values = {
  encode(message: Values, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.values) {
      Value.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Values {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseValues();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.values.push(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Values>): Values {
    return Values.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Values>): Values {
    const message = createBaseValues();
    message.values = object.values?.map((e) => Value.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDependency(): Dependency {
  return { param: "", values_enable: false, values: [] };
}

export const Dependency = {
  encode(message: Dependency, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.param !== "") {
      writer.uint32(10).string(message.param);
    }
    if (message.values_enable === true) {
      writer.uint32(16).bool(message.values_enable);
    }
    for (const v of message.values) {
      Value.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Dependency {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDependency();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.param = reader.string();
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.values_enable = reader.bool();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.values.push(Value.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Dependency>): Dependency {
    return Dependency.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Dependency>): Dependency {
    const message = createBaseDependency();
    message.param = object.param ?? "";
    message.values_enable = object.values_enable ?? false;
    message.values = object.values?.map((e) => Value.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSelectOption(): SelectOption {
  return { label: "", value: undefined };
}

export const SelectOption = {
  encode(message: SelectOption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    if (message.value !== undefined) {
      Value.encode(message.value, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SelectOption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSelectOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.value = Value.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<SelectOption>): SelectOption {
    return SelectOption.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<SelectOption>): SelectOption {
    const message = createBaseSelectOption();
    message.label = object.label ?? "";
    message.value = (object.value !== undefined && object.value !== null) ? Value.fromPartial(object.value) : undefined;
    return message;
  },
};

function createBaseOptions(): Options {
  return {
    disabled: false,
    optional: false,
    list: false,
    select_options: [],
    min: 0,
    max: 0,
    list_length_min: 0,
    list_length_max: 0,
    dependencies: [],
    disable_dependencies: [],
    alt: "",
    file_types: "",
    textarea: false,
  };
}

export const Options = {
  encode(message: Options, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.disabled === true) {
      writer.uint32(8).bool(message.disabled);
    }
    if (message.optional === true) {
      writer.uint32(16).bool(message.optional);
    }
    if (message.list === true) {
      writer.uint32(24).bool(message.list);
    }
    for (const v of message.select_options) {
      SelectOption.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    if (message.min !== 0) {
      writer.uint32(41).double(message.min);
    }
    if (message.max !== 0) {
      writer.uint32(49).double(message.max);
    }
    if (message.list_length_min !== 0) {
      writer.uint32(57).double(message.list_length_min);
    }
    if (message.list_length_max !== 0) {
      writer.uint32(65).double(message.list_length_max);
    }
    for (const v of message.dependencies) {
      Dependency.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    for (const v of message.disable_dependencies) {
      Dependency.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.alt !== "") {
      writer.uint32(82).string(message.alt);
    }
    if (message.file_types !== "") {
      writer.uint32(90).string(message.file_types);
    }
    if (message.textarea === true) {
      writer.uint32(96).bool(message.textarea);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Options {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOptions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 8) {
            break;
          }

          message.disabled = reader.bool();
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.optional = reader.bool();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.list = reader.bool();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.select_options.push(SelectOption.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag != 41) {
            break;
          }

          message.min = reader.double();
          continue;
        case 6:
          if (tag != 49) {
            break;
          }

          message.max = reader.double();
          continue;
        case 7:
          if (tag != 57) {
            break;
          }

          message.list_length_min = reader.double();
          continue;
        case 8:
          if (tag != 65) {
            break;
          }

          message.list_length_max = reader.double();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.dependencies.push(Dependency.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag != 106) {
            break;
          }

          message.disable_dependencies.push(Dependency.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.alt = reader.string();
          continue;
        case 11:
          if (tag != 90) {
            break;
          }

          message.file_types = reader.string();
          continue;
        case 12:
          if (tag != 96) {
            break;
          }

          message.textarea = reader.bool();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<Options>): Options {
    return Options.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<Options>): Options {
    const message = createBaseOptions();
    message.disabled = object.disabled ?? false;
    message.optional = object.optional ?? false;
    message.list = object.list ?? false;
    message.select_options = object.select_options?.map((e) => SelectOption.fromPartial(e)) || [];
    message.min = object.min ?? 0;
    message.max = object.max ?? 0;
    message.list_length_min = object.list_length_min ?? 0;
    message.list_length_max = object.list_length_max ?? 0;
    message.dependencies = object.dependencies?.map((e) => Dependency.fromPartial(e)) || [];
    message.disable_dependencies = object.disable_dependencies?.map((e) => Dependency.fromPartial(e)) || [];
    message.alt = object.alt ?? "";
    message.file_types = object.file_types ?? "";
    message.textarea = object.textarea ?? false;
    return message;
  },
};

function createBaseFormInput(): FormInput {
  return { name: "", label: "", description: "", type: 0, placeholder: "", default: undefined, options: undefined };
}

export const FormInput = {
  encode(message: FormInput, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.label !== "") {
      writer.uint32(18).string(message.label);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.type !== 0) {
      writer.uint32(32).int32(message.type);
    }
    if (message.placeholder !== "") {
      writer.uint32(66).string(message.placeholder);
    }
    if (message.default !== undefined) {
      Value.encode(message.default, writer.uint32(74).fork()).ldelim();
    }
    if (message.options !== undefined) {
      Options.encode(message.options, writer.uint32(82).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FormInput {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFormInput();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.label = reader.string();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag != 32) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 8:
          if (tag != 66) {
            break;
          }

          message.placeholder = reader.string();
          continue;
        case 9:
          if (tag != 74) {
            break;
          }

          message.default = Value.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag != 82) {
            break;
          }

          message.options = Options.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<FormInput>): FormInput {
    return FormInput.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FormInput>): FormInput {
    const message = createBaseFormInput();
    message.name = object.name ?? "";
    message.label = object.label ?? "";
    message.description = object.description ?? "";
    message.type = object.type ?? 0;
    message.placeholder = object.placeholder ?? "";
    message.default = (object.default !== undefined && object.default !== null)
      ? Value.fromPartial(object.default)
      : undefined;
    message.options = (object.options !== undefined && object.options !== null)
      ? Options.fromPartial(object.options)
      : undefined;
    return message;
  },
};

function createBaseFormGroup(): FormGroup {
  return { label: "", description: "", collapsed: false, inputs: [], workspaces: [], dependencies: [] };
}

export const FormGroup = {
  encode(message: FormGroup, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.label !== "") {
      writer.uint32(10).string(message.label);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.collapsed === true) {
      writer.uint32(24).bool(message.collapsed);
    }
    for (const v of message.inputs) {
      FormInput.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    writer.uint32(42).fork();
    for (const v of message.workspaces) {
      writer.int32(v);
    }
    writer.ldelim();
    for (const v of message.dependencies) {
      Dependency.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FormGroup {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFormGroup();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.label = reader.string();
          continue;
        case 2:
          if (tag != 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag != 24) {
            break;
          }

          message.collapsed = reader.bool();
          continue;
        case 4:
          if (tag != 34) {
            break;
          }

          message.inputs.push(FormInput.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag == 40) {
            message.workspaces.push(reader.int32() as any);
            continue;
          }

          if (tag == 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.workspaces.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 6:
          if (tag != 50) {
            break;
          }

          message.dependencies.push(Dependency.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<FormGroup>): FormGroup {
    return FormGroup.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FormGroup>): FormGroup {
    const message = createBaseFormGroup();
    message.label = object.label ?? "";
    message.description = object.description ?? "";
    message.collapsed = object.collapsed ?? false;
    message.inputs = object.inputs?.map((e) => FormInput.fromPartial(e)) || [];
    message.workspaces = object.workspaces?.map((e) => e) || [];
    message.dependencies = object.dependencies?.map((e) => Dependency.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFormResponse(): FormResponse {
  return { groups: [], metric: false, version: "" };
}

export const FormResponse = {
  encode(message: FormResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.groups) {
      FormGroup.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.metric === true) {
      writer.uint32(16).bool(message.metric);
    }
    if (message.version !== "") {
      writer.uint32(26).string(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FormResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFormResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag != 10) {
            break;
          }

          message.groups.push(FormGroup.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag != 16) {
            break;
          }

          message.metric = reader.bool();
          continue;
        case 3:
          if (tag != 26) {
            break;
          }

          message.version = reader.string();
          continue;
      }
      if ((tag & 7) == 4 || tag == 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create(base?: DeepPartial<FormResponse>): FormResponse {
    return FormResponse.fromPartial(base ?? {});
  },

  fromPartial(object: DeepPartial<FormResponse>): FormResponse {
    const message = createBaseFormResponse();
    message.groups = object.groups?.map((e) => FormGroup.fromPartial(e)) || [];
    message.metric = object.metric ?? false;
    message.version = object.version ?? "";
    return message;
  },
};

/** Form generator service definition. */
export type FormDefinition = typeof FormDefinition;
export const FormDefinition = {
  name: "Form",
  fullName: "Form",
  methods: {
    /** Returns a grid form. */
    grid: {
      name: "Grid",
      requestType: FormRequest,
      requestStream: false,
      responseType: FormResponse,
      responseStream: false,
      options: {},
    },
    cage: {
      name: "Cage",
      requestType: FormRequest,
      requestStream: false,
      responseType: FormResponse,
      responseStream: false,
      options: {},
    },
    programPath: {
      name: "ProgramPath",
      requestType: FormRequest,
      requestStream: false,
      responseType: FormResponse,
      responseStream: false,
      options: {},
    },
  },
} as const;

export interface FormServiceImplementation<CallContextExt = {}> {
  /** Returns a grid form. */
  grid(request: FormRequest, context: CallContext & CallContextExt): Promise<DeepPartial<FormResponse>>;
  cage(request: FormRequest, context: CallContext & CallContextExt): Promise<DeepPartial<FormResponse>>;
  programPath(request: FormRequest, context: CallContext & CallContextExt): Promise<DeepPartial<FormResponse>>;
}

export interface FormClient<CallOptionsExt = {}> {
  /** Returns a grid form. */
  grid(request: DeepPartial<FormRequest>, options?: CallOptions & CallOptionsExt): Promise<FormResponse>;
  cage(request: DeepPartial<FormRequest>, options?: CallOptions & CallOptionsExt): Promise<FormResponse>;
  programPath(request: DeepPartial<FormRequest>, options?: CallOptions & CallOptionsExt): Promise<FormResponse>;
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;
