import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Icon, Badge, EntityBreadcrumbs, DateTime, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { WorkDeviceUtils, WorkDevice, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IWorkDeviceHeaderProps {
	workDevice?: WorkDevice
	section?: string
	view: string
}

export const WorkDeviceHeader: React.FC<IWorkDeviceHeaderProps> = (props) => {
	const { t } = useSession()
	const { workDevice, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: WorkDeviceUtils.name(t, workDevice) || "...", created: "", updated: "" },
		workDevice,
	)

	const { redirect, setRedirect } = useRedirect()
	const [trashHandler, trashable] = useTrash(EntityType.Worker, setRedirect, created, 120)

	const tabs = _.compact([
		{
			label: t("common.tabs.show"),
			to: urlTo("workDevices/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workDevices", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workDevices/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("workDevices/edit", id), active: view === "edit" },
		{
			label: t("common.tabs.options"),
			options: [
				{
					disabled: !trashable,
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("workDevice.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { name }),
		},
	])

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("workDevice.titles.new")
	} else if (view === "edit") {
		title = t("workDevice.titles.edit", { name })
	} else {
		title = t("workDevice.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={workDevice} entityType={EntityType.WorkDevice} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
