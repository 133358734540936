import React from "react"

import { useSession } from "@app/contexts"
import { AlertLevel, AssemblyType, newAssembly } from "@app/domain"
import { Button, Icon, PageContainer, Title } from "@app/components"
import { urlTo } from "@app/util"
import { useRedirect } from "@app/hooks"
import { ImportAssembly } from "@app/api"
import { useHistory } from "react-router-dom"

export const AssemblyImportPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const history = useHistory()

	const [fileName, setFileName] = React.useState<string>("")
	const [stpFile, setSTPFile] = React.useState<string>("")
	const [ifcFile, setIFCFile] = React.useState<string>("")
	const [fileHovering, setFileHovering] = React.useState<boolean>(false)
	const acceptedFileTypes = [".gob", ".ifc", ".stp", ".step"]

	const dropHandler = (ev: React.DragEvent<HTMLDivElement>) => {
		ev.preventDefault()
		setFileHovering(false)
		fileHandler(ev.dataTransfer?.files?.[0] ?? ev.dataTransfer?.items?.[0]?.getAsFile())
	}

	const fileHoverStyles = {
		border: "2px dashed #ccc",
		borderRadius: "5px",
		backgroundColor: "rgb(255 214 51)",
	}

	const getFileBase64 = (file: File): Promise<string> => {
		return new Promise((acc, err) => {
			const reader = new FileReader()
			reader.onload = (event) => {
				acc((event?.target?.result as string).replace("data:application/octet-stream;base64,", ""))
			}
			reader.onerror = (error) => {
				err(error)
			}
			reader.readAsDataURL(file)
		})
	}

	const getFileText = (file: File): Promise<string> => {
		return new Promise((acc, err) => {
			const reader = new FileReader()
			reader.onload = (event) => {
				acc(event?.target?.result as string)
			}
			reader.onerror = (error) => {
				err(error)
			}
			reader.readAsText(file)
		})
	}

	const fileHandler = async (file: File | null) => {
		if (!file) {
			return
		}
		if (!acceptedFileTypes.some((ft) => file.name.endsWith(ft))) {
			addNotification({
				title: t("common.errors.invalidFileTypeTitle", { fileTypes: acceptedFileTypes.join(", ") }),
				alertLevel: AlertLevel.Danger,
				subtitle: t("common.errors.invalidFileTypeSubtitle", {
					fileTypes: acceptedFileTypes.join(", "),
				}),
			})
		}
		switch (file.name.split(".").pop()?.toLowerCase()) {
			case "gob":
				await handleGOBImport(await getFileBase64(file))
				break
			case "ifc":
				setFileName(file.name)
				setIFCFile(await getFileText(file))
				break
			case "stp":
			case "step":
				setFileName(file.name)
				setSTPFile(await getFileText(file))
				break
			default:
				break
		}
	}

	const handleSTPImport = async (type: AssemblyType) => {
		const assembly = newAssembly({
			assemblyType: type,
			configuratorParams: {
				[type]: {
					geometry_source_stp: stpFile,
					metric: true,
				},
			},
		})
		history.push(urlTo(`assemblies/${type.toLowerCase()}/new`), { assembly })
	}

	const handleGOBImport = async (base64: string) => {
		const resp = await ImportAssembly({ base64 })
		if (!resp.ok) {
			handleError(resp.errors)
		} else {
			const saved = resp.result.assemblies[0]
			setRedirect(urlTo("assemblies", saved), {
				alertLevel: AlertLevel.Success,
				title: t("assembly.notifications.success.created", { name: saved.name }),
			})
		}
	}

	const handleIFCImport = async (type: AssemblyType) => {
		const assembly = newAssembly({
			assemblyType: type,
			configuratorParams: {
				[type]: {
					geometry_source_ifc: ifcFile,
					metric: true,
				},
			},
		})
		history.push(urlTo(`assemblies/${type.toLowerCase()}/new`), { assembly })
	}

	const dragEventHandler = (fileHovering: boolean) => (ev: React.DragEvent<HTMLDivElement>) => {
		ev.preventDefault()
		setFileHovering(fileHovering)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer padding={false} flex={true}>
			<Title>{t("assembly.titles.import")}</Title>
			<div
				className="w-full flex flex-col gap-8 place-content-center place-items-center"
				onDrop={dropHandler}
				onDragEnter={dragEventHandler(true)}
				onDragOver={dragEventHandler(true)}
				onDragExit={dragEventHandler(false)}
				onDragLeave={dragEventHandler(false)}
			>
				<div
					className="p-10 bg-gray-300 rounded-lg text-gray-900 hover:bg-yellow-400 duration-150 flex gap-4 items-center cursor-pointer"
					onClick={() =>
						(document.getElementById("hidden-file-input") as HTMLInputElement)?.click()
					}
					style={fileHovering ? fileHoverStyles : {}}
				>
					<Icon name="Upload" size="2x" />
					<p>{fileName ? fileName : t("assembly.buttons.import")}</p>
				</div>
				<input
					type="file"
					id="hidden-file-input"
					className="hidden"
					accept={acceptedFileTypes.join(",")}
					// @ts-expect-error
					onChange={(ev) => fileHandler(ev.target?.files?.[0])}
				/>
				{stpFile.length > 0 && (
					<>
						<p>{t("assembly.labels.selectAssemblyType")}</p>
						<div className="flex gap-4 w-72">
							<Button
								label={t("assemblyTypes.W1-Cage")}
								onClick={() => handleSTPImport(AssemblyType.W1Cage)}
							/>
							<Button
								label={t("assemblyTypes.W2-Grid")}
								onClick={() => handleSTPImport(AssemblyType.W2Grid)}
							/>
						</div>
					</>
				)}
				{ifcFile.length > 0 && (
					<>
						<p>{t("assembly.labels.selectAssemblyType")}</p>
						<div className="flex gap-4 w-72">
							<Button
								label={t("assemblyTypes.W1-Cage")}
								onClick={() => handleIFCImport(AssemblyType.W1Cage)}
							/>
							<Button
								label={t("assemblyTypes.W2-Grid")}
								onClick={() => handleIFCImport(AssemblyType.W2Grid)}
							/>
						</div>
					</>
				)}
			</div>
		</PageContainer>
	)
}
