import React from "react"

import { Await, PageContainer, DocumentDetails } from "@app/components"
import { DocumentHeader } from "@app/headers"
import { Document, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const DocumentShowPage: React.FC = () => {
	const [document, documentLoading] = useEntity<Document>(EntityType.Document)

	return (
		<PageContainer>
			<DocumentHeader document={document} view="show" />
			<Await
				loading={documentLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<DocumentDetails document={document} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
