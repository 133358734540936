import React from "react"
import _ from "lodash"
import { parseJSON } from "date-fns"
import { useLocation } from "react-router-dom"

import { PageContainer, DateTime, Link, WorkCellStatusCard } from "@app/components"
import { GetWorkCellRunHistories, GetEventLogs, RunHistory } from "@app/api"
import { Entity, EntityType, EventLog, User } from "@app/domain"
import { useRelation, useEntityDetails } from "@app/hooks"
import { articleize, humanize } from "@app/util"
import { useInterval } from "usehooks-ts"

const pluralize = (e: string): string => {
	if (_.endsWith(e, "y")) {
		return `${e.substring(0, _.size(e) - 1)}ies`
	}
	return `${e}s`
}

export const DashboardPage: React.FC = () => {
	const [eventLogs, setEventLogs] = React.useState<EventLog[]>([])
	const [runHistory, setRunHistory] = React.useState<RunHistory[]>([])
	const [now, setNow] = React.useState(new Date())

	useInterval(
		() => {
			setNow(new Date())
		},
		1000 * 60 * 60,
	) // Once an hour.
	const location = useLocation()

	React.useEffect(() => {
		const load = async () => {
			const resp = await GetEventLogs({ limit: 100 })
			const { eventLogs } = resp.result
			setEventLogs(
				_.chain(eventLogs)
					.filter((log) => {
						return !(
							_.isNil(log.userID) ||
							_.includes([EntityType.Documentable, EntityType.Contactable], log.entityType) ||
							log.userID === log.entityID
						)
					})
					.uniqBy((log) => {
						let created = parseJSON(log.created).getTime()
						created -= created % 5000
						return `${log.action}${created}${log.parentEntityID}`
					})
					.take(50)
					.value(),
			)
		}
		load()
	}, [location.search, now])

	React.useEffect(() => {
		const load = async () => {
			const resp = await GetWorkCellRunHistories()
			const { runHistory } = resp.result
			setRunHistory(runHistory)
		}
		load()
	}, [location.search, now])

	return (
		<PageContainer>
			<div className="flex gap-x-2">
				<div className="w-2/5 space-x-2">
					<div className="flex flex-col gap-4">
						{_.map(runHistory, (rh) => (
							<WorkCellStatusCard key={rh.id} runHistory={rh} />
						))}
					</div>
				</div>
				<div className="w-3/5">
					{_.map(eventLogs, (log) => (
						<RecentEventLog key={log.id} log={log} />
					))}
				</div>
			</div>
		</PageContainer>
	)
}

export const RecentEventLog = ({ log }: { log: EventLog }) => {
	const [user] = useRelation<User>(EntityType.User, log.userID)
	let id = log.entityID
	let entityType: EntityType | undefined = undefined
	const relation = _.startsWith(log.action, "Relation")
	_.each(log.entityIDs, (eids, et) => {
		_.each(eids, (eid) => {
			if (relation && eid !== id) {
				entityType = et as EntityType
				return false
			} else if (!relation) {
				entityType = log.entityType as EntityType
				return false
			}
		})
		if (entityType) {
			return false
		}
	})
	id = log.entityIDs?.[entityType ?? ""]?.[0]
	const action = _.replace(log.action, "Relation", "")

	const [entity] = useRelation<Entity>(entityType, id)
	const [, name, route] = useEntityDetails(entityType, entity)

	const what = _.capitalize(humanize(log.entityType || ""))

	const description = _.isEmpty(name) ? (
		_.capitalize(`${articleize(humanize(log.entityType || ""))} `)
	) : (
		<>
			{relation ? `${pluralize(what)} of ` : `${what} `}
			<Link to={route} relation>
				{name}
			</Link>
		</>
	)

	return (
		<div className="text-right">
			{description}
			{` ${_.lowerCase(`${action}d`)} by `}
			{user ? `${user.firstName} ${user.lastName}` : "..."}
			<div className="text-gray-500 ml-2 w-28 inline-block text-left">
				<DateTime time={log.created} />
			</div>
		</div>
	)
}
