import React from "react"

import {
	AssemblyHeader,
	ContactHeader,
	CustomerHeader,
	DocumentHeader,
	JobHeader,
	MaterialHeader,
	ReleaseHeader,
	ReleaseUnitHeader,
	SettingHeader,
	ShipmentHeader,
	TagHeader,
	UserHeader,
	WorkActionHeader,
	WorkCellHeader,
	WorkCellProgramHeader,
	WorkCellServerHeader,
	WorkDeviceHeader,
	WorkerHeader,
	WorkEventHeader,
	WorkFacilityHeader,
	WorkResourceHeader,
} from "@app/headers"

import {
	Entity,
	EntityType,
	Assembly,
	Contact,
	Customer,
	Document,
	Job,
	Material,
	Release,
	ReleaseUnit,
	Setting,
	Shipment,
	Tag,
	User,
	WorkAction,
	WorkCell,
	WorkCellProgram,
	WorkCellServer,
	WorkDevice,
	Worker,
	WorkEvent,
	WorkFacility,
	WorkResource,
} from "@app/domain"

export const useEntityHeader = (
	parentType: EntityType,
	view: string,
	entity?: Entity,
): JSX.Element | null => {
	switch (parentType) {
		case EntityType.Assembly:
			return <AssemblyHeader assembly={entity as Assembly} view={view} />
		case EntityType.Contact:
			return <ContactHeader contact={entity as Contact} view={view} />
		case EntityType.Customer:
			return <CustomerHeader customer={entity as Customer} view={view} />
		case EntityType.Document:
			return <DocumentHeader document={entity as Document} view={view} />
		case EntityType.Job:
			return <JobHeader job={entity as Job} view={view} />
		case EntityType.Material:
			return <MaterialHeader material={entity as Material} view={view} />
		case EntityType.Release:
			return <ReleaseHeader release={entity as Release} view={view} />
		case EntityType.ReleaseUnit:
			return <ReleaseUnitHeader releaseUnit={entity as ReleaseUnit} view={view} />
		case EntityType.Setting:
			return <SettingHeader setting={entity as Setting} view={view} />
		case EntityType.Shipment:
			return <ShipmentHeader shipment={entity as Shipment} view={view} />
		case EntityType.Tag:
			return <TagHeader tag={entity as Tag} view={view} />
		case EntityType.User:
			return <UserHeader user={entity as User} view={view} />
		case EntityType.Worker:
			return <WorkerHeader worker={entity as Worker} view={view} />
		case EntityType.WorkAction:
			return <WorkActionHeader workAction={entity as WorkAction} view={view} />
		case EntityType.WorkCellProgram:
			return <WorkCellProgramHeader workCellProgram={entity as WorkCellProgram} view={view} />
		case EntityType.WorkCell:
			return <WorkCellHeader workCell={entity as WorkCell} view={view} />
		case EntityType.WorkDevice:
			return <WorkDeviceHeader workDevice={entity as WorkDevice} view={view} />
		case EntityType.WorkEvent:
			return <WorkEventHeader workEvent={entity as WorkEvent} view={view} />
		case EntityType.WorkCellServer:
			return <WorkCellServerHeader workCellServer={entity as WorkCellServer} view={view} />
		case EntityType.WorkFacility:
			return <WorkFacilityHeader workFacility={entity as WorkFacility} view={view} />
		case EntityType.WorkResource:
			return <WorkResourceHeader workResource={entity as WorkResource} view={view} />
		default:
			console.warn(`unhandled header entity: "${parentType}"`)
			return null
	}
}
