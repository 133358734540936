import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Title, Tabs, Breadcrumbs, BreadcrumbDelimiter } from "@app/components"
import { useQuery, useRedirect } from "@app/hooks"
import { Tenant } from "@app/domain"

interface IReportsHeaderProps {
	view: string
	tenants?: Tenant[]
}

export const ReportsHeader: React.FC<IReportsHeaderProps> = (props) => {
	const { view, tenants = [] } = props

	const { t, tenantID } = useSession()
	const { redirect } = useRedirect()
	const query = useQuery()

	const tabs = [
		{
			label: t("common.tabs.calendar"),
			to: "/reports",
			active: view === "calendar",
		},
		{
			label: t("reports.tabs.workCellSessions"),
			to: "/reports/work-cell-sessions",
			active: view === "work-cell-sessions",
		},
		{
			label: t("reports.tabs.workTrackingTable"),
			to: "/reports/work-tracking-table",
			active: view === "work-tracking-table",
		},
		{
			label: t("reports.tabs.workTrackingCalendar"),
			to: "/reports/work-tracking-calendar",
			active: view === "work-tracking-calendar",
		},
		{
			label: t("reports.tabs.workCellStatuses"),
			to: "/reports/work-cell-statuses",
			active: view === "work-cell-statuses",
			subTabs:
				_.size(tenants) <= 1
					? []
					: _.map(tenants, (t) => {
							let active = false
							if (_.isEmpty(query["tenantID"])) {
								active = tenantID === t.id
							} else {
								active = query["tenantID"] === t.id.toString()
							}
							return {
								label: t.name,
								to: `/reports/work-cell-statuses?tenantID=${t.id}`,
								active,
							}
					  }),
		},
		{
			label: t("reports.tabs.workCellRunReports"),
			to: "/reports/work-cell-run-reports",
			active: view === "work-cell-run-reports",
		},
	]

	if (redirect) {
		return redirect()
	}

	return (
		<>
			<Title>{t(`reports.titles.${_.camelCase(view)}`)}</Title>
			<Breadcrumbs>
				{t(`reports.titles.reports`)}
				<BreadcrumbDelimiter />
				{t(`reports.titles.${_.camelCase(view)}`)}
			</Breadcrumbs>
			<Tabs tabs={tabs} />
		</>
	)
}
