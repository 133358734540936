import React from "react"
import ReactDOM from "react-dom/client"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import { Helmet } from "react-helmet-async"
import { Router } from "@app/router"
import { Providers } from "@app/providers"
import Config from "@app/config"

import "@assets/css/tailwind.css"
import "@assets/css/animation.css"
import "@assets/css/misc.css"
import "@assets/css/react-select.scss"
import "@assets/css/jsondiffpatch.css"

Sentry.init({
	dsn: "https://d7bd1288a75242a9acf0eed18f7aec31@o4504012018614272.ingest.sentry.io/4504056755847168",
	environment: Config.env,
	enabled: ["staging", "production"].includes(Config.env),
	integrations: [
		new BrowserTracing({
			startTransactionOnLocationChange: false,
			startTransactionOnPageLoad: false,
		}),
	],
	release: Config.buildCommit,
	tracesSampleRate: 0.01,
})

// eslint-disable-next-line
ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<Sentry.ErrorBoundary showDialog>
			<Providers>
				<Helmet>
					<link rel="icon" href={`/assets/img/${Config.env}-favicon.png`} />
				</Helmet>
				<Router />
			</Providers>
		</Sentry.ErrorBoundary>
	</React.StrictMode>,
)
