import React from "react"
import _ from "lodash"

import { Select, Button, Input, FormActions } from "@app/components"
import { useSession } from "@app/contexts"
import { UpdateAccountParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers, useTimezones } from "@app/hooks"

interface IAccountFormProps {
	busy?: boolean
	disabled?: boolean
	defaultParams: UpdateAccountParams
	errors: { [key: string]: string }
	onSave: (params: UpdateAccountParams) => void
}

export const AccountForm: React.FC<IAccountFormProps> = (props) => {
	const { defaultParams, busy = false, disabled = false, onSave, errors } = props

	const { t, user, addNotification } = useSession()
	const { inputHandler, selectHandler, params } = useFormHelpers<UpdateAccountParams>(defaultParams)
	const { timezoneOptions, defaultTimezoneOption } = useTimezones(_.get(params, "timezone", ""))

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("account.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.firstName}
						label={t("account.labels.firstName")}
						name="firstName"
						error={_.get(errors, "firstName")}
						onChange={inputHandler("firstName")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.lastName}
						label={t("account.labels.lastName")}
						name="lastName"
						error={_.get(errors, "lastName")}
						onChange={inputHandler("lastName")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled}
						readOnly={true}
						defaultValue={user?.email}
						label={t("account.labels.email")}
						name="email"
						type="email"
						hint={t("account.hints.emailNoEdit")}
					/>
					<Select
						defaultValue={defaultTimezoneOption}
						disabled={disabled || busy}
						error={_.get(errors, "timezone")}
						isSearchable={true}
						label={t("account.labels.timezone")}
						name="timezone"
						onChange={selectHandler("timezone")}
						options={timezoneOptions}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("account.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
