import _ from "lodash"

import type { Address, Attribute, Contactable, Documentable } from "@app/domain"

export type Shipment = {
	id: string
	customerID: string
	jobID: string
	workFacilityID: string | null

	addresses: Address[]
	attributes: Attribute[]
	delivered: string | null
	metric: boolean
	name: string
	referenceID: string
	shipped: string | null

	contactables: Contactable[]
	documentables: Documentable[]

	created: string
	updated: string
}

export const newShipment = (opts?: {
	[key: string]: string | boolean | number | Address[] | Contactable[]
}): Shipment => {
	return _.assign(
		{
			id: "",
			customerID: "",
			jobID: "",
			workFacilityID: "",

			addresses: [],
			attributes: [],
			delivered: null,
			metric: false,
			name: "",
			referenceID: "",
			shipped: null,

			contactables: [],
			documentables: [],

			created: "",
			updated: "",
		},
		opts,
	)
}
