import React from "react"
import _ from "lodash"

import { TrackingQRCode, Property, Label } from "@app/components"
import { useSession } from "@app/contexts"
import { WorkAction, WorkActionMaxDurationSecondsOptions } from "@app/domain"
import { fromUUID } from "@app/util"

interface IWorkActionDetailsProps {
	workAction?: WorkAction
}

export const WorkActionDetails: React.FC<IWorkActionDetailsProps> = (props) => {
	const { t } = useSession()

	const { workAction } = props

	if (_.isNil(workAction)) {
		return null
	}

	const maxDurationSeconds = _.find(
		WorkActionMaxDurationSecondsOptions,
		(opt) => opt.value === workAction.maxDurationSeconds.toString(),
	)

	const makeCode = (t: string, actorIsSubject?: boolean) => {
		let type = t
		if (actorIsSubject) {
			type += ",a"
		}
		return {
			action: {
				id: fromUUID(workAction.id),
				description: workAction.name,
				type,
			},
		}
	}

	return (
		<>
			<div className="pt-1 sm:px-2 max-w-lg">
				<Property label={t("workAction.labels.name")} value={workAction.name} />
				<Property label={t("workAction.labels.description")} value={workAction.description} />
				<Property label={t("workAction.labels.disabled")} value={workAction.disabled} />
				<Property
					label={t("workAction.labels.resolveReleaseUnitStatus")}
					value={workAction.resolveReleaseUnitStatus}
				/>
				<Property label={t("workAction.labels.actorIsSubject")} value={workAction.actorIsSubject} />
				<Property
					label={t("workAction.labels.maxDurationSeconds")}
					value={maxDurationSeconds?.label}
				/>
			</div>
			{workAction.maxDurationSeconds === 0 ? (
				<div className="pl-2 inline-block">
					<Label className="block">{t("workAction.labels.trackingQRCodes")}</Label>
					<div className="mx-4 p-4 border border-gray-400 inline-block text-center">
						<TrackingQRCode trackingCode={makeCode("t", workAction.actorIsSubject)} />
						<div className="font-bold pt-2">Timestamp</div>
					</div>
				</div>
			) : (
				<div className="pl-2 inline-block">
					<Label className="block">{t("workAction.labels.trackingQRCodes")}</Label>
					<div className="space-x-4">
						<div className="p-4 border border-gray-400 inline-block text-center">
							<TrackingQRCode trackingCode={makeCode("s", workAction.actorIsSubject)} />
							<div className="font-bold pt-2">Start {workAction.name}</div>
						</div>
						<div className="p-4 border border-gray-400 inline-block text-center">
							<TrackingQRCode trackingCode={makeCode("f", workAction.actorIsSubject)} />
							<div className="font-bold pt-2">Finish {workAction.name}</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
