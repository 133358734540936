import React from "react"
import _ from "lodash"

import { PageContainer, Await, Title } from "@app/components"
import { WorkAction, AlertLevel, EntityType } from "@app/domain"
import { WorkActionForm } from "@app/forms"
import { WorkActionHeader } from "@app/headers"
import { newSaveWorkActionParams, SaveWorkAction, SaveWorkActionParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkActionEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workAction, workActionLoading] = useEntity<WorkAction>(EntityType.WorkAction)
	const { id, name } = _.assign({ id: "", name: "..." }, workAction)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const onSave = async (params: SaveWorkActionParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkAction({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workActions?.[0]
				setRedirect(urlTo("workActions", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workAction.notifications.success.updated", { name: saved?.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workAction.titles.edit", { name })}</Title>
			<WorkActionHeader workAction={workAction} view="edit" />
			<Await
				loading={workActionLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkActionForm
							busy={busy}
							defaultParams={newSaveWorkActionParams(workAction)}
							errors={errors}
							onSave={onSave}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
