import _ from "lodash"
import { GetSentryTx } from "@app/tx"

export class UnauthorizedError extends Error {
	constructor() {
		super("invalid authentication credentials")
		this.name = "UnauthorizedError"
	}
}

export class JSONParseError extends Error {
	constructor() {
		super("failed to parse JSON")
		this.name = "JSONParseError"
	}
}

export class ServerError extends Error {
	constructor(message: string) {
		super(message)
		this.name = "ServerError"
	}
}

export class HTTPError extends Error {
	status: number

	constructor(status: number) {
		super(`received HTTP ${status}`)
		this.name = "HTTPError"
		this.status = status
	}
}

const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const extractErrors = (result: Result<unknown>) =>
	_.reduce(
		result.fieldErrors,
		(acc, fe) => {
			acc[fe.field] = fe.message
			return acc
		},
		{},
	)

export type RPCOptions = {
	minDuration: number
}

export const rpc = async (
	method: string,
	params?: unknown,
	options: RPCOptions = { minDuration: 0 },
): Promise<Result<unknown>> => {
	const transaction = GetSentryTx()
	const waitUntil = Date.now() + options.minDuration
	let hint = ""
	if (import.meta.env.DEV) {
		if (_.isObject(params)) {
			const keys = Object.keys(params).sort()
			hint = _.compact(
				_.map(keys, (k) => {
					const v = params[k]
					if (_.isNil(v)) {
						return null
					}
					if (_.isArray(v)) {
						if (_.size(v) > 1) {
							return `${k}=[${_.first(v)},…${_.size(v)} more]`
						} else if (_.size(v) === 0) {
							return `${k}=[]`
						}
						return `${k}=[${_.first(v)}]`
					}
					if (_.isObject(v)) {
						return null
					}
					if (_.isString(k) && k.toLowerCase() === "password") {
						return null
					}
					return `${k}=${v}`
				}),
			).join("&")
		} else if (_.isString(params)) {
			hint = params
		}
	}
	const body = {
		id: _.uniqueId(),
		method,
		params,
	}
	const userSessionToken = localStorage.getItem("sessionToken")
	const span = transaction?.startChild({ op: "rpc", description: method, data: { params } })
	const headers = {
		"Content-Type": "application/json",
	}
	if (_.size(userSessionToken) > 256) {
		headers["X-Toggle-Session"] = userSessionToken
	}
	const resp = await fetch(`/rpc/core?${method}${hint ? `(${hint})` : ""}`, {
		method: "post",
		body: JSON.stringify(body),
		headers: headers,
	})
	if (resp.status !== 200) {
		span?.finish()
		transaction?.finish()
		throw new HTTPError(resp.status)
	}
	const data = await resp.json()
	if (_.has(data, "error")) {
		if (_.get(data, "error.name") === "unauthorized") {
			localStorage.clear()
			span?.finish()
			transaction?.finish()
			throw new UnauthorizedError()
		}
		span?.finish()
		transaction?.finish()
		if (import.meta.env.DEV) {
			console.error(JSON.stringify({ request: body, response: data }, null, "  "))
		}
		throw new ServerError(_.get(data, "error.message"))
	}
	if (method === "Login" || method === "LoginByToken") {
		localStorage.setItem("sessionToken", _.get(data, "result.result.session.token"))
	} else if (method === "Logout") {
		localStorage.clear()
	}
	const wait = waitUntil - Date.now()
	if (wait > 0) {
		await timeout(wait)
	}
	const result = _.get(data, "result")
	if (_.has(result, "fieldErrors")) {
		if (import.meta.env.DEV) {
			console.warn(JSON.stringify(result, null, "  "))
		}
		result.errors = extractErrors(result)
	} else {
		result.errors = {}
	}
	span?.finish()
	return result as Result<unknown>
}

export type FieldError = {
	field: string
	message: string
}

export type Result<T> = {
	ok: boolean
	fieldErrors?: FieldError[]
	errors: { [key: string]: string }
	result: T
}
