export enum BlobType {
	AssemblyPNG = "AssemblyPNG",
	AssemblyGLTF = "AssemblyGLTF",
	AssemblySTP = "AssemblySTP",
	AssemblyIGES = "AssemblyIGES",
}

export const blobTypeToExtension = (blobType: BlobType): string | undefined => {
	switch (blobType) {
		case BlobType.AssemblyPNG:
			return "png"
		case BlobType.AssemblyGLTF:
			return "gltf"
		case BlobType.AssemblySTP:
			return "stp"
		case BlobType.AssemblyIGES:
			return "iges"
	}
}
