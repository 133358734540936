import React from "react"
import _ from "lodash"
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	rectSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable"
import clsx from "clsx"

import { ButtonIcon, Icon, Input } from "@app/components"
import { MaterialItemParams, newMaterialItemParams, newRebarMaterialItemParams } from "@app/api"
import { useSession } from "@app/contexts"
import { RebarMaterialItemBendFields } from "@app/domain"

interface IMaterialItemsFormProps {
	defaultParams?: MaterialItemParams[]
	metric: boolean
	disabled: boolean
	errors: { [key: string]: string }
	onChange: (params?: MaterialItemParams[]) => void
}

export const MaterialItemsForm: React.FC<IMaterialItemsFormProps> = (props) => {
	const { onChange, disabled, errors, metric, defaultParams = [] } = props

	const [params, setParams] = React.useState<MaterialItemParams[]>(defaultParams)

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over && active.id !== over.id && params) {
			const oldIndex = params.findIndex((param) => param.id === active.id)
			const newIndex = params.findIndex((param) => param.id === over.id)
			swap(oldIndex, newIndex)
		}
	}

	const swap = (oldIndex: number, newIndex: number) => {
		setParams((params) => {
			return arrayMove(
				params.map((r) => ({ ...r })),
				oldIndex,
				newIndex,
			)
		})
	}

	React.useEffect(() => {
		if (_.size(params) === 0) {
			setParams([newMaterialItemParams()])
		}
	}, [params])

	const addHandler = (r: MaterialItemParams) => {
		return () => {
			setParams((prev) => {
				const next: MaterialItemParams[] = []
				_.each(prev, (item) => {
					next.push(item)
					if (item.id === r.id) {
						next.push(newMaterialItemParams())
					}
				})
				return next
			})
		}
	}

	const removeHandler = (r: MaterialItemParams) => {
		return () => {
			setParams((prev) => {
				const next: MaterialItemParams[] = []
				_.each(prev, (item) => {
					if (item.id !== r.id) {
						next.push(item)
					}
				})
				if (_.isEmpty(next)) {
					next.push(newMaterialItemParams())
				}
				return next
			})
		}
	}

	const inputHandler = (
		r: MaterialItemParams,
		key: string,
		ref?: React.RefObject<HTMLInputElement>,
	): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			let value: string | number | null = target.value as string
			const field = _.last(key.split("."))
			if (field === "quantity" || field === "size") {
				const num = parseInt(value.replace(/[^0-9]/g, ""), 10)
				value = isNaN(num) ? 1 : num
				if (ref && ref.current) {
					ref.current.value = value.toString()
				}
			} else if (field === "weight") {
				const invalid = value.match(/[^\d.]/)
				const num = parseFloat(value)
				value = isNaN(num) ? "" : num
				if (invalid && ref && ref.current) {
					ref.current.value = value.toString()
				}
			}
			setParams((prev) => {
				const next: MaterialItemParams[] = []
				_.each(prev, (item) => {
					if (item.id === r.id) {
						next.push(_.set(item, key, value))
					} else {
						next.push(item)
					}
				})
				return next
			})
		}
	}

	const units = metric ? "metric" : "imperial"

	return (
		<div className="flex flex-col md:flex-row md:gap-x-4">
			<div className="w-full">
				<div className="divide-y divide-gray-300 border-t border-b border-gray-300">
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragEnd={handleDragEnd}
					>
						<SortableContext items={params ?? []} strategy={rectSortingStrategy}>
							{_.map(params, (r, i) => (
								<MaterialItemForm
									addHandler={addHandler}
									errors={errors}
									key={r.id}
									i={i}
									inputHandler={inputHandler}
									last={i === (params?.length ?? 1) - 1}
									onMoveUp={() => swap(i, i - 1)}
									onMoveDown={() => swap(i, i + 1)}
									param={r}
									removeHandler={removeHandler}
									disabled={disabled}
									units={units}
								/>
							))}
						</SortableContext>
					</DndContext>
				</div>
			</div>
		</div>
	)
}

interface IMaterialItemFormProps {
	addHandler: (r: MaterialItemParams) => () => void
	errors: { [key: string]: string }
	i: number
	inputHandler: (
		r: MaterialItemParams,
		key: string,
		ref?: React.RefObject<HTMLInputElement>,
	) => (e: React.FormEvent<HTMLInputElement>) => void
	last: boolean
	onMoveUp: () => void
	onMoveDown: () => void
	param: MaterialItemParams
	removeHandler: (r: MaterialItemParams) => () => void
	disabled: boolean
	units: string
}

export const MaterialItemForm: React.FC<IMaterialItemFormProps> = (props) => {
	const { t } = useSession()
	const weightRef = React.createRef<HTMLInputElement>()
	const quantityRef = React.createRef<HTMLInputElement>()
	const sizeRef = React.createRef<HTMLInputElement>()
	const {
		addHandler,
		errors,
		i,
		inputHandler,
		last,
		onMoveUp,
		onMoveDown,
		param,
		removeHandler,
		disabled,
		units,
	} = props
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: param.id,
	})
	const rebarItem = param.rebarMaterialItem || newRebarMaterialItemParams()
	return (
		<div
			className={clsx("bg-white", isDragging ? "relative z-10 shadow-lg" : "z-0")}
			ref={setNodeRef}
			style={{
				transform: !transform ? undefined : `translateY(${Math.ceil(transform.y)}px)`,
				transition,
			}}
		>
			<div className="flex flex-row gap-x-2 md:gap-x-4 pt-4 px-4">
				<div className="inline-flex items-center w-4 gap-1">
					<div className="flex-col text-gray-600">
						<button
							type="button"
							disabled={i === 0}
							className={"disabled:text-gray-300 hover:text-700"}
							onClick={onMoveUp}
						>
							<Icon name="ChevronUp" />
						</button>
						<Icon
							name="GripLines"
							className="cursor-move focus:outline-none hover:text-700"
							{...listeners}
							{...attributes}
						/>
						<button
							type="button"
							disabled={last}
							className={"disabled:text-gray-300 hover:text-700"}
							onClick={onMoveDown}
						>
							<Icon name="ChevronDown" />
						</button>
					</div>
				</div>
				<div className="inline-flex items-center text-right w-4 text-gray-500">{i + 1}</div>
				<div className="flex flex-col">
					<div className="flex flex-row gap-x-2 md:gap-x-4">
						<div className="flex-none w-24">
							<Input
								className="text-right"
								defaultValue={rebarItem.quantity || ""}
								disabled={disabled}
								error={_.get(errors, `params[${i}].quantity`)}
								label={t("materialItem.labels.quantity")}
								name={`quantity[${i}]`}
								onChange={inputHandler(param, "rebarMaterialItem.quantity", quantityRef)}
								ref={quantityRef}
								type="text"
							/>
						</div>
						<div className="flex-none w-24">
							<Input
								className="text-right"
								defaultValue={rebarItem.size || ""}
								disabled={disabled}
								error={_.get(errors, `params[${i}].size`)}
								label={t("materialItem.labels.size")}
								name={`size[${i}]`}
								onChange={inputHandler(param, "rebarMaterialItem.size", sizeRef)}
								ref={sizeRef}
								type="text"
							/>
						</div>
						<div className="flex-none w-32">
							<Input
								defaultValue={rebarItem.length}
								disabled={disabled}
								error={_.get(errors, `params[${i}].length`)}
								label={t("materialItem.labels.length")}
								name={`length[${i}]`}
								onChange={inputHandler(param, "rebarMaterialItem.length")}
								type="text"
							/>
						</div>
						<div className="flex-none w-32">
							<Input
								defaultValue={rebarItem.mark}
								disabled={disabled}
								error={_.get(errors, `params[${i}].mark`)}
								label={t("materialItem.labels.mark")}
								name={`mark[${i}]`}
								onChange={inputHandler(param, "rebarMaterialItem.mark")}
								type="text"
							/>
						</div>
						<div className="flex-none w-24">
							<Input
								defaultValue={rebarItem.shape}
								disabled={disabled}
								error={_.get(errors, `params[${i}].shape`)}
								label={t("materialItem.labels.shape")}
								name={`shape[${i}]`}
								onChange={inputHandler(param, "rebarMaterialItem.shape")}
								type="text"
							/>
						</div>
						<div className="flex-none w-32">
							<Input
								className="text-right"
								defaultValue={rebarItem.weight || ""}
								disabled={disabled}
								error={_.get(errors, `params[${i}].weight`)}
								label={`${t("materialItem.labels.weight")} (${t(`units.${units}.weightAbbr`)})`}
								name={`weight[${i}]`}
								onChange={inputHandler(param, "rebarMaterialItem.weight", weightRef)}
								ref={weightRef}
								type="text"
							/>
						</div>
					</div>
					<div className="flex flex-row gap-x-2 md:gap-x-4">
						{_.map(RebarMaterialItemBendFields, (field) => (
							<div className="w-12" key={field}>
								<Input
									className="text-right"
									defaultValue={rebarItem[field]}
									disabled={disabled}
									error={_.get(errors, `params[${i}].${field}`)}
									label={t(`materialItem.labels.${field}`)}
									name={`${field}[${i}]`}
									onChange={inputHandler(param, `rebarMaterialItem.${field}`)}
									type="text"
								/>
							</div>
						))}
					</div>
				</div>
				<div className="hidden md:inline-flex items-center w-24 text-right gap-x-1 pl-4">
					<ButtonIcon icon="Plus" onClick={addHandler(param)} disabled={disabled} />
					<ButtonIcon icon="Minus" onClick={removeHandler(param)} disabled={disabled} />
				</div>
			</div>
		</div>
	)
}
