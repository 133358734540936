import React from "react"
import _ from "lodash"

import { Button, FormActions, Input, Select, Toggle, WorkFacilitySelect } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkDeviceParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"
import { fromUUID } from "@app/util"

import En from "@app/translations/en.json"

interface IWorkDeviceFormProps {
	busy?: boolean
	defaultParams: SaveWorkDeviceParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkDeviceParams) => void
}

export const WorkDeviceForm: React.FC<IWorkDeviceFormProps> = (props) => {
	const { t, addNotification } = useSession()

	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { inputHandler, valueHandler, selectHandler, params } =
		useFormHelpers<SaveWorkDeviceParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workDevice.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const workDeviceTypes = _.map(En.workDeviceTypes, (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultWorkDeviceTypeOption = undefined
	const workDeviceTypeOptions = workDeviceTypes.map((c) => {
		const opt = { value: c.code, label: c.name }
		if (_.get(params, "workDeviceType", "") === c.code) {
			defaultWorkDeviceTypeOption = opt
		}
		return opt
	})

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<WorkFacilitySelect
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={false}
						error={_.get(errors, "workFacilityID")}
						label={t("workDevice.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID")}
					/>
					<Select
						defaultValue={defaultWorkDeviceTypeOption}
						disabled={disabled || busy}
						isSearchable={true}
						label={t("workDevice.labels.workDeviceType")}
						name="workDeviceType"
						onChange={selectHandler("workDeviceType")}
						error={_.get(errors, "workDeviceType")}
						options={workDeviceTypeOptions}
					/>
					<Input
						autoComplete="off"
						autoFocus={params.id === ""}
						disabled={disabled || busy}
						defaultValue={params.inventoryTag}
						hint={t("workDevice.hints.inventoryTag")}
						label={t("workDevice.labels.inventoryTag")}
						name="inventoryTag"
						error={_.get(errors, "inventoryTag")}
						onChange={inputHandler("inventoryTag")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.description}
						label={t("workDevice.labels.description")}
						name="description"
						error={_.get(errors, "description")}
						onChange={inputHandler("description")}
						type="text"
					/>
					<Toggle
						label={t("common.labels.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
					{!_.isEmpty(params.id) ? (
						<>
							<Input
								readOnly={true}
								disabled={disabled || busy}
								value={fromUUID(params.id)}
								hint={t("workDevice.hints.id")}
								label={t("workDevice.labels.id")}
								name="id"
								type="text"
							/>
							<Input
								readOnly={true}
								disabled={disabled || busy}
								value={params.secret}
								hint={t("workDevice.hints.secret")}
								label={t("workDevice.labels.secret")}
								name="secret"
								type="text"
							/>
							<Toggle
								label={t("workDevice.labels.newSecret")}
								defaultChecked={params.newSecret}
								disabled={disabled || busy}
								onChange={valueHandler("newSecret")}
							/>
						</>
					) : null}
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workDevice.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
