import React from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Title, Icon, Tabs } from "@app/components"
import { urlTo, uuidForURL } from "@app/util"
import {
	WorkCellProgram,
	WorkCellProgramUtils,
	EntityType,
	UserPermission,
	AlertLevel,
} from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"
import { SaveWorkCellProgramAlwaysAvailable } from "@app/api"

interface IWorkCellProgramHeaderProps {
	workCellProgram?: WorkCellProgram
	view: string
	section?: string
}

export const WorkCellProgramHeader: React.FC<IWorkCellProgramHeaderProps> = (props) => {
	const { workCellProgram, view, section = "" } = props
	const params = useParams()
	const { id, name, created, updated, programID, deployable, alwaysAvailable } = _.assign(
		{
			id: _.get(params, "id", ""),
			name: "...",
			programID: _.get(params, "programID", ""),
			created: "",
			updated: "",
			deployable: false,
			alwaysAvailable: false,
		},
		workCellProgram,
	)

	const { t, userHas, tenantID, addNotification } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.WorkCellProgram, setRedirect)

	const href = WorkCellProgramUtils.zipURL(tenantID, workCellProgram)
	const tabs = _.compact([
		{
			label: t("common.tabs.show"),
			to: urlTo("workCellPrograms/show", id),
			active: view === "show" || view == "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workCellPrograms", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workCellPrograms/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.programs"),
			to: urlTo(`workCellPrograms/programs/${programID}`, id),
			active: view === "programs",
			subTabs: _.map(_.get(workCellProgram, "robotPrograms"), (p, i) => {
				return {
					label: t(`robotTypes.${p.robotType}`),
					to: urlTo(`workCellPrograms/programs/${uuidForURL(p.id)}`, id),
					active:
						view === "programs" && ((section === "" && i === 0) || section === uuidForURL(p.id)),
				}
			}),
		},
		{
			label: t("common.tabs.pathVisualizer"),
			to: urlTo(`workCellPrograms/path-visualizer/${programID}`, id),
			active: view === "pathVisualizer",
		},
		userHas(UserPermission.ModifyWorkCellPrograms) && {
			label: t("common.tabs.edit"),
			to: urlTo("workCellPrograms/edit", id),
			active: view === "edit",
		},
		userHas(UserPermission.ApproveWorkCellPrograms) && {
			label: t("common.tabs.feedback"),
			to: urlTo("workCellPrograms/feedback", id),
			active: view === "feedback",
		},
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="download">
							<Icon className="mr-2" name="Document" fixedWidth />
							{t("workCellProgram.buttons.download")}
						</span>
					),
					href,
				},
				{
					label: (
						<span key="schedule">
							<Icon className="mr-2" name="CalendarDay" fixedWidth />
							{t("workCellProgram.options.schedule")}
						</span>
					),
					to: urlTo("workEvents/new", undefined, { workCellProgram: uuidForURL(id) }),
				},
				{
					label: (
						<span key="schedule">
							<Icon className="mr-2" name="Infinity" fixedWidth />
							{workCellProgram?.alwaysAvailable
								? t("workCellProgram.options.unmarkAlwaysAvailable")
								: t("workCellProgram.options.markAlwaysAvailable")}
						</span>
					),
					onOptionClick: async () => {
						if (!workCellProgram) {
							addNotification({
								alertLevel: AlertLevel.Danger,
								title: "Work Cell Program not defined",
								subtitle: "Refresh & try again",
							})
							return
						}
						const resp = await SaveWorkCellProgramAlwaysAvailable({
							id: workCellProgram.id,
							alwaysAvailable: !workCellProgram.alwaysAvailable,
						})
						if (resp.ok) {
							addNotification({
								alertLevel: AlertLevel.Success,
								title: "Success",
								subtitle: "Work Cell Program updated",
							})
							workCellProgram.alwaysAvailable = resp.result.workCellPrograms[0].alwaysAvailable
						} else {
							addNotification({
								alertLevel: AlertLevel.Danger,
								title: "Error",
								subtitle: "Work Cell Program not updated",
							})
						}
					},
				},
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("workCellProgram.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { name }),
		},
	])

	if (redirect) {
		return redirect()
	}

	return (
		<>
			<Title>{name}</Title>

			<EntityBreadcrumbs
				entity={workCellProgram}
				entityType={EntityType.WorkCellProgram}
				view={view}
			/>

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>
					<div className="mb-4 space-x-1">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
						<Badge color={`text-white ${deployable ? "bg-green-500" : "bg-red-500"}`}>
							{deployable ? (
								<span>
									<Icon name="Check" className="mr-1" />
									{t("workCellProgramFeedback.types.approved")}
								</span>
							) : (
								<span>
									<Icon name="Times" className="mr-1" />
									{t("workCellProgramFeedback.types.unapproved")}
								</span>
							)}
						</Badge>
						{alwaysAvailable && (
							<Badge color="text-white bg-blue-500">
								<span>
									<Icon name="Infinity" className="mr-1" />
									{t("workCellProgram.header.alwaysAvailable")}
								</span>
							</Badge>
						)}
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
