import React from "react"
import _ from "lodash"

import { TrackingQRCode, Property, Label } from "@app/components"
import { useSession } from "@app/contexts"
import { fromUUID } from "@app/util"
import { Worker } from "@app/domain"

interface IWorkerDetailsProps {
	worker?: Worker
}

export const WorkerDetails: React.FC<IWorkerDetailsProps> = (props) => {
	const { t } = useSession()

	const { worker } = props

	if (_.isNil(worker)) {
		return null
	}

	const trackingCode = {
		actors: [
			{
				id: fromUUID(worker.id),
				description: `${worker.firstName} ${worker.lastName}`,
				type: "Worker",
			},
		],
	}

	return (
		<>
			<div className="pt-1 sm:px-2 max-w-lg">
				<Property label={t("worker.labels.firstName")} value={worker.firstName} />
				<Property label={t("worker.labels.lastName")} value={worker.lastName} />
				<Property label={t("worker.labels.disabled")} value={worker.disabled} />
			</div>
			<div className="pl-2 inline-block">
				<Label className="block">{t("workAction.labels.trackingQRCodes")}</Label>
				<div className="mx-4 p-4 border border-gray-400 inline-block text-center">
					<TrackingQRCode trackingCode={trackingCode} />
					<div className="font-bold pt-2">{trackingCode.actors[0].description}</div>
				</div>
			</div>
		</>
	)
}
