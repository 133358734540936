import _ from "lodash"
import { v4 } from "uuid"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkResource, Entity, Pagination } from "@app/domain"

export type SaveWorkResourceParams = {
	id: string
	description?: string
	disabled: boolean
	name?: string
	url?: string
	workCellID?: string
	workFacilityID?: string
	workResourceType?: string
}

export const newSaveWorkResourceParams = (opts?: WorkResource): SaveWorkResourceParams => {
	return _.assign(
		{
			id: v4(),
			description: "",
			disabled: false,
			name: "",
			workCellID: "",
			workFacilityID: "",
		},
		opts,
	)
}

export type SaveWorkResourceResult = {
	workResources: WorkResource[]
}

export const SaveWorkResource = async (
	params: SaveWorkResourceParams,
): Promise<Result<SaveWorkResourceResult>> => {
	const data = await rpc("SaveWorkResource", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkResourceResult>(data)
}

export type GetWorkResourcesParams = {
	ids?: string[]
	includeIDs?: string[]
	workCellID?: string
	workFacilityID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkResourcesResult = {
	workResources: WorkResource[]
	pagination?: Pagination
}

export const GetWorkResources = async (
	params: GetWorkResourcesParams,
): Promise<Result<GetWorkResourcesResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkResources", "workResources", id).then((workResource?: Entity) => {
			return {
				ok: true,
				result: { workResources: _.compact([workResource as WorkResource]) },
			} as Result<GetWorkResourcesResult>
		}) as Promise<Result<GetWorkResourcesResult>>
	}
	const data = await rpc("GetWorkResources", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkResourcesResult>(data)
}
