import { v4 as uuidv4 } from "uuid"
import _ from "lodash"

import type { Attribute } from "@app/domain"

export type ShipmentItem = {
	id: string
	assemblyID?: string
	customerID: string
	jobID: string
	releaseID?: string
	releaseItemID?: string
	shipmentID: string
	tagID?: string

	attributes: Attribute[]
	description: string
	item: number
	sortOrder: number

	created: string
	updated: string
}

export const newShipmentItem = (): ShipmentItem => {
	return {
		id: uuidv4(),
		assemblyID: undefined,
		customerID: "",
		jobID: "",
		releaseID: undefined,
		shipmentID: "",
		releaseItemID: undefined,
		tagID: undefined,

		attributes: [],
		description: "",
		item: 1,
		sortOrder: Date.now(),

		created: "",
		updated: "",
	}
}

export type ShipmentItemGroup = {
	id: string
	assemblyID?: string
	customerID: string
	jobID: string
	releaseID?: string
	releaseItemID?: string
	tagID?: string

	attributes: Attribute[]
	description: string
	unitStart: number
	unitEnd: number
	sortOrder: number
	itemIDMap?: { [key: number]: string }
}
export const newShipmentItemGroup = (): ShipmentItemGroup => ({
	id: uuidv4(),
	assemblyID: undefined,
	customerID: "",
	jobID: "",
	releaseID: undefined,
	releaseItemID: undefined,
	tagID: undefined,

	attributes: [],
	description: "",
	unitStart: 1,
	unitEnd: 1,
	sortOrder: 0,
})

export const groupShipmentItems = (shipmentItems: ShipmentItem[]): ShipmentItemGroup[] => {
	const shipmentItemMap = {}
	const shipmentItemIDMap = {}
	for (const shipmentItem of shipmentItems) {
		const key = `${shipmentItem.tagID}-${shipmentItem.description}`
		if (!shipmentItemMap[key]) {
			shipmentItemMap[key] = []
		}
		shipmentItemMap[key].push(shipmentItem)

		if (!shipmentItemIDMap[key]) {
			shipmentItemIDMap[key] = {}
		}
		shipmentItemIDMap[key][shipmentItem.item] = shipmentItem.id
	}
	const shipmentItemGroups = []
	let sortOrder = 0
	for (const key of Object.keys(shipmentItemMap)) {
		const sorted = _.sortBy(shipmentItemMap[key], "item")
		sorted.push({ item: Number.MAX_SAFE_INTEGER })
		let startItem = sorted[0].item
		let prev = sorted[0]
		for (const si of sorted) {
			if (si.item > prev.item + 1) {
				shipmentItemGroups.push({
					id: uuidv4(),
					assemblyID: prev.assemblyID,
					customerID: prev.customerID,
					jobID: prev.jobID,
					releaseID: prev.releaseID,
					shipmentID: prev.shipmentID,
					releaseItemID: prev.releaseItemID,
					tagID: prev.tagID,

					attributes: prev.attributes.map((a: Attribute) => ({
						...a,
						value: a.name === "tag-id" ? a.value.split("-")[0] : a.value,
					})),
					description: prev.description,
					unitStart: startItem,
					unitEnd: prev.item,
					itemIDMap: shipmentItemIDMap[key],
					sortOrder,
				})
				sortOrder += 1
				startItem = si.item
			}
			prev = si
		}
	}
	return shipmentItemGroups
}
