import React from "react"

import { CreateWorkerBadge, CreateWorkerBadgeParams } from "@app/api"
import { useSession } from "@app/contexts"
import { AlertLevel } from "@app/domain"
import { WorkerBadgeForm } from "@app/forms"
import { PageContainer, BreadcrumbDelimiter, Breadcrumbs, Title } from "@app/components"
import { fromUUID } from "@app/util"

export const WorkerBadgeNewPage: React.FC = () => {
	const { t, handleError, tenantID, addNotification } = useSession()

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const submitHandler = async (params: CreateWorkerBadgeParams) => {
		setBusy(true)
		try {
			const resp = await CreateWorkerBadge({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const blobID = fromUUID(resp.result.id)
				const blobResponse = await fetch(`/f/blobs/${tenantID}/${blobID}.pdf?type=WorkerBadgePDF`)
				if (!blobResponse.ok) {
					setErrors(resp.errors)
				} else {
					const blob = await blobResponse.blob()
					const link = document.createElement("a")
					link.href = URL.createObjectURL(blob)
					link.download = `WorkerBadges-${blobID.slice(0, 8)}.pdf`
					link.click()
					link.remove()
					window.URL.revokeObjectURL(link.href)
					addNotification({
						alertLevel: AlertLevel.Success,
						title: t("workerBadge.notifications.success"),
					})
				}
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	return (
		<PageContainer>
			<Title>{t("workerBadge.titles.new")}</Title>
			<Breadcrumbs>
				{t("workerBadge.titles.list")}
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<WorkerBadgeForm errors={errors} busy={busy} onSubmit={submitHandler} />
			</div>
		</PageContainer>
	)
}
