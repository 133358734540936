import React from "react"
import _ from "lodash"
import { formatRFC3339, addHours, roundToNearestMinutes } from "date-fns"

import { SaveWorkEvent, SaveWorkEventParams, newSaveWorkEventParams } from "@app/api"
import { useSession } from "@app/contexts"
import {
	WorkCellProgram,
	WorkEventType,
	newWorkEvent,
	AlertLevel,
	EntityType,
	Job,
} from "@app/domain"
import { WorkEventForm } from "@app/forms"
import {
	Await,
	PageContainer,
	Link,
	BreadcrumbDelimiter,
	Breadcrumbs,
	Title,
} from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useQuery, useRedirect, useRelation } from "@app/hooks"

export const WorkEventNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const { workFacility: workFacilityID = "", workCellProgram: workCellProgramID = "" } = useQuery()
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: SaveWorkEventParams) => {
		setBusy(true)
		try {
			const resp = await SaveWorkEvent({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workEvents?.[0]
				setRedirect(urlTo("workEvents", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workEvent.notifications.success.created", { description: saved?.description }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	const [workCellProgram, loadingWorkCellProgram] = useRelation<WorkCellProgram>(
		EntityType.WorkCellProgram,
		workCellProgramID,
	)

	const [job, loadingJob] = useRelation<Job>(EntityType.Job, _.get(workCellProgram, "jobID"))

	if (redirect) {
		return redirect()
	}

	const now = roundToNearestMinutes(new Date(), { nearestTo: 30 })
	const timeBegin = formatRFC3339(now)
	const timeEnd = formatRFC3339(addHours(now, 1))

	const defaultParams = newSaveWorkEventParams(
		newWorkEvent({
			workFacilityID: toUUID(workFacilityID),
			workCellProgramID: toUUID(workCellProgramID),
			timeBegin: timeBegin,
			timeEnd: timeEnd,
			description: _.get(job, "name", ""),
			workEventType: WorkEventType.DeployWorkCellProgram,
		}),
	)

	return (
		<PageContainer>
			<Title>{t("workEvent.titles.new")}</Title>

			<Breadcrumbs>
				<Link to={urlTo("workEvents")}>{t("workEvent.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<Await
					loading={loadingWorkCellProgram || loadingJob}
					then={() => (
						<WorkEventForm
							defaultParams={defaultParams}
							errors={errors}
							busy={busy}
							onSave={saveHandler}
						/>
					)}
				/>
			</div>
		</PageContainer>
	)
}
