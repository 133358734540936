import React from "react"
import _ from "lodash"

import { Link, Icon, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { GetShipmentItems } from "@app/api"
import { EntityType, ShipmentItem, WorkFacility, groupShipmentItems } from "@app/domain"
import { urlTo } from "@app/util"

import type { Shipment, ShipmentItemGroup } from "@app/domain"
import { useRelation } from "@app/hooks"

interface IShipmentDetailsProps {
	shipment?: Shipment
	section?: string
}

export const ShipmentDetails: React.FC<IShipmentDetailsProps> = (props) => {
	const { t, handleError } = useSession()

	const { shipment, section } = props
	const [shipmentItems, setShipmentItems] = React.useState<ShipmentItem[]>([])
	const [shipmentItemGroups, setShipmentItemGroups] = React.useState<ShipmentItemGroup[]>([])
	React.useEffect(() => {
		if (!shipmentItems) {
			return
		}
		setShipmentItemGroups(groupShipmentItems(shipmentItems))
	}, [shipmentItems])

	const [workFacility] = useRelation<WorkFacility>(
		EntityType.WorkFacility,
		shipment?.workFacilityID,
	)

	if (_.isNil(shipment)) {
		return null
	}

	React.useEffect(() => {
		let canceled = false
		const getShipmentItems = async () => {
			try {
				const resp = await GetShipmentItems({ shipmentID: shipment.id, limit: 500 })
				if (canceled) {
					return
				}
				if (resp.ok) {
					const next = _.get(resp, "result.shipmentItems", [])
					setShipmentItems(next)
				}
			} catch (err) {
				handleError(err)
			}
		}
		if (!_.isNil(shipment)) {
			getShipmentItems()
		}
		return () => {
			canceled = true
		}
	}, [shipment])

	if (section === "items") {
		return (
			<div className="divide-y divide-gray-300 border-t border-b border-gray-300">
				<div className="flex flex-row gap-x-2 md:gap-x-4 py-2 px-4 text-gray-700">
					<div className="flex-none w-4 text-center">&nbsp;</div>
					<div className="flex-grow text-left">{t("shipmentItem.labels.description")}</div>
					<div className="flex-none w-16 md:w-20 text-right">
						{t("shipmentItem.labels.unitStart")}
					</div>
					<div className="flex-none w-16 md:w-20 text-right">
						{t("shipmentItem.labels.unitEnd")}
					</div>
					<div className="flex-none w-16 md:w-20 text-right">
						{t("shipmentItem.labels.unitTotal")}
					</div>
				</div>
				{_.map(shipmentItemGroups, (item, i) => {
					return (
						<div key={i}>
							<div className="flex flex-row gap-x-2 md:gap-x-4 py-2 px-4" key={item.id}>
								<div className="flex-none w-4 text-center text-gray-700 text-xs leading-6">
									{i + 1}
								</div>
								<div className="flex-grow text-left">
									{item.tagID ? (
										<>
											<Icon name="Tag" className="text-gray-700 mr-1" />
											<Link styled to={urlTo("tag", item.tagID)}>
												{item.description}
											</Link>
										</>
									) : (
										item.description
									)}
								</div>
								<div className="flex-none w-16 md:w-20 text-right">{item.unitStart}</div>
								<div className="flex-none w-16 md:w-20 text-right">{item.unitEnd}</div>
								<div className="flex-none w-16 md:w-20 text-right">
									{item.unitEnd - item.unitStart + 1}
								</div>
							</div>

							{_.isEmpty(item.attributes) ? null : (
								<div className="flex flex-row gap-x-2 md:gap-x-4 pb-2 px-4">
									<div className="flex-none w-4"></div>
									<div>
										<Property group={t("attribute.header")} />
										{_.map(item.attributes, (attr, i) => {
											return <Property key={i} label={attr.name} value={attr.value} lineBreaks />
										})}
									</div>
								</div>
							)}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<div className="pt-1 max-w-lg">
			<Property label={t("common.labels.referenceID")} id={shipment.referenceID} />
			<Property label={t("shipment.labels.name")} value={shipment.name} />
			<Property label={t("common.labels.metric")} value={shipment.metric} />
			<Property
				label={t("shipment.labels.shipped")}
				date={shipment.shipped}
				dateTimezone={workFacility?.timezone}
			/>
			<Property
				label={t("shipment.labels.delivered")}
				date={shipment.delivered}
				dateTimezone={workFacility?.timezone}
			/>
			<Property group={t("common.headers.associations")} />
			<Property
				label={t("shipment.labels.customerID")}
				relation={EntityType.Customer}
				value={shipment.customerID}
			/>
			<Property
				label={t("shipment.labels.jobID")}
				relation={EntityType.Job}
				value={shipment.jobID}
			/>
			<Property
				label={t("shipment.labels.workFacilityID")}
				relation={EntityType.WorkFacility}
				value={shipment.workFacilityID}
			/>
			{_.size(shipment.attributes) > 0 ? <Property group={t("attribute.header")} /> : null}
			{_.map(shipment.attributes, (attr, i) => {
				return <Property key={i} label={attr.name} value={attr.value} lineBreaks />
			})}
			{_.size(shipment.addresses) > 0 ? <Property group={t("address.header")} /> : null}
			{_.map(shipment.addresses, (v, i) => {
				let label = t("address.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} address={v} />
			})}
			{_.size(shipment.contactables) > 0 ? <Property group={t("contactable.header")} /> : null}
			{_.map(shipment.contactables, (v, i) => {
				let label = t("contactable.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} relation={EntityType.Contact} value={v.contactID} />
			})}
		</div>
	)
}
