import React from "react"
import _ from "lodash"

import {
	AssemblyDetails,
	ContactableDetails,
	ContactDetails,
	CustomerDetails,
	DocumentDetails,
	JobDetails,
	MaterialDetails,
	ReleaseDetails,
	ReleaseUnitDetails,
	SettingDetails,
	ShipmentDetails,
	TagDetails,
	WorkActionDetails,
	WorkCellDetails,
	WorkCellProgramDetails,
	WorkCellServerDetails,
	WorkDeviceDetails,
	WorkerDetails,
	WorkEventDetails,
	WorkResourceDetails,
} from "@app/components"
import {
	Assembly,
	Contact,
	Contactable,
	ContactUtils,
	Customer,
	Document,
	Entity,
	EntityType,
	Job,
	Material,
	Release,
	ReleaseUnit,
	ReleaseUnitUtils,
	Setting,
	Shipment,
	Tag,
	TagUtils,
	User,
	UserUtils,
	WorkAction,
	WorkCell,
	WorkCellProgram,
	WorkCellServer,
	WorkDevice,
	WorkDeviceUtils,
	Worker,
	WorkEvent,
	WorkResource,
	WorkerUtils,
} from "@app/domain"
import { urlTo, pluralize } from "@app/util"
import { useSession } from "@app/contexts"

export const useEntityDetails = (
	entityType?: EntityType,
	entity?: Entity,
): [id: string, name: string, route: string, details: JSX.Element | null] => {
	let route = _.isNil(entityType) ? "" : urlTo(pluralize(entityType), entity)
	let id = _.get(entity, "id", "")
	let name = _.get(entity, "name", "")
	let details: JSX.Element | null = null
	const { t } = useSession()

	switch (entityType) {
		case EntityType.Assembly:
			details = <AssemblyDetails assembly={entity as Assembly} />
			break
		case EntityType.Contact:
			name = ContactUtils.name(entity as Contact)
			details = <ContactDetails contact={entity as Contact} />
			break
		case EntityType.Contactable:
			route = urlTo(pluralize(_.get(entity, "contactableType", "")))
			id = _.get(entity, "contactableID", "")
			details = <ContactableDetails contactable={entity as Contactable} />
			break
		case EntityType.Customer:
			details = <CustomerDetails customer={entity as Customer} />
			break
		case EntityType.Document:
			details = <DocumentDetails document={entity as Document} />
			break
		case EntityType.Job:
			details = <JobDetails job={entity as Job} />
			break
		case EntityType.Material:
			details = <MaterialDetails material={entity as Material} />
			break
		case EntityType.Release:
			details = <ReleaseDetails release={entity as Release} />
			break
		case EntityType.ReleaseItem:
			name = _.get(entity, "description", "")
			details = null
			break
		case EntityType.ReleaseUnit:
			name = ReleaseUnitUtils.name(entity as ReleaseUnit)
			details = <ReleaseUnitDetails releaseUnit={entity as ReleaseUnit} />
			break
		case EntityType.Setting:
			details = <SettingDetails setting={entity as Setting} />
			break
		case EntityType.Shipment:
			details = <ShipmentDetails shipment={entity as Shipment} />
			break
		case EntityType.Tag:
			name = TagUtils.id(entity as Tag)
			details = <TagDetails tag={entity as Tag} />
			break
		case EntityType.WorkAction:
			details = <WorkActionDetails workAction={entity as WorkAction} />
			break
		case EntityType.Worker:
			name = WorkerUtils.name(entity as Worker)
			details = <WorkerDetails worker={entity as Worker} />
			break
		case EntityType.WorkCell:
			details = <WorkCellDetails workCell={entity as WorkCell} />
			break
		case EntityType.WorkDevice:
			name = WorkDeviceUtils.name(t, entity as WorkDevice)
			details = <WorkDeviceDetails workDevice={entity as WorkDevice} />
			break
		case EntityType.WorkCellProgram:
			details = <WorkCellProgramDetails workCellProgram={entity as WorkCellProgram} />
			break
		case EntityType.WorkCellServer:
			details = <WorkCellServerDetails workCellServer={entity as WorkCellServer} />
			break
		case EntityType.WorkEvent:
			name = _.get(entity, "description", "")
			details = <WorkEventDetails workEvent={entity as WorkEvent} />
			break
		case EntityType.WorkResource:
			details = <WorkResourceDetails workResource={entity as WorkResource} />
			break
		case EntityType.User:
			name = UserUtils.name(entity as User)
			break
	}

	return [id, name, route, details]
}
