import React from "react"
import _ from "lodash"

import { ReportsHeader } from "@app/headers"
import { Tenant } from "@app/domain"
import { PageContainer, WorkCellStatusCard } from "@app/components"
import { GetWorkCellRunHistories, RunHistory } from "@app/api"
import { useQuery } from "@app/hooks"
import { useSession } from "@app/contexts"
import { useInterval } from "usehooks-ts"

export const ReportsWorkCellStatusesPage: React.FC = () => {
	const [runHistory, setRunHistory] = React.useState<{ [name: string]: RunHistory[] }>({})
	const [tenants, setTenants] = React.useState<Tenant[]>([])
	const [now, setNow] = React.useState(new Date())

	useInterval(
		() => {
			setNow(new Date())
		},
		1000 * 60 * 60,
	) // Once an hour.

	const { tenantID: currentTenantID } = useSession()
	const query = useQuery()

	let tenantID = currentTenantID
	if (_.isString(query["tenantID"])) {
		tenantID = parseInt(query["tenantID"]) || currentTenantID
	}

	React.useEffect(() => {
		const load = async () => {
			const resp = await GetWorkCellRunHistories({ tenantID, showAll: true })
			const { runHistory, tenants } = resp.result
			setTenants(_.sortBy(tenants, "name"))
			setRunHistory(_.chain(runHistory).groupBy("workFacilityName").value())
		}
		load()
	}, [tenantID, now])

	return (
		<PageContainer>
			<ReportsHeader view="work-cell-statuses" tenants={tenants} />
			{_.map(_.keys(runHistory).sort(), (name) => {
				return (
					<div key={name}>
						<h1 className="text-lg text-gray-500 mb-2 mt-4">{name}</h1>
						<div className="flex flex-wrap justify-items-start gap-4">
							{_.map(runHistory[name], (rh) => (
								<WorkCellStatusCard key={rh.id} runHistory={rh} />
							))}
						</div>
					</div>
				)
			})}
		</PageContainer>
	)
}
