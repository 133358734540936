import React from "react"

import { Await, PageContainer, WorkCellDetails } from "@app/components"
import { WorkCellHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkCell } from "@app/domain"

export const WorkCellShowPage: React.FC = () => {
	const [workCell, workCellLoading] = useEntity<WorkCell>(EntityType.WorkCell)

	return (
		<PageContainer>
			<WorkCellHeader workCell={workCell} view="show" />
			<Await
				loading={workCellLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkCellDetails workCell={workCell} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
