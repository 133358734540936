import React from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { Link, Icon } from "@app/components"
import { TrackingCode, decodeTrackingCode } from "@app/domain"
import { useHTTPStatus } from "@app/contexts"
import { pluralize } from "@app/util"

export const TrackingCodeShowPublicPage: React.FC = () => {
	const { setHTTPStatus } = useHTTPStatus()
	const base64TrackingCode = _.get(useParams(), "trackingCode", "")
	const trackingCode: TrackingCode | undefined = decodeTrackingCode(
		JSON.parse(window.atob(base64TrackingCode)),
	)
	React.useEffect(() => {
		if (!trackingCode) {
			setHTTPStatus(404)
		}
	})
	const pluralizeIfNeeded = (str: string, i: number) => (i > 1 ? pluralize(str) : str)
	const pill = (str: string) =>
		str && (
			<p className="inline rounded-full text-sm text-gray-700 bg-gray-300 max-w-max px-2">{str}</p>
		)
	return (
		<>
			<Helmet>
				<title>Tracking Code | Toggle OS</title>
			</Helmet>
			<div className="mt-40 space-y-4 flex content-center items-center flex-col w-screen">
				<Icon name="ToggleColor" />
				<div className="rounded bg-gray-200 p-6 pt-4 space-y-4">
					{trackingCode?.action && (
						<div>
							<h2 className=" text-xl text-center font-semibold mb-1">Action</h2>
							<div className="flex items-baseline justify-between gap-x-2">
								<Link to={`/work-actions/${trackingCode?.action?.id}`} className="underline">
									{trackingCode?.action?.description}
								</Link>
								<div className="space-x-1">
									{trackingCode?.action?.type && (
										<>
											{pill(
												{ s: "Start", t: "Timestamp", f: "Finish" }[
													trackingCode?.action?.type[0]
												] ?? "",
											)}
											{trackingCode?.action?.type.includes("a") && pill("Actor Is Subject")}
										</>
									)}
								</div>
							</div>
						</div>
					)}
					{Object.entries({
						Subject: trackingCode?.subjects,
						Location: trackingCode?.locations,
						Actor: trackingCode?.actors,
					}).map(([label, entities], i) => (
						<React.Fragment key={i}>
							{entities?.length && (
								<div>
									<h2 className="text-xl text-center mb-1 font-semibold">
										{_.startCase(pluralizeIfNeeded(label, entities.length))}
									</h2>
									<ul>
										{entities.map((entity, j) => (
											<li key={j} className="flex items-baseline justify-between gap-x-2">
												<Link
													to={`/${_.startCase(pluralize(entity.type))
														.replaceAll(" ", "-")
														.toLowerCase()}/${entity.id}`}
													className="underline"
												>
													{entity.description}
												</Link>
												{pill(_.startCase(entity.type))}
											</li>
										))}
									</ul>
								</div>
							)}
						</React.Fragment>
					))}
				</div>
			</div>
		</>
	)
}
