import _ from "lodash"
import { v4 } from "uuid"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type {
	ReleaseUnit,
	Entity,
	Pagination,
	Job,
	Customer,
	ReleaseItemWorkCellDateQuantityMap,
} from "@app/domain"

export type CreateReleaseUnitsParams = {
	releaseID: string
	releaseItemWorkCellDateQuantityMap: ReleaseItemWorkCellDateQuantityMap
	workCellID?: string
	notes?: string
}

export type CreateReleaseUnitsResult = {
	releaseUnits: ReleaseUnit[]
}

export const CreateReleaseUnits = async (
	params: CreateReleaseUnitsParams,
): Promise<Result<CreateReleaseUnitsResult>> => {
	const data = await rpc("CreateReleaseUnits", params, { minDuration: 300 })
	return ReceivedResult<CreateReleaseUnitsResult>(data)
}

export const newCreateReleaseUnitsParams = (opts?: {
	releaseItemIDs?: string[]
	releaseID?: string
}): CreateReleaseUnitsParams => {
	const scheduled = new Date().toISOString()
	return _.assign(
		{
			releaseID: "",
			releaseItemWorkCellDateQuantityMap: {},
			scheduled,
		},
		opts,
	)
}

export type SaveReleaseUnitParams = {
	id: string
	assemblyID?: string
	customerID?: string
	jobID?: string
	releaseID?: string
	releaseItemID?: string
	tagID?: string
	workCellID?: string
	workFacilityID: string

	buildType?: string
	controlCodes?: string
	description?: string
	scheduled?: string
	status?: string
	unit?: number
	notes?: string
}

export const newSaveReleaseUnitParams = (opts?: ReleaseUnit): SaveReleaseUnitParams => {
	return _.assign(
		{
			id: v4(),
			buildType: "",
			controlCodes: "",
			description: "",
			scheduled: "",
			status: "",
		},
		opts,
	)
}

export type SaveReleaseUnitResult = {
	releaseUnits: ReleaseUnit[]
}

export const SaveReleaseUnit = async (
	params: SaveReleaseUnitParams,
): Promise<Result<SaveReleaseUnitResult>> => {
	const data = await rpc(
		"BatchEditReleaseUnits",
		{ ...params, releaseUnitIDs: [params.id] },
		{ minDuration: 300 },
	)
	return ReceivedResult<SaveReleaseUnitResult>(data)
}

export type GetReleaseUnitsParams = {
	ids?: string[]
	includeIDs?: string[]
	assemblyID?: string
	customerID?: string
	jobID?: string
	tagID?: string
	releaseID?: string
	releaseItemID?: string
	workCellID?: string
	workFacilityID?: string

	includeCustomers?: boolean
	includeJobs?: boolean

	timeBegin?: Date
	timeEnd?: Date
	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetReleaseUnitsResult = {
	releaseUnits: ReleaseUnit[]
	jobs?: Job[]
	customers?: Customer[]
	pagination?: Pagination
}

export const GetReleaseUnits = async (
	params: GetReleaseUnitsParams,
): Promise<Result<GetReleaseUnitsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetReleaseUnits", "releaseUnits", id).then((workResource?: Entity) => {
			return {
				ok: true,
				result: { releaseUnits: _.compact([workResource as ReleaseUnit]) },
			} as Result<GetReleaseUnitsResult>
		}) as Promise<Result<GetReleaseUnitsResult>>
	}
	const data = await rpc("GetReleaseUnits", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetReleaseUnitsResult>(data)
}
