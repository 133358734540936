import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { Material } from "@app/domain"
import { GetMaterials } from "@app/api"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface IMaterialSelectProps {
	autoFocus?: boolean
	defaultValue?: string
	customerID?: string
	jobID?: string
	workFacilityID?: string
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	onChange: (v: OptionType[]) => void
}

export const MaterialSelect: React.FC<IMaterialSelectProps> = (props) => {
	const { handleError } = useSession()
	const {
		autoFocus,
		defaultValue = "",
		customerID = "",
		jobID = "",
		workFacilityID = "",
		onChange,
		disabled,
		name,
		label,
		hint,
		error,
	} = props

	const defaultValues = defaultValue.split(", ")
	const [defaultOptions, setDefaultOptions] = React.useState<OptionType[]>([])
	const [loaded, setLoaded] = React.useState(false)

	React.useEffect(() => {
		const loadDefaults = async () => {
			setDefaultOptions(
				(await Promise.all(defaultValues.map((v) => GetMaterials({ query: v, limit: 1 }))))
					.filter((resp) => resp.ok && resp.result.materials[0])
					.map((resp) => resp.result.materials[0])
					.reduce((p: OptionType[], c) => [...p, { label: c.controlCode, value: c.id }], []),
			)
			setLoaded(true)
		}
		loadDefaults()
	}, [])

	const getMaterials = (inputValue: string) => {
		return new Promise<OptionType[]>((resolve) => {
			GetMaterials({
				customerID,
				jobID,
				workFacilityID,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const materials = _.get(resp, "result.materials", []).map((c: Material) => {
							return {
								label: c.controlCode,
								value: c.id,
							}
						})
						resolve(materials as OptionType[])
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	return (
		<>
			{loaded && (
				<AsyncSelect
					autoFocus={autoFocus}
					cacheOptions={true}
					defaultOptions={true}
					defaultValue={_.isEmpty(defaultValue) ? null : defaultOptions}
					disabled={disabled}
					hint={hint}
					error={error}
					isSearchable={true}
					isMulti={true}
					label={label}
					loadOptions={getMaterials}
					menuPlacement="auto"
					name={name}
					onChange={(v) => {
						onChange(_.compact(v))
					}}
				/>
			)}
		</>
	)
}
