import { ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type {
	Assembly,
	Contact,
	Customer,
	EntityType,
	Job,
	Release,
	Shipment,
	TrashedItem,
} from "@app/domain"

export type TrashItemParams = {
	items: {
		id: string
		entityType: EntityType
	}[]
}

export type TrashedItemsResult = {
	trashedItems: TrashedItem[]
}

export const TrashItems = async (params: TrashItemParams): Promise<Result<TrashedItemsResult>> => {
	const data = await rpc("TrashItems", params)
	return ReceivedResult<TrashedItemsResult>(data)
}

export type UntrashItemsResult = {
	assemblies: Assembly[]
	contacts: Contact[]
	customers: Customer[]
	jobs: Job[]
	releases: Release[]
	shipments: Shipment[]
}

export type UntrashItemParams = {
	ids: string[]
}

export const UntrashItems = async (
	params: UntrashItemParams,
): Promise<Result<UntrashItemsResult>> => {
	const data = await rpc("UntrashItems", params)
	return ReceivedResult<UntrashItemsResult>(data)
}

export type GetTrashedItemsParams = {
	ids?: string[]

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export const GetTrashedItems = async (
	params: GetTrashedItemsParams,
): Promise<Result<TrashedItemsResult>> => {
	const data = await rpc("GetTrashedItems", params)
	return ReceivedResult<TrashedItemsResult>(data)
}
