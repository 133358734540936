import React from "react"
import _ from "lodash"

import {
	Button,
	FormActions,
	Input,
	Toggle,
	WorkCellSelect,
	Select,
	WorkFacilitySelect,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkResourceParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IWorkResourceFormProps {
	busy?: boolean
	defaultParams: SaveWorkResourceParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkResourceParams) => void
}

export const WorkResourceForm: React.FC<IWorkResourceFormProps> = (props) => {
	const { t, addNotification } = useSession()

	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { inputHandler, valueHandler, selectHandler, params } =
		useFormHelpers<SaveWorkResourceParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workFacility.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const workResourceTypes = _.map(En.workResourceTypes, (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultWorkResourceTypeOption = undefined
	const workResourceTypeOptions = workResourceTypes.map((c) => {
		const opt = { value: c.code, label: c.name }
		if (_.get(params, "workResourceType", "") === c.code) {
			defaultWorkResourceTypeOption = opt
		}
		return opt
	})

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<WorkFacilitySelect
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={false}
						error={_.get(errors, "workFacilityID")}
						label={t("workResource.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID", false, (next: SaveWorkResourceParams) => {
							if (_.get(next, "workFacilityID") !== _.get(params, "workFacilityID")) {
								return { ...next, workCellID: undefined }
							}
							return next
						})}
					/>
					<WorkCellSelect
						workFacilityID={_.get(params, "workFacilityID")}
						defaultValue={_.get(params, "workCellID")}
						disabled={disabled || busy}
						error={_.get(errors, "workCellID")}
						label={t("workResource.labels.workCellID")}
						name="workCellID"
						onChange={selectHandler("workCellID", false)}
					/>
					<Select
						defaultValue={defaultWorkResourceTypeOption}
						disabled={disabled || busy}
						isSearchable={true}
						label={t("workResource.labels.workResourceType")}
						name="workResourceType"
						onChange={selectHandler("workResourceType")}
						options={workResourceTypeOptions}
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.name}
						label={t("workResource.labels.name")}
						name="name"
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.description}
						hint={t("workResource.hints.description")}
						label={t("workResource.labels.description")}
						name="description"
						error={_.get(errors, "description")}
						onChange={inputHandler("description")}
						type="text"
					/>

					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.url}
						hint={t("workResource.hints.url")}
						label={t("workResource.labels.url")}
						name="url"
						error={_.get(errors, "url")}
						onChange={inputHandler("url")}
						type="text"
					/>

					<Toggle
						label={t("common.labels.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workResource.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
