import React from "react"
import _ from "lodash"
import { useInterval } from "usehooks-ts"

import { Icon, Property, GLTFViewer } from "@app/components"
import { useSession } from "@app/contexts"
import { entityToBlobURL, entityToFileURL } from "@app/util"

import {
	Assembly,
	ShapeDiverInput,
	ShapeDiverInputType,
	EntityType,
	FileType,
	BlobType,
	AssemblyPendingFileType,
	assemblyPendingFiles,
} from "@app/domain"

interface IAssemblyDetailsProps {
	assembly?: Assembly
}

export const AssemblyDetails: React.FC<IAssemblyDetailsProps> = (props) => {
	const { t, tenantID } = useSession()

	const { assembly } = props

	if (_.isNil(assembly)) {
		return null
	}

	const intervalTiming = 3000
	const maxAttempts = 10

	const metric = _.get(assembly, "metric", false)
	const [modelURL, setModelURL] = React.useState("")
	const [modelAttempt, setModelAttempt] = React.useState(0)
	const [modelError, setModelError] = React.useState(false)
	const [fabPNGURL, setFabPNGURL] = React.useState("")
	const [fabPNGAttempt, setFabPNGAttempt] = React.useState(0)
	const [fabPNGError, setFabPNGError] = React.useState(false)

	const getFabPNGURL = async () => {
		const resp = await fetch(
			entityToFileURL(tenantID, assembly, FileType.AssemblyFabPNG, assembly.updated),
		)
		if (resp.ok) {
			const blob = await resp.blob()
			const fileURL = window.URL.createObjectURL(blob)
			setFabPNGURL(fileURL)
		} else if (fabPNGAttempt > maxAttempts || resp.status !== 408) {
			setFabPNGError(true)
		} else {
			setFabPNGAttempt((p) => p + 1)
		}
	}

	React.useEffect(() => {
		getFabPNGURL()
		return () => {
			if (fabPNGURL) {
				window.URL.revokeObjectURL(fabPNGURL)
			}
		}
	}, [])

	useInterval(getFabPNGURL, fabPNGURL || fabPNGError ? null : intervalTiming)

	const getModel = async () => {
		const resp = await fetch(
			entityToBlobURL(tenantID, assembly, BlobType.AssemblyGLTF, assembly.updated),
		)
		if (resp.ok) {
			const blob = await resp.blob()
			const fileURL = window.URL.createObjectURL(blob)
			setModelURL(fileURL)
		} else if (modelAttempt > maxAttempts || resp.status !== 408) {
			setModelError(true)
		} else {
			setModelAttempt((p) => p + 1)
		}
	}

	React.useEffect(() => {
		getModel()
		return () => {
			if (modelURL) {
				window.URL.revokeObjectURL(modelURL)
			}
		}
	}, [])

	useInterval(getModel, modelURL || modelError ? null : intervalTiming)

	return (
		<div className="flex flex-col-reverse md:flex-row md:h-usable-sm">
			<div className="pt-4 sm:px-2 max-w-lg overflow-scroll min-w-fit">
				<Property label={t("common.labels.referenceID")} id={assembly.referenceID} />
				<Property label={t("assembly.labels.name")} value={assembly.name} />
				<Property
					label={t("assembly.labels.assemblyType")}
					value={t(`assemblyTypes.${assembly.assemblyType}`)}
				/>
				<Property label={t("common.labels.metric")} value={assembly.metric} />
				<Property group={t("common.headers.associations")} />
				<Property
					label={t("assembly.labels.customerID")}
					relation={EntityType.Customer}
					value={assembly.customerID}
				/>
				<Property
					label={t("assembly.labels.jobID")}
					relation={EntityType.Job}
					value={assembly.jobID}
				/>
				{assemblyPendingFiles(assembly) && (
					<>
						<Property group={t("common.headers.filesPending")} />
						<Property
							label={t("fileTypes.AssemblyRobotProgram")}
							value={assembly?.pendingFiles?.[AssemblyPendingFileType?.RobotProgram ?? false]}
						/>
					</>
				)}
				{_.map(assembly.shapeDiverData?.groups, (group) => {
					const details = _.chain(assembly.shapeDiverData?.inputsByGroup[group])
						.map((input: ShapeDiverInput) => {
							const { id, name, args, label, inputType, type } = input
							const value = _.get(assembly.shapeDiverParams, name, 0) as number
							if (inputType === ShapeDiverInputType.Length) {
								return <Property key={id} label={label} length={value} metric={metric} />
							} else if (inputType === ShapeDiverInputType.Select) {
								const { labels, values } = _.reduce(
									args.split(","),
									(result: { labels: string[]; values: string[] }, arg) => {
										if (_.startsWith(arg, "values=")) {
											result.values = (_.last(arg.split("=")) || "").split("|")
										} else if (_.startsWith(arg, "labels=")) {
											result.labels = (_.last(arg.split("=")) || "").split("|")
										}
										return result
									},
									{ labels: [], values: [] },
								)
								const index = values.indexOf(value.toString())
								return <Property key={id} label={label} value={_.get(labels, index, value)} />
							}
							if (type !== "StringList") {
								return <Property key={id} label={label} value={value} />
							}
						})
						.compact()
						.value()
					return (
						<div key={group}>
							{_.isEmpty(group) || _.isEmpty(details) ? null : (
								<Property key={group} group={group} />
							)}
							{details}
						</div>
					)
				})}
			</div>
			<div className="w-full sm:h-screen/3 md:h-full flex flex-col place-content-center place-items-center">
				{fabPNGURL && !fabPNGError ? (
					<img className="h-1/3" src={fabPNGURL} alt="Assembly Preview" />
				) : (
					<div className="progress-bar grid h-full" slot="progress-bar">
						<div className="update-bar m-auto flex flex-col items-center text-gray-600">
							<Icon
								className={"mb-2 " + (fabPNGError ? "text-red-400" : "")}
								name={fabPNGError ? "Exclamation" : "Cog"}
								size="lg"
								spin={!fabPNGError}
								fixedWidth
							/>
							{fabPNGError && (
								<div className="text-sm text-center">
									<p>Unable to load fabrication image.</p>
									<p>If not saved recently, try re-saving the assembly from the Edit tab.</p>
								</div>
							)}
						</div>
					</div>
				)}
				{modelURL && !modelError ? (
					<div className="border border-gray-300 mb-4 h-2/3 w-full">
						<GLTFViewer
							modelURLs={[modelURL]}
							onError={() => {
								setModelError(true)
							}}
							metric={metric}
						/>
					</div>
				) : (
					<div className="progress-bar grid h-full" slot="progress-bar">
						<div className="update-bar m-auto flex flex-col items-center text-gray-600">
							<Icon
								className={"mb-2 " + (modelError ? "text-red-400" : "")}
								name={modelError ? "Exclamation" : "Cog"}
								size="lg"
								spin={!modelError}
								fixedWidth
							/>
							{modelError && (
								<div className="text-sm text-center">
									<p>Unable to load assembly preview.</p>
									<p>If not saved recently, try re-saving the assembly from the Edit tab.</p>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
