import { v4 as uuidv4 } from "uuid"

import type { Attribute } from "@app/domain"

export type ReleaseItem = {
	id: string
	assemblyID?: string
	customerID: string
	jobID: string
	releaseID: string

	attributes: Attribute[]
	controlCodes: string
	description: string
	quantity: number
	sortOrder: number
	weight?: number

	created: string
	updated: string
}

export const newReleaseItem = (): ReleaseItem => {
	return {
		id: uuidv4(),
		assemblyID: undefined,
		customerID: "",
		jobID: "",
		releaseID: "",

		attributes: [],
		controlCodes: "",
		description: "",
		quantity: 1,
		sortOrder: Date.now(),
		weight: undefined,

		created: "",
		updated: "",
	}
}
