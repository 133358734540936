import { v4 as uuidv4 } from "uuid"
import _ from "lodash"

export type Material = {
	id: string
	customerID: string
	jobID: string
	workFacilityID: string

	controlCode: string
	received: string | null
	items: MaterialItem[]
	metric: boolean
	status: string
	supplierName: string
	supplierReferenceID: string

	created: string
	updated: string
}

export type MaterialItem = {
	id: string
	rebarMaterialItem: RebarMaterialItem | null | undefined
}

export type RebarMaterialItem = {
	length: string
	mark: string
	quantity?: number
	shape: string
	size: number
	weight?: number

	A: string
	B: string
	C: string
	D: string
	E: string
	F: string
	G: string
	H: string
	J: string
	K: string
	O: string
	R: string
}

export const RebarMaterialItemBendFields = [
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"J",
	"K",
	"O",
	"R",
]

export const newMaterial = (opts?: { [key: string]: string | boolean | number }): Material => {
	return _.assign(
		{
			id: "",
			customerID: "",
			jobID: "",
			workFacilityID: "",

			controlCode: "",
			items: [],
			received: "",
			metric: false,
			status: "Available",
			supplierName: "",
			supplierReferenceID: "",

			created: "",
			updated: "",
		},
		opts,
	)
}

export const newMaterialItem = (): MaterialItem => {
	return {
		id: uuidv4(),

		rebarMaterialItem: null,
	}
}
