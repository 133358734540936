import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import {
	Badge,
	EntityBreadcrumbs,
	DateTime,
	Icon,
	Tabs,
	Title,
	HeaderDownloadButton,
} from "@app/components"
import { urlTo } from "@app/util"
import { Tag, TagUtils, EntityType, AlertLevel, Release } from "@app/domain"
import { useTrash, useRedirect } from "@app/hooks"
import { SaveTagLock } from "@app/api"

interface ITagHeaderProps {
	release?: Release
	section?: string
	tag?: Tag
	setTag?: (tag: Tag) => void
	view: string
}

export const TagHeader: React.FC<ITagHeaderProps> = (props) => {
	const { release, tag, view, section = "" } = props
	const { id, name, locked, created, updated } = _.assign(
		{
			id: "",
			created: "",
			locked: false,
			name: TagUtils.id(tag) || "...",
			referenceID: "",
			updated: "",
		},
		tag,
	)

	const { t, addNotification, tenantID } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Tag, setRedirect)

	const optionHandler = async (value: string) => {
		switch (value) {
			case "delete": {
				trashHandler(id, { name })
				break
			}
			case "flipLockState": {
				const resp = await SaveTagLock({ id, locked: !locked })
				if (resp.ok) {
					if (props.setTag) {
						props.setTag(resp.result.tags[0])
					}
					addNotification({
						alertLevel: AlertLevel.Success,
						title: t("tag.notifications.success.updated", {
							name: TagUtils.id(resp.result.tags[0]),
						}),
					})
				} else {
					addNotification({
						alertLevel: AlertLevel.Warn,
						title: t("tag.notifications.failure.saving"),
						subtitle: t("common.errors.tryAgain"),
					})
				}
				break
			}
		}
	}

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("tags/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("tags/", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("tags/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.releaseUnits"),
			to: urlTo("tags/releaseUnits", id),
			active: view === "releaseUnits",
		},
		{
			label: (
				<span>
					<Icon className="mr-1" size="sm" fixedWidth name={locked ? "Lock" : "LockOpen"} />
					{t("common.tabs.edit")}
				</span>
			),
			to: urlTo("tags/edit", id),
			active: view === "edit",
		},
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<HeaderDownloadButton
							url={TagUtils.pdfURL(tag, tenantID)}
							label={t("tag.buttons.download")}
						/>
					),
				},
				{
					label: (
						<span key="download">
							<Icon className="mr-2" name={locked ? "LockOpen" : "Lock"} fixedWidth />
							{locked ? t("tag.buttons.unlock") : t("tag.buttons.lock")}
						</span>
					),
					value: "flipLockState",
				},
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("tag.buttons.delete")}
						</span>
					),
					value: "delete",
					disabled: locked,
				},
			],
			onOptionClick: optionHandler,
		},
	]

	let title
	if (view === "create") {
		title = t("tag.titles.create")
	} else if (view === "edit") {
		title = t("tag.titles.edit", { name })
	} else {
		title = t("tag.titles.show", { name })
	}

	if (redirect) {
		return redirect()
	}

	return (
		<>
			<Title>{title}</Title>

			{view === "create" ? (
				<EntityBreadcrumbs entity={release} entityType={EntityType.Release} view={"tags/create"} />
			) : (
				<EntityBreadcrumbs entity={tag} entityType={EntityType.Tag} view={view} />
			)}

			{view !== "create" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
