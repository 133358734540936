import { rpc } from "@app/api"
import type { Result } from "@app/api"
import type { EntityType, File, FileType } from "@app/domain"

export type SaveFileParams = {
	fileableID?: string
	fileableType: EntityType

	data: string
	name: string
	type: FileType
}

export type SaveFileResult = {
	files: File[]
}

export const SaveFile = async (params: SaveFileParams): Promise<Result<SaveFileResult>> => {
	const data = await rpc("SaveFile", { ...params })
	return data as Result<SaveFileResult>
}

export type GetFilesParams = {
	ids: string[]
}

export type GetFilesResult = {
	files: File[]
}

export const GetFiles = async (params: GetFilesParams): Promise<Result<GetFilesResult>> => {
	const data = await rpc("GetFiles", { ...params })
	return data as Result<GetFilesResult>
}

export type GetFilesByFileableIDAndTypeParams = {
	fileableID: string
	fileType: string
}

export const GetFilesByFileableIDAndType = async (
	params: GetFilesByFileableIDAndTypeParams,
): Promise<Result<GetFilesResult>> => {
	const data = await rpc("GetFilesByFileableIDAndType", { ...params })
	return data as Result<GetFilesResult>
}
