import React from "react"
import _ from "lodash"

import { Link, Icon } from "@app/components"

export type TabOption = {
	disabled?: boolean
	label: string | React.ReactNode
	to?: string
	download?: string
	href?: string
	value?: string
	options?: TabOption[]
	onOptionClick?: (value: string) => void
}

export type SubTab = {
	active?: boolean
	label: string | React.ReactNode
	to?: string
}

export type Tab = {
	active?: boolean
	label: string | React.ReactNode
	onOptionClick?: (value: string) => void
	options?: TabOption[]
	subTabs?: SubTab[]
	to?: string
	disabled?: boolean
}

interface ITabsProps {
	tabs: Tab[]
	className?: string
}

const Tab: React.FC<Tab> = (props) => {
	const { label, onOptionClick, to, options, active = false, disabled = false } = props

	const node = React.useRef<HTMLDivElement>(null)
	const [open, setOpen] = React.useState(false)

	const handleClickOutside = (e: MouseEvent) => {
		if (node?.current?.contains(e.target as Node)) {
			return
		}
		setOpen(false)
	}

	React.useEffect(() => {
		if (open) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [open])

	let className =
		" inline-block px-3 pt-2.5 pb-2 font-semibold tracking-tight text-sm focus:outline-none "

	className += active
		? "cursor-default text-black bg-yellow-500"
		: disabled
		? "cursor-default text-gray-500 bg-gray-300"
		: "cursor-pointer text-gray-800 bg-gray-300 hover:text-black hover:bg-yellow-300"

	const optionList = (opts: TabOption[]) => {
		return (
			<>
				{opts.map((o, i) => (
					<li
						key={i}
						className={`cursor-pointer py-2 pr-4 text-sm leading-5 flex justify-between items-center focus:outline-none ${
							o.disabled ? "text-gray-500" : "hover:bg-yellow-300 hover:text-black"
						}`}
						role="menuitem"
						onClick={(e) => {
							e.stopPropagation()
							if (o.disabled || o.to || o.href) {
								return
							}
							if (o.onOptionClick) {
								o.onOptionClick(o.value || "")
							} else if (onOptionClick) {
								onOptionClick(o.value || "")
							}
						}}
					>
						{o.options && (
							<ul
								className="absolute translate-x-full -translate-y-2 bg-white shadow-xl right-0 hover-item self-start"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="options-sub-menu"
							>
								{optionList(o.options)}
							</ul>
						)}
						{o.href && !o.disabled ? (
							<a
								className="block px-4"
								target="_blank"
								rel="noreferrer"
								href={o.href}
								download={o.download}
							>
								{o.label}
							</a>
						) : o.to && !o.disabled ? (
							<Link className="block px-4" target="_blank" to={o.to}>
								{o.label}
							</Link>
						) : (
							<span className="pl-4">{o.label}</span>
						)}
						{o.options && <Icon className="align-self-center " name="ChevronRight" />}
					</li>
				))}
			</>
		)
	}

	if (options) {
		return (
			<div className="relative whitespace-nowrap" ref={node} onClick={() => setOpen(!open)}>
				<span className={className}>
					{label}&nbsp;
					<Icon name="ChevronDown" size="xs" fixedWidth />
				</span>
				<div
					className={`${
						open ? "absolute" : "hidden"
					} z-20 origin-top-right left-0 w-56 rounded-md shadow-lg`}
				>
					<div className="bg-white shadow-xs">
						<ul
							className="py-1"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							{optionList(options)}
						</ul>
					</div>
				</div>
			</div>
		)
	}

	if (to && !disabled) {
		return (
			<Link className={className} to={to || ""}>
				{label}
			</Link>
		)
	} else {
		return (
			<div
				className={className}
				onClick={() => {
					if (active || disabled) {
						return
					}
					if (onOptionClick && typeof label === "string") {
						onOptionClick(label)
					} else if (onOptionClick && typeof label !== "string") {
						onOptionClick("")
					}
				}}
			>
				{label}
			</div>
		)
	}
}

export const Tabs: React.FC<ITabsProps> = (props) => {
	const { tabs, className } = props
	const activeTab = _.find(tabs, "active") || { subTabs: [] }

	return (
		<nav id="tabs" className={"-mx-2 " + className}>
			<div
				className={"flex flex-row border-b border-yellow-500 gap-x-2 pl-2"}
				style={{ borderBottomWidth: "4px" }}
			>
				{tabs.map((tab, index) => (
					<Tab key={index} {...tab} />
				))}
			</div>
			{_.isEmpty(activeTab.subTabs) ? null : (
				<div className="bg-yellow-500 pt-1 px-2 w-full flex flex-row gap-x-2">
					{_.map(activeTab.subTabs, (subTab, i) => {
						let className = "text-sm font-semibold tracking-tight px-3 pb-2 pt-2  "
						className += subTab.active
							? "cursor-default text-black bg-white"
							: "cursor-pointer text-gray-800 bg-yellow-500 hover:bg-yellow-300"
						return (
							<Link key={i} className={className} to={subTab.to || ""}>
								{subTab.label}
							</Link>
						)
					})}
				</div>
			)}
		</nav>
	)
}
