export type Setting = {
	id: string

	group: string
	name: string
	value: string

	created: string
	updated: string
}

export const newSetting = (): Setting => {
	return {
		id: "",

		group: "general",
		name: "",
		value: "",

		created: "",
		updated: "",
	}
}
