import _ from "lodash"

import { rpc, Batch, Cache, compactIDs } from "@app/api"

import type { Result } from "@app/api"
import type { Worker, Pagination, Entity } from "@app/domain"

export type SaveWorkerParams = {
	id?: string

	disabled: boolean
	firstName: string
	lastName: string
}

export const newSaveWorkerParams = (user?: Worker): SaveWorkerParams => {
	return {
		id: _.get(user, "id", ""),

		disabled: _.get(user, "disabled", false),
		firstName: _.get(user, "firstName", ""),
		lastName: _.get(user, "lastName", ""),
	}
}

export type SaveWorkerResult = {
	workers: Worker[]
}

export const SaveWorker = async (params: SaveWorkerParams): Promise<Result<SaveWorkerResult>> => {
	const data = await rpc("SaveWorker", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveWorkerResult>
}

export type GetWorkersParams = {
	ids?: string[]
	includeIDs?: string[]

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkersResult = {
	workers: Worker[]
	pagination?: Pagination
}

export const GetWorkers = async (params: GetWorkersParams): Promise<Result<GetWorkersResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkers", "workers", id).then((worker?: Entity) => {
			return {
				ok: true,
				result: { workers: _.compact([worker as Worker]) },
			} as Result<GetWorkersResult>
		}) as Promise<Result<GetWorkersResult>>
	}
	const data = await rpc("GetWorkers", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetWorkersResult>
}
