import _ from "lodash"

import { AddressParams, Batch, compactIDs, ContactableParams, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkFacility, Entity, Pagination } from "@app/domain"

export type SaveWorkFacilityParams = {
	id?: string

	addresses: AddressParams[]
	description: string
	disabled: boolean
	name: string
	timezone: string

	contactables: ContactableParams[]
	removeContactables: string[]
}

export const newSaveWorkFacilityParams = (workFacility?: WorkFacility): SaveWorkFacilityParams => {
	return {
		id: _.get(workFacility, "id", ""),

		addresses: _.get(workFacility, "addresses", []),
		description: _.get(workFacility, "description", ""),
		disabled: _.get(workFacility, "disabled", false),
		name: _.get(workFacility, "name", ""),
		timezone: _.get(workFacility, "timezone", ""),

		contactables: _.get(workFacility, "contactables", []),
		removeContactables: [],
	}
}

export type SaveWorkFacilityResult = {
	workFacilities: WorkFacility[]
}

export const SaveWorkFacility = async (
	params: SaveWorkFacilityParams,
): Promise<Result<SaveWorkFacilityResult>> => {
	const data = await rpc("SaveWorkFacility", params, { minDuration: 300 })
	return ReceivedResult<GetWorkFacilitiesResult>(data)
}

export type GetWorkFacilitiesParams = {
	ids?: string[]
	includeIDs?: string[]

	contactID?: string

	query?: string
	withoutCount?: boolean
	withoutContactables?: boolean

	limit?: number
	offset?: number
}

export type GetWorkFacilitiesResult = {
	workFacilities: WorkFacility[]
	pagination?: Pagination
}

export const GetWorkFacilities = async (
	params: GetWorkFacilitiesParams,
): Promise<Result<GetWorkFacilitiesResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkFacilities", "workFacilities", id).then((workFacility?: Entity) => {
			return {
				ok: true,
				result: { workFacilities: _.compact([workFacility as WorkFacility]) },
			} as Result<GetWorkFacilitiesResult>
		}) as Promise<Result<GetWorkFacilitiesResult>>
	}
	const data = await rpc("GetWorkFacilities", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkFacilitiesResult>(data)
}

export type GetWorkFacilityProductionDashboardParams = {
	id: string
	tenantID: number
}

export type WorkFacilityProductionDashboardReleaseUnit = {
	id: string

	buildType: string
	controlCodes: string
	description: string
	finished?: string
	notes?: string
	referenceID: string
	scheduled?: string
	sortOrder: number
	started?: string
	status: string
	unit?: number

	workTrackingStates: {
		id: string

		started?: string
		finished?: string
		paramsStartID?: string
		paramsFinishID?: string
		worker: {
			name: string
		}
		workAction: {
			name: string
		}
	}[]

	customer: { name: string }
	job: { name: string }
	release: { name: string }
	tag: { referenceID: string }
	workCell: { id: string; name: string }
}

export type GetWorkFacilityProductionDashboardResult = {
	workFacilityName: string
	releaseUnits: WorkFacilityProductionDashboardReleaseUnit[]
}

export const GetWorkFacilityProductionDashboard = async (
	params: GetWorkFacilityProductionDashboardParams,
): Promise<Result<GetWorkFacilityProductionDashboardResult>> => {
	const data = await rpc("GetWorkFacilityProductionDashboard", params)
	return data as Result<GetWorkFacilityProductionDashboardResult>
}
