import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType, Job } from "@app/domain"

interface IJobDetailsProps {
	job?: Job
}

export const JobDetails: React.FC<IJobDetailsProps> = (props) => {
	const { t } = useSession()

	const { job } = props

	if (_.isNil(job)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("common.labels.referenceID")} id={job.referenceID} />
			<Property label={t("job.labels.name")} value={job.name} />
			<Property label={t("job.labels.countryOfOrigin")} value={job.countryOfOrigin} />
			<Property label={t("common.labels.metric")} value={job.metric} />
			<Property group={t("common.headers.associations")} />
			<Property
				label={t("job.labels.customerID")}
				relation={EntityType.Customer}
				value={job.customerID}
			/>
			{_.map(job.workFacilityIDs, (v) => (
				<Property
					key={v}
					label={t("job.labels.workFacilityID")}
					relation={EntityType.WorkFacility}
					value={v}
				/>
			))}
			{_.size(job.attributes) > 0 ? <Property group={t("attribute.header")} /> : null}
			{_.map(job.attributes, (tag, i) => {
				return <Property key={i} label={tag.name} value={tag.value} lineBreaks />
			})}
			{_.size(job.addresses) > 0 ? <Property group={t("address.header")} /> : null}
			{_.map(job.addresses, (v, i) => {
				let label = t("address.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} address={v} />
			})}
			{_.size(job.contactables) > 0 ? <Property group={t("contactable.header")} /> : null}
			{_.map(job.contactables, (v, i) => {
				let label = t("contactable.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} relation={EntityType.Contact} value={v.contactID} />
			})}
		</div>
	)
}
