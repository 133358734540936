import React from "react"
import _ from "lodash"
import { useHistory } from "react-router-dom"

import { Await, Button, Icon, InputSearch, Link, Paginator, Property } from "@app/components"
import { GetContacts, GetContactsParams } from "@app/api"
import { useSession } from "@app/contexts"
import { urlTo, fromUUID } from "@app/util"
import { usePagination } from "@app/hooks"
import { EntityType, ContactUtils } from "@app/domain"

import type { Email, PhoneNumber, Contact, Pagination } from "@app/domain"

interface IContactListingProps {
	parentType?: EntityType
	parentID?: string
}

export const ContactListing: React.FC<IContactListingProps> = (props) => {
	const { parentType, parentID } = props

	const { t, handleError } = useSession()
	const history = useHistory()

	const [loading, setLoading] = React.useState<boolean>(true)
	const [contacts, setContacts] = React.useState<Contact[]>([])
	const [pagination, setPagination] = React.useState<Pagination | undefined>()
	const [query, setQuery] = React.useState<string>("")

	const { offset, limit } = usePagination(10)

	const results = t("contact.name", _.size(contacts))

	const newParams: { [key: string]: string } = {}
	if (parentType && parentID) {
		switch (parentType) {
			case EntityType.Job:
				newParams.job = fromUUID(parentID)
				break
			case EntityType.Customer:
				newParams.customer = fromUUID(parentID)
				break
			case EntityType.Release:
				newParams.release = fromUUID(parentID)
				break
			case EntityType.Shipment:
				newParams.shipment = fromUUID(parentID)
				break
		}
	}

	React.useEffect(() => {
		let canceled = false
		const getContacts = async () => {
			setLoading(true)
			const params: GetContactsParams = { query, offset: _.isEmpty(query) ? offset : 0, limit }
			switch (parentType) {
				case EntityType.Customer:
					params.customerID = parentID
					break
				case EntityType.Job:
					params.jobID = parentID
					break
				case EntityType.Release:
					params.releaseID = parentID
					break
				case EntityType.Shipment:
					params.shipmentID = parentID
					break
				case EntityType.WorkFacility:
					params.workFacilityID = parentID
					break
			}
			try {
				const resp = await GetContacts(params)
				if (canceled) {
					return
				}
				if (resp.ok) {
					setContacts(_.get(resp, "result.contacts", []))
					setPagination(_.get(resp, "result.pagination"))
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		getContacts()
		return () => {
			canceled = true
		}
	}, [offset, limit, query])

	return (
		<Await
			loading={loading}
			then={() => (
				<>
					<Paginator
						sibling={
							<div className="flex gap-1 mr-1 items-center">
								<Button
									add={true}
									to={urlTo("contacts/new", undefined, newParams)}
									label={t("contact.buttons.new")}
								/>
								<div className="flex-grow-0">
									<InputSearch
										onInputChange={(input: string) => {
											setQuery(input)
										}}
										busy={loading}
										disabled={_.isEmpty(contacts)}
									/>
								</div>
							</div>
						}
						pagination={pagination}
						results={results}
					/>
					<ul className="border-t border-gray-400 -mx-2">
						{contacts.map((c) => {
							return (
								<li key={c.id} className="border-b border-gray-400">
									<div
										onClick={(e) => {
											e.preventDefault()
											history.push(urlTo("contacts", c))
											return false
										}}
										className="block cursor-pointer hover:bg-yellow-300 focus:outline-none focus:bg-gray-300 transition duration-150 ease-in-out"
									>
										<div className="flex items-center px-2 py-3">
											<div className="flex-1 flex items-center">
												<div className="flex-1 space-y-2 md:space-y-0 md:grid md:grid-cols-4 md:gap-4">
													<div>
														<div className="text-gray-500 text-sm leading-5">
															{t("contact.labels.name")}
														</div>
														<div className="mt-1 text-lg leading-5 font-medium">
															{ContactUtils.name(c)}
														</div>
													</div>
													<div>
														<div className="text-gray-500 text-sm leading-5">
															{t("contact.labels.emails")}
														</div>
														<div className="mt-1 text-sm leading-5 font-medium">
															{_.size(c.emails) === 0 ? <Property /> : null}
															{_.map(c.emails, (e: Email, i: number) => {
																return (
																	<div key={i}>
																		<Link to={`mailto:${e.email}`}>{e.email}</Link>
																	</div>
																)
															})}
														</div>
													</div>
													<div>
														<div className="text-gray-500 text-sm leading-5">
															{t("contact.labels.phoneNumbers")}
														</div>
														<div className="mt-1 text-sm leading-5 font-medium">
															{_.size(c.phoneNumbers) === 0 ? <Property /> : null}
															{_.map(c.phoneNumbers, (p: PhoneNumber, i: number) => {
																return (
																	<div key={i}>
																		<Link to={`tel:${p.phoneNumber}`}>{p.phoneNumber}</Link>
																	</div>
																)
															})}
														</div>
													</div>
												</div>
											</div>
											<div>
												<Icon className="rounded" name="ChevronRight" size="1x" />
											</div>
										</div>
									</div>
								</li>
							)
						})}
					</ul>

					<Paginator pagination={pagination} results={results} />
				</>
			)}
		/>
	)
}
