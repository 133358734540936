import React from "react"
import _ from "lodash"

import { Property, Link, TagPreview } from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType, Tag, TagUtils } from "@app/domain"
import { urlTo } from "@app/util"

interface ITagDetailsProps {
	tag?: Tag
}

export const TagDetails: React.FC<ITagDetailsProps> = (props) => {
	const { t } = useSession()

	const { tag } = props

	if (_.isNil(tag)) {
		return null
	}

	return (
		<div className="flex flex-col md:flex-row md:gap-x-8">
			<div className="pt-1 px-2 max-w-lg">
				<Property label={t("common.labels.referenceID")} id={tag.referenceID} />
				<Property
					label={t("tag.labels.publicURL")}
					url={TagUtils.url(tag, 1, {})}
					value={TagUtils.url(tag, 1, {})}
				/>
				<Property label={t("tag.labels.color")} color={tag.color} />
				<Property label={t("tag.labels.controlCodes")} value={tag.controlCodes} />
				<Property label={t("tag.labels.description")} value={tag.description} />
				<Property label={t("tag.labels.locked")} value={tag.locked} />
				<Property label={t("tag.labels.quantity")} value={tag.quantity} />
				<Property label={t("tag.labels.unitStart")} value={tag.unitStart} />
				<Property label={t("tag.labels.unitEnd")} value={tag.unitEnd} />
				<Property label={t("common.labels.metric")} value={tag.metric} />
				<Property label={t("tag.labels.weight")} weight={tag.weight} metric={tag.metric} />
				<Property group={t("common.headers.associations")} />
				<Property
					label={t("tag.labels.customerID")}
					value={tag.customerID}
					relation={EntityType.Customer}
				/>
				<Property label={t("tag.labels.jobID")} value={tag.jobID} relation={EntityType.Job} />
				<Property
					label={t("tag.labels.releaseID")}
					value={tag.releaseID}
					relation={EntityType.Release}
				/>
				<Property
					label={t("tag.labels.assemblyID")}
					value={tag.assemblyID}
					relation={EntityType.Assembly}
				/>
				<Property group={t("shipment.titles.list")} />
				{_.map(tag.shipments, (v) => {
					return (
						<div key={v.id}>
							<Link styled to={urlTo("shipments", v)}>{`${v.name} (${v.referenceID})`}</Link>
						</div>
					)
				})}
				{_.size(tag.attributes) > 0 ? <Property group={t("attribute.header")} /> : null}
				{_.map(tag.attributes, (tag, i) => {
					return <Property key={i} label={tag.name} value={tag.value} lineBreaks />
				})}
				{_.size(tag.templateFields) > 0 ? <Property group={t("templateField.header")} /> : null}
				{_.map(tag.templateFields, (tag, i) => {
					return <Property key={i} label={tag.label} value={tag.value} lineBreaks />
				})}
			</div>
			<div className="hidden md:block">
				<TagPreview tag={tag} />
			</div>
		</div>
	)
}
