import React from "react"
import _ from "lodash"

import {
	Button,
	FormActions,
	Input,
	Toggle,
	WorkCellSelect,
	TextField,
	WorkFacilitySelect,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkCellServerParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

interface IWorkCellServerFormProps {
	busy?: boolean
	defaultParams: SaveWorkCellServerParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkCellServerParams) => void
}

export const WorkCellServerForm: React.FC<IWorkCellServerFormProps> = (props) => {
	const { t, addNotification } = useSession()

	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { inputHandler, valueHandler, selectHandler, params } =
		useFormHelpers<SaveWorkCellServerParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workFacility.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<WorkFacilitySelect
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={false}
						error={_.get(errors, "workFacilityID")}
						label={t("workCellServer.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID", false, (next: SaveWorkCellServerParams) => {
							if (_.get(next, "workFacilityID") !== _.get(params, "workFacilityID")) {
								return { ...next, workCellID: undefined }
							}
							return next
						})}
					/>
					<WorkCellSelect
						workFacilityID={_.get(params, "workFacilityID")}
						defaultValue={_.get(params, "workCellID")}
						disabled={disabled || busy}
						error={_.get(errors, "workCellID")}
						label={t("workCellServer.labels.workCellID")}
						name="workCellID"
						onChange={selectHandler("workCellID", false)}
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.name}
						label={t("workCellServer.labels.name")}
						name="name"
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						hint={t("workCellServer.hints.name")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.description}
						hint={t("workCellServer.hints.description")}
						label={t("workCellServer.labels.description")}
						name="description"
						error={_.get(errors, "description")}
						onChange={inputHandler("description")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.ipAddress}
						hint={t("workCellServer.hints.ipAddress")}
						placeholder={t("workCellServer.placeholders.ipAddress")}
						label={t("workCellServer.labels.ipAddress")}
						name="ipAddress"
						error={_.get(errors, "ipAddress")}
						onChange={inputHandler("ipAddress")}
						type="text"
					/>
					<Toggle
						label={t("common.labels.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
					{!_.isEmpty(params.id) ? (
						<>
							<Input
								readOnly={true}
								disabled={disabled || busy}
								value={params.id}
								hint={t("workCellServer.hints.id")}
								label={t("workCellServer.labels.id")}
								name="id"
								type="text"
							/>
							<Input
								readOnly={true}
								disabled={disabled || busy}
								value={params.secret}
								hint={t("workCellServer.hints.secret")}
								label={t("workCellServer.labels.secret")}
								name="secret"
								type="text"
							/>
							<Toggle
								label={t("workCellServer.labels.newSecret")}
								defaultChecked={params.newSecret}
								disabled={disabled || busy}
								onChange={valueHandler("newSecret")}
							/>
							<TextField
								onFocus={(evt) => {
									evt.target.select()
								}}
								name="configString"
								label={t("workCellServer.labels.configString")}
								hint={t("workCellServer.hints.configString")}
								value={params.configString}
								readOnly
							/>
						</>
					) : null}
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workCellServer.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
