import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { Release, ReleaseUnit, EntityType, ReleaseUnitUtils } from "@app/domain"

interface IReleaseUnitHeaderProps {
	releaseUnit?: ReleaseUnit
	release?: Release
	section?: string
	view: string
}

export const ReleaseUnitHeader: React.FC<IReleaseUnitHeaderProps> = (props) => {
	const { releaseUnit, view, section = "", release } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: ReleaseUnitUtils.name(releaseUnit) || "...", created: "", updated: "" },
		releaseUnit,
	)

	const { t } = useSession()

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("releaseUnits/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("releaseUnits", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("releaseUnits/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("releaseUnits/edit", id), active: view === "edit" },
	]

	let title
	if (view === "new") {
		title = t("releaseUnit.titles.new")
	} else if (view === "create") {
		title = t("releaseUnit.titles.create")
	} else if (view === "edit") {
		title = t("releaseUnit.titles.edit", { name })
	} else {
		title = t("releaseUnit.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			{view === "create" ? (
				<EntityBreadcrumbs
					entity={release}
					entityType={EntityType.Release}
					view={"releaseUnits/create"}
				/>
			) : (
				<EntityBreadcrumbs entity={releaseUnit} entityType={EntityType.ReleaseUnit} view={view} />
			)}

			{!["new"].includes(view) ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
