import React from "react"
import { Helmet } from "react-helmet-async"

interface ITitleProps {
	children: string
}

export const Title: React.FC<ITitleProps> = (props) => {
	const { children } = props
	return (
		<Helmet>
			<title>{`${children} | Toggle OS`}</title>
		</Helmet>
	)
}
