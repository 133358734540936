import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import {
	Badge,
	BreadcrumbDelimiter,
	Breadcrumbs,
	DateTime,
	Icon,
	Link,
	Tabs,
	Title,
} from "@app/components"
import { urlTo } from "@app/util"
import { Setting, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface ISettingHeaderProps {
	setting?: Setting
	section?: string
	view: string
}

export const SettingHeader: React.FC<ISettingHeaderProps> = (props) => {
	const { setting, view, section = "" } = props
	const { id, name, group, created, updated } = _.assign(
		{ id: "", group: "", name: "...", created: "", updated: "" },
		setting,
	)

	const { t } = useSession()
	const groupName = _.isEmpty(group) ? "" : t(`setting.groupOptions.${group}`)
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Setting, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("settings/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("settings", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("settings/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("settings/edit", id), active: view === "edit" },

		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("setting.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { name }),
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("setting.titles.new")
	} else if (view === "edit") {
		title = t("setting.titles.edit", { name })
	} else {
		title = t("setting.titles.show", { name })
	}

	const header = setting ? `${groupName}/${name}` : "..."

	return (
		<>
			<Title>{title}</Title>

			<Breadcrumbs>
				<Link to={urlTo("settings")}>{t("setting.titles.list")}</Link>
				{view !== "new" ? (
					<>
						<BreadcrumbDelimiter />
						<Link to={urlTo("settings/show", id)}>{header}</Link>
					</>
				) : null}
				<BreadcrumbDelimiter />
				{t(`common.tabs.${view}`)}
			</Breadcrumbs>

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{header}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
