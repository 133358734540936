import React from "react"

import { EntityType } from "@app/domain"
import { EntityTable, PageContainer } from "@app/components"
import { ReportsHeader } from "@app/headers"

export const ReportsWorkTrackingTablePage: React.FC = () => {
	return (
		<PageContainer>
			<ReportsHeader view="work-tracking-table" />
			<EntityTable
				entityType={EntityType.WorkTrackingState}
				fullHeight
				unclickable
				unsearchable
				downloadable
				refreshable
				autoUpdateMinutes={60}
				filterableEntityTypes={new Set([EntityType.WorkFacility, EntityType.WorkCell])}
			/>
		</PageContainer>
	)
}
