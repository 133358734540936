import React from "react"
import _ from "lodash"

import { Button, FormActions, Input } from "@app/components"
import { useSession } from "@app/contexts"
import { ChangeAccountPasswordParams } from "@app/api"
import { AlertLevel } from "@app/domain"

interface IAccountPasswordFormProps {
	busy?: boolean
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: ChangeAccountPasswordParams) => void
}

export const AccountPasswordForm: React.FC<IAccountPasswordFormProps> = (props) => {
	const { busy = false, disabled = false, onSave, errors } = props
	const { t, addNotification } = useSession()

	const [params, setParams] = React.useState<ChangeAccountPasswordParams>({
		currentPassword: "",
		newPassword: "",
	})

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("account.notifications.failure.changePassword", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const inputHandler = (key: string): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			setParams((params) => _.set({ ...params }, key, target.value))
		}
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						autoFocus={true}
						defaultValue={params.currentPassword}
						disabled={disabled || busy}
						error={_.get(errors, "currentPassword")}
						label={t("account.labels.currentPassword")}
						name="name"
						onChange={inputHandler("currentPassword")}
						type="password"
					/>
					<Input
						autoComplete="off"
						defaultValue={params.newPassword}
						disabled={disabled || busy}
						error={_.get(errors, "newPassword")}
						hint={t("account.hints.newPassword")}
						label={t("account.labels.newPassword")}
						name="name"
						onChange={inputHandler("newPassword")}
						type="password"
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("account.buttons.changePassword")}
					errors={errors}
					busy={busy}
					disabled={disabled}
				/>
			</FormActions>
		</form>
	)
}
