import _ from "lodash"

let context: AudioContext | undefined

const play = (freq: number, duration: number) => {
	if (_.isNil(context)) {
		context = new AudioContext()
	}
	const oscillator = context.createOscillator()
	const gain = context.createGain()
	oscillator.connect(gain)
	oscillator.type = "sine"
	gain.connect(context.destination)
	oscillator.frequency.value = freq
	gain.gain.exponentialRampToValueAtTime(0.00001, context.currentTime + duration)
	oscillator.start(context.currentTime)
	oscillator.stop(context.currentTime + duration)
}

const vibrate = (pattern: number | number[]) => {
	if (window.navigator.vibrate) {
		window.navigator.vibrate(pattern)
	}
}

type Feedback = {
	confirm: () => void
	scanned: () => void
	duplicate: () => void
	fail: () => void
}

export const useAudio = (): Feedback => {
	return {
		confirm: () => {
			play(830.6, 1.5)
			vibrate(200)
		},
		scanned: () => {
			play(830.6, 0.5)
			vibrate(100)
		},
		duplicate: () => {
			play(830.6, 0.08)
			vibrate([100, 50, 100])
		},
		fail: () => {
			play(261.6, 1.0)
			vibrate([400])
		},
	}
}
