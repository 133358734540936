import React from "react"
import _ from "lodash"
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	rectSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable"
import short from "short-uuid"
import clsx from "clsx"

import { TemplateFieldParams, newTemplateFieldParams } from "@app/api"
import { Badge, Button, ButtonIcon, Icon, Input, TextField, Select } from "@app/components"
import { useSession } from "@app/contexts"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface ITemplateFieldFormProps {
	busy?: boolean
	defaultParams: TemplateFieldParams
	disabled?: boolean
	documentType: string
	errors: { [key: string]: string }
	onAdd: () => void
	onChange: (params?: TemplateFieldParams) => void
	onMoveUp: () => void
	onMoveDown: () => void
	first: boolean
	last: boolean
	id: string
}

const TemplateFieldForm: React.FC<ITemplateFieldFormProps> = (props) => {
	const {
		busy = false,
		defaultParams,
		disabled = false,
		documentType,
		errors,
		onAdd,
		onChange,
		onMoveUp,
		onMoveDown,
		first,
		last,
		id,
	} = props

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: id,
	})

	const { t } = useSession()

	const { params, inputHandler, textFieldHandler, selectHandler } =
		useFormHelpers<TemplateFieldParams>(defaultParams)

	const removeHandler = () => {
		onChange(undefined)
	}

	React.useEffect(() => {
		if (!open) {
			if (_.some([params.label, params.value], _.isEmpty)) {
				removeHandler()
			}
		}
	}, [open])

	React.useEffect(() => {
		onChange(_.cloneDeep(params))
	}, [params])

	const documentTypeKey = _.camelCase(documentType)
	const options = _.get(En.templateField.options, documentTypeKey, [])

	const templateFieldOptions = _.chain(options)
		.keys()
		.map((key: string) => {
			return {
				label: t(`templateField.options.${documentTypeKey}.${key}`),
				value: _.upperFirst(key),
			}
		})
		.sortBy("label")
		.value()

	const defaultTemplateFieldOptions = _.map(_.get(params, "options", []), (option) => {
		return {
			label: t(`templateField.options.${documentTypeKey}.${_.camelCase(option)}`),
			value: _.upperFirst(option),
		}
	})

	return (
		<div
			className={clsx(
				"mt-2 pt-4 px-4 bg-gray-200 border border-gray-300",
				isDragging ? "relative z-10 shadow-lg" : "z-0",
			)}
			ref={setNodeRef}
			style={{
				transform: !transform ? undefined : `translateY(${Math.ceil(transform.y)}px)`,
				transition,
			}}
		>
			<div className="flex flex-row gap-x-4">
				<div className="flex-1 pb-1">
					<Input
						label={t("templateField.labels.label")}
						collapse
						autoComplete="off"
						defaultValue={params.label}
						disabled={disabled || busy}
						error={_.get(errors, "label")}
						name="label"
						onChange={inputHandler("label")}
						placeholder={t("templateField.placeholders.label")}
						type="text"
					/>
					<TextField
						label={t("templateField.labels.value")}
						collapse
						autoComplete="off"
						defaultValue={params.value}
						disabled={disabled || busy}
						error={_.get(errors, "value")}
						name="value"
						onChange={textFieldHandler("value")}
						placeholder={t("templateField.placeholders.value")}
					/>
					<Select
						defaultValue={defaultTemplateFieldOptions}
						disabled={disabled}
						isMulti
						isSearchable={true}
						label={t("templateField.labels.options")}
						name="labels"
						onChange={selectHandler("options", true)}
						error={_.get(errors, "options")}
						options={templateFieldOptions}
					/>
				</div>
				<div className="flex flex-col justify-between">
					<div className="text-center flex flex-col gap-2 place-items-center">
						<Icon
							name="GripLines"
							className="mt-1 text-gray-500 cursor-move focus:outline-none"
							{...listeners}
							{...attributes}
						/>
						{!first && (
							<Icon
								onClick={() => onMoveUp()}
								name="ChevronUp"
								className="mt-1 text-gray-500 cursor-pointer"
							/>
						)}
						{!last && (
							<Icon
								onClick={() => onMoveDown()}
								name="ChevronDown"
								className="mt-1 text-gray-500 cursor-pointer"
							/>
						)}
					</div>
					<div className="mb-4 gap-x-1 inline-flex items-end pb-1">
						<ButtonIcon
							tooltip={t("templateField.buttons.add")}
							icon="Plus"
							onClick={onAdd}
							disabled={disabled}
							busy={busy}
						/>
						<ButtonIcon
							tooltip={t("templateField.buttons.remove")}
							icon="Minus"
							onClick={removeHandler}
							disabled={disabled}
							busy={busy}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

interface ITemplateFieldsFormProps {
	busy?: boolean
	defaultParams: TemplateFieldParams[]
	disabled?: boolean
	documentType: string
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (params: TemplateFieldParams[]) => void
}

export const TemplateFieldsForm: React.FC<ITemplateFieldsFormProps> = (props) => {
	const { t } = useSession()

	const {
		documentType,
		defaultParams,
		onChange,
		busy = false,
		disabled = false,
		errors,
		errorsKey,
	} = props
	const [params, setParams] = React.useState<TemplateFieldParams[]>(defaultParams)
	const [keys, setKeys] = React.useState<string[]>(_.map(params, () => _.uniqueId()))

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)

	React.useEffect(() => {
		setParams((params) => params.map((param) => ({ ...param, id: short.generate() })))
	}, [])

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over && active.id !== over.id) {
			const oldIndex = keys.findIndex((key) => key === active.id)
			const newIndex = keys.findIndex((key) => key === over.id)
			swap(oldIndex, newIndex)
		}
	}

	const swap = (oldIndex: number, newIndex: number) => {
		setParams((params) => arrayMove(params, oldIndex, newIndex))
		setKeys((keys) => arrayMove(keys, oldIndex, newIndex))
	}

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const changeHandler = (index: number): ((nextParams?: TemplateFieldParams) => void) => {
		return (nextParams?: TemplateFieldParams): void => {
			if (_.isNil(nextParams)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				setParams((params) => {
					const result = [...params]
					result.splice(index, 1)
					return result
				})
			} else {
				setParams((params) => {
					const result = [...params]
					result[index] = nextParams
					return result
				})
			}
		}
	}

	const addHandler = (index: number): (() => void) => {
		return () => {
			setKeys((keys) => {
				const next: string[] = []
				_.each(keys, (key, i) => {
					if (i === index) {
						next.push(_.uniqueId())
					}
					next.push(key)
				})
				if (index === _.size(next)) {
					next.push(_.uniqueId())
				}
				return next
			})
			setParams((params) => {
				const next: TemplateFieldParams[] = []
				_.each(params, (p, i) => {
					if (i === index) {
						next.push(newTemplateFieldParams())
					}
					next.push({ ...p })
				})
				if (index === _.size(next)) {
					next.push(newTemplateFieldParams())
				}
				return next
			})
		}
	}

	return (
		<div className="mt-4">
			<div className="flex flex-row justify-between">
				<h2 className="text-md mb-4 text-gray-700">
					{t("templateField.header")} <Badge quantity>{_.size(params)}</Badge>
				</h2>
				<div>
					<Button
						disabled={disabled}
						busy={busy}
						type="button"
						label={t("templateField.buttons.addTerse")}
						onClick={addHandler(0)}
						small
						add
					/>
				</div>
			</div>
			<div className="space-y-2">
				<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
					<SortableContext items={keys} strategy={rectSortingStrategy}>
						{_.map(params, (param: TemplateFieldParams, i: number) => (
							<TemplateFieldForm
								id={keys[i]}
								key={keys[i]}
								documentType={documentType}
								disabled={disabled}
								busy={busy}
								defaultParams={param}
								errors={_.reduce(
									errors,
									(acc, err: string, k: string) => {
										const prefix = `${errorsKey}[${i}].`
										if (_.startsWith(k, prefix)) {
											acc[k.slice(_.size(prefix))] = err
										}
										return acc
									},
									{},
								)}
								onChange={changeHandler(i)}
								onAdd={addHandler(i + 1)}
								first={i === 0}
								last={i === params.length - 1}
								onMoveUp={() => swap(i, i - 1)}
								onMoveDown={() => swap(i, i + 1)}
							/>
						))}
					</SortableContext>
				</DndContext>
			</div>
		</div>
	)
}
