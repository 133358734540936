import React from "react"
import _ from "lodash"

import { BillOfLadingLineItemParams, newBillOfLadingLineItemParams } from "@app/api"
import { Badge, Button, ButtonIcon, ColorSelect, Icon, Input, TextField } from "@app/components"
import { useSession } from "@app/contexts"
import { useFormHelpers } from "@app/hooks"

interface IBillOfLadingLineItemFormProps {
	busy?: boolean
	defaultParams: BillOfLadingLineItemParams
	disabled?: boolean
	errors: { [key: string]: string }
	onAdd: () => void
	onChange: (params?: BillOfLadingLineItemParams) => void
}

const BillOfLadingLineItemForm: React.FC<IBillOfLadingLineItemFormProps> = (props) => {
	const { busy = false, defaultParams, disabled = false, errors, onAdd, onChange } = props

	const { t } = useSession()
	const { params, valueHandler, textFieldHandler, floatInputHandler } =
		useFormHelpers<BillOfLadingLineItemParams>(defaultParams)

	const removeHandler = () => {
		onChange(undefined)
	}

	React.useEffect(() => {
		onChange(params)
	}, [params])

	return (
		<div className="mt-2 pt-4 px-4 bg-gray-200 border border-gray-300">
			<div className="flex flex-row gap-x-4">
				<div className="flex-1 pb-1">
					<ColorSelect
						label={t("tag.labels.color")}
						defaultValue={params.color}
						disabled={disabled || busy}
						onChange={valueHandler("color")}
						error={_.get(errors, "color")}
					/>
					<TextField
						label={t("billOfLadingLineItem.labels.part")}
						collapse
						autoComplete="off"
						defaultValue={params.part}
						disabled={disabled || busy}
						error={_.get(errors, "part")}
						name="part"
						onChange={textFieldHandler("part")}
						placeholder={t("billOfLadingLineItem.placeholders.part")}
					/>
					<TextField
						label={t("billOfLadingLineItem.labels.description")}
						collapse
						autoComplete="off"
						defaultValue={params.description}
						disabled={disabled || busy}
						error={_.get(errors, "description")}
						name="description"
						onChange={textFieldHandler("description")}
						placeholder={t("billOfLadingLineItem.placeholders.description")}
					/>
					<TextField
						label={t("billOfLadingLineItem.labels.quantity")}
						collapse
						autoComplete="off"
						defaultValue={params.quantity}
						disabled={disabled || busy}
						error={_.get(errors, "quantity")}
						name="quantity"
						onChange={textFieldHandler("quantity")}
						placeholder={t("billOfLadingLineItem.placeholders.quantity")}
					/>
					<Input
						label={t("billOfLadingLineItem.labels.weight")}
						autoComplete="off"
						defaultValue={params.weight}
						disabled={disabled || busy}
						error={_.get(errors, "weight")}
						name="weight"
						onChange={floatInputHandler("weight")}
						type="text"
					/>
				</div>
				<div className="flex flex-col justify-between">
					<div className="text-center cursor-move">
						<Icon name="GripLines" className="mt-1 text-gray-500" />
					</div>
					<div className="mb-4 gap-x-1 inline-flex items-end pb-1">
						<ButtonIcon icon="Plus" onClick={onAdd} disabled={disabled} busy={busy} />
						<ButtonIcon icon="Minus" onClick={removeHandler} disabled={disabled} busy={busy} />
					</div>
				</div>
			</div>
		</div>
	)
}

interface IBillOfLadingLineItemsFormProps {
	busy?: boolean
	defaultParams: BillOfLadingLineItemParams[]
	disabled?: boolean
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (params: BillOfLadingLineItemParams[]) => void
}

export const BillOfLadingLineItemsForm: React.FC<IBillOfLadingLineItemsFormProps> = (props) => {
	const { t } = useSession()

	const { defaultParams, onChange, busy = false, disabled = false, errors, errorsKey } = props
	const [params, setParams] = React.useState<BillOfLadingLineItemParams[]>(defaultParams)
	const [keys, setKeys] = React.useState<string[]>(_.map(params, () => _.uniqueId()))

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const changeHandler = (index: number): ((nextParams?: BillOfLadingLineItemParams) => void) => {
		return (nextParams?: BillOfLadingLineItemParams): void => {
			if (_.isNil(nextParams)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				setParams((params) => {
					const result = [...params]
					result.splice(index, 1)
					return result
				})
			} else {
				setParams((params) => {
					const result = [...params]
					result[index] = nextParams
					return result
				})
			}
		}
	}

	const addHandler = (index: number): (() => void) => {
		return () => {
			setKeys((keys) => {
				const next: string[] = []
				_.each(keys, (key, i) => {
					if (i === index) {
						next.push(_.uniqueId())
					}
					next.push(key)
				})
				if (index === _.size(next)) {
					next.push(_.uniqueId())
				}
				return next
			})
			setParams((params) => {
				const next: BillOfLadingLineItemParams[] = []
				_.each(params, (p, i) => {
					if (i === index) {
						next.push(newBillOfLadingLineItemParams())
					}
					next.push({ ...p })
				})
				if (index === _.size(next)) {
					next.push(newBillOfLadingLineItemParams())
				}
				return next
			})
		}
	}

	return (
		<div className="mt-4">
			<div className="flex flex-row justify-between">
				<h2 className="text-md mb-4 text-gray-700">
					{t("billOfLadingLineItem.header")} <Badge quantity>{_.size(params)}</Badge>
				</h2>
				<div>
					<Button
						disabled={disabled}
						busy={busy}
						type="button"
						label={t("billOfLadingLineItem.buttons.add")}
						onClick={addHandler(0)}
						small
						add
					/>
				</div>
			</div>
			<div className="space-y-2">
				{_.map(params, (v: BillOfLadingLineItemParams, i: number) => (
					<BillOfLadingLineItemForm
						key={keys[i]}
						disabled={disabled}
						busy={busy}
						defaultParams={v}
						errors={_.reduce(
							errors,
							(acc, err: string, k: string) => {
								const prefix = `${errorsKey}[${i}].`
								if (_.startsWith(k, prefix)) {
									acc[k.slice(_.size(prefix))] = err
								}
								return acc
							},
							{},
						)}
						onChange={changeHandler(i)}
						onAdd={addHandler(i + 1)}
					/>
				))}
			</div>
		</div>
	)
}
