import { v4 as uuidv4 } from "uuid"

export type ReleaseUnit = {
	id: string
	assemblyID?: string
	customerID?: string
	jobID?: string
	releaseID?: string
	releaseItemID?: string
	tagID?: string
	workCellID?: string
	workFacilityID: string

	buildType: string
	controlCodes: string
	description: string
	notes: string
	scheduled?: string
	sortOrder: number
	status: string
	referenceID: string
	unit?: number

	created: string
	updated: string
}

export const newReleaseUnit = (): ReleaseUnit => {
	return {
		id: uuidv4(),
		workFacilityID: "",

		buildType: "",
		controlCodes: "",
		description: "",
		notes: "",
		sortOrder: 0,
		status: "",
		referenceID: "",

		created: "",
		updated: "",
	}
}

export const ReleaseUnitUtils = {
	name(releaseUnit?: ReleaseUnit): string {
		if (releaseUnit) {
			return `${releaseUnit.description}${releaseUnit.unit ? ` (Unit ${releaseUnit.unit})` : ""}`
		}
		return ""
	},
}
