import _ from "lodash"

import { rpc, Cache, compactIDs } from "@app/api"
import { TagColor } from "@app/domain"

import type { Result, AttributeParams, TemplateFieldParams } from "@app/api"
import type { Tag, Pagination } from "@app/domain"

export type CreateTagsParams = {
	releaseID: string
	releaseItemIDs: string[]
	templateFields: TemplateFieldParams[]
	color: TagColor
}

export const newCreateTagsParams = (): CreateTagsParams => {
	return {
		releaseID: "",
		releaseItemIDs: [],
		templateFields: [],
		color: TagColor.None,
	}
}

export const CreateTags = async (params: CreateTagsParams): Promise<Result<SaveTagResult>> => {
	const data = await rpc("CreateTags", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveTagResult>
}

export type SaveTagParams = {
	id?: string

	attributes: AttributeParams[]
	color: TagColor
	controlCodes: string
	description: string
	metric: boolean
	quantity: number
	referenceID?: string
	templateFields: TemplateFieldParams[]
	unitEnd: number
	unitStart: number
	weight: number | undefined
}

export const newSaveTagParams = (tag?: Tag): SaveTagParams => {
	return {
		id: _.get(tag, "id", ""),

		attributes: _.get(tag, "attributes", []),
		color: _.get(tag, "color", TagColor.None),
		controlCodes: _.get(tag, "controlCodes", ""),
		description: _.get(tag, "description", ""),
		metric: _.get(tag, "metric", false),
		quantity: _.get(tag, "quantity", 1),
		templateFields: _.get(tag, "templateFields", []),
		unitEnd: _.get(tag, "unitEnd", 1),
		unitStart: _.get(tag, "unitStart", 1),
		weight: _.get(tag, "weight"),
	}
}

export type SaveTagResult = {
	tags: Tag[]
}

export const SaveTag = async (params: SaveTagParams): Promise<Result<SaveTagResult>> => {
	const data = await rpc("SaveTag", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveTagResult>
}

export type SaveTagLockParams = {
	id?: string

	locked: boolean
}

export type SaveTagLockResult = {
	tags: Tag[]
}

export const SaveTagLock = async (
	params: SaveTagLockParams,
): Promise<Result<SaveTagLockResult>> => {
	const data = await rpc("SaveTagLock", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveTagLockResult>
}

export type GetTagsParams = {
	ids?: string[]
	includeIDs?: string[]
	excludeIDs?: string[]
	referenceID?: string

	assemblyID?: string
	customerID?: string
	jobID?: string
	releaseID?: string
	releaseItemID?: string
	releaseItemIDs?: string[]

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetTagsResult = {
	tags: Tag[]
	pagination: Pagination
}

export const GetTags = async (params: GetTagsParams): Promise<Result<GetTagsResult>> => {
	const data = await rpc("GetTags", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetTagsResult>
}

export type GetTagByReferenceIDParams = {
	referenceID: string
}

export type GetTagByReferenceIDResult = {
	tags: Tag[]
}

export const GetTagByReferenceID = async (
	params: GetTagByReferenceIDParams,
): Promise<Result<GetTagByReferenceIDResult>> => {
	const data = await rpc("GetTagByReferenceID", {
		...params,
	})
	return data as Result<GetTagsResult>
}

export type GetTagPreviewResult = {
	image: string
}

export const GetTagPreview = async (
	params: SaveTagParams,
): Promise<Result<GetTagPreviewResult>> => {
	const data = await rpc("GetTagPreview", { ...params })
	return data as Result<GetTagPreviewResult>
}
