export type EmailParams = {
	email: string
	labels: string[]
}

export const newEmailParams = (): EmailParams => {
	return {
		email: "",
		labels: [],
	}
}
