import React from "react"
import _ from "lodash"

import { File } from "@app/domain"
import {
	Badge,
	BreadcrumbDelimiter,
	Breadcrumbs,
	DateTime,
	Icon,
	Link,
	Tabs,
	Title,
} from "@app/components"
import { useSession } from "@app/contexts"
import { urlTo } from "@app/util"

interface IFileHeaderProps {
	file?: File
}

export const FileHeader: React.FC<IFileHeaderProps> = (props) => {
	const { file } = props
	const { id, name, created, updated, url } = _.assign(
		{ id: "", name: "...", created: "", updated: "", url: "" },
		file,
	)

	const { t } = useSession()

	if (!file) {
		return null
	}

	const title = t("file.titles.show", { name })
	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("logs/show", id),
			active: true,
		},
		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="download">
							<Icon className="mr-2" name="Document" fixedWidth />
							{t("file.buttons.download")}
						</span>
					),
					href: url,
				},
			],
		},
	]

	return (
		<>
			<Title>{title}</Title>

			<Breadcrumbs>
				<Link to="/files">{t(`file.titles.list`)}</Link>
				<BreadcrumbDelimiter />
				{name}
				<BreadcrumbDelimiter />
				{t(`common.breadcrumbs.show`)}
			</Breadcrumbs>

			<>
				<h2 className="text-2xl mb-0 font-medium">{title}</h2>
				<div className="mb-4 space-x-2">
					<Badge label={t("common.labels.created")}>
						<DateTime time={created} />
					</Badge>
					<Badge label={t("common.labels.updated")}>
						<DateTime time={updated} />
					</Badge>
					&nbsp;
				</div>
				<Tabs tabs={tabs} className="mb-4" />
			</>
		</>
	)
}
