import React from "react"
import _ from "lodash"

import {
	CustomerSelect,
	Toggle,
	Button,
	FormActions,
	Input,
	WorkFacilitySelect,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveJobParams } from "@app/api"
import { AddressesForm, ContactablesForm, AttributesForm } from "@app/forms"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

interface IJobFormProps {
	busy?: boolean
	defaultParams: SaveJobParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveJobParams) => void
	referenceID?: string
}

export const JobForm: React.FC<IJobFormProps> = (props) => {
	const { referenceID, defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { params, contactablesHandler, inputHandler, valueHandler, selectHandler } =
		useFormHelpers<SaveJobParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("job.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					{referenceID ? (
						<Input
							disabled={disabled || busy}
							readOnly={true}
							defaultValue={referenceID}
							hint={t("common.hints.referenceID")}
							label={t("common.labels.referenceID")}
							name="referenceID"
							error={_.get(errors, "referenceID")}
							onChange={inputHandler("referenceID")}
							type="text"
						/>
					) : null}
					<CustomerSelect
						autoFocus={_.isEmpty(params.customerID)}
						defaultValue={_.get(params, "customerID")}
						disabled={disabled || busy}
						error={_.get(errors, "customerID")}
						label={t("job.labels.customerID")}
						name="customerID"
						onChange={selectHandler("customerID")}
					/>
					<WorkFacilitySelect
						defaultValue={_.get(params, "workFacilityIDs")}
						disabled={disabled || busy}
						error={_.get(errors, "workFacilityIDs")}
						label={t("job.labels.workFacilityIDs")}
						name="workFacilityIDs"
						isMulti={true}
						onChange={selectHandler("workFacilityIDs", true)}
					/>
					<Input
						autoFocus={!_.isEmpty(params.customerID) && _.isEmpty(params.name)}
						autoComplete="off"
						defaultValue={params.name}
						disabled={disabled || busy}
						error={_.get(errors, "name")}
						label={t("job.labels.name")}
						name="name"
						onChange={inputHandler("name")}
						type="text"
					/>
					<Input
						autoComplete="off"
						defaultValue={params.countryOfOrigin}
						disabled={disabled || busy}
						error={_.get(errors, "countryOfOrigin")}
						label={t("job.labels.countryOfOrigin")}
						name="countryOfOrigin"
						onChange={inputHandler("countryOfOrigin")}
						type="text"
					/>
					{referenceID ? (
						<Toggle
							label={t("common.labels.metric")}
							hint={t("job.hints.metric")}
							defaultChecked={params.metric}
							disabled={disabled || busy}
							error={_.get(errors, "metric")}
							onChange={valueHandler("metric")}
						/>
					) : null}
					<AttributesForm
						busy={busy}
						defaultParams={_.get(params, "attributes")}
						disabled={disabled}
						errors={errors}
						errorsKey="attributes"
						onChange={valueHandler("attributes")}
					/>
				</div>
				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<AddressesForm
						defaultParams={_.get(params, "addresses", [])}
						errors={errors}
						errorsKey="addresses"
						disabled={disabled}
						busy={busy}
						onChange={valueHandler("addresses")}
					/>
				</div>
				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<ContactablesForm
						defaultParams={_.get(params, "contactables", [])}
						errors={errors}
						errorsKey="contactables"
						disabled={busy}
						busy={busy}
						onChange={contactablesHandler}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					disabled={disabled}
					busy={busy}
					errors={errors}
					label={t("job.buttons.save")}
					type="submit"
				/>
			</FormActions>
		</form>
	)
}
