import React from "react"

import { Icon, Tooltip } from "@app/components"

interface IButtonIconProps {
	disabled?: boolean
	busy?: boolean
	icon: string
	tooltip?: string
	color?: string
}

export const ButtonIcon: React.FC<
	IButtonIconProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
	const { tooltip, busy = false, disabled = false, icon, ...rest } = props

	let color = props.color || "bg-yellow-500 hover:bg-yellow-400"
	if (icon === "Plus") {
		color = "bg-green-500 hover:bg-green-400"
	} else if (icon === "Minus") {
		color = "bg-red-500 hover:bg-red-400"
	}

	const button = (
		<button
			type="button"
			disabled={disabled || busy}
			className={`inline-flex text-center items-center justify-center text-white h-6 w-6 rounded-full text-sm leading-5 focus:outline-none ${color} disabled:bg-gray-500 disabled:text-white`}
			{...rest}
		>
			<Icon name={icon} />
		</button>
	)

	if (tooltip) {
		return <Tooltip content={tooltip}>{button}</Tooltip>
	}

	return button
}
