import React from "react"
import QrReader from "react-qr-reader"
import _ from "lodash"

import { Tag, ScanEvent } from "@app/domain"
import { GetTagByReferenceID, SaveScanLogs } from "@app/api"
import { useSession } from "@app/contexts"
import { useAudio } from "@app/hooks"
import { TagScanCard, Icon, Button, WorkFacilitySelect } from "@app/components"

enum ProcessStatus {
	Duplicate,
	Fail,
	Success,
}

type TagReference = {
	referenceID: string
	item: number
	flash: boolean
	tag?: Tag
}

interface ITagScannerProps {
	scanEntityMap?: { [key: string]: string }
	onClose?: () => void
	onSubmit?: (tagReferences: TagReference[]) => void
	scanEvent?: ScanEvent
	submitLabel?: string
}

export const TagScanner: React.FC<ITagScannerProps> = (props) => {
	const {
		scanEntityMap,
		scanEvent = ScanEvent.Noop,
		onClose = _.noop,
		onSubmit,
		submitLabel,
	} = props

	const { workFacility } = useSession()

	const [flash, setFlash] = React.useState(false)
	const [lastData, setLastData] = React.useState<string | undefined>()
	const [tags, setTags] = React.useState<{ [referenceID: string]: Tag }>({})
	const [tagReferences, setTagReferences] = React.useState<TagReference[]>([])
	const [workFacilityID, setWorkFacilityID] = React.useState<string>(_.get(workFacility, "id", ""))
	const [, setScanned] = React.useState<{
		[referenceID: string]: {
			loaded: boolean
			loading: boolean
			meta?: { top?: boolean; bottom?: boolean }
			tag?: Tag
			items: number[]
		}
	}>({})
	const feedback = useAudio()

	const loadTag = async (referenceID: string) => {
		const resp = await GetTagByReferenceID({ referenceID })
		if (_.size(resp.result.tags) === 1) {
			feedback.confirm()
			const tag = resp.result.tags[0]
			setTags((prev) => {
				const next = { ...prev }
				next[tag.referenceID] = tag
				return next
			})
		} else {
			feedback.fail()
		}
	}

	const unflash = (tagRef: TagReference) => {
		setTagReferences((prev) => {
			return _.reduce(
				prev,
				(acc: TagReference[], v) => {
					if (v.referenceID === tagRef.referenceID && v.item === tagRef.item) {
						const tagRef = { referenceID: v.referenceID, item: v.item, flash: false }
						acc.push(tagRef)
					} else {
						acc.push(v)
					}
					return acc
				},
				[],
			)
		})
	}

	const process = (input: string): ProcessStatus => {
		let tagID = ""
		let meta = {}

		if (_.startsWith(input, "http")) {
			const url = new URL(input)
			if (/\.toggle\.(test|industries)/.exec(url.hostname)) {
				if (_.startsWith(url.pathname, "/t/T")) {
					tagID = _.trimStart(url.pathname, "/t/")
					meta = {
						top: !_.isNil(url.searchParams.get("top")),
						bottom: !_.isNil(url.searchParams.get("bottom")),
					}
				}
			}
		} else if (_.startsWith(input, "T")) {
			tagID = input
		}

		const parts = tagID.split("-")
		if (_.size(parts) !== 2) {
			return ProcessStatus.Fail
		}

		const referenceID = parts[0]
		const item = parseInt(parts[1], 10)
		if (_.find(tagReferences, { referenceID, item })) {
			setTagReferences((prev) => {
				return _.reduce(
					prev,
					(acc: TagReference[], v) => {
						if (v.referenceID === referenceID && v.item === item) {
							const tagRef = { referenceID, item, flash: true }
							acc = [tagRef].concat(acc)
							_.delay(() => {
								unflash(tagRef)
							}, 300)
						} else {
							acc.push(v)
						}
						return acc
					},
					[],
				)
			})
			return ProcessStatus.Duplicate
		}
		if (!isNaN(item) && item > 0) {
			setTagReferences((prev) => {
				const next: TagReference[] = []
				if (_.isNil(_.find(next, { referenceID, item }))) {
					const tagReference = { referenceID, item, flash: true }
					next.push(tagReference)
					_.delay(() => {
						unflash(tagReference)
					}, 300)
				}
				return next.concat([...prev])
			})
			setScanned((prev) => {
				const next = { ...prev }
				if (_.has(next, referenceID)) {
					if (_.findIndex(next[referenceID].items, item) === -1) {
						next[referenceID].items.push(item)
					}
				} else {
					loadTag(referenceID)
					next[referenceID] = { meta, loaded: false, loading: false, items: [item] }
				}
				return next
			})
			return ProcessStatus.Success
		}

		return ProcessStatus.Fail
	}

	const handleScan = (data: string | null) => {
		if (data && data !== lastData) {
			const result = process(data)
			SaveScanLogs({
				entityMap: scanEntityMap,
				workFacilityID: workFacilityID,
				scanLogs: [
					{
						rawData: data,
						scanEvent: scanEvent,
					},
				],
			})
			setLastData(data)
			_.delay(() => {
				setLastData(undefined)
			}, 1000)
			if (result === ProcessStatus.Success) {
				setFlash(true)
				_.delay(() => {
					setFlash(false)
				}, 50)
				feedback.scanned()
			} else if (result === ProcessStatus.Duplicate) {
				feedback.duplicate()
			} else {
				feedback.fail()
			}
		}
	}

	const closeHandler = (reference: TagReference) => {
		return () => {
			setTagReferences((prev) => {
				return _.reduce(
					prev,
					(acc: TagReference[], v) => {
						if (v.referenceID !== reference.referenceID && v.item !== reference.item) {
							acc.push(v)
						}
						return acc
					},
					[],
				)
			})
		}
	}

	/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	const handleError = (err: any) => {
		console.error(err)
	}

	let disabled = true
	_.each(tagReferences, (ref) => {
		if (tags[ref.referenceID]) {
			disabled = false
			return false
		}
	})

	return (
		<div className="relative bg-white m-4 rounded-md p-4">
			<div className="absolute right-0 top-0 p-2">
				<button
					onClick={onClose}
					className="text-gray-500 hover:text-gray-900 inline-flex items-center rounded-full hover:bg-yellow-500 w-7 h-7"
				>
					<Icon name="Times" size="lg" className="flex-1" />
				</button>
			</div>
			<div className="mx-auto relative max-w-sm">
				<WorkFacilitySelect
					name="workFacilityID"
					onChange={(v) => {
						setWorkFacilityID(_.get(v, "[0].value", ""))
					}}
					defaultValue={[_.get(workFacility, "id", "")]}
				/>
				<div className={`absolute w-full h-full bg-white z-10 ${flash ? "" : "hidden"}`}></div>
				<QrReader delay={500} onError={handleError} onScan={handleScan} style={{ width: "100%" }} />
			</div>
			<form
				onSubmit={() => {
					const refs = _.reduce(
						tagReferences,
						(acc: TagReference[], ref: TagReference) => {
							const tag = tags[ref.referenceID]
							if (tag) {
								acc.push(_.assign({ tag }, ref))
							}
							return acc
						},
						[],
					)
					if (onSubmit) {
						onSubmit(refs)
					}
					return false
				}}
			>
				{onSubmit && submitLabel ? (
					<div className="bg-gray-300 p-4 text-center my-2">
						<Button
							label={submitLabel}
							type="submit"
							icon="ChevronRight"
							disabled={disabled}
							iconRight={true}
						/>
					</div>
				) : null}
				<div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
					{tagReferences.map((ref) => {
						const tag = tags[ref.referenceID]
						if (tag) {
							return (
								<TagScanCard
									key={`${ref.referenceID}-${ref.item}`}
									tag={tag}
									item={ref.item}
									flash={ref.flash}
									onClose={closeHandler(ref)}
								/>
							)
						} else {
							return (
								<TagScanCard
									key={`${ref.referenceID}-${ref.item}`}
									notFound={true}
									referenceID={ref.referenceID}
									item={ref.item}
									onClose={closeHandler(ref)}
								/>
							)
						}
					})}
				</div>
			</form>
		</div>
	)
}
