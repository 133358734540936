import _ from "lodash"

export type WorkCell = {
	id: string
	workFacilityID: string

	capabilities: string[]
	description: string
	logoURL: string
	name: string
	tagsDisabled: boolean
	workCellType: string

	created: string
	updated: string
}

export const newWorkCell = (opts?: { [key: string]: string | boolean | number }): WorkCell => {
	return _.assign(
		{
			id: "",
			workFacilityID: "",

			capabilities: [],
			description: "",
			logoURL: "",
			name: "",
			tagsDisabled: false,
			workCellType: "",

			created: "",
			updated: "",
		},
		opts,
	)
}
