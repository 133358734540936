import React from "react"
import _ from "lodash"

import { FormActions, Button, Input, Toggle } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkerParams } from "@app/api"
import { useFormHelpers } from "@app/hooks"

interface IWorkerFormProps {
	busy?: boolean
	defaultParams: SaveWorkerParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkerParams) => void
}

export const WorkerForm: React.FC<IWorkerFormProps> = (props) => {
	const { onSave, defaultParams, errors, disabled = false, busy = false } = props

	const { t } = useSession()
	const { params, inputHandler, valueHandler } = useFormHelpers<SaveWorkerParams>(defaultParams)

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.firstName}
						label={t("worker.labels.firstName")}
						name="firstName"
						error={_.get(errors, "firstName")}
						onChange={inputHandler("firstName")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.lastName}
						label={t("worker.labels.lastName")}
						name="lastName"
						error={_.get(errors, "lastName")}
						onChange={inputHandler("lastName")}
						type="text"
					/>
					<Toggle
						label={t("worker.labels.disabled")}
						hint={t("worker.hints.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("worker.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
