import React from "react"
import _ from "lodash"

import { Select } from "@app/components"
import type { OptionType } from "@app/components"

import En from "@app/translations/en.json"
import { PropsValue } from "react-select"

type Props = {
	value?: PropsValue<OptionType> | undefined
	defaultValue?: string | string[]
	disabled?: boolean
	enumTypeName: string
	error?: string
	hint?: string
	includeBlank?: boolean
	label?: string
	isMulti?: boolean
	name?: string
	onChange: (v: OptionType[]) => void
}

export const EnumTypeSelect = (props: Props) => {
	const {
		value,
		defaultValue,
		disabled,
		enumTypeName,
		error,
		hint,
		includeBlank = false,
		isMulti = false,
		label,
		name = "",
		onChange,
	} = props

	const optionTypes = _.map(En[enumTypeName], (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultTypeOption: OptionType | OptionType[] | undefined = isMulti ? [] : undefined

	const options = optionTypes.map((c) => {
		const opt = { value: c.code, label: c.name }
		if (!isMulti && defaultValue === c.code) {
			defaultTypeOption = opt
		}
		return opt
	})

	if (isMulti) {
		defaultTypeOption = _.map(defaultValue, (v) => {
			return _.find(options, (option) => {
				return option.value === v
			})
		}) as OptionType[]
	}

	if (includeBlank) {
		options.unshift({ label: "", value: "" })
	} else if (_.isNil(defaultTypeOption)) {
		defaultTypeOption = _.first(options)
	}

	return (
		<Select
			value={value}
			defaultValue={defaultTypeOption}
			disabled={disabled}
			error={error}
			hint={hint}
			isSearchable={true}
			label={label}
			name={name}
			onChange={onChange}
			options={options}
			isMulti={isMulti}
		/>
	)
}
