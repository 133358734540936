import { SaveContactParams, newSaveContactParams } from "@app/api"

export type ContactableParams = {
	id?: string
	contact?: SaveContactParams
	contactableID?: string
	contactableType?: string
	contactID?: string
	labels?: string[]
}

export const newContactableParams = (): ContactableParams => {
	return {
		id: "",
		contactID: "",
		contact: newSaveContactParams(),
	}
}
