import React from "react"
import _ from "lodash"

import { Button, FormActions, Input, Toggle } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveCustomerParams } from "@app/api"
import { AddressesForm, ContactablesForm } from "@app/forms"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

interface ICustomerFormProps {
	busy?: boolean
	defaultParams: SaveCustomerParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveCustomerParams) => void
	referenceID?: string
}

export const CustomerForm: React.FC<ICustomerFormProps> = (props) => {
	const { referenceID, defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { params, inputHandler, valueHandler, contactablesHandler } =
		useFormHelpers<SaveCustomerParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("customer.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					{referenceID ? (
						<Input
							disabled={disabled || busy}
							readOnly={true}
							defaultValue={referenceID}
							hint={t("common.hints.referenceID")}
							label={t("common.labels.referenceID")}
							name="referenceID"
							error={_.get(errors, "referenceID")}
							onChange={inputHandler("referenceID")}
							type="text"
						/>
					) : null}
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.name}
						label={t("customer.labels.name")}
						name="name"
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						type="text"
					/>
					<Toggle
						label={t("common.labels.metric")}
						defaultChecked={params.metric}
						disabled={disabled || busy}
						error={_.get(errors, "metric")}
						onChange={valueHandler("metric")}
					/>
				</div>
				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<AddressesForm
						busy={busy}
						defaultParams={_.get(params, "addresses", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="addresses"
						onChange={valueHandler("addresses")}
					/>
				</div>
				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<ContactablesForm
						busy={busy}
						defaultParams={_.get(params, "contactables", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="contactables"
						onChange={contactablesHandler}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("customer.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
