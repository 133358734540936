import React from "react"
import _ from "lodash"

import { EntityType } from "@app/domain"
import { toUUID } from "@app/util"
import {
	Cache,
	GetAssemblies,
	GetContacts,
	GetCustomers,
	GetDocuments,
	GetEventLogs,
	GetFiles,
	GetJobs,
	GetMaterials,
	GetReleaseItems,
	GetReleases,
	GetReleaseUnits,
	GetSettings,
	GetShipmentItems,
	GetShipments,
	GetTags,
	GetUsers,
	GetWorkActions,
	GetWorkCellPrograms,
	GetWorkCells,
	GetWorkCellServers,
	GetWorkDevices,
	GetWorkers,
	GetWorkEvents,
	GetWorkFacilities,
	GetWorkResources,
} from "@app/api"
import { useHTTPStatus, useSession } from "@app/contexts"

type Options = {
	setHTTPStatus?: boolean
}

export const useRelation = <T,>(
	entityType?: EntityType,
	id?: string | string[] | null,
	opts: Options = {},
): [
	T | undefined,
	boolean,
	(e: T | undefined | ((p: T | undefined) => T | undefined)) => void,
	boolean,
] => {
	const { handleError } = useSession()
	const { setHTTPStatus } = useHTTPStatus()
	const uuid = entityType ? toUUID(id) || "" : ""

	const getFromCache = () => {
		if (_.isNil(entityType)) {
			return undefined
		}
		switch (entityType) {
			case EntityType.ReleaseItem:
			case EntityType.ShipmentItem:
				return undefined
			default:
				return Cache.get(uuid) as T | undefined
		}
	}

	const [result, setResult] = React.useState<T | undefined>(() => getFromCache())
	const [notFound, setNotFound] = React.useState<boolean>(false)
	const [loading, setLoading] = React.useState<boolean>(() => _.isEmpty(result) && !_.isEmpty(uuid))

	React.useEffect(() => {
		let canceled = false
		const load = async () => {
			if (_.isNil(entityType)) {
				return
			}
			setLoading(true)
			try {
				let key: string | undefined
				let resp
				switch (entityType) {
					case EntityType.Assembly:
						key = "assemblies"
						resp = await GetAssemblies({ ids: [uuid] })
						break
					case EntityType.Contact:
						key = "contacts"
						resp = await GetContacts({ ids: [uuid] })
						break
					case EntityType.Customer:
						key = "customers"
						resp = await GetCustomers({ ids: [uuid] })
						break
					case EntityType.Document:
						key = "documents"
						resp = await GetDocuments({ ids: [uuid] })
						break
					case EntityType.File:
						key = "files"
						resp = await GetFiles({ ids: [uuid] })
						break
					case EntityType.Job:
						key = "jobs"
						resp = await GetJobs({ ids: [uuid] })
						break
					case EntityType.EventLog:
						key = "eventLogs"
						resp = await GetEventLogs({ ids: [uuid] })
						break
					case EntityType.Material:
						key = "materials"
						resp = await GetMaterials({ ids: [uuid] })
						break
					case EntityType.Release:
						key = "releases"
						resp = await GetReleases({ ids: [uuid] })
						break
					case EntityType.ReleaseItem:
						key = "releaseItems"
						resp = await GetReleaseItems({ releaseID: uuid })
						break
					case EntityType.ReleaseUnit:
						key = "releaseUnits"
						resp = await GetReleaseUnits({ ids: [uuid] })
						break
					case EntityType.Setting:
						key = "settings"
						resp = await GetSettings({ ids: [uuid] })
						break
					case EntityType.Shipment:
						key = "shipments"
						resp = await GetShipments({ ids: [uuid] })
						break
					case EntityType.ShipmentItem:
						key = "shipmentItems"
						resp = await GetShipmentItems({ shipmentID: uuid })
						break
					case EntityType.Tag:
						key = "tags"
						resp = await GetTags({ ids: [uuid] })
						break
					case EntityType.User:
						key = "users"
						resp = await GetUsers({ ids: [uuid] })
						break
					case EntityType.WorkCell:
						key = "workCells"
						resp = await GetWorkCells({ ids: [uuid] })
						break
					case EntityType.WorkCellProgram:
						key = "workCellPrograms"
						resp = await GetWorkCellPrograms({ ids: [uuid] })
						break
					case EntityType.WorkDevice:
						key = "workDevices"
						resp = await GetWorkDevices({ ids: [uuid] })
						break
					case EntityType.Worker:
						key = "workers"
						resp = await GetWorkers({ ids: [uuid] })
						break
					case EntityType.WorkAction:
						key = "workActions"
						resp = await GetWorkActions({ ids: [uuid] })
						break
					case EntityType.WorkEvent:
						key = "workEvents"
						resp = await GetWorkEvents({ ids: [uuid] })
						break
					case EntityType.WorkCellServer:
						key = "workCellServers"
						resp = await GetWorkCellServers({ ids: [uuid] })
						break
					case EntityType.WorkResource:
						key = "workResources"
						resp = await GetWorkResources({ ids: [uuid] })
						break
					case EntityType.WorkFacility:
						key = "workFacilities"
						resp = await GetWorkFacilities({ ids: [uuid] })
						break
				}
				if (canceled) {
					return
				}
				if (_.get(resp, "ok") && key) {
					let entity: T | undefined
					switch (entityType) {
						case EntityType.ReleaseItem:
						case EntityType.ShipmentItem:
							entity = _.get(resp, `result.${key}`) as T | undefined
							break
						default:
							entity = _.get(resp, `result.${key}[0]`) as T | undefined
					}
					if (_.isNil(entity)) {
						setNotFound(true)
						if (opts.setHTTPStatus) {
							setHTTPStatus(404)
						}
					} else {
						setResult(entity)
					}
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		if (!_.isEmpty(id)) {
			const entity = getFromCache()
			if (entity) {
				setResult(entity)
			} else {
				load()
			}
		}
		return () => {
			canceled = true
		}
	}, [id])
	return [result, loading, setResult, notFound]
}
