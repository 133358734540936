import React from "react"
import { InterpolationOptions } from "node-polyglot"

import { AlertLevel } from "@app/domain"

import type { IconProp } from "@fortawesome/fontawesome-svg-core"
import type {
	Tenant,
	UserSession,
	User,
	Notification,
	WorkFacility,
	UserPermission,
} from "@app/domain"

export type Translator = (phrase: string, options?: number | InterpolationOptions) => string

const warn = () => {
	console.warn("SessionContext: misconfigured provider")
}

export interface INotificationProps {
	alertLevel?: AlertLevel
	autoCloseTimeout?: number
	icon?: IconProp
	subtitle?: string
	title: string
}

type SessionContext = {
	addNotification: (n: INotificationProps) => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleError: (err: any) => void
	loading: boolean
	notifications: Notification[]
	offline: boolean
	oldBuild: boolean
	removeNotification: (id: string) => void
	setLoading: (loading: boolean) => void
	setOffline: (v: boolean) => void
	setOldBuild: (oldBuild: boolean) => void
	setSession: React.Dispatch<React.SetStateAction<UserSession | undefined>>
	t: Translator
	tenantID: number
	tenantName: string
	tenants: Tenant[]
	user?: User
	userHas: (...permissions: [UserPermission]) => boolean
	workFacility?: WorkFacility
}

export const sessionContext = React.createContext<SessionContext>({
	addNotification: warn,
	handleError: warn,
	loading: true,
	notifications: [],
	offline: false,
	oldBuild: false,
	removeNotification: warn,
	setLoading: warn,
	setOffline: warn,
	setOldBuild: warn,
	setSession: warn,
	t: () => "translations are unconfigured",
	tenantID: 0,
	tenantName: "",
	tenants: [],
	userHas: () => false,
})

export const useSession = (): SessionContext => React.useContext(sessionContext)
