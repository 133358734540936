import React from "react"
import _ from "lodash"
import { useDropzone } from "react-dropzone"

import {
	Icon,
	Label,
	Button,
	TextField,
	FieldError,
	FormActions,
	Input,
	Select,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkCellProgramParams } from "@app/api"
import { Assembly, EntityType, AlertLevel } from "@app/domain"
import { useFormHelpers, useRelation } from "@app/hooks"

import En from "@app/translations/en.json"

interface IWorkCellProgramFormProps {
	busy?: boolean
	defaultParams: SaveWorkCellProgramParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkCellProgramParams) => void
}

export const WorkCellProgramForm: React.FC<IWorkCellProgramFormProps> = (props) => {
	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { setParams, params, textFieldHandler, inputHandler, selectHandler } =
		useFormHelpers<SaveWorkCellProgramParams>(defaultParams)

	const [assembly, assemblyLoading] = useRelation<Assembly>(
		EntityType.Assembly,
		defaultParams.assemblyID,
	)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workCellProgram.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const onDrop = React.useCallback((acceptedFiles: File[]) => {
		acceptedFiles.forEach((file: File, index: number) => {
			const reader = new FileReader()
			reader.onabort = () => console.error("file reading was aborted")
			reader.onerror = () => console.error("file reading has failed")
			reader.onload = () => {
				const content = reader.result?.toString() || ""
				const robotProgram = { id: "", name: file.name, content, data: [], csv: "", robotType: "" }
				setParams((prev) => {
					const robotPrograms = prev.robotPrograms || []
					robotPrograms[index] = robotProgram
					return { ...prev, robotPrograms }
				})
			}
			reader.readAsBinaryString(file)
		})
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const workCellTypeOptions = _.map(En.workCellTypes, (label, value) => {
		return { label, value: _.upperFirst(value) }
	}).sort((left, right) => {
		return left.label < right.label ? -1 : 1
	})
	const assemblyWorkCellType = _.first(_.get(assembly, "assemblyType", "").split("-"))
	let defaultWorkCellTypeOption: undefined | { label: string; value: string }
	_.each(workCellTypeOptions, (opt) => {
		if (opt.value === _.get(params, "workCellType") || opt.value === assemblyWorkCellType) {
			defaultWorkCellTypeOption = opt
			return false
		}
	})

	React.useEffect(() => {
		setParams((prev) => {
			if (defaultWorkCellTypeOption) {
				const workCellType = defaultWorkCellTypeOption.value
				return { ...prev, workCellType }
			}
			return prev
		})
	}, [defaultWorkCellTypeOption?.value])

	if (assemblyLoading) {
		return null
	}

	const isNew = _.isEmpty(_.get(defaultParams, "id"))
	const robotProgramsLabel = t("workCellProgram.labels.robotPrograms")

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						disabled={true}
						value={assembly?.name}
						label={t("workCellProgram.labels.assemblyID")}
						name="assemblyID"
						hint={t("workCellProgram.hints.assemblyID")}
						type="text"
					/>
					<Select
						disabled={true}
						defaultValue={defaultWorkCellTypeOption}
						isSearchable={true}
						label={t("workCellProgram.labels.workCellType")}
						name="workCellType"
						error={_.get(errors, "workCellType")}
						onChange={selectHandler("workCellType")}
						options={workCellTypeOptions}
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.name}
						label={t("workCellProgram.labels.name")}
						name="name"
						hint={t("workCellProgram.hints.name")}
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						type="text"
					/>
					<TextField
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.description}
						label={t("workCellProgram.labels.description")}
						name="description"
						hint={t("workCellProgram.hints.description")}
						error={_.get(errors, "description")}
						onChange={textFieldHandler("description")}
						type="text"
					/>
					<div className="border-b border-gray-400">
						<Label>{robotProgramsLabel}</Label>
					</div>
					{!isNew && (
						<div className="py-4 flex flex-row justify-center items-center mb-4 mt-2">
							<span className="bg-gray-300 inline-flex text-center items-center justify-center text-black h-10 w-10 rounded-full text-sm mr-4">
								<Icon name="Info" className="" size="lg" />
							</span>
							<p className="w-4/5 text-sm">{t("workCellProgram.notes.editing")}</p>
						</div>
					)}
					<div {...getRootProps()} className="cursor-pointer">
						<div className="flex p-4 justify-center items-center bg-gray-200 w-full h-full">
							<input {...getInputProps()} />
							{isDragActive ? (
								<p>{t("workCellProgram.hints.drop")}</p>
							) : (
								<>
									<span className="bg-yellow-500 inline-flex text-center items-center justify-center text-black h-10 w-10 rounded-full text-sm mr-4">
										<Icon name="Upload" className="" size="lg" />
									</span>
									<p className="text-center w-64">{t("workCellProgram.hints.robotPrograms")}</p>
								</>
							)}
						</div>
						{!_.isEmpty(params.robotPrograms) && (
							<div className="mt-4">
								{_.map(params.robotPrograms, (p, i) => {
									return (
										<div className="text-sm" key={p.name}>
											<Icon name="Document" className="mr-2" />
											{p.name}
											<FieldError
												label={p.name}
												error={_.get(errors, "robotPrograms[" + i + "].data")}
												message="$field is not a valid robot program."
											/>
											<FieldError
												label={p.name}
												error={_.get(errors, "robotPrograms[" + i + "].robotType")}
												message={`The robot type couldn't be determined, the name of the file should contain Loader, Welder, or Handler.`}
											/>
										</div>
									)
								})}
							</div>
						)}
					</div>
					<FieldError
						label={robotProgramsLabel}
						error={_.get(errors, "robotPrograms")}
						collapse={false}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workCellProgram.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
