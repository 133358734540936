import _ from "lodash"
import short from "short-uuid"
import {
	EntityType,
	FileType,
	BlobType,
	WorkCellSession,
	fileTypeToExtension,
	blobTypeToExtension,
} from "@app/domain"

export const MM_PER_IN = 25.4

export const gltfDataURLPrefix = "data:application/gltf,"

export const downloadBlob = async (blob: Blob, filename: string) => {
	const downloadURL = window.URL.createObjectURL(blob)
	const a = document.createElement("a")
	a.href = downloadURL
	a.download = filename
	document.body.appendChild(a)
	a.click()
	a.remove()
	window.URL.revokeObjectURL(downloadURL)
}

export const entityToBlobURL = (
	tenantID: number,
	entity: Entity,
	blobType: BlobType,
	updated: string,
): string => {
	const epoch = Math.floor(new Date(updated).getTime() / 1000)
	return (
		"/f/blobs/" +
		tenantID +
		"/" +
		fromUUID(entity.id) +
		"." +
		blobTypeToExtension(blobType) +
		"?updated=" +
		epoch +
		"&type=" +
		blobType
	)
}

export const entityToFileURL = (
	tenantID: number,
	entity: Entity,
	fileType: FileType,
	updated: string,
): string => {
	const epoch = Math.floor(new Date(updated).getTime() / 1000)
	return (
		"/f/files/" +
		tenantID +
		"/" +
		fromUUID(entity.id) +
		"." +
		fileTypeToExtension(fileType) +
		"?updated=" +
		epoch +
		"&type=" +
		fileType
	)
}

interface Entity {
	id: string
}

const formatter = new Intl.NumberFormat("en-US")
export const formatNumber = (n: number | string): string => {
	return formatter.format(n as number)
}

export const articleize = (s: string) => {
	if (s.substring(0, 1) === "a") {
		return `an ${s}`
	}
	return `a ${s}`
}

export const humanize = (s: string): string => _.snakeCase(s).replace(/_/g, " ")

export const toFixed = (n: number, places: number): string => {
	if (n === 0) {
		return "0"
	}
	const out = _.trimEnd(n.toFixed(places), "0")
	return _.endsWith(out, ".") ? out.substring(0, _.size(out) - 1) : out
}

export const formatLength = (metric: boolean, value: number | undefined, fixed = 3): string => {
	if (!_.isNumber(value)) {
		return ""
	}
	if (metric) {
		return toFixed(value, fixed)
	} else {
		if (value === 0) {
			return "0"
		}
		const negative = value < 0
		if (negative) {
			value *= -1
		}
		const feet = Math.floor(value / 12)
		const inches = value - feet * 12
		return `${negative ? "-" : ""}${Math.floor(feet)}-${toFixed(inches, fixed)}`
	}
}

export const toPrecision = (n: number, places: number): number => {
	const whole = Math.trunc(n)
	const frac = parseFloat((n - whole).toPrecision(places + 1))
	return whole + frac
}

export const pluralize = (entity: string): string => {
	const e = _.camelCase(entity)
	if (_.endsWith(e, "y")) {
		return `${e.substring(0, _.size(e) - 1)}ies`
	}
	return `${e}s`
}

export const singularize = (entity: string): string => {
	const e = _.camelCase(entity)
	if (_.endsWith(e, "ies")) {
		return `${e.slice(0, -3)}y`
	} else if (_.endsWith(e, "s")) {
		return e.slice(0, -1)
	}
	return e
}

export const titleCase = (str?: string): string => {
	if (!str) {
		return ""
	}
	return _.lowerCase(_.startCase(str))
		.split(" ")
		.map((s, i) => {
			if (i === 0) {
				return _.upperFirst(s)
			} else if (_.size(s) === 1) {
				return _.upperCase(s)
			}
			return s
		})
		.join(" ")
}

const routeMap: { [key: string]: string } = {
	account: "account",
	assemblies: "assemblies",
	contacts: "contacts",
	customers: "customers",
	documents: "documents",
	eventLog: "logs",
	files: "files",
	jobs: "jobs",
	logs: "logs",
	materials: "materials",
	releaseItems: "release-items",
	releases: "releases",
	releaseUnits: "release-units",
	settings: "settings",
	shipmentItems: "shipment-items",
	shipments: "shipments",
	tags: "tags",
	trashItem: "trash",
	users: "users",
	workActions: "work-actions",
	workCellPrograms: "work-cell-programs",
	workCells: "work-cells",
	workCellServers: "work-cell-servers",
	workCellSession: "work-cell-servers",
	workDevices: "work-devices",
	workers: "workers",
	workEvents: "work-events",
	workFacilities: "work-facilities",
	workResources: "work-resources",

	assembly: "assemblies",
	contact: "contacts",
	customer: "customers",
	document: "documents",
	file: "files",
	job: "jobs",
	log: "logs",
	material: "materials",
	release: "releases",
	releaseItem: "release-items",
	releaseUnit: "release-units",
	runReports: "run-reports",
	setting: "settings",
	shipment: "shipments",
	shipmentItem: "shipment-items",
	tag: "tags",
	user: "users",
	workAction: "work-actions",
	workCell: "work-cells",
	workCellProgram: "work-cell-programs",
	workCellServer: "work-cell-servers",
	workDevice: "work-devices",
	worker: "workers",
	workEvent: "work-events",
	workFacility: "work-facilities",
	workResource: "work-resources",
}

export const urlTo = (
	name: string,
	opts?: undefined | string | Entity,
	params?: { [key: string]: string },
): string => {
	const parts = name.split("/")
	const routeName = parts[0]
	const action = parts.slice(1).join("/")
	const route = routeMap[_.camelCase(routeName)]
	if (!route) {
		return ""
	}
	let id = null
	if (_.isObject(opts)) {
		if (name === EntityType.WorkCellSession) {
			id = (opts as WorkCellSession).workCellServerID
		} else {
			id = opts.id
		}
	} else if (!_.isNil(opts)) {
		id = opts
	}
	if (!_.isNil(id) && isUUID(id)) {
		id = fromUUID(id)
	}
	if (!route) {
		throw new Error(`unknown route: '${routeName}'`)
	}
	let suffix = null
	switch (action) {
		case "edit":
			suffix = "edit"
			break
		case "new":
			suffix = "new"
			break
		case "show":
			break
		default:
			suffix = _.map(action.split("/"), (a) => {
				const name = routeMap[_.camelCase(a)]
				if (name) {
					return name
				}
				return a
			}).join("/")
	}
	let qs
	if (!_.isEmpty(params)) {
		qs = _.chain(params)
			.map((v, k) => {
				if (_.isEmpty(v)) {
					return undefined
				}
				return `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
			})
			.compact()
			.value()
	}
	const url = "/" + _.trimEnd(_.compact([route, id, suffix]).join("/"), "/")
	if (qs) {
		return url + "?" + qs.join("&")
	}
	return url
}

const converter = short(short.constants.flickrBase58, { consistentLength: true })

export const toUUID = (shortID?: string | string[] | null): string => {
	if (_.isEmpty(shortID)) {
		return ""
	}
	const id = (_.isArray(shortID) ? _.first(shortID) : shortID) || ""
	if (_.isString(id) && id.includes("-")) {
		return id
	}
	return converter.toUUID(id)
}

export const fromUUID = (uuid?: string): string => {
	if (!uuid) {
		return ""
	}
	return converter.fromUUID(uuid)
}

export const isUUID = (id?: string | string[] | null): boolean => {
	const uuid = (_.isArray(id) ? _.first(id) : id) || ""
	if (_.isEmpty(uuid)) {
		return false
	}
	if (_.size(uuid) === 36) {
		return _.every([8, 13, 18, 23], (v) => {
			return uuid.substring(v, v + 1) === "-"
		})
	}
	return false
}

export const uuidForURL = (id: string): string => {
	if (isUUID(id)) {
		return fromUUID(id)
	}
	return id
}
