import React from "react"
import _ from "lodash"

import { UserForm } from "@app/forms"
import { UserHeader } from "@app/headers"
import { FormActions, PageContainer, Await, Title, Button } from "@app/components"
import { User, UserUtils, AlertLevel, EntityType } from "@app/domain"
import { SaveUser, SaveUserParams, newSaveUserParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const UserEditPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()

	const [user, userLoading] = useEntity<User>(EntityType.User)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveUserParams>(newSaveUserParams(user))
	const [saving, setSaving] = React.useState<boolean>(false)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			const general = _.get(errors, "general")
			const title = general || t("user.notifications.failure.saving", count)
			addNotification({
				alertLevel: AlertLevel.Warn,
				title,
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setSaving(true)
		setErrors({})
		try {
			const resp = await SaveUser({ ...params, id: toUUID(_.get(user, "id")) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.user")
				setRedirect(urlTo("users", saved), {
					alertLevel: AlertLevel.Success,
					title: t("user.notifications.success.updated", { name: UserUtils.name(saved) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setSaving(false)
		return false
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("user.titles.edit", { name: UserUtils.name(user) })}</Title>
			<UserHeader user={user} view="edit" />
			<Await
				loading={userLoading}
				then={() => (
					<form onSubmit={submitHandler} noValidate>
						<div className="max-w-md px-2">
							<UserForm
								defaultParams={newSaveUserParams(user)}
								errors={errors}
								disabled={saving}
								onChange={(nextParams) => {
									setParams(nextParams)
								}}
							/>
						</div>
						<FormActions>
							<Button
								errors={errors}
								label={t("user.buttons.save")}
								disabled={saving}
								type="submit"
							/>
						</FormActions>
					</form>
				)}
			/>
		</PageContainer>
	)
}
