import React from "react"
import _ from "lodash"
import { Tooltip as ReactTooltip, PlacesType } from "react-tooltip"

interface ITooltipProps {
	content: string | JSX.Element
	children: React.ReactNode
	delayHide?: number
	place?: PlacesType
	className?: string
	float?: boolean
}

export const Tooltip: React.FC<ITooltipProps> = (props) => {
	const { content, delayHide = 0, children, place = "top", className = "", float = false } = props

	const [id] = React.useState(_.uniqueId())

	return (
		<>
			<ReactTooltip id={id} aria-haspopup="true" float={float} place={place} delayHide={delayHide}>
				{content}
			</ReactTooltip>
			<div data-tooltip-id={id} className={className}>
				{children}
			</div>
		</>
	)
}
