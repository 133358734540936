import React from "react"

import { Await, PageContainer } from "@app/components"
import { Release, EntityType } from "@app/domain"
import { TagCreateForm } from "@app/forms"
import { ReleaseHeader } from "@app/headers"
import { useEntity } from "@app/hooks"

export const TagCreatePage: React.FC = () => {
	const [release, releaseLoading] = useEntity<Release>(EntityType.Release)

	return (
		<PageContainer>
			<ReleaseHeader view="tags/create" release={release} />
			<Await
				loading={releaseLoading}
				then={() => (
					<div className="max-w-auto">
						<TagCreateForm release={release} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
