import React from "react"
import _ from "lodash"

import { Link, Icon } from "@app/components"

interface IButtonProps {
	add?: boolean
	busy?: boolean
	create?: boolean
	destroy?: boolean
	disabled?: boolean
	errors?: { [key: string]: string }
	footer?: boolean
	generate?: boolean
	icon?: string
	iconRight?: boolean
	label: string
	small?: boolean
	to?: string
}

const classesRed = "bg-red-500 text-white hover:bg-red-400 focus:border-red-500 focus:ring-red-500"

const classesGray = "bg-gray-300 text-gray-500 cursor-default"

export const Button: React.FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = (
	props,
) => {
	const {
		add,
		busy = false,
		className = "",
		create,
		destroy,
		disabled,
		errors,
		footer,
		generate,
		icon,
		iconRight = false,
		label,
		small,
		to,
		...rest
	} = props

	const hasErrors = _.size(errors) !== 0

	let iconName = icon

	let color =
		"bg-yellow-500 text-black hover:bg-yellow-300 focus:border-yellow-500 focus:ring-yellow-500"

	if (add || generate) {
		color = "bg-green-500 text-white hover:bg-green-400 focus:border-green-500 focus:ring-green-500"
		iconName = generate ? "Generate" : "Plus"
	} else if (destroy) {
		color = classesRed
		iconName = "Minus"
	} else if (create) {
		color = "bg-blue-500 text-white hover:bg-blue-400 focus:border-blue-500 focus:ring-blue-500"
		iconName = "Create"
	}

	if (hasErrors) {
		if (!busy) {
			iconName = "Exclamation"
		}
		color = classesRed
	}

	if (disabled) {
		color = classesGray
	}

	let classes =
		"inline-flex pointer-events-auto items-center justify-center rounded-full font-medium leading-5 focus:outline-none disabled:bg-gray-500 disabled:text-white"

	if (footer) {
		classes += " w-full md:w-auto shadow-lg"
	}

	if (small) {
		classes = `py-1 px-1 text-xs ${classes}`
	} else {
		classes = `w-full max-w-xl py-2 px-4 ${classes}`
	}

	classes = `${classes} ${color}`

	const button = (
		<button className={`${classes} ${className}`} disabled={disabled || busy} {...rest}>
			{iconName && !iconRight ? <Icon className="mx-1" name={iconName} /> : null}
			{_.isEmpty(label) ? null : (
				<span className="mx-1">
					{busy && !small ? (
						<Icon className="text-yellow" name="Cog" size="lg" spin={true} fixedWidth />
					) : (
						label
					)}
				</span>
			)}
			{iconName && iconRight ? <Icon className="mx-1" name={iconName} /> : null}
		</button>
	)

	return to ? <Link to={to}>{button}</Link> : button
}
