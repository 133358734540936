import React from "react"
import _ from "lodash"

import {
	Assembly,
	Contact,
	ContactUtils,
	Customer,
	Document,
	Entity,
	EntityType,
	Job,
	Release,
	ReleaseUnit,
	ReleaseUnitUtils,
	Shipment,
	User,
	UserUtils,
	WorkCell,
	WorkDevice,
	WorkDeviceUtils,
	Worker,
	WorkerUtils,
	WorkFacility,
} from "@app/domain"
import { useRelation } from "@app/hooks"
import { Link, BreadcrumbDelimiter } from "@app/components"
import { useSession, Translator } from "@app/contexts"
import { urlTo } from "@app/util"

import type { ILinkProps } from "@app/components"

interface IEntityBreadcrumbs {
	entity: Entity | undefined
	entityType: EntityType
	view: string
	section?: string
}

const nameForEntity = (t: Translator, entity: Entity, entityType: EntityType) => {
	switch (entityType) {
		case EntityType.Material:
			return _.get(entity, "controlCode", "...")
		case EntityType.WorkEvent:
			return _.get(entity, "description", "...")
		case EntityType.ReleaseUnit:
			return ReleaseUnitUtils.name(entity as ReleaseUnit) || "..."
		case EntityType.User:
			return UserUtils.name(entity as User) || "..."
		case EntityType.Worker:
			return WorkerUtils.name(entity as Worker) || "..."
		case EntityType.WorkDevice:
			return WorkDeviceUtils.name(t, entity as WorkDevice) || "..."
		case EntityType.Contact:
			return ContactUtils.name(entity as Contact) || "..."
		case EntityType.Tag:
			return _.get(entity, "referenceID", "...")
	}
	return _.get(entity, "name", "...")
}

const Crumb: React.FC<ILinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = (props) => {
	return <Link className="hover:text-gray-600 truncate" {...props} />
}

export const EntityBreadcrumbs: React.FC<IEntityBreadcrumbs> = (props) => {
	const { t } = useSession()
	const { entity, entityType, view, section } = props

	let assemblyID: string | undefined =
		entityType === EntityType.Tag ? undefined : _.get(entity, "assemblyID")
	let customerID: string | undefined = _.get(entity, "customerID")
	let jobID: string | undefined = _.get(entity, "jobID")
	let releaseID: string | undefined = _.get(entity, "releaseID")
	let shipmentID: string | undefined = _.get(entity, "shipmentID")
	const workCellID: string | undefined = _.get(entity, "workCellID")
	let workFacilityID: string | undefined = _.get(entity, "workFacilityID")

	// root is whether the breadcrumb is a top-level entity (no parent entity).
	let root = false

	switch (entityType) {
		case EntityType.User:
		case EntityType.Worker:
		case EntityType.Contact:
		case EntityType.Customer:
		case EntityType.WorkFacility:
		case EntityType.WorkAction:
			root = true
			break
		case EntityType.Document: {
			const documentable = (entity as Document)?.documentables[0]
			if (documentable) {
				switch (documentable.documentableType) {
					case "Assembly":
						assemblyID = documentable.documentableID
						break
					case "Customer":
						customerID = documentable.documentableID
						break
					case "Job":
						jobID = documentable.documentableID
						break
					case "Release":
						releaseID = documentable.documentableID
						break
					case "Shipment":
						shipmentID = documentable.documentableID
						break
				}
			}
		}
	}

	const [assembly] = useRelation<Assembly>(EntityType.Assembly, assemblyID)
	if (!jobID) {
		jobID = _.get(assembly, "jobID")
	}
	const [shipment] = useRelation<Shipment>(EntityType.Shipment, shipmentID)
	if (!jobID) {
		jobID = _.get(shipment, "jobID")
	}
	const [release] = useRelation<Release>(EntityType.Release, releaseID)
	if (!jobID) {
		jobID = _.get(release, "jobID")
	}
	const [job] = useRelation<Job>(EntityType.Job, jobID)
	if (!customerID) {
		customerID = _.get(job, "customerID")
	}
	const [customer, customerLoading] = useRelation<Customer>(EntityType.Customer, customerID)

	const [workCell] = useRelation<WorkCell>(EntityType.WorkCell, workCellID)
	if (!workFacilityID) {
		workFacilityID = _.get(workCell, "workFacilityID")
	}
	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, workFacilityID)

	const relations: { entity: Entity; entityType: EntityType }[] = []
	if (customer) {
		relations.push({ entity: customer, entityType: EntityType.Customer })
	}
	if (job) {
		relations.push({ entity: job, entityType: EntityType.Job })
	}
	if (assembly) {
		relations.push({ entity: assembly, entityType: EntityType.Assembly })
	}
	if (release) {
		relations.push({ entity: release, entityType: EntityType.Release })
	}
	if (shipment) {
		relations.push({ entity: shipment, entityType: EntityType.Shipment })
	}

	if (workFacility && !customer && !customerLoading) {
		relations.push({ entity: workFacility, entityType: EntityType.WorkFacility })
	}
	if (workCell && !release) {
		relations.push({ entity: workCell, entityType: EntityType.WorkCell })
	}

	if (!_.isNil(entity)) {
		relations.push({ entity, entityType })
	}

	let prev: { entity: Entity; entityType: EntityType } | undefined
	const links = _.reduce(
		relations,
		(result: JSX.Element[], r) => {
			if (!_.isNil(prev)) {
				result.push(
					<Crumb
						key={prev.entityType}
						to={urlTo(`${prev.entityType}/${r.entityType}`, prev.entity)}
					>
						{t(`${_.camelCase(r.entityType)}.titles.list`)}
					</Crumb>,
				)
			}
			prev = r
			result.push(
				<Crumb
					key={r.entity.id}
					title={t(`entity.${_.camelCase(r.entityType)}`)}
					to={urlTo(r.entityType, r.entity)}
				>
					{nameForEntity(t, r.entity, r.entityType)}
				</Crumb>,
			)
			return result
		},
		[],
	)

	_.each(view.split("/"), (v, i) => {
		if (i > 0) {
			links.push(
				<Crumb key={v} to={undefined}>
					{t(`common.tabs.${v}`)}
				</Crumb>,
			)
		} else {
			links.push(
				<Crumb key={section || v} to={urlTo(`${entityType}/${section || v}`, entity)}>
					{t(`common.tabs.${section || v}`)}
				</Crumb>,
			)
		}
	})

	if (root || _.size(links) <= 2) {
		links.unshift(
			<Crumb key="root" to={urlTo(entityType)}>
				{t(`${_.camelCase(entityType)}.titles.list`)}
			</Crumb>,
		)
	}

	return (
		<nav className="flex mb-2 lg:mb-4 text-xs font-semibold uppercase text-gray-500">
			{_.isEmpty(links)
				? "..."
				: _.reduce(
						links,
						(result: JSX.Element[], link, i) => {
							result.push(link)
							if (i !== _.size(links) - 1) {
								result.push(<BreadcrumbDelimiter key={i} />)
							}
							return result
						},
						[],
				  )}
		</nav>
	)
}
