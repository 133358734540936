import React from "react"
import _ from "lodash"
import { differenceInSeconds, parseJSON } from "date-fns"

import { TrashItems } from "@app/api"
import { useSession, INotificationProps } from "@app/contexts"
import { EntityType, AlertLevel } from "@app/domain"
import { fromUUID, toUUID } from "@app/util"
import { InterpolationOptions } from "node-polyglot"

export const useTrash = (
	entityType: EntityType,
	setRedirect: (url: string, notification?: INotificationProps) => void,
	created = "",
	maxAgeSeconds = 0,
): [(id: string, opts: InterpolationOptions) => void, boolean] => {
	const { t, handleError } = useSession()

	const [now, setNow] = React.useState(new Date())
	const age = differenceInSeconds(now, parseJSON(created))

	React.useEffect(() => {
		if (maxAgeSeconds > 0 && !isNaN(age) && age < maxAgeSeconds) {
			const timeoutID = setTimeout(
				() => {
					setNow(new Date())
				},
				(maxAgeSeconds - age) * 1000,
			)
			return () => {
				clearTimeout(timeoutID)
			}
		}
	}, [age])

	const trashable = maxAgeSeconds == 0 || age < maxAgeSeconds

	const trashHandler = async (id: string, opts: InterpolationOptions) => {
		try {
			const resp = await TrashItems({
				items: [
					{
						id: toUUID(id),
						entityType: entityType,
					},
				],
			})
			if (resp.ok) {
				setRedirect(`/trash?id=${fromUUID(id)}`, {
					alertLevel: AlertLevel.Success,
					title: t(`${_.camelCase(entityType)}.notifications.success.deleted`, opts),
				})
			}
		} catch (err) {
			handleError(err)
		}
	}

	return [trashHandler, trashable]
}
