import { rpc, Result } from "@app/api"
import { EntityType } from "@app/domain"

export type TrackingCodeEntity = {
	id: string
	type: EntityType
	description: string
}

export type TrackingCodeParams = {
	action?: TrackingCodeEntity
	locations: TrackingCodeEntity[]
	subjects: TrackingCodeEntity[]
	actors: TrackingCodeEntity[]
}

export type CreateTrackingSheetParams = {
	trackingCodes: TrackingCodeParams[]
}

export type CreateTrackingSheetResult = {
	id: string
}

export const CreateTrackingSheet = async (
	params: CreateTrackingSheetParams,
): Promise<Result<CreateTrackingSheetResult>> => {
	const data = await rpc("CreateTrackingSheet", {
		...params,
	})
	return data as Result<CreateTrackingSheetResult>
}
