import React from "react"
import { Redirect, useParams } from "react-router-dom"

import { LoginByToken } from "@app/api"
import { Title } from "@app/components"
import { useSession, useHTTPStatus } from "@app/contexts"
import { toUUID } from "@app/util"
import { AlertLevel } from "@app/domain"

export const LoginByTokenPage: React.FC = () => {
	const { t, user, setSession, handleError, addNotification } = useSession()
	const { setHTTPStatus } = useHTTPStatus()
	const { id = "", token = "" } = useParams<{ id: string | undefined; token: string | undefined }>()

	React.useEffect(() => {
		let canceled = false
		const loginByToken = async () => {
			try {
				const resp = await LoginByToken({ id: toUUID(id), token })
				if (canceled) {
					return
				}
				if (!resp.ok) {
					setHTTPStatus(404)
				} else {
					const session = resp.result.session
					const { firstName, lastName } = session.user
					setSession(session)
					addNotification({
						alertLevel: AlertLevel.Success,
						title: t("loginByToken.loginSuccessful"),
						subtitle: t("loginByToken.loginUser", {
							firstName,
							lastName,
						}),
					})
					return false
				}
			} catch (err) {
				if (canceled) {
					return
				}
				handleError(err)
				setHTTPStatus(404)
			}
		}
		loginByToken()
		return () => {
			canceled = true
		}
	}, [id])

	if (user) {
		return <Redirect to="/" />
	}

	return (
		<div>
			<Title>{t("loginByToken.title")}</Title>
		</div>
	)
}
