import React from "react"
import _ from "lodash"
import { compareAsc, isSameDay } from "date-fns"

import { Link, MultiDateInput, Property, Input, EntitySearchSelect, Icon } from "@app/components"
import { useSession } from "@app/contexts"
import { GetReleaseItems, GetWorkCells, GetWorkFacilities } from "@app/api"
import {
	EntityType,
	Release,
	ReleaseItem,
	WorkCell,
	WorkFacility,
	ReleaseItemWorkCellDateQuantityMap,
} from "@app/domain"
import { urlTo, locale } from "@app/util"
import { useRelation } from "@app/hooks"

interface IReleaseDetailsProps {
	release?: Release
	section?: string
	selectable?: boolean
	scheduleable?: boolean
	onChange?: (releaseItemWorkCellDateQuantityMap: ReleaseItemWorkCellDateQuantityMap) => void
	onChangeIDs?: (releaseItemIDs: string[]) => void
	untaggedReleaseItemIDs?: Set<string>
}

export const ReleaseDetails: React.FC<IReleaseDetailsProps> = (props) => {
	const { t, handleError } = useSession()

	const {
		release,
		section,
		selectable,
		scheduleable,
		onChange,
		onChangeIDs,
		untaggedReleaseItemIDs = new Set(),
	} = props
	const [releaseItems, setReleaseItems] = React.useState<ReleaseItem[]>([])
	const [releaseItemWorkCellDateQuantityMap, setReleaseItemWorkCellDateQuantityMap] =
		React.useState<ReleaseItemWorkCellDateQuantityMap>({})
	const [workCellIDInfoMap, setWorkCellIDInfoMap] = React.useState<{
		[key: string]: { name: string; timezone: string }
	}>({})

	const [checked, setChecked] = React.useState({})

	const [workFacility] = useRelation<WorkFacility>(
		EntityType.WorkFacility,
		release?.workFacilityID ?? undefined,
	)

	React.useEffect(() => {
		let canceled = false
		const getReleaseItems = async () => {
			try {
				const resp = await GetReleaseItems({ releaseID: _.get(release, "id", ""), limit: 500 })
				if (canceled) {
					return
				}
				if (resp.ok) {
					const next = _.get(resp, "result.releaseItems", [])
					setReleaseItems(next)
					setChecked(() => {
						return _.reduce(
							next,
							(acc, item) => {
								acc[item.id] = true
								return acc
							},
							{},
						)
					})
				}
			} catch (err) {
				handleError(err)
			}
		}
		if (!_.isNil(release)) {
			getReleaseItems()
		}
		return () => {
			canceled = true
		}
	}, [release])

	React.useEffect(() => {
		if (onChange) {
			onChange(
				Object.fromEntries(
					Object.keys(checked)
						.filter((releaseItemID) => checked[releaseItemID])
						.map((releaseItemID) => [
							releaseItemID,
							releaseItemWorkCellDateQuantityMap?.[releaseItemID] ?? {},
						])
						.filter(([, schedule]) => !_.isEmpty(schedule)),
				),
			)
		}
		if (onChangeIDs) {
			const ids: string[] = _.reduce(
				releaseItems,
				(acc: string[], item) => {
					if (_.get(checked, item.id, false)) {
						acc.push(item.id)
					}
					return acc
				},
				[],
			)
			onChangeIDs(ids)
		}
	}, [releaseItemWorkCellDateQuantityMap, checked])

	if (_.isNil(release)) {
		return null
	}

	const units = release.metric ? "metric" : "imperial"

	if (section === "items") {
		return (
			<div className="divide-y divide-gray-300 border-t border-b border-gray-300">
				<div className="flex flex-row gap-x-2 md:gap-x-4 py-2 px-4 text-gray-700">
					<div className="flex-none w-4 text-center">
						{selectable ? (
							<input
								type="checkbox"
								name={"toggle-all"}
								defaultChecked={true}
								onChange={(evt) => {
									const { checked } = evt.currentTarget
									setChecked((prev) => {
										return _.reduce(
											prev,
											(acc, _v, k) => {
												acc[k] = checked
												return acc
											},
											{},
										)
									})
								}}
							/>
						) : null}
					</div>
					<div className="flex-grow text-left">{t("releaseItem.labels.description")}</div>
					<div className="flex-none w-16 md:w-28 text-left">
						{t("releaseItem.labels.controlCodes")}
					</div>
					<div className="flex-none w-16 md:w-16 text-right">{t("releaseItem.labels.weight")}</div>
					<div className="flex-none w-16 md:w-16 text-right">
						{t("releaseItem.labels.quantity")}
					</div>
				</div>
				{releaseItems.map((item, i) => {
					return (
						<div key={i}>
							<div className="flex flex-row gap-x-2 md:gap-x-4 py-2 px-4" key={item.id}>
								<div className="flex-none w-4 text-center text-gray-700 text-xs leading-6">
									{selectable ? (
										<input
											type="checkbox"
											name={item.id}
											checked={_.get(checked, item.id, false)}
											onChange={() => {
												setChecked((prev) => {
													const next = { ...prev }
													next[item.id] = !prev[item.id]
													return next
												})
											}}
										/>
									) : (
										i + 1
									)}
								</div>
								<div className="flex-grow text-left">
									{item.assemblyID ? (
										<Link styled to={urlTo("assembly", item.assemblyID)}>
											{item.description}
										</Link>
									) : (
										item.description
									)}
								</div>
								<div className="flex-none w-16 md:w-28 text-left">{item.controlCodes}</div>
								<div className="flex-none w-16 md:w-16 text-right">
									{item.weight}&nbsp;
									{_.isNil(item.weight) ? null : `${t(`units.${units}.weightAbbr`)}`}
								</div>
								<div className="flex-none w-16 md:w-16 text-right">{item.quantity}</div>
							</div>
							{scheduleable &&
								(untaggedReleaseItemIDs.has(item.id) ? (
									<div className="flex flex-row space-x-2 md:space-x-4 py-2 px-4">
										<div className="text-gray-600">{t("releaseItem.hints.missingTags")}</div>
									</div>
								) : (
									<div className="flex flex-row space-x-2 md:space-x-4 py-2 px-4">
										<div className="justify-start align-top self-start place-self-start text-left flex gap-2 w-full flex-col">
											<div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 mb-3">
												<EntitySearchSelect
													getResults={(query: string) => GetWorkCells({ query, limit: 5 })}
													placeholder="Search Work Cells"
													respKey="workCells"
													getEntityName={(e: WorkCell) => e.name}
													onSelect={async (workcell: WorkCell) => {
														setReleaseItemWorkCellDateQuantityMap((prev) => ({
															...prev,
															[item.id]: { ...prev[item.id], [workcell.id]: [] },
														}))
														const resp = await GetWorkFacilities({ ids: [workcell.workFacilityID] })
														if (resp.ok) {
															const timezone = resp.result.workFacilities[0].timezone
															setWorkCellIDInfoMap((prev) => ({
																...prev,
																[workcell.id]: { name: workcell.name, timezone },
															}))
														}
													}}
													selectedIDs={Object.keys(
														releaseItemWorkCellDateQuantityMap[item?.id] ?? {},
													)}
												/>
											</div>
											{Object.entries(releaseItemWorkCellDateQuantityMap[item?.id] ?? [])?.map(
												([workCellID, dateQuantityList]) => {
													const scheduledQuantity = Object.values(
														releaseItemWorkCellDateQuantityMap[item?.id],
													).reduce(
														(acc, elt) =>
															acc +
															Object.values(elt)
																.flat()
																.reduce((acc, elt) => acc + elt.quantity, 0),
														0,
													)
													const overQuantity = scheduledQuantity > item.quantity
													return (
														<div
															key={workCellID}
															className="flex gap-2 items-center flex-wrap w-[76vw]"
														>
															<Icon
																className="text-xs cursor-pointer hover:text-red-600 transition-colors"
																name="Times"
																onClick={() => {
																	setReleaseItemWorkCellDateQuantityMap((prev) => ({
																		...prev,
																		[item.id]: _.omit(prev[item.id], workCellID),
																	}))
																}}
															/>
															<div>{workCellIDInfoMap[workCellID]?.name}</div>
															<MultiDateInput
																timezone={workCellIDInfoMap[workCellID]?.timezone}
																defaultValue=""
																onChange={(dates) =>
																	setReleaseItemWorkCellDateQuantityMap((prev) => {
																		const prevFiltered =
																			prev?.[item?.id]?.[workCellID]?.filter(
																				({ date }) => dates?.some((d) => isSameDay(d, date)),
																			) ?? []
																		const datesAdded =
																			dates
																				?.filter(
																					(d) =>
																						prevFiltered?.every(({ date }) => !isSameDay(d, date)),
																				)
																				?.map((date) => ({ date, quantity: 0 })) ?? []
																		return {
																			...prev,
																			[item?.id]: {
																				...prev?.[item?.id],
																				[workCellID]: [...prevFiltered, ...datesAdded],
																			},
																		}
																	})
																}
																name="Dates"
																label="Select Dates"
																hideInputBox
																clearable
															/>
															{dateQuantityList
																?.sort((a, b) => compareAsc(a.date, b.date))
																?.map(({ date, quantity }) => {
																	return (
																		<div key={date.toISOString()} className="w-24">
																			<Input
																				key={date.toISOString()}
																				min={0}
																				max={item.quantity}
																				defaultValue={quantity}
																				label={date.toLocaleDateString(locale, {
																					month: "short",
																					day: "numeric",
																					weekday: "short",
																				})}
																				name={date.toDateString()}
																				type="number"
																				error={
																					overQuantity
																						? `Scheduled quantity greater than release quantity (${item.quantity})`
																						: undefined
																				}
																				onChange={(e) =>
																					setReleaseItemWorkCellDateQuantityMap((prev) => ({
																						...prev,
																						[item?.id]: {
																							...prev?.[item?.id],
																							[workCellID]:
																								prev?.[item?.id]?.[workCellID]?.map((elt) =>
																									isSameDay(date, elt.date)
																										? {
																												...elt,
																												quantity: Number(e.target["value"]),
																										  }
																										: elt,
																								) ?? [],
																						},
																					}))
																				}
																			/>
																		</div>
																	)
																})}
														</div>
													)
												},
											)}
										</div>
									</div>
								))}

							{_.isEmpty(item.attributes) ? null : (
								<div className="flex flex-row gap-x-2 md:gap-x-4 pb-2 px-4">
									<div className="flex-none w-4"></div>
									<div>
										<Property group={t("attribute.header")} />
										{_.map(item.attributes, (attr, i) => {
											return <Property key={i} label={attr.name} value={attr.value} lineBreaks />
										})}
									</div>
								</div>
							)}
						</div>
					)
				})}
			</div>
		)
	}
	return (
		<div className="pt-1 max-w-lg">
			<Property label={t("common.labels.referenceID")} id={release.referenceID} />
			<Property label={t("release.labels.name")} value={release.name} />
			<Property label={t("common.labels.metric")} value={release.metric} />
			<Property group={t("common.headers.associations")} />
			<Property
				label={t("release.labels.customerID")}
				relation={EntityType.Customer}
				value={release.customerID}
			/>
			<Property label={t("release.labels.jobID")} relation={EntityType.Job} value={release.jobID} />
			<Property
				label={t("release.labels.workFacilityID")}
				relation={EntityType.WorkFacility}
				value={release.workFacilityID}
			/>
			<Property group={t("release.headers.keyDates")} />
			<Property
				label={t("release.labels.received")}
				date={release.keyDates.received}
				dateTimezone={workFacility?.timezone}
			/>
			<Property
				label={t("release.labels.deliverBy")}
				date={release.keyDates.deliverBy}
				dateTimezone={workFacility?.timezone}
			/>
			<Property
				label={t("release.labels.produced")}
				date={release.keyDates.produced}
				dateTimezone={workFacility?.timezone}
			/>
			<Property
				label={t("release.labels.shipped")}
				date={release.keyDates.shipped}
				dateTimezone={workFacility?.timezone}
			/>
			{_.size(release.attributes) > 0 ? <Property group={t("attribute.header")} /> : null}
			{_.map(release.attributes, (attr, i) => {
				return <Property key={i} label={attr.name} value={attr.value} lineBreaks />
			})}
			{_.size(release.addresses) > 0 ? <Property group={t("address.header")} /> : null}
			{_.map(release.addresses, (v, i) => {
				let label = t("address.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} address={v} />
			})}
			{_.size(release.contactables) > 0 ? <Property group={t("contactable.header")} /> : null}
			{_.map(release.contactables, (v, i) => {
				let label = t("contactable.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} relation={EntityType.Contact} value={v.contactID} />
			})}
		</div>
	)
}
