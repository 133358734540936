import _ from "lodash"
import { Cache, Result } from "@app/api"
import {
	EntityType,
	ContactUtils,
	Entity,
	TrashedItem,
	Contactable,
	Documentable,
} from "@app/domain"

import type { Contact, Document } from "@app/domain"

export const JoinRelations = (data: Result<unknown>): Result<unknown> => {
	let result = JoinContactables(data)
	result = JoinDocumentables(data)
	if (_.has(result, "result.trashedItems")) {
		result = JoinTrashedItems(result)
	}
	return result
}

const pluralMap = {
	assembly: "assemblies",
	contact: "contacts",
	customer: "customers",
	document: "documents",
	job: "jobs",
	log: "logs",
	release: "releases",
	releaseItem: "releaseItems",
	shipment: "shipments",
	shipmentItem: "shipmentItems",
	tag: "tags",
	user: "users",
	workFacility: "workFacilities",
}

const JoinTrashedItems = (data: Result<unknown>): Result<unknown> => {
	const maps = _.reduce(
		{
			contacts: {},
			customers: {},
			documents: {},
			jobs: {},
			releases: {},
			shipments: {},
			workFacilities: {},
		},
		(acc, _v, path) => {
			acc[path] = {}
			_.each(_.get(data, `result.${path}`), (entity: Entity) => {
				acc[path][entity.id] = entity
			})
			return acc
		},
		{},
	)
	const joined = _.map(_.get(data, `result.trashedItems`), (entity: TrashedItem) => {
		if (entity.entityType === EntityType.Contactable) {
			const contact = _.get(maps, `contacts.${(entity.meta as Contactable).contactID}`)
			const contactableID = _.get(entity, "meta.contactableID")
			const path: string = _.last(_.get(entity, "entitySubtype", "").split(".")) || ""
			const subtype = _.get(pluralMap, path)
			if (contact && contactableID && subtype) {
				_.set(entity, "meta.contact", contact)
				const relatedEntity = _.get(maps, `${subtype}.${contactableID}`)
				const relationName = relatedEntity.name
				const contactName = ContactUtils.name(contact)
				_.set(entity, "name", `${relationName} to ${contactName}`)
			}
		}
		return entity
	})
	_.set(data, `result.trashedItems`, joined)
	return data
}

const JoinContactables = (data: Result<unknown>): Result<unknown> => {
	const contactsByID = _.reduce(
		_.get(data, "result.contacts"),
		(acc, v: Contact) => {
			acc[v.id] = v
			return acc
		},
		{},
	)
	const contactablesByContactID = {}
	const contactablesByDocumentableID = _.reduce(
		_.get(data, "result.contactables"),
		(acc: { [key: string]: Contact[] }, v: Contactable & Contact) => {
			contactablesByContactID[v.contactID] = contactablesByContactID[v.contactID] || []
			contactablesByContactID[v.contactID].push(v)
			v.contact = contactsByID[v.contactID]
			acc[v.contactableID] = acc[v.contactableID] || []
			let insert = 0
			_.each(acc[v.contactableID], (contact, i) => {
				if (contact.lastName === v.lastName && contact.firstName > v.firstName) {
					return false
				} else if (contact.lastName > v.lastName) {
					return false
				}
				insert = i
			})
			acc[v.contactableID].splice(insert, 0, v)
			return acc
		},
		{},
	)
	_.each(["customers", "jobs", "releases", "shipments", "workFacilities"], (path) => {
		const joined = _.map(_.get(data, `result.${path}`), (entity: Entity) => {
			const contactables = contactablesByDocumentableID[entity.id] || []
			return { contactables, ...entity }
		})
		_.set(data, `result.${path}`, joined)
	})
	_.each(["contacts"], (path) => {
		const joined = _.map(_.get(data, `result.${path}`), (entity: Entity) => {
			const contactables = contactablesByContactID[entity.id] || []
			return { contactables, ...entity }
		})
		_.set(data, `result.${path}`, joined)
	})
	return data
}

const JoinDocumentables = (data: Result<unknown>): Result<unknown> => {
	const documentsByID = _.reduce(
		_.get(data, "result.documents"),
		(acc, v: Documentable) => {
			acc[v.id] = v
			return acc
		},
		{},
	)
	const documentablesByDocumentID = {}
	const documentablesByDocumentableID = _.reduce(
		_.get(data, "result.documentables"),
		(acc: { [key: string]: Document[] }, v: Documentable & Document) => {
			documentablesByDocumentID[v.documentID] = documentablesByDocumentID[v.documentID] || []
			documentablesByDocumentID[v.documentID].push(v)
			v.document = documentsByID[v.documentID]
			acc[v.documentableID] = acc[v.documentableID] || []
			let insert = 0
			_.each(acc[v.documentableID], (document, i) => {
				if (document.name > v.name) {
					return false
				}
				insert = i
			})
			acc[v.documentableID].splice(insert, 0, v)
			return acc
		},
		{},
	)
	_.each(["customers", "jobs", "releases", "shipments"], (path) => {
		const joined = _.map(_.get(data, `result.${path}`), (entity: Entity) => {
			const documentables = documentablesByDocumentableID[entity.id] || []
			return { documentables, ...entity }
		})
		_.set(data, `result.${path}`, joined)
	})
	_.each(["documents"], (path) => {
		const joined = _.map(_.get(data, `result.${path}`), (entity: Entity) => {
			const documentables = documentablesByDocumentID[entity.id] || []
			return { documentables, ...entity }
		})
		_.set(data, `result.${path}`, joined)
	})
	return data
}

export const ReceivedResult = <T>(data: Result<unknown>): Result<T> => {
	data = JoinRelations(data)
	Cache.scan(data)
	return data as Result<T>
}
