import React from "react"

import { useSession } from "@app/contexts"

interface FieldErrorProps {
	label: string
	error?: string
	message?: string
	collapse?: boolean
}

const ZeroWidthSpace = <span>&#8203;</span>

export const FieldError: React.FC<FieldErrorProps> = (props: FieldErrorProps) => {
	const { label, error, message, collapse = true } = props
	const { t } = useSession()
	let msg: string | undefined
	if (error) {
		msg = (message || error)?.replace("$field", label || t("validation.this"))
	}
	return (
		<p className="text-xs text-red-500 mt-1">
			{msg}
			{collapse ? "" : ZeroWidthSpace}
		</p>
	)
}
