import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { WorkDevice, AlertLevel, EntityType, WorkDeviceUtils } from "@app/domain"
import { WorkDeviceForm } from "@app/forms"
import { WorkDeviceHeader } from "@app/headers"
import { newSaveWorkDeviceParams, SaveWorkDevice, SaveWorkDeviceParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkDeviceEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workDevice, workDeviceLoading] = useEntity<WorkDevice>(EntityType.WorkDevice)
	const { id, name } = _.assign({ id: "", name: "..." }, workDevice)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveWorkDeviceParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkDevice({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workDevices?.[0]
				setRedirect(urlTo("workDevices", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workDevice.notifications.success.updated", {
						name: WorkDeviceUtils.name(t, saved),
					}),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workDevice.titles.edit", { name })}</Title>
			<WorkDeviceHeader workDevice={workDevice} view="edit" />
			<Await
				loading={workDeviceLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkDeviceForm
							busy={busy}
							defaultParams={newSaveWorkDeviceParams(workDevice)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
