import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import type { User } from "@app/domain"

interface IUserDetailsProps {
	user?: User
}

export const UserDetails: React.FC<IUserDetailsProps> = (props) => {
	const { t } = useSession()

	const { user } = props

	if (_.isNil(user)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("user.labels.firstName")} value={user.firstName} />
			<Property label={t("user.labels.lastName")} value={user.lastName} />
			<Property label={t("user.labels.email")} value={user.email} email={user.email} />
			<Property label={t("user.labels.timezone")} timezone={user.timezone} />
			{_.size(user.permissions) > 0 ? <Property group={t("user.labels.permissions")} /> : null}
			{_.map(user.permissions, (v, i) => {
				return (
					<Property
						key={`permission-${i}`}
						label={t("user.labels.permission")}
						value={t(`userPermission.types.${_.camelCase(v)}`)}
					/>
				)
			})}
			<Property label={t("user.labels.disabled")} value={user.disabled} />
		</div>
	)
}
