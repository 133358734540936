import React from "react"

import { Await, PageContainer, FileDetails } from "@app/components"
import { FileHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, File } from "@app/domain"

export const FileShowPage: React.FC = () => {
	const [file, fileLoading] = useEntity<File>(EntityType.File)

	return (
		<PageContainer>
			<FileHeader file={file} />
			<Await
				loading={fileLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<FileDetails file={file} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
