import React from "react"
import _ from "lodash"

import { Icon } from "@app/components"
import { TagColor } from "@app/domain"

interface IColorOption {
	color: TagColor
	checked?: boolean
	disabled?: boolean
	readonly?: boolean
	onChange?: (v: boolean) => void
}

export const ColorOption: React.FC<IColorOption> = (props) => {
	const { readonly = false, disabled = false, checked = false, onChange = _.noop } = props
	const color = _.get(props, "color", TagColor.None)

	let textColor = "",
		bgColor = ""
	switch (color) {
		case TagColor.None:
			textColor = "text-gray-500"
			bgColor = "bg-gray-500"
			break
		case TagColor.White:
			textColor = "text-black"
			bgColor = "bg-gray-300"
			break
		case TagColor.Red:
			textColor = "text-red"
			bgColor = "bg-red"
			break
		case TagColor.Pink:
			textColor = "text-pink"
			bgColor = "bg-pink"
			break
		case TagColor.Orange:
			textColor = "text-orange"
			bgColor = "bg-orange"
			break
		case TagColor.Yellow:
			textColor = "text-yellow"
			bgColor = "bg-yellow"
			break
		case TagColor.Green:
			textColor = "text-green"
			bgColor = "bg-green"
			break
		case TagColor.Blue:
			textColor = "text-blue"
			bgColor = "bg-blue"
			break
		case TagColor.Purple:
			textColor = "text-purple"
			bgColor = "bg-purple"
	}
	if (checked) {
		if (color === TagColor.White) {
			textColor = "text-black"
		} else {
			textColor = "text-white"
		}
	} else {
		bgColor = ""
	}
	if (disabled && !checked) {
		textColor = "text-gray"
	}

	return (
		<button
			type="button"
			disabled={disabled || readonly}
			className={`${disabled ? "cursor-default" : ""} ${checked ? bgColor : ""} px-1.5 pb-0.5`}
			onClick={() => {
				onChange(!checked)
			}}
		>
			<span
				className={`leading-3 inline-flex items-center ${bgColor} ${textColor} font-bold text-sm tracking-tighter`}
			>
				{checked ? (
					<>
						{readonly ? null : <Icon name="Check" className="mr-1" size="xs" />}
						<span>{_.toUpper(color)}</span>
					</>
				) : color === TagColor.None ? (
					"NONE"
				) : (
					_.toUpper(color.substring(0, 2))
				)}
			</span>
		</button>
	)
}
