import _ from "lodash"

import {
	AddressParams,
	AttributeParams,
	Batch,
	compactIDs,
	ContactableParams,
	ReceivedResult,
	rpc,
} from "@app/api"

import type { Result } from "@app/api"
import type { Job, Pagination, Entity } from "@app/domain"

export type SaveJobParams = {
	id?: string
	customerID: string
	workFacilityIDs: string[]

	addresses: AddressParams[]
	attributes: AttributeParams[]
	countryOfOrigin: string
	metric: boolean
	name: string

	contactables: ContactableParams[]
	removeContactables: string[]
}

export const newSaveJobParams = (job?: Job): SaveJobParams => {
	return {
		id: _.get(job, "id", ""),
		customerID: _.get(job, "customerID", ""),
		workFacilityIDs: _.get(job, "workFacilityIDs", []),

		addresses: _.get(job, "addresses", []),
		attributes: _.get(job, "attributes", []),
		countryOfOrigin: _.get(job, "countryOfOrigin", ""),
		metric: _.get(job, "metric", false),
		name: _.get(job, "name", ""),

		contactables: _.get(job, "contactables", []),
		removeContactables: [],
	}
}

export type SaveJobResult = {
	jobs: Job[]
}

export const SaveJob = async (params: SaveJobParams): Promise<Result<SaveJobResult>> => {
	const data = await rpc("SaveJob", params, { minDuration: 300 })
	return ReceivedResult<GetJobsResult>(data)
}

export type GetJobsParams = {
	ids?: string[]
	includeIDs?: string[]

	contactID?: string
	customerID?: string
	workFacilityIDs?: string[]

	query?: string
	withoutCount?: boolean
	withoutContactables?: boolean

	limit?: number
	offset?: number
}

export type GetJobsResult = {
	jobs: Job[]
	pagination: Pagination
}

export const GetJobs = async (params: GetJobsParams): Promise<Result<GetJobsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetJobs", "jobs", id).then((job?: Entity) => {
			return {
				ok: true,
				result: { jobs: _.compact([job as Job]) },
			} as Result<GetJobsResult>
		})
	}
	const data = await rpc("GetJobs", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetJobsResult>(data)
}
