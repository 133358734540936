import React from "react"
import _ from "lodash"

interface PropsLogo {
	className?: string
	width?: number | string
	height?: number | string
	rotate?: boolean
}

const WIDTH = 637
const HEIGHT = 157

export const Logo: React.FC<PropsLogo> = (props: PropsLogo) => {
	const { className = "", rotate = false } = props
	let { width, height } = props

	const ratio = rotate ? WIDTH / HEIGHT : HEIGHT / WIDTH
	const transform = rotate ? `translate(${HEIGHT},0) rotate(90)` : ""

	if (_.isNumber(width)) {
		height = `${ratio * width}px`
	} else if (_.isNumber(height)) {
		width = `${ratio / height}px`
	}

	return (
		<svg
			className={className}
			fill="currentColor"
			width={rotate ? height : width}
			height={rotate ? width : height}
			viewBox={rotate ? `0 0 ${HEIGHT} ${WIDTH}` : `0 0 ${WIDTH} ${HEIGHT}`}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				transform={transform}
				d="M96.9 3.07V28.56H63.27V153.93H34.43V28.55H0.790009V3.06H96.9V3.07ZM113 51.54C113 14.36 132.69 0.999996 160.68 0.999996C188.67 0.999996 208.32 14.37 208.32 51.55V105.46C208.32 142.65 188.68 156.03 160.68 156.03C132.68 156.03 113 142.65 113 105.45V51.54ZM141.84 109.21C141.84 125.93 149.15 130.53 160.64 130.53C172.13 130.53 179.45 125.93 179.45 109.21V47.79C179.45 31.07 172.13 26.47 160.64 26.47C149.15 26.47 141.84 31.07 141.84 47.79V109.21ZM303.33 141.39C295.6 150.16 287.24 156 276.38 156C248.38 156 228.74 142.63 228.74 105.43V51.53C228.74 14.35 248.38 0.979996 276.38 0.979996C305.84 0.979996 321.71 17.9 322.76 52.17H293.92C293.92 32.73 286.83 26.47 276.38 26.47C264.89 26.47 257.57 31.07 257.57 47.79V109.21C257.57 125.93 264.89 130.53 276.38 130.53C287.24 130.53 293.92 122.59 293.92 109.63V100.44H273.66V76.62H322.76V153.93H307.76L303.33 141.39ZM418.38 141.39C410.65 150.16 402.29 156.01 391.43 156.01C363.43 156.01 343.79 142.64 343.79 105.44V51.53C343.79 14.35 363.43 0.979996 391.43 0.979996C420.89 0.979996 436.76 17.9 437.81 52.17H409C409 32.73 401.91 26.47 391.46 26.47C379.97 26.47 372.65 31.07 372.65 47.79V109.21C372.65 125.93 379.97 130.53 391.46 130.53C402.32 130.53 409 122.59 409 109.63V100.44H388.71V76.62H437.81V153.93H422.76L418.38 141.39ZM461.24 153.93V3.07H490.08V128.43H536.46V153.93H461.24ZM555.13 153.93V3.07H634.13V28.56H584V63.25H622.24V88.74H584V128.44H636.23V153.94H555.13V153.93Z"
			/>
		</svg>
	)
}
