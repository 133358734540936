import React from "react"
import _ from "lodash"

import {
	Button,
	DateTimeInput,
	FormActions,
	Input,
	JobSelect,
	Toggle,
	WorkFacilitySelect,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveReleaseParams } from "@app/api"
import { AddressesForm, ContactablesForm, AttributesForm } from "@app/forms"
import { AlertLevel, EntityType, WorkFacility } from "@app/domain"
import { useRelation, useFormHelpers } from "@app/hooks"

interface IReleaseGeneralFormProps {
	busy?: boolean
	disabled?: boolean
	defaultParams: SaveReleaseParams
	errors: { [key: string]: string }
	onSave: (params: SaveReleaseParams) => void
	referenceID?: string
}

export const ReleaseGeneralForm: React.FC<IReleaseGeneralFormProps> = (props) => {
	const { referenceID, defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { contactablesHandler, dateHandler, inputHandler, params, selectHandler, valueHandler } =
		useFormHelpers<SaveReleaseParams>(defaultParams)

	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, params?.workFacilityID)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("release.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					{referenceID ? (
						<Input
							disabled={disabled || busy}
							readOnly={true}
							defaultValue={referenceID}
							hint={t("common.hints.referenceID")}
							label={t("common.labels.referenceID")}
							name="referenceID"
							error={_.get(errors, "referenceID")}
							onChange={inputHandler("referenceID")}
							type="text"
						/>
					) : null}
					<JobSelect
						autoFocus={_.isEmpty(params.jobID)}
						defaultValue={_.get(params, "jobID")}
						disabled={disabled || busy}
						error={_.get(errors, "jobID")}
						label={t("release.labels.jobID")}
						name="jobID"
						onChange={selectHandler("jobID")}
					/>
					<WorkFacilitySelect
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={disabled || busy}
						error={_.get(errors, "workFacilityID")}
						label={t("shipment.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID")}
					/>
					<Input
						autoFocus={!_.isEmpty(params.jobID) && _.isEmpty(params.name)}
						autoComplete="off"
						defaultValue={params.name}
						disabled={disabled || busy}
						error={_.get(errors, "name")}
						label={t("release.labels.name")}
						name="name"
						onChange={inputHandler("name")}
						type="text"
					/>
					{referenceID ? (
						<Toggle
							label={t("common.labels.metric")}
							defaultChecked={params.metric}
							disabled={disabled || busy}
							hint={t("release.hints.metric")}
							error={_.get(errors, "metric")}
							onChange={valueHandler("metric")}
						/>
					) : null}

					<h2 className="text-md mb-4 text-gray-700">{t("release.headers.keyDates")}</h2>

					<DateTimeInput
						defaultValue={_.get(params, "keyDates.received")}
						disabled={disabled || busy}
						error={_.get(errors, "keyDates.received")}
						hint={t("release.hints.received")}
						label={t("release.labels.received")}
						name="keyDates.received"
						onChange={dateHandler("keyDates.received")}
						timezone={workFacility?.timezone}
					/>
					<DateTimeInput
						defaultValue={_.get(params, "keyDates.deliverBy")}
						disabled={disabled || busy}
						error={_.get(errors, "keyDates.deliverBy")}
						hint={t("release.hints.deliverBy")}
						label={t("release.labels.deliverBy")}
						name="keyDates.deliverBy"
						onChange={dateHandler("keyDates.deliverBy")}
						timezone={workFacility?.timezone}
					/>
					<DateTimeInput
						defaultValue={_.get(params, "keyDates.produced")}
						disabled={disabled || busy}
						error={_.get(errors, "keyDates.produced")}
						hint={t("release.hints.produced")}
						label={t("release.labels.produced")}
						name="keyDates.produced"
						onChange={dateHandler("keyDates.produced")}
						timezone={workFacility?.timezone}
					/>
					<DateTimeInput
						defaultValue={_.get(params, "keyDates.shipped")}
						disabled={disabled || busy}
						error={_.get(errors, "keyDates.shipped")}
						hint={t("release.hints.shipped")}
						label={t("release.labels.shipped")}
						name="keyDates.shipped"
						onChange={dateHandler("keyDates.shipped")}
						timezone={workFacility?.timezone}
					/>
					<AttributesForm
						busy={busy}
						defaultParams={_.get(params, "attributes")}
						disabled={disabled}
						errors={errors}
						errorsKey="attributes"
						onChange={valueHandler("attributes")}
					/>
				</div>

				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<AddressesForm
						busy={busy}
						defaultParams={_.get(params, "addresses", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="addresses"
						onChange={valueHandler("addresses")}
					/>
				</div>

				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<ContactablesForm
						busy={busy}
						defaultParams={_.get(params, "contactables", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="contactables"
						onChange={contactablesHandler}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("release.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
