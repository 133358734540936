export type File = {
	id: string
	fileableID?: string
	fileableType: string

	name: string
	size: number
	type: string
	url: string

	created: string
	updated: string
}

export enum FileType {
	AssemblyIFCImport = "AssemblyIFCImport",
	AssemblyFabPNG = "AssemblyFabPNG",
	AssemblySTPImport = "AssemblySTPImport",
	AssemblySTPMask = "AssemblySTPMask",
}

export const fileTypeToExtension = (fileType: FileType): string => {
	switch (fileType) {
		case FileType.AssemblyFabPNG:
			return "png"
		case FileType.AssemblyIFCImport:
			return "ifc"
		case FileType.AssemblySTPImport:
		case FileType.AssemblySTPMask:
			return "stp"
	}
}

export const GetFileNameFromURL = (url: string): string => {
	return url.split("/").at(-1)?.split("?")[0] ?? ""
}

// Mirrors value in domain/file.go
export const FileMaxSizeBytes = 16 * 1024 * 1024 // 16 MB
