import React from "react"
import { useHistory } from "react-router-dom"

import { HTTPErrorPage } from "@app/pages"

import { httpStatusContext } from "."

type ErrorProviderProps = {
	children: React.ReactNode
}

export const ErrorProvider: React.FC<ErrorProviderProps> = (props) => {
	const { children } = props
	const history = useHistory()
	const [httpStatus, setHTTPStatus] = React.useState<number | undefined>()

	React.useEffect(() => {
		const unlisten = history.listen(() => setHTTPStatus(undefined))
		return unlisten
	}, [])

	const renderContent = () => {
		if (httpStatus === 404) {
			return <HTTPErrorPage code={404} error="Page not found" />
		}

		return children
	}

	const payload = React.useMemo(() => ({ setHTTPStatus }), [setHTTPStatus])

	return <httpStatusContext.Provider value={payload}>{renderContent()}</httpStatusContext.Provider>
}
