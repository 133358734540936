import React from "react"
import _ from "lodash"

import { SaveWorkResource, SaveWorkResourceParams, newSaveWorkResourceParams } from "@app/api"
import { useSession } from "@app/contexts"
import { newWorkResource, WorkCell, EntityType, AlertLevel } from "@app/domain"
import { WorkResourceForm } from "@app/forms"
import { PageContainer, Link, BreadcrumbDelimiter, Breadcrumbs, Title } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useQuery, useRedirect, useRelation } from "@app/hooks"

export const WorkResourceNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const workCellID = toUUID(_.get(useQuery(), "workCell"))
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const [workCell] = useRelation<WorkCell>(EntityType.WorkCell, workCellID, {
		setHTTPStatus: true,
	})

	const saveHandler = async (params: SaveWorkResourceParams) => {
		setBusy(true)
		try {
			const resp = await SaveWorkResource({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workResources?.[0]
				setRedirect(urlTo("workResources", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workResource.notifications.success.created", { name: saved?.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	if (workCellID && !workCell) {
		return null
	}

	const defaultParams = newSaveWorkResourceParams(
		newWorkResource({
			workCellID,
			workFacilityID: _.get(workCell, "workFacilityID", ""),
		}),
	)

	return (
		<PageContainer>
			<Title>{t("workResource.titles.new")}</Title>

			<Breadcrumbs>
				<Link to={urlTo("workResources")}>{t("workResource.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<WorkResourceForm
					defaultParams={defaultParams}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
