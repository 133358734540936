import React from "react"
import _ from "lodash"

export type HTTPStatusContext = {
	setHTTPStatus: (status?: number) => void
}

export const httpStatusContext = React.createContext<HTTPStatusContext>({
	setHTTPStatus: _.noop,
})

export const useHTTPStatus = (): HTTPStatusContext => React.useContext(httpStatusContext)
