import React from "react"

import { Await, PageContainer, CustomerDetails } from "@app/components"
import { CustomerHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, Customer } from "@app/domain"

export const CustomerShowPage: React.FC = () => {
	const [customer, customerLoading] = useEntity<Customer>(EntityType.Customer)

	return (
		<PageContainer>
			<CustomerHeader customer={customer} view="show" />
			<Await
				loading={customerLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<CustomerDetails customer={customer} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
