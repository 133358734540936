import React from "react"
import _ from "lodash"

import { SaveWorkCell, SaveWorkCellParams, newSaveWorkCellParams } from "@app/api"
import { useSession } from "@app/contexts"
import { newWorkCell, AlertLevel } from "@app/domain"
import { WorkCellForm } from "@app/forms"
import { PageContainer, Link, BreadcrumbDelimiter, Breadcrumbs, Title } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useRedirect, useQuery } from "@app/hooks"

export const WorkCellNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const { workFacility = "" } = useQuery()
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: SaveWorkCellParams) => {
		setBusy(true)
		try {
			const resp = await SaveWorkCell({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workCells[0]")
				setRedirect(urlTo("workCells", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workCell.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	const defaultParams = newSaveWorkCellParams(newWorkCell({ workFacilityID: toUUID(workFacility) }))

	return (
		<PageContainer>
			<Title>{t("workCell.titles.new")}</Title>

			<Breadcrumbs>
				<Link to={urlTo("workCells")}>{t("workCell.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<WorkCellForm
					defaultParams={defaultParams}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
