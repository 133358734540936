/* eslint react/no-unknown-property: 0 */
import React from "react"
import { Object3D, Vector3, MeshPhongMaterial, Mesh, LoadingManager } from "three"
import { Canvas } from "@react-three/fiber"
import {
	Center,
	OrbitControls,
	Line,
	Billboard,
	Text,
	useGLTF,
	GizmoHelper,
	GizmoViewcube,
} from "@react-three/drei"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"
import clsx from "clsx"
import URDFLoader, { URDFRobot } from "urdf-loader"
import { STLLoader } from "three-stdlib"

import { formatLength, MM_PER_IN } from "@app/util"
import { ButtonIcon, Icon } from "@app/components"
import { AssemblyType } from "@app/domain"

import type {
	Lines as LinesType,
	Labels,
	Spheres,
	Origins,
	Origin,
	RobotJointData,
} from "@app/grpc/visualization"

interface IGLTFViewerProps<T1> {
	modelURLs: string[]
	urdfURL?: string
	lines?: LinesType[]
	labels?: Labels[]
	spheres?: Spheres[]
	origins?: Origins[]
	robotJointData?: RobotJointData[]
	collisions?: number[]
	onError: (error: Error) => void
	metric: boolean
	zUp?: boolean
	viewCube?: boolean
	viewUnits?: boolean
	simulate?: boolean
	robotType?: AssemblyType
	viewCollisionObjects?: boolean
	viewURDF?: boolean
	connectionEditing?: boolean
	setRequestParams?: React.Dispatch<React.SetStateAction<T1>>
}

export const GLTFViewer = <T1,>(props: IGLTFViewerProps<T1>) => {
	const {
		modelURLs,
		urdfURL = "",
		labels = [],
		lines = [],
		spheres = [],
		origins = [],
		robotJointData = [],
		collisions = [],
		onError,
		viewCube = false,
		viewUnits = true,
		zUp = false,
		simulate = false,
		viewCollisionObjects = false,
		viewURDF = false,
		metric,
		connectionEditing = false,
		setRequestParams = () => {},
	} = props
	const [frame, setFrame] = React.useState(0)
	const gltfs = modelURLs.map((modelURL) => useGLTF(modelURL))
	const max = gltfs[0].scene.children.length
	gltfs.forEach((gltf) => {
		if (!gltf?.scene) {
			onError({} as Error)
		}
	})
	const [simulationPaused, setSimulationPaused] = React.useState(true)
	const [intervalID, setIntervalID] = React.useState<number>()
	React.useEffect(() => {
		if (zUp) {
			Object3D.DEFAULT_UP = new Vector3(0, 0, 1)
		}
	}, [])
	React.useEffect(() => {
		setFrame(0)
		setSimulationPaused(true)
	}, [simulate])
	React.useEffect(() => {
		if (simulate) {
			gltfs.map((gltf) =>
				gltf.scene.children.map((c, i) => {
					c.visible = i === frame ? true : false
					return c
				}),
			)
		}
	}, [frame, simulate, robotJointData, modelURLs, viewURDF])
	React.useEffect(() => {
		if (!simulationPaused) {
			const id = setInterval(() => {
				setFrame((prev) => (prev >= max - 1 ? 0 : prev + 1))
			}, 1000 / 2)
			setIntervalID(id)
			return () => {
				clearInterval(intervalID)
			}
		} else {
			clearInterval(intervalID)
		}
	}, [simulationPaused])
	const manager = new LoadingManager()
	const loader = new URDFLoader(manager)
	const [urdfRobot, setURDFRobot] = React.useState<URDFRobot>()
	React.useEffect(() => {
		if (!viewURDF) {
			return
		}
		if (urdfURL) {
			loader.parseCollision = viewCollisionObjects
			loader.loadMeshCb = (path, manager, onComplete) => {
				new STLLoader(manager).load(
					path,
					(result) => {
						const material = new MeshPhongMaterial()
						const mesh = new Mesh(result, material)
						onComplete(mesh)
					},
					() => null,
					(err) => {
						console.error(err)
						// @ts-expect-error
						onComplete(null, err)
					},
				)
			}
			loader.load(urdfURL, (robot) => {
				setURDFRobot(robot)
			})
		}
	}, [viewURDF, viewCollisionObjects, urdfURL])
	React.useEffect(() => {
		if (viewURDF && urdfRobot && robotJointData[frame]) {
			urdfRobot.setJointValues(robotJointData[frame]["robot_joint_data"])
		}
	}, [frame, urdfRobot, viewCollisionObjects, robotJointData, viewURDF])

	React.useEffect(() => {
		const arrowKeyHandler = (event: KeyboardEvent) => {
			if (!simulate || !simulationPaused || document.activeElement?.tagName === "INPUT") {
				return
			}
			if (event.key === "ArrowLeft") {
				event.shiftKey || event.ctrlKey
					? setFrame(0)
					: setFrame((prev) => (prev <= 0 ? max - 1 : prev - 1))
			} else if (event.key === "ArrowRight") {
				event.shiftKey || event.ctrlKey
					? setFrame(max - 1)
					: setFrame((prev) => (prev >= max - 1 ? 0 : prev + 1))
			}
		}
		window.addEventListener("keydown", arrowKeyHandler)
		return () => {
			window.removeEventListener("keydown", arrowKeyHandler)
		}
	}, [max])

	const objects = (
		<>
			<OrbitControls maxDistance={100000} minDistance={50} zoomSpeed={1.0} makeDefault />
			<mesh visible={viewURDF && !!urdfRobot && robotJointData.length > (simulate ? 1 : 0)}>
				<primitive object={urdfRobot ?? {}} />
			</mesh>
			{gltfs.map((gltf, i) => (
				<mesh key={i}>
					<primitive object={gltf.scene} />
				</mesh>
			))}
			{(labels[frame]?.labels ?? []).map((label, i) => (
				// @ts-expect-error
				<mesh position={label.point} key={i}>
					<Billboard>
						<Text
							color={label.color}
							fontSize={label.font_size}
							outlineColor={label.color === "black" ? "white" : "black"}
							outlineWidth={2}
							outlineOpacity={1}
						>
							{label.length
								? formatLength(metric, Number(label.label) / (metric ? 1 : MM_PER_IN), 2) +
								  (viewUnits ? (metric ? " mm" : '"') : "")
								: label.label}
						</Text>
					</Billboard>
				</mesh>
			))}
			{(lines[frame]?.lines ?? []).map((line, i) => (
				<Line
					key={i}
					// @ts-expect-error
					points={[line.points[0].coords, line.points[1].coords]}
					color={line.color}
					opacity={line.opacity}
					lineWidth={line.width}
				/>
			))}
			<React.Fragment>
				{(spheres[frame]?.spheres ?? [])
					.filter((sphere) => connectionEditing || !sphere.disabled)
					.map((sphere, i) => (
						<mesh
							key={i}
							castShadow
							// @ts-expect-error
							position={sphere.point}
							onClick={() => {
								if (!connectionEditing) {
									return
								}
								if (spheres[frame]) {
									const clickedSphere = spheres[frame].spheres[i]
									clickedSphere.disabled = !clickedSphere.disabled
									const sphereID = clickedSphere.immutable_identifier
									clickedSphere.color = clickedSphere.color === 0xffffff ? 0x111111 : 0xffffff
									setRequestParams((prev) => {
										// @ts-expect-error
										if (!prev?.interactively_selected_connections) {
											return prev
										}
										const newSelectedConnections = structuredClone(
											// @ts-expect-error
											prev.interactively_selected_connections,
										)
										if (
											sphereID in newSelectedConnections &&
											newSelectedConnections[sphereID] === clickedSphere.disabled
										) {
											delete newSelectedConnections[sphereID]
										} else {
											newSelectedConnections[sphereID] = !clickedSphere.disabled
										}
										return {
											...prev,
											interactively_selected_connections: newSelectedConnections,
										}
									})
								}
							}}
						>
							<sphereGeometry
								attach="geometry"
								args={[sphere.radius, sphere.radius > 5 ? 50 : 10, sphere.radius > 5 ? 50 : 10]}
							/>
							<meshStandardMaterial
								color={sphere.color}
								opacity={sphere.opacity}
								roughness={1.0}
								transparent
							/>
						</mesh>
					))}
			</React.Fragment>
			{(origins[frame]?.origins ?? []).map((origin) => axesWidget(origin))}
		</>
	)

	return (
		<>
			<Canvas
				frameloop={"demand"}
				camera={{
					position: [0, -4000, 3000],
					near: 10,
					far: 10000000,
					fov: 45,
					up: [0, 0, 1],
				}}
				onCreated={(state) => {
					state.gl.setClearColor("white")
				}}
			>
				{viewCube && (
					<GizmoHelper alignment="bottom-right">
						<GizmoViewcube
							strokeColor="white"
							faces={["Right", "Left", "Back", "Front", "Top", "Bottom"]}
						/>
					</GizmoHelper>
				)}
				<directionalLight color="white" intensity={0.5} position={[600, -600, 1000]} />
				<directionalLight color="white" intensity={0.2} position={[-600, -600, 1000]} />
				<ambientLight intensity={0.2} />
				{/* Robot animation makes camera jump around when inside a Center component */}
				<Center disable={simulate && !!urdfRobot}>{objects}</Center>
			</Canvas>
			{simulate && (
				<div className="absolute bottom-0 md:mb-24 flex z-10 h-20 w-full pointer-events-none items-center justify-center place-content-center">
					<div className="pointer-events-auto bg-gray-200 px-6 py-6 grid grid-rows-2 md:grid-rows-1 md:grid-flow-col items-baseline">
						<div className="flex items-center place-content-center order-last md:order-first">
							<ButtonIcon
								icon={simulationPaused ? "Play" : "Pause"}
								onClick={() => {
									setSimulationPaused((prev) => !prev)
								}}
							/>
							<button
								className="ml-2 flex items-center p-2 rounded-l-full bg-gray-400 text-gray-600 hover:text-gray-700 hover:bg-gray-500 border-r-[1px] border-gray-500"
								onClick={() => {
									setFrame((prev) => (prev <= 0 ? max - 1 : prev - 1))
								}}
							>
								<Icon name="ChevronLeft" size="xs" />
							</button>
							<button
								className="flex items-center p-2 rounded-r-full bg-gray-400 text-gray-600 hover:text-gray-700 hover:bg-gray-500 border-l-[1px] border-gray-500"
								onClick={() => {
									setFrame((prev) => (prev >= max - 1 ? 0 : prev + 1))
								}}
							>
								<Icon name="ChevronRight" size="xs" />
							</button>
						</div>
						<span className="pt-1 w-[72vw] md:w-80 md:ml-4 md:mr-2">
							<Slider
								disabled={!simulationPaused}
								onChange={(v) => {
									setFrame(v as number)
								}}
								min={0}
								max={max - 1}
								marks={Object.fromEntries(collisions.map((collision) => [collision, "❌"]))}
								defaultValue={0}
								value={frame}
								step={1}
								included={false}
								dots={true}
								handleStyle={{
									width: "8px",
									height: "18px",
									borderRadius: 1,
									opacity: 1,
									backgroundColor: "#FFCC00",
									border: "solid 2px #FFCC00",
									boxShadow: "none",
								}}
								railStyle={{ backgroundColor: "white", borderRadius: 1 }}
								dotStyle={{
									backgroundColor: "gray",
									borderColor: "gray",
									borderRadius: 1,
									width: "4px",
								}}
							/>
						</span>
						<span className={clsx(frame + 1 < 10 && "pl-2", "hidden md:inline self-center")}>
							{frame + 1} of {max}
						</span>
					</div>
				</div>
			)}
		</>
	)
}

const axesWidget = (origin: Origin) =>
	[
		{
			label: "X / Pitch",
			point: (length: number) => [
				origin.point[0] + origin.x_axis[0] * length,
				origin.point[1] + origin.x_axis[1] * length,
				origin.point[2] + origin.x_axis[2] * length,
			],
			color: 0xff0000,
		},
		{
			label: "Y / Yaw",
			point: (length: number) => [
				origin.point[0] + origin.y_axis[0] * length,
				origin.point[1] + origin.y_axis[1] * length,
				origin.point[2] + origin.y_axis[2] * length,
			],
			color: 0x008000,
		},
		{
			label: "Z / Roll",
			point: (length: number) => [
				origin.point[0] + origin.z_axis[0] * length,
				origin.point[1] + origin.z_axis[1] * length,
				origin.point[2] + origin.z_axis[2] * length,
			],
			color: 0x0000ff,
		},
	].map((axis, i) => (
		<React.Fragment key={i}>
			<Line
				// @ts-expect-error
				points={[origin.point, axis.point(120)]}
				lineWidth={1}
				color={axis.color}
			/>
			{/* @ts-expect-error */}
			<mesh position={axis.point(150)}>
				<Billboard>
					<Text
						color={axis.color}
						fontSize={20}
						outlineColor="white"
						outlineWidth={1}
						outlineOpacity={1}
					>
						{axis.label}
					</Text>
				</Billboard>
			</mesh>
		</React.Fragment>
	))
