import { rpc } from "@app/api"

import type { Result } from "@app/api"
import { Entity, EntityType, EntityColumn } from "@app/domain"

export type EntityTypeIDMap = { [key in EntityType]?: string }

export type EntityQueryParams = {
	entityType: EntityType
	entityTypeIDMap: EntityTypeIDMap
	page: number
	pageSize: number
	parentEntityType: EntityType | null
	parentEntityID: string | null
	query: string
	sortCol: string
	sortDir: number
	timeStart: Date
	timeEnd: Date
}

// golang "null" time (1/1/1 00:00:00.000000000 UTC).
export const NullDate = new Date(-62135596800000)

export const DefaultEntityQueryParams = (
	entityType: EntityType,
	parentEntityType: EntityType | null = null,
	parentEntityID: string | null = null,
	entityTypeIDMap: EntityTypeIDMap = {},
	pageSize = 0,
): EntityQueryParams => ({
	entityType,
	entityTypeIDMap,
	page: 1,
	pageSize,
	parentEntityType,
	parentEntityID,
	query: "",
	// Backend sorts by most recently updated by default.
	// This reflects that to the user.
	sortCol: "",
	sortDir: 0,
	// 12AM of current day.
	timeStart: new Date(new Date().setHours(0, 0, 0, 0)),
	timeEnd: NullDate,
})

export type EntityQueryResult = {
	columns: EntityColumn[]
	entityType: EntityType
	imageLayout: boolean
	pageSize: number
	pagination: { Limit: number; Offset: number; Total: number }
	rows: Entity[]
}

export const EntityQuery = async (
	params: EntityQueryParams,
): Promise<Result<EntityQueryResult>> => {
	const data = await rpc("EntityQuery", { ...params })
	return data as Result<EntityQueryResult>
}
