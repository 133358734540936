import React from "react"
import ReactSelect, { GroupBase } from "react-select"
import _ from "lodash"

import { Icon, Label } from "@app/components"
import { useSession } from "@app/contexts"

import type { Props } from "react-select"

export type OptionType = {
	label: string
	value: string
}

interface ISelectProps {
	alt?: string
	clearable?: boolean
	disabled?: boolean
	error?: string
	fieldInfo?: string
	hint?: string
	label?: string
	name: string
	onChange: (v: OptionType[]) => void
	options: OptionType[]
	withoutErrors?: boolean
}

export const Select = (
	props: ISelectProps & Omit<Props<OptionType, boolean, GroupBase<OptionType>>, "onChange">,
) => {
	const { t } = useSession()

	const {
		alt = "",
		clearable = true,
		fieldInfo,
		disabled,
		onChange,
		options,
		name,
		label,
		hint,
		withoutErrors = false,
		error,
		...rest
	} = props
	const errorMessage = error?.replace("$field", label || t("validation.this"))

	return (
		<div className="">
			{_.isNil(label) ? null : (
				<Label name={name} fieldInfo={fieldInfo} className="flex gap-1">
					{label}&#8203;
					{alt && (
						<span title={alt}>
							<Icon name="InfoCircle" fixedWidth className="text-gray-500" />
						</span>
					)}
				</Label>
			)}
			<ReactSelect
				className="react-select"
				classNamePrefix="rs"
				isClearable={clearable}
				isDisabled={disabled}
				name={name}
				options={options}
				onChange={(v) => {
					if (_.isArray(v)) {
						onChange(_.compact(v))
					} else {
						onChange(_.compact([v]) as OptionType[])
					}
				}}
				{...rest}
			/>
			{!withoutErrors && (
				<>
					{!_.isEmpty(errorMessage) ? (
						<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
							<svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
								<path
									fillRule="evenodd"
									d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
									clipRule="evenodd"
								/>
							</svg>
						</div>
					) : null}
					{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
						<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203; </p>
					) : null}
					{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
						<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203; </p>
					) : null}
				</>
			)}
		</div>
	)
}
