import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import type { Contact, EntityType } from "@app/domain"

interface IContactDetailsProps {
	contact?: Contact
}

export const ContactDetails: React.FC<IContactDetailsProps> = (props) => {
	const { t } = useSession()

	const { contact } = props

	if (_.isNil(contact)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("contact.labels.firstName")} value={contact.firstName} />
			<Property label={t("contact.labels.lastName")} value={contact.lastName} />
			<Property label={t("contact.labels.title")} value={contact.title} />
			{_.size(contact.emails) > 0 ? <Property group={t("email.header")} /> : null}
			{_.map(contact.emails, (v, i) => {
				let label = t("email.defaultLabel")
				if (_.size(v.labels) > 0) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={`email-${i}`} label={label} email={v.email} />
			})}
			{_.size(contact.phoneNumbers) > 0 ? <Property group={t("phoneNumber.header")} /> : null}
			{_.map(contact.phoneNumbers, (v, i) => {
				let label = t("phoneNumber.defaultLabel")
				if (_.size(v.labels) > 0) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={`phoneNumber-${i}`} label={label} phoneNumber={v.phoneNumber} />
			})}
			{_.isEmpty(contact.contactables) ? null : (
				<Property group={t("common.headers.associations")} />
			)}
			{_.map(contact.contactables, (v, i) => (
				<Property
					key={i}
					label={t(`entity.${_.camelCase(v.contactableType)}`)}
					relation={v.contactableType as EntityType}
					value={v.contactableID}
				/>
			))}
		</div>
	)
}
