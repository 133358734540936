import React from "react"
import { Channel, createChannel, createClient } from "nice-grpc-web"

import {
	createBaseProgramPathRequest,
	ProgramPathRequest,
	VisualizationClient,
	VisualizationDefinition,
	TaskClient,
	TaskDefinition,
} from "@app/grpc/visualization"
import { FormClient, FormDefinition } from "@app/grpc/form"

import { Fieldset } from "@app/components"
import { useConfiguratorForm } from "@app/hooks"
import Config from "@app/config"

interface IPathVisualizerFormProps {
	programs: string[]
	workCellType: string
	loading?: boolean
}

export const PathVisualizerForm: React.FC<IPathVisualizerFormProps> = (props) => {
	const { programs, workCellType, loading: busy = false } = props

	const [channel] = React.useState<Channel>(createChannel(Config.internalConfiguratorGRPCWebURL))
	const visualizationClient: VisualizationClient = createClient(VisualizationDefinition, channel)
	const taskClient: TaskClient = createClient(TaskDefinition, channel)
	const formClient: FormClient = createClient(FormDefinition, channel)

	const [requestParams, setRequestParams] = React.useState<ProgramPathRequest>({
		...createBaseProgramPathRequest(),
		program_csvs: programs,
		workcell_type: workCellType,
	})
	const [prevRequestParams, setPrevRequestParams] =
		React.useState<ProgramPathRequest>(requestParams) // Parameters that generated the current view. Used to know whether to update view.

	const { gltfViewer, form, initialized, renderInput, info } = useConfiguratorForm({
		urdfURL:
			workCellType === "W2"
				? "/assets/robots/w2/urdf/w2.urdf"
				: "/assets/robots/abb_irb6700_220_265_leanid/urdf/abb_irb6700_220_265_leanid.urdf",
		requestParams,
		setRequestParams,
		prevRequestParams,
		setPrevRequestParams,
		busy,
		getForm: formClient.programPath,
		getHashes: visualizationClient.programPathHashes,
		getLayers: visualizationClient.programPath,
		getTask: taskClient.task,
		simulate: true,
		viewURDF: true,
	})

	return (
		<div className="grid grid-rows-[100px_1fr] md:grid-rows-[1fr] md:grid-cols-[24rem_1fr] h-max md:h-[calc(100vh-255px)] w-full">
			<div className="w-full border-gray-400 md:border-r md:overflow-auto pt-6 md:px-6 h-screen md:h-full md:w-96">
				<div className="md:hidden relative flex flex-row md:flex-col flex-1 w-full h-1/2 overflow-hidden mb-4">
					{gltfViewer}
				</div>
				{form &&
					initialized &&
					form.groups.map((group, i) => (
						<Fieldset
							key={i}
							defaultOpen={!group.collapsed}
							className="mt-1 mb-3 pb-1"
							label={group.label}
						>
							{group.inputs.map((input, j) => (
								<React.Fragment key={j}>{renderInput(input, j)}</React.Fragment>
							))}
						</Fieldset>
					))}
				{info && (
					<Fieldset defaultOpen={true} className="mt-1 mb-3 pb-1" label="Info">
						<p className="mb-3 whitespace-pre-line">{info}</p>
					</Fieldset>
				)}
				{form && (
					<div className="text-center text-xs text-gray-500 py-2 mb-6 bg-white">
						{`Program Path Visualizer v${form?.version}`}
					</div>
				)}
			</div>
			<div className="hidden md:block relative w-full h-full">{gltfViewer}</div>
		</div>
	)
}
