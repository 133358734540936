import React from "react"
import _ from "lodash"

import { Button, FormActions, Input } from "@app/components"
import { useSession } from "@app/contexts"
import { ChangeAccountPINParams } from "@app/api"
import { AlertLevel } from "@app/domain"

interface IAccountPINFormProps {
	busy?: boolean
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: ChangeAccountPINParams) => void
}

export const AccountPINForm: React.FC<IAccountPINFormProps> = (props) => {
	const { busy = false, disabled = false, onSave, errors } = props
	const { t, addNotification } = useSession()

	const [params, setParams] = React.useState<ChangeAccountPINParams>({
		newPIN: "",
	})

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("account.notifications.failure.changePIN", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const inputHandler = (key: string): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			setParams((params) => _.set({ ...params }, key, target.value))
		}
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						defaultValue={params.newPIN}
						disabled={disabled || busy}
						error={_.get(errors, "newPIN")}
						hint={t("account.hints.newPIN")}
						label={t("account.labels.newPIN")}
						name="name"
						onChange={inputHandler("newPIN")}
						type="password"
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("account.buttons.changePIN")}
					errors={errors}
					busy={busy}
					disabled={disabled}
				/>
			</FormActions>
		</form>
	)
}
