import React from "react"
import _ from "lodash"

import { TrackingQRCode, Property, Label } from "@app/components"
import { useSession } from "@app/contexts"
import { WorkCell, EntityType } from "@app/domain"
import { fromUUID } from "@app/util"

interface IWorkCellDetailsProps {
	workCell?: WorkCell
}

export const WorkCellDetails: React.FC<IWorkCellDetailsProps> = (props) => {
	const { t } = useSession()

	const { workCell } = props

	if (_.isNil(workCell)) {
		return null
	}

	const trackingCode = {
		locations: [
			{
				id: fromUUID(workCell.id),
				description: `${workCell.name}`,
				type: "WorkCell",
			},
		],
	}

	return (
		<>
			<div className="pt-1 sm:px-2 max-w-lg">
				<Property label={t("workCell.labels.name")} value={workCell.name} />
				<Property
					label={t("workCell.labels.workCellType")}
					value={workCell.workCellType ? t(`workCellTypes.${workCell.workCellType}`) : ""}
				/>
				<Property label={t("workCell.labels.description")} value={workCell.description} />
				<Property label={t("workCell.labels.logoURL")} value={workCell.logoURL} />
				<Property
					label={t("workCell.labels.workFacilityID")}
					relation={EntityType.WorkFacility}
					value={workCell.workFacilityID}
				/>
				{_.size(workCell.capabilities) > 0 ? (
					<Property group={t("workCell.labels.capabilities")} />
				) : null}
				{_.map(workCell.capabilities, (v, i) => {
					return (
						<Property
							key={`capabilities${i}`}
							label={t("workCell.labels.capabilities")}
							value={t(`workCellCapabilityTypes.${v}`)}
						/>
					)
				})}
			</div>
			<div className="pl-2 inline-block">
				<Label className="block">{t("workAction.labels.trackingQRCodes")}</Label>
				<div className="mx-4 p-4 border border-gray-400 inline-block text-center">
					<TrackingQRCode trackingCode={trackingCode} />
					<div className="font-bold pt-2">{trackingCode.locations[0].description}</div>
				</div>
			</div>
		</>
	)
}
