import React from "react"

import { Await, PageContainer, JobDetails } from "@app/components"
import { Job, EntityType } from "@app/domain"
import { JobHeader } from "@app/headers"
import { useEntity } from "@app/hooks"

export const JobShowPage: React.FC = () => {
	const [job, jobLoading] = useEntity<Job>(EntityType.Job)

	return (
		<PageContainer>
			<JobHeader job={job} view="show" />
			<Await
				loading={jobLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<JobDetails job={job} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
