import React from "react"

import { Await, PageContainer, WorkDeviceDetails } from "@app/components"
import { WorkDeviceHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkDevice } from "@app/domain"

export const WorkDeviceShowPage: React.FC = () => {
	const [workDevice, workDeviceLoading] = useEntity<WorkDevice>(EntityType.WorkDevice)

	return (
		<PageContainer>
			<WorkDeviceHeader workDevice={workDevice} view="show" />
			<Await
				loading={workDeviceLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkDeviceDetails workDevice={workDevice} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
