import React from "react"
import { Redirect } from "react-router-dom"

import { useSession, INotificationProps } from "@app/contexts"

type FuncToJSX = () => JSX.Element

interface IUseRedirectParams {
	redirect?: FuncToJSX
	setRedirect: (url: string, notification?: INotificationProps) => void
}

export const useRedirect = (): IUseRedirectParams => {
	const { addNotification } = useSession()

	const [url, setURL] = React.useState<string | undefined>()
	const [redirect, setRedirect] = React.useState<FuncToJSX | undefined>()

	React.useEffect(() => {
		if (url) {
			// eslint-disable-next-line react/display-name
			const fn = () => <Redirect to={url} />
			setRedirect(() => fn)
		}
	}, [url])

	return {
		redirect,
		setRedirect: (url: string, notification?: INotificationProps) => {
			setURL(url)
			if (notification) {
				addNotification(notification)
			}
		},
	}
}
