import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Icon, Badge, EntityBreadcrumbs, DateTime, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { Worker, WorkerUtils, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IWorkerHeaderProps {
	worker?: Worker
	section?: string
	view: string
}

export const WorkerHeader: React.FC<IWorkerHeaderProps> = (props) => {
	const { worker, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: WorkerUtils.name(worker) || "...", created: "", updated: "" },
		worker,
	)

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler, trashable] = useTrash(EntityType.Worker, setRedirect, created, 120)

	const options = {
		label: t("common.tabs.options"),
		options: [
			{
				disabled: !trashable,
				label: (
					<span key="delete">
						<Icon className="mr-2" name="Trash" fixedWidth />
						{t("worker.buttons.delete")}
					</span>
				),
				value: "delete",
			},
		],
		onOptionClick: () => trashHandler(id, { name }),
	}

	const tabs = _.compact([
		{
			label: t("common.tabs.show"),
			to: urlTo("workers/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workers", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workers/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("workers/edit", id), active: view === "edit" },
		options,
	])

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("worker.titles.new")
	} else if (view === "edit") {
		title = t("worker.titles.edit", { name })
	} else {
		title = t("worker.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={worker} entityType={EntityType.Worker} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
