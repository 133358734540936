import React from "react"
import _ from "lodash"

import { Toggle, Input, ColorSelect, TagPreview } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveTagParams } from "@app/api"
import { newTag } from "@app/domain"
import { TemplateFieldsForm, AttributesForm } from "@app/forms"
import { useFormHelpers } from "@app/hooks"

interface ITagFormProps {
	busy?: boolean
	defaultParams: SaveTagParams
	disabled?: boolean
	errors: { [key: string]: string }
	onChange?: (params: SaveTagParams) => void
	referenceID?: string
}

export const TagForm: React.FC<ITagFormProps> = (props) => {
	const {
		referenceID = "",
		disabled = false,
		defaultParams,
		errors,
		busy = false,
		onChange = _.noop,
	} = props

	const { t } = useSession()

	const { params, inputHandler, floatInputHandler, intInputHandler, valueHandler } =
		useFormHelpers<SaveTagParams>(defaultParams)

	React.useEffect(() => {
		onChange(_.cloneDeep(params))
	}, [params])

	const units = _.get(params, "metric") ? "metric" : "imperial"

	return (
		<div className="flex flex-col sm:flex-row">
			<div className="sm:w-1/2 lg:w-1/3">
				{referenceID ? (
					<Input
						defaultValue={referenceID}
						disabled={disabled || busy}
						error={_.get(errors, "referenceID")}
						hint={t("common.hints.referenceID")}
						label={t("common.labels.referenceID")}
						name="referenceID"
						onChange={inputHandler("referenceID")}
						readOnly={true}
						type="text"
					/>
				) : null}
				<div>
					<ColorSelect
						label={t("tag.labels.color")}
						defaultValue={params.color}
						disabled={disabled || busy}
						onChange={valueHandler("color")}
						error={_.get(errors, "color")}
					/>
					<Input
						defaultValue={params.description}
						disabled={disabled || busy}
						error={_.get(errors, "description")}
						label={t("tag.labels.description")}
						name="description"
						onChange={inputHandler("description")}
						type="text"
					/>
					<Input
						defaultValue={params.controlCodes}
						disabled={disabled || busy}
						error={_.get(errors, "controlCodes")}
						label={t("tag.labels.controlCodes")}
						name="controlCodes"
						onChange={inputHandler("controlCodes")}
						type="text"
					/>
					<Input
						defaultValue={params.quantity}
						disabled={disabled || busy}
						error={_.get(errors, "quantity")}
						label={t("tag.labels.quantity")}
						name="quantity"
						onChange={intInputHandler("quantity")}
						type="text"
					/>
					<Input
						defaultValue={params.unitStart}
						disabled={disabled || busy}
						error={_.get(errors, "unitStart")}
						label={t("tag.labels.unitStart")}
						name="unitStart"
						onChange={intInputHandler("unitStart")}
						type="text"
					/>
					<Input
						defaultValue={params.unitEnd}
						disabled={disabled || busy}
						error={_.get(errors, "unitEnd")}
						label={t("tag.labels.unitEnd")}
						name="unitEnd"
						onChange={intInputHandler("unitEnd")}
						type="text"
					/>
					<Input
						defaultValue={params.weight}
						disabled={disabled || busy}
						error={_.get(errors, "weight")}
						label={`${t("tag.labels.weight")} (${t(`units.${units}.weightAbbr`)})`}
						name="weight"
						onChange={floatInputHandler("weight")}
						type="text"
					/>
					<Toggle
						label={t("common.labels.metric")}
						defaultChecked={params.metric}
						disabled={disabled || busy}
						hint={t("tag.hints.metric")}
						error={_.get(errors, "metric")}
						onChange={valueHandler("metric")}
					/>
					<AttributesForm
						busy={busy}
						defaultParams={_.get(params, "attributes")}
						disabled={disabled}
						errors={errors}
						errorsKey="attributes"
						onChange={valueHandler("attributes")}
					/>
					<TemplateFieldsForm
						documentType={"tag"}
						busy={busy}
						defaultParams={_.get(params, "templateFields", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="templateFields"
						onChange={valueHandler("templateFields")}
					/>
				</div>
			</div>
			<div className="hidden lg:block lg:w-2/3 ml-4 sticky top-4 self-start">
				<TagPreview tag={newTag({ ...defaultParams, ...params, referenceID })} editing={true} />
			</div>
		</div>
	)
}
