import React from "react"
import _ from "lodash"

import { Icon, Image, Link, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType, Tag, TagUtils } from "@app/domain"
import { urlTo, fromUUID } from "@app/util"

interface ITagScanCardProps {
	flash?: boolean
	referenceID?: string
	item?: number
	tag?: Tag
	notFound?: boolean
	onClose?: () => void
	show?: {
		assembly: boolean
		customer: boolean
		job: boolean
		release: boolean
	}
}

export const TagScanCard: React.FC<ITagScanCardProps> = (props) => {
	const { t } = useSession()

	const { flash = false, item, tag, notFound = false, referenceID = "", onClose = _.noop } = props

	if (notFound) {
		return (
			<div
				className={`block text-center relative py-4 transition duration-150 ease-in-out ${
					flash ? "bg-yellow-300" : "bg-gray-300"
				}`}
			>
				<div className="absolute right-1 top-1">
					<button
						onClick={onClose}
						className="text-gray-500 hover:text-gray-900 inline-flex items-center rounded-full hover:bg-yellow-500 w-5 h-5"
					>
						<Icon name="Times" className="flex-1" />
					</button>
				</div>
				<div className="border border-gray-400 inline-flex justify-center">
					<div className="p-0.5">
						<Image
							src={`/assets/img/tag-not-found.png`}
							loading="lazy"
							fallback={(err) => {
								return (
									<div
										className="bg-white flex justify-center items-center"
										style={{ minHeight: "128px", minWidth: "82px" }}
									>
										{err ? <Icon className="text-gray-300 text-xl" name="QuestionCircle" /> : null}
									</div>
								)
							}}
							style={{ maxHeight: "128px", maxWidth: "128px" }}
						/>
					</div>
				</div>
				<div className="py-2 px-1 border-gray-400 border-b leading-5 font-medium">
					{t("tag.notFound")}
				</div>
				<div>
					<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">
						{t("common.labels.referenceID")}
					</div>
					<div className="text-sm leading-5 font-medium">{`${referenceID}-${item}`}</div>
				</div>
			</div>
		)
	} else if (_.isNil(tag)) {
		return null
	}

	return (
		<div
			className={`block text-center relative py-4 transition duration-150 ease-in-out ${
				flash ? "bg-yellow-300" : "bg-gray-300"
			}`}
		>
			<div className="bg-white border border-gray-400 inline-flex justify-center">
				<div className="absolute right-1 top-1">
					<button
						onClick={onClose}
						className="text-gray-500 hover:text-gray-900 inline-flex items-center rounded-full hover:bg-yellow-500 w-5 h-5"
					>
						<Icon name="Times" className="flex-1" />
					</button>
				</div>
				<div className="p-0.5">
					<Link key={tag.id} to={urlTo("tags", tag)}>
						<Image
							src={`/thumbnail/t/${fromUUID(tag.id)}-${TagUtils.updated(tag)}.png`}
							loading="lazy"
							fallback={(err) => {
								return (
									<div
										className="bg-white flex justify-center items-center"
										style={{ minHeight: "128px", minWidth: "82px" }}
									>
										{err ? <Icon className="text-gray-300 text-xl" name="QuestionCircle" /> : null}
									</div>
								)
							}}
							style={{ maxHeight: "128px", maxWidth: "128px" }}
						/>
					</Link>
				</div>
			</div>
			<div className="py-2 px-1 border-gray-400 border-b leading-5 font-medium">
				{tag.description}
			</div>
			<div className="mt-2 px-1">
				<div>
					<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">
						{t("common.labels.referenceID")}
					</div>
					<div className="text-sm leading-5 font-medium">{`${tag.referenceID}-${item}`}</div>
				</div>
				<div>
					<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">
						{t("tag.labels.customerID")}
					</div>
					<div className="text-sm leading-5 font-medium">
						<Property relation={EntityType.Customer} value={tag.customerID} nolink />
					</div>
				</div>
				<div>
					<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">{t("tag.labels.jobID")}</div>
					<div className="text-sm leading-5 font-medium">
						<Property relation={EntityType.Job} value={tag.jobID} nolink />
					</div>
				</div>
				<div>
					<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">
						{t("tag.labels.releaseID")}
					</div>
					<div className="text-sm leading-5 font-medium">
						<Property relation={EntityType.Release} value={tag.releaseID} nolink />
					</div>
				</div>
				<div>
					<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">
						{t("tag.labels.assemblyID")}
					</div>
					<div className="text-sm leading-5 font-medium">
						<Property relation={EntityType.Assembly} value={tag.assemblyID} nolink />
					</div>
				</div>
			</div>
		</div>
	)
}
