import React from "react"
import _ from "lodash"

import { DocumentForm } from "@app/forms"
import { DocumentHeader } from "@app/headers"
import { FormActions, PageContainer, Await, Title, Button } from "@app/components"
import { Document, AlertLevel, EntityType } from "@app/domain"
import { SaveDocument, SaveDocumentParams, newSaveDocumentParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const DocumentEditPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()

	const [document, documentLoading] = useEntity<Document>(EntityType.Document)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveDocumentParams>(newSaveDocumentParams(document))
	const [saving, setSaving] = React.useState<boolean>(false)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("document.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setSaving(true)
		setErrors({})
		try {
			const resp = await SaveDocument({ ...params, id: toUUID(_.get(document, "id")) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.documents[0]")
				setRedirect(urlTo("documents", saved), {
					alertLevel: AlertLevel.Success,
					title: t("document.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setSaving(false)
		return false
	}

	if (redirect) {
		return redirect()
	}

	const { name = "" } = document || {}

	return (
		<PageContainer>
			<Title>{t("document.titles.edit", { name })}</Title>
			<DocumentHeader document={document} view="edit" />
			<Await
				loading={documentLoading}
				then={() => (
					<form onSubmit={submitHandler} noValidate>
						<div className="px-2">
							<DocumentForm
								defaultParams={newSaveDocumentParams(document)}
								errors={errors}
								disabled={saving}
								onChange={(nextParams) => {
									setParams(nextParams)
								}}
							/>
						</div>
						<FormActions>
							<Button
								errors={errors}
								label={t("document.buttons.save")}
								disabled={saving}
								type="submit"
							/>
						</FormActions>
					</form>
				)}
			/>
		</PageContainer>
	)
}
