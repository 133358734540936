import React from "react"
import _ from "lodash"

import { Icon, Label } from "@app/components"
import { useSession } from "@app/contexts"

interface ITextFieldProps {
	alt?: string
	autoFocus?: boolean
	collapse?: boolean
	error?: string
	fieldInfo?: string
	label?: string
	name: string
	hint?: string
	onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

type TextFieldProps = ITextFieldProps &
	React.HTMLProps<HTMLTextAreaElement> &
	React.HTMLAttributes<HTMLTextAreaElement>

export const TextField: React.FC<TextFieldProps> = (props) => {
	const { t } = useSession()
	const ref = React.useRef<HTMLTextAreaElement>(null)

	React.useEffect(() => {
		if (ref && ref.current) {
			ref.current.style.height = "auto"
			ref.current.style.height = ref.current.scrollHeight + "px"
		}
	}, [])

	const {
		alt = "",
		autoFocus = false,
		className = "",
		collapse = false,
		error = "",
		fieldInfo,
		hint,
		label,
		name,
		onChange,
		onFocus,
		readOnly,
		...rest
	} = props
	const errorMessage = error?.replace("$field", label || t("validation.this"))

	let classes = "bg-white p-2 block w-full disabled:text-gray-500 disabled:bg-gray-300 focus:z-10 "
	if (_.isEmpty(error)) {
		classes +=
			"border border-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
	} else {
		classes +=
			"border border-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
	}

	if (readOnly) {
		classes += " text-gray-500"
	}

	return (
		<div className="mt-1 relative">
			{_.isNil(label) || collapse ? null : (
				<Label name={name} fieldInfo={fieldInfo} className="flex gap-1">
					{label}&#8203;
					{alt && (
						<span title={alt}>
							<Icon name="InfoCircle" fixedWidth className="text-gray-500" />
						</span>
					)}
				</Label>
			)}
			<textarea
				aria-label={label}
				onFocus={onFocus}
				autoFocus={autoFocus}
				className={`${classes} ${className}`}
				key={name}
				name={name}
				readOnly={readOnly}
				ref={ref}
				noValidate
				onChange={(evt) => {
					evt.target.style.height = "auto"
					evt.target.style.height = evt.target.scrollHeight + "px"
					if (onChange) {
						onChange(evt)
					}
				}}
				{...rest}
			/>

			{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
				<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203;</p>
			) : null}
			{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
				<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203;</p>
			) : null}
		</div>
	)
}
