import type {
	Assembly,
	Contact,
	Contactable,
	Customer,
	Document,
	EventLog,
	File,
	Job,
	Material,
	Release,
	ReleaseItem,
	ReleaseUnit,
	Setting,
	Shipment,
	ShipmentItem,
	Tag,
	User,
	WorkAction,
	WorkCell,
	WorkCellProgram,
	WorkCellRunReport,
	WorkCellServer,
	WorkCellSession,
	WorkDevice,
	Worker,
	WorkEvent,
	WorkFacility,
	WorkResource,
	WorkTrackingState,
} from "@app/domain"

export type Entity =
	| Assembly
	| Contact
	| Contactable
	| Customer
	| Document
	| EventLog
	| File
	| Job
	| Material
	| Release
	| ReleaseItem
	| ReleaseUnit
	| Setting
	| Shipment
	| ShipmentItem
	| Tag
	| User
	| WorkAction
	| WorkCell
	| WorkCellProgram
	| WorkCellRunReport
	| WorkCellServer
	| WorkCellSession
	| WorkDevice
	| Worker
	| WorkEvent
	| WorkFacility
	| WorkResource
	| WorkTrackingState

export enum EntityType {
	Assembly = "Assembly",
	Contact = "Contact",
	Contactable = "Contactable",
	Customer = "Customer",
	Document = "Document",
	Documentable = "Documentable",
	EventLog = "EventLog",
	File = "File",
	Job = "Job",
	Material = "Material",
	Release = "Release",
	ReleaseItem = "ReleaseItem",
	ReleaseUnit = "ReleaseUnit",
	Setting = "Setting",
	Shipment = "Shipment",
	ShipmentItem = "ShipmentItem",
	Tag = "Tag",
	TrashItem = "TrashItem",
	User = "User",
	WorkAction = "WorkAction",
	WorkCell = "WorkCell",
	WorkCellProgram = "WorkCellProgram",
	WorkCellRunReport = "WorkCellRunReport",
	WorkCellServer = "WorkCellServer",
	WorkCellSession = "WorkCellSession",
	WorkDevice = "WorkDevice",
	Worker = "Worker",
	WorkEvent = "WorkEvent",
	WorkFacility = "WorkFacility",
	WorkResource = "WorkResource",
	WorkTrackingState = "WorkTrackingState",
}

export enum EntityColumnType {
	Boolean = "Boolean",
	Date = "Date",
	DownloadLink = "DownloadLink",
	FileName = "FileName",
	FileSize = "FileSize",
	FileType = "FileType",
	Float = "Float",
	ForeignKey = "ForeignKey",
	ForeignKeyArray = "ForeignKeyArray",
	Icon = "Icon",
	Integer = "Integer",
	ObjectArray = "ObjectArray",
	PrimaryKey = "PrimaryKey",
	ReferenceID = "ReferenceID",
	Relation = "Relation",
	Seconds = "Seconds",
	NanoSeconds = "NanoSeconds",
	StrikethroughString = "StrikethroughString",
	String = "String",
	StringArray = "StringArray",
	ThumbnailURL = "ThumbnailURL",
	Timestamp = "Timestamp",
	Timezone = "Timezone",
}

// EntityColumn contains the details of a column.
export type EntityColumn = {
	label: string
	name: string
	options: {
		group: boolean
		groupable: boolean
		hidden: boolean
		relativeTime: boolean
		sortable: boolean
		timeEnd: boolean
		timeStart: boolean
	}
	relatedEntityType: EntityType
	type: EntityColumnType
	objectDetails?: { [key: string]: string }[]
}
