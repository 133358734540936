import React from "react"
import _ from "lodash"
import { useHistory, useParams } from "react-router-dom"
import { useInterval } from "usehooks-ts"
import { useLocation } from "react-router-dom"

import { useSession, useEntity } from "@app/contexts"
import { EntityBreadcrumbs, Badge, DateTime, Icon, Title, Tabs } from "@app/components"
import { urlTo, entityToBlobURL } from "@app/util"
import {
	AlertLevel,
	Assembly,
	assemblyPendingFiles,
	EntityType,
	AssemblyPendingFileType,
	BlobType,
	blobTypeToExtension,
} from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"
import { SaveAssembly, GetAssemblyPendingFiles, ExportAssembly } from "@app/api"
import { downloadBlob } from "@app/util"

interface IAssemblyHeaderProps {
	assembly?: Assembly
	setAssembly?: (assembly: Assembly) => void
	section?: string
	view: string
}

export const AssemblyHeader: React.FC<IAssemblyHeaderProps> = (props) => {
	const { assembly, setAssembly, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: _.get(useParams(), "id", ""), name: "...", created: "", updated: "" },
		assembly,
	)

	const { t, handleError, addNotification, tenantID } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const history = useHistory()
	const location = useLocation()
	const [trashHandler] = useTrash(EntityType.Assembly, setRedirect)
	const { setEntity } = useEntity(assembly?.id)

	const [blobTypeDownloading, setBlobTypeDownloading] = React.useState<BlobType | null>(null)
	const [pendingFiles, setPendingFiles] = React.useState<{
		[key in AssemblyPendingFileType]?: boolean
	}>(assembly?.pendingFiles || {})

	const refreshAssembly = async () => {
		const res = await GetAssemblyPendingFiles(assembly)
		const pendingFiles = res?.result?.assemblyPendingFiles?.pendingFiles
		if (
			res.ok &&
			_.sum(_.valuesIn(pendingFiles ?? {})) !== _.sum(_.valuesIn(assembly?.pendingFiles))
		) {
			setAssembly?.({ ...assembly, pendingFiles } as Assembly)
			setPendingFiles(pendingFiles)
			setEntity?.(assembly?.id, { ...assembly, pendingFiles } as Assembly)
		}
	}

	useInterval(refreshAssembly, assemblyPendingFiles({ pendingFiles } as Assembly) ? 3000 : null)
	React.useEffect(() => {
		refreshAssembly()
	}, [location])

	const b64toBlob = async (base64: string, type = "application/octet-stream") =>
		(await fetch(`data:${type};base64,${base64}`)).blob()

	const optionHandler = async (action: string) => {
		let resp
		switch (action) {
			case "export":
				if (!assembly) {
					return
				}
				resp = await ExportAssembly({ id: assembly.id })
				if (!resp.ok) {
					return
				}
				await b64toBlob(resp.result.bytes)
				downloadBlob(
					await b64toBlob(resp.result.bytes),
					(assembly.name + "-" + updated.substring(0, 19) + ".gob").trim(),
				)
				break
			case "delete":
				trashHandler(id, { name })
				break
			case "duplicate":
				if (assembly && setAssembly) {
					try {
						const resp = await SaveAssembly({
							..._.omit(assembly, "id", "created", "updated", "referenceID", "customerID"),
							name: assembly.name + " copy",
						})
						if (resp.ok) {
							const saved = _.get(resp, "result.assemblies[0]")
							setAssembly(saved)
							history.push(urlTo(EntityType.Assembly, saved.id))
							addNotification({
								alertLevel: AlertLevel.Success,
								title: t("assembly.notifications.success.created", { name: saved.name }),
							})
						}
					} catch (err) {
						handleError(err)
					}
				}
				break
		}
	}

	const downloadHandler = async (blobType: string) => {
		if (!assembly) {
			return
		}
		setBlobTypeDownloading(blobType as BlobType)
		const blobURL = entityToBlobURL(tenantID, assembly, blobType as BlobType, assembly.updated)
		let attempt = 0
		const intervalID = setInterval(async () => {
			const resp = await fetch(blobURL)
			if (!resp.ok) {
				if (attempt++ > 10) {
					addNotification({
						alertLevel: AlertLevel.Danger,
						title: t("common.errors.serverUnexpected"),
					})
					clearInterval(intervalID)
				}
				return
			}
			const blob = await resp.blob()
			const filename = (
				assembly.name +
				"-" +
				updated.substring(0, 19) +
				"." +
				blobTypeToExtension(blobType as BlobType)
			).trim()
			if (!assembly) {
				return
			}
			downloadBlob(blob, filename)
			setBlobTypeDownloading(null)
			clearInterval(intervalID)
		}, 3000)
	}

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("assemblies/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("assemblies", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("assemblies/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.files"),
			to: urlTo("assemblies/files", id),
			active: view === "files",
		},
		{
			label: t("common.tabs.workCellPrograms"),
			to: urlTo("assemblies/work-cell-programs", id),
			active: view === "workCellPrograms",
		},
		{
			label: t("common.tabs.tags"),
			to: urlTo("assemblies/tags", id),
			active: view === "tags",
		},
		{
			label: t("common.tabs.edit"),
			to: urlTo("assemblies/edit", id),
			active: view === "edit",
			disabled: !_.isNil(_.get(assembly, "shapeDiverParams")),
		},
		{
			label: t("common.tabs.options"),
			options: _.compact([
				_.isNil(_.get(assembly, "shapeDiverParams")) && {
					label: (
						<span key="download-as">
							<Icon className="mr-2" name="Download" fixedWidth />
							{t("assembly.buttons.download")}
						</span>
					),
					options: [
						...[
							BlobType.AssemblyGLTF,
							BlobType.AssemblySTP,
							BlobType.AssemblyIGES,
							BlobType.AssemblyPNG,
						].map((blobType) => ({
							label: (
								<span key={blobType}>
									<Icon
										className="mr-2"
										spin={blobTypeDownloading === blobType}
										name={blobTypeDownloading === blobType ? "Cog" : "Document"}
										fixedWidth
									/>
									{blobTypeToExtension(blobType)?.toLocaleUpperCase()}
								</span>
							),
							value: blobType,
							onOptionClick: downloadHandler,
						})),
					],
				},
				_.isNil(_.get(assembly, "shapeDiverParams")) && {
					label: (
						<span key="export">
							<Icon className="mr-2" name="Document" fixedWidth />
							{t("assembly.buttons.export")}
						</span>
					),
					value: "export",
				},
				_.isNil(_.get(assembly, "shapeDiverParams")) && {
					label: (
						<span key="duplicate">
							<Icon className="mr-2" name="Copy" fixedWidth />
							{t("assembly.buttons.duplicate")}
						</span>
					),
					value: "duplicate",
				},
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("assembly.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			]),
			onOptionClick: optionHandler,
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("assembly.titles.new")
	} else if (view === "edit") {
		title = t("assembly.titles.edit", { name })
	} else {
		title = t("assembly.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={assembly} entityType={EntityType.Assembly} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4 flex gap-1">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
						{!_.isEmpty(assembly?.errors) && (
							<Badge label={t("common.labels.errors")} color="red">
								<p>{Object.values(assembly?.errors ?? {}).flat().length}</p>
							</Badge>
						)}
						{assemblyPendingFiles({ pendingFiles } as Assembly) && (
							<>
								<Badge label={_.startCase(t("common.headers.filesPending"))}>
									<DateTime time={updated} />
									<Icon
										name="Cog"
										size="lg"
										fixedWidth
										spin={true}
										className="ml-1 text-gray-300"
									/>
								</Badge>
							</>
						)}
					</div>

					<Tabs tabs={tabs} className="" />
				</>
			) : null}
		</>
	)
}
