import React from "react"

import { Await, PageContainer, WorkCellServerDetails } from "@app/components"
import { WorkCellServerHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkCellServer } from "@app/domain"

export const WorkCellServerShowPage: React.FC = () => {
	const [workCellServer, workCellServerLoading] = useEntity<WorkCellServer>(
		EntityType.WorkCellServer,
	)

	return (
		<PageContainer>
			<WorkCellServerHeader workCellServer={workCellServer} view="show" />
			<Await
				loading={workCellServerLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkCellServerDetails workCellServer={workCellServer} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
