import React from "react"

import { Await, PageContainer, WorkEventDetails } from "@app/components"
import { WorkEventHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkEvent } from "@app/domain"

export const WorkEventShowPage: React.FC = () => {
	const [workEvent, workEventLoading] = useEntity<WorkEvent>(EntityType.WorkEvent)

	return (
		<PageContainer>
			<WorkEventHeader workEvent={workEvent} view="show" />
			<Await
				loading={workEventLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkEventDetails workEvent={workEvent} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
