import React from "react"
import _ from "lodash"

import { User } from "@app/domain"
import { GetUsers } from "@app/api"

type UserMap = { [key: string]: User }

export const useUsers = (): [UserMap, boolean] => {
	const [loading, setLoading] = React.useState<boolean>(true)
	const [users, setUsers] = React.useState<UserMap>({})

	React.useEffect(() => {
		let canceled = false
		const load = async () => {
			const result = await GetUsers({ limit: 100 })
			if (canceled) {
				return
			}
			if (result.ok) {
				const users = _.get(result, "result.users", [])
				setUsers((prev) =>
					_.reduce(
						users,
						(acc, user) => {
							acc[user.id] = user
							return acc
						},
						{ ...prev },
					),
				)
			}
			setLoading(false)
		}
		load()
		return () => {
			canceled = true
		}
	}, [])

	return [users, loading]
}
