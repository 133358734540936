import React from "react"
import _ from "lodash"

import { Await, PageContainer } from "@app/components"
import { Job, AlertLevel, EntityType } from "@app/domain"
import { JobForm } from "@app/forms"
import { JobHeader } from "@app/headers"
import { SaveJob, SaveJobParams, newSaveJobParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useSession } from "@app/contexts"
import { useEntity, useRedirect } from "@app/hooks"

export const JobEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [job, jobLoading] = useEntity<Job>(EntityType.Job)
	const { id, referenceID } = _.assign({ id: "", referenceID: "" }, job)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveJobParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveJob({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.jobs[0]")
				setRedirect(urlTo("job", saved), {
					alertLevel: AlertLevel.Success,
					title: t("job.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<JobHeader job={job} view="edit" />
			<Await
				loading={jobLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<JobForm
							busy={busy}
							defaultParams={newSaveJobParams(job)}
							errors={errors}
							onSave={saveHandler}
							referenceID={referenceID}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
