import _ from "lodash"
import { parseISO, getUnixTime } from "date-fns"

import Config from "@app/config"
import { Address, Attribute, TemplateField, TagColor } from "@app/domain"
import { SaveDocumentParams } from "@app/api"
import { fromUUID } from "@app/util"
import { Documentable } from "./documentable"

export type BillOfLadingLineItem = {
	color: TagColor
	description: string
	part: string
	quantity: string
	weight?: number
}

export type Document = {
	id: string
	customerID: string
	jobID?: string

	attributes: Attribute[]
	documentType: DocumentType
	name: string
	templateFields: TemplateField[]
	pageCount: number | null

	billOfLading?: {
		metric: boolean
		lineItems: BillOfLadingLineItem[]
		workFacilityAddress: Address | null
	}

	file?: {
		originalFilename: string
		mimeType: string
	}

	documentables: Documentable[]

	created: string
	updated: string
}

export enum DocumentType {
	BillOfLading = "BillOfLading",
	File = "File",
}

export const newDocument = (opts?: SaveDocumentParams): Document => {
	return _.assign(
		{
			id: "",
			customerID: "",
			jobID: undefined,

			attributes: [],
			documentType: DocumentType.File,
			name: "",
			templateFields: [],
			pageCount: null,

			billOfLading: undefined,
			file: undefined,

			documentables: [],

			created: "",
			updated: "",
		},
		opts,
	)
}

export class DocumentUtils {
	static url(v?: Document): string {
		if (v) {
			return `${Config.baseURL}/d/${fromUUID(v.id)}`
		}
		return ""
	}

	static pngURL(document: Document | undefined, tenantID: number, page = 1): string {
		if (!document) {
			return ""
		}
		return `/f/blobs/${tenantID}/${fromUUID(
			document.id,
		)}.png?type=DocumentPNG&pageIndex=${page}&updated=${DocumentUtils.updated(document)}`
	}

	static pdfURL(document: Document | undefined, tenantID: number): string {
		if (!document) {
			return ""
		}
		return `/f/blobs/${tenantID}/${fromUUID(
			document.id,
		)}.pdf?type=DocumentPDF&updated=${DocumentUtils.updated(document)}`
	}

	static updated(v?: Document): string {
		if (v && v.updated) {
			const t = parseISO(v.updated)
			return getUnixTime(t).toString()
		}
		return ""
	}

	static processTemplateFields(document?: Document): TemplateField[] {
		if (!document) {
			return []
		}
		const attributes = document.attributes
		return _.map(document.templateFields, (templateField) => {
			let label = _.get(templateField, "label", "")
			let value = _.get(templateField, "value", "")
			_.each(attributes, (attr) => {
				label = label.replaceAll(`{{${attr.name}}}`, attr.value)
				value = value.replaceAll(`{{${attr.name}}}`, attr.value)
			})
			return { label, value, options: templateField.options }
		})
	}

	static colorToClassName(color: TagColor): string {
		switch (color) {
			case TagColor.Red:
				return "bg-red-500"
			case TagColor.Pink:
				return "bg-pink-400"
			case TagColor.Orange:
				return "bg-orange-500"
			case TagColor.Yellow:
				return "bg-yellow-500"
			case TagColor.Green:
				return "bg-green-500"
			case TagColor.Blue:
				return "bg-blue-300"
			case TagColor.Purple:
				return "bg-purple-500"
			case TagColor.White:
			default:
				return ""
		}
	}
}
