import _ from "lodash"
import { v4 } from "uuid"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkAction, Entity, Pagination } from "@app/domain"

export type SaveWorkActionParams = {
	id: string

	actorIsSubject: boolean
	description?: string
	disabled: boolean
	entityTypes: string[]
	maxDurationSeconds: number
	name: string
	resolveReleaseUnitStatus: boolean
}

export const newSaveWorkActionParams = (opts?: WorkAction): SaveWorkActionParams => {
	return _.assign(
		{
			id: v4(),
			actorIsSubject: false,
			description: "",
			disabled: false,
			entityTypes: [],
			maxDurationSeconds: 0,
			name: "",
			resolveReleaseUnitStatus: false,
		},
		opts,
	)
}

export type SaveWorkActionResult = {
	workActions: WorkAction[]
}

export const SaveWorkAction = async (
	params: SaveWorkActionParams,
): Promise<Result<SaveWorkActionResult>> => {
	const data = await rpc("SaveWorkAction", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkActionResult>(data)
}

export type GetWorkActionsParams = {
	ids?: string[]
	includeIDs?: string[]

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkActionsResult = {
	workActions: WorkAction[]
	pagination?: Pagination
}

export const GetWorkActions = async (
	params: GetWorkActionsParams,
): Promise<Result<GetWorkActionsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkActions", "workActions", id).then((workAction?: Entity) => {
			return {
				ok: true,
				result: { workActions: _.compact([workAction as WorkAction]) },
			} as Result<GetWorkActionsResult>
		}) as Promise<Result<GetWorkActionsResult>>
	}
	const data = await rpc("GetWorkActions", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkActionsResult>(data)
}
