import { rpc, Cache } from "@app/api"

import type { Result } from "@app/api"
import type { Tenant, TenantToUser } from "@app/domain"

export type GetTenantsResult = {
	tenants: Tenant[]
}

export const GetTenants = async (): Promise<Result<GetTenantsResult>> => {
	const data = await rpc("GetTenants", {})
	Cache.scan(data)
	return data as Result<GetTenantsResult>
}

export type GetTenantToUsersByUserIDParams = {
	id: string
}

export type GetTenantToUsersByUserIDResult = {
	tenantToUsers: TenantToUser[]
}

export const GetTenantToUsersByUserID = async (
	params: GetTenantToUsersByUserIDParams,
): Promise<Result<GetTenantToUsersByUserIDResult>> => {
	const data = await rpc("GetTenantToUsersByUserID", params)
	return data as Result<GetTenantToUsersByUserIDResult>
}
