import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"

import { Await, PageContainer, ReleaseDetails } from "@app/components"
import { ReleaseHeader } from "@app/headers"
import { Release, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const ReleaseShowPage: React.FC = () => {
	const { pathname } = useLocation()
	const segments = pathname.split("/")
	const section = _.size(segments) === 4 ? _.last(segments) || "" : ""
	const [release, releaseLoading] = useEntity<Release>(EntityType.Release)

	return (
		<PageContainer>
			<ReleaseHeader release={release} view="show" section={section} />
			<Await
				loading={releaseLoading}
				then={() => (
					<>
						<div className="px-2 sm:px-0">
							<ReleaseDetails release={release} section={section} />
						</div>
					</>
				)}
			/>
		</PageContainer>
	)
}
