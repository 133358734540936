import React from "react"
import _ from "lodash"

import { DocumentPreview, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { Document, DocumentUtils, EntityType } from "@app/domain"

interface IDocumentDetailsProps {
	document?: Document
}

export const DocumentDetails: React.FC<IDocumentDetailsProps> = (props) => {
	const { t } = useSession()
	const { document } = props
	if (!document) {
		return null
	}
	return (
		<div className="flex flex-col md:flex-row md:gap-x-8">
			<div className="pt-1 px-2 max-w-lg">
				<Property label={t("document.labels.name")} value={document.name} />
				<Property
					label={t("document.labels.documentType")}
					value={t(`document.types.${_.camelCase(document.documentType)}`)}
				/>
				<Property
					label={t("document.labels.publicURL")}
					url={DocumentUtils.url(document)}
					value={DocumentUtils.url(document)}
				/>
				<Property group={t("common.headers.associations")} />
				<Property
					label={t(`entity.customer`)}
					relation={EntityType.Customer}
					value={document.customerID}
				/>
				{document.jobID ? (
					<Property label={t(`entity.job`)} relation={EntityType.Job} value={document.jobID} />
				) : null}
				{_.map(document.documentables, (v, i) => (
					<Property
						key={i}
						label={t(`entity.${_.camelCase(v.documentableType)}`)}
						relation={v.documentableType as EntityType}
						value={v.documentableID}
					/>
				))}
			</div>
			<div className="hidden md:block">
				<DocumentPreview document={document} />
			</div>
		</div>
	)
}
