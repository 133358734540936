import React from "react"

import { Await, PageContainer, EventLogDetails } from "@app/components"
import { EventLogHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, EventLog } from "@app/domain"

export const EventLogShowPage: React.FC = () => {
	const [eventLog, eventLogLoading] = useEntity<EventLog>(EntityType.EventLog)

	return (
		<PageContainer>
			<EventLogHeader eventLog={eventLog} />
			<Await
				loading={eventLogLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<EventLogDetails eventLog={eventLog} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
