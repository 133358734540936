import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import { WorkResource, EntityType } from "@app/domain"

interface IWorkResourceDetailsProps {
	workResource?: WorkResource
}

export const WorkResourceDetails: React.FC<IWorkResourceDetailsProps> = (props) => {
	const { t } = useSession()

	const { workResource } = props

	if (_.isNil(workResource)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("workResource.labels.id")} id={workResource.id} />
			<Property label={t("workResource.labels.name")} value={workResource.name} />
			<Property label={t("workResource.labels.description")} value={workResource.description} />
			<Property label={t("workResource.labels.url")} value={workResource.url} />
			<Property
				label={t("workResource.labels.workFacilityID")}
				relation={EntityType.WorkFacility}
				value={workResource.workFacilityID}
			/>
			<Property
				label={t("workResource.labels.workCellID")}
				relation={EntityType.WorkCell}
				value={workResource.workCellID}
			/>
			<Property label={t("workResource.labels.disabled")} value={workResource.disabled} />
		</div>
	)
}
