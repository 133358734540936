import React from "react"

import { Await, PageContainer, UserDetails } from "@app/components"
import { UserHeader } from "@app/headers"
import { User, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const UserShowPage: React.FC = () => {
	const [user, userLoading] = useEntity<User>(EntityType.User)

	return (
		<PageContainer>
			<UserHeader user={user} view="show" />
			<Await
				loading={userLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<UserDetails user={user} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
