import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import { ReleaseUnit, EntityType, WorkFacility } from "@app/domain"
import { useRelation } from "@app/hooks"

interface IReleaseUnitDetailsProps {
	releaseUnit?: ReleaseUnit
}

export const ReleaseUnitDetails: React.FC<IReleaseUnitDetailsProps> = (props) => {
	const { t } = useSession()

	const { releaseUnit } = props
	const [workFacility] = useRelation<WorkFacility>(
		EntityType.WorkFacility,
		releaseUnit?.workFacilityID,
	)

	if (_.isNil(releaseUnit)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("releaseUnit.labels.referenceID")} id={releaseUnit.referenceID} />
			<Property label={t("releaseUnit.labels.buildType")} value={releaseUnit.buildType} />
			<Property label={t("releaseUnit.labels.controlCodes")} value={releaseUnit.controlCodes} />
			<Property label={t("releaseUnit.labels.description")} value={releaseUnit.description} />
			<Property
				label={t("releaseUnit.labels.scheduled")}
				date={releaseUnit.scheduled}
				dateTimezone={workFacility?.timezone}
				dateExplicit
			/>
			<Property label={t("releaseUnit.labels.unit")} value={releaseUnit.unit} />
			<Property label={t("releaseUnit.labels.notes")} value={releaseUnit.notes} />
			<Property label={t("releaseUnit.labels.status")} value={releaseUnit.status} />
			<Property group="Associations" />
			<Property
				label={t("releaseUnit.labels.assemblyID")}
				relation={EntityType.Assembly}
				value={releaseUnit.assemblyID}
			/>
			<Property
				label={t("releaseUnit.labels.customerID")}
				relation={EntityType.Customer}
				value={releaseUnit.customerID}
			/>
			<Property
				label={t("releaseUnit.labels.workFacilityID")}
				relation={EntityType.WorkFacility}
				value={releaseUnit.workFacilityID}
			/>
			<Property
				label={t("releaseUnit.labels.jobID")}
				relation={EntityType.Job}
				value={releaseUnit.jobID}
			/>
			<Property
				label={t("releaseUnit.labels.releaseID")}
				relation={EntityType.Release}
				value={releaseUnit.releaseID}
			/>
			<Property
				label={t("releaseUnit.labels.tagID")}
				relation={EntityType.Tag}
				value={releaseUnit.tagID}
			/>
			<Property
				label={t("releaseUnit.labels.workCellID")}
				relation={EntityType.WorkCell}
				value={releaseUnit.workCellID}
			/>
		</div>
	)
}
