import React from "react"

import { Icon } from "@app/components"

interface IAlertProps {
	children: null | string | JSX.Element
	className?: string
	color: string
	icon?: string
	title: string
}

export const Alert: React.FC<IAlertProps> = (props) => {
	const { children, icon, title, className = "", color = "gray" } = props

	let borderColor = "border-gray-400"
	let textColor = "text-gray-700"
	let bgColor = "bg-gray-200"
	switch (color) {
		case "green":
			borderColor = "border-green-400"
			textColor = "text-green-700"
			bgColor = "bg-green-200"
			break
		case "yellow":
			borderColor = "border-yellow-400"
			textColor = "text-yellow-700"
			bgColor = "bg-yellow-200"
			break
		case "red":
			borderColor = "border-red-400"
			textColor = "text-red-700"
			bgColor = "bg-red-200"
			break
		case "blue":
			borderColor = "border-blue-400"
			textColor = "text-blue-500"
			bgColor = "bg-blue-100"
			break
	}

	return (
		<div className={`p-4 border ${borderColor} ${bgColor} flex ${className}`}>
			{icon ? (
				<div className="flex-initial">
					<Icon name={icon} className={`mr-2 ${textColor}`} />
				</div>
			) : null}
			<div className="flex-initial">
				<div className={`font-bold ${textColor}`}>{title}</div>
				<div className="mt-1">{children}</div>
			</div>
		</div>
	)
}
