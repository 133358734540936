import React from "react"
import _ from "lodash"
import QRCode from "react-qr-code"

import { Property, Label, Tooltip } from "@app/components"
import { useSession } from "@app/contexts"
import { fromUUID } from "@app/util"

import { WorkDevice, EntityType, WorkDeviceUtils } from "@app/domain"

interface IWorkDeviceDetailsProps {
	workDevice?: WorkDevice
}

export const WorkDeviceDetails: React.FC<IWorkDeviceDetailsProps> = (props) => {
	const { t } = useSession()

	const { workDevice } = props

	if (_.isNil(workDevice)) {
		return null
	}

	const configURL = WorkDeviceUtils.configURL(workDevice)

	return (
		<>
			<div className="pt-1 sm:px-2 max-w-lg">
				<Property label={t("workDevice.labels.id")} value={fromUUID(workDevice.id)} />
				<Property
					label={t("workDevice.labels.workDeviceType")}
					value={t(`workDeviceTypes.${workDevice.workDeviceType}`)}
				/>
				<Property label={t("workDevice.labels.description")} value={workDevice.description} />
				<Property label={t("workDevice.labels.inventoryTag")} value={workDevice.inventoryTag} />
				<Property
					label={t("workDevice.labels.secret")}
					id={_.truncate(workDevice.secret, { length: 11 })}
				/>
				<Property
					label={t("workDevice.labels.workFacilityID")}
					relation={EntityType.WorkFacility}
					value={workDevice.workFacilityID}
				/>
				<Property label={t("workDevice.labels.disabled")} value={workDevice.disabled} />
			</div>
			{!workDevice.disabled && (
				<div className="pl-2 inline-block">
					<Label className="block">{t("workDevice.labels.configQRCode")}</Label>
					<Tooltip content={configURL} place="right">
						<div className="p-4 border border-gray-400 inline-block">
							<a href={configURL}>
								<QRCode value={configURL} />
							</a>
						</div>
					</Tooltip>
				</div>
			)}
		</>
	)
}
