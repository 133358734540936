import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { WorkCell, AlertLevel, EntityType } from "@app/domain"
import { WorkCellForm } from "@app/forms"
import { WorkCellHeader } from "@app/headers"
import { newSaveWorkCellParams, SaveWorkCell, SaveWorkCellParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkCellEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workCell, workCellLoading] = useEntity<WorkCell>(EntityType.WorkCell)
	const { id, name } = _.assign({ id: "", name: "..." }, workCell)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveWorkCellParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkCell({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workCells[0]")
				setRedirect(urlTo("workCells", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workCell.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workCell.titles.edit", { name })}</Title>
			<WorkCellHeader workCell={workCell} view="edit" />
			<Await
				loading={workCellLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkCellForm
							busy={busy}
							defaultParams={newSaveWorkCellParams(workCell)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
