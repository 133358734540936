import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { ReleaseUnit, AlertLevel, EntityType, ReleaseUnitUtils } from "@app/domain"
import { ReleaseUnitForm } from "@app/forms"
import { ReleaseUnitHeader } from "@app/headers"
import { newSaveReleaseUnitParams, SaveReleaseUnit, SaveReleaseUnitParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const ReleaseUnitEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [releaseUnit, releaseUnitLoading] = useEntity<ReleaseUnit>(EntityType.ReleaseUnit)
	const { id } = _.assign({ id: "" }, releaseUnit)
	const name = ReleaseUnitUtils.name(releaseUnit)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveReleaseUnitParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveReleaseUnit({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.releaseUnits[0]")
				setRedirect(urlTo("releaseUnits", saved), {
					alertLevel: AlertLevel.Success,
					title: t("releaseUnit.notifications.success.updated", {
						name: ReleaseUnitUtils.name(saved),
					}),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("releaseUnit.titles.edit", { name })}</Title>
			<ReleaseUnitHeader releaseUnit={releaseUnit} view="edit" />
			<Await
				loading={releaseUnitLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<ReleaseUnitForm
							busy={busy}
							defaultParams={newSaveReleaseUnitParams(releaseUnit)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
