import React from "react"
import _ from "lodash"

import {
	GetContacts,
	Cache,
	ContactableParams,
	SaveContactParams,
	newContactableParams,
} from "@app/api"
import { ContactForm } from "@app/forms"
import { Badge, Select, Button, Icon, InputSearch, ButtonIcon } from "@app/components"
import { useSession } from "@app/contexts"
import { Contact, ContactUtils, EntityType } from "@app/domain"
import { toUUID } from "@app/util"
import { useQuery, useRelation, useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IContactableFormProps {
	onChange: (contactable?: ContactableParams) => void
	defaultContactable: ContactableParams
	defaultOpen: boolean
	disabled?: boolean
	busy?: boolean
	errors: { [key: string]: string }
}

export const ContactableForm: React.FC<IContactableFormProps> = (props) => {
	const { t } = useSession()

	const {
		busy = false,
		defaultContactable,
		defaultOpen = false,
		disabled = false,
		errors,
		onChange,
	} = props

	if (!_.isEmpty(defaultContactable.contactID) && _.isEmpty(_.get(defaultContactable, "contact"))) {
		defaultContactable.contact = Cache.get(defaultContactable.contactID) as Contact | undefined
	}
	const isInitialMount = React.useRef(true)
	const { params, setParams, selectHandler } = useFormHelpers<ContactableParams>(defaultContactable)
	const [open, setOpen] = React.useState<boolean>(defaultOpen)

	const removeHandler = () => {
		onChange(undefined)
	}

	React.useEffect(() => {
		if (!_.isEmpty(errors) && !open) {
			setOpen(true)
		}
	}, [errors])

	React.useEffect(() => {
		if (!open && !isInitialMount.current) {
			isInitialMount.current = false
			const contact = _.get(params, "contact", {}) as SaveContactParams
			if (
				_.every(
					[
						contact.emails,
						contact.firstName,
						contact.lastName,
						contact.phoneNumbers,
						contact.title,
					],
					_.isEmpty,
				)
			) {
				removeHandler()
			}
		}
	}, [open])

	React.useEffect(() => {
		const loadContact = async () => {
			let contact = Cache.get(params.contactID) as Contact | undefined
			if (!contact) {
				const result = await GetContacts({ ids: [params.contactID || ""] })
				if (result.ok) {
					contact = _.get(result, "contacts[0]")
				}
			}
			setParams((prev) => {
				return { ...prev, contact }
			})
		}
		if (!params.contact && !_.isEmpty(params.contactID)) {
			loadContact()
		}
	}, [params])

	React.useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false
		} else {
			onChange(params)
		}
	}, [params])

	const labels = _.map(En.contactable.labelOptions, (_val, key) =>
		t(`contactable.labelOptions.${key}`),
	).sort()

	const defaultLabelsOption = _.map(_.get(params, "labels", []), (label) => {
		return { value: label, label: label }
	})

	const labelOptions = labels.map((v) => {
		return { value: v, label: v }
	})

	const contactHandler = (contact: SaveContactParams) => {
		setParams((prev) => {
			return { ...prev, contact }
		})
	}

	if (!params.contact) {
		return null
	}

	const contact = params.contact

	return (
		<div className="border-t border-gray-300 py-5 mb-2">
			<div className="p-4 bg-gray-200 border border-gray-300">
				<div className={`flex flex-row justify-between gap-x-2 ${open ? "" : "hidden"}`}>
					<div className="w-full">
						<ContactForm
							errors={_.reduce(
								errors,
								(acc, err: string, k: string) => {
									const prefix = "contact."
									if (_.startsWith(k, prefix)) {
										acc[k.slice(_.size(prefix))] = err
									}
									return acc
								},
								{},
							)}
							defaultParams={contact}
							onChange={contactHandler}
							disabled={disabled || busy}
						/>

						<Select
							defaultValue={defaultLabelsOption}
							disabled={disabled || busy}
							isMulti
							error={_.get(errors, "labels")}
							isSearchable={true}
							label={t("contactable.labels.labels")}
							name="labels"
							onChange={selectHandler("labels", true)}
							options={labelOptions}
						/>
					</div>

					<div className="pt-9">
						<ButtonIcon
							tooltip={t("contactable.buttons.remove")}
							icon="Minus"
							onClick={removeHandler}
							disabled={disabled}
							busy={busy}
						/>
					</div>
				</div>

				<div className={`flex justify-between ${open ? "hidden" : ""}`}>
					<div>
						<h2 className="text-lg">
							{_.trim([contact.lastName, contact.firstName].join(", "), ", ")}
						</h2>
						{!_.isEmpty(contact.title) ? (
							<div className="text-sm text-gray-600">{contact.title}</div>
						) : null}
					</div>
					<div className="text-right">
						{_.map(params.labels, (label) => {
							return (
								<Badge key={label} className="ml-1">
									{label}
								</Badge>
							)
						})}
					</div>
				</div>

				<button
					type="button"
					className="w-full mt-4 bg-gray-300 text-center text-gray-500 hover:text-yellow-700 hover:bg-yellow-300"
					onClick={() => setOpen(!open)}
				>
					<Icon name={open ? "ChevronUp" : "ChevronDown"} />
				</button>
			</div>
		</div>
	)
}

interface IContactablesFormProps {
	busy?: boolean
	defaultParams: ContactableParams[]
	disabled?: boolean
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (contactables: ContactableParams[], removed: string[]) => void
}

export const ContactablesForm: React.FC<IContactablesFormProps> = (props) => {
	const { defaultParams, onChange, disabled = false, busy = false, errors, errorsKey } = props

	const { t, handleError } = useSession()
	const { contact } = useQuery()
	const [injectedContact, disabledInjectedContact] = useRelation(
		EntityType.Contact,
		toUUID(contact),
	)

	const node = React.useRef<HTMLDivElement>(null)

	const [active, setActive] = React.useState<boolean>(false)
	const [contactables, setContactables] = React.useState<ContactableParams[]>(defaultParams)
	const [highlightIndex, setHighlightIndex] = React.useState<number>(0)
	const [keys, setKeys] = React.useState<string[]>(_.map(defaultParams, () => _.uniqueId()))
	const [results, setResults] = React.useState<Contact[]>([])
	const [searching, setSearching] = React.useState<boolean>(false)
	const [removed, setRemoved] = React.useState<string[]>([])

	const handleClickOutside = (e: MouseEvent) => {
		if (node?.current?.contains(e.target as Node)) {
			return
		}
		setActive(false)
	}

	React.useEffect(() => {
		if (active) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [active])

	React.useEffect(() => {
		onChange(contactables, removed)
	}, [contactables, removed])

	const highlighted = Math.max(0, Math.min(highlightIndex, _.size(results)))

	const focusHandler = (): void => {
		setActive(true)
		setHighlightIndex(0)
	}

	const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

	const inputChangeHandler = async (query: string) => {
		if (_.isEmpty(query)) {
			setResults([])
			return
		}
		const waitUntil = Date.now() + 300
		setSearching(true)
		try {
			const excludeIDs = _.compact(
				_.map(contactables, (v) => (_.isEmpty(v.contactID) ? null : v.contactID)),
			)
			const resp = await GetContacts({ query, excludeIDs, limit: 3, offset: 0 })
			if (resp.ok) {
				setResults(_.get(resp, "result.contacts", []))
			}
		} catch (err) {
			handleError(err)
		}
		const wait = waitUntil - Date.now()
		if (wait > 0) {
			await timeout(wait)
		}
		setSearching(false)
	}

	const newHandler = (): void => {
		setKeys((keys) => {
			return [_.uniqueId()].concat([...keys])
		})
		setContactables((contactables) => {
			return [newContactableParams()].concat([...contactables])
		})
	}

	const addContact = (contact: Contact) => {
		if (_.find(contactables, (c) => c.contactID === contact.id)) {
			return
		}
		setKeys((keys) => {
			return [_.uniqueId()].concat([...keys])
		})
		setContactables((contactables: ContactableParams[]) => {
			return [
				{
					contact: _.pick(contact, [
						"id",
						"addresses",
						"emails",
						"firstName",
						"lastName",
						"phoneNumbers",
						"title",
					]),
					id: "",
					contactableID: "",
					contactID: contact.id,
					labels: [],
				},
				...contactables,
			]
		})
	}

	React.useEffect(() => {
		if (injectedContact) {
			addContact(injectedContact as Contact)
		}
	}, [disabledInjectedContact])

	const renderedResults = results.map((r, i) => {
		let className = "cursor-pointer "
		if (i === highlighted) {
			className += " bg-yellow-300"
		}
		return (
			<li
				key={r.id}
				className={className}
				onMouseEnter={() => {
					setHighlightIndex(i)
				}}
				onClick={(e) => {
					e.preventDefault()
					addContact(r)
					setResults([])
					setActive(false)
					return false
				}}
			>
				<div className="py-1 px-1">
					<div className="px-2 py-2 sm:px-4">
						<div className="flex items-center justify-between">
							<div className="text-black font-medium truncate">{ContactUtils.name(r)}</div>
							<div className="text-xs truncate">{r.title}</div>
						</div>
						<div>
							{_.map(r.emails, (v) => {
								return (
									<div key={v.email} className="flex items-center justify-between mt-1">
										<div className="text-xs font-medium truncate mr-2">{v.email}</div>
										<div className="text-xs truncate">
											{_.map(v.labels, (l) => (
												<Badge key={l} color="blue" className="ml-1">
													{l}
												</Badge>
											))}
										</div>
									</div>
								)
							})}
							{_.map(r.phoneNumbers, (v) => {
								return (
									<div key={v.phoneNumber} className="flex items-center justify-between mt-1">
										<div className="text-xs font-medium truncate mr-2">{v.phoneNumber}</div>
										<div className="text-xs truncate">
											{_.map(v.labels, (l) => (
												<Badge key={l} color="blue" className="ml-1">
													{l}
												</Badge>
											))}
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
				<div className="border-t border-gray-300"></div>
			</li>
		)
	})

	const contactableHandler = (index: number): ((contactable?: ContactableParams) => void) => {
		return (contactable?: ContactableParams): void => {
			if (_.isNil(contactable)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				let removed: ContactableParams | undefined
				setContactables((contactables) => {
					const result = [...contactables]
					removed = result[index]
					result.splice(index, 1)
					return result
				})
				setRemoved((prev) => {
					const result = [...prev]
					const id = _.get(removed, "id", "")
					if (!_.isEmpty(id)) {
						result.push(id)
					}
					return result
				})
			} else {
				setContactables((contactables) => {
					const result = [...contactables]
					result[index] = contactable
					return result
				})
			}
		}
	}

	return (
		<>
			<h2 className="text-md mb-4 text-gray-700">
				{t("contactable.header")} <Badge quantity>{_.size(contactables)}</Badge>
				<Button
					className="float-right"
					disabled={disabled}
					busy={busy}
					type="button"
					label={t("contactable.buttons.new")}
					onClick={newHandler}
					small
					add
				/>
			</h2>

			<div ref={node} className="relative w-full focus-within:text-gray-600">
				<div className="absolute text-gray-300 inset-y-0 left-2 flex items-center pointer-events-none">
					<Icon name={searching ? "Cog" : "Search"} size="1x" spin={searching} fixedWidth />
				</div>
				<InputSearch
					disabled={disabled || busy}
					busy={searching}
					placeholder={t("contactable.placeholders.findExisting")}
					onKeyDown={(e) => {
						if (e.key === "ArrowDown") {
							e.preventDefault()
							setHighlightIndex(highlightIndex + 1)
						} else if (e.key === "ArrowUp") {
							e.preventDefault()
							setHighlightIndex(highlightIndex - 1)
						} else if (e.key === "Enter" && _.some(results)) {
							e.preventDefault()
							addContact(results[highlightIndex])
							setResults([])
							const target = e.target as HTMLInputElement
							target.value = ""
						} else if (e.key === "Escape") {
							e.preventDefault()
							setActive(false)
							setHighlightIndex(0)
							setResults([])
							const target = e.target as HTMLInputElement
							target.blur()
						}
					}}
					onFocus={focusHandler}
					onInputChange={inputChangeHandler}
				/>

				{_.isEmpty(results) || !active ? null : (
					<div className="z-20 absolute left-0 top-10 mt-0 w-full max-w-7xl shadow-xl">
						<div
							className="bg-white"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							<div className="border-t border-gray-300"></div>
							<ul>{renderedResults}</ul>
						</div>
					</div>
				)}
			</div>

			<div className="mt-4">
				{_.map(contactables, (v, i) => (
					<ContactableForm
						defaultContactable={v}
						defaultOpen={v.contactID === ""}
						key={keys[i]}
						disabled={disabled}
						busy={busy}
						onChange={contactableHandler(i)}
						errors={_.reduce(
							errors,
							(acc, err: string, k: string) => {
								const prefix = `${errorsKey}[${i}].`
								if (_.startsWith(k, prefix)) {
									acc[k.slice(_.size(prefix))] = err
								}
								return acc
							},
							{},
						)}
					/>
				))}
			</div>
		</>
	)
}
