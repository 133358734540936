import React from "react"
import _ from "lodash"

import { AsyncSelect } from "@app/components"
import { Job, EntityType } from "@app/domain"
import { GetJobs } from "@app/api"
import { useRelation } from "@app/hooks"
import { useSession } from "@app/contexts"

import type { OptionType } from "@app/components"

interface IJobSelectProps {
	autoFocus?: boolean
	defaultValue?: string
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	onChange: (v: OptionType[]) => void
}

export const JobSelect: React.FC<IJobSelectProps> = (props) => {
	const { handleError } = useSession()
	const { autoFocus, defaultValue = "", onChange, disabled, name, label, hint, error } = props

	const [jobID, setJobID] = React.useState<string>(defaultValue)
	const [job, loadingJob] = useRelation<Job>(EntityType.Job, defaultValue)

	const getJobs = (inputValue: string) => {
		const includeIDs = _.compact([jobID])
		return new Promise<OptionType[]>((resolve) => {
			GetJobs({
				includeIDs,

				query: inputValue,
				withoutCount: true,

				offset: 0,
				limit: 5,
			})
				.then((resp) => {
					if (resp.ok) {
						const jobs = _.get(resp, "result.jobs", []).map((c: Job) => {
							return {
								label: c.name,
								value: c.id,
							}
						})
						resolve(jobs as OptionType[])
					}
				})
				.catch((err) => {
					handleError(err)
				})
		})
	}

	const defaultOption = {
		label: job?.name ?? defaultValue,
		value: jobID,
	}

	if (loadingJob) {
		return null
	}
	return (
		<AsyncSelect
			autoFocus={autoFocus}
			cacheOptions={true}
			defaultOptions={true}
			defaultValue={_.isEmpty(defaultValue) ? null : defaultOption}
			disabled={disabled}
			hint={hint}
			error={error}
			isSearchable={true}
			label={label}
			loadOptions={getJobs}
			menuPlacement="auto"
			name={name}
			onChange={(v) => {
				const value = _.get(v, "value", "")
				setJobID(value)
				onChange(_.compact([v]))
			}}
		/>
	)
}
