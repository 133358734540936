import React from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"

import { Await, TagPreview } from "@app/components"
import { GetTagByReferenceID } from "@app/api"
import { Tag } from "@app/domain"
import { useQuery } from "@app/hooks"
import { useSession, useHTTPStatus } from "@app/contexts"

export const TagShowPublicPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { setHTTPStatus } = useHTTPStatus()

	const identifier = _.get(useParams(), "identifier", "")
	const parts = identifier.split("-")

	const print = _.has(useQuery(), "print")
	const referenceID = _.first(parts) || ""
	const item = _.last(parts) || ""

	let itemNum = 1
	if (!print) {
		itemNum = parseInt(item, 10)
		if (isNaN(itemNum)) {
			itemNum = 0
		}
	}

	const [tag, setTag] = React.useState<Tag | undefined>()
	const [loading, setLoading] = React.useState<boolean>(true)

	const { quantity = 0 } = tag || {}

	React.useEffect(() => {
		let canceled = false
		const load = async () => {
			try {
				const resp = await GetTagByReferenceID({ referenceID })
				if (canceled) {
					return
				}
				if (_.get(resp, "ok")) {
					const tag = _.get(resp, "result.tags[0]") as Tag | undefined
					if (tag) {
						setTag(tag)
					} else {
						setHTTPStatus(404)
					}
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		load()
		return () => {
			canceled = true
		}
	}, [referenceID])

	if (tag && !print) {
		if (itemNum < 1 || itemNum > tag.quantity) {
			setHTTPStatus(404)
		}
	}

	const name = `${_.get(tag, "referenceID", "")}-${itemNum}`
	const title = t("tag.titles.showPublic", { name })

	return (
		<>
			<Await
				loading={loading}
				then={() => (
					<>
						<Helmet>
							<title>{title}</title>
						</Helmet>
						{print ? (
							<div id="capture">
								{_.times(quantity, (i) => (
									<div style={{ pageBreakAfter: "always" }} key={i}>
										<TagPreview tag={tag} unit={i + 1} />
									</div>
								))}
							</div>
						) : (
							<div className="mt-4 flex flex-row justify-center">
								<TagPreview tag={tag} unit={itemNum} />
							</div>
						)}
					</>
				)}
			/>
		</>
	)
}
