import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"

import { Await, PageContainer, ShipmentDetails } from "@app/components"
import { ShipmentHeader } from "@app/headers"
import { Shipment, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const ShipmentShowPage: React.FC = () => {
	const { pathname } = useLocation()
	const segments = pathname.split("/")
	const section = _.size(segments) === 4 ? _.last(segments) || "" : ""
	const [shipment, shipmentLoading] = useEntity<Shipment>(EntityType.Shipment)

	return (
		<PageContainer>
			<ShipmentHeader shipment={shipment} view="show" section={section} />
			<Await
				loading={shipmentLoading}
				then={() => (
					<>
						<div className="px-2 sm:px-0">
							<ShipmentDetails shipment={shipment} section={section} />
						</div>
					</>
				)}
			/>
		</PageContainer>
	)
}
