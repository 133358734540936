import React from "react"
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
	DragStartEvent,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	rectSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable"
import short from "short-uuid"
import clsx from "clsx"

import { Icon, Input } from "@app/components"
import { useSession } from "@app/contexts"
import { MM_PER_IN, formatLength, toPrecision } from "@app/util"
import { InputType } from "@app/grpc/form"

interface IUseListInputProps {
	label: string
	range: number[]
	value: string
	metric: boolean
	type: InputType
}

interface IUseListInputReturn {
	label: string
	hint: string
	placeholder: string
	badChars: RegExp
	checkErrors: (value: string) => string
	toConfigurator: (value: string) => string
	toDisplay: (value: string) => string
	fromUser: (value: string) => string
}

const ftInToMM = (n: string) => {
	const [feet, inches] = [
		...n
			.split("-")
			.filter((v) => v)
			.map((v) => Number(v)),
		0,
	] // Inches are optional
	const negative = n[0] === "-" ? -1 : 1
	return (feet * 12 + inches) * MM_PER_IN * negative
}

const useListInput = (props: IUseListInputProps): IUseListInputReturn => {
	const { label, range, value, metric, type } = props
	const { t } = useSession()
	const [min, max] = range
	const placeholder = "10,20,30"
	const minHint = formatLength(metric, metric ? min : min / MM_PER_IN)
	const maxHint = formatLength(metric, metric ? max : Math.round(max / MM_PER_IN))
	const units = metric ? "mm" : "ft-in"
	switch (props.type) {
		case InputType.LENGTH: {
			return {
				label: `${label} (${units})`,
				placeholder: metric ? placeholder : "0-6,1-3.5",
				hint: `${minHint} to ${maxHint}`,
				checkErrors: (list: string) => {
					for (const n of list.split(",")) {
						if (n.length === 0 && list.length > 0) {
							continue
						}
						const number = Number(!metric ? ftInToMM(n) : n)
						if (
							(metric && isNaN(number)) ||
							(!metric &&
								(n.split("-").length > 3 ||
									n
										.split("-")
										.filter((v) => v)
										.some((v: string) => isNaN(Number(v)))))
						) {
							return t("validation.isNumber")
						}
						const clamped = Math.min(max, Math.max(min, number))
						if (clamped !== number) {
							return t("validation.inRangeUnits", {
								min: minHint,
								max: maxHint,
								units,
							})
						}
					}
					return ""
				},
				badChars: metric ? /[^\d.,-]/g : /[^\d.,-]/g,
				toConfigurator: (num) => String(toPrecision(Number(num), 3)),
				toDisplay: (numStr) => formatLength(metric, Number(numStr) / (metric ? 1 : MM_PER_IN)),
				fromUser: (num) => String(metric ? num : ftInToMM(num)),
			}
		}
		case InputType.INTEGER:
		// fallthrough
		case InputType.DOUBLE:
			return {
				label,
				placeholder,
				hint: `${min} to ${max}`,
				checkErrors: (list: string) => {
					for (const n of list.split(",")) {
						if (n.length === 0 && list.length > 0) {
							continue
						}
						const number = Number(n)
						if (isNaN(number)) {
							return t("validation.isNumber")
						}
						const clamped = Math.min(max, Math.max(min, number))
						if (clamped !== number) {
							return t("validation.inRange", {
								min,
								max,
							})
						}
					}
					return ""
				},
				badChars: type === InputType.DOUBLE ? /[^\d.,-]/g : /[^\d,-]/g,
				toConfigurator: (num) => num,
				toDisplay: (num) => num,
				fromUser: (num) => String(toPrecision(Number(num), 3)),
			}
		default:
		// fallthrough
		case InputType.STRING:
			return {
				label,
				placeholder: "a,b,c",
				hint: "",
				checkErrors: (list: string) => {
					for (const n of list.split(",")) {
						if (n.length === 0 && list.length > 0) {
							continue
						}
						if (value.length > max) {
							return t("validation.inRangeUnits", {
								min,
								max,
								units: "characters",
							})
						}
					}
					return ""
				},
				badChars: /(?:)/g, // No bad chars
				toConfigurator: (v) => v,
				toDisplay: (v) => v,
				fromUser: (v) => v,
			}
		case InputType.QUANTITY_AT_SPACING:
			return {
				label: `${label} (${units})`,
				placeholder: metric ? "10@300,2@200" : "3@0-6,10@1-3.5",
				hint: `${minHint} to ${maxHint}`,
				checkErrors: (list: string) => {
					for (const n of list.split(",")) {
						if (n.length === 0 && list.length > 0) {
							continue
						}
						const parts = n.split("@")
						if (!n.includes("@") || parts.length !== 2 || parts.some((v) => v.length === 0)) {
							return "Please enter a quantity and spacing separated by an @ symbol."
						}
						if (/[^\d]/g.test(parts[0])) {
							return t("validation.isInteger")
						}
						if (
							!metric &&
							(parts[1].split("-").length !== 2 || parts[1].split("-").some((v) => v.length === 0))
						) {
							return "Please separate feet and inches by a - symbol."
						}
						const numbers = n.split("@").map((n) => Number(!metric ? ftInToMM(n) : n))
						if (numbers[0] <= 0) {
							return "Quantity must be greater than 0."
						}
						if (numbers[1] < 0) {
							return "Spacing must be greater than or equal to 0."
						}
						if (numbers.some((n) => isNaN(n))) {
							return t("validation.isNumber")
						}
						const clamped = Math.min(max, Math.max(min, numbers[1]))
						if (clamped !== numbers[1]) {
							return t("validation.inRangeUnits", {
								min: minHint,
								max: maxHint,
								units,
							})
						}
					}
					return ""
				},
				badChars: metric ? /[^\d.,@]/g : /[^\d.,@-]/g,
				toConfigurator: (num) =>
					num
						.split("@")
						.map((n, i) => (i === 0 ? n : String(toPrecision(Number(n), 3))))
						.join("@"),
				toDisplay: (numStr) =>
					numStr
						.split("@")
						.map((n, i) =>
							i === 0 ? n : formatLength(metric, Number(n) / (metric ? 1 : MM_PER_IN)),
						)
						.join("@"),
				fromUser: (num) =>
					num
						.split("@")
						.map((n, i) => (i === 0 ? n : String(metric ? n : ftInToMM(n))))
						.join("@"),
			}
	}
}

interface IListInputProps {
	alt?: string
	label: string
	range: number[]
	defaultValue: string
	value: string
	error?: string
	metric: boolean
	disabled: boolean
	type: InputType
	onChange?: (v: string) => void
	onError?: (v: string) => void
	onBlur?: () => void
}

export const ListInput: React.FC<IListInputProps> = (props: IListInputProps) => {
	const {
		alt,
		defaultValue,
		value,
		error: errorProp = "",
		disabled,
		metric,
		type,
		onChange,
		onError = () => null,
		onBlur = () => null,
	} = props

	const { label, hint, placeholder, badChars, checkErrors, toConfigurator, toDisplay, fromUser } =
		useListInput(props)

	const separator = "?"
	const [activeId, setActiveId] = React.useState<string | null>(null)
	const [items, setItems] = React.useState<string[]>([])

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)

	const handleDragStart = (event: DragStartEvent) => {
		setActiveId(event.active.id)
	}

	const handleDragEnd = (event: DragEndEvent) => {
		setActiveId(null)
		const { active, over } = event
		if (over && active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.indexOf(active.id)
				const newIndex = items.indexOf(over.id)
				return arrayMove(items, oldIndex, newIndex)
			})
		}
	}

	React.useEffect(() => {
		if (onChange && items.length > 0) {
			onChange(
				items
					.map((v) => v.split(separator)[0])
					.map((v) => toConfigurator(v))
					.join(","),
			)
		}
	}, [items])

	const [error, setError] = React.useState("")
	React.useEffect(() => {
		onError(error)
	}, [error])

	const [num, setNum] = React.useState(defaultValue)
	React.useEffect(() => {
		if (value) {
			setNum(value)
		}
	}, [value])
	const submitHandler = () => {
		if (!error && num.length > 0) {
			setItems((items) => [
				...items,
				...num
					.split(",")
					.filter((n) => n)
					.map((n) => fromUser(n) + separator + short.generate()),
			])
			setNum("")
		}
	}

	const [metricInitialization, setMetricInitialization] = React.useState(metric)
	React.useEffect(() => {
		// No conversion necessary on mount if metric.
		if (metricInitialization) {
			setMetricInitialization(false)
			return
		}
		// Backwards compatible behavior (conversion of numbers in input box)
		if (type === InputType.LENGTH) {
			setNum(
				num
					.split(",")
					.map((v: string) =>
						metric
							? Math.round((ftInToMM(v.trim()) + Number.EPSILON) * 10) / 10 // Round to 1/10 mm
							: formatLength(metric, Number(v.trim()) / MM_PER_IN),
					)
					.join(","),
			)
		}
		if (type === InputType.QUANTITY_AT_SPACING) {
			setNum(
				num
					.split(",")
					.map((v: string) => {
						const parts = v.split("@")
						return (
							parts[0] +
							"@" +
							String(
								metric
									? Math.round((ftInToMM(parts[1].trim()) + Number.EPSILON) * 10) / 10 // Round to 1/10 mm
									: formatLength(metric, Number(parts[1].trim()) / MM_PER_IN),
							)
						)
					})
					.join(","),
			)
		}
	}, [metric])

	return (
		<>
			<div className="relative">
				<Input
					disabled={disabled}
					value={num}
					label={label}
					alt={alt}
					hint={hint}
					placeholder={placeholder}
					onChange={(e: React.FormEvent<HTMLInputElement>) => {
						const filteredInput = e.target["value"].replace(badChars, "")
						setNum(filteredInput)
						const errorMsg = checkErrors(filteredInput)
						setError(errorMsg)
						// Backwards compatible behavior (input can be left in input box)
						if (errorMsg === "" && onChange && items.length === 0) {
							onChange(
								filteredInput
									.split(",")
									.filter((v: string) => v)
									.map((v: string) => fromUser(v))
									.map((v: string) => toConfigurator(v))
									.join(","),
							)
						}
					}}
					onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
						if (e.key === "Enter") {
							e.preventDefault()
							submitHandler()
						}
					}}
					error={error || errorProp}
					name="length input"
					type="text"
					onBlur={onBlur}
				/>
				<button
					type="button"
					disabled={error.length > 0 || num.length === 0}
					className="hover:text-yellow-300 text-yellow-500 disabled:text-gray-500 absolute inset-y-0 right-2 flex items-center"
					onClick={submitHandler}
				>
					<Icon name="Plus" size="1x" fixedWidth />
				</button>
			</div>
			{items.length > 0 && (
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
					onDragStart={handleDragStart}
				>
					<ul className="flex flex-wrap flex-row gap-1 max-w-2xl mb-6 mt-1">
						<SortableContext items={items} strategy={rectSortingStrategy}>
							{items.map((v, i) => (
								<SortableItem
									key={i}
									id={v}
									value={toDisplay(v.split(separator)[0])}
									inactive={activeId === null}
									onTrash={() => {
										setItems((items) => {
											return items.filter((val) => val !== v)
										})
									}}
								/>
							))}
						</SortableContext>
						<button
							type="button"
							title="Remove all items"
							disabled={items.length === 0}
							className="hover:bg-red-300 h-8 rounded p-1 bg-red-400 disabled:bg-gray-500 text-gray-100 inset-y-0 right-2 flex items-center"
							onClick={() => setItems([])}
						>
							<Icon name="Trash" size="1x" fixedWidth />
						</button>
						<button
							type="button"
							title="Move items to input box"
							disabled={items.length === 0}
							className="hover:bg-gray-400 h-8 rounded p-1 bg-gray-500 disabled:bg-gray-300 text-gray-100 inset-y-0 right-2 flex items-center"
							onClick={() => {
								setNum(items.map((v) => toDisplay(v.split(separator)[0])).join(","))
								setItems([])
							}}
						>
							<Icon name="ArrowUp" size="1x" fixedWidth />
						</button>
					</ul>
				</DndContext>
			)}
		</>
	)
}

interface ISortableItemProps {
	id: string
	value: string
	onTrash: () => void
	inactive: boolean
}

const SortableItem: React.FC<ISortableItemProps> = (props: ISortableItemProps) => {
	const { id, value, onTrash, inactive } = props
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: id,
	})
	const [hovered, setHovered] = React.useState(false)

	return (
		<span
			className={clsx("flex items-center rounded", isDragging ? "z-10 shadow" : "z-0")}
			onMouseOver={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			style={{
				transform: !transform
					? undefined
					: `translate(${Math.ceil(transform.x)}px,${Math.ceil(transform.y)}px)`,
				transition,
			}}
		>
			<li
				className={clsx(
					"py-1 flex items-center gap-1 bg-gray-300 pl-2",
					inactive && hovered && !isDragging ? "rounded-l pr-1" : "rounded pr-5",
				)}
				ref={setNodeRef}
				{...listeners}
				{...attributes}
			>
				<Icon name="GripVertical" className="text-xs text-gray-500" />
				<p>{value}</p>
			</li>
			{inactive && hovered && (
				<button
					type="button"
					className="bg-red-500 h-full w-4 rounded-r hover:bg-red-600 text-gray-200"
					onClick={() => onTrash()}
				>
					<Icon name="Trash" className="text-xs" />
				</button>
			)}
		</span>
	)
}
