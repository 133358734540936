import React from "react"
import { Icon } from "@app/components"

export const BreadcrumbDelimiter: React.FC = () => {
	return (
		<span className="px-2">
			<Icon name="ChevronRight" size="xs" />
		</span>
	)
}
