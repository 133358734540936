import React, { ReactNode } from "react"
import _ from "lodash"

import { Await, Button, ContactListing, PageContainer, Title, EntityTable } from "@app/components"
import { useSession } from "@app/contexts"
import { Entity, EntityType, AlertLevel } from "@app/domain"
import { pluralize } from "@app/util"
import { useEntity, useEntityHeader, useEntityDetails, useRedirect } from "@app/hooks"
import { fromUUID, urlTo } from "@app/util"
import { GenerateReleaseFromJob, GenerateBillOfLadingFromShipment } from "@app/api"
import { EntityProvider } from "@app/contexts"

export const RelationListPage = (parentType: EntityType, childType: EntityType): React.FC => {
	let view = pluralize(childType)
	if (childType === EntityType.EventLog) {
		view = "log"
	}

	const RelationListing = () => {
		const { t, handleError } = useSession()

		const [entity, entityLoading] = useEntity<Entity>(parentType)
		const header = useEntityHeader(parentType, view, entity)
		const [parentID, parentName] = useEntityDetails(parentType, entity)
		const { redirect, setRedirect } = useRedirect()
		const [busy, setBusy] = React.useState<boolean>(false)

		const newParams: { [key: string]: string } = {}
		if (parentType && parentID) {
			switch (parentType) {
				case EntityType.Assembly:
					newParams.assembly = fromUUID(parentID)
					break
				case EntityType.Contact:
					newParams.contact = fromUUID(parentID)
					break
				case EntityType.Customer:
					newParams.customer = fromUUID(parentID)
					break
				case EntityType.Job:
					newParams.job = fromUUID(parentID)
					break
				case EntityType.Material:
					newParams.material = fromUUID(parentID)
					break
				case EntityType.WorkCell:
					newParams.workCell = fromUUID(parentID)
					break
				case EntityType.WorkFacility:
					newParams.workFacility = fromUUID(parentID)
					break
				case EntityType.Release:
					newParams.release = fromUUID(parentID)
					break
			}
		}

		const showNewButton = !(
			(parentType === EntityType.Customer && childType === EntityType.Release) ||
			(parentType === EntityType.Customer && childType === EntityType.Tag) ||
			(parentType === EntityType.Customer && childType === EntityType.Shipment) ||
			(parentType !== EntityType.Assembly && childType === EntityType.WorkCellProgram) ||
			childType === EntityType.File ||
			childType === EntityType.EventLog ||
			childType === EntityType.Tag ||
			childType === EntityType.Document ||
			childType === EntityType.Release ||
			childType === EntityType.ReleaseUnit ||
			childType === EntityType.WorkCellRunReport
		)

		const downloadable = childType === EntityType.WorkCellRunReport
		const unclickable = childType === EntityType.WorkCellRunReport
		const refreshable = childType === EntityType.WorkCellRunReport
		const unsearchable = childType === EntityType.WorkCellRunReport
		const autoUpdateMinutes = childType === EntityType.WorkCellRunReport ? 5 : undefined

		if (redirect) {
			return redirect()
		}

		let selectable = false
		if (parentType === EntityType.Release && childType === EntityType.ReleaseUnit) {
			selectable = true
		}

		let parentEntityType = parentType
		if (
			childType === EntityType.Document &&
			![EntityType.Customer, EntityType.Job].includes(parentType)
		) {
			parentEntityType = EntityType.Documentable
		}
		return (
			<PageContainer>
				<EntityProvider entity={entity}>
					{header}
					<Title>{`${parentName} › ${t(`common.tabs.${view}`)}`}</Title>
					<Await
						loading={entityLoading}
						then={() => {
							const buttons: ReactNode[] = []
							switch (childType) {
								case EntityType.Assembly:
									return (
										<EntityTable
											entityType={childType}
											parentEntityType={parentEntityType}
											parentEntityID={parentID}
											buttons={
												showNewButton ? (
													<>
														<Button
															add={true}
															label={"New W1-Cage"}
															to={urlTo("assemblies/w1-cage/new", undefined, newParams)}
														/>
														<Button
															add={true}
															label={"New W2-Grid"}
															to={urlTo("assemblies/w2-grid/new", undefined, newParams)}
														/>
													</>
												) : undefined
											}
										/>
									)
								case EntityType.Contact:
									return <ContactListing parentType={parentType} parentID={parentID} />
								case EntityType.Document:
									if (childType === EntityType.Document && parentType === EntityType.Shipment) {
										buttons.push(
											<Button
												key={"generate-BOL"}
												disabled={busy}
												create={true}
												label={t("document.buttons.types.billOfLading")}
												onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
													evt.preventDefault()
													if (busy) {
														return false
													}
													setBusy(true)
													const generate = async () => {
														try {
															const resp = await GenerateBillOfLadingFromShipment({
																shipmentID: parentID || "",
															})
															if (resp.ok) {
																const document = _.get(resp, "result.documents[0]")
																setRedirect(urlTo("documents/edit", document), {
																	alertLevel: AlertLevel.Success,
																	title: t("document.notifications.success.created", {
																		name: document.name,
																	}),
																})
															}
														} catch (err) {
															handleError(err)
														}
													}
													generate()
													return false
												}}
											/>,
										)
									}
								// fallthrough
								case EntityType.Tag:
									if (childType === EntityType.Tag && parentType === EntityType.Release) {
										buttons.push(
											<Button
												key={"create-tags"}
												generate={true}
												className="mb-2 md:mb-0"
												label={t("tag.buttons.create")}
												to={urlTo(`releases/${fromUUID(parentID)}/tags/create`)}
											/>,
										)
									}
								// fallthrough
								case EntityType.ReleaseUnit:
									if (childType === EntityType.ReleaseUnit && parentType === EntityType.Release) {
										buttons.push(
											<Button
												key={"create-releaseUnits"}
												generate={true}
												className="mb-2 md:mb-0"
												label={t("releaseUnit.buttons.create")}
												to={urlTo(`releases/${fromUUID(parentID)}/releaseUnits/create`)}
											/>,
										)
									}
								// fallthrough
								case EntityType.Contactable:
								case EntityType.Customer:
								case EntityType.Job:
								case EntityType.ReleaseItem:
								case EntityType.Release:
									if (childType === EntityType.Release && parentType === EntityType.Job) {
										buttons.push(
											<Button
												key={"generate-release"}
												disabled={busy}
												create={true}
												label={t("release.buttons.create")}
												onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
													evt.preventDefault()
													if (busy) {
														return false
													}
													setBusy(true)
													const generate = async () => {
														try {
															const resp = await GenerateReleaseFromJob({ jobID: parentID })
															if (resp.ok) {
																const release = _.get(resp, "result.releases[0]")
																setRedirect(urlTo("releases/edit", release), {
																	alertLevel: AlertLevel.Success,
																	title: t("release.notifications.success.created", {
																		name: release.name,
																	}),
																})
															}
														} catch (err) {
															handleError(err)
														}
													}
													generate()
													return false
												}}
											/>,
										)
									}
								// fallthrough
								case EntityType.EventLog:
								case EntityType.File:
								case EntityType.Material:
								case EntityType.Setting:
								case EntityType.Shipment:
								case EntityType.ShipmentItem:
								case EntityType.User:
								case EntityType.WorkCell:
								case EntityType.WorkCellProgram:
								case EntityType.WorkCellServer:
								case EntityType.WorkCellRunReport:
								case EntityType.WorkDevice:
								case EntityType.WorkEvent:
								case EntityType.WorkFacility:
								case EntityType.WorkResource:
									if (showNewButton) {
										buttons.push(
											<Button
												key={`${parentType}-${childType}-new`}
												add={true}
												to={urlTo(
													`${_.snakeCase(pluralize(childType)).replaceAll("_", "-")}/new`,
													undefined,
													newParams,
												)}
												label={t(`${_.camelCase(childType)}.buttons.new`)}
											/>,
										)
									}
									return (
										<EntityTable
											selectable={selectable}
											entityType={childType}
											parentEntityType={parentEntityType}
											parentEntityID={parentID}
											buttons={buttons}
											unclickable={unclickable}
											downloadable={downloadable}
											refreshable={refreshable}
											unsearchable={unsearchable}
											autoUpdateMinutes={autoUpdateMinutes}
										/>
									)
								default:
									throw new Error("RelationListPage: unknown child type, " + childType)
							}
						}}
					/>
				</EntityProvider>
			</PageContainer>
		)
	}

	return RelationListing
}
