import React from "react"
import _ from "lodash"
import { useLocation } from "react-router-dom"

import { useSession } from "@app/contexts"
import { AlertLevel, newAssembly, AssemblyType, EntityType, Job } from "@app/domain"
import { AssemblyForm } from "@app/forms"
import { PageContainer, Title } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useRedirect, useRelation, useQuery } from "@app/hooks"
import { SaveAssembly, SaveAssemblyParams, newSaveAssemblyParams } from "@app/api"

export const AssemblyNewPage: React.FC = () => {
	const { pathname, state } = useLocation()
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const { job: jobID = "" } = useQuery()
	const view = pathname.split("/").indexOf("w2-grid") === -1 ? "w1-cage" : "w2-grid"
	const assemblyType = view === "w1-cage" ? AssemblyType.W1Cage : AssemblyType.W2Grid

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: SaveAssemblyParams, stpMaskFileName?: string) => {
		setBusy(true)
		try {
			const stpFileMask = params?.configuratorParams?.["W2-Grid"]?.mask_stp
			if (params?.configuratorParams?.["W2-Grid"]) {
				// @ts-expect-error -- All fields are optional in protobuf v3.
				delete params.configuratorParams["W2-Grid"].mask_stp
			}
			if (stpFileMask && stpFileMask.length > 0) {
				params.stpMask = btoa(new TextDecoder("utf8").decode(stpFileMask))
				params.stpMaskFileName = stpMaskFileName
			}
			const assemblyResp = await SaveAssembly({ ...params })
			if (!assemblyResp.ok) {
				setErrors(assemblyResp.errors)
			} else {
				const saved = assemblyResp.result.assemblies[0]
				setRedirect(urlTo("assemblies", saved), {
					alertLevel: AlertLevel.Success,
					title: t("assembly.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	const [job, loadingJob] = useRelation<Job>(EntityType.Job, jobID)

	if (!_.isEmpty(jobID) && loadingJob) {
		return null
	}

	const defaultParams =
		state?.["assembly"] ??
		newSaveAssemblyParams(
			newAssembly({
				jobID: toUUID(jobID),
				name: job?.name ? `${job.name} ${assemblyType}` : assemblyType,
				assemblyType,
				metric: _.get(job, "metric", false),
			}),
		)

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer padding={false} flex={true}>
			<Title>{t("assembly.titles.new")}</Title>
			<AssemblyForm
				assemblyParams={defaultParams}
				errors={errors}
				loading={busy}
				onSave={saveHandler}
				assemblyType={assemblyType}
			/>
		</PageContainer>
	)
}
