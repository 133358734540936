import React from "react"
import _ from "lodash"

import { Button, FormActions, Input, Toggle, Select } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkFacilityParams } from "@app/api"
import { AddressesForm, ContactablesForm } from "@app/forms"
import { AlertLevel } from "@app/domain"
import { useFormHelpers, useTimezones } from "@app/hooks"

interface IWorkFacilityFormProps {
	busy?: boolean
	defaultParams: SaveWorkFacilityParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkFacilityParams) => void
}

export const WorkFacilityForm: React.FC<IWorkFacilityFormProps> = (props) => {
	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { params, inputHandler, valueHandler, contactablesHandler, selectHandler } =
		useFormHelpers<SaveWorkFacilityParams>(defaultParams)
	const { timezoneOptions, defaultTimezoneOption } = useTimezones(_.get(params, "timezone", ""))

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workFacility.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.name}
						label={t("workFacility.labels.name")}
						hint={t("workFacility.hints.name")}
						name="name"
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						type="text"
					/>
					<Toggle
						label={t("common.labels.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
					<Select
						defaultValue={defaultTimezoneOption}
						disabled={disabled || busy}
						error={_.get(errors, "timezone")}
						isSearchable={true}
						label={t("account.labels.timezone")}
						name="timezone"
						onChange={selectHandler("timezone")}
						options={timezoneOptions}
					/>
				</div>
				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<AddressesForm
						busy={busy}
						defaultParams={_.get(params, "addresses", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="addresses"
						onChange={valueHandler("addresses")}
					/>
				</div>
				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<ContactablesForm
						busy={busy}
						defaultParams={_.get(params, "contactables", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="contactables"
						onChange={contactablesHandler}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workFacility.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
