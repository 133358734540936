import React from "react"
import _ from "lodash"
import QRCode from "react-qr-code"

import { TrackingCode, EncodedTrackingCode, encode, encodeEntity } from "@app/domain"

type TrackingQRCodeProps = {
	trackingCode: TrackingCode
	width?: number
	className?: string
}

// TrackingQRCode encodes the data that can be embedded in a production tracking
// QR code.
//
// Examples:
//
// JSON:
// {"a":[{"id":"ivC26Tu3yWH7BajkPQXmzy","t":"Worker","d":"Nelle Streich"}]}
//
// URL:
// https://os.toggle.industries/pt/eyJhIjpbeyJpZCI6Iml2QzI2VHUzeVdIN0JhamtQUVhtenkiLCJ0IjoiV29ya2VyIiwiZCI6Ik5lbGxlIFN0cmVpY2gifV19
export const TrackingQRCode: React.FC<TrackingQRCodeProps> = (props) => {
	const { trackingCode, width, className } = props
	const { actors, locations, action, subjects } = trackingCode

	const encoded: EncodedTrackingCode = {
		z: encodeEntity(action),

		a: encode(actors),
		l: encode(locations),
		s: encode(subjects),
	}
	const json = JSON.stringify(encoded)
	const url = `https://os.toggle.industries/pt/${_.trim(window.btoa(json), "=")}`
	return (
		<div className={className}>
			<a href={url}>
				<QRCode value={url} width={width} />
			</a>
		</div>
	)
}
