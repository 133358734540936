import React from "react"
import _ from "lodash"

import {
	EnumTypeSelect,
	WorkFacilitySelect,
	Button,
	Select,
	FormActions,
	Input,
	Toggle,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkCellParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IWorkCellFormProps {
	busy?: boolean
	defaultParams: SaveWorkCellParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkCellParams) => void
}

export const WorkCellForm: React.FC<IWorkCellFormProps> = (props) => {
	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { params, inputHandler, valueHandler, selectHandler } =
		useFormHelpers<SaveWorkCellParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workCell.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const workCellTypes = _.map(En.workCellTypes, (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultWorkCellTypeOption = undefined
	const workCellTypeOptions = workCellTypes.map((c) => {
		const opt = { value: c.code, label: c.name }
		if (_.get(params, "workCellType", "") === c.code) {
			defaultWorkCellTypeOption = opt
		}
		return opt
	})

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<WorkFacilitySelect
						defaultValue={[_.get(params, "workFacilityID", "")]}
						disabled={disabled || busy}
						error={_.get(errors, "workFacilityID")}
						label={t("workCell.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID")}
					/>
					<Select
						defaultValue={defaultWorkCellTypeOption}
						disabled={disabled || busy}
						isSearchable={true}
						error={_.get(errors, "workCellType")}
						label={t("workCell.labels.workCellType")}
						name="workCellType"
						onChange={selectHandler("workCellType")}
						options={workCellTypeOptions}
					/>
					<EnumTypeSelect
						enumTypeName="workCellCapabilityTypes"
						disabled={busy}
						error={_.get(errors, "capabilities")}
						label={t("workCell.labels.capabilities")}
						hint={t("workCell.hints.capabilities")}
						name="capabilities"
						onChange={selectHandler("capabilities", true)}
						defaultValue={params.capabilities}
						isMulti
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						defaultValue={params.name}
						label={t("workCell.labels.name")}
						name="name"
						hint={t("workCell.hints.name")}
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.description}
						hint={t("workCell.hints.description")}
						label={t("workCell.labels.description")}
						name="description"
						error={_.get(errors, "description")}
						onChange={inputHandler("description")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.logoURL}
						placeholder={t("workCell.placeholders.logoURL")}
						label={t("workCell.labels.logoURL")}
						name="logoURL"
						error={_.get(errors, "logoURL")}
						onChange={inputHandler("logoURL")}
						type="text"
					/>
					<Toggle
						label={t("workCell.labels.tagsDisabled")}
						defaultChecked={params.tagsDisabled}
						disabled={disabled || busy}
						error={_.get(errors, "tagsDisabled")}
						onChange={valueHandler("tagsDisabled")}
					/>
					<Toggle
						label={t("common.labels.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workCell.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
