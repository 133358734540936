import React from "react"
import _ from "lodash"

import { SaveDocument, SaveDocumentParams, newSaveDocumentParams } from "@app/api"
import { useSession } from "@app/contexts"
import { AlertLevel, Document, newDocument, DocumentType } from "@app/domain"
import { DocumentForm } from "@app/forms"
import {
	Button,
	PageContainer,
	Link,
	BreadcrumbDelimiter,
	Breadcrumbs,
	Title,
} from "@app/components"
import { urlTo } from "@app/util"
import { useRedirect, useQuery } from "@app/hooks"

export const DocumentNewPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const query = useQuery()

	let documentType = DocumentType.File
	switch (query.documentType) {
		case "BillOfLading":
			documentType = DocumentType.BillOfLading
			break
		case "File":
		default:
			documentType = DocumentType.File
	}

	const [document] = React.useState<Document>(() => {
		const doc = newDocument()
		doc.documentType = documentType
		return doc
	})
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveDocumentParams>(() =>
		newSaveDocumentParams(document),
	)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("document.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveDocument({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.documents[0]")
				setRedirect(urlTo("documents", saved), {
					alertLevel: AlertLevel.Success,
					title: t("document.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>
				{t("document.titles.new", {
					documentType: t(`document.types.${_.camelCase(params.documentType)}`),
				})}
			</Title>

			<Breadcrumbs>
				<Link to="/documents">{t("document.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("document.titles.new", {
					documentType: t(`document.types.${_.camelCase(params.documentType)}`),
				})}
			</Breadcrumbs>

			<div>
				<form onSubmit={submitHandler} noValidate>
					<DocumentForm
						defaultParams={newSaveDocumentParams(document)}
						disabled={busy}
						errors={errors}
						onChange={(next) => {
							setParams((prev) => _.assign({}, prev, next))
						}}
					/>
					<div className="max-w-md mt-6">
						<Button errors={errors} label={t("document.buttons.save")} busy={busy} type="submit" />
					</div>
				</form>
			</div>
		</PageContainer>
	)
}
