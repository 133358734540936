import React from "react"

import { Await, PageContainer, ReleaseUnitDetails } from "@app/components"
import { ReleaseUnitHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, ReleaseUnit } from "@app/domain"

export const ReleaseUnitShowPage: React.FC = () => {
	const [releaseUnit, releaseUnitLoading] = useEntity<ReleaseUnit>(EntityType.ReleaseUnit)

	return (
		<PageContainer>
			<ReleaseUnitHeader releaseUnit={releaseUnit} view="show" />
			<Await
				loading={releaseUnitLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<ReleaseUnitDetails releaseUnit={releaseUnit} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
