import React from "react"

import { Await, PageContainer } from "@app/components"
import { PathVisualizerForm } from "@app/forms"
import { WorkCellProgramHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkCellProgram } from "@app/domain"

export const WorkCellProgramPathVisualizerPage: React.FC = () => {
	const [workCellProgram, workCellProgramLoading] = useEntity<WorkCellProgram>(
		EntityType.WorkCellProgram,
	)

	return (
		<PageContainer>
			<WorkCellProgramHeader workCellProgram={workCellProgram} view="pathVisualizer" />
			<Await
				loading={workCellProgramLoading}
				then={() => {
					return (
						<div className="px-2 sm:px-0 flex">
							{workCellProgram?.workCellType !== undefined ? (
								<PathVisualizerForm
									programs={workCellProgram?.robotPrograms.map((rp) => rp?.csv ?? "") || []}
									workCellType={workCellProgram.workCellType}
								/>
							) : (
								<div>Error: WorkCellType is undefined</div>
							)}
						</div>
					)
				}}
			/>
		</PageContainer>
	)
}
