import React from "react"
import _, { delay } from "lodash"
import zstd from "zstandard-wasm"
import {
	TaskStatus,
	TaskIDResponse,
	TaskResponse,
	Layer,
	Lines,
	Labels,
	Spheres,
	Origins,
	RobotJointData,
	Message,
} from "@app/grpc/visualization"
import { Dependency, FormInput, FormResponse, InputType, Value } from "@app/grpc/form"

import {
	GLTFViewer,
	Icon,
	Input,
	InputLength,
	ListInput,
	Select,
	TextField,
	Toggle,
} from "@app/components"
import { useSession } from "@app/contexts"
import { AlertLevel, FileMaxSizeBytes } from "@app/domain"
import { useDebouncedEffect } from "@app/hooks"
import { MM_PER_IN, toPrecision, gltfDataURLPrefix } from "@app/util"

interface IUseConfiguratorFormProps<T1> {
	requestParams: T1
	setRequestParams: React.Dispatch<React.SetStateAction<T1>>
	prevRequestParams: T1
	setPrevRequestParams: React.Dispatch<React.SetStateAction<T1>>

	// eslint-disable-next-line no-empty-pattern
	getForm: ({}) => Promise<FormResponse>
	getHashes: (requestParams: T1) => Promise<TaskIDResponse>
	getLayers: (requestParams: { params: T1; layers: Layer[] }) => Promise<TaskIDResponse>
	getTask: (request: { id: string }) => Promise<TaskResponse>
	setViewCollisionObjects?: React.Dispatch<React.SetStateAction<boolean>>
	setConnectionEditing?: React.Dispatch<React.SetStateAction<boolean>>

	urdfURL?: string
	busy?: boolean
	useDefaultParams?: boolean
	isProgram?: boolean
	viewCollisionObjects?: boolean
	connectionEditing?: boolean
	viewURDF?: boolean
	simulate?: boolean
	metric?: boolean
	setWeight?: React.Dispatch<React.SetStateAction<number>>
}

interface IUseConfiguratorFormReturn {
	gltfViewer: JSX.Element
	onBlur: () => void
	renderInput: (
		input: FormInput,
		j: number,
		onChange?: (name: string, v: string | boolean | number | File) => void,
	) => JSX.Element | undefined

	form: FormResponse | undefined
	info: string
	initialized: boolean
	loading: boolean
	setViewUnits: React.Dispatch<React.SetStateAction<boolean>>
	viewUnits: boolean
	errors: { [key: string]: string[] }
	hiddenByDependency: (dependencies: Dependency[] | undefined, group?: boolean) => boolean
}

export const useConfiguratorForm = <T1,>(
	props: IUseConfiguratorFormProps<T1>,
): IUseConfiguratorFormReturn => {
	const {
		requestParams,
		setRequestParams,
		prevRequestParams,
		setPrevRequestParams,
		setViewCollisionObjects,
		getForm,
		getHashes,
		getLayers,
		getTask,
		urdfURL = "",
		busy = false,
		useDefaultParams = true,
		viewCollisionObjects = false,
		connectionEditing = false,
		viewURDF = false,
		simulate = false,
		metric = true,
		setWeight = () => null,
	} = props

	const { addNotification, handleError } = useSession()

	const [loading, setLoading] = React.useState(false)
	const [autoUpdate, setAutoUpdate] = React.useState(true)
	const [viewUnits, setViewUnits] = React.useState(true)

	const [form, setForm] = React.useState<FormResponse>()

	const [gltfs, setGLTFs] = React.useState({})
	const [layerHashes, setLayerHashes] = React.useState<{ [key in Layer]?: string }>(
		_.fromPairs(_.values(Layer).map((layer) => [layer, "0"])),
	)
	const [labels, setLabels] = React.useState<Labels[]>([])
	const [lines, setLines] = React.useState<Lines[]>([])
	const [info, setInfo] = React.useState("")
	const [spheres, setSpheres] = React.useState<Spheres[]>([])
	const [origins, setOrigins] = React.useState<Origins[]>([])
	const [collisions, setCollisions] = React.useState<number[]>([])
	const [robotJointData, setRobotJointData] = React.useState<RobotJointData[]>([])
	const [warnings, setWarnings] = React.useState<{ [key: string]: string[] }>({})
	const [errors, setErrors] = React.useState<{ [key: string]: string[] }>({})
	const [modifiedParams, setModifiedParams] = React.useState<{ [key: string]: string }>({})
	const [formError, setFormError] = React.useState(false)
	const [warningsMuted, setWarningsMuted] = React.useState(false)
	const [allWarningsMuted, setAllWarningsMuted] = React.useState(false)

	const handleKeyPress = React.useCallback(
		(e: { shiftKey: boolean; ctrlKey: boolean; key: string }) => {
			if (e.shiftKey && e.ctrlKey && e.key === "H") {
				console.log("all warnings & errors muted")
				setAllWarningsMuted(true)
			} else if (e.ctrlKey && e.key === "h") {
				console.log("'request failed' warning muted")
				setWarningsMuted(true)
			}
		},
		[warningsMuted],
	)
	React.useEffect(() => {
		document.addEventListener("keydown", handleKeyPress)
		return () => {
			document.removeEventListener("keydown", handleKeyPress)
		}
	}, [])

	const messageMapper = (msgList: Message[] = []) => {
		const messageMap = {}
		for (const { param, message } of msgList) {
			if (!(param in messageMap)) {
				messageMap[param] = []
			}
			messageMap[param].push(message)
		}
		return messageMap
	}

	const [taskID, setTaskID] = React.useState("")
	const [taskResp, setTaskResp] = React.useState({
		status: TaskStatus.TaskStatusUnknown,
	} as TaskResponse)
	React.useEffect(() => {
		let intervalID: number
		let failureCount = 0
		let errorCount = 0
		const check = async () => {
			try {
				const taskResp = await getTask({ id: taskID })
				if (TaskStatus.TaskStatusSuccess === taskResp.status) {
					setTaskResp(taskResp)
					return
				}
				if (TaskStatus.TaskStatusFailure === taskResp.status) {
					failureCount += 1
					if (failureCount > 15) {
						setTaskResp(taskResp)
						return
					}
				}
				errorCount = 0
			} catch (error) {
				errorCount += 1
				if (errorCount > 5 && !allWarningsMuted) {
					addNotification({
						alertLevel: AlertLevel.Warn,
						title: "Configurator Unresponsive",
						subtitle: `${errorCount} consecutive server requests have failed.`,
					})
					return
				}
			}
			schedule()
		}
		const schedule = () => {
			intervalID = delay(check, Math.min(2 ** errorCount, 10) * 1000)
		}
		schedule()
		return () => {
			clearTimeout(intervalID)
		}
	}, [taskID])

	const [toFetchLayers, setToFetchLayers] = React.useState([] as Layer[])
	React.useEffect(() => {
		const handler = async () => {
			if (taskResp.status === TaskStatus.TaskStatusFailure && !warningsMuted && !allWarningsMuted) {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: "Configurator Request Failed",
				})
				setLoading(false)
			} else if (taskResp.hashes) {
				const hashResp = taskResp.hashes
				setWarnings(messageMapper(hashResp.messages?.warnings))
				setErrors(messageMapper(hashResp.messages?.errors))
				if (!allWarningsMuted) {
					hashResp.messages?.warnings.forEach(({ param, message }) => {
						addNotification({
							alertLevel: AlertLevel.Warn,
							title: param,
							subtitle: message,
						})
					})
					hashResp.messages?.errors.forEach(({ param, message }) => {
						addNotification({
							alertLevel: AlertLevel.Danger,
							title: param,
							subtitle: message,
						})
					})
				}
				if (!_.isEmpty(hashResp.messages?.errors)) {
					setLoading(false)
					setInitialized(true)
					return
				}
				if (Object.keys(hashResp.modified_params).length > 0) {
					const modified_params = Object.fromEntries(
						Object.entries(hashResp.modified_params).map(([key, val]) => [key, getValue(val)]),
					)
					if (setViewCollisionObjects && "assembly-view-collision-objects" in modified_params) {
						setViewCollisionObjects(true)
					}
					setRequestParams((prev) => ({
						...prev,
						...modified_params,
					}))
					setModifiedParams(modified_params)
				} else {
					setModifiedParams({})
				}
				const oldHashes = _.cloneDeep(layerHashes)
				setLayerHashes(hashResp.hashes)
				const layers: Layer[] = []
				for (const [key, hash] of _.entries(hashResp.hashes)) {
					const layer = Number(key)
					if (!(layer in oldHashes) || oldHashes[layer] != hash) {
						layers.push(layer)
					}
				}
				setToFetchLayers(layers)
				const layerTaskIDResp = await getLayers({
					params: requestParams,
					layers: layers,
				})
				setTaskID(layerTaskIDResp.id)
			} else if (taskResp.layers) {
				const layerResp = taskResp.layers
				const decoder = new TextDecoder()
				setGLTFs((prev) => ({
					...prev,
					..._.fromPairs(
						_.entries(layerResp.gltfs).map(([k, v]) => [k, decoder.decode(zstd.decompress(v))]),
					),
				}))
				if (toFetchLayers.includes(Layer.LayerLabels)) {
					setLabels(layerResp.labels)
				}
				if (toFetchLayers.includes(Layer.LayerLines)) {
					setLines(layerResp.lines)
				}
				if (toFetchLayers.includes(Layer.LayerInfo)) {
					setInfo(layerResp.info)
				}
				if (toFetchLayers.includes(Layer.LayerSpheres)) {
					setSpheres(layerResp.spheres)
				}
				if (toFetchLayers.includes(Layer.LayerOrigins)) {
					setOrigins(layerResp.origins)
				}
				if (toFetchLayers.includes(Layer.LayerCollision)) {
					setCollisions(layerResp.collisions)
				}
				if (toFetchLayers.includes(Layer.LayerRobotJointData)) {
					setRobotJointData(layerResp.robot_joint_data)
				}
				if (setWeight) {
					setWeight((prevWeight) => layerResp?.weight ?? prevWeight)
				}
				setPrevRequestParams(requestParams)
				setLoading(false)
				setInitialized(true)
			} else if (
				[TaskStatus.TaskStatusFailure, TaskStatus.TaskStatusSuccess].includes(taskResp.status)
			) {
				setLoading(false)
				handleError(new Error("Task response undefined"))
			}
		}
		try {
			handler()
		} catch (error) {
			handleError(error)
		}
	}, [taskResp])

	const [requestCounter, setRequestCounter] = React.useState(0)
	const [forceUpdate, setForceUpdate] = React.useState(false)
	React.useEffect(() => {
		if (
			!forceUpdate &&
			(formError ||
				JSON.stringify(requestParams) === JSON.stringify(prevRequestParams) ||
				!autoUpdate ||
				busy)
		) {
			setForceUpdate(false)
			if (
				JSON.stringify(requestParams) === JSON.stringify(prevRequestParams) &&
				_.entries(errors).length > 0
			) {
				setErrors({})
			}
			return
		}
		setForceUpdate(false)
		const requestHashTaskID = async () => {
			setLoading(true)
			try {
				const hashTaskIDResp = await getHashes(requestParams)
				setTaskID(hashTaskIDResp.id)
			} catch (error) {
				setLoading(false)
				handleError(error)
			}
		}
		requestHashTaskID()
	}, [requestCounter])

	const [initialized, setInitialized] = React.useState(false)
	const [onBlurCounter, setOnBlurCounter] = React.useState(0)
	useDebouncedEffect(() => setRequestCounter((r) => r + 1), !initialized ? 0 : 750, [onBlurCounter])
	const onBlur = () => {
		setOnBlurCounter((prev) => prev + 1)
	}
	React.useEffect(() => {
		onBlur()
	}, [metric])

	React.useEffect(() => {
		const loadWASM = async () => {
			await zstd.loadWASM()
		}
		// Request form on load.
		const requestForm = async () => {
			setForm(await getForm({}))
		}
		loadWASM()
		requestForm()
	}, [])

	const getValue = (v?: Value) => {
		const rawVal = Object.values(v ?? {}).find((e) => e !== undefined) ?? "error"
		return typeof rawVal === "object" && !(rawVal instanceof Uint8Array)
			? rawVal.values.map((e: Value) => getValue(e))
			: rawVal
	}

	React.useEffect(() => {
		if (!form) {
			return
		}
		if (!useDefaultParams) {
			setForceUpdate(true)
			setRequestCounter((r) => r + 1)
		} else {
			// Load form defaults into requestParams.
			setRequestParams((params) => ({
				...params,
				...form.groups
					.map((group) =>
						group.inputs.reduce(
							(acc, input) => ({
								...acc,
								[input.name]: getValue(input.default),
							}),
							{},
						),
					)
					.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
			}))
			onBlur()
		}
	}, [form])

	const hiddenByDependency = (dependencies: Dependency[] | undefined, group?: boolean) => {
		if (!dependencies) {
			return false
		}
		if (group === undefined) {
			group = false
		}
		const Uint8ArraysEqual = (a: Uint8Array, b: Uint8Array) =>
			a.length === b.length && a.every((value, index) => value === b[index])
		const valuesEqual = (a: Value, b: Value) =>
			ArrayBuffer.isView(a) ? Uint8ArraysEqual(a as Uint8Array, b as Uint8Array) : a === b
		const disabledFn = (dep: Dependency) =>
			dep.values_enable
				? true
				: dep.values.every((val) => !valuesEqual(getValue(val), requestParams[dep.param]))
		const disabledByDep =
			dependencies.length ?? 0 > 0
				? group
					? !dependencies.some((dep) => disabledFn(dep))
					: !dependencies.some((dep) => disabledFn(dep))
				: false
		const enabledFn = (dep: Dependency) =>
			dep.values_enable
				? dep.values.some((val) => valuesEqual(getValue(val), requestParams[dep.param]))
				: true
		const enabledByDep =
			dependencies.length ?? 0 > 0
				? group
					? dependencies.every(enabledFn)
					: dependencies.some(enabledFn)
				: true
		return !enabledByDep || disabledByDep
	}

	const renderInput = (
		input: FormInput,
		j: number,
		onChange: (name: string, v: string | boolean | number | File) => void = () => null,
	) => {
		if (hiddenByDependency(input.options?.dependencies)) {
			return <React.Fragment key={j} />
		}
		const disabledByDependency = hiddenByDependency(input.options?.disable_dependencies)
		if (input.options?.list) {
			return (
				<ListInput
					key={j}
					error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
					type={input.type}
					metric={metric}
					disabled={input.options?.disabled || busy || loading || disabledByDependency}
					label={input.label}
					alt={input.options?.alt}
					value={modifiedParams[input.name] ?? ""}
					defaultValue={(requestParams[input.name] ?? "").toString() ?? ""}
					range={[input.options?.min ?? -Infinity, input.options?.max ?? Infinity]}
					onChange={(v: string) => {
						onChange(input.name, v)
						setRequestParams((params) => ({
							...params,
							[input.name]:
								input.type == InputType.STRING || input.type == InputType.QUANTITY_AT_SPACING
									? v.split(",")
									: input.type == InputType.BOOL
									? v.split(",").map((e) => e.toLowerCase() === "true")
									: v.split(",").map((e) => Number(e)),
						}))
					}}
					onBlur={onBlur}
					onError={(e: string) => setFormError(Boolean(e))}
				/>
			)
		}
		if ((input.options?.select_options ?? []).length > 0) {
			return (
				<Select
					key={j}
					label={input.label}
					name={input.name}
					alt={input.options?.alt}
					isSearchable={true}
					error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
					value={{
						label:
							(input.options?.select_options ?? []).find(
								({ value }) => getValue(value) === requestParams[input.name],
							)?.label ?? requestParams[input.name],
						value: requestParams[input.name],
					}}
					options={(input.options?.select_options ?? []).map(({ label, value }) => ({
						label,
						value: getValue(value),
					}))}
					disabled={input.options?.disabled || busy || loading || disabledByDependency}
					fieldInfo={`assembly-${input.name}`}
					onChange={(v) => {
						const value = v?.[0]?.["value"]
						onChange(input.name, value)
						setRequestParams((params) => ({
							...params,
							[input.name]: value,
						}))
						onBlur()
					}}
				/>
			)
		}
		switch (input.type) {
			case InputType.LENGTH:
				return (
					<InputLength
						key={j}
						error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
						value={requestParams[input.name]}
						disabled={input.options?.disabled || busy || loading || disabledByDependency}
						fieldInfo={`assembly-${input.name}`}
						label={input.label}
						metric={metric}
						name={input.name}
						alt={input.options?.alt}
						onChange={(v: number | undefined) => {
							if (_.isNumber(v)) {
								const value = toPrecision(metric ? v : v * MM_PER_IN, 3)
								onChange(input.name, value)
								setRequestParams((params) => ({
									...params,
									[input.name]: value,
								}))
							}
						}}
						range={[
							[
								Math.round((input.options?.min ?? -Infinity) / MM_PER_IN),
								Math.round((input.options?.max ?? Infinity) / MM_PER_IN),
							],
							[input.options?.min ?? -Infinity, input.options?.max ?? Infinity],
						]}
						onBlur={onBlur}
					/>
				)
			case InputType.STRING:
				return input.options?.textarea ? (
					<TextField
						key={j}
						error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
						disabled={input.options?.disabled || busy || loading || disabledByDependency}
						value={requestParams[input.name]}
						fieldInfo={`assembly-${input.name}`}
						hint={input.description}
						label={input.label}
						name={input.name}
						alt={input.options?.alt}
						onChange={(e) => {
							onChange(input.name, e.target["value"])
							setRequestParams((params) => ({
								...params,
								[input.name]: e.target["value"],
							}))
						}}
						onBlur={onBlur}
					/>
				) : (
					<Input
						key={j}
						error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
						disabled={input.options?.disabled || busy || loading || disabledByDependency}
						value={requestParams[input.name]}
						fieldInfo={`assembly-${input.name}`}
						hint={input.description}
						label={input.label}
						name={input.name}
						alt={input.options?.alt}
						type="text"
						onChange={(e) => {
							onChange(input.name, e.target["value"])
							setRequestParams((params) => ({
								...params,
								[input.name]: e.target["value"],
							}))
						}}
						onBlur={onBlur}
					/>
				)
			case InputType.INTEGER:
			case InputType.DOUBLE:
				return (
					<Input
						key={j}
						error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
						disabled={input.options?.disabled || busy || loading || disabledByDependency}
						value={requestParams[input.name]}
						fieldInfo={`assembly-${input.name}`}
						hint={input.description}
						label={input.label}
						name={input.name}
						alt={input.options?.alt}
						type="number"
						required={!input.options?.optional}
						placeholder={input.placeholder}
						onChange={(e) => {
							onChange(input.name, e.target["value"])
							setRequestParams((params) => ({
								...params,
								[input.name]: Number(e.target["value"]) || 0,
							}))
						}}
						onKeyDown={(e) => {
							if (e.key === "." && input.type === InputType.INTEGER) {
								e.preventDefault()
								e.target["value"] = e.target["value"].replace(/\./g, "")
							}
						}}
						onBlur={onBlur}
					/>
				)
			case InputType.BOOL:
				return (
					<Toggle
						key={j}
						error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
						value={requestParams[input.name]}
						disabled={input.options?.disabled || busy || loading || disabledByDependency}
						fieldInfo={`assembly-${input.name}`}
						label={input.label}
						alt={input.options?.alt}
						onChange={async (checked) => {
							onChange(input.name, checked)
							setRequestParams((params) => ({
								...params,
								[input.name]: checked,
							}))
							onBlur()
						}}
					/>
				)
			case InputType.FILE:
				return (
					<Input
						key={j}
						error={errors?.[input.name]?.[0] ?? warnings?.[input.name]?.[0]}
						disabled={input.options?.disabled || busy || loading || disabledByDependency}
						fieldInfo={`assembly-${input.name}`}
						id={`assembly-${input.name}`}
						hint={input.description}
						label={input.label}
						name={input.name}
						alt={input.options?.alt}
						type="file"
						accept={input.options?.file_types}
						onChange={async (e) => {
							const getFileBytes = (file: File): Promise<ArrayBuffer> => {
								return new Promise((acc, err) => {
									const reader = new FileReader()
									reader.onload = (event) => {
										acc(event?.target?.result as ArrayBuffer)
									}
									reader.onerror = (error) => {
										err(error)
									}
									reader.readAsArrayBuffer(file)
								})
							}
							const fileBytes = await getFileBytes(e.target["files"][0])
							if (fileBytes.byteLength > FileMaxSizeBytes) {
								setErrors((errors) => ({
									...errors,
									[input.name]: [`File size must be less than ${FileMaxSizeBytes / 1024 ** 2}MB`],
								}))
								return
							}
							onChange(input.name, e.target["files"][0])
							setRequestParams((params) => ({
								...params,
								[input.name]: new Uint8Array(fileBytes),
							}))
							onBlur()
						}}
					/>
				)
		}
	}

	const mdWidth = 768 // from tailwindcss
	const mobile = window.innerWidth < mdWidth
	const gltfViewer = (
		<>
			<div className="absolute flex top-2 left-2 z-10">
				<div className="flex-col space-y-2">
					<div className="flex items-center gap-x-2">
						<button
							onClick={() => {
								setForceUpdate(true)
								setRequestCounter((r) => r + 1)
							}}
							className="px-1.5 py-1 bg-yellow text-black hover:bg-yellow-300 disabled:bg-gray-300 disabled:text-gray-600"
							disabled={loading}
						>
							<Icon spin={loading} name="Sync" size="lg" />
						</button>
						<input
							className="px-2 text-black disabled:bg-gray-300 disabled:text-gray-600"
							type="checkbox"
							name="auto-update"
							checked={autoUpdate}
							onChange={() => setAutoUpdate((prev) => !prev)}
						/>
						<label className="m-0">Auto-Update</label>
					</div>
				</div>
			</div>
			{_.values(gltfs).every((gltf) => !gltf) || !_.isEmpty(errors) ? (
				<div className="flex md:flex-col h-full justify-center items-center">
					{_.entries(errors).map(([param, errorList]) =>
						errorList.map((error: string, j) => (
							<p key={j} className="mt-4 w-1/3 mx-auto text-center">
								{`Error: ${param ? param + ": " : ""}${error}`}
							</p>
						)),
					)}
					{_.entries(warnings).map(([param, warningList]) =>
						warningList.map((warning: string, j) => (
							<p key={j} className="mt-4 w-1/3 mx-auto text-center">
								{`Warning: ${param ? param + ": " : ""}${warning}`}
							</p>
						)),
					)}
				</div>
			) : (
				<GLTFViewer<T1>
					modelURLs={_.entries(gltfs)
						.filter(([, gltf]) => gltf)
						.filter(
							([layer]) => viewCollisionObjects || layer !== String(Layer.LayerAnimationBoundaries),
						)
						.map(([, gltf]) => `${gltfDataURLPrefix}${gltf}`)}
					labels={labels}
					lines={lines}
					spheres={spheres}
					collisions={collisions}
					robotJointData={robotJointData}
					onError={() => {
						setErrors((prev) => ({ ...prev, "": [...(prev[""] ?? []), "Loading error."] }))
					}}
					metric={metric}
					viewCube={!mobile}
					viewUnits={viewUnits}
					origins={origins}
					zUp
					simulate={simulate}
					urdfURL={urdfURL}
					viewCollisionObjects={viewCollisionObjects}
					viewURDF={viewURDF}
					connectionEditing={connectionEditing}
					setRequestParams={setRequestParams}
				/>
			)}
		</>
	)

	return {
		gltfViewer,
		form,
		initialized,
		renderInput,
		viewUnits,
		loading,
		setViewUnits,
		info,
		onBlur,
		errors,
		hiddenByDependency,
	}
}
