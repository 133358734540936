import React from "react"

interface IBadgeProps {
	quantity?: boolean
	label?: string
	className?: string
	color?: string
	children: React.ReactNode
}

export const Badge: React.FC<IBadgeProps> = (props) => {
	const { quantity, label, children, color } = props
	let { className = "" } = props

	let labelColor = "text-gray-500"
	let textColor = "text-gray-300"
	let bgColor = "bg-gray-700"
	let size = "text-xs"

	if (quantity) {
		size = "text-sm"
		textColor = "text-gray-700"
		bgColor = "bg-gray-400"
	}

	if (color === "blue") {
		labelColor = "text-blue-600"
		textColor = "text-white"
		bgColor = "bg-blue-600"
	} else if (color === "red") {
		labelColor = "text-red-200"
		textColor = "text-white"
		bgColor = "bg-red-600"
	} else if (color) {
		bgColor = color
		textColor = ""
	}

	className = `inline-flex items-center px-2.5 py-0.5 rounded-full font-medium leading-5 ${className} ${textColor} ${bgColor} ${size}`

	if (label) {
		return (
			<span className={className}>
				<span className={`${labelColor} mr-1`}>{label}</span>
				{children}
			</span>
		)
	}
	return <span className={className}>{children}</span>
}
