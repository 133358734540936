import React from "react"
import _ from "lodash"

import {
	SaveWorkCellProgram,
	SaveWorkCellProgramParams,
	newSaveWorkCellProgramParams,
} from "@app/api"
import { useSession } from "@app/contexts"
import { newWorkCellProgram, EntityType, Assembly, AlertLevel } from "@app/domain"
import { WorkCellProgramForm } from "@app/forms"
import { PageContainer, Link, BreadcrumbDelimiter, Breadcrumbs, Title } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useRedirect, useQuery, useRelation } from "@app/hooks"

export const WorkCellProgramNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const { assembly: assemblyID = "" } = useQuery()
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [assembly, assemblyLoading] = useRelation<Assembly>(EntityType.Assembly, assemblyID)

	const saveHandler = async (params: SaveWorkCellProgramParams) => {
		setBusy(true)
		try {
			const resp = await SaveWorkCellProgram({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workCellPrograms[0]")
				setRedirect(urlTo("workCellPrograms", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workCellProgram.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	if (assemblyLoading) {
		return null
	}

	const defaultParams = newSaveWorkCellProgramParams(
		newWorkCellProgram({
			name: _.get(assembly, "name", ""),
			assemblyID: toUUID(assemblyID),
		}),
	)

	return (
		<PageContainer>
			<Title>{t("workCellProgram.titles.new")}</Title>

			<Breadcrumbs>
				<Link to={urlTo("workCellPrograms")}>{t("workCellProgram.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<WorkCellProgramForm
					defaultParams={defaultParams}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
