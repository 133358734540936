import { rpc, Cache, compactIDs } from "@app/api"

import type { Result } from "@app/api"
import type { EventLog, Pagination } from "@app/domain"

export type GetEventLogsParams = {
	ids?: string[]

	entityIDs?: string[]
	entityType?: string

	query?: string
	withoutCount?: boolean
	withoutContactables?: boolean

	limit?: number
	offset?: number
}

export type GetEventLogsResult = {
	eventLogs: EventLog[]
	pagination: Pagination
}

export const GetEventLogs = async (
	params: GetEventLogsParams,
): Promise<Result<GetEventLogsResult>> => {
	const data = await rpc("GetEventLogs", {
		...params,
		ids: compactIDs(params.ids),
	})
	Cache.scan(data)
	return data as Result<GetEventLogsResult>
}
