import _ from "lodash"
import { v4 } from "uuid"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkEvent, Entity, Pagination } from "@app/domain"

export type SaveWorkEventParams = {
	id: string
	description?: string
	disabled: boolean
	name?: string
	canceled: boolean
	workCellID?: string
	workFacilityID?: string
	workCellProgramID?: string
	workEventType?: string
}

export const newSaveWorkEventParams = (opts?: WorkEvent): SaveWorkEventParams => {
	return _.assign(
		{
			id: v4(),
			description: "",
			disabled: false,
			name: "",
			canceled: false,
			workCellID: "",
			workFacilityID: "",
			workCellProgramID: "",
			workEventType: "deployWorkCellProgram",
		},
		opts,
	)
}

export type SaveWorkEventResult = {
	workEvents: WorkEvent[]
}

export const SaveWorkEvent = async (
	params: SaveWorkEventParams,
): Promise<Result<SaveWorkEventResult>> => {
	const data = await rpc("SaveWorkEvent", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkEventResult>(data)
}

export type GetWorkEventsParams = {
	ids?: string[]
	includeIDs?: string[]
	workCellID?: string
	workFacilityID?: string
	workCellProgramID?: string
	workResourceID?: string

	workEventType?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkEventsResult = {
	workEvents: WorkEvent[]
	pagination?: Pagination
}

export const GetWorkEvents = async (
	params: GetWorkEventsParams,
): Promise<Result<GetWorkEventsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkEvents", "workEvents", id).then((workEvent?: Entity) => {
			return {
				ok: true,
				result: { workEvents: _.compact([workEvent as WorkEvent]) },
			} as Result<GetWorkEventsResult>
		}) as Promise<Result<GetWorkEventsResult>>
	}
	const data = await rpc("GetWorkEvents", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkEventsResult>(data)
}
