import React from "react"

import { SaveMaterial, SaveMaterialParams, newSaveMaterialParams } from "@app/api"
import { useSession } from "@app/contexts"
import { newMaterial, AlertLevel } from "@app/domain"
import { MaterialForm } from "@app/forms"
import { Link, BreadcrumbDelimiter, Breadcrumbs, Title, PageContainer } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useRedirect, useQuery } from "@app/hooks"

export const MaterialNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const { job = "" } = useQuery()

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: SaveMaterialParams) => {
		setBusy(true)
		try {
			const resp = await SaveMaterial({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.materials?.[0]
				setRedirect(urlTo("materials", saved), {
					alertLevel: AlertLevel.Success,
					title: t("material.notifications.success.created", { controlCode: saved?.controlCode }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("material.titles.new")}</Title>

			<Breadcrumbs>
				<Link to="/materials">{t("material.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<MaterialForm
					defaultParams={newSaveMaterialParams(newMaterial({ jobID: toUUID(job) }))}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
