import React from "react"
import _ from "lodash"

import { ScanEvent } from "@app/domain"
import { GetTags } from "@app/api"
import { Badge, Icon, InputSearch, TagScanner } from "@app/components"
import { useSession, useModals } from "@app/contexts"

import type { Tag } from "@app/domain"

interface ITagSearchFormProps {
	addTag: (tag: Tag, item: number) => void
	busy?: boolean
	disabled?: boolean
	jobID?: string
	scanEntityMap?: { [key: string]: string }
	scanEvent: ScanEvent
}

export const TagSearchForm: React.FC<ITagSearchFormProps> = (props) => {
	const { scanEvent, scanEntityMap, addTag = _.noop, jobID, busy = false, disabled = false } = props

	const { t, handleError } = useSession()
	const { setModal, dismissModal } = useModals()

	const node = React.useRef<HTMLDivElement>(null)

	const [active, setActive] = React.useState<boolean>(false)
	const [highlightIndex, setHighlightIndex] = React.useState<number>(0)
	const [results, setResults] = React.useState<Tag[]>([])
	const [searching, setSearching] = React.useState<boolean>(false)

	const handleClickOutside = (e: MouseEvent) => {
		if (node?.current?.contains(e.target as Node)) {
			return
		}
		setActive(false)
	}

	React.useEffect(() => {
		if (active) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [active])

	const highlighted = Math.max(0, Math.min(highlightIndex, _.size(results)))

	const focusHandler = (): void => {
		setActive(true)
		setHighlightIndex(0)
	}

	const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

	const inputHandler = async (query: string) => {
		if (_.isEmpty(query)) {
			setResults([])
			return
		}
		const waitUntil = Date.now() + 300
		setSearching(true)
		try {
			const resp = await GetTags({ query, jobID, limit: 5, offset: 0 })
			if (resp.ok) {
				setResults(_.get(resp, "result.tags", []))
			}
		} catch (err) {
			handleError(err)
		}
		const wait = waitUntil - Date.now()
		if (wait > 0) {
			await timeout(wait)
		}
		setSearching(false)
	}

	const renderedResults = results.map((v, i) => {
		let className = "cursor-pointer "
		if (i === highlighted) {
			className += " bg-yellow-300"
		}
		return (
			<li
				key={v.id}
				className={className}
				onMouseEnter={() => {
					setHighlightIndex(i)
				}}
				onClick={(e) => {
					e.preventDefault()
					addTag(v)
					setResults([])
					setActive(false)
					return false
				}}
			>
				<div className="py-1 px-1">
					<div className="px-2 py-2 sm:px-4">
						<div className="flex items-center justify-between">
							<div className="text-black font-medium truncate">{v.description}</div>
						</div>
						<div className="flex flex-wrap">
							<Badge className="mr-1 mb-1" label={t("common.labels.referenceID")}>
								{v.referenceID}
							</Badge>
							<Badge className="mr-1 mb-1" label={t("tag.labels.weight")}>
								{`${v.weight} ${t(`units.${v.metric ? "metric" : "imperial"}.weightAbbr`)}`}
							</Badge>
							<Badge className="mr-1 mb-1" label={t("tag.labels.quantity")}>
								{v.quantity}
							</Badge>
							<Badge className="mr-1 mb-1" label={t("tag.labels.color")}>
								{v.color}
							</Badge>
						</div>
					</div>
				</div>
				<div className="border-t border-gray-300"></div>
			</li>
		)
	})

	return (
		<>
			<div ref={node} className="relative w-full focus-within:text-gray-600">
				<div className="absolute text-gray-300 inset-y-0 left-2 flex items-center pointer-events-none">
					<Icon name={searching ? "Cog" : "Search"} size="1x" spin={searching} fixedWidth />
				</div>
				<InputSearch
					onScanClick={() => {
						setModal(
							"TagScanner",
							<TagScanner
								onSubmit={(refs) => {
									_.each(refs, (ref) => {
										if (ref.tag) {
											addTag(ref.tag, ref.item)
										}
									})
									dismissModal("TagScanner")
								}}
								submitLabel={t("tag.buttons.addItems")}
								onClose={() => {
									dismissModal("TagScanner")
								}}
								scanEvent={scanEvent}
								scanEntityMap={scanEntityMap}
							/>,
						)
					}}
					disabled={disabled || busy}
					busy={searching}
					placeholder={t("tag.placeholders.findExisting")}
					onKeyDown={(e) => {
						if (e.key === "ArrowDown") {
							e.preventDefault()
							setHighlightIndex(highlightIndex + 1)
						} else if (e.key === "ArrowUp") {
							e.preventDefault()
							setHighlightIndex(highlightIndex - 1)
						} else if (e.key === "Enter" && _.some(results)) {
							e.preventDefault()
							addTag(results[highlightIndex])
							setResults([])
							const target = e.target as HTMLInputElement
							target.value = ""
						} else if (e.key === "Escape") {
							e.preventDefault()
							setActive(false)
							setHighlightIndex(0)
							setResults([])
							const target = e.target as HTMLInputElement
							target.blur()
						}
					}}
					onFocus={focusHandler}
					onInputChange={inputHandler}
				/>

				{_.isEmpty(results) || !active ? null : (
					<div className="z-20 absolute left-0 top-10 mt-0 w-full max-w-7xl shadow-xl">
						<div
							className="bg-white"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="options-menu"
						>
							<div className="border-t border-gray-300"></div>
							<ul>{renderedResults}</ul>
						</div>
					</div>
				)}
			</div>
		</>
	)
}
