import _ from "lodash"
import * as Sentry from "@sentry/browser"
import type { Transaction } from "@sentry/types"

let currPath: string | undefined
export let tx: Transaction | undefined

window.onbeforeunload = () => {
	if (tx) {
		tx.finish()
	}
}

export const GetSentryTx = (): Transaction => {
	if (!tx) {
		const name = currPath || "<unknown>"
		tx = Sentry.startTransaction({ name, op: "view" })
		Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(tx))
	}
	return tx
}

export const startTx = (path: string | readonly string[] | undefined) => {
	const nextPath = _.isArray(path) ? _.first(path) || "" : path
	if (nextPath !== currPath) {
		if (tx) {
			tx.finish()
			tx = undefined
		}
		currPath = nextPath
		GetSentryTx()
	}
}
