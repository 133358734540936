import _ from "lodash"

export type WorkCellServer = {
	id: string
	workCellID: string
	workFacilityID: string

	configString: string
	description: string
	disabled: boolean
	ipAddress: string
	name: string
	secret: string

	created: string
	updated: string
}

export const newWorkCellServer = (opts?: {
	[key: string]: string | boolean | number
}): WorkCellServer => {
	return _.assign(
		{
			id: "",
			workCellID: "",
			workFacilityID: "",

			configString: "",
			description: "",
			disabled: false,
			ipAddress: "",
			name: "",
			secret: "",

			created: "",
			updated: "",
		},
		opts,
	)
}
