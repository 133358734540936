import React from "react"

import { Await, PageContainer, WorkerDetails } from "@app/components"
import { WorkerHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, Worker } from "@app/domain"

export const WorkerShowPage: React.FC = () => {
	const [worker, workerLoading] = useEntity<Worker>(EntityType.Worker)

	return (
		<PageContainer>
			<WorkerHeader worker={worker} view="show" />
			<Await
				loading={workerLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkerDetails worker={worker} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
