import React from "react"
import _ from "lodash"

import { AccountForm } from "@app/forms"
import { AlertLevel, UserSession } from "@app/domain"
import { PageContainer } from "@app/components"
import { AccountHeader, AccountHeaderView } from "@app/headers"
import { useSession } from "@app/contexts"
import { UpdateAccount, UpdateAccountParams, newUpdateAccountParams } from "@app/api"

export const AccountEditPage: React.FC = () => {
	const { t, user, setSession, handleError, addNotification } = useSession()

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: UpdateAccountParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await UpdateAccount({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				setSession((prev) => {
					return { ...(prev as UserSession), user: _.get(resp, "result.user") }
				})
				addNotification({
					alertLevel: AlertLevel.Success,
					title: t("account.notifications.success.saving"),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	return (
		<PageContainer>
			<AccountHeader view={AccountHeaderView.edit} />
			<div className="max-w-auto px-2">
				<AccountForm
					defaultParams={newUpdateAccountParams(user)}
					busy={busy}
					errors={errors}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
