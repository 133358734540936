import React from "react"

import { FormActions, Button, Icon, EntitySearchSelect } from "@app/components"
import { useSession } from "@app/contexts"
import { Worker, WorkerUtils } from "@app/domain"
import { GetWorkers, CreateWorkerBadgeParams } from "@app/api"
import clsx from "clsx"

interface IWorkerFormProps {
	busy?: boolean
	disabled?: boolean
	errors: { [key: string]: string }
	onSubmit: (params: CreateWorkerBadgeParams) => void
}

export const WorkerBadgeForm: React.FC<IWorkerFormProps> = (props) => {
	const { errors, onSubmit, disabled = false, busy = false } = props

	const { t } = useSession()

	const [selectedWorkers, setSelectedWorkers] = React.useState<Worker[]>([])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSubmit({ workerIDs: selectedWorkers.map((w) => w.id) })
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row gap-10">
				<EntitySearchSelect
					getResults={(query: string) => GetWorkers({ query, limit: 5 })}
					respKey="workers"
					getEntityName={(e: Worker) => WorkerUtils.name(e)}
					onSelect={(e: Worker) => {
						setSelectedWorkers((prev) => [...prev, e])
					}}
					selectedIDs={selectedWorkers.map((e) => e.id) ?? []}
					label="Search Workers"
					placeholder="Jane Smith"
					disabled={disabled}
					busy={busy}
				/>
				<div className="flex flex-col w-52">
					<h2 className="text-gray-700">Selected Workers</h2>
					<ul className="flex flex-col">
						{selectedWorkers.map((worker, i) => (
							<li
								key={i}
								className={clsx(
									"flex items-center justify-between px-2 py-1",
									i % 2 == 0 && "bg-gray-200",
								)}
							>
								<div className="flex-1">{WorkerUtils.name(worker)}</div>
								<button type="button" className="ml-4 text-gray-500 hover:text-gray-700 hover-item">
									<Icon
										name="Times"
										onClick={() => {
											setSelectedWorkers((prev) => prev.filter((w) => w.id !== worker.id))
										}}
									/>
								</button>
							</li>
						))}
					</ul>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workerBadge.buttons.download")}
					errors={errors}
					disabled={disabled || selectedWorkers.length === 0}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
