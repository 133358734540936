import React from "react"
import _ from "lodash"

import { Button, FormActions, ReleaseDetails } from "@app/components"
import { AlertLevel, ReleaseItem, ReleaseItemWorkCellDateQuantityMap } from "@app/domain"
import { useRedirect, useFormHelpers } from "@app/hooks"
import { useSession } from "@app/contexts"
import {
	CreateReleaseUnitsParams,
	CreateReleaseUnits,
	newCreateReleaseUnitsParams,
	GetTags,
} from "@app/api"
import { urlTo } from "@app/util"

import type { Release } from "@app/domain"

type ReleaseUnitCreateFormProps = {
	release: Release
	releaseItems: ReleaseItem[]
	section?: string
}

export const ReleaseUnitCreateForm: React.FC<ReleaseUnitCreateFormProps> = (props) => {
	const { t, handleError } = useSession()
	const { release, releaseItems } = props

	const [busy, setBusy] = React.useState<boolean>(false)
	const [untaggedReleaseItemIDs, setUntaggedReleaseItemIDs] = React.useState<Set<string>>(new Set())
	const { params } = useFormHelpers<CreateReleaseUnitsParams>(() => {
		const releaseItemIDs = _.map(releaseItems, "id")
		return newCreateReleaseUnitsParams({
			releaseID: release.id,
			releaseItemIDs,
		})
	})
	const { redirect, setRedirect } = useRedirect()
	const [releaseItemWorkCellDateQuantityMap, setReleaseItemWorkCellDateQuantityMap] =
		React.useState<ReleaseItemWorkCellDateQuantityMap>({})

	React.useEffect(() => {
		if (!releaseItems) {
			return
		}
		;(async () => {
			const resp = await GetTags({
				releaseItemIDs: releaseItems.map((releaseItem) => releaseItem.id),
			})
			if (!resp.ok) {
				return
			}
			const taggedReleaseItemIDs = (resp.result.tags ?? []).map((tag) => tag.releaseItemID)
			const untaggedReleaseItems = releaseItems.filter(
				(releaseItem) => !taggedReleaseItemIDs.includes(releaseItem.id),
			)
			setUntaggedReleaseItemIDs(new Set(untaggedReleaseItems.map((releaseItem) => releaseItem.id)))
		})()
	}, [releaseItems])

	if (redirect) {
		return redirect()
	}

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		try {
			const resp = await CreateReleaseUnits({ ...params, releaseItemWorkCellDateQuantityMap })
			if (!resp.ok) {
				if (_.size(resp.errors) === 1 && _.has(resp.errors, "general")) {
					throw _.get(resp, "errors.general", t("common.errors.unexpected"))
				} else {
					handleError(resp.errors)
				}
			} else {
				const count = _.size(_.get(resp, "result.releaseUnits", []))
				setRedirect(urlTo("release/releaseUnits", release), {
					alertLevel: AlertLevel.Success,
					title: t("releaseUnit.notifications.success.generated", count),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	const disabled = _.isEmpty(releaseItemWorkCellDateQuantityMap)

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row gap-x-4">
				<div className="w-full">
					<h2 className="mb-4 text-lg">Schedule release items:</h2>
					<ReleaseDetails
						release={release}
						section={"items"}
						scheduleable
						untaggedReleaseItemIDs={untaggedReleaseItemIDs}
						onChange={(map: ReleaseItemWorkCellDateQuantityMap) =>
							setReleaseItemWorkCellDateQuantityMap(map)
						}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("releaseUnit.buttons.create")}
					busy={busy}
					disabled={disabled}
				/>
			</FormActions>
		</form>
	)
}
