import React from "react"
import _ from "lodash"
import { Redirect } from "react-router-dom"

import { Alert, Button, Input, Title } from "@app/components"
import { SplashLayout } from "@app/layouts"
import { Login, LoginByEmail, WhoAmI } from "@app/api"
import { useSession } from "@app/contexts"
import { AlertLevel } from "@app/domain"

export const LoginPage: React.FC = () => {
	const { t, user, setSession, handleError, addNotification } = useSession()

	let defaultEmail = ""
	let defaultPassword = ""
	if (import.meta.env.DEV) {
		defaultEmail = "navn.navnesen-1@toggle.is"
		defaultPassword = "verysecret"
	}

	const [email, setEmail] = React.useState<string>(defaultEmail)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)
	const [password, setPassword] = React.useState<string>(defaultPassword)
	const [forgotPassword, setForgotPassword] = React.useState<boolean>(true)
	const [loginByEmail, setLoginByEmail] = React.useState<boolean>(false)

	React.useEffect(() => {
		const count = _.size(errors)
		const message = _.get(errors, "general", "")
		if (message !== "") {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: message,
				subtitle: t("common.errors.tryAgain"),
			})
		} else if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("login.notifications.failure.submit", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	React.useEffect(() => {
		const whoAmI = async () => {
			try {
				const resp = await WhoAmI()
				if (_.get(resp, "ok")) {
					setSession(resp.result.session)
				}
			} catch (err) {
				handleError(err)
			}
		}
		whoAmI()
	}, [])

	if (user) {
		return <Redirect to="/" />
	}

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		try {
			let resp
			if (forgotPassword) {
				resp = await LoginByEmail({ email })
				if (!resp.ok) {
					setErrors(resp.errors)
				} else {
					setLoginByEmail(true)
					setErrors({})
					setBusy(false)
					return false
				}
			} else {
				resp = await Login({ email, password })
				if (!resp.ok) {
					setErrors(resp.errors)
				} else {
					setErrors({})
					setSession(resp.result.session)
					return false
				}
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	const actionText = loginByEmail
		? ""
		: forgotPassword
		? t("login.buttons.email")
		: t("login.buttons.submit")

	return (
		<SplashLayout>
			<Title>{actionText}</Title>
			<div className="block text-center">
				<h2 className="text-2xl leading-9 text-gray-700">{t("login.header")}</h2>
			</div>
			{loginByEmail ? (
				<Alert
					className="mb-4 mt-4"
					color="green"
					icon="Check"
					title={t("login.confirm.loginByEmailHeader")}
				>
					{t("login.confirm.loginByEmailBody")}
				</Alert>
			) : null}
			<form className="mt-2" method="post" onSubmit={onSubmit} noValidate>
				<div className="">
					<Input
						autoCapitalize="none"
						disabled={busy || loginByEmail}
						autoFocus={true}
						defaultValue={email}
						label={t("login.labels.email")}
						name="email"
						error={_.get(errors, "email")}
						onChange={(e: React.FormEvent<HTMLInputElement>) => {
							const target = e.target as HTMLInputElement
							setEmail(target.value)
						}}
						type="email"
					/>
					{forgotPassword ? null : (
						<Input
							disabled={busy}
							defaultValue={password}
							label={t("login.labels.password")}
							name="password"
							type="password"
							error={_.get(errors, "password")}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								const target = e.target as HTMLInputElement
								setPassword(target.value)
							}}
						/>
					)}
				</div>
				{forgotPassword ? (
					<div className="mt-1 mb-5 flex items-center justify-between">
						<div className="flex items-center"></div>
						<div className="text-sm text-gray-700 leading-5">
							<a
								data-test="hasPasswordButton"
								className="underline cursor-pointer"
								onClick={() => {
									if (busy) {
										return
									}
									setForgotPassword(false)
									setLoginByEmail(false)
									setBusy(false)
								}}
							>
								{t("login.links.havePassword")}
							</a>
						</div>
					</div>
				) : (
					<div className="mt-1 mb-5 flex items-center justify-between">
						<div className="flex items-center"></div>
						<div className="text-sm text-gray-700 leading-5">
							<a
								data-test="forgotPasswordButton"
								className="underline cursor-pointer"
								onClick={() => {
									if (busy) {
										return
									}
									setForgotPassword(true)
									setLoginByEmail(false)
									setBusy(false)
								}}
							>
								{t("login.links.forgotPassword")}
							</a>
						</div>
					</div>
				)}
				<div className="mt-2">
					<Button
						data-test="loginSubmit"
						type="submit"
						label={actionText}
						icon={loginByEmail ? "CheckCircle" : undefined}
						errors={errors}
						busy={busy}
						disabled={loginByEmail}
					/>
				</div>
			</form>
		</SplashLayout>
	)
}
