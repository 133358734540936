import React from "react"
import _ from "lodash"

import {
	Button,
	FormActions,
	Input,
	Toggle,
	WorkCellSelect,
	Select,
	WorkFacilitySelect,
	WorkResourceSelect,
	DateTimeInput,
	Label,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkEventParams } from "@app/api"
import { AlertLevel, EntityType, WorkFacility } from "@app/domain"
import { useFormHelpers, useRelation } from "@app/hooks"

import En from "@app/translations/en.json"

interface IWorkEventFormProps {
	busy?: boolean
	defaultParams: SaveWorkEventParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkEventParams) => void
}

export const WorkEventForm: React.FC<IWorkEventFormProps> = (props) => {
	const { t, addNotification } = useSession()

	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { dateHandler, inputHandler, valueHandler, selectHandler, params } =
		useFormHelpers<SaveWorkEventParams>(defaultParams)

	const workCellProgramID = _.get(params, "workCellProgramID")
	const [workCellProgram] = useRelation(EntityType.WorkCellProgram, workCellProgramID)
	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, params?.workFacilityID)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workEvent.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const workEventTypes = _.map(En.workEventTypes, (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultWorkEventTypeOption = undefined
	const workEventTypeOptions = workEventTypes.map((c) => {
		const opt = { value: _.upperFirst(c.code), label: c.name }
		if (_.get(params, "workEventType", "") === c.code) {
			defaultWorkEventTypeOption = opt
		}
		return opt
	})

	if (_.isNil(defaultWorkEventTypeOption)) {
		defaultWorkEventTypeOption = _.first(workEventTypeOptions)
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<>
						<Select
							isClearable={false}
							defaultValue={defaultWorkEventTypeOption}
							disabled={disabled || busy || !_.isNil(workCellProgram)}
							isSearchable={true}
							label={t("workEvent.labels.workEventType")}
							name="workEventType"
							onChange={selectHandler("workEventType")}
							options={workEventTypeOptions}
						/>
						{workCellProgram && (
							<>
								<Label>{t("workEvent.labels.workCellProgramID")}</Label>
								<div className="p-2 border mb-4 border-gray-400 text-gray-800">
									{_.get(workCellProgram, "name", "...")}
								</div>
							</>
						)}
						<WorkFacilitySelect
							defaultValue={_.compact([_.get(params, "workFacilityID")])}
							disabled={false}
							error={_.get(errors, "workFacilityID")}
							label={t("workEvent.labels.workFacilityID")}
							name="workFacilityID"
							onChange={selectHandler("workFacilityID", false, (next: SaveWorkEventParams) => {
								if (_.get(next, "workFacilityID") !== _.get(params, "workFacilityID")) {
									return { ...next, workCellID: undefined }
								}
								return next
							})}
						/>
						<WorkCellSelect
							workFacilityID={_.get(params, "workFacilityID")}
							defaultValue={_.get(params, "workCellID")}
							disabled={disabled || busy}
							error={_.get(errors, "workCellID")}
							label={t("workEvent.labels.workCellID")}
							name="workCellID"
							onChange={selectHandler("workCellID", false)}
						/>
						{!workCellProgram && (
							<WorkResourceSelect
								workFacilityID={_.get(params, "workFacilityID")}
								defaultValue={_.get(params, "workResourceID")}
								disabled={disabled || busy}
								error={_.get(errors, "workResourceID")}
								label={t("workEvent.labels.workResourceID")}
								name="workResourceID"
								onChange={selectHandler("workResourceID", false)}
							/>
						)}
						<Input
							autoComplete="off"
							disabled={disabled || busy}
							defaultValue={params.description}
							label={t("workEvent.labels.description")}
							name="description"
							error={_.get(errors, "description")}
							onChange={inputHandler("description")}
							type="text"
						/>
						<DateTimeInput
							withTime={true}
							clearable={false}
							defaultValue={_.get(params, "timeBegin")}
							disabled={disabled || busy}
							error={_.get(errors, "timeBegin")}
							label={t("workEvent.labels.timeBegin")}
							name="timeBegin"
							onChange={dateHandler("timeBegin")}
							timezone={workFacility?.timezone}
						/>
						<DateTimeInput
							withTime={true}
							clearable={false}
							defaultValue={_.get(params, "timeEnd")}
							disabled={disabled || busy}
							error={_.get(errors, "timeEnd")}
							label={t("workEvent.labels.timeEnd")}
							name="timeEnd"
							onChange={dateHandler("timeEnd")}
							timezone={workFacility?.timezone}
						/>
						<Toggle
							label={t("workEvent.labels.canceled")}
							defaultChecked={params.canceled}
							disabled={disabled || busy}
							error={_.get(errors, "canceled")}
							onChange={valueHandler("canceled")}
						/>
					</>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workEvent.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
