import React from "react"

import { Await, PageContainer, TagDetails } from "@app/components"
import { TagHeader } from "@app/headers"
import { Tag, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const TagShowPage: React.FC = () => {
	const [tag, tagLoading, setTag] = useEntity<Tag>(EntityType.Tag)

	return (
		<PageContainer>
			<TagHeader tag={tag} setTag={setTag} view="show" />
			<Await
				loading={tagLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<TagDetails tag={tag} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
