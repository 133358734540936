import _ from "lodash"

import type { Address, Attribute, Contactable } from "@app/domain"

export type Job = {
	id: string
	customerID: string
	workFacilityIDs: string[]

	addresses: Address[]
	attributes: Attribute[]
	countryOfOrigin: string
	metric: boolean
	name: string
	referenceID: string

	contactables: Contactable[]

	created: string
	updated: string
}

export const newJob = (opts?: { [key: string]: string | boolean | number }): Job => {
	return _.assign(
		{
			id: "",
			customerID: "",
			workFacilityIDs: [],

			addresses: [],
			attributes: [],
			countryOfOrigin: "",
			metric: false,
			name: "",
			referenceID: "",

			contactables: [],

			created: "",
			updated: "",
		},
		opts,
	)
}
