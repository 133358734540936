import React from "react"
import { HelmetProvider } from "react-helmet-async"
import { I18n } from "react-polyglot"

import { SessionProvider, StreamProvider } from "@app/contexts"
import En from "@app/translations/en.json"

export const Providers = ({ children }: { children: React.ReactNode }) => {
	return (
		<I18n locale={"en"} messages={En}>
			<HelmetProvider>
				<SessionProvider>
					<StreamProvider>{children}</StreamProvider>
				</SessionProvider>
			</HelmetProvider>
		</I18n>
	)
}
