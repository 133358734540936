import * as locales from "date-fns/locale"
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz"

export const locale = (
	navigator.languages?.filter((l) => l)?.[0] ||
	navigator?.language ||
	"enUS"
).split("-")[0]
export const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

const msToHours = (ms: number) => ms / 1000 / 60 / 60
export const getBrowserTimezoneOffset = (timezone: string) =>
	msToHours(getTimezoneOffset(browserTimezone) - getTimezoneOffset(timezone))
export const getUTCOffset = (timezone: string) => msToHours(getTimezoneOffset(timezone))
export const addBrowserTimezoneOffset = zonedTimeToUtc // Renamed for clarity.
export const subtractBrowserTimezoneOffset = utcToZonedTime // Renamed for clarity.
export const formatTimezone = (timezone: string, abbreviated?: boolean) =>
	format(new Date(), abbreviated ? "zzz" : "zzzz", {
		timeZone: timezone,
		locale: locales?.[locale] ?? locales?.enUS,
	})
