import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { GetTagPreview } from "@app/api"
import { Tag, TagUtils } from "@app/domain"
import { Image, Icon } from "@app/components"
import { useDebouncedEffect } from "@app/hooks"

interface ITagPreviewProps {
	editing?: boolean
	tag?: Tag
	unit?: number
}

export const TagPreview: React.FC<ITagPreviewProps> = (props) => {
	const { handleError, tenantID } = useSession()

	const { unit, tag, editing = false } = props

	const [loading, setLoading] = React.useState<boolean>(true)
	const [fetch, setFetch] = React.useState<boolean>(true)
	const [tagData, setTagData] = React.useState<Tag | undefined>(() => _.cloneDeep(tag))
	const [imageData, setImageData] = React.useState<string | undefined>()

	useDebouncedEffect(
		() => {
			setFetch(true)
		},
		1000,
		[tagData],
	)

	React.useEffect(() => {
		if (!_.isEqual(tag, tagData)) {
			if (!_.isNil(tagData)) {
				setFetch(false)
			}
			setTagData(_.cloneDeep(tag))
		}
	}, [tag])

	React.useEffect(() => {
		const getTagPreview = async () => {
			setLoading(true)
			try {
				if (tagData) {
					const resp = await GetTagPreview(tagData)
					if (resp.ok) {
						setImageData("data:image/png;base64," + _.get(resp, "result.imageData"))
					}
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		if (fetch && editing) {
			getTagPreview()
		}
	}, [fetch])

	if (!editing) {
		return _.isNil(tag) ? null : (
			<Image
				src={TagUtils.pngURL(tag, tenantID, unit)}
				loading="lazy"
				style={{ maxHeight: "640px", maxWidth: "234px" }}
				fallback={(err) => {
					return (
						<div
							className="bg-gray-200 inline-flex justify-center items-center rounded-xl m-1"
							style={{ minHeight: "640px", minWidth: "234px" }}
						>
							{err ? (
								<Icon className="text-gray-300 text-xl" name="QuestionCircle" />
							) : (
								<Icon className="text-gray-500 text-xl" name="Cog" spin />
							)}
						</div>
					)
				}}
			/>
		)
	}

	return _.isNil(imageData) || loading ? (
		<div
			className="bg-gray-200 inline-flex justify-center items-center rounded-xl m-1"
			style={{ minHeight: "640px", minWidth: "234px" }}
		>
			<Icon className="text-gray-500 text-xl" name="Cog" spin />
		</div>
	) : (
		<img src={imageData} style={{ maxHeight: "640px", maxWidth: "234px" }} />
	)
}
