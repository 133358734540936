import React from "react"

import { GetWorkCellServers, GetWorkFacilities, GetWorkCells } from "@app/api"
import { EntityColumnType, EntityColumn } from "@app/domain"
import { ReportsHeader } from "@app/headers"

import { EntityTable, PageContainer } from "@app/components"
import { EntityType } from "@app/domain"

type WorkCellSessionRow = {
	created: string
	id: string
	invalidated: string
	ipAddress: string
	updated: string
	version: string
	rapidVersion: string
	rlsVersion: string
	workCellServerID: string
}

export const ReportsWorkCellSessionsPage: React.FC = () => {
	return (
		<PageContainer>
			<ReportsHeader view="work-cell-sessions" />
			<EntityTable
				fullHeight={true}
				entityType={EntityType.WorkCellSession}
				sideEffects={async (resp) => {
					const invalidatedIdx = resp.result.columns.findIndex((col) => col.name === "invalidated")
					if (invalidatedIdx !== -1) {
						resp.result.columns.splice(invalidatedIdx, 1)
					}
					const tenantIdx = resp.result.columns.findIndex((col) => col.name === "tenantID")
					if (tenantIdx !== -1) {
						resp.result.columns.splice(tenantIdx, 1)
					}
					const workCellServersResp = await GetWorkCellServers({
						ids: resp.result.rows.map((wcs) => (wcs as WorkCellSessionRow).workCellServerID),
					})
					if (workCellServersResp.ok) {
						const workCellsResp = await GetWorkCells({
							ids: workCellServersResp.result.workCellServers.map((wcs) => wcs.workCellID),
						})
						const workFacilitiesResp = await GetWorkFacilities({
							ids: workCellServersResp.result.workCellServers.map((wcs) => wcs.workFacilityID),
						})

						const workCellServersMap = Object.fromEntries(
							workCellServersResp.result.workCellServers.map((wcs) => [wcs.id, wcs]),
						)
						const workCellsMap = Object.fromEntries(
							workCellsResp.result.workCells.map((wc) => [wc.id, wc]),
						)
						const workFacilitiesMap = Object.fromEntries(
							workFacilitiesResp.result.workFacilities.map((wf) => [wf.id, wf]),
						)

						resp.result.rows = resp.result.rows.map((row) => ({
							...row,
							workCellServer: workCellServersMap[(row as WorkCellSessionRow).workCellServerID].name,
							workCell:
								workCellsMap[
									workCellServersMap[(row as WorkCellSessionRow).workCellServerID].workCellID
								].name,
							workFacility:
								workFacilitiesMap[
									workCellServersMap[(row as WorkCellSessionRow).workCellServerID].workFacilityID
								].name,
						}))
						resp.result.columns = [
							{
								label: "Work Facility",
								name: "workFacility",
								options: {
									hidden: false,
									group: false,
									groupable: true,
									relativeTime: false,
									sortable: true,
									timeEnd: false,
									timeStart: false,
								},
								type: EntityColumnType.String,
							} as EntityColumn,
							{
								label: "Work Cell",
								name: "workCell",
								options: {
									hidden: false,
									group: false,
									groupable: true,
									relativeTime: false,
									sortable: true,
									timeEnd: false,
									timeStart: false,
								},
								type: EntityColumnType.String,
							} as EntityColumn,
							{
								label: "Work Cell Server",
								name: "workCellServer",
								options: {
									hidden: false,
									group: false,
									groupable: true,
									relativeTime: false,
									sortable: true,
									timeEnd: false,
									timeStart: false,
								},
								type: EntityColumnType.String,
							} as EntityColumn,
							...resp.result.columns,
						]
					}
				}}
			/>
		</PageContainer>
	)
}
