import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { GetDocumentPreview, GetDocumentImages } from "@app/api"
import { Document, DocumentUtils } from "@app/domain"
import { Image, Icon } from "@app/components"
import { useDebouncedEffect } from "@app/hooks"

interface IDocumentPreviewProps {
	editing?: boolean
	document: Document
	item?: number
}

export const DocumentPreview: React.FC<IDocumentPreviewProps> = (props: IDocumentPreviewProps) => {
	const { tenantID, handleError } = useSession()

	const { document, editing = false } = props

	const [loading, setLoading] = React.useState<boolean>(true)
	const [fetch, setFetch] = React.useState<boolean>(true)
	const [documentData, setDocumentData] = React.useState<Document | undefined>(() =>
		_.cloneDeep(document),
	)
	const [imageData, setImageData] = React.useState<string[] | undefined>()

	useDebouncedEffect(
		() => {
			setFetch(true)
		},
		1000,
		[documentData],
	)

	React.useEffect(() => {
		if (!_.isEqual(document, documentData)) {
			if (!_.isNil(documentData)) {
				setFetch(false)
			}
			setDocumentData(_.cloneDeep(document))
		}
	}, [document])

	React.useEffect(() => {
		const getDocumentPreview = async () => {
			setLoading(true)
			try {
				if (documentData) {
					const resp = await GetDocumentPreview(documentData)
					if (resp.ok) {
						setImageData(resp.result.imageData)
					}
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		if (fetch && editing) {
			getDocumentPreview()
		}
	}, [fetch])

	React.useEffect(() => {
		const getDocumentImages = async () => {
			setLoading(true)
			try {
				const resp = await GetDocumentImages(document.id)
				if (resp.ok) {
					setImageData(resp.result.imageData)
				}
			} catch (err) {
				handleError(err)
			}
			setLoading(false)
		}
		if (!editing) {
			getDocumentImages()
		}
	}, [editing, document])

	if (!editing) {
		return _.isNil(document) ? null : (
			<>
				{_.times(document.pageCount || 1, (pageIndex) => (
					<Image
						key={pageIndex}
						className="mb-1"
						src={DocumentUtils.pngURL(document, tenantID, pageIndex)}
						loading="lazy"
						style={{ maxHeight: "800px", maxWidth: "640px" }}
						fallback={(err) => {
							return (
								<div
									className="bg-gray-200 inline-flex justify-center items-center rounded-xl m-1"
									style={{ minHeight: "800px", minWidth: "640px" }}
								>
									{err ? (
										<Icon className="text-gray-300 text-xl" name="QuestionCircle" />
									) : (
										<Icon className="text-gray-500 text-xl" name="Cog" spin />
									)}
								</div>
							)
						}}
					/>
				))}
			</>
		)
	}

	return _.isNil(imageData) || loading ? (
		<div className="bg-gray-200 inline-flex justify-center items-center rounded-xl h-screen/2 w-96">
			<Icon className="text-gray-500 text-xl" name="Cog" spin />
		</div>
	) : (
		<>
			{imageData.map((image, i) => (
				<img
					className="py-1 w-full"
					loading="lazy"
					key={i}
					src={"data:image/png;base64," + image}
				/>
			))}
		</>
	)
}
