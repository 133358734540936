import React from "react"
import _ from "lodash"

import {
	CustomerSelect,
	DocumentPreview,
	Input,
	JobSelect,
	ReleaseSelect,
	Select,
	ShipmentSelect,
	Toggle,
} from "@app/components"
import { AttributesForm, TemplateFieldsForm, BillOfLadingLineItemsForm } from "@app/forms"
import { useSession } from "@app/contexts"
import { SaveDocumentParams } from "@app/api"
import { Customer, EntityType, Job, newDocument, Release, Shipment } from "@app/domain"
import { toUUID } from "@app/util"
import { useQuery, useRelation, useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

import type { OptionType } from "@app/components"

interface IDocumentFormProps {
	defaultParams: SaveDocumentParams
	errors: { [key: string]: string }
	disabled?: boolean
	busy?: boolean
	saving?: boolean
	onChange?: (params: SaveDocumentParams) => void
}

export const DocumentForm: React.FC<IDocumentFormProps> = (props) => {
	const { defaultParams, errors, disabled = false, busy = false, onChange } = props

	const { t } = useSession()
	const query = useQuery()

	const { params, setParams, inputHandler, selectHandler, valueHandler } =
		useFormHelpers<SaveDocumentParams>(defaultParams)
	const [customer, customerLoading] = useRelation<Customer>(
		EntityType.Customer,
		toUUID(query.customer),
	)
	const [job, jobLoading] = useRelation<Job>(EntityType.Job, toUUID(query.job))
	const [release, releaseLoading] = useRelation<Release>(EntityType.Release, toUUID(query.release))
	const [shipment, shipmentLoading] = useRelation<Shipment>(
		EntityType.Shipment,
		toUUID(query.shipment),
	)

	React.useEffect(() => {
		if (onChange) {
			onChange(params)
		}
	}, [params])

	React.useEffect(() => {
		setParams((params) => {
			const documentables = []
			if (customer) {
				documentables.push({
					documentableID: customer.id,
					documentableType: EntityType.Customer,
				})
			} else if (job) {
				documentables.push({
					documentableID: job.id,
					documentableType: EntityType.Job,
				})
			} else if (release) {
				documentables.push({
					documentableID: release.id,
					documentableType: EntityType.Release,
				})
			} else if (shipment) {
				documentables.push({
					documentableID: shipment.id,
					documentableType: EntityType.Shipment,
				})
			}
			return { ...params, documentables }
		})
	}, [customerLoading, jobLoading, releaseLoading, shipmentLoading])

	const documentableHandler = (entityType: EntityType): ((v: OptionType[]) => void) => {
		return (v: OptionType[]) => {
			setParams((params) => {
				const documentableID = _.first(v)
				if (_.isString(documentableID)) {
					const documentables = [
						{
							documentableID,
							documentableType: entityType,
						},
					]
					return { ...params, documentables }
				}
				return { ...params, documentables: [] }
			})
		}
	}

	const documentTypeOptions = _.keys(En.document.types)
		.sort()
		.map((k) => {
			return { value: _.upperFirst(k), label: t(`document.types.${_.camelCase(k)}`) }
		})

	const defaultDocumentType = _.find(documentTypeOptions, { value: defaultParams.documentType })

	const loading = customerLoading || jobLoading || releaseLoading || shipmentLoading

	if (loading) {
		return null
	}

	return (
		<div className="flex flex-col md:flex-row-reverse md:h-usable">
			<div className="h-screen/2 md:h-auto mb-4 md:w-3/5 lg:w-2/3 md:ml-4 overflow-auto">
				<DocumentPreview document={newDocument({ ...defaultParams, ...params })} editing={true} />
			</div>
			<div className="md:w-2/5 lg:w-1/3 overflow-auto md:pr-3">
				{customer ? (
					<CustomerSelect
						defaultValue={_.get(customer, "id")}
						disabled={busy}
						error={_.get(errors, "customerID")}
						label={t("entity.customer")}
						name="customerID"
						onChange={documentableHandler(EntityType.Customer)}
					/>
				) : null}

				{job ? (
					<JobSelect
						defaultValue={_.get(job, "id")}
						disabled={busy}
						error={_.get(errors, "jobID")}
						label={t("entity.job")}
						name="jobID"
						onChange={documentableHandler(EntityType.Job)}
					/>
				) : null}

				{release ? (
					<ReleaseSelect
						defaultValue={_.get(release, "id")}
						disabled={busy}
						error={_.get(errors, "releaseID")}
						label={t("entity.release")}
						name="releaseID"
						onChange={documentableHandler(EntityType.Release)}
					/>
				) : null}

				{shipment ? (
					<ShipmentSelect
						defaultValue={_.get(shipment, "id")}
						disabled={busy}
						error={_.get(errors, "shipmentID")}
						label={t("entity.shipment")}
						name="shipmentID"
						onChange={documentableHandler(EntityType.Shipment)}
					/>
				) : null}
				<Select
					defaultValue={defaultDocumentType}
					disabled={disabled || busy}
					isSearchable={true}
					label={t("document.labels.documentType")}
					name="documentType"
					onChange={selectHandler("documentType")}
					options={documentTypeOptions}
				/>
				<Input
					autoComplete="off"
					disabled={disabled || busy}
					autoFocus={params.id === ""}
					defaultValue={params.name}
					label={t("document.labels.name")}
					name="name"
					error={_.get(errors, "name")}
					onChange={inputHandler("name")}
					type="text"
				/>
				<Toggle
					label={t("common.labels.metric")}
					defaultChecked={_.get(params, "billOfLading.metric", false)}
					disabled={disabled || busy}
					error={_.get(errors, "metric")}
					onChange={valueHandler("billOfLading.metric")}
				/>
				<AttributesForm
					busy={busy}
					defaultParams={_.get(params, "attributes")}
					disabled={disabled}
					errors={errors}
					errorsKey="attributes"
					onChange={valueHandler("attributes")}
				/>
				<TemplateFieldsForm
					busy={busy}
					defaultParams={_.get(params, "templateFields", [])}
					disabled={disabled}
					errors={errors}
					documentType={params.documentType}
					errorsKey="templateFields"
					onChange={valueHandler("templateFields")}
				/>
				<BillOfLadingLineItemsForm
					busy={busy}
					defaultParams={_.get(params, "billOfLading.lineItems", [])}
					disabled={disabled}
					errors={errors}
					errorsKey="billOfLading.lineItems"
					onChange={valueHandler("billOfLading.lineItems")}
				/>
			</div>
		</div>
	)
}
