import _ from "lodash"

interface IConfig {
	baseURL: string
	buildCommit: string
	buildTime: string
	buildVersion: string
	debug: boolean
	env: string
	internalConfiguratorGRPCWebURL: string
	webSocketsDisabled: boolean
}

const config = {
	baseURL: "https://os.toggle.test",
	buildCommit: "0000000000000000000000000000000000000000",
	buildTime: new Date().toISOString(),
	buildVersion: "v0.0.0",
	debug: false,
	env: "development",
	internalConfiguratorGRPCWebURL: "https://configurator-grpc-web.toggle.test",
	webSocketsDisabled: false,
}

export default _.assign({}, config, _.get(window, "__CONFIG")) as IConfig
