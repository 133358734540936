import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { WorkCellServer, AlertLevel, EntityType } from "@app/domain"
import { WorkCellServerForm } from "@app/forms"
import { WorkCellServerHeader } from "@app/headers"
import { newSaveWorkCellServerParams, SaveWorkCellServer, SaveWorkCellServerParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkCellServerEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workCellServer, workCellServerLoading] = useEntity<WorkCellServer>(
		EntityType.WorkCellServer,
	)
	const { id, name } = _.assign({ id: "", name: "..." }, workCellServer)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveWorkCellServerParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkCellServer({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workCellServers[0]")
				setRedirect(urlTo("workCellServers", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workCellServer.notifications.success.updated", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workCellServer.titles.edit", { name })}</Title>
			<WorkCellServerHeader workCellServer={workCellServer} view="edit" />
			<Await
				loading={workCellServerLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkCellServerForm
							busy={busy}
							defaultParams={newSaveWorkCellServerParams(workCellServer)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
