import React from "react"

import { DialogLayout } from "@app/layouts"
import { Title } from "@app/components"

interface IHTTPErrorPageProps {
	code?: number
	error?: string
}

export const HTTPErrorPage: React.FC<IHTTPErrorPageProps> = (props) => {
	const { code = 500, error = "Internal Server Error" } = props
	return (
		<DialogLayout>
			<Title>{`${code} | ${error}`}</Title>
			<div className="text-center mt-2">
				<h3 className="text-xl text-white bg-red-500 py-2 px-4">
					<code>{code}</code>
					<span className="text-white opacity-50 px-3">|</span>
					<span>{error}</span>
				</h3>
			</div>
		</DialogLayout>
	)
}
