import React from "react"
import _ from "lodash"

import {
	Button,
	FormActions,
	Input,
	EnumTypeSelect,
	WorkCellSelect,
	TextField,
	WorkFacilitySelect,
	Property,
	DateTimeInput,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveReleaseUnitParams } from "@app/api"
import { AlertLevel, EntityType, WorkFacility, WorkCell } from "@app/domain"
import { useRelation, useFormHelpers } from "@app/hooks"

interface IReleaseUnitFormProps {
	busy?: boolean
	defaultParams: SaveReleaseUnitParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveReleaseUnitParams) => void
}

export const ReleaseUnitForm: React.FC<IReleaseUnitFormProps> = (props) => {
	const { t, addNotification } = useSession()

	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { inputHandler, dateHandler, textFieldHandler, selectHandler, intInputHandler, params } =
		useFormHelpers<SaveReleaseUnitParams>(defaultParams)
	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, params?.workFacilityID)
	const [workCell] = useRelation<WorkCell>(EntityType.WorkFacility, params?.workCellID)
	const buildType =
		workCell?.workCellType[0] === "M"
			? t("releaseUnitBuildTypes.Manual")
			: t("releaseUnitBuildTypes.Robotic")

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("workFacility.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Property
						label={t("releaseUnit.labels.assemblyID")}
						relation={EntityType.Assembly}
						value={params.assemblyID}
					/>
					<Property
						label={t("releaseUnit.labels.customerID")}
						relation={EntityType.Customer}
						value={params.customerID}
					/>
					<Property
						label={t("releaseUnit.labels.jobID")}
						relation={EntityType.Job}
						value={params.jobID}
					/>
					<Property
						label={t("releaseUnit.labels.releaseID")}
						relation={EntityType.Release}
						value={params.releaseID}
					/>
					<Property
						label={t("releaseUnit.labels.tagID")}
						relation={EntityType.Tag}
						value={params.tagID}
					/>
					<WorkFacilitySelect
						value={params?.workCellID ? workCell?.workFacilityID : undefined}
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={!!params?.workCellID}
						error={_.get(errors, "workFacilityID")}
						label={t("releaseUnit.labels.workFacilityID")}
						hint={!params?.workCellID ? "" : t("releaseUnit.hints.workCellDetermined")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID", false, (next: SaveReleaseUnitParams) => {
							if (_.get(next, "workFacilityID") !== _.get(params, "workFacilityID")) {
								return { ...next, workCellID: undefined }
							}
							return next
						})}
					/>
					<WorkCellSelect
						workFacilityID={_.get(params, "workFacilityID")}
						defaultValue={_.get(params, "workCellID")}
						disabled={disabled || busy}
						error={_.get(errors, "workCellID")}
						label={t("releaseUnit.labels.workCellID")}
						name="workCellID"
						onChange={selectHandler("workCellID", false)}
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.controlCodes}
						hint={t("releaseUnit.hints.controlCodes")}
						label={t("releaseUnit.labels.controlCodes")}
						name="controlCodes"
						error={_.get(errors, "controlCodes")}
						onChange={inputHandler("controlCodes")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.description}
						hint={t("releaseUnit.hints.description")}
						label={t("releaseUnit.labels.description")}
						name="description"
						error={_.get(errors, "description")}
						onChange={inputHandler("description")}
						type="text"
					/>
					<EnumTypeSelect
						value={
							!params?.workCellID
								? undefined
								: {
										label: buildType,
										value: buildType,
								  }
						}
						enumTypeName="releaseUnitBuildTypes"
						disabled={busy || !!params?.workCellID}
						label={t("releaseUnit.labels.buildType")}
						hint={!params?.workCellID ? "" : t("releaseUnit.hints.workCellDetermined")}
						name="buildType"
						onChange={selectHandler("buildType")}
						defaultValue={params.buildType}
					/>
					<EnumTypeSelect
						enumTypeName="releaseUnitStatusTypes"
						disabled={busy}
						label={t("releaseUnit.labels.status")}
						name="status"
						onChange={selectHandler("status")}
						defaultValue={params.status}
					/>
					<DateTimeInput
						clearable
						defaultValue={JSON.stringify(params.scheduled)}
						name="scheduled"
						onChange={dateHandler("scheduled")}
						timezone={workFacility?.timezone}
						withTime
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.unit}
						hint={t("releaseUnit.hints.unit")}
						label={t("releaseUnit.labels.unit")}
						name="unit"
						error={_.get(errors, "unit")}
						onChange={intInputHandler("unit")}
						type="text"
					/>
					<TextField
						onChange={textFieldHandler("notes")}
						name="notes"
						label={t("releaseUnit.labels.notes")}
						hint={t("releaseUnit.hints.notes")}
						value={params.notes}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("releaseUnit.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
