import React from "react"

export const useDebouncedEffect = (effect: () => void, delay: number, deps: unknown[]): void => {
	const callback = React.useCallback(effect, deps)

	React.useEffect(() => {
		let mounted = true

		const handler = setTimeout(() => {
			if (mounted) {
				callback()
			}
		}, delay)

		return () => {
			mounted = false
			clearTimeout(handler)
		}
	}, [callback, delay])
}
