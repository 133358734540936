import React from "react"
import _ from "lodash"

import { Badge, Select, Button, ButtonIcon, Input, Icon, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { AddressParams, newAddressParams } from "@app/api"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IAddressFormProps {
	busy?: boolean
	defaultOpen?: boolean
	defaultParams: AddressParams
	disabled?: boolean
	errors: { [key: string]: string }
	onChange: (address?: AddressParams) => void
}

const AddressForm: React.FC<IAddressFormProps> = (props) => {
	const { t } = useSession()
	const {
		defaultParams,
		defaultOpen = false,
		busy = false,
		onChange,
		disabled = false,
		errors,
	} = props

	const { params, inputHandler, selectHandler } = useFormHelpers<AddressParams>(defaultParams)
	const [open, setOpen] = React.useState<boolean>(defaultOpen)

	React.useEffect(() => {
		if (!open) {
			if (
				_.every(
					[
						params.city,
						params.country,
						params.labels,
						params.line1,
						params.line2,
						params.postalCode,
						params.state,
					],
					_.isEmpty,
				)
			) {
				removeHandler()
			}
		}
	}, [open])

	const removeHandler = () => {
		onChange(undefined)
	}

	const countries = _.map(En.countries, (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultCountryOption = undefined
	const countryOptions = countries.map((c) => {
		const opt = { value: c.code, label: c.name }
		if (_.get(params, "country", "") === c.code) {
			defaultCountryOption = opt
		}
		return opt
	})

	const labels = _.map(En.address.labelOptions, (val, key) =>
		t(`address.labelOptions.${key}`),
	).sort()

	const defaultLabelsOption = _.map(_.get(params, "labels", []), (label) => {
		return { value: label, label: label }
	})

	const labelOptions = labels.map((v) => {
		return { value: v, label: v }
	})

	React.useEffect(() => {
		onChange(params)
	}, [params])

	React.useEffect(() => {
		if (!_.isEmpty(errors) && !open) {
			setOpen(true)
		}
	}, [errors])

	return (
		<div className="border-t border-gray-300 py-5 mb-6">
			<div className={`p-4 bg-gray-200 border border-gray-300 ${open ? "" : "hidden"}`}>
				<div className="flex flex-row justify-between gap-x-2">
					<div className="w-full">
						<Input
							autoComplete="off"
							defaultValue={params.line1}
							disabled={disabled || busy}
							error={_.get(errors, "line1")}
							label={t("address.labels.line1")}
							name="line1"
							onChange={inputHandler("line1")}
							placeholder={t("address.placeholders.line1")}
							type="text"
						/>
						<Input
							autoComplete="off"
							defaultValue={params.line2}
							disabled={disabled || busy}
							error={_.get(errors, "line2")}
							label={t("address.labels.line2")}
							name="line2"
							onChange={inputHandler("line2")}
							placeholder={t("address.placeholders.line2")}
							type="text"
						/>
						<Input
							autoComplete="off"
							defaultValue={params.city}
							disabled={disabled || busy}
							error={_.get(errors, "city")}
							label={t("address.labels.city")}
							name="city"
							onChange={inputHandler("city")}
							type="text"
						/>
						<Input
							autoComplete="off"
							defaultValue={params.state}
							disabled={disabled || busy}
							error={_.get(errors, "state")}
							label={t("address.labels.state")}
							name="state"
							onChange={inputHandler("state")}
							type="text"
						/>
						<Input
							autoComplete="off"
							defaultValue={params.postalCode}
							disabled={disabled || busy}
							error={_.get(errors, "postalCode")}
							label={t("address.labels.postalCode")}
							name="postalCode"
							onChange={inputHandler("postalCode")}
							type="text"
						/>
						<Select
							defaultValue={defaultCountryOption}
							disabled={disabled || busy}
							isSearchable={true}
							label={t("address.labels.country")}
							name="country"
							onChange={selectHandler("country")}
							options={countryOptions}
						/>
						<Select
							defaultValue={defaultLabelsOption}
							disabled={disabled || busy}
							isMulti
							isSearchable={true}
							label={t("address.labels.labels")}
							name="labels"
							onChange={selectHandler("labels", true)}
							options={labelOptions}
						/>
					</div>
					<div className="pt-9">
						<ButtonIcon
							tooltip={t("address.buttons.remove")}
							icon="Minus"
							onClick={removeHandler}
							disabled={disabled}
							busy={busy}
						/>
					</div>
				</div>
			</div>

			<div className={`flex justify-between ${open ? "hidden" : ""}`}>
				<div>
					<Property address={params} />
				</div>
				<div className="text-right">
					{_.map(params.labels, (label) => (
						<Badge key={label} color="blue" className="ml-1">
							{label}
						</Badge>
					))}
				</div>
			</div>

			<button
				type="button"
				className="w-full mt-4 bg-gray-300 text-center text-gray-500 hover:text-yellow-700 hover:bg-yellow-300"
				onClick={() => setOpen(!open)}
			>
				<Icon name={open ? "ChevronUp" : "ChevronDown"} />
			</button>
		</div>
	)
}

interface IAddressesFormProps {
	busy?: boolean
	defaultParams: AddressParams[]
	disabled?: boolean
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (params: AddressParams[]) => void
}

export const AddressesForm: React.FC<IAddressesFormProps> = (props) => {
	const { t } = useSession()

	const { defaultParams, onChange, disabled = false, busy = false, errors, errorsKey } = props
	const [params, setParams] = React.useState<AddressParams[]>(defaultParams)
	const [keys, setKeys] = React.useState<string[]>(_.map(params, () => _.uniqueId()))

	const changeHandler = (index: number): ((address?: AddressParams) => void) => {
		return (address?: AddressParams): void => {
			if (_.isNil(address)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				setParams((params) => {
					const result = [...params]
					result.splice(index, 1)
					return result
				})
			} else {
				setParams((params) => {
					const result = [...params]
					result[index] = address
					return result
				})
			}
		}
	}

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const addHandler = (): void => {
		setKeys((keys) => {
			return [_.uniqueId()].concat([...keys])
		})
		setParams((params) => {
			return [newAddressParams()].concat([...params])
		})
	}

	return (
		<>
			<h2 className="text-md mb-4 text-gray-700">
				{t("address.header")} <Badge quantity>{_.size(params)}</Badge>
				<Button
					busy={busy}
					className="float-right"
					disabled={disabled || busy}
					label={t("address.buttons.add")}
					onClick={addHandler}
					type="button"
					small
					add
				/>
			</h2>

			{_.map(params, (v: AddressParams, i: number) => (
				<AddressForm
					key={keys[i]}
					disabled={disabled}
					busy={busy}
					defaultParams={v}
					defaultOpen={_.get(v, "line1", "") === ""}
					errors={_.reduce(
						errors,
						(acc, err: string, k: string) => {
							const prefix = `${errorsKey}[${i}].`
							if (_.startsWith(k, prefix)) {
								acc[k.slice(_.size(prefix))] = err
							}
							return acc
						},
						{},
					)}
					onChange={changeHandler(i)}
				/>
			))}
		</>
	)
}
