import _ from "lodash"
import { addMinutes } from "date-fns"
import { format } from "date-fns-tz"

import { Cache, Batch, compactIDs, ReceivedResult, rpc } from "@app/api"
import {
	Entity,
	WorkCellProgram,
	WorkCellProgramFeedback,
	RobotProgram,
	Pagination,
} from "@app/domain"

import type { Result } from "@app/api"

export type SaveWorkCellProgramFeedbackParams = {
	workCellProgramID: string
	note: string
	feedbackType: string
}

export const newSaveWorkCellProgramFeedbackParams = (
	workCellProgramFeedback?: WorkCellProgramFeedback,
): SaveWorkCellProgramFeedbackParams => {
	return {
		workCellProgramID: "",
		feedbackType: _.get(workCellProgramFeedback, "feedbackType", ""),
		note: _.get(workCellProgramFeedback, "note", ""),
	}
}

export const SaveWorkCellProgramFeedback = async (
	params: SaveWorkCellProgramFeedbackParams,
): Promise<Result<SaveWorkCellProgramResult>> => {
	const data = await rpc("SaveWorkCellProgramFeedback", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkCellProgramResult>(data)
}

export type SaveWorkCellProgramParams = {
	id?: string
	assemblyID?: string

	name: string
	description: string
	robotPrograms: RobotProgram[]
	workCellType: string
	alwaysAvailable?: boolean
}

export const newSaveWorkCellProgramParams = (
	workCellProgram?: WorkCellProgram,
): SaveWorkCellProgramParams => {
	const now = new Date()
	const customName =
		"Custom-Program-" +
		format(addMinutes(now, now.getTimezoneOffset()), "EEE-MMM-dd-yyyy-HHmmss", { timeZone: "UTC" })

	return {
		id: _.get(workCellProgram, "id", ""),
		assemblyID: _.get(workCellProgram, "assemblyID", ""),

		name: _.get(workCellProgram, "name") || customName,
		description: _.get(workCellProgram, "description", ""),
		robotPrograms: [],
		workCellType: _.get(workCellProgram, "workCellType", ""),
		alwaysAvailable: _.get(workCellProgram, "alwaysAvailable", false),
	}
}

export type SaveWorkCellProgramResult = {
	workCellPrograms: WorkCellProgram[]
}

export const SaveWorkCellProgram = async (
	params: SaveWorkCellProgramParams,
): Promise<Result<SaveWorkCellProgramResult>> => {
	const data = await rpc("SaveWorkCellProgram", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkCellProgramResult>(data)
}

export type SaveWorkCellProgramAlwaysAvailableParams = {
	id: string
	alwaysAvailable: boolean
}

export type SaveWorkCellProgramAlwaysAvailableResult = {
	workCellPrograms: WorkCellProgram[]
}

export const SaveWorkCellProgramAlwaysAvailable = async (
	params: SaveWorkCellProgramAlwaysAvailableParams,
): Promise<Result<SaveWorkCellProgramAlwaysAvailableResult>> => {
	const data = await rpc("SaveWorkCellProgramAlwaysAvailable", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkCellProgramAlwaysAvailableResult>(data)
}

export type GetWorkCellProgramsParams = {
	ids?: string[]
	includeIDs?: string[]

	assemblyID?: string
	withoutCount?: boolean
	alwaysAvailable?: boolean

	limit?: number
	offset?: number
}

export type GetWorkCellProgramsResult = {
	workCellPrograms: WorkCellProgram[]
	pagination: Pagination
}

export const GetWorkCellPrograms = async (
	params: GetWorkCellProgramsParams,
): Promise<Result<GetWorkCellProgramsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkCellPrograms", "workCellPrograms", id).then((workCellProgram?: Entity) => {
			return {
				ok: true,
				result: { workCellPrograms: _.compact([workCellProgram as WorkCellProgram]) },
			} as Result<GetWorkCellProgramsResult>
		}) as Promise<Result<GetWorkCellProgramsResult>>
	}
	const data = await rpc("GetWorkCellPrograms", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetWorkCellProgramsResult>
}
