import React from "react"
import _ from "lodash"

import { SaveWorkCellServer, SaveWorkCellServerParams, newSaveWorkCellServerParams } from "@app/api"
import { useSession } from "@app/contexts"
import { newWorkCellServer, WorkCell, WorkFacility, EntityType, AlertLevel } from "@app/domain"
import { WorkCellServerForm } from "@app/forms"
import { PageContainer, Link, BreadcrumbDelimiter, Breadcrumbs, Title } from "@app/components"
import { urlTo, toUUID } from "@app/util"
import { useQuery, useRedirect, useRelation } from "@app/hooks"

export const WorkCellServerNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const workCellID = toUUID(_.get(useQuery(), "workCell"))
	let workFacilityID = toUUID(_.get(useQuery(), "workFacility"))

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const [workCell] = useRelation<WorkCell>(EntityType.WorkCell, workCellID, {
		setHTTPStatus: true,
	})

	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, workFacilityID, {
		setHTTPStatus: true,
	})

	const saveHandler = async (params: SaveWorkCellServerParams) => {
		setBusy(true)
		try {
			const resp = await SaveWorkCellServer({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workCellServers[0]")
				setRedirect(urlTo("workCellServers", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workCellServer.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	if (workCellID && !workCell) {
		return null
	}

	if (workFacilityID && !workFacility) {
		return null
	}

	if (workCell) {
		workFacilityID = workCell.workFacilityID
	}

	const defaultParams = newSaveWorkCellServerParams(
		newWorkCellServer({
			workCellID,
			workFacilityID,
		}),
	)

	return (
		<PageContainer>
			<Title>{t("workCellServer.titles.new")}</Title>

			<Breadcrumbs>
				<Link to={urlTo("workCellServers")}>{t("workCellServer.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<WorkCellServerForm
					defaultParams={defaultParams}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
