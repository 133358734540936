import React from "react"
import _ from "lodash"
import { format } from "date-fns-tz"

import En from "@app/translations/en.json"

export const useTimezones = (defaultParam?: string) => {
	const now = new Date()

	const timezones = React.useMemo(
		() =>
			_.map(En.timezones, (val, key) => {
				let offset = format(now, "XXX", { timeZone: key })
				if (offset === "Z") {
					offset = "+00:00"
				}
				return { name: `GMT${offset} ${val}`, code: key }
			}).sort((left, right) => {
				return left.name < right.name ? -1 : 1
			}),
		[],
	)

	let defaultTimezoneOption = undefined
	const timezoneOptions = React.useMemo(
		() =>
			timezones.map((c) => {
				const opt = { value: c.code, label: c.name }
				if (defaultParam === c.code) {
					defaultTimezoneOption = opt
				}
				return opt
			}),
		[defaultParam],
	)

	const timezoneLookup = _.reduce(
		timezones,
		(acc, tz) => {
			acc[tz.code] = tz.name
			return acc
		},
		{},
	)

	return {
		defaultTimezoneOption,
		timezoneLookup,
		timezoneOptions,
		timezones,
	}
}
