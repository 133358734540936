import _ from "lodash"
import { parseISO, getUnixTime } from "date-fns"

import Config from "@app/config"
import { SaveTagParams } from "@app/api"
import type { Attribute, Shipment, TemplateField } from "@app/domain"
import { fromUUID } from "@app/util"

export type Tag = {
	id: string
	assemblyID: string
	customerID: string
	jobID: string
	releaseItemID: string
	releaseID: string

	attributes: Attribute[]
	color: TagColor
	controlCodes: string
	customerReleaseID: string
	description: string
	locked: boolean
	metric: boolean
	quantity: number
	referenceID: string
	templateFields: TemplateField[]
	unitEnd: number
	unitStart: number
	weight: number | undefined

	shipments: Shipment[]

	updated: string
	created: string
}

export const newTag = (opts?: SaveTagParams): Tag => {
	return _.assign(
		{
			id: "",
			assemblyID: "",
			customerID: "",
			jobID: "",
			releaseItemID: "",
			releaseID: "",

			attributes: [],
			color: TagColor.None,
			controlCodes: "",
			customerReleaseID: "",
			description: "",
			locked: false,
			metric: false,
			quantity: 1,
			referenceID: "",
			templateFields: [],
			unitEnd: 1,
			unitStart: 1,
			weight: undefined,

			shipments: [],

			created: "",
			updated: "",
		},
		opts,
	)
}

export enum TagColor {
	None = "None",
	Red = "Red",
	Pink = "Pink",
	Orange = "Orange",
	Yellow = "Yellow",
	Green = "Green",
	Blue = "Blue",
	Purple = "Purple",
	White = "White",
}

export class TagUtils {
	static id(tag?: Tag): string {
		if (tag) {
			return _.toUpper(tag.referenceID)
		}
		return ""
	}

	static pngURL(tag: Tag | undefined, tenantID: number, unit = 1): string {
		if (!tag) {
			return ""
		}
		let tid: number | string = tenantID
		if (tenantID === 0 && tag.referenceID[0] !== "T") {
			tid = tag.referenceID.split("T")[0]
		}
		return `/f/blobs/${tid}/${fromUUID(
			tag.id,
		)}.png?type=TagPNG&unit=${unit}&updated=${TagUtils.updated(tag)}`
	}

	static pdfURL(tag: Tag | undefined, tenantID: number): string {
		if (!tag) {
			return ""
		}
		return `/f/blobs/${tenantID}/${fromUUID(tag.id)}.pdf?type=TagPDF&updated=${TagUtils.updated(
			tag,
		)}`
	}

	static url(tag: Tag | undefined, item: number, vars: { [key: string]: string | null }): string {
		if (tag) {
			const result = `${Config.baseURL}/t/${TagUtils.identifier(tag, item)}`
			const qs = _.reduce(
				vars,
				(acc: string[], v, k) => {
					if (_.isNil(v)) {
						acc.push(k)
					} else {
						acc.push(`${k}=${encodeURIComponent(v)}`)
					}
					return acc
				},
				[],
			)
			if (!_.isEmpty(qs)) {
				return `${result}?${qs.join("&")}`
			}
			return result
		}
		return ""
	}

	static identifier(tag: Tag | undefined, item: number): string {
		if (tag) {
			return `${tag.referenceID}-${item}`
		}
		return ""
	}

	static fontSize(t?: TemplateField): string {
		if (_.some(_.get(t, "options"), (o) => o === "FontSizeSmall")) {
			return "1.25rem"
		} else if (_.some(_.get(t, "options"), (o) => o === "FontSizeLarge")) {
			return "1.75rem"
		}
		return "1.5rem"
	}

	static lineHeight(t?: TemplateField): string {
		if (_.some(_.get(t, "options"), (o) => o === "FontSizeSmall")) {
			return "1.375rem"
		} else if (_.some(_.get(t, "options"), (o) => o === "FontSizeLarge")) {
			return "1.875rem"
		}
		return "1.625rem"
	}

	static bottom(t?: TemplateField): boolean {
		return _.some(_.get(t, "options"), (o) => o === "Bottom")
	}

	static footer(t?: TemplateField): boolean {
		return _.some(_.get(t, "options"), (o) => o === "Footer")
	}

	static boxed(t?: TemplateField): boolean {
		return _.some(_.get(t, "options"), (o) => o === "Boxed")
	}

	static top(t?: TemplateField): boolean {
		return _.some(_.get(t, "options"), (o) => o === "Top")
	}

	static showLabel(t?: TemplateField): boolean {
		return _.some(_.get(t, "options"), (o) => o === "ShowLabel")
	}

	static updated(v?: Tag): string {
		if (v && v.updated) {
			const t = parseISO(v.updated)
			return getUnixTime(t).toString()
		}
		return ""
	}

	static processAttributes(
		tag: Tag | undefined,
		item: number,
		t: (v: string) => string,
	): Attribute[] {
		if (!tag) {
			return []
		}
		const units = tag.metric ? "metric" : "imperial"
		const now = new Date()
		return _.chain(tag.attributes)
			.concat(
				_.map(
					{
						"color": tag.color,
						"generated-time": now.toLocaleTimeString(),
						"generated-date": now.toLocaleDateString(),
						"description": tag.description,
						"controlCodes": tag.controlCodes,
						"quantity": tag.quantity,
						item,
						"tag-id": tag.referenceID,
						"weight": `${tag.weight || "n/a"} ${t(`units.${units}.weightAbbr`)}`,
					},
					(value, name) => {
						return { name, value: value.toString() }
					},
				),
			)
			.sortBy("name")
			.value()
	}

	static processTemplateFields(
		tag: Tag | undefined,
		item: number,
		t: (v: string) => string,
	): TemplateField[] {
		if (!tag) {
			return []
		}
		const attributes = this.processAttributes(tag, item, t)
		return _.map(tag.templateFields, (templateField) => {
			let label = _.get(templateField, "label", "")
			let value = _.get(templateField, "value", "")
			_.each(attributes, (attr) => {
				label = label.replaceAll(`{{${attr.name}}}`, attr.value)
				value = value.replaceAll(`{{${attr.name}}}`, attr.value)
			})
			return { label, value, options: templateField.options }
		})
	}
}
