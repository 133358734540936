import _ from "lodash"
import { differenceInSeconds } from "date-fns"

import type { Result } from "@app/api"
import { EntityType } from "@app/domain"
import type { Entity, TrashedItem } from "@app/domain"

type cacheEntry = {
	entity: Entity
	type: EntityType
	created: number
}

let extendedTTL = false

const memoryStore: { [key: string]: cacheEntry } = {}

class cache {
	extendedTTL(v: boolean) {
		extendedTTL = v
	}

	set(entity: Entity, type: EntityType): void {
		memoryStore[entity.id] = {
			entity: entity,
			type: type,
			created: Date.now(),
		}
	}

	unset(id: string): void {
		delete memoryStore[id]
	}

	scan(data: Result<unknown>): void {
		if (!data.ok) {
			return
		}
		_.each(
			{
				"assemblies": EntityType.Assembly,
				"contacts": EntityType.Contact,
				"customers": EntityType.Customer,
				"documents": EntityType.Document,
				"jobs": EntityType.Job,
				"releases": EntityType.Release,
				"settings": EntityType.Setting,
				"shipments": EntityType.Shipment,
				"tags": EntityType.Tag,
				"tags[0].shipments": EntityType.Shipment,
				"users": EntityType.User,
				"workCellPrograms": EntityType.WorkCellProgram,
				"workCells": EntityType.WorkCell,
				"workCellServers": EntityType.WorkCellServer,
				"workEvents": EntityType.WorkEvent,
				"workFacilities": EntityType.WorkFacility,
				"workResources": EntityType.WorkResource,
			},
			(entityType, key) => {
				_.get(data, `result.${key}`, []).forEach((v: Entity) => {
					this.set(v, entityType)
				})
			},
		)
		_.get(data, "result.trashedItems", []).forEach((v: TrashedItem) => {
			this.unset(v.id)
		})
	}

	get(id = ""): Entity | undefined {
		if (!_.isEmpty(id)) {
			const result = _.get(memoryStore, id)
			if (result) {
				const ttl = extendedTTL ? 600 : 10
				if (differenceInSeconds(Date.now(), result.created) < ttl) {
					return result.entity
				}
			}
		}
		return undefined
	}
}

export const Cache = new cache()
