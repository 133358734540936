import React from "react"
import _ from "lodash"
import { Manager, Reference, Popper } from "react-popper"
import { format, startOfDay, endOfDay, isSameDay } from "date-fns"
import { useDebounce } from "usehooks-ts"
import clsx from "clsx"

import { Label, Icon, Button as ButtonCmpnt } from "@app/components"
import { useSession } from "@app/contexts"
import {
	subtractBrowserTimezoneOffset,
	addBrowserTimezoneOffset,
	browserTimezone,
	formatTimezone,
	getBrowserTimezoneOffset,
} from "@app/util"

const headerClassName = "bg-yellow-500 font-medium"

const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]
const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"]

interface IDateInputProps {
	clearable?: boolean
	defaultValue?: string | null
	value?: Date[]
	disabled?: boolean
	error?: string
	hint?: string
	label?: string
	name: string
	onChange: (dates?: Date[]) => void
	withTime?: boolean
	hideInputBox?: boolean
	timezone?: string
	endOfDay?: boolean
}

export const MultiDateInput: React.FC<IDateInputProps> = (props) => {
	const {
		clearable = true,
		disabled = false,
		label,
		hint,
		name,
		error,
		defaultValue = "",
		value,
		onChange,
		hideInputBox = false,
		timezone = browserTimezone,
		endOfDay = false,
	} = props
	const { t } = useSession()

	const dates: Date[] | undefined = defaultValue?.split(",").every((v) => Date.parse(v))
		? defaultValue?.split(",").map((v) => new Date(v))
		: undefined

	const errorMessage = error?.replace("$field", label || t("validation.this"))

	return (
		<>
			{_.isEmpty(label) || hideInputBox ? null : <Label name={name}>{label}</Label>}
			<MultiCalendar
				clearable={clearable}
				disabled={disabled}
				defaultValue={dates}
				value={value}
				label={label}
				onChange={onChange}
				timezone={timezone}
				hideInputBox={hideInputBox}
				endOfDay={endOfDay}
			></MultiCalendar>
			{!_.isEmpty(errorMessage) ? (
				<div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
					<svg className="h-5 w-5 text-red-500" fill="currentColor" viewBox="0 0 20 20">
						<path
							fillRule="evenodd"
							d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
							clipRule="evenodd"
						/>
					</svg>
				</div>
			) : null}
			{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
				<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203;</p>
			) : null}
			{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
				<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203;</p>
			) : null}
		</>
	)
}

const MultiCalendar: React.FC<{
	value?: Date[]
	defaultValue?: Date[]
	onChange: (dates?: Date[]) => void
	hideInputBox?: boolean
	label?: string
	timezone?: string
	disabled?: boolean
	clearable?: boolean
	endOfDay?: boolean
}> = (props) => {
	const {
		clearable,
		onChange,
		value,
		label,
		defaultValue = [],
		disabled,
		hideInputBox = false,
		timezone = browserTimezone,
		endOfDay: endOfDayBool = false,
	} = props
	const popupRef = React.useRef<HTMLElement>()
	const inputRef = React.useRef<HTMLInputElement>(null)

	const [showCalendar, setShowCalendar] = React.useState(false)
	const debouncedShowCalendar = useDebounce<boolean>(showCalendar, 20)

	const [dates, setDates] = React.useState<Date[]>(defaultValue)

	React.useEffect(() => {
		if (value) {
			dateChangeHandler(value)
		}
	}, [value])

	const dateChangeHandler = (datesIn?: Date | Date[]) => {
		if (datesIn === undefined) {
			setDates([])
			return
		}
		const newValue = Array.isArray(datesIn)
			? [...datesIn]
			: dates.some((d) => isSameDay(d, datesIn))
			? dates.filter((d) => !isSameDay(d, datesIn))
			: [...dates, datesIn]
		setDates(newValue)
		if (inputRef && inputRef.current) {
			inputRef.current.value = newValue
				.reduce((acc, date) => acc + format(date, "yyyy/MM/dd") + ", ", "")
				.slice(0, -2)
		}
	}

	const startOfDayInTimezone = (date: Date, timezone: string): Date =>
		addBrowserTimezoneOffset(startOfDay(subtractBrowserTimezoneOffset(date, timezone)), timezone)
	const endOfDayInTimezone = (date: Date, timezone: string): Date =>
		addBrowserTimezoneOffset(endOfDay(subtractBrowserTimezoneOffset(date, timezone)), timezone)
	const datePickerTimeTransform = endOfDayBool ? endOfDayInTimezone : startOfDayInTimezone

	React.useEffect(() => {
		if (!dates) {
			return
		}
		onChange(dates.map((d) => datePickerTimeTransform(d, timezone)))
	}, [dates])

	React.useEffect(() => {
		const mouseDownListener = (e: MouseEvent) => {
			if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
				setShowCalendar(false)
			}
		}

		document.addEventListener("mousedown", mouseDownListener)

		return () => {
			document.removeEventListener("mousedown", mouseDownListener)
		}
	}, [showCalendar])

	React.useEffect(() => {
		if (!dates) {
			return
		}
		const zonedDates = dates.map((d) => subtractBrowserTimezoneOffset(d, timezone))
		dateChangeHandler(zonedDates)
	}, [timezone])

	const timezoneElt = (
		<>
			{getBrowserTimezoneOffset(timezone) !== 0 && (
				<div className={clsx("text-gray-500 text-xs float-right", hideInputBox && "mt-1")}>
					{formatTimezone(timezone)}
				</div>
			)}
		</>
	)
	return (
		<Manager>
			<Reference>
				{({ ref }) => (
					<>
						<div className={clsx("flex gap-x-2", hideInputBox && "flex-col")} ref={ref}>
							{hideInputBox && (
								<>
									<ButtonCmpnt
										label={label ?? "Select Dates"}
										small
										disabled={disabled}
										onClick={(e) => {
											e.preventDefault()
											setShowCalendar(!debouncedShowCalendar)
										}}
										icon="Calendar"
									/>
									{timezoneElt}
								</>
							)}
							{!hideInputBox && (
								<div className="relative w-full">
									<button
										disabled={disabled}
										type="button"
										className="absolute inset-y-0 left-0 px-3 text-gray-500 flex items-center"
										onClick={() => setShowCalendar((prev) => !prev)}
									>
										<Icon name="Calendar" />
									</button>
									<input
										name="date"
										autoComplete="off"
										disabled={disabled}
										ref={inputRef}
										className="w-full bg-white p-2 pl-8 block border border-gray-400 disabled:text-gray-500 disabled:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
										type="text"
										onFocus={() => {
											setShowCalendar(true)
										}}
										onChange={() => {
											setDates(
												inputRef?.current?.value
													.split(",")
													.filter((v) => v.split("/").length === 3 && Date.parse(v))
													.map((v) => new Date(v)) ?? [],
											)
										}}
										defaultValue={dates
											.reduce((acc, date) => acc + format(date, "yyyy/MM/dd") + ", ", "")
											.slice(0, -2)}
									/>
									{dates.length > 0 && clearable ? (
										<button
											disabled={disabled}
											type="button"
											className="absolute inset-y-0 right-0 px-3 text-gray-400 flex items-center hover:text-gray-700 focus:outline-none"
											onClick={() => dateChangeHandler([])}
										>
											<Icon name="Times" />
										</button>
									) : null}
								</div>
							)}
						</div>
						{!hideInputBox && timezoneElt}
					</>
				)}
			</Reference>
			<Popper placement="bottom-start" innerRef={(node) => (popupRef.current = node)}>
				{({ ref, style, placement }) =>
					showCalendar ? (
						<Calendar
							dates={dates}
							onChange={dateChangeHandler}
							onClose={() => setShowCalendar(false)}
							placement={placement}
							style={style}
							ref={ref as React.Ref<HTMLDivElement>}
						/>
					) : null
				}
			</Popper>
		</Manager>
	)
}

type SelectionState = "date" | "month" | "year"

interface ICalendarProps {
	dates?: Date[]
	onChange: (dates: Date | Date[]) => void
	onClose: () => void
	placement: string
	ref: React.Ref<HTMLDivElement>
	style: React.CSSProperties
}

// eslint-disable-next-line react/display-name
const Calendar: React.FC<ICalendarProps> = React.forwardRef<HTMLDivElement, ICalendarProps>(
	(props, ref) => {
		const { placement, style, dates = [], onChange } = props

		const [selection, setSelection] = React.useState<SelectionState>("date")
		const [datesClone, setDatesClone] = React.useState(new Date())

		let selectionComponent = null
		switch (selection) {
			case "date":
				selectionComponent = (
					<DateSelection
						dates={dates}
						innerDate={datesClone}
						onChange={(dates) => onChange(dates)}
						onChangeInnerDate={setDatesClone}
						onChangeSelectionState={setSelection}
					/>
				)
				break
			case "month":
				selectionComponent = (
					<MonthSelection
						dates={dates}
						innerDate={datesClone}
						onChangeInnerDate={setDatesClone}
						onChangeSelectionState={setSelection}
					/>
				)
				break
			case "year":
				selectionComponent = (
					<YearSelection
						dates={dates}
						innerDate={datesClone}
						onChangeInnerDate={setDatesClone}
						onChangeSelectionState={setSelection}
					/>
				)
				break
		}

		return (
			<div
				className="z-50 bg-white relative shadow-xl max-w-xs w-64 p-2"
				ref={ref}
				data-placement={placement}
				style={style}
			>
				{selectionComponent}
			</div>
		)
	},
)

interface ISelectionProps {
	dates: Date[]
	innerDate: Date
	onChangeInnerDate: (dates: Date) => void
}

interface DateSelectionProps extends ISelectionProps {
	onChange: (dates: Date | Date[]) => void
	onChangeSelectionState: (state: SelectionState) => void
}

interface MonthYearSelectionProps extends ISelectionProps {
	onChangeSelectionState: (state: SelectionState) => void
}

const DateSelection: React.FC<DateSelectionProps> = (props) => {
	const { onChangeInnerDate, onChangeSelectionState, onChange, innerDate } = props
	const { t } = useSession()

	const dateCompare = (date: number, dateClone: Date, propDate: Date): boolean => {
		if (
			date === propDate.getDate() &&
			dateClone.getMonth() === propDate.getMonth() &&
			dateClone.getFullYear() === propDate.getFullYear()
		) {
			return true
		}
		return false
	}

	const handleDateSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const date = parseInt(e.currentTarget.innerHTML)
		const selectedDate = new Date(innerDate.valueOf())
		selectedDate.setDate(date)
		onChange(selectedDate)
	}

	return (
		<div
			className="divide-x divide-white"
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
				gridTemplateRows: "2rem auto",
				alignItems: "stretch",
			}}
		>
			<Button
				className={headerClassName}
				onClick={() => {
					onChangeInnerDate(prevMonth(innerDate))
				}}
				chevron="left"
			/>

			<Button
				className={headerClassName}
				style={{ gridColumn: "2/5" }}
				onClick={() => {
					onChangeSelectionState("month")
				}}
			>
				{t(`common.months.${months[innerDate.getMonth()]}`)}
			</Button>

			<Button
				className={headerClassName}
				style={{ gridColumn: "5/7" }}
				onClick={() => {
					onChangeSelectionState("year")
				}}
			>
				{innerDate.getFullYear()}
			</Button>

			<Button
				className={headerClassName}
				onClick={() => {
					onChangeInnerDate(nextMonth(innerDate))
				}}
				chevron="right"
			/>

			{days.map((day) => (
				<div
					key={(200 + day).toString()}
					className="p-1 text-sm text-center text-gray-500 select-none"
				>
					{_.capitalize(t(`common.days.${day}`).substring(0, 1))}
				</div>
			))}

			{_.range(beginningDayOfWeek(innerDate)).map((i) => (
				<div key={(400 + i).toString()}></div>
			))}

			{_.range(1, daysInMonth(innerDate.getMonth(), innerDate.getFullYear()) + 1).map((date) => (
				<Button
					type="button"
					key={(300 + date).toString()}
					className={`hover:bg-yellow-300 p-1 text-sm font-normal ${
						props.dates.some((d) => dateCompare(date, innerDate, d))
							? "bg-yellow-500 font-normal"
							: ""
					}`}
					onClick={handleDateSelect}
					style={{ textAlign: "center" }}
				>
					{date}
				</Button>
			))}
		</div>
	)
}

/**
 * Month Selection Component
 * @param props
 */
const MonthSelection: React.FC<MonthYearSelectionProps> = (props) => {
	const { onChangeInnerDate, onChangeSelectionState } = props
	const { t } = useSession()

	const dateWithMonth = (date: Date, month: number): Date => {
		const dateClone = new Date(date.valueOf())
		dateClone.setMonth(month)
		return dateClone
	}

	return (
		<div
			className="h-48"
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr 1fr",
				gridTemplateRows: "2rem auto",
				alignItems: "stretch",
			}}
		>
			<div className="flex divide-x divide-white" style={{ gridColumn: "1/5" }}>
				<Button
					className={headerClassName}
					chevron="left"
					onClick={() => {
						onChangeInnerDate(prevYear(props.innerDate))
					}}
				/>
				<Button
					className={headerClassName}
					onClick={() => {
						onChangeSelectionState("year")
					}}
				>
					{props.innerDate.getFullYear()}
				</Button>
				<Button
					className={headerClassName}
					chevron="right"
					onClick={() => {
						onChangeInnerDate(nextYear(props.innerDate))
					}}
				/>
			</div>
			{months.map((month, index) => (
				<Button
					key={index}
					onClick={() => {
						{
							onChangeInnerDate(dateWithMonth(props.innerDate, index))
							onChangeSelectionState("date")
						}
					}}
				>
					{t(`common.months.${month}`).substring(0, 3)}
				</Button>
			))}
		</div>
	)
}

const YearSelection: React.FC<MonthYearSelectionProps> = (props) => {
	const { onChangeInnerDate, onChangeSelectionState } = props

	const dateWithYear = (date: Date, year: number): Date => {
		const dateClone = new Date(date.valueOf())
		dateClone.setFullYear(year)
		return dateClone
	}

	const minYear = () => props.innerDate.getFullYear() - 6
	const maxYear = () => minYear() + 12

	return (
		<div
			className="h-48"
			style={{
				display: "grid",
				gridTemplateColumns: "1fr 1fr 1fr 1fr",
				gridTemplateRows: "2rem auto",
				alignItems: "stretch",
			}}
		>
			<div className="flex divide-x divide-white" style={{ gridColumn: "1/5" }}>
				<Button
					className={headerClassName}
					chevron="left"
					onClick={() => {
						onChangeInnerDate(prev12Year(props.innerDate))
					}}
				/>
				<Button className={headerClassName}>{`${minYear()} - ${maxYear() - 1}`}</Button>
				<Button
					className={headerClassName}
					chevron="right"
					onClick={() => {
						onChangeInnerDate(next12Year(props.innerDate))
					}}
				/>
			</div>
			{_.range(minYear(), maxYear()).map((year) => (
				<Button
					key={year}
					onClick={() => {
						{
							onChangeInnerDate(dateWithYear(props.innerDate, year))
							onChangeSelectionState("month")
						}
					}}
				>
					{year}
				</Button>
			))}
		</div>
	)
}

const beginningDayOfWeek = (date: Date): number => {
	const dateClone = date
	dateClone.setDate(1)
	return dateClone.getDay()
}

const daysInMonth = (month: number, year: number) => {
	switch (month) {
		case 0:
		case 2:
		case 4:
		case 6:
		case 7:
		case 9:
		case 11:
			return 31
		case 1:
			return isLeapYear(year) ? 29 : 28
		default:
			return 30
	}
}

const isLeapYear = (year: number): boolean => {
	return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

const nextMonth = (date: Date): Date => {
	const dateClone = new Date(date.valueOf())
	if (date.getMonth() === 11) {
		dateClone.setFullYear(date.getFullYear() + 1)
		dateClone.setMonth(0)
	} else {
		dateClone.setMonth(date.getMonth() + 1)
	}
	return dateClone
}

const prevMonth = (date: Date): Date => {
	const dateClone = new Date(date.valueOf())
	if (date.getMonth() === 0) {
		dateClone.setFullYear(date.getFullYear() - 1)
		dateClone.setMonth(11)
	} else {
		dateClone.setMonth(date.getMonth() - 1)
	}
	return dateClone
}

const increaseYear = (date: Date, step: number) => {
	const dateClone = new Date(date.valueOf())
	dateClone.setFullYear(date.getFullYear() + step)
	return dateClone
}

const prevYear = (date: Date) => increaseYear(date, -1)
const nextYear = (date: Date) => increaseYear(date, 1)
const prev12Year = (date: Date) => increaseYear(date, -12)
const next12Year = (date: Date) => increaseYear(date, 12)

const buttonClassName =
	"hover:bg-yellow-300 p-1 text-sm items-center justify-center focus:outline-none"

const Button: React.FC<{
	chevron?: "right" | "left"
	children?: React.ReactNode
	className?: string
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	style?: React.CSSProperties
	type?: string
}> = (props) => {
	const { chevron, className, onClick, style } = props
	let { children } = props

	if (chevron === "left") {
		children = <Icon name="ChevronLeft" size="xs" />
	} else if (chevron === "right") {
		children = <Icon name="ChevronRight" size="xs" />
	}

	return (
		<button
			className={`${buttonClassName} ${className} ${chevron ? "w-10" : "flex-grow"} `}
			type="button"
			style={style}
			onClick={onClick}
		>
			{children}
		</button>
	)
}
