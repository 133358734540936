import React from "react"

import { Await, PageContainer, WorkCellProgramDetails } from "@app/components"
import { WorkCellProgramHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkCellProgram } from "@app/domain"

export const WorkCellProgramShowPage: React.FC = () => {
	const [workCellProgram, workCellProgramLoading] = useEntity<WorkCellProgram>(
		EntityType.WorkCellProgram,
	)

	return (
		<PageContainer>
			<WorkCellProgramHeader workCellProgram={workCellProgram} view="show" />
			<Await
				loading={workCellProgramLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkCellProgramDetails workCellProgram={workCellProgram} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
