import React from "react"
import _ from "lodash"

import {
	JobSelect,
	WorkFacilitySelect,
	Toggle,
	FormActions,
	Button,
	Input,
	DateTimeInput,
} from "@app/components"
import { useSession } from "@app/contexts"
import { SaveShipmentParams } from "@app/api"
import { AddressesForm, ContactablesForm, AttributesForm } from "@app/forms"
import { AlertLevel, EntityType, WorkFacility } from "@app/domain"
import { useRelation, useFormHelpers } from "@app/hooks"

interface IShipmentGeneralFormProps {
	busy?: boolean
	disabled?: boolean
	defaultParams: SaveShipmentParams
	errors: { [key: string]: string }
	onSave: (params: SaveShipmentParams) => void
	referenceID?: string
}

export const ShipmentGeneralForm: React.FC<IShipmentGeneralFormProps> = (props) => {
	const { referenceID, defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()

	const { contactablesHandler, dateHandler, inputHandler, params, selectHandler, valueHandler } =
		useFormHelpers<SaveShipmentParams>(defaultParams)
	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, params?.workFacilityID)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("shipment.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					{referenceID ? (
						<Input
							disabled={disabled || busy}
							readOnly={true}
							defaultValue={referenceID}
							hint={t("common.hints.referenceID")}
							label={t("common.labels.referenceID")}
							name="referenceID"
							error={_.get(errors, "referenceID")}
							onChange={inputHandler("referenceID")}
							type="text"
						/>
					) : null}
					<JobSelect
						autoFocus={_.isEmpty(params.jobID)}
						defaultValue={_.get(params, "jobID")}
						disabled={disabled || busy}
						error={_.get(errors, "jobID")}
						label={t("shipment.labels.jobID")}
						name="jobID"
						onChange={selectHandler("jobID")}
					/>
					<WorkFacilitySelect
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={disabled || busy}
						error={_.get(errors, "workFacilityID")}
						label={t("shipment.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID")}
					/>
					<Input
						autoFocus={!_.isEmpty(params.jobID) && _.isEmpty(params.name)}
						autoComplete="off"
						defaultValue={params.name}
						disabled={disabled || busy}
						error={_.get(errors, "name")}
						label={t("release.labels.name")}
						name="name"
						onChange={inputHandler("name")}
						type="text"
					/>
					{referenceID ? (
						<Toggle
							label={t("common.labels.metric")}
							defaultChecked={params.metric}
							disabled={disabled || busy}
							hint={t("shipment.hints.metric")}
							error={_.get(errors, "metric")}
							onChange={valueHandler("metric")}
						/>
					) : null}
					<DateTimeInput
						defaultValue={_.get(params, "shipped", null)}
						disabled={disabled || busy}
						error={_.get(errors, "referenceID")}
						hint={t("shipment.hints.shipped")}
						label={t("shipment.labels.shipped")}
						name="shipped"
						onChange={dateHandler("shipped")}
						timezone={workFacility?.timezone}
					/>
					<DateTimeInput
						defaultValue={_.get(params, "delivered", null)}
						disabled={disabled || busy}
						error={_.get(errors, "referenceID")}
						hint={t("shipment.hints.delivered")}
						label={t("shipment.labels.delivered")}
						name="delivered"
						onChange={dateHandler("delivered")}
						timezone={workFacility?.timezone}
					/>
					<AttributesForm
						busy={busy}
						defaultParams={_.get(params, "attributes")}
						disabled={disabled}
						errors={errors}
						errorsKey="attributes"
						onChange={valueHandler("attributes")}
					/>
				</div>

				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<AddressesForm
						busy={busy}
						defaultParams={_.get(params, "addresses", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="addresses"
						onChange={valueHandler("addresses")}
					/>
				</div>

				<div className="sm:ml-8 sm:mt-1 sm:w-1/2 lg:w-1/3">
					<ContactablesForm
						busy={busy}
						defaultParams={_.get(params, "contactables", [])}
						disabled={disabled}
						errors={errors}
						errorsKey="contactables"
						onChange={contactablesHandler}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("shipment.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
