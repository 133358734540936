export enum ScanEvent {
	BuildFinish = "BuildFinish",
	BuildStart = "BuildStart",
	Inventory = "Inventory",
	Load = "Load",
	Move = "Move",
	Noop = "Noop",
	Print = "Print",
}

export type ScanLog = {
	id: string
	tagID?: string
	userID: string
	workFacilityID: string

	rawData: string
	scanEvent: ScanEvent

	created: string
}
