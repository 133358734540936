import React from "react"
import _ from "lodash"

import { SplashLayout } from "@app/layouts"
import { Logout } from "@app/api"
import { Icon, Title } from "@app/components"
import { useSession } from "@app/contexts"
import { useHistory } from "react-router-dom"

export const LogoutPage: React.FC = () => {
	const { t, setSession, handleError } = useSession()
	const history = useHistory()

	React.useEffect(() => {
		let canceled = false
		const logout = async () => {
			try {
				const resp = await Logout()
				if (canceled) {
					return
				}
				if (_.get(resp, "ok")) {
					setSession(undefined)
				}
			} catch (err) {
				handleError(err)
			}
			history.push("/login")
		}
		logout()
		return () => {
			canceled = true
		}
	}, [])

	return (
		<SplashLayout>
			<Title>{t("logout.title")}</Title>
			<div className="block text-center text-gray-700">
				<h2 className="text-2xl leading-9">
					<span>{t("logout.header")}</span>
					<Icon name="Cog" size="1x" spin={true} fixedWidth />
				</h2>
			</div>
		</SplashLayout>
	)
}
