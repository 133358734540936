import _ from "lodash"

import { AddressParams, Batch, compactIDs, ContactableParams, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { Customer, Entity, Pagination } from "@app/domain"

export type SaveCustomerParams = {
	id?: string

	addresses: AddressParams[]
	name: string
	metric: boolean

	contactables: ContactableParams[]
	removeContactables: string[]
}

export const newSaveCustomerParams = (customer?: Customer): SaveCustomerParams => {
	return {
		id: _.get(customer, "id", ""),

		addresses: _.get(customer, "addresses", []),
		name: _.get(customer, "name", ""),
		metric: _.get(customer, "metric", false),

		contactables: _.get(customer, "contactables", []),
		removeContactables: [],
	}
}

export type SaveCustomerResult = {
	customers: Customer[]
}

export const SaveCustomer = async (
	params: SaveCustomerParams,
): Promise<Result<SaveCustomerResult>> => {
	const data = await rpc("SaveCustomer", params, { minDuration: 300 })
	return ReceivedResult<GetCustomersResult>(data)
}

export type GetCustomersParams = {
	ids?: string[]
	includeIDs?: string[]

	contactID?: string

	query?: string
	withoutCount?: boolean
	withoutContactables?: boolean

	limit?: number
	offset?: number
}

export type GetCustomersResult = {
	customers: Customer[]
	pagination?: Pagination
}

export const GetCustomers = async (
	params: GetCustomersParams,
): Promise<Result<GetCustomersResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetCustomers", "customers", id).then((customer?: Entity) => {
			return {
				ok: true,
				result: { customers: _.compact([customer as Customer]) },
			} as Result<GetCustomersResult>
		}) as Promise<Result<GetCustomersResult>>
	}
	const data = await rpc("GetCustomers", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetCustomersResult>(data)
}
