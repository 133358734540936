import React from "react"

import { SaveWorkAction, SaveWorkActionParams, newSaveWorkActionParams } from "@app/api"
import { useSession } from "@app/contexts"
import { newWorkAction, AlertLevel } from "@app/domain"
import { WorkActionForm } from "@app/forms"
import { PageContainer, Link, BreadcrumbDelimiter, Breadcrumbs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { useRedirect } from "@app/hooks"

export const WorkActionNewPage: React.FC = () => {
	const { t, handleError } = useSession()
	const { redirect, setRedirect } = useRedirect()

	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})

	const saveHandler = async (params: SaveWorkActionParams) => {
		setBusy(true)
		try {
			const resp = await SaveWorkAction({ ...params })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workActions?.[0]
				setRedirect(urlTo("workActions", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workAction.notifications.success.created", { name: saved?.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	const defaultParams = newSaveWorkActionParams(newWorkAction())

	return (
		<PageContainer>
			<Title>{t("workAction.titles.new")}</Title>

			<Breadcrumbs>
				<Link to={urlTo("workActions")}>{t("workAction.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>

			<div className="max-w-auto">
				<WorkActionForm
					defaultParams={defaultParams}
					errors={errors}
					busy={busy}
					onSave={saveHandler}
				/>
			</div>
		</PageContainer>
	)
}
