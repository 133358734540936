import React from "react"
import clsx from "clsx"

import { Link, Icon, Image } from "@app/components"
import { urlTo } from "@app/util"
import { EntityColumnType, EntityType, Entity, Assembly, AssemblyType } from "@app/domain"

import type { EntityTableChildType } from "./EntityTable"

export const EntityImageTable: React.FC<EntityTableChildType> = (props: EntityTableChildType) => {
	const { entityType, fullWidth, columns, entities, renderEntityColumn } = props

	const iconColumn = columns.find((c) => c.type === EntityColumnType.Icon)
	const [iconTrue, iconFalse] = [
		iconColumn?.objectDetails?.["true"] ?? undefined,
		iconColumn?.objectDetails?.["false"] ?? undefined,
	]
	const thumbnailURLColumnName =
		columns.find((c) => c.type === EntityColumnType.ThumbnailURL)?.name ?? ""

	// Shapediver (v0.x) assembly thumbnail special case handling.
	const getEntityThumbnail = (entity: Entity) =>
		entityType === EntityType.Assembly &&
		((entity as Assembly).configuratorVersion?.[0] ?? "0") === "0"
			? `/assets/img/v0_${
					(entity as Assembly).assemblyType === AssemblyType.W1Cage ? "cage" : "grid"
			  }_thumbnail.png`
			: entity[thumbnailURLColumnName]
	return (
		<div
			className={clsx(
				fullWidth ? "w-full" : "w-fit",
				"grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6",
			)}
		>
			{entities.map((entity) => (
				<Link
					key={entity.id}
					to={urlTo(entityType, entity)}
					className="block text-center bg-gray-300 hover:bg-yellow-300 focus:outline-none focus:bg-gray-300 transition duration-150 ease-in-out"
				>
					<div className="block py-4">
						<div className="bg-white border border-gray-400 inline-flex justify-center">
							<div className="p-0.5">
								{thumbnailURLColumnName && (
									<Image
										src={getEntityThumbnail(entity)}
										loading="lazy"
										fallback={(err) => {
											return (
												<div
													className="bg-white flex justify-center items-center"
													style={
														entityType === EntityType.Assembly
															? { minHeight: "176px", minWidth: "176px" }
															: { minHeight: "176px", minWidth: "82px" }
													}
												>
													{err ? (
														<Icon className="text-gray-300 text-xl" name="QuestionCircle" />
													) : (
														<Icon className="text-gray-500 text-xl" name="Cog" spin />
													)}
												</div>
											)
										}}
										className={clsx("max-h-[176px]", "max-w-[176px]")}
									/>
								)}
							</div>
						</div>
						<div className="py-2 px-1 border-gray-400 border-b leading-5 font-medium">
							{iconColumn && iconTrue && iconFalse && (
								<Icon
									name={entity[iconColumn.name] ? iconTrue : iconFalse}
									size="sm"
									className={`mr-1 ${entity[iconColumn.name] ? "text-black" : "text-gray-500"}`}
									fixedWidth
								/>
							)}
							{"name" in entity && entity.name}
						</div>
						<div className="mt-2 px-1">
							{columns
								?.filter((column) => !column.options.hidden)
								.map((column, j) => (
									<div key={j}>
										<div className="mt-1 mr-1 text-gray-500 text-sm leading-5">{column.label}</div>
										<div className="text-sm leading-5 font-medium">
											{renderEntityColumn(entity, column, j, false)}
										</div>
									</div>
								))}
						</div>
					</div>
				</Link>
			))}
		</div>
	)
}
