import { rpc, Cache, compactIDs } from "@app/api"

import type { Result } from "@app/api"
import type { ShipmentItem, Pagination } from "@app/domain"

export type ShipmentItemParams = {
	id: string
	description: string
	item?: number
	sortOrder: number
}

export type SaveShipmentItemsParams = {
	shipmentID: string
	shipmentItems: ShipmentItemParams[]
	removeShipmentItems: string[]
}

export const SaveShipmentItems = async (
	params: SaveShipmentItemsParams,
): Promise<Result<SaveShipmentItemsResult>> => {
	const data = await rpc("SaveShipmentItems", {
		...params,
	})
	Cache.scan(data)
	return data as Result<SaveShipmentItemsResult>
}

export type SaveShipmentItemsResult = {
	shipmentItems: ShipmentItem[]
}

export type GetShipmentItemsParams = {
	ids?: string[]
	includeIDs?: string[]

	assemblyID?: string
	customerID?: string
	jobID?: string
	shipmentID?: string

	query?: string

	limit?: number
	offset?: number
	withoutCount?: boolean
}

export type GetShipmentItemsResult = {
	shipmentItems: ShipmentItem[]
	pagination: Pagination
}

export const GetShipmentItems = async (
	params: GetShipmentItemsParams,
): Promise<Result<GetShipmentItemsResult>> => {
	const data = await rpc("GetShipmentItems", {
		limit: 200,
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetShipmentItemsResult>
}
