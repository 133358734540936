import _ from "lodash"

import {
	AddressParams,
	Batch,
	compactIDs,
	ContactableParams,
	EmailParams,
	PhoneNumberParams,
	ReceivedResult,
	rpc,
} from "@app/api"

import type { Result } from "@app/api"
import type { Contact, Pagination, Entity } from "@app/domain"

export type SaveContactParams = {
	id?: string

	addresses: AddressParams[]
	emails: EmailParams[]
	firstName: string
	lastName: string
	phoneNumbers: PhoneNumberParams[]
	title: string

	contactables?: ContactableParams[]
}

export const newSaveContactParams = (contact?: Contact): SaveContactParams => {
	return {
		id: _.get(contact, "id", ""),

		addresses: _.get(contact, "addresses", []),
		emails: _.get(contact, "emails", []),
		phoneNumbers: _.get(contact, "phoneNumbers", []),
		firstName: _.get(contact, "firstName", ""),
		lastName: _.get(contact, "lastName", ""),
		title: _.get(contact, "title", ""),

		contactables: [],
	}
}

export type SaveContactResult = {
	contacts: Contact[]
}

export const SaveContact = async (
	params: SaveContactParams,
): Promise<Result<SaveContactResult>> => {
	const data = await rpc("SaveContact", params, { minDuration: 300 })
	return ReceivedResult<SaveContactResult>(data)
}

export type GetContactsParams = {
	ids?: string[]
	includeIDs?: string[]
	excludeIDs?: string[]

	customerID?: string
	jobID?: string
	releaseID?: string
	shipmentID?: string
	workFacilityID?: string

	query?: string
	withoutCount?: boolean
	withoutContactables?: boolean

	limit?: number
	offset?: number
}

export type GetContactsResult = {
	contacts: Contact[]
	pagination?: Pagination
}

export const GetContacts = async (
	params: GetContactsParams,
): Promise<Result<GetContactsResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetContacts", "contacts", id).then((contact?: Entity) => {
			return {
				ok: true,
				result: { contacts: _.compact([contact as Contact]) },
			} as Result<GetContactsResult>
		}) as Promise<Result<GetContactsResult>>
	}
	const data = await rpc("GetContacts", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetContactsResult>(data)
}
