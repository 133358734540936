import _ from "lodash"

export enum UserPermission {
	ApproveWorkCellPrograms = "ApproveWorkCellPrograms",
	JumpLineWorkCellPrograms = "JumpLineWorkCellPrograms",
	LoadWorkCellProgramsFromDisk = "LoadWorkCellProgramsFromDisk",
	ModifyUsers = "ModifyUsers",
	ModifyUserTenants = "ModifyUserTenants",
	ModifyWorkCellPrograms = "ModifyWorkCellPrograms",
	RunWorkCellPrograms = "RunWorkCellPrograms",
	SkipTagWorkCellPrograms = "SkipTagWorkCellPrograms",
}

export type User = {
	id: string

	disabled: boolean
	email: string
	firstName: string
	lastName: string
	permissions: string[]
	timezone: string

	created: string
	updated: string
}

export const UserUtils = {
	name: (v?: User): string => {
		if (v) {
			return _.trim([v.firstName, v.lastName].join(" "))
		}
		return ""
	},
}

export const newUser = (): User => {
	return {
		id: "",

		disabled: false,
		email: "",
		firstName: "",
		lastName: "",
		permissions: [],
		timezone: "",

		created: "",
		updated: "",
	}
}
