import React from "react"
import _ from "lodash"

import {
	Button,
	DateTimeInput,
	FormActions,
	Input,
	JobSelect,
	OptionType,
	Select,
	Toggle,
	WorkFacilitySelect,
} from "@app/components"
import { useSession } from "@app/contexts"
import { MaterialItemsForm } from "@app/forms"
import { SaveMaterialParams } from "@app/api"
import { AlertLevel, EntityType, WorkFacility } from "@app/domain"
import { useRelation, useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IMaterialFormProps {
	busy?: boolean
	disabled?: boolean
	defaultParams: SaveMaterialParams
	errors: { [key: string]: string }
	onSave: (params: SaveMaterialParams) => void
	id?: string
}

export const MaterialForm: React.FC<IMaterialFormProps> = (props) => {
	const { id, defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { dateHandler, inputHandler, params, selectHandler, valueHandler } =
		useFormHelpers<SaveMaterialParams>(defaultParams)

	const [workFacility] = useRelation<WorkFacility>(EntityType.WorkFacility, params?.workFacilityID)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("material.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const materialStatuses = _.map(En.materialStatuses, (val, key) => {
		return { name: val, code: key }
	}).sort((left, right) => {
		return left.name < right.name ? -1 : 1
	})

	let defaultMaterialStatus: OptionType | undefined = undefined
	const materialStatusOptions = materialStatuses.map((c) => {
		const opt = { value: c.code, label: c.name }
		if (_.get(params, "status", "") === c.code || !defaultMaterialStatus) {
			defaultMaterialStatus = opt
		}
		return opt
	})

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row gap-x-4">
				<div className="sm:w-1/2 lg:w-1/4">
					<WorkFacilitySelect
						defaultValue={_.compact([_.get(params, "workFacilityID")])}
						disabled={disabled || busy}
						error={_.get(errors, "workFacilityID")}
						label={t("shipment.labels.workFacilityID")}
						name="workFacilityID"
						onChange={selectHandler("workFacilityID")}
					/>
					<JobSelect
						autoFocus={_.isEmpty(params.jobID)}
						defaultValue={_.get(params, "jobID")}
						disabled={disabled || busy}
						error={_.get(errors, "jobID")}
						label={t("material.labels.jobID")}
						name="jobID"
						onChange={selectHandler("jobID")}
					/>
					{id ? (
						<Toggle
							label={t("common.labels.metric")}
							defaultChecked={params.metric}
							disabled={disabled || busy}
							hint={t("material.hints.metric")}
							error={_.get(errors, "metric")}
							onChange={valueHandler("metric")}
						/>
					) : null}
					<Input
						autoFocus={!_.isEmpty(params.jobID) && _.isEmpty(params.controlCode)}
						autoComplete="off"
						defaultValue={params.controlCode}
						disabled={disabled || busy}
						error={_.get(errors, "controlCode")}
						label={t("material.labels.controlCode")}
						name="controlCode"
						onChange={inputHandler("controlCode")}
						type="text"
					/>

					<Input
						defaultValue={params.supplierName}
						disabled={disabled || busy}
						error={_.get(errors, "supplierName")}
						label={t("material.labels.supplierName")}
						name="supplierName"
						onChange={inputHandler("supplierName")}
						type="text"
					/>
					<Input
						defaultValue={params.supplierReferenceID}
						disabled={disabled || busy}
						error={_.get(errors, "supplierReferenceID")}
						label={t("material.labels.supplierReferenceID")}
						name="supplierReferenceID"
						onChange={inputHandler("supplierReferenceID")}
						type="text"
					/>
					<DateTimeInput
						defaultValue={_.get(params, "received")}
						disabled={disabled || busy}
						error={_.get(errors, "received")}
						hint={t("material.hints.received")}
						label={t("material.labels.received")}
						name="received"
						onChange={dateHandler("received")}
						timezone={workFacility?.timezone}
					/>
					<Select
						defaultValue={defaultMaterialStatus}
						disabled={disabled || busy}
						isSearchable={true}
						error={_.get(errors, "status")}
						label={t("material.labels.status")}
						name="status"
						onChange={selectHandler("status")}
						options={materialStatusOptions}
					/>
				</div>
				<div className="sm:w-1/2 lg:w-3/4">
					<MaterialItemsForm
						errors={errors}
						disabled={disabled || busy}
						metric={params.metric}
						defaultParams={params.items}
						onChange={valueHandler("items")}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("material.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
