import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { WorkCellServer, EntityType } from "@app/domain"

interface IWorkCellServerHeaderProps {
	workCellServer?: WorkCellServer
	section?: string
	view: string
}

export const WorkCellServerHeader: React.FC<IWorkCellServerHeaderProps> = (props) => {
	const { workCellServer, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: "...", created: "", updated: "" },
		workCellServer,
	)

	const { t } = useSession()

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("workCellServers/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workCellServers", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workCellServers/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.edit"),
			to: urlTo("workCellServers/edit", id),
			active: view === "edit",
		},
	]

	let title
	if (view === "new") {
		title = t("workCellServer.titles.new")
	} else if (view === "edit") {
		title = t("workCellServer.titles.edit", { name })
	} else {
		title = t("workCellServer.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs
				entity={workCellServer}
				entityType={EntityType.WorkCellServer}
				view={view}
			/>

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
