import React from "react"
import _ from "lodash"

import { EventLog } from "@app/domain"
import { Badge, BreadcrumbDelimiter, Breadcrumbs, DateTime, Tabs, Title } from "@app/components"
import { useSession } from "@app/contexts"
import { fromUUID, urlTo } from "@app/util"

interface IEventLogHeaderProps {
	eventLog?: EventLog
}

export const EventLogHeader: React.FC<IEventLogHeaderProps> = (props) => {
	const { eventLog } = props
	const { id, created } = _.assign({ id: "", created: "" }, eventLog)

	const { t } = useSession()

	if (!eventLog) {
		return null
	}

	const shortID = fromUUID(id)
	const title = t("eventLog.titles.show", { id: shortID })
	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo("logs/show", id),
			active: true,
		},
	]

	return (
		<>
			<Title>{title}</Title>

			<Breadcrumbs>
				{t(`eventLog.titles.list`)}
				<BreadcrumbDelimiter />
				{shortID}
				<BreadcrumbDelimiter />
				{t(`common.breadcrumbs.show`)}
			</Breadcrumbs>

			<>
				<h2 className="text-2xl mb-0 font-medium">{title}</h2>
				<div className="mb-4">
					<Badge label={t("eventLog.labels.created")}>
						<DateTime time={created} />
					</Badge>
					&nbsp;
				</div>
				<Tabs tabs={tabs} className="mb-4" />
			</>
		</>
	)
}
