import React from "react"
import _ from "lodash"

import { Badge, Select, Button, Input, Icon, Property } from "@app/components"
import { useSession } from "@app/contexts"
import { EmailParams, newEmailParams } from "@app/api"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface IEmailFormProps {
	busy?: boolean
	defaultOpen?: boolean
	defaultParams: EmailParams
	disabled?: boolean
	errors: { [key: string]: string }
	onChange: (params?: EmailParams) => void
}

const EmailForm: React.FC<IEmailFormProps> = (props) => {
	const { t } = useSession()
	const {
		busy = false,
		defaultParams,
		defaultOpen = false,
		disabled = false,
		errors,
		onChange,
	} = props

	const { params, inputHandler, selectHandler } = useFormHelpers<EmailParams>(defaultParams)
	const [open, setOpen] = React.useState<boolean>(defaultOpen)

	const removeHandler = () => {
		onChange(undefined)
	}

	React.useEffect(() => {
		if (!open) {
			if (_.every([params.email, params.labels], _.isEmpty)) {
				removeHandler()
			}
		}
	}, [open])

	React.useEffect(() => {
		onChange(_.cloneDeep(params))
	}, [params])

	React.useEffect(() => {
		if (!_.isEmpty(errors) && !open) {
			setOpen(true)
		}
	}, [errors])

	const labels = _.map(En.email.labelOptions, (_val, key) => t(`email.labelOptions.${key}`)).sort()

	const defaultLabelsOption = _.map(_.get(params, "labels", []), (label) => {
		return { value: label, label: label }
	})

	const labelOptions = labels.map((v) => {
		return { value: v, label: v }
	})

	return (
		<div className="border-t border-gray-300 py-5 mb-2">
			<div className={`p-4 bg-gray-200 border border-gray-300 ${open ? "" : "hidden"}`}>
				<Input
					autoComplete="off"
					disabled={disabled || busy}
					defaultValue={params.email}
					placeholder={t("email.placeholders.email")}
					label={t("email.labels.email")}
					name="email"
					error={_.get(errors, "email")}
					onChange={inputHandler("email")}
					type="text"
				/>
				<Select
					defaultValue={defaultLabelsOption}
					disabled={disabled || busy}
					isMulti
					isSearchable={true}
					label={t("email.labels.labels")}
					name="labels"
					onChange={selectHandler("labels", true)}
					options={labelOptions}
				/>
				<div className="text-right">
					<Button
						disabled={disabled}
						busy={busy}
						type="button"
						label={t("email.buttons.remove")}
						small
						destroy
						onClick={removeHandler}
					/>
				</div>
			</div>

			<div className={`flex justify-between ${open ? "hidden" : ""}`}>
				<div>
					<Property email={params.email} />
				</div>
				<div className="text-right">
					{_.map(params.labels, (label, i) => {
						return (
							<Badge key={i} className="ml-1">
								{label}
							</Badge>
						)
					})}
				</div>
			</div>

			<button
				type="button"
				className="w-full mt-4 bg-gray-300 text-center text-gray-500 hover:text-yellow-700 hover:bg-yellow-300"
				onClick={() => setOpen(!open)}
			>
				<Icon name={open ? "ChevronUp" : "ChevronDown"} />
			</button>
		</div>
	)
}

interface IEmailsFormProps {
	busy?: boolean
	defaultParams: EmailParams[]
	disabled?: boolean
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (params: EmailParams[]) => void
}

export const EmailsForm: React.FC<IEmailsFormProps> = (props) => {
	const { t } = useSession()

	const { defaultParams, onChange, disabled = false, busy = false, errors, errorsKey } = props
	const [params, setParams] = React.useState<EmailParams[]>(defaultParams)
	const [keys, setKeys] = React.useState<string[]>(_.map(params, () => _.uniqueId()))

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const changeHandler = (index: number): ((nextParams?: EmailParams) => void) => {
		return (nextParams?: EmailParams): void => {
			if (_.isNil(nextParams)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				setParams((params) => {
					const result = [...params]
					result.splice(index, 1)
					return result
				})
			} else {
				setParams((params) => {
					const result = [...params]
					result[index] = nextParams
					return result
				})
			}
		}
	}

	const addHandler = (): void => {
		setKeys((keys) => {
			return [_.uniqueId()].concat([...keys])
		})
		setParams((params) => {
			return [newEmailParams()].concat([...params])
		})
	}

	return (
		<>
			<h2 className="text-md mb-4 text-gray-700">
				{t("email.header")} <Badge quantity>{_.size(params)}</Badge>
				<Button
					className="float-right"
					disabled={disabled}
					busy={busy}
					type="button"
					label={t("email.buttons.add")}
					onClick={addHandler}
					small
					add
				/>
			</h2>

			{_.map(params, (a: EmailParams, i: number) => (
				<EmailForm
					key={keys[i]}
					disabled={disabled}
					busy={busy}
					defaultParams={a}
					defaultOpen={_.get(a, "email", "") === ""}
					errors={_.reduce(
						errors,
						(acc, v: string, k: string) => {
							const prefix = `${errorsKey}[${i}].`
							if (_.startsWith(k, prefix)) {
								acc[k.slice(_.size(prefix))] = v
							}
							return acc
						},
						{},
					)}
					onChange={changeHandler(i)}
				/>
			))}
		</>
	)
}
