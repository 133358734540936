import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import { EntityType, Customer } from "@app/domain"

interface ICustomerDetailsProps {
	customer?: Customer
}

export const CustomerDetails: React.FC<ICustomerDetailsProps> = (props) => {
	const { t } = useSession()

	const { customer } = props

	if (_.isNil(customer)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property label={t("common.labels.referenceID")} id={customer.referenceID} />
			<Property label={t("customer.labels.name")} value={customer.name} />
			<Property label={t("common.labels.metric")} value={customer.metric} />
			{_.size(customer.addresses) > 0 ? <Property group={t("address.header")} /> : null}
			{_.map(customer.addresses, (v, i) => {
				let label = t("address.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} address={v} />
			})}
			{_.size(customer.contactables) > 0 ? <Property group={t("contactable.header")} /> : null}
			{_.map(customer.contactables, (v, i) => {
				let label = t("contactable.defaultLabel")
				if (_.size(v.labels)) {
					label = `${v.labels.sort().join(", ")}`
				}
				return <Property key={i} label={label} relation={EntityType.Contact} value={v.contactID} />
			})}
		</div>
	)
}
