import _ from "lodash"
import { v4 } from "uuid"

import { Batch, compactIDs, ReceivedResult, rpc } from "@app/api"

import type { Result } from "@app/api"
import type { WorkDevice, Entity, Pagination } from "@app/domain"

export type SaveWorkDeviceParams = {
	id: string
	description?: string
	disabled: boolean
	inventoryTag: string
	newSecret?: boolean
	secret?: string
	workDeviceType: string
	workFacilityID?: string
}

export const newSaveWorkDeviceParams = (opts?: WorkDevice): SaveWorkDeviceParams => {
	return _.assign(
		{
			id: v4(),
			description: "",
			disabled: false,
			workFacilityID: "",
		},
		opts,
	)
}

export type SaveWorkDeviceResult = {
	workDevices: WorkDevice[]
}

export const SaveWorkDevice = async (
	params: SaveWorkDeviceParams,
): Promise<Result<SaveWorkDeviceResult>> => {
	const data = await rpc("SaveWorkDevice", params, { minDuration: 300 })
	return ReceivedResult<SaveWorkDeviceResult>(data)
}

export type GetWorkDevicesParams = {
	ids?: string[]
	includeIDs?: string[]
	workFacilityID?: string

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetWorkDevicesResult = {
	workDevices: WorkDevice[]
	pagination?: Pagination
}

export const GetWorkDevices = async (
	params: GetWorkDevicesParams,
): Promise<Result<GetWorkDevicesResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetWorkDevices", "workDevices", id).then((workDevice?: Entity) => {
			return {
				ok: true,
				result: { workDevices: _.compact([workDevice as WorkDevice]) },
			} as Result<GetWorkDevicesResult>
		}) as Promise<Result<GetWorkDevicesResult>>
	}
	const data = await rpc("GetWorkDevices", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	return ReceivedResult<GetWorkDevicesResult>(data)
}
