import React from "react"
import _ from "lodash"

import { Property } from "@app/components"
import { useSession } from "@app/contexts"

import { Setting } from "@app/domain"

interface ISettingDetailsProps {
	setting?: Setting
}

export const SettingDetails: React.FC<ISettingDetailsProps> = (props) => {
	const { t } = useSession()

	const { setting } = props

	if (_.isNil(setting)) {
		return null
	}

	return (
		<div className="pt-1 sm:px-2 max-w-lg">
			<Property
				label={t("setting.labels.group")}
				value={t(`setting.groupOptions.${setting.group}`)}
			/>
			<Property label={t("setting.labels.name")} value={setting.name} />
			<Property
				label={t("setting.labels.value")}
				value={_.truncate(setting.value, { length: 40 })}
			/>
		</div>
	)
}
