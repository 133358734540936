import React from "react"

interface IBreadcrumbsProps {
	className?: string
	children?: React.ReactNode
}

export const Breadcrumbs: React.FC<IBreadcrumbsProps> = (props) => {
	const { className = "text-gray-500", ...rest } = props

	return (
		<nav className="flex mb-2 lg:mb-4">
			<div className={`flex-1 text-xs font-semibold uppercase ${className}`} {...rest} />
		</nav>
	)
}
