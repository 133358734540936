export type AddressParams = {
	city: string
	country: string
	labels: string[]
	line1: string
	line2: string
	postalCode: string
	state: string
}

export const newAddressParams = (): AddressParams => {
	return {
		city: "",
		country: "",
		labels: [],
		line1: "",
		line2: "",
		postalCode: "",
		state: "",
	}
}
