import _ from "lodash"
import { parseISO, getUnixTime } from "date-fns"

import { RobotProgram } from "@app/domain"
import { fromUUID } from "@app/util"

export type WorkCellProgramFeedback = {
	id: string

	note: string
	feedbackType: string
	userID: string

	created: string
}

export type WorkCellProgram = {
	id: string
	assemblyID: string
	parentWorkCellProgramID: string

	alwaysAvailable: boolean
	deployable: boolean
	description: string
	feedback: WorkCellProgramFeedback[]
	name: string
	robotPrograms: RobotProgram[]
	version: number
	workCellType: string

	created: string
	updated: string
}

export enum WorkCellProgramFeedbackType {
	Approved = "Approved",
	Unapproved = "Unapproved",
	General = "General",
}

export const newWorkCellProgram = (opts?: {
	[key: string]: string | boolean | number
}): WorkCellProgram => {
	return _.assign(
		{
			id: "",
			assemblyID: "",
			parentWorkCellProgramID: "",

			alwaysAvailable: false,
			deployable: false,
			description: "",
			feedback: [],
			name: "",
			robotPrograms: [],
			version: 0,
			workCellType: "",

			created: "",
			updated: "",
		},
		opts,
	)
}

export class WorkCellProgramUtils {
	static zipURL(tenantID: number, v?: WorkCellProgram) {
		if (v) {
			return `/f/blobs/${tenantID}/${fromUUID(
				v.id,
			)}.zip?type=WorkCellProgramZIP&updated=${WorkCellProgramUtils.updated(v)}`
		}
		return ""
	}

	static updated(v?: WorkCellProgram): string {
		if (v && v.updated) {
			const t = parseISO(v.updated)
			return getUnixTime(t).toString()
		}
		return ""
	}
}
