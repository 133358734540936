import React from "react"

import { entityContext } from "."
import { Entity } from "@app/domain"

type EntityProviderProps = {
	children: React.ReactNode
	entity?: Entity
}

export const EntityProvider: React.FC<EntityProviderProps> = (props) => {
	const { children, entity: entityProp } = props
	const [entityMap, setEntityMap] = React.useState<{ [key: string]: Entity }>({
		"": {} as Entity,
	})

	React.useEffect(() => {
		if (entityProp && entityProp.id) {
			setEntityMap((prev) => ({ ...prev, [entityProp.id]: entityProp }))
		}
	}, [entityProp])

	const setEntity = (id: string | undefined, entity: Entity | undefined) => {
		if (entity && id) {
			setEntityMap((prev) => ({ ...prev, [id]: entity }))
		}
	}

	return (
		<entityContext.Provider value={{ entity: entityMap, setEntity }}>
			{children}
		</entityContext.Provider>
	)
}
