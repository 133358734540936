import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import Config from "@app/config"
import {
	StreamListener,
	StreamMessage,
	StreamRequest,
	SessionLocations,
	StreamGoodbye,
} from "@app/domain"

import { streamContext } from "."

const listeners: { [key: string]: StreamListener[] } = {}

const events = ["Action", "Location", "Unauthenticated", "WhoAmI"]

type StreamProviderProps = {
	children: React.ReactNode
}

export const StreamProvider: React.FC<StreamProviderProps> = (props) => {
	const { children } = props

	const { user } = useSession()

	const [conn, setConn] = React.useState<WebSocket | undefined>()
	const [disabled, setDisabled] = React.useState<boolean>(true)
	const [locations, setLocations] = React.useState<SessionLocations>({})
	const [open, setOpen] = React.useState<boolean>(false)
	const [requests, setRequests] = React.useState<StreamRequest[]>([])

	const send = (req: StreamRequest) => {
		setRequests((requests) => [...requests, req])
	}

	const pushLocation = (curr: string, prev: string) => {
		send({ Location: { c: curr, p: prev } })
	}

	const addEventListener = (name: string, fn: StreamListener) => {
		if (_.indexOf(events, name) < 0) {
			throw new Error(`unhandled event "${name}"`)
		}
		if (!listeners[name]) {
			listeners[name] = []
		}
		listeners[name].push(fn)
	}

	const removeEventListener = (name: string, fn: StreamListener) => {
		if (listeners[name]) {
			listeners[name] = _.filter(listeners[name], (listener) => fn !== listener)
		}
	}

	React.useEffect(() => {
		let fails = 0
		let disabled = false

		const connect = () => {
			if (!window["WebSocket"] || Config.webSocketsDisabled) {
				return
			}

			if (disabled) {
				return
			}

			const protocol = document.location.protocol === "https:" ? "wss:" : "ws:"
			const conn = new WebSocket(`${protocol}//${document.location.host}/stream`)

			conn.onopen = () => {
				const t = localStorage.getItem("sessionToken") || ""
				send({ Auth: { t } })
				setConn(conn)
			}

			conn.onclose = (evt) => {
				setOpen(false)
				setConn(undefined)
				fails++
				if (fails >= 2 && evt.code === 1006) {
					disabled = true
					setDisabled(true)
				} else {
					const delay = 2 ** Math.max(1, Math.min(fails, 6)) * 500
					setTimeout(() => {
						connect()
					}, delay)
				}
			}

			conn.onmessage = (evt) => {
				fails = 0
				const messages = evt.data.split("\n")
				_.each(messages, (m: string) => {
					// console.log("ws:read:" + m)
					let parsed: StreamMessage | undefined
					try {
						parsed = JSON.parse(m) as StreamMessage
					} catch (err) {
						// drop message
					}
					if (!open && _.has(parsed, "WhoAmI")) {
						setOpen(true)
						setDisabled(false)
					}
					if (parsed && _.has(parsed, "Goodbye")) {
						const data = (parsed as StreamGoodbye)?.Goodbye
						setLocations((prev) => {
							const next = _.cloneDeep(prev)
							const userSessionID = data.s
							return _.chain(next)
								.reduce((acc, userSessions, path) => {
									acc[path] = _.reduce(
										userSessions,
										(acc, uid, sid) => {
											if (sid !== userSessionID) {
												acc[sid] = uid
											}
											return acc
										},
										{},
									)
									return acc
								}, {})
								.reduce((acc, userSessions, path) => {
									if (!_.isEmpty(acc[path])) {
										acc[path] = userSessions
									}
									return acc
								}, {})
								.value()
						})
					}
					if (_.has(parsed, "Location")) {
						const data = _.get(parsed, "Location", {})
						setLocations((prev) => {
							const next = _.cloneDeep(prev)
							const prevPath = _.get(data, "p", "")
							const currPath = _.get(data, "c", "")
							const userSessionID = _.get(data, "s", "")
							const userID = _.get(data, "u", "")
							if (!_.isEmpty(prevPath)) {
								next[prevPath] = _.reduce(
									next[prevPath],
									(acc, uid, sid) => {
										if (sid !== userSessionID) {
											acc[sid] = uid
										}
										return acc
									},
									{},
								)
								if (_.isEmpty(next[prevPath])) {
									delete next[prevPath]
								}
							}
							if (!_.isEmpty(currPath)) {
								next[currPath] = next[currPath] || {}
								next[currPath][userSessionID] = userID
							}
							return next
						})
					}
					_.each(events, (e) => {
						if (_.has(parsed, e)) {
							const message = _.get(parsed, e)
							_.each(_.get(listeners, e), (listener) => {
								listener(message)
							})
							return false
						}
					})
				})
			}
		}

		if (user && !conn) {
			connect()
		}
	}, [user, conn])

	React.useEffect(() => {
		if (!conn || _.isEmpty(requests)) {
			return
		}
		_.each(requests, (m) => {
			// console.log("ws:write:" + JSON.stringify(m))
			conn.send(JSON.stringify(m))
		})
		setRequests([])
	}, [requests, conn])

	return (
		<streamContext.Provider
			value={{
				addEventListener,
				disabled,
				locations,
				open,
				pushLocation,
				removeEventListener,
			}}
		>
			{children}
		</streamContext.Provider>
	)
}
