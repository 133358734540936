import React from "react"
import _ from "lodash"
import { useHistory, useLocation } from "react-router-dom"
import clsx from "clsx"
import { Menu, Transition } from "@headlessui/react"

import { Tooltip, Icon, Link, Badge, DateTime } from "@app/components"
import { SearchAll, SwitchTenant } from "@app/api"
import { AlertLevel, User, SearchResult } from "@app/domain"
import { useSession, useStream } from "@app/contexts"
import { useUsers } from "@app/hooks"
import Config from "@app/config"
import { urlTo } from "@app/util"

export const SearchBar: React.FC = () => {
	const { addNotification, setSession, tenantID, tenants, t, handleError, user, tenantName } =
		useSession()
	const { locations } = useStream()
	const { pathname } = useLocation()

	const history = useHistory()
	const [users] = useUsers()

	const node = React.useRef<HTMLDivElement>(null)

	const [loading, setLoading] = React.useState<boolean>(false)
	const [active, setActive] = React.useState<boolean>(false)
	const [searchResults, setSearchResults] = React.useState<SearchResult[]>([])
	const [highlightIndex, setHighlightIndex] = React.useState<number>(0)

	const env = Config.env

	const handleClickOutside = (e: MouseEvent) => {
		if (node?.current?.contains(e.target as Node)) {
			return
		}
		setActive(false)
	}

	React.useEffect(() => {
		if (active) {
			document.addEventListener("mousedown", handleClickOutside)
		} else {
			document.removeEventListener("mousedown", handleClickOutside)
		}
		return () => {
			document.removeEventListener("mousedown", handleClickOutside)
		}
	}, [active])

	const highlighted = Math.max(0, Math.min(highlightIndex, _.size(searchResults)))

	const focusHandler = (e: React.FormEvent<HTMLInputElement>): void => {
		const input = e.target as HTMLInputElement
		input.select()
		setActive(true)
		setHighlightIndex(0)
	}

	const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

	const inputHandler = _.debounce(async (query) => {
		if (_.isEmpty(query)) {
			setSearchResults([])
			return
		}
		const waitUntil = Date.now() + 300
		setLoading(true)
		try {
			const resp = await SearchAll({ query, limit: 5, offset: 0 })
			if (resp.ok) {
				setSearchResults(_.get(resp, "result.search", []))
			}
		} catch (err) {
			handleError(err)
		}
		const wait = waitUntil - Date.now()
		if (wait > 0) {
			await timeout(wait)
		}
		setLoading(false)
	}, 300)

	const results = searchResults.map((r, i) => {
		let className = ""
		if (i === highlighted) {
			className += " bg-yellow-300"
		}
		return (
			<li
				key={r.id}
				className={className}
				onMouseEnter={() => {
					setHighlightIndex(i)
				}}
				onClick={() => {
					history.push(r.url)
					setActive(false)
				}}
			>
				<div className="py-1 px-1">
					<Link
						to={r.url}
						className="block focus:outline-none focus:bg-gray-500 transition duration-150 ease-in-out"
					>
						<div className="px-4 py-4 sm:px-6">
							<div className="flex items-center justify-between">
								<div className="font-medium text-indigo-600 truncate">{r.name}</div>
								<div className="ml-2 flex-shrink-0 flex">
									{_.map(r.meta, (v, k) => {
										if (_.isEmpty(v)) {
											return null
										}
										return _.map(_.flatten([v]), (b) => (
											<Badge
												key={`${k}${b}`}
												className="ml-1"
												label={t(`search.meta.${k}`, { kind: t(`entity.${r.type}`) })}
											>
												{b}
											</Badge>
										))
									})}
								</div>
							</div>
							<div className="text-sm mt-2 sm:flex sm:justify-between">
								<div className="sm:flex">
									<div className="mr-6 flex items-center text-gray-600">
										<Icon name={r.iconName} size="1x" fixedWidth />
										<span className="ml-1">{t(`entity.${r.type}`)}</span>
									</div>
								</div>
								<div className="mt-2 flex items-center text-gray-600 sm:mt-0">
									<Icon className="mr-1" name="Calendar" size="1x" fixedWidth />
									<span>
										{t("common.labels.updated")}&nbsp;
										<DateTime time={r.updated} />
									</span>
								</div>
							</div>
						</div>
					</Link>
				</div>
				<div className="border-t border-gray-300"></div>
			</li>
		)
	})

	const usersOnPage: User[] = []
	if (!_.isEmpty(locations[pathname])) {
		_.each(locations[pathname], (uid) => {
			const user = users[uid]
			if (user) {
				usersOnPage.push(user)
			}
		})
	}

	const multiTenant = _.size(tenants) > 1

	const switchTenantHandler = (id: number) => {
		return async () => {
			const resp = await SwitchTenant({ id })
			if (resp.ok) {
				const { session } = resp.result
				const { tenantName } = session
				setSession(session)
				addNotification({
					alertLevel: AlertLevel.Success,
					title: `Tenant switched to "${tenantName}"`,
				})
				history.push(`/?${Date.now().toString(16)}`)
			}
		}
	}

	return (
		<div ref={node} className="flex-1 px-4 flex justify-between">
			<div className="flex-1 flex">
				<form className="w-full flex lg:ml-0" method="get">
					<label htmlFor="search" className="sr-only">
						{t("search.labels.query")}
					</label>
					<div className="relative w-full text-gray-700 focus-within:text-gray-600">
						<div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
							<Icon name={loading ? "Cog" : "Search"} size="1x" spin={loading} fixedWidth />
						</div>
						<input
							id="global-search"
							className="block border-none w-full h-full pl-8 pr-3 py-2 text-gray-900 placeholder-gray-400 focus:border-none focus:outline-none focus:placeholder-gray-400"
							placeholder={t("search.placeholders.query")}
							autoComplete="off"
							onKeyDown={(e) => {
								if (e.key === "ArrowDown") {
									e.preventDefault()
									setHighlightIndex(highlightIndex + 1)
								} else if (e.key === "ArrowUp") {
									e.preventDefault()
									setHighlightIndex(highlightIndex - 1)
								} else if (e.key === "Enter" && _.some(searchResults)) {
									e.preventDefault()
									history.push(searchResults[highlighted].url)
									setActive(false)
									const target = e.target as HTMLInputElement
									target.blur()
								} else if (e.key === "Escape") {
									e.preventDefault()
									setActive(false)
									setHighlightIndex(0)
									setSearchResults([])
									const target = e.target as HTMLInputElement
									target.value = ""
									target.blur()
								}
							}}
							onFocus={focusHandler}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								const query = (e.target as HTMLInputElement).value
								inputHandler(_.trim(query))
							}}
						/>
					</div>

					{_.isEmpty(results) || !active ? null : (
						<div className="absolute left-0 top-16 mt-0 shadow-xl max-w-4xl w-full lg:w-full-sidebar lg:ml-11">
							<div
								className="bg-white"
								role="menu"
								aria-orientation="vertical"
								aria-labelledby="options-menu"
							>
								<div className="border-t border-gray-300"></div>
								<ul>{results}</ul>
							</div>
						</div>
					)}
				</form>
			</div>
			<div className="w-24 relative flex items-center justify-end">
				{_.map(usersOnPage, (u, i) => {
					const content = t(
						u.id === _.get(user, "id") ? "user.selfViewingPage" : "user.viewingPage",
						{
							name: `${u.firstName} ${u.lastName}`,
						},
					)
					return (
						<Tooltip key={i} content={content}>
							<a href={urlTo("users/show", u)} target="_blank" rel="noopener noreferrer">
								<div
									className="border-2 border-gray-900 h-9 w-9 text-center align-middle rounded-full bg-black text-white leading-8"
									style={{ right: `${i}rem` }}
								>
									{`${u.firstName.substring(0, 1)}${u.lastName.substring(0, 1)}`}
								</div>
							</a>
						</Tooltip>
					)
				})}
			</div>
			<div className="ml-2 flex flex-col items-center lg:ml-2">
				<Link className="block" to="/">
					<span className="block font-bold uppercase text-lg tracking-tight whitespace-nowrap max-w-[16rem] overflow-x-hidden">
						{tenantName}
					</span>
				</Link>
				{multiTenant && (
					<Menu as="div" className="text-xs w-full">
						<Menu.Button className="block w-full">
							<div
								className={clsx(
									"text-black font-bold tracking-widest text-center leading-4 text-xs uppercase px-2",
									env === "development" ? "bg-neon-green" : "bg-yellow-500",
								)}
							>
								{env !== "production" && (
									<span className="mr-1">{_.size(env) > 7 ? env.substring(0, 3) : env}</span>
								)}
								{multiTenant && <Icon name="ChevronDown" />}
							</div>
						</Menu.Button>
						<Transition
							as={React.Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items className="absolute right-0 z-10 mt-2 w-64 origin-top-right bg-white shadow-lg focus:outline-none">
								<div className="py-1">
									{_.map(tenants, (tenant) => (
										<Menu.Item disabled={tenantID === tenant.id} key={tenant.id}>
											{({ active }) => (
												<div
													onClick={switchTenantHandler(tenant.id)}
													className={clsx(
														active ? "bg-yellow-300 text-gray-900" : "",
														"block px-4 py-2 text-lg cursor-pointer",
													)}
												>
													<Icon
														name="Check"
														className={clsx(
															"mr-2",
															tenantID === tenant.id || active ? "text-gray-800" : "text-gray-300",
														)}
													/>
													{tenant.name}
												</div>
											)}
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Transition>
					</Menu>
				)}
			</div>
		</div>
	)
}
