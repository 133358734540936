import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Badge, EntityBreadcrumbs, DateTime, Icon, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { Release, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IReleaseHeaderProps {
	release?: Release
	view: string
	section?: string
}

export const ReleaseHeader: React.FC<IReleaseHeaderProps> = (props) => {
	const { release, view: views, section } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: "...", created: "", updated: "" },
		release,
	)

	const [view] = views.split("/")

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler] = useTrash(EntityType.Release, setRedirect)

	const tabs = [
		{
			label: t("common.tabs.show"),
			to: urlTo(`releases${_.isEmpty(section) ? "" : `/${section}`}`, id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("releases/", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.releaseItems"),
					to: urlTo("releases/items", id),
					active: view === "show" && section === "items",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("releases/log", id),
					active: view === "log",
				},
			],
		},
		{
			label: t("common.tabs.contacts"),
			to: urlTo("releases/contacts", id),
			active: view === "contacts",
		},
		{
			label: t("common.tabs.shipments"),
			to: urlTo("releases/shipments", id),
			active: view === "shipments",
		},
		{
			label: t("common.tabs.releaseUnits"),
			to: urlTo("releases/releaseUnits", id),
			active: view === "releaseUnits",
		},
		{
			label: t("common.tabs.tags"),
			to: urlTo("releases/tags", id),
			active: view === "tags",
		},
		{
			label: t("common.tabs.edit"),
			to: urlTo(`releases/edit${_.isEmpty(section) ? "" : `/${section}`}`, id),
			active: view === "edit",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("releases/edit", id),
					active: view === "edit" && section === "",
				},
				{
					label: t("common.tabs.releaseItems"),
					to: urlTo("releases/edit/items", id),
					active: view === "edit" && section === "items",
				},
			],
		},

		{
			label: t("common.tabs.options"),
			options: [
				{
					label: (
						<span key="delete">
							<Icon className="mr-2" name="Trash" fixedWidth />
							{t("release.buttons.delete")}
						</span>
					),
					value: "delete",
				},
			],
			onOptionClick: () => trashHandler(id, { name }),
		},
	]

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("release.titles.new")
	} else if (view === "edit") {
		title = t("release.titles.edit", { name })
	} else {
		title = t("release.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs
				entity={release}
				entityType={EntityType.Release}
				view={views}
				section={section}
			/>

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>
					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>
					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
