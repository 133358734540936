import React from "react"

import { Await, PageContainer, AssemblyDetails } from "@app/components"
import { AssemblyHeader } from "@app/headers"
import { Assembly, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const AssemblyShowPage: React.FC = () => {
	const [assembly, assemblyLoading, setAssembly] = useEntity<Assembly>(EntityType.Assembly)

	return (
		<PageContainer>
			<AssemblyHeader assembly={assembly} setAssembly={setAssembly} view="show" />
			<Await
				loading={assemblyLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<AssemblyDetails assembly={assembly} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
