import React from "react"

import { Await, PageContainer, WorkResourceDetails } from "@app/components"
import { WorkResourceHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkResource } from "@app/domain"

export const WorkResourceShowPage: React.FC = () => {
	const [workResource, workResourceLoading] = useEntity<WorkResource>(EntityType.WorkResource)

	return (
		<PageContainer>
			<WorkResourceHeader workResource={workResource} view="show" />
			<Await
				loading={workResourceLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkResourceDetails workResource={workResource} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
