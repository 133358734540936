import React from "react"
import _ from "lodash"

import { UserHeader } from "@app/headers"
import { FormActions, PageContainer, Await, Title, Button, Input } from "@app/components"
import { User, UserUtils, AlertLevel, EntityType } from "@app/domain"
import { NewPIN } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const UserNewPINPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()

	const [user, userLoading] = useEntity<User>(EntityType.User)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [pin, setPIN] = React.useState<string>("")
	const [saving, setSaving] = React.useState<boolean>(false)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("user.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setSaving(true)
		setErrors({})
		try {
			const resp = await NewPIN({
				pin,
				id: toUUID(_.get(user, "id")),
			})
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				setRedirect(urlTo("users", user), {
					alertLevel: AlertLevel.Success,
					title: t("user.notifications.success.updated", { name: UserUtils.name(user) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setSaving(false)
		return false
	}

	if (redirect) {
		return redirect()
	}
	return (
		<PageContainer>
			<Title>{t("user.titles.newPIN", { name: UserUtils.name(user) })}</Title>
			<UserHeader user={user} view="newPIN" />
			<Await
				loading={userLoading}
				then={() => (
					<form onSubmit={submitHandler} noValidate>
						<div className="max-w-md px-2">
							<Input
								autoComplete="off"
								disabled={saving}
								defaultValue={pin}
								label={t("user.labels.PIN")}
								name="PIN"
								error={errors.pin}
								onChange={(e: React.FormEvent<HTMLInputElement>) => {
									setPIN((e.target as HTMLInputElement).value)
								}}
								type="number"
							/>
						</div>
						<FormActions>
							<Button
								errors={errors}
								label={t("user.buttons.save")}
								disabled={saving || pin.length === 0}
								type="submit"
							/>
						</FormActions>
					</form>
				)}
			/>
		</PageContainer>
	)
}
