import React from "react"
import _ from "lodash"

import { useSession } from "@app/contexts"
import { Icon, Badge, EntityBreadcrumbs, DateTime, Tabs, Title } from "@app/components"
import { urlTo } from "@app/util"
import { WorkAction, EntityType } from "@app/domain"
import { useRedirect, useTrash } from "@app/hooks"

interface IWorkActionHeaderProps {
	workAction?: WorkAction
	section?: string
	view: string
}

export const WorkActionHeader: React.FC<IWorkActionHeaderProps> = (props) => {
	const { workAction, view, section = "" } = props
	const { id, name, created, updated } = _.assign(
		{ id: "", name: "...", created: "", updated: "" },
		workAction,
	)

	const { t } = useSession()
	const { redirect, setRedirect } = useRedirect()
	const [trashHandler, trashable] = useTrash(EntityType.WorkAction, setRedirect, created, 120)

	const options = {
		label: t("common.tabs.options"),
		options: [
			{
				disabled: !trashable,
				label: (
					<span key="delete">
						<Icon className="mr-2" name="Trash" fixedWidth />
						{t("workAction.buttons.delete")}
					</span>
				),
				value: "delete",
			},
		],
		onOptionClick: () => trashHandler(id, { name }),
	}

	const tabs = _.compact([
		{
			label: t("common.tabs.show"),
			to: urlTo("workActions/show", id),
			active: view === "show" || view === "log",
			subTabs: [
				{
					label: t("common.tabs.general"),
					to: urlTo("workActions", id),
					active: view === "show" && section === "",
				},
				{
					label: t("common.tabs.log"),
					to: urlTo("workActions/log", id),
					active: view === "log",
				},
			],
		},
		{ label: t("common.tabs.edit"), to: urlTo("workActions/edit", id), active: view === "edit" },
		options,
	])

	if (redirect) {
		return redirect()
	}

	let title
	if (view === "new") {
		title = t("workAction.titles.new")
	} else if (view === "edit") {
		title = t("workAction.titles.edit", { name })
	} else {
		title = t("workAction.titles.show", { name })
	}

	return (
		<>
			<Title>{title}</Title>

			<EntityBreadcrumbs entity={workAction} entityType={EntityType.WorkAction} view={view} />

			{view !== "new" ? (
				<>
					<h2 className="text-2xl mb-0 font-medium">{name}</h2>

					<div className="mb-4">
						<Badge label={t("common.labels.created")}>
							<DateTime time={created} />
						</Badge>
						&nbsp;
						<Badge label={t("common.labels.updated")}>
							<DateTime time={updated} />
						</Badge>
					</div>

					<Tabs tabs={tabs} className="mb-4" />
				</>
			) : null}
		</>
	)
}
