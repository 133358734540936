import React from "react"
import _ from "lodash"

import { AlertLevel } from "@app/domain"
import { Icon } from "@app/components"
import { useSession } from "@app/contexts"

import type { Notification } from "@app/domain"

const NotificationDialog: React.FC<Notification> = (props) => {
	const { id, created, title, subtitle, alertLevel, autoCloseTimeout } = props

	const { removeNotification } = useSession()

	const [now, setNow] = React.useState<number>(Date.now())
	const [closed, setClosed] = React.useState<boolean>(false)

	React.useEffect(() => {
		const intv = setInterval(() => {
			setNow(Date.now())
		}, 100)
		return () => clearInterval(intv)
	}, [])

	if (closed) {
		return null
	}

	const close = () => {
		if (!closed) {
			setClosed(true)
			_.defer(removeNotification, id)
		}
	}

	const percent = Math.min(Math.floor(((now - created) / autoCloseTimeout) * 100), 100)
	if (percent === 100) {
		close()
	}

	let iconName, iconColor
	switch (alertLevel) {
		case AlertLevel.Danger:
			iconName = "Skull"
			iconColor = "text-white bg-black"
			break
		case AlertLevel.Warn:
			iconName = "Exclamation"
			iconColor = "text-white bg-red-500"
			break
		case AlertLevel.Info:
			iconName = "Info"
			iconColor = "text-white bg-gray-700"
			break
		default:
			iconName = "Check"
			iconColor = "text-white bg-green-500"
	}

	return (
		<div className="max-w-sm w-full bg-black text-white shadow-2xl pointer-events-auto mb-2">
			<div className="shadow-xs overflow-hidden">
				<div className="px-4 pt-4 pb-1">
					<div className="flex items-start">
						<div className={"flex-shrink-0 p-1 rounded-md " + iconColor}>
							<Icon name={iconName} size="lg" fixedWidth />
						</div>
						<div className="ml-3 w-0 flex-1 pt-0.5">
							<p className="text-sm leading-5 text-gray-200">{title}</p>
							{_.isEmpty(subtitle) ? null : (
								<p className="mt-1 text-sm leading-5 text-gray-500">{subtitle}</p>
							)}
						</div>
						<div className="ml-4 flex-shrink-0 flex">
							<button
								onClick={close}
								className="inline-flex text-gray-500 hover:text-white focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
							>
								<Icon name="Times" size="lg" fixedWidth />
							</button>
						</div>
					</div>
				</div>
				<div className="bg-gray-900 m-3 h-1">
					<div
						className="bg-gray-700 h-1 transition-all ease-linear duration-100"
						style={{
							width: `${Math.max(0, 95 - percent)}%`,
						}}
					></div>
				</div>
			</div>
		</div>
	)
}

export const Notifications: React.FC = () => {
	const { notifications } = useSession()

	return (
		<div
			id="notifications"
			className="z-20 fixed inset-0 flex flex-col items-end justify-start px-4 py-6 sm:p-6 pointer-events-none sm:items-end sm:justify-end"
		>
			<div className="flex flex-col-reverse w-96">
				{notifications.map((n) => (
					<NotificationDialog key={n.id} {...n} />
				))}
			</div>
		</div>
	)
}
