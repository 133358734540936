import React from "react"
import clsx from "clsx"

import { Icon } from "@app/components"
import { SearchAll } from "@app/api"
import { SearchResult, EntityType } from "@app/domain"
import { useSession } from "@app/contexts"
import _ from "lodash"

interface IOmniSearchProps {
	onChange: (searchResults: SearchResult) => void
	selectedIDs?: string[]
	entityTypes?: EntityType[]
	label: string
	placeholder?: string
	disabled?: boolean
	busy?: boolean
	autocomplete?: boolean
}

export const OmniSearch: React.FC<IOmniSearchProps> = (props) => {
	const {
		onChange,
		selectedIDs = [],
		entityTypes = undefined,
		label,
		placeholder = "",
		disabled = false,
		busy = false,
		autocomplete = false,
	} = props

	const { handleError } = useSession()

	const inputRef = React.useRef<HTMLInputElement>(null)
	const [query, setQuery] = React.useState<string>("")
	const [results, setResults] = React.useState<SearchResult[]>([])
	const [showResults, setShowResults] = React.useState<boolean>(false)
	const [highlightIndex, setHighlightIndex] = React.useState<number>(0)

	React.useEffect(() => {
		if (query.length === 0) {
			setResults([])
			return
		}
		SearchAll({ query: query, limit: 5, offset: 0, entityTypes }).then((resp) => {
			if (!resp.ok) {
				handleError("Error retrieving results. Try again.")
				return
			}
			setResults(resp.result.search.filter((result) => selectedIDs.every((id) => id !== result.id)))
			setHighlightIndex(Math.min(highlightIndex, resp.result.search.length - 1))
		})
	}, [query])

	// If not typing and query isn't empty, prompt user to finish selection.
	const finishSelection = document.activeElement !== inputRef.current && query.length > 0

	const selectResult = () => {
		onChange(results[highlightIndex])
		setQuery("")
	}
	return (
		<div
			className={clsx(
				"flex flex-col relative transition-all duration-300",
				finishSelection && "mb-6",
			)}
		>
			<label htmlFor="name-input" className="text-md text-gray-700">
				{label}
			</label>
			<input
				value={query}
				autoComplete={autocomplete ? "on" : "off"}
				placeholder={placeholder}
				ref={inputRef}
				disabled={disabled || busy}
				name="name-input"
				id="name-input"
				onFocus={() => {
					setShowResults(true)
				}}
				onBlur={() => {
					// Timeout is to allow for click event to fire on result list.
					setTimeout(() => setShowResults(false), 150)
				}}
				onChange={(e) => {
					setQuery(e.target.value)
					setHighlightIndex(0)
				}}
				onKeyDown={(e) => {
					if (e.key === "ArrowDown") {
						e.preventDefault()
						setHighlightIndex(Math.min(highlightIndex + 1, results.length - 1))
					} else if (e.key === "ArrowUp") {
						e.preventDefault()
						setHighlightIndex(Math.max(highlightIndex - 1, 0))
					} else if (e.key === "Enter" && results.length > 0) {
						e.preventDefault()
						selectResult()
					} else if (e.key === "Escape") {
						e.preventDefault()
						setHighlightIndex(0)
						setResults([])
						const target = e.target as HTMLInputElement
						target.blur()
					}
				}}
				className="w-full border border-gray-400 p-2 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
			/>
			{query.length > 0 && showResults && results.length > 0 && (
				<div className="absolute top-16 bg-white flex flex-col flex-1 w-full border-t-2 z-10">
					{results.map((result, i) => (
						<button
							key={i}
							className={`text-left font-semibold border-2 border-t-0 p-2 flex w-full items-center justify-between ${
								i === highlightIndex ? "bg-yellow-500" : "bg-white"
							}`}
							onClick={() => {
								selectResult()
								if (inputRef && inputRef.current) {
									inputRef.current.focus()
									// Work around for input OnBlur timeout.
									setTimeout(() => setShowResults(true), 151)
								}
							}}
							onMouseOver={() => {
								setHighlightIndex(i)
							}}
						>
							<div className="space-x-2">
								<p className="inline">{result.name}</p>
								<p className="inline rounded-full text-sm text-gray-700 bg-gray-300 max-w-max px-2">
									{_.startCase(result.type)}
								</p>
							</div>
							<Icon
								name="Plus"
								className={clsx("ml-2 text-gray-500", i === highlightIndex && "text-gray-200")}
							/>
						</button>
					))}
				</div>
			)}
			<p className="text-sm text-red-500 h-0">
				{finishSelection && "Please select an item from the list."}
			</p>
		</div>
	)
}
