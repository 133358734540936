import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { WorkCellProgram, AlertLevel, EntityType } from "@app/domain"
import { WorkCellProgramForm } from "@app/forms"
import { WorkCellProgramHeader } from "@app/headers"
import {
	newSaveWorkCellProgramParams,
	SaveWorkCellProgram,
	SaveWorkCellProgramParams,
} from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkCellProgramEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workCellProgram, workCellProgramLoading] = useEntity<WorkCellProgram>(
		EntityType.WorkCellProgram,
	)
	const { id, name } = _.assign({ id: "", name: "..." }, workCellProgram)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveWorkCellProgramParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkCellProgram({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.workCellPrograms[0]")
				setRedirect(urlTo("workCellPrograms", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workCellProgram.notifications.success.created", { name: saved.name }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workCellProgram.titles.edit", { name })}</Title>
			<WorkCellProgramHeader workCellProgram={workCellProgram} view="edit" />
			<Await
				loading={workCellProgramLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkCellProgramForm
							busy={busy}
							defaultParams={newSaveWorkCellProgramParams(workCellProgram)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
