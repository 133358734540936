import _ from "lodash"

import type { Attribute, Address, Contactable } from "@app/domain"

export type Release = {
	id: string
	customerID: string
	jobID: string
	workFacilityID: string | null

	addresses: Address[]
	attributes: Attribute[]
	metric: boolean
	name: string
	referenceID: string

	keyDates: {
		received: string | null
		deliverBy: string | null
		produced: string | null
		shipped: string | null
	}

	contactables: Contactable[]

	created: string
	updated: string
}

export type ReleaseItemWorkCellDateQuantityMap = {
	// releaseItemID -> workCellID -> {date, quantity}[]
	[key: string]: { [key: string]: { date: Date; quantity: number }[] }
}

export const newRelease = (opts?: { [key: string]: string | boolean | number }): Release => {
	return _.assign(
		{
			id: "",
			customerID: "",
			jobID: "",
			workFacilityID: "",

			addresses: [],
			attributes: [],
			keyDates: {
				received: new Date().toISOString(),
				deliverBy: null,
				produced: null,
				shipped: null,
			},
			metric: false,
			name: "",
			referenceID: "",

			contactables: [],

			created: "",
			updated: "",
		},
		opts,
	)
}
