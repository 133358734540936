import { rpc, Cache, compactIDs } from "@app/api"

import type { Result } from "@app/api"
import type { ReleaseItem, Pagination } from "@app/domain"

export type ReleaseItemParams = {
	id: string
	description: string
	quantity: number
	sortOrder: number
}

export type SaveReleaseItemsParams = {
	releaseID: string
	releaseItems: ReleaseItemParams[]
	removeReleaseItems: string[]
}

export const SaveReleaseItems = async (
	params: SaveReleaseItemsParams,
): Promise<Result<SaveReleaseItemsResult>> => {
	const data = await rpc("SaveReleaseItems", {
		...params,
	})
	Cache.scan(data)
	return data as Result<SaveReleaseItemsResult>
}

export type SaveReleaseItemsResult = {
	releaseItems: ReleaseItem[]
}

export type GetReleaseItemsParams = {
	ids?: string[]
	includeIDs?: string[]

	assemblyID?: string
	customerID?: string
	jobID?: string
	releaseID?: string

	query?: string

	limit?: number
	offset?: number
	withoutCount?: boolean
}

export type GetReleaseItemsResult = {
	releaseItems: ReleaseItem[]
	pagination: Pagination
}

export const GetReleaseItems = async (
	params: GetReleaseItemsParams,
): Promise<Result<GetReleaseItemsResult>> => {
	const data = await rpc("GetReleaseItems", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetReleaseItemsResult>
}
