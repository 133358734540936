import _ from "lodash"
import { useQuery } from "."

export type PaginationQuery = {
	limit: number
	offset: number
}

export const usePagination = (limit: number): PaginationQuery => {
	const query = useQuery()
	let page = 1
	if (_.isString(query.page)) {
		page = Math.max(1, parseInt(query.page, 10))
	}
	return {
		limit: limit,
		offset: (page - 1) * limit,
	}
}
