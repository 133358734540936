import React from "react"
import _ from "lodash"
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from "@dnd-kit/core"
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	rectSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable"
import clsx from "clsx"

import {
	Await,
	Badge,
	Button,
	ButtonIcon,
	FormActions,
	Icon,
	Input,
	MaterialSelect,
} from "@app/components"
import { AssemblySearchForm, AttributesForm } from "@app/forms"
import { urlTo } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"
import { AttributeParams, SaveReleaseItems, SaveReleaseItemsParams } from "@app/api"
import { Assembly, Release, ReleaseItem, AlertLevel, EntityType, newReleaseItem } from "@app/domain"

interface IReleaseItemsFormProps {
	release?: Release
}

export const ReleaseItemsForm: React.FC<IReleaseItemsFormProps> = (props) => {
	const { t, handleError, addNotification } = useSession()
	const { release } = props

	const releaseID = _.get(release, "id", "")
	const jobID = _.get(release, "jobID", "")

	const { redirect, setRedirect } = useRedirect()
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [removeIDs, setRemoveIDs] = React.useState<string[]>([])
	const [saving, setSaving] = React.useState<boolean>(false)
	const [releaseItems, releaseablesLoading, setReleaseItems] = useEntity<ReleaseItem[]>(
		EntityType.ReleaseItem,
	)

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	)

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event
		if (over && active.id !== over.id && releaseItems) {
			const oldIndex = releaseItems.findIndex((releaseItem) => releaseItem.id === active.id)
			const newIndex = releaseItems.findIndex((releaseItem) => releaseItem.id === over.id)
			swap(oldIndex, newIndex)
		}
	}

	const swap = (oldIndex: number, newIndex: number) => {
		setReleaseItems((releaseItems) => {
			if (releaseItems) {
				return arrayMove(
					releaseItems.map((r, i) => ({
						...r,
						sortOrder: i === oldIndex ? newIndex : i === newIndex ? oldIndex : r.sortOrder,
					})),
					oldIndex,
					newIndex,
				)
			}
		})
	}

	React.useEffect(() => {
		if (_.size(releaseItems) === 0) {
			setReleaseItems([newReleaseItem()])
		}
	}, [releaseItems])

	if (redirect) {
		return redirect()
	}

	const saveHandler = async (params: SaveReleaseItemsParams) => {
		setSaving(true)
		setErrors({})
		try {
			const resp = await SaveReleaseItems({ ...params, releaseID })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const next = _.get(resp, "result.releaseItems", [])
				addNotification({
					alertLevel: AlertLevel.Success,
					title: t("releaseItem.notifications.success.updated", {
						smart_count: _.size(next),
					}),
				})
				setRedirect(urlTo("release/items", releaseID))
			}
		} catch (err) {
			handleError(err)
		}
		setSaving(false)
	}

	const addHandler = (r: ReleaseItem) => {
		return () => {
			setReleaseItems((prev) => {
				const next: ReleaseItem[] = []
				_.each(prev, (item) => {
					next.push(item)
					if (item.id === r.id) {
						next.push(newReleaseItem())
					}
				})
				return next
			})
		}
	}

	const removeHandler = (r: ReleaseItem) => {
		return () => {
			setRemoveIDs((prev) => {
				return [...prev].concat(r.id)
			})
			setReleaseItems((prev) => {
				const next: ReleaseItem[] = []
				_.each(prev, (item) => {
					if (item.id !== r.id) {
						next.push(item)
					}
				})
				if (_.isEmpty(next)) {
					next.push(newReleaseItem())
				}
				return next
			})
		}
	}

	const attributesHandler = (r: ReleaseItem): ((attrs: AttributeParams[]) => void) => {
		return (attrs) => {
			setReleaseItems((prev) => {
				const next: ReleaseItem[] = []
				_.each(prev, (item) => {
					if (item.id === r.id) {
						next.push(_.set(item, "attributes", attrs))
					} else {
						next.push(item)
					}
				})
				return next
			})
		}
	}

	const setReleaseItemKey = (r: ReleaseItem, key: string, value: string | number | null) => {
		setReleaseItems((prev) => {
			const next: ReleaseItem[] = []
			_.each(prev, (item) => {
				if (item.id === r.id) {
					next.push(_.set(item, key, value))
				} else {
					next.push(item)
				}
			})
			return next
		})
	}

	const inputHandler = (
		r: ReleaseItem,
		key: string,
		ref?: React.RefObject<HTMLInputElement>,
	): ((e: React.FormEvent<HTMLInputElement>) => void) => {
		return (e) => {
			const target = e.target as HTMLInputElement
			let value: string | number | null = target.value as string
			if (key === "quantity") {
				const num = parseInt(value.replace(/[^0-9]/g, ""), 10)
				value = isNaN(num) ? 1 : num
				if (ref && ref.current) {
					ref.current.value = value.toString()
				}
			} else if (key === "weight") {
				const num = parseFloat(value.replace(/[^.0-9]/g, ""))
				value = isNaN(num) ? null : num
			}
			setReleaseItemKey(r, key, value)
		}
	}

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		const params = {
			releaseID,
			releaseItems: _.map(releaseItems, (r) => {
				return { ...r }
			}),
			removeReleaseItems: removeIDs,
		}
		saveHandler(params)
		return false
	}

	const units = _.get(release, "metric") ? "metric" : "imperial"

	return (
		<Await
			loading={releaseablesLoading}
			then={() => (
				<form onSubmit={submitHandler} noValidate>
					<div className="flex flex-col lg:flex-row lg:gap-x-4">
						<div className="w-full lg:w-4/5">
							<div className="divide-y divide-gray-300 border-t border-b border-gray-300">
								<div className="flex flex-wrap gap-x-2 lg:gap-x-4 px-4 pt-2 pb-2 text-gray-700">
									<div className="hidden lg:block flex-none w-4 text-right"></div>
									<div className="flex-none w-4 text-right"></div>
									<div className="flex-grow text-left">{t("releaseItem.labels.description")}</div>
									<div className="flex-none w-16 lg:w-28 text-left">
										{t("releaseItem.labels.controlCodes")}
									</div>
									<div className="flex-none w-20 lg:w-20 text-left">
										{t("releaseItem.labels.weight")}
										&nbsp;
										{`(${t(`units.${units}.weightAbbr`)})`}
									</div>
									<div className="flex-none w-16 lg:w-20 text-left">
										{t("releaseItem.labels.quantity")}
									</div>
									<div className="hidden lg:block flex-0 w-20"></div>
									<div className="hidden lg:block flex-none w-16 py-3 text-right"></div>
								</div>
								<DndContext
									sensors={sensors}
									collisionDetection={closestCenter}
									onDragEnd={handleDragEnd}
								>
									<SortableContext items={releaseItems ?? []} strategy={rectSortingStrategy}>
										{_.map(releaseItems, (r, i) => (
											<ReleaseItemForm
												addHandler={addHandler}
												attributesHandler={attributesHandler}
												errors={errors}
												key={r.id}
												i={i}
												setReleaseItemKey={setReleaseItemKey}
												inputHandler={inputHandler}
												last={i === (releaseItems?.length ?? 1) - 1}
												onMoveUp={() => swap(i, i - 1)}
												onMoveDown={() => swap(i, i + 1)}
												release={release}
												releaseItem={r}
												removeHandler={removeHandler}
												saving={saving}
												units={units}
											/>
										))}
									</SortableContext>
								</DndContext>
							</div>
						</div>
						<div className="w-full lg:w-1/5">
							<AssemblySearchForm
								jobID={jobID}
								busy={saving}
								addAssembly={(assembly: Assembly) => {
									setReleaseItems((prev) => {
										const releaseItem = newReleaseItem()
										releaseItem.assemblyID = assembly.id
										releaseItem.description = assembly.name
										if (assembly?.weight) {
											if (release?.metric === assembly?.metric) {
												releaseItem.weight = assembly.weight
											} else if (release?.metric) {
												releaseItem.weight = assembly.weight / 2.2046
											} else {
												releaseItem.weight = assembly.weight * 2.2046
											}
											releaseItem.weight = _.round(releaseItem.weight, 2)
										}
										if (prev) {
											return [...prev].concat([releaseItem])
										} else {
											return [releaseItem]
										}
									})
								}}
							/>
						</div>
					</div>
					<FormActions>
						<Button type="submit" label={"Save release items"} busy={saving} />
					</FormActions>
				</form>
			)}
		/>
	)
}

interface IReleaseItemFormProps {
	addHandler: (r: ReleaseItem) => () => void
	attributesHandler: (r: ReleaseItem) => (attrs: AttributeParams[]) => void
	errors: { [key: string]: string }
	i: number
	inputHandler: (
		r: ReleaseItem,
		key: string,
		ref?: React.RefObject<HTMLInputElement>,
	) => (e: React.FormEvent<HTMLInputElement>) => void
	last: boolean
	onMoveUp: () => void
	onMoveDown: () => void
	setReleaseItemKey: (r: ReleaseItem, key: string, value: string | number | null) => void
	release?: Release
	releaseItem: ReleaseItem
	removeHandler: (r: ReleaseItem) => () => void
	saving: boolean
	units: string
}

export const ReleaseItemForm: React.FC<IReleaseItemFormProps> = (props) => {
	const { t } = useSession()
	const weightRef = React.createRef<HTMLInputElement>()
	const quantityRef = React.createRef<HTMLInputElement>()
	const {
		addHandler,
		attributesHandler,
		errors,
		i,
		inputHandler,
		last,
		onMoveUp,
		onMoveDown,
		setReleaseItemKey,
		release,
		releaseItem,
		removeHandler,
		saving,
		units,
	} = props
	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: releaseItem.id,
	})
	return (
		<div
			className={clsx("bg-white", isDragging ? "relative z-10 shadow-lg" : "z-0")}
			ref={setNodeRef}
			style={{
				transform: !transform ? undefined : `translateY(${Math.ceil(transform.y)}px)`,
				transition,
			}}
		>
			<div className="flex flex-row items-baseline gap-x-2 lg:gap-x-4 pt-4 px-4">
				<div className="flex flex-col gap-1 place-items-center w-4 pt-3 text-right">
					<Icon
						name="GripLines"
						className="text-gray-500 cursor-move focus:outline-none"
						{...listeners}
						{...attributes}
					/>
					{i !== 0 && (
						<Icon
							onClick={() => onMoveUp()}
							name="ChevronUp"
							className="mt-1 text-gray-500 cursor-pointer"
						/>
					)}
					{!last && (
						<Icon
							onClick={() => onMoveDown()}
							name="ChevronDown"
							className="mt-1 text-gray-500 cursor-pointer"
						/>
					)}
				</div>
				<div className="flex-none w-4 pt-4 text-right text-xs text-gray-700 ">{i + 1}</div>
				<div className="flex-none w-20 sm:flex-grow">
					<Input
						disabled={saving}
						defaultValue={releaseItem.description}
						name={`description[${i}]`}
						onChange={inputHandler(releaseItem, "description")}
						type="text"
					/>
				</div>
				<div className="flex-grow w-24 lg:w-28">
					<MaterialSelect
						defaultValue={releaseItem.controlCodes}
						disabled={saving}
						error={_.get(errors, `releaseItems[${i}].controlCodes`)}
						name={`controlCodes[${i}]`}
						customerID={release?.customerID}
						jobID={release?.jobID}
						workFacilityID={release?.workFacilityID ?? undefined}
						onChange={(v) =>
							setReleaseItemKey(
								releaseItem,
								"controlCodes",
								v.reduce((p, c) => `${p}, ${c.label}`, "").slice(2),
							)
						}
					/>
				</div>
				<div className="flex-none w-16 lg:w-20">
					<Input
						ref={weightRef}
						name={`weight[${i}]`}
						disabled={saving}
						placeholder={t(`units.${units}.weightAbbr`)}
						className="text-right"
						defaultValue={releaseItem.weight || ""}
						error={_.get(errors, `releaseItems[${i}].weight`)}
						onChange={inputHandler(releaseItem, "weight", weightRef)}
						type="text"
					/>
				</div>
				<div className="flex-none w-16 lg:w-20">
					<Input
						ref={quantityRef}
						name={`quantity[${i}]`}
						disabled={saving}
						className="text-right"
						defaultValue={releaseItem.quantity}
						error={_.get(errors, `releaseItems[${i}].quantity`)}
						onChange={() => {
							const prevQuantity = releaseItem.quantity
							inputHandler(releaseItem, "quantity", quantityRef)
							if (releaseItem?.weight && weightRef?.current?.value && quantityRef?.current?.value) {
								weightRef.current.value = String(
									_.round(
										(releaseItem?.weight / prevQuantity) * Number(quantityRef?.current?.value),
										2,
									),
								)
								inputHandler(releaseItem, "weight", weightRef)
							}
						}}
						type="text"
					/>
				</div>
				<div className="hidden lg:block flex-0 w-20 pt-3 text-center">
					<Badge className="">{releaseItem.assemblyID ? "Assembly" : "Item"}</Badge>
				</div>
				<div className="hidden lg:block flex-none w-16 pt-3 text-right space-x-1">
					<ButtonIcon icon="Plus" onClick={addHandler(releaseItem)} busy={saving} />
					<ButtonIcon icon="Minus" onClick={removeHandler(releaseItem)} busy={saving} />
				</div>
			</div>
			<div className="flex flex-row gap-x-2 lg:gap-x-4 px-4">
				<div className="hidden lg:block flex-none w-4 pb-3 text-right"></div>
				<div className="flex-none w-4 pb-4 text-right text-xs text-gray-700 "></div>
				<div className="flex-grow">
					<AttributesForm
						busy={saving}
						defaultParams={_.get(releaseItem, "attributes")}
						errors={errors}
						errorsKey={`releaseItems[${i}].attributes`}
						onChange={attributesHandler(releaseItem)}
					/>
				</div>
				<div className="flex-none w-16 lg:w-40" />
			</div>
		</div>
	)
}
