import React from "react"
import _ from "lodash"

import { Await, Title, PageContainer } from "@app/components"
import { WorkEvent, AlertLevel, EntityType } from "@app/domain"
import { WorkEventForm } from "@app/forms"
import { WorkEventHeader } from "@app/headers"
import { newSaveWorkEventParams, SaveWorkEvent, SaveWorkEventParams } from "@app/api"
import { urlTo, toUUID } from "@app/util"
import { useEntity, useRedirect } from "@app/hooks"
import { useSession } from "@app/contexts"

export const WorkEventEditPage: React.FC = () => {
	const { t, handleError } = useSession()

	const [workEvent, workEventLoading] = useEntity<WorkEvent>(EntityType.WorkEvent)
	const { id, description } = _.assign({ id: "", description: "..." }, workEvent)
	const { redirect, setRedirect } = useRedirect()

	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [busy, setBusy] = React.useState<boolean>(false)

	const saveHandler = async (params: SaveWorkEventParams) => {
		setBusy(true)
		setErrors({})
		try {
			const resp = await SaveWorkEvent({ ...params, id: toUUID(id) })
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = resp.result.workEvents?.[0]
				setRedirect(urlTo("workEvents", saved), {
					alertLevel: AlertLevel.Success,
					title: t("workEvent.notifications.success.updated", { description: saved?.description }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("workEvent.titles.edit", { description })}</Title>
			<WorkEventHeader workEvent={workEvent} view="edit" />
			<Await
				loading={workEventLoading}
				then={() => (
					<div className="max-w-auto px-2">
						<WorkEventForm
							busy={busy}
							defaultParams={newSaveWorkEventParams(workEvent)}
							errors={errors}
							onSave={saveHandler}
						/>
					</div>
				)}
			/>
		</PageContainer>
	)
}
