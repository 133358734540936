import React from "react"
import _ from "lodash"

import { Link, Icon } from "@app/components"
import { useSession } from "@app/contexts"

import type { Pagination } from "@app/domain"

interface IPaginationProps {
	disabled?: boolean
	pagination?: Pagination
	results: string
	sibling?: React.ReactNode
}

export const Paginator: React.FC<IPaginationProps> = (props) => {
	const { pagination, results, sibling, disabled } = props
	if (_.isNil(pagination)) {
		return null
	}

	const { from, next, page, prev, to, total } = pagination

	const { t } = useSession()

	const nextDisabled = next === page
	const prevDisabled = prev === page

	const disabledClassName =
		"relative inline-flex items-center px-4 py-2 text-sm leading-5 font-medium text-gray-500 bg-gray-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 transition ease-in-out duration-150"
	const enabledClassName =
		"relative inline-flex items-center px-4 py-2 text-sm leading-5 font-medium bg-yellow-500 hover:bg-yellow-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 transition ease-in-out duration-150"

	return (
		<div className="py-2 flex items-center justify-between">
			<div className="flex-1 flex justify-between sm:hidden">
				<Link
					disabled={disabled || prevDisabled}
					to={`?page=${prev}`}
					className={prevDisabled ? disabledClassName : enabledClassName}
				>
					<Icon name="ChevronLeft" size="1x" />
					&nbsp;{t("pagination.prev")}
				</Link>
				{sibling ? <div>{sibling}</div> : null}
				<Link
					disabled={disabled || nextDisabled}
					to={`?page=${next}`}
					className={nextDisabled ? disabledClassName : enabledClassName}
				>
					{t("pagination.next")}&nbsp;
					<Icon name="ChevronRight" size="1x" />
				</Link>
			</div>
			<div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
				<div>
					<p className="text-sm leading-5">
						{total === "0" ? (
							<>
								<span className="font-medium">{total}</span> {results}
							</>
						) : (
							<>
								<span className="font-medium">{from}</span> {t("pagination.to")}{" "}
								<span className="font-medium">{to}</span> {t("pagination.of")}{" "}
								<span className="font-medium">{total}</span> {results}
							</>
						)}
					</p>
				</div>
				<div>
					<nav className="relative inline-flex divide-x divide-gray-100">
						{sibling ? <div>{sibling}</div> : null}
						<Link
							disabled={disabled || prevDisabled}
							to={`?page=${prev}`}
							className={prevDisabled ? disabledClassName : enabledClassName}
						>
							<Icon name="ChevronLeft" size="1x" />
						</Link>
						<Link
							disabled={disabled || nextDisabled}
							to={`?page=${next}`}
							className={nextDisabled ? disabledClassName : enabledClassName}
						>
							<Icon name="ChevronRight" size="1x" />
						</Link>
					</nav>
				</div>
			</div>
		</div>
	)
}
