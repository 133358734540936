import _ from "lodash"

export type WorkEvent = {
	id: string
	workCellID: string | null
	workCellProgramID: string | null
	workFacilityID: string | null
	workResourceID: string | null

	canceled: boolean
	description: string
	timeBegin: string
	timeEnd: string
	workEventType: string

	created: string
	updated: string
}

export enum WorkEventType {
	DeployWorkCellProgram = "DeployWorkCellProgram",
	Hold = "Hold",
}

export const newWorkEvent = (opts?: { [key: string]: string | boolean | number }): WorkEvent => {
	return _.assign(
		{
			id: "",
			workCellID: null,
			workCellProgramID: null,
			workFacilityID: null,
			workResourceID: null,

			canceled: false,
			description: "",
			timeBegin: "",
			timeEnd: "",
			workEventType: "",

			created: "",
			updated: "",
		},
		opts,
	)
}
