import React from "react"
import _ from "lodash"

import { SaveUser, SaveUserParams, newSaveUserParams } from "@app/api"
import { useSession } from "@app/contexts"
import { newUser, UserUtils, User, AlertLevel } from "@app/domain"
import { UserForm } from "@app/forms"
import {
	Button,
	PageContainer,
	Link,
	BreadcrumbDelimiter,
	Breadcrumbs,
	Title,
} from "@app/components"
import { urlTo } from "@app/util"
import { useRedirect } from "@app/hooks"

export const UserNewPage: React.FC = () => {
	const { t, handleError, addNotification } = useSession()
	const { redirect, setRedirect } = useRedirect()

	const [user] = React.useState<User>(newUser())
	const [busy, setBusy] = React.useState<boolean>(false)
	const [errors, setErrors] = React.useState<{ [key: string]: string }>({})
	const [params, setParams] = React.useState<SaveUserParams>(newSaveUserParams(user))

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			const general = _.get(errors, "general")
			const title = general || t("user.notifications.failure.saving", count)
			addNotification({
				alertLevel: AlertLevel.Warn,
				title,
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])
	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		setBusy(true)
		setErrors({})
		try {
			const saveParams = { ...params }
			const resp = await SaveUser(saveParams)
			if (!resp.ok) {
				setErrors(resp.errors)
			} else {
				const saved = _.get(resp, "result.user")
				setRedirect(urlTo("users", saved), {
					alertLevel: AlertLevel.Success,
					title: t("user.notifications.success.created", { name: UserUtils.name(saved) }),
				})
			}
		} catch (err) {
			handleError(err)
		}
		setBusy(false)
		return false
	}

	if (redirect) {
		return redirect()
	}

	return (
		<PageContainer>
			<Title>{t("user.titles.new")}</Title>

			<Breadcrumbs>
				<Link to="/users">{t("user.titles.list")}</Link>
				<BreadcrumbDelimiter />
				{t("common.breadcrumbs.new")}
			</Breadcrumbs>
			<div className="max-w-md">
				<form onSubmit={submitHandler} noValidate>
					<UserForm
						defaultParams={newSaveUserParams(user)}
						disabled={busy}
						errors={errors}
						onChange={(nextParams: SaveUserParams) => {
							setParams((prev) => _.assign({}, prev, nextParams))
						}}
					/>
					<div className="max-w-md mt-6">
						<Button errors={errors} label={t("user.buttons.save")} busy={busy} type="submit" />
					</div>
				</form>
			</div>
		</PageContainer>
	)
}
