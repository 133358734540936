import { rpc } from "@app/api"

import type { Result } from "@app/api"

export type CreateWorkerBadgeParams = {
	workerIDs: string[]
}

export type CreateWorkerBadgeResult = {
	id: string
}

export const CreateWorkerBadge = async (
	params: CreateWorkerBadgeParams,
): Promise<Result<CreateWorkerBadgeResult>> => {
	const data = await rpc("CreateWorkerBadge", {
		...params,
	})
	return data as Result<CreateWorkerBadgeResult>
}
