import React from "react"
import _ from "lodash"
import clsx from "clsx"
import { add, differenceInHours, endOfDay, format, startOfDay } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

import { DateTimeInput, Icon, PageContainer, Select, Toggle, Tooltip } from "@app/components"
import { ReportsHeader } from "@app/headers"
import {
	AlertLevel,
	Assembly,
	Customer,
	Entity,
	Job,
	Release,
	Shipment,
	Tag,
	WorkAction,
	WorkCell,
	WorkCellServer,
	WorkFacility,
	WorkResource,
	WorkTrackingState,
	Worker,
	WorkerUtils,
} from "@app/domain"
import { GetWorkCells, GetWorkFacilities, GetWorkTrackingStates } from "@app/api"
import { useSession } from "@app/contexts"
import { getBrowserTimezoneOffset, browserTimezone, formatTimezone } from "@app/util"

type WorkTrackingStateGroup = {
	location: string
	actor: string
	action: string
	actionDescription: string
	subject: string
	from: Date
	to: Date
	timezone: string
}

enum GroupBy {
	Location = "location",
	Actor = "actor",
	Action = "action",
	Subject = "subject",
}

export const ReportsWorkTrackingCalendarPage: React.FC = () => {
	const { addNotification } = useSession()

	const [workTrackingStates, setWorkTrackingStates] = React.useState<WorkTrackingState[]>([])
	const [entityIDMap, setEntityIDMap] = React.useState<{ [key: string]: Entity }>({})

	const [workFacilities, setWorkFacilities] = React.useState<WorkFacility[]>([])
	const [selectedWorkFacility, setSelectedWorkFacility] = React.useState<WorkFacility>()
	const [workCells, setWorkCells] = React.useState<WorkCell[]>([])
	const [selectedWorkCell, setSelectedWorkCell] = React.useState<WorkCell>()

	const [loading, setLoading] = React.useState(false)
	const [date, setDate] = React.useState<Date>(new Date())
	const [groups, setGroups] = React.useState<{ [key: string]: WorkTrackingStateGroup[] }>({})
	const [groupBy, setGroupBy] = React.useState<GroupBy>(GroupBy.Location)
	const [earliestHour, setEarliestHour] = React.useState(8)
	const [numRows, setNumRows] = React.useState(12)
	const [rowDivisions, setRowDivisions] = React.useState(4)
	const [condensed, setCondensed] = React.useState(false)

	React.useEffect(() => {
		const getWorkFacilities = async () => {
			const resp = await GetWorkFacilities({})
			if (resp.ok) {
				setWorkFacilities(resp.result.workFacilities)
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work facilities.`,
				})
			}
		}
		getWorkFacilities()
	}, [])

	React.useEffect(() => {
		const getWorkCells = async () => {
			const resp = await GetWorkCells({ workFacilityID: selectedWorkFacility?.id })
			if (resp.ok) {
				setWorkCells(resp.result.workCells)
			} else {
				addNotification({
					alertLevel: AlertLevel.Danger,
					title: `Failed to request work cells.`,
				})
			}
		}
		getWorkCells()
	}, [selectedWorkFacility])

	React.useEffect(() => {
		const getWorkTrackingStates = async () => {
			setLoading(true)
			const resp = await GetWorkTrackingStates({
				workFacilityIDs: selectedWorkFacility?.id ? [selectedWorkFacility?.id] : undefined,
				workCellIDs: selectedWorkCell?.id ? [selectedWorkCell?.id] : undefined,
				timeBegin: startOfDay(date),
				timeEnd: endOfDay(date),
			})
			if (!resp.ok) {
				return
			}
			const workTrackingStates = resp.result.workTrackingStates
			const newEntityIDMap = {}
			for (const [, entities] of Object.entries(resp.result)) {
				if (!Array.isArray(entities)) {
					continue
				}
				for (const entity of entities) {
					newEntityIDMap[entity?.id] = entity
				}
			}
			setEntityIDMap(newEntityIDMap)
			setWorkTrackingStates(workTrackingStates)
			setLoading(false)
		}
		getWorkTrackingStates()
	}, [selectedWorkFacility, selectedWorkCell, date])

	React.useEffect(() => {
		let newEarliestHour = 24
		let newLatestHour = 0
		const workTrackingStateGroups =
			workTrackingStates?.map((wts): WorkTrackingStateGroup => {
				// Types as defined in TrackingSheetForm.tsx
				const actor = entityIDMap[wts.actorID] as Worker | WorkCellServer
				const subject = entityIDMap[wts.subjectID] as
					| Assembly
					| Customer
					| Job
					| Release
					| Shipment
					| Tag
					| WorkCell
					| WorkFacility
					| WorkResource
					| Worker
				const timezone =
					(entityIDMap[wts.locationID] as WorkFacility)?.timezone ??
					(entityIDMap[wts?.workFacilityID] as WorkFacility)?.timezone ??
					selectedWorkFacility?.timezone ??
					browserTimezone
				newEarliestHour = Math.min(
					newEarliestHour,
					utcToZonedTime(wts.reportedStart, timezone).getHours(),
				)
				newLatestHour = Math.max(
					newLatestHour,
					utcToZonedTime(wts.reportedFinish, timezone).getHours(),
				)
				return {
					action: (entityIDMap[wts.workActionID] as WorkAction)?.name,
					actionDescription: (entityIDMap[wts.workActionID] as WorkAction)?.description,
					location: (entityIDMap[wts.locationID] as WorkFacility | WorkCell | WorkResource)?.name,
					actor: (actor as WorkCellServer)?.name ?? WorkerUtils.name(actor as Worker),
					subject:
						(
							subject as
								| Assembly
								| Customer
								| Job
								| Release
								| Shipment
								| WorkCell
								| WorkFacility
								| WorkResource
						)?.name ??
						(subject as Tag)?.description ??
						WorkerUtils.name(subject as Worker),
					from: utcToZonedTime(wts.reportedStart, timezone),
					to: utcToZonedTime(wts.reportedFinish, timezone),
					timezone:
						getBrowserTimezoneOffset(timezone) !== 0 ? `(${formatTimezone(timezone, true)})` : "",
				}
			}) ?? []
		newEarliestHour = condensed ? newEarliestHour : Math.min(8, newEarliestHour)
		newLatestHour = condensed ? newLatestHour : Math.max(19, newLatestHour)
		setEarliestHour(newEarliestHour)
		const numRows = newLatestHour - newEarliestHour + 1
		setNumRows(numRows)
		setRowDivisions(Math.round(12 * 0.9 ** numRows))
		setGroups(
			workTrackingStateGroups.reduce(
				(acc, wts) => {
					const key = wts[groupBy]
					if (!acc[key]) {
						acc[key] = []
					}
					acc[key].push(wts)
					return acc
				},
				{} as { [key: string]: WorkTrackingStateGroup[] },
			),
		)
	}, [workTrackingStates, entityIDMap, groupBy, selectedWorkFacility, condensed])

	const wtsgToGridRows = (wtsg: WorkTrackingStateGroup) => {
		const fromHours = wtsg.from.getHours()
		const fromMinutes = wtsg.from.getMinutes()
		const toHours = wtsg.to.getHours()
		const toMinutes = wtsg.to.getMinutes()
		const minutesToCol = (minutes: number) => Math.round(minutes / (60 / rowDivisions))
		const n =
			fromHours === toHours &&
			minutesToCol(fromMinutes) === minutesToCol(toMinutes) &&
			(fromMinutes / (60 / rowDivisions)) % 1 > 0.5
				? 0
				: -1
		return `${(fromHours - earliestHour) * rowDivisions + minutesToCol(fromMinutes) - n} / ${
			(toHours - earliestHour) * rowDivisions + minutesToCol(toMinutes) - n
		}`
	}

	return (
		<PageContainer>
			<ReportsHeader view="work-tracking-calendar" />
			<div className="flex gap-4 mt-4">
				<div className="min-w-[12rem] max-w-[20rem] flex flex-col">
					<div className="flex items-center place-content-center order-last md:order-first text-gray-600 text-sm mb-4">
						<button
							className="flex gap-2 items-center p-2 rounded-l-full bg-gray-400 hover:text-gray-700 hover:bg-gray-500 border-r-[1px] border-gray-500"
							onClick={() => {
								setDate((p) => add(p, { days: -1 }))
							}}
						>
							<Icon name="ChevronLeft" size="lg" />
							Prev day
						</button>
						<button
							className="flex gap-2 items-center p-2 rounded-r-full bg-gray-400 hover:text-gray-700 hover:bg-gray-500 border-l-[1px] border-gray-500"
							onClick={() => {
								setDate((p) => add(p, { days: 1 }))
							}}
						>
							Next day
							<Icon name="ChevronRight" size="lg" />
						</button>
					</div>
					<DateTimeInput
						clearable={false}
						defaultValue={JSON.stringify(date)}
						value={date}
						name="date"
						label="Date"
						disabled={loading}
						onChange={(date) => {
							setDate((p) => date ?? p)
						}}
						timezone={selectedWorkFacility?.timezone ?? browserTimezone}
					/>
					<Select
						disabled={loading}
						name="groupBy"
						label="Group By"
						clearable={false}
						value={{ value: groupBy, label: _.startCase(groupBy) }}
						options={Object.values(GroupBy).map((groupBy) => ({
							value: groupBy,
							label: _.startCase(groupBy),
						}))}
						onChange={(v) => {
							setGroupBy(v[0].value as GroupBy)
						}}
					/>
					<Select
						disabled={loading}
						name="workFacilities"
						label="Work Facilities"
						placeholder="All"
						options={workFacilities.map((workFacility) => ({
							value: workFacility.id,
							label: workFacility.name,
						}))}
						onChange={(v) => {
							const ids = v.map((o) => o.value)
							setSelectedWorkFacility(
								workFacilities.filter((workFacility) => ids.includes(workFacility.id))?.[0],
							)
						}}
					/>
					<Select
						disabled={loading}
						name="workCells"
						label="Work Cells"
						placeholder="All"
						options={workCells.map((workCell) => ({
							value: workCell.id,
							label: workCell.name,
						}))}
						onChange={(v) => {
							const ids = v.map((o) => o.value)
							setSelectedWorkCell(workCells.filter((workCell) => ids.includes(workCell.id))?.[0])
						}}
					/>
					<Toggle
						disabled={loading}
						name="condensed"
						label="Condensed"
						defaultChecked={condensed}
						onChange={(v) => {
							setCondensed(v)
						}}
					/>
					{loading && <Icon name="Cog" spin size="2x" className="text-gray-400 self-center" />}
				</div>

				{Object.entries(groups).length === 0 ? (
					<div className="text-gray-600 flex flex-col gap-2 mt-2 w-full pl-4 border-gray-300 border-l place-content-center place-items-center">
						<Icon name="ExclamationTriangle" size="2x" className="text-gray-400" />
						No work tracking states found for the given date
					</div>
				) : (
					<div className="pl-4 border-gray-300 border-l overflow-auto max-h-[calc(100vh-180px)]">
						<div className="flex box-border relative min-h-[calc(100vh-180px)] !min-w-fit">
							<div
								className="grid border-b border-r border-gray-400 bg-gray-200 px-2"
								style={{ gridTemplateRows: `50px repeat(${numRows},1fr)` }}
							>
								<div className=""></div>
								{[...Array(numRows)].map((_, i) => {
									const hour = earliestHour + i
									const pm = hour % 24 > 12
									return (
										<span key={i} className="text-center text-sm self-center font-bold min-w-max">
											{`${((hour - 1) % 12) + 1} ${pm ? "PM" : "AM"}`}
										</span>
									)
								})}
							</div>
							<div
								className="gantt__row gantt__row--lines"
								style={{
									gridTemplateRows: `50px repeat(${numRows},1fr)`,
									height: "-webkit-fill-available",
								}}
							>
								{[...Array(numRows + 1)].map((_, i) => (
									<span key={i} className="border-b border-gray-400"></span>
								))}
							</div>
							{Object.entries(groups)?.map(([groupName, states]) => (
								<div key={groupName} className="gantt__row ">
									<div className="gantt__row-first">
										{`${groupName.replaceAll("-", " ")} ${states[0].timezone}`.trim()}
									</div>
									<ul
										className="gantt__row-bars"
										style={{ gridTemplateRows: `repeat(${numRows * rowDivisions},1fr)` }}
									>
										{states?.map((wtsg, i) => {
											const gridRow = wtsgToGridRows(wtsg)
											const rowCount = Math.max(
												1,
												gridRow.split("/").reduce((acc, curr) => Number(curr) - acc, 0),
											)
											const showFromAMPM =
												(wtsg.from.getHours() < 12 && wtsg.to.getHours() >= 12) ||
												differenceInHours(wtsg.to, wtsg.from) >= 12
											const timeString = `${format(
												wtsg.from,
												showFromAMPM ? "hh:mmaaa" : "hh:mm",
											)} — ${format(wtsg.to, "hh:mmaaa")} ${wtsg.timezone}`
											return (
												<li
													key={i}
													className="bg-yellow-500 text-gray-900 border-yellow-600 border"
													style={{
														gridRow,
													}}
												>
													<Tooltip
														content={
															<>
																<p>{wtsg.action}</p>
																<p>{wtsg.subject}</p>
																<p>{wtsg.location}</p>
																<p>{wtsg.actor}</p>
																<p>{timeString}</p>
															</>
														}
														className="h-full"
													>
														<div
															className={clsx(
																"px-1 py-[0.125rem] h-full truncate",
																rowCount >= 2 && "flex flex-col ",
															)}
														>
															{groupBy !== GroupBy.Action && (
																<span className={clsx("truncate", rowCount <= 3 && "inline mr-1")}>
																	{wtsg.action}
																</span>
															)}
															{groupBy !== GroupBy.Subject && (
																<span className={clsx("truncate", rowCount <= 3 && "inline")}>
																	{wtsg.subject}
																</span>
															)}
															{rowCount > 1 && groupBy !== GroupBy.Actor && (
																<span className="font-normal truncate">{wtsg.actor}</span>
															)}
															{rowCount > 1 && groupBy !== GroupBy.Location && (
																<span className="font-normal truncate">{wtsg.location}</span>
															)}
															{rowCount > 2 && (
																<span className="font-normal truncate">{timeString}</span>
															)}
														</div>
													</Tooltip>
												</li>
											)
										})}
									</ul>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</PageContainer>
	)
}
