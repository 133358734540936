import React from "react"
import { Helmet } from "react-helmet-async"

import { Logo, Notifications, Link } from "@app/components"

const SplashImageCount = 23

const easeInOutSine = (t: number, b: number, _c: number, d: number): number => {
	const c = _c - b
	return (-c / 2) * (Math.cos((Math.PI * t) / d) - 1) + b
}

const useAnimationFrame = (callback: (deltaTime: number) => void) => {
	// Use useRef for mutable variables that we want to persist
	// without triggering a re-render on their change
	const requestRef = React.useRef(0)
	const previousTimeRef = React.useRef(0)

	const animate = (time: number): void => {
		if (previousTimeRef.current != undefined) {
			const deltaTime = time - previousTimeRef.current
			callback(deltaTime)
		}
		previousTimeRef.current = time
		requestRef.current = requestAnimationFrame(animate)
	}

	React.useEffect(() => {
		requestRef.current = requestAnimationFrame(animate)
		return () => cancelAnimationFrame(requestRef.current)
	}, [])
}

const randomSplash = (not?: string): string => {
	let result = not
	while (result === not) {
		result = `splash-${1 + ((Math.random() * SplashImageCount) | 0)}.jpg`
	}
	return result || ""
}

type SplashLayoutProps = {
	children: React.ReactNode
}

export const SplashLayout: React.FC<SplashLayoutProps> = (props) => {
	const { children } = props
	const [srcs, setSrcs] = React.useState<string[]>(() => {
		const first = randomSplash()
		return [first, randomSplash(first)]
	})
	const [duration, setDuration] = React.useState<number>(0)
	const setWaiting = React.useState<number>(0)[1]

	useAnimationFrame((deltaTime) => {
		let wait = 0
		setWaiting((prev) => {
			wait = prev + deltaTime
			return wait
		})
		if (wait > 5000) {
			let swap = false
			setDuration((prev) => {
				const next = (prev + deltaTime * 0.05) % 100
				if (Math.floor(next) === 0 && Math.floor(prev) !== 0) {
					swap = true
				}
				return next
			})

			if (swap) {
				setSrcs((prevSrcs) => {
					const next = [prevSrcs[1], randomSplash(prevSrcs[1])]
					return next
				})
				setWaiting(0)
			}
		}
	})

	const currOpacity = easeInOutSine(duration, 1, 0, 100)
	const nextOpacity = easeInOutSine(duration, 0, 1, 100)

	return (
		<>
			<Helmet>
				<meta name="viewport" content="width=device-width,initial-scale=1" />
			</Helmet>
			<Notifications />
			<div className="min-h-screen bg-white flex">
				<div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
					<div className="mx-auto w-full max-w-sm lg:w-96">
						<div className="text-center">
							<div className="inline-block text-black">
								<Link className="hover:text-gray-700" to="/">
									<Logo width={160} />
								</Link>
							</div>
						</div>
						<div>{children}</div>
					</div>
				</div>
				<div className="hidden lg:block relative w-0 flex-1">
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src={`/assets/img/splash/${srcs[0]}`}
						alt=""
						style={{ opacity: currOpacity }}
					/>
					<img
						className="absolute inset-0 h-full w-full object-cover"
						src={`/assets/img/splash/${srcs[1]}`}
						alt=""
						style={{ opacity: nextOpacity }}
					/>
				</div>
			</div>
		</>
	)
}
