import React from "react"
import _ from "lodash"

import { Icon, Label } from "@app/components"
import { useSession } from "@app/contexts"
import { formatLength, MM_PER_IN } from "@app/util"

interface IInputLengthProps {
	alt?: string
	autoFocus?: boolean
	className?: string
	collapse?: boolean
	value?: number
	defaultValue?: number
	disabled?: boolean
	error?: string
	fieldInfo?: string
	hide?: boolean
	hint?: string
	label?: string
	metric?: boolean
	name: string
	onChange?: (v: number | undefined) => void
	onBlur?: (e: React.FormEvent<HTMLInputElement>) => void
	onError?: (v: string) => void
	range?: number[][]
}

const clampStep = (value: number, step: number): number => {
	return value - Math.round(value % step)
}

export const InputLength: React.FC<IInputLengthProps> = (props) => {
	const { t } = useSession()

	const {
		alt = "",
		autoFocus = false,
		className = "",
		collapse = false,
		value: valueIn,
		defaultValue,
		disabled = false,
		error = "",
		fieldInfo,
		hide = false,
		hint,
		label,
		metric = false,
		name,
		onBlur = _.noop,
		onChange = _.noop,
		onError = _.noop,
		range,
	} = props

	const ref = React.createRef<HTMLInputElement>()
	const [mounted, setMounted] = React.useState<boolean>(false)
	const [validationError, setValidationError] = React.useState<string | undefined>()
	const [value, setValue] = React.useState<number | undefined>(
		// Values are stored in mm.
		metric ? defaultValue : defaultValue ? defaultValue / MM_PER_IN : undefined,
	)

	const errorMessage = (error || validationError)?.replace("$field", label || t("validation.this"))
	// Range format: [[inMin, inMax, inStep?],[mmMin, mmMax, mmStep?]].
	const min = _.get(range, `[${metric ? 1 : 0}][0]`, 0) as number
	const max = _.get(range, `[${metric ? 1 : 0}][1]`, 0) as number
	const step = _.get(range, `[${metric ? 1 : 0}][2]`, metric ? 0.1 : 0.125) as number

	React.useEffect(() => {
		onError(validationError)
	}, [validationError])

	React.useEffect(() => {
		if (!mounted) {
			setMounted(true)
			return
		}
		if (!ref.current || !_.isNumber(value)) {
			return
		}
		if (metric) {
			const converted = value * MM_PER_IN
			const mm = Math.max(min, Math.min(max, clampStep(converted, step)))
			setValue(mm)
			ref.current.value = formatLength(metric, mm)
		} else {
			const converted = value / MM_PER_IN
			const inches = Math.max(min, Math.min(max, clampStep(converted, step)))
			setValue(inches)
			ref.current.value = formatLength(metric, inches)
		}
	}, [metric])

	React.useEffect(() => {
		if (valueIn !== value) {
			setValue(metric || !valueIn ? valueIn : valueIn / MM_PER_IN)
		}
	}, [valueIn])

	React.useEffect(() => {
		onChange(value)
	}, [value])

	let classes = "bg-white p-2 block w-full disabled:text-gray-500 disabled:bg-gray-300 focus:z-10 "
	if (_.isEmpty(errorMessage)) {
		classes +=
			"border border-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
	} else {
		classes +=
			"border border-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
	}

	if (hide) {
		return null
	}

	const changeHandler = (e: React.FormEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement
		if (metric) {
			const mm = parseFloat(target.value)
			if (isNaN(mm)) {
				setValue(undefined)
			} else {
				setValue(mm)
			}
		} else {
			const negative = target.value[0] === "-" ? -1 : 1
			const parts = target.value.split("-").filter((v) => v)
			if (_.size(parts) >= 2) {
				const feet = parseFloat(parts[0])
				const inches = parseFloat(parts[1])
				if (isNaN(feet) || isNaN(inches)) {
					setValue(undefined)
				} else {
					setValue(negative * (12 * feet + inches))
				}
			} else {
				const feet = parseFloat(parts[0])
				if (isNaN(feet)) {
					setValue(undefined)
				} else {
					setValue(negative * 12 * feet)
				}
			}
		}
	}

	const blurHandler = (e: React.FormEvent<HTMLInputElement>) => {
		if (ref.current && _.isNumber(value)) {
			if (_.clamp(value, min, max) !== value) {
				setValidationError(
					t("validation.inRangeUnits", {
						min: formatLength(metric, min),
						max: formatLength(metric, max),
						units,
					}),
				)
			} else {
				const next = formatLength(metric, Math.max(min, Math.min(max, clampStep(value, step))))
				ref.current.value = next
				setValidationError(undefined)
			}
		}
		onBlur(e)
	}

	const units = metric ? "mm" : "ft-in"

	let rangeSummary = ""
	if (metric) {
		rangeSummary = `${formatLength(metric, min)} to ${formatLength(metric, max)} ${
			step > 1 ? `(by ${step} mm)` : ""
		} `
	} else {
		rangeSummary = `${formatLength(metric, min)} to ${formatLength(metric, max)} ${
			step > 1 ? `(by ${step} in)` : ""
		} `
	}

	return (
		<div className="mt-1 relative">
			{_.isNil(label) || collapse ? null : (
				<Label name={name} fieldInfo={fieldInfo} className="flex gap-1">
					{`${label} (${units})`}&#8203;
					{alt && (
						<span title={alt}>
							<Icon name="InfoCircle" fixedWidth className="text-gray-500" />
						</span>
					)}
				</Label>
			)}
			<div className="relative">
				<input
					aria-label={label}
					autoFocus={autoFocus}
					className={`${classes} ${className} `}
					defaultValue={formatLength(metric, value)}
					disabled={disabled}
					name={name}
					onBlur={blurHandler}
					onChange={changeHandler}
					ref={ref}
					type="text"
				/>
				<div
					className={`absolute top-0 right-0 mt-2.5 mr-2 text-sm rounded-full px-2 ${
						disabled ? "text-gray-200 bg-gray-400" : "text-white bg-gray-500"
					} `}
				>
					{rangeSummary}
				</div>
			</div>

			{_.isEmpty(hint) || !_.isEmpty(errorMessage) ? (
				<p className="text-xs text-red-500 mt-1">{errorMessage}&#8203;</p>
			) : null}
			{!_.isEmpty(hint) && _.isEmpty(errorMessage) ? (
				<p className="text-xs text-right text-gray-500 mt-1">{hint}&#8203;</p>
			) : null}
		</div>
	)
}
