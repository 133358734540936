import React from "react"
import _ from "lodash"

import { Select, FormActions, Button, Input, Toggle } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveWorkActionParams } from "@app/api"
import { useFormHelpers } from "@app/hooks"
import { WorkActionMaxDurationSecondsOptions } from "@app/domain"

interface IWorkActionFormProps {
	busy?: boolean
	defaultParams: SaveWorkActionParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveWorkActionParams) => void
}

export const WorkActionForm: React.FC<IWorkActionFormProps> = (props) => {
	const { onSave, defaultParams, errors, disabled = false, busy = false } = props

	const { t } = useSession()
	const { params, inputHandler, valueHandler, selectHandler } =
		useFormHelpers<SaveWorkActionParams>(defaultParams)

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	let maxDurationSecondsDisabled = false
	let maxDurationSecondsOptions = [...WorkActionMaxDurationSecondsOptions]

	// A work action cannot be changed between a timestamp and duration once
	// saved because this would be inconsistent with historical tracking data.
	if (params.id) {
		if (params.maxDurationSeconds.toString() === "0") {
			maxDurationSecondsDisabled = true
		} else {
			maxDurationSecondsOptions = maxDurationSecondsOptions.slice(1)
		}
	}

	const defaultMaxDurationSeconds = _.find(
		maxDurationSecondsOptions,
		(opt) => opt.value === params.maxDurationSeconds.toString(),
	)

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.id === ""}
						value={params.name}
						hint={t("workAction.hints.name")}
						placeholder={t("workAction.placeholders.name")}
						label={t("workAction.labels.name")}
						name="name"
						error={_.get(errors, "name")}
						onChange={inputHandler("name", (params) => {
							const name = _.trim((params.name || "").toLowerCase()).replace(/[^0-9a-z-]+/, "-")
							return { ...params, name }
						})}
						type="text"
					/>
					<div>&nbsp;</div>
					<Toggle
						label={t("workAction.labels.actorIsSubject")}
						hint={t("workAction.hints.actorIsSubject")}
						defaultChecked={params.actorIsSubject}
						disabled={disabled || busy}
						error={_.get(errors, "actorIsSubject")}
						onChange={valueHandler("actorIsSubject")}
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.description}
						label={t("workAction.labels.description")}
						hint={t("workAction.hints.description")}
						name="description"
						error={_.get(errors, "description")}
						onChange={inputHandler("description")}
						type="text"
					/>
					<Select
						disabled={disabled || busy || maxDurationSecondsDisabled}
						error={_.get(errors, "maxDurationSeconds")}
						hint={t("workAction.hints.maxDurationSeconds")}
						label={t("workAction.labels.maxDurationSeconds")}
						name="maxDurationSeconds"
						defaultValue={defaultMaxDurationSeconds}
						onChange={selectHandler("maxDurationSeconds", false, (params) => {
							const maxDurationSeconds = parseInt(params.maxDurationSeconds?.toString() || "0")
							return { ...params, maxDurationSeconds }
						})}
						options={maxDurationSecondsOptions}
					/>
					<div>&nbsp;</div>
					<Toggle
						label={t("workAction.labels.resolveReleaseUnitStatus")}
						hint={t("workAction.hints.resolveReleaseUnitStatus")}
						defaultChecked={params.resolveReleaseUnitStatus}
						disabled={disabled || busy}
						error={_.get(errors, "resolveReleaseUnitStatus")}
						onChange={valueHandler("resolveReleaseUnitStatus")}
					/>
					<Toggle
						label={t("workAction.labels.disabled")}
						hint={t("workAction.hints.disabled")}
						defaultChecked={params.disabled}
						disabled={disabled || busy}
						error={_.get(errors, "disabled")}
						onChange={valueHandler("disabled")}
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("workAction.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
