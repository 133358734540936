import React from "react"

import { Await, PageContainer, WorkFacilityDetails } from "@app/components"
import { WorkFacilityHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkFacility } from "@app/domain"

export const WorkFacilityShowPage: React.FC = () => {
	const [workFacility, workFacilityLoading] = useEntity<WorkFacility>(EntityType.WorkFacility)

	return (
		<PageContainer>
			<WorkFacilityHeader workFacility={workFacility} view="show" />
			<Await
				loading={workFacilityLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<WorkFacilityDetails workFacility={workFacility} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
