import React from "react"
import _ from "lodash"

import { Badge, Button, ButtonIcon, Input } from "@app/components"
import { useSession } from "@app/contexts"
import { AttributeParams, newAttributeParams } from "@app/api"
import { useFormHelpers } from "@app/hooks"

interface IAttributeForm {
	busy?: boolean
	defaultParams: AttributeParams
	disabled?: boolean
	errors: { [key: string]: string }
	onChange: (params?: AttributeParams) => void
}

export const AttributeForm: React.FC<IAttributeForm> = (props) => {
	const { defaultParams, errors, disabled = false, busy = false, onChange = _.noop } = props

	const { t } = useSession()
	const { params, setParams, inputHandler } = useFormHelpers<AttributeParams>(defaultParams)

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const removeHandler = () => {
		onChange(undefined)
	}

	return (
		<div className="flex flex-row justify-between gap-x-2">
			<div className="w-1/3">
				<Input
					collapse
					autoComplete="off"
					defaultValue={params.name}
					disabled={disabled || busy}
					error={_.get(errors, "name")}
					name="name"
					onBlur={(e) => {
						const name = _.kebabCase(e.target.value)
						e.target.value = name
						setParams((params) => _.set({ ...params }, "name", name))
					}}
					onChange={inputHandler("name")}
					placeholder={t("attribute.placeholders.name")}
					type="text"
				/>
			</div>
			<div className="w-2/3">
				<Input
					collapse
					autoComplete="off"
					defaultValue={params.value}
					disabled={disabled || busy}
					error={_.get(errors, "value")}
					name="value"
					onChange={inputHandler("value")}
					placeholder={t("attribute.placeholders.value")}
					type="text"
				/>
			</div>
			<div className="pt-3">
				<ButtonIcon
					tooltip={t("attribute.buttons.remove")}
					icon="Minus"
					onClick={removeHandler}
					disabled={disabled}
					busy={busy}
				/>
			</div>
		</div>
	)
}

interface IAttributesFormProps {
	busy?: boolean
	defaultParams?: AttributeParams[]
	disabled?: boolean
	errors: { [key: string]: string }
	errorsKey: string
	onChange: (params: AttributeParams[]) => void
}

export const AttributesForm: React.FC<IAttributesFormProps> = (props) => {
	const { defaultParams = [], errors, disabled = false, busy = false, errorsKey, onChange } = props

	const { t } = useSession()

	const [params, setParams] = React.useState<AttributeParams[]>(() =>
		_.chain(defaultParams)
			.map((attr) => {
				return { ...attr }
			})
			.sortBy("name")
			.value(),
	)
	const [keys, setKeys] = React.useState<string[]>(() => _.map(defaultParams, () => _.uniqueId()))

	React.useEffect(() => {
		onChange(params)
	}, [params])

	const changeHandler = (index: number): ((attr?: AttributeParams) => void) => {
		return (attr?: AttributeParams): void => {
			if (_.isNil(attr)) {
				setKeys((keys) => {
					const result = [...keys]
					result.splice(index, 1)
					return result
				})
				setParams((params) => {
					const result = [...params]
					result.splice(index, 1)
					return result
				})
			} else {
				setParams((params) => {
					const result = [...params]
					result[index] = attr
					return result
				})
			}
		}
	}

	const addHandler = (index: number): (() => void) => {
		return () => {
			setKeys((keys) => {
				const next: string[] = []
				_.each(keys, (key, i) => {
					if (i === index) {
						next.push(_.uniqueId())
					}
					next.push(key)
				})
				if (index === _.size(next)) {
					next.push(_.uniqueId())
				}
				return next
			})
			setParams((params) => {
				const next: AttributeParams[] = []
				_.each(params, (attr, i) => {
					if (i === index) {
						next.push(newAttributeParams())
					}
					next.push({ ...attr })
				})
				if (index === _.size(next)) {
					next.push(newAttributeParams())
				}
				return next
			})
		}
	}

	return (
		<div className="mt-4">
			<div className="flex flex-row justify-between">
				<h2 className="text-md mb-4 text-gray-700">
					{t("attribute.header")} <Badge quantity>{_.size(params)}</Badge>
				</h2>
				<div>
					<Button
						disabled={disabled}
						busy={busy}
						type="button"
						label={t("attribute.buttons.add")}
						onClick={addHandler(0)}
						small
						add
					/>
				</div>
			</div>
			<div>
				{_.map(params, (attr, i) => (
					<AttributeForm
						key={keys[i]}
						errors={_.reduce(
							errors,
							(acc, err: string, k: string) => {
								const prefix = `${errorsKey}[${i}].`
								if (_.startsWith(k, prefix)) {
									acc[k.slice(_.size(prefix))] = err
								}
								return acc
							},
							{},
						)}
						defaultParams={attr}
						disabled={disabled}
						onChange={changeHandler(i)}
					/>
				))}
			</div>
		</div>
	)
}
