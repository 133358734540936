import _ from "lodash"

import { rpc } from "@app/api"

import type { Result } from "@app/api"
import type { User } from "@app/domain"

export type UpdateAccountParams = {
	firstName: string
	lastName: string
	timezone: string
}

export type UpdateAccountResult = {
	user: User
}

export const newUpdateAccountParams = (user?: User): UpdateAccountParams => {
	return {
		firstName: _.get(user, "firstName", ""),
		lastName: _.get(user, "lastName", ""),
		timezone: _.get(user, "timezone", ""),
	}
}

export const UpdateAccount = async (
	params: UpdateAccountParams,
): Promise<Result<UpdateAccountResult>> => {
	const data = await rpc("UpdateAccount", params, { minDuration: 300 })
	return data as Result<UpdateAccountResult>
}

export type ChangeAccountPasswordParams = {
	currentPassword: string
	newPassword: string
}

export const ChangeAccountPassword = async (
	params: ChangeAccountPasswordParams,
): Promise<Result<unknown>> => {
	const data = await rpc("ChangeAccountPassword", params, { minDuration: 300 })
	return data as Result<unknown>
}

export type ChangeAccountPINParams = {
	newPIN: string
}

export const ChangeAccountPIN = async (
	params: ChangeAccountPINParams,
): Promise<Result<unknown>> => {
	const data = await rpc("ChangeAccountPIN", params, { minDuration: 300 })
	return data as Result<unknown>
}
