import React from "react"
import _ from "lodash"

import { Await, PageContainer } from "@app/components"
import { Release, EntityType, ReleaseItem } from "@app/domain"
import { ReleaseUnitCreateForm } from "@app/forms"
import { ReleaseHeader } from "@app/headers"
import { useEntity, useRelation } from "@app/hooks"

export const ReleaseUnitCreatePage: React.FC = () => {
	const [release, releaseLoading] = useEntity<Release>(EntityType.Release)
	const releaseID = _.get(release, "id")
	const [releaseItems, releaseItemsLoading] = useRelation<ReleaseItem[]>(
		EntityType.ReleaseItem,
		releaseID,
	)

	return (
		<PageContainer>
			<ReleaseHeader view="releaseUnits/create" release={release} />
			<Await
				loading={releaseLoading || releaseItemsLoading || _.isNil(release)}
				then={() => (
					<div className="max-w-auto">
						{release && releaseItems && (
							<ReleaseUnitCreateForm release={release} releaseItems={releaseItems} />
						)}
					</div>
				)}
			/>
		</PageContainer>
	)
}
