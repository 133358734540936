import React from "react"

import { Await, PageContainer, ContactDetails } from "@app/components"
import { ContactHeader } from "@app/headers"
import { Contact, EntityType } from "@app/domain"
import { useEntity } from "@app/hooks"

export const ContactShowPage: React.FC = () => {
	const [contact, contactLoading] = useEntity<Contact>(EntityType.Contact)

	return (
		<PageContainer>
			<ContactHeader contact={contact} view="show" />
			<Await
				loading={contactLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<ContactDetails contact={contact} />
					</div>
				)}
			/>
		</PageContainer>
	)
}
