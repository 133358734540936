import React from "react"
import _ from "lodash"
import { useParams } from "react-router-dom"
import clsx from "clsx"
import qs from "query-string"

import { Await, PageContainer } from "@app/components"
import { WorkCellProgramHeader } from "@app/headers"
import { useEntity } from "@app/hooks"
import { EntityType, WorkCellProgram } from "@app/domain"
import { fromUUID } from "@app/util"

const Labels = ["x", "y", "z", "xº", "yº", "zº", "track"]

const ColoredPair = (props: {
	name: string
	value: string | (null | string)[] | null
	number?: boolean
}) => {
	const { name, value, number } = props
	const val = (value || "").toString()
	const numeric = _.isNil(number) ? !!val.match(/^[0-9.-]+$/) : number
	return (
		<span className="">
			<span className="text-white">{name}</span>
			<span className="text-blue-100">=</span>
			<span className={clsx(numeric ? "text-orange-300" : "text-green-200")}>{value}</span>{" "}
		</span>
	)
}

export const WorkCellProgramProgramsPage: React.FC = () => {
	const { programID } = _.assign({ programID: "" }, useParams())

	const [workCellProgram, workCellProgramLoading] = useEntity<WorkCellProgram>(
		EntityType.WorkCellProgram,
	)

	const robotProgram =
		workCellProgram?.robotPrograms.find((rp) => fromUUID(rp.id) === programID) ??
		workCellProgram?.robotPrograms[0] // Default is first program when clicking into parent tab (when programID is undefined).

	const tableHeader = (
		<tr className="text-center">
			<th className="py-2 w-16">Line</th>
			<th className="py-2">Command</th>
			<th className="py-2">Values</th>
			<th className="py-2">Comment</th>
			<th className="py-2">Meta</th>
		</tr>
	)

	let line = 0
	const tableRows = _.get(robotProgram, "data", []).map((row: string[], i: number) => {
		line++
		const command = _.toUpper(_.first(row))
		if (command === "HEADER" || command === "CAPABILITIES") {
			const parsed = qs.parse(row[8])
			const content = _.map(parsed, (value, name) => (
				<ColoredPair key={name} name={name} value={value} />
			))
			return (
				<tr
					key={i}
					className={clsx(
						"divide-x divide-gray-600 text-white bg-gray-700",
						command === "CAPABILITIES" && "divide-y",
					)}
				>
					<td className="px-2 py-0.5 text-right text-gray-500">{line}</td>
					<td className="px-2 py-0.5">{command}</td>
					<td className="px-2 py-1" colSpan={3}>
						{content}
					</td>
				</tr>
			)
		} else if (command === "NOTES") {
			return (
				<tr key={i} className={"divide-x divide-y divide-gray-600 text-white bg-gray-700"}>
					<td className="px-2 py-0.5 text-right text-gray-500">{line}</td>
					<td className="px-2 py-0.5">{command}</td>
					<td className="px-2 py-1" colSpan={3}>
						<div className="max-w-md">{row[8]}</div>
					</td>
				</tr>
			)
		}

		const parsed = qs.parse(row[9] || "")
		const meta = _.map(parsed, (value, name) => (
			<ColoredPair key={name} name={name} value={value} />
		))

		let content = <span />
		switch (command) {
			case "JOINT_DATA":
				content = (
					<span>
						{_.times(7, (i) => (
							<ColoredPair key={i} name={Labels[i]} value={row[i + 1]} number={true} />
						))}
					</span>
				)
				break
			case "OPTION":
				content = (
					<span>
						<ColoredPair name="name" value={row[1]} />
						<ColoredPair name="value" value={row[2]} />
					</span>
				)
				break
		}

		return (
			<tr key={i} className={"divide-x divide-gray-700 text-white bg-gray-800"}>
				<td className="px-2 py-0.5 text-right text-gray-500">{line}</td>
				<td className="px-2 py-1">{command}</td>
				<td className="px-2 py-1 whitespace-nowrap">{content}</td>
				<td className="px-2 py-1 text-gray-500">{row[8]}</td>
				<td className="px-2 py-1">{meta}</td>
			</tr>
		)
	})

	return (
		<PageContainer>
			<WorkCellProgramHeader
				workCellProgram={workCellProgram}
				view="programs"
				section={programID}
			/>
			<Await
				loading={workCellProgramLoading}
				then={() => (
					<div className="px-2 sm:px-0">
						<table className="table-auto w-full font-mono text-sm text-gray-400 border-b border-gray-700">
							<thead className="bg-gray-800 text-white text-sm">{tableHeader}</thead>
							<tbody className="divide-y divide-gray-700">{tableRows}</tbody>
						</table>
					</div>
				)}
			/>
		</PageContainer>
	)
}
