import React from "react"
import _ from "lodash"

import {
	EntitySearchSelect,
	Icon,
	Button,
	ButtonIcon,
	FormActions,
	OmniSearch,
} from "@app/components"
import { useSession } from "@app/contexts"
import { EntityType, SearchResult, WorkAction } from "@app/domain"
import {
	TrackingCodeParams,
	TrackingCodeEntity,
	CreateTrackingSheetParams,
	GetWorkActions,
} from "@app/api"
import clsx from "clsx"

interface ITrackingSheetFormProps {
	busy?: boolean
	disabled?: boolean
	errors: { [key: string]: string }
	onSubmit: (params: CreateTrackingSheetParams) => void
}

export const TrackingSheetForm: React.FC<ITrackingSheetFormProps> = (props) => {
	const { errors, onSubmit, disabled = false, busy = false } = props

	const { t } = useSession()
	const emptyTrackingCode = {
		action: undefined,
		locations: [],
		subjects: [],
		actors: [],
	}
	const [trackingCodes, setTrackingCodes] = React.useState<TrackingCodeParams[]>([
		emptyTrackingCode,
	])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSubmit({ trackingCodes })
		return false
	}
	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col gap-10">
				{trackingCodes.map((_, i) => (
					<div className="flex" key={i}>
						<TrackingCodeForm
							onChange={(trackingCode) => {
								setTrackingCodes((prev) => [
									...prev.slice(0, i),
									trackingCode,
									...prev.slice(i + 1),
								])
							}}
							busy={busy}
							disabled={disabled}
							errors={errors}
						/>
						<div className="flex flex-col gap-1 items-center justify-center w-8 text-right">
							<ButtonIcon
								type="button"
								icon="Minus"
								onClick={() => {
									setTrackingCodes((prev) => [...prev.slice(0, i), ...prev.slice(i + 1)])
								}}
							/>
						</div>
					</div>
				))}
			</div>
			<div className="w-full flex justify-center my-6">
				<Button
					type="button"
					label="Add Tracking Code"
					add
					className="w-60"
					onClick={() => {
						setTrackingCodes((prev) => [...prev, emptyTrackingCode])
					}}
				/>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("trackingSheets.buttons.download")}
					errors={errors}
					disabled={
						disabled ||
						trackingCodes.some(
							(t) =>
								!(
									t.action ||
									t.locations.length > 0 ||
									t.subjects.length > 0 ||
									t.actors.length > 0
								),
						)
					}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}

interface ITrackingCodeFormProps {
	onChange: (params: TrackingCodeParams) => void
	errors: { [key: string]: string }
	disabled?: boolean
	busy?: boolean
}

export const TrackingCodeForm: React.FC<ITrackingCodeFormProps> = (props) => {
	const { onChange, disabled = false, busy = false } = props
	const [action, setAction] = React.useState<TrackingCodeEntity>()
	const [locations, setLocations] = React.useState<TrackingCodeEntity[]>([])
	const [subjects, setSubjects] = React.useState<TrackingCodeEntity[]>([])
	const [actors, setActors] = React.useState<TrackingCodeEntity[]>([])

	React.useEffect(() => {
		onChange({
			action,
			locations,
			subjects,
			actors,
		})
	}, [action, locations, subjects, actors])

	const resultList = (
		label: string,
		entities: TrackingCodeEntity[],
		onRemove: (id: string) => void,
	) => (
		<div className="w-full">
			{label && <h2 className="text-gray-700">{entities.length ? label : ""}</h2>}
			<ul className="flex flex-col">
				{entities.map((entity, i) => (
					<li
						key={i}
						className={clsx(
							"flex items-center justify-between px-2 py-1 gap-2",
							i % 2 == 0 && "bg-gray-200",
						)}
					>
						<div className="flex-1">{entity.description}</div>
						<p
							className={clsx(
								"inline rounded-full text-sm text-gray-700 max-w-max px-2",
								i % 2 == 0 ? "bg-gray-300" : "bg-gray-200",
							)}
						>
							{_.startCase(entity.type)}
						</p>
						<button type="button" className="text-gray-500 hover:text-gray-700 hover-item-opacity">
							<Icon name="Times" onClick={() => onRemove(entity.id)} />
						</button>
					</li>
				))}
			</ul>
		</div>
	)
	const searchResultToEntity = (result: SearchResult): TrackingCodeEntity => ({
		id: result.id,
		description: result.name,
		type: _.upperFirst(result.type) as EntityType,
	})
	return (
		<div className="flex flex-col bg-gray-300 w-full">
			<div className="w-full px-6 pt-6"></div>
			<div className="flex flex-row gap-6 px-6 pb-6 w-full">
				<div className="flex flex-col w-full sm:w-1/5">
					{!action ? (
						<EntitySearchSelect
							getResults={(query: string) => GetWorkActions({ query, limit: 5 })}
							respKey="workActions"
							getEntityName={(e: WorkAction) => e.name}
							onSelect={(e: WorkAction) => {
								setAction({ id: e.id, description: e.name, type: EntityType.WorkAction })
							}}
							selectedIDs={[]}
							label="Search Work Actions"
							disabled={disabled}
							busy={busy}
						/>
					) : (
						<>
							{resultList("Selected Work Action", [action], () => {
								setAction(undefined)
							})}
						</>
					)}
				</div>
				<div className="w-1/4">
					{_.isEmpty(locations) ? (
						<OmniSearch
							onChange={(searchResult) =>
								setLocations((prev) => [...prev, searchResultToEntity(searchResult)])
							}
							entityTypes={[EntityType.WorkResource, EntityType.WorkCell, EntityType.WorkFacility]}
							label="Location"
						/>
					) : (
						<>
							{resultList("Selected Location", locations, () => {
								setLocations([])
							})}
						</>
					)}
				</div>
				<div className="w-1/4">
					<OmniSearch
						onChange={(searchResult) =>
							setSubjects((prev) => [...prev, searchResultToEntity(searchResult)])
						}
						selectedIDs={subjects.map((subject) => subject.id)}
						entityTypes={[
							EntityType.Assembly,
							EntityType.Customer,
							EntityType.Job,
							EntityType.Release,
							EntityType.Shipment,
							EntityType.Tag,
							EntityType.WorkCell,
							EntityType.WorkFacility,
							EntityType.WorkResource,
							EntityType.Worker,
						]}
						label="Subjects"
					/>
					<>
						{resultList("", subjects, (id) => {
							setSubjects((prev) => prev.filter((e) => e.id !== id))
						})}
					</>
				</div>
				<div className="w-1/4">
					<OmniSearch
						onChange={(searchResult) =>
							setActors((prev) => [...prev, searchResultToEntity(searchResult)])
						}
						selectedIDs={actors.map((actor) => actor.id)}
						entityTypes={[EntityType.Worker, EntityType.WorkCellServer]}
						label="Actors"
					/>
					<>
						{resultList("", actors, (id) => {
							setActors((prev) => prev.filter((e) => e.id !== id))
						})}
					</>
				</div>
			</div>
		</div>
	)
}
