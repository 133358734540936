import React from "react"
import clsx from "clsx"
import { Transition } from "@headlessui/react"

import { urlTo } from "@app/util"
import { Link, Icon } from "@app/components"

type DropDownSubButtons = {
	label: string
	to: string
}

interface IDropDownButtonProps {
	label: string
	className?: string
	add?: boolean
	subButtons: DropDownSubButtons[]
}

export const DropDownButton: React.FC<
	IDropDownButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
	const { label, className, add = false, subButtons } = props
	const [open, setOpen] = React.useState(false)
	const iconName = add ? "Plus" : ""
	const buttonClasses = add ? "bg-green-500 text-white hover:bg-green-400 " : ""
	const subButtonClasses = add
		? "bg-green-500 text-white hover:bg-green-400"
		: "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
	const subButtonBoxClasses = add ? "bg-green-500" : "bg-white"
	return (
		<div
			className={className}
			// Safari workaround. The dropdown doesn't open without the onClick event because elements aren't focusable in Safari.
			onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				setOpen(true)
				;(e.target as HTMLDivElement)?.focus()
			}}
			onFocus={() => setOpen(true)}
			onBlur={() => setOpen(false)}
		>
			<button
				type="button"
				className={clsx(
					buttonClasses,
					"inline-flex leading-5 items-center w-full justify-center rounded-full font-medium px-4 py-2 focus:outline-none",
				)}
				id="dropDownButton"
				aria-expanded={open}
				aria-haspopup="true"
			>
				{iconName ? <Icon className="mr-2" name={iconName} aria-hidden="true" /> : null}
				{label}
				<Icon className="ml-2" size="sm" name={"ChevronDown"} aria-hidden="true" />
			</button>

			<Transition
				show={open}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<div
					className={clsx(
						subButtonBoxClasses,
						"absolute right-0 mt-2 origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none",
					)}
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex={-1}
				>
					<div className="py-1" role="none">
						{subButtons.map((button, i) => (
							<Link
								key={i}
								className={clsx("block px-4 py-2 text-sm", subButtonClasses)}
								to={urlTo(button.to)}
								role="menuitem"
							>
								{iconName && <Icon className="mr-2" name={iconName} aria-hidden="true" />}
								{button.label}
							</Link>
						))}
					</div>
				</div>
			</Transition>
		</div>
	)
}
