import React from "react"
import _ from "lodash"
import clsx from "clsx"
import { useParams } from "react-router-dom"

import { Title, ToggleConstructionLogo, Icon, DaysOfWeek } from "@app/components"
import {
	GetWorkCells,
	GetWorkFacilityProductionDashboard,
	GetWorkFacilityProductionDashboardResult,
} from "@app/api"
import { useHTTPStatus } from "@app/contexts"
import { toUUID } from "@app/util"
import { differenceInSeconds, format, parseJSON } from "date-fns"
import { WorkCell } from "@app/domain"

const Header = (props: { left?: React.ReactNode; right?: React.ReactNode }) => {
	const { left, right } = props
	const connected = true
	return (
		<div
			className={clsx(
				connected ? "bg-gray-300" : "bg-red-500",
				"fixed top-0 left-0 w-full h-16 text-black z-10",
			)}
		>
			<div
				className={clsx(
					connected ? "text-gray-700" : "text-white",
					"absolute inset-0 p-5 font-semibold",
				)}
			>
				<span className={connected ? "text-gray-500" : "text-white"}>{left}</span>
			</div>
			<div
				className={clsx(
					connected ? "text-gray-700" : "text-white",
					"absolute right-0 top-0 p-5 font-semibold",
				)}
			>
				<span className={connected ? "text-gray-500" : "text-white"}>{right}</span>
			</div>

			<div className="flex justify-center">
				<div className="relative col-start-2 pt-2 mb-[-30px] z-10">
					<ToggleConstructionLogo height="70px" />
				</div>
			</div>
		</div>
	)
}

const Badge = ({ className, children }: { className?: string; children: React.ReactNode }) => {
	return (
		<span
			className={clsx(
				"uppercase inline-flex items-center px-2 py-0.5 rounded-full text-sm font-medium border-opacity-50",
				className,
			)}
		>
			{children}
		</span>
	)
}

const None = ({ value }: { value: string | null | undefined | number }) => {
	if (_.isNil(value)) {
		return <span className="text-gray-500">n/a</span>
	}
	return <span>{value}</span>
}

const Timer = (props: {
	className?: string
	from?: string | number | Date
	started?: string | number | Date
	enabled?: boolean
}) => {
	const { className, from, started = Date.now(), enabled } = props
	const [now, setNow] = React.useState(Date.now())
	React.useEffect(() => {
		if (from) {
			return
		}
		const tick = setTimeout(() => {
			setNow(Date.now())
		}, 1000)
		return () => {
			clearTimeout(tick)
		}
	}, [now, from])
	if (!enabled || !started) {
		return <div className={clsx("font-mono font-medium", className)}>0:00:00</div>
	}
	const diff = differenceInSeconds(parseJSON(from || now), parseJSON(started))
	const hours = (Math.floor(diff / 3600) % 60).toString()
	const minutes = _.padStart((Math.floor(diff / 60) % 60).toString(), 2, "0")
	const seconds = _.padStart(Math.floor(diff % 60).toString(), 2, "0")
	return (
		<div
			className={clsx("font-mono font-medium", className)}
		>{`${hours}:${minutes}:${seconds}`}</div>
	)
}

const formatDate = (t: string | number | Date = "") => {
	try {
		const date = parseJSON(t)
		return `${_.capitalize(DaysOfWeek[date.getUTCDay()])} ${
			date.getUTCMonth() + 1
		}/${date.getUTCDate()}`
	} catch (err) {
		return undefined
	}
}

const formatTime = (t: string | number | Date = "") => {
	try {
		const date = parseJSON(t)
		return format(date, "h:mm:ss aa")
	} catch (err) {
		return undefined
	}
}

export const ProductionDashboardPage: React.FC = () => {
	const id = _.get(useParams(), "id", "")
	const tenantID = Number(_.get(useParams(), "tenantID", 1))
	const workFacilityID = toUUID(id)

	const { setHTTPStatus } = useHTTPStatus()
	const [data, setData] = React.useState<GetWorkFacilityProductionDashboardResult | null>(null)
	const [tick, setTick] = React.useState(0)
	const [workCells, setWorkCells] = React.useState<WorkCell[]>([])
	const [workCellFilterID, setWorkCellFilterID] = React.useState("")

	React.useEffect(() => {
		const load = async () => {
			const resp = await GetWorkCells({ workFacilityID, tenantID })
			if (resp.ok) {
				setWorkCells(_.sortBy(_.get(resp, "result.workCells", []), "name"))
			}
		}
		load()
	}, [])

	React.useEffect(() => {
		let canceled = false
		const load = async () => {
			try {
				const resp = await GetWorkFacilityProductionDashboard({ id: workFacilityID, tenantID })
				if (!resp.ok) {
					setHTTPStatus(404)
				}
				if (!canceled) {
					const result = resp.result
					let releaseUnits = _.map(result.releaseUnits, (v) => {
						const grouped = _.groupBy(v.workTrackingStates, (v) => {
							if (_.isNil(v.paramsStartID) && _.isNil(v.paramsFinishID)) {
								return v.id
							}
							return `${v.paramsStartID}-${v.paramsFinishID}`
						})
						const workTrackingStates = _.map(grouped, (vv) => {
							const workers = _.map(vv, "worker")
							return { ...vv[0], workers }
						})
						return { ...v, workTrackingStates }
					})
					releaseUnits = _.sortBy(releaseUnits, (v) => {
						const unit = v.unit ? _.padStart(v.unit.toString(), 8) : _.repeat("0", 8)
						return `${v.scheduled}-${_.get(v, "tag.referenceID", "")}-${unit}`
					})

					setData({ ...result, releaseUnits })
				}
			} catch (err) {
				setHTTPStatus(500)
			}
		}
		load()
		return () => {
			canceled = true
		}
	}, [tick])

	React.useEffect(() => {
		const id = setTimeout(() => {
			setTick((prev) => prev + 1)
		}, 15000)
		return () => {
			clearTimeout(id)
		}
	}, [tick])

	if (!data) {
		return null
	}

	const Th = (props: React.HTMLProps<HTMLTableCellElement>) => {
		const { className, children, ...rest } = props
		return (
			<td
				className={clsx(
					"text-sm uppercase text-white font-bold px-2 pt-4 pb-2 bg-gray-800",
					className,
				)}
				{...rest}
			>
				{children}
			</td>
		)
	}

	const Td = (props: { status?: string } & React.HTMLProps<HTMLTableCellElement>) => {
		const { className, children, status, ...rest } = props
		return (
			<td
				className={clsx(
					status === "Completed" && "bg-green-200",
					status === "InProgress" && "bg-yellow-200 text-yellow-800 font-medium",
					status === "Canceled" && "line-through bg-gray-200 text-gray-600",
					className,
					"p-2",
				)}
				{...rest}
			>
				{children}
			</td>
		)
	}

	const releaseUnits = _.filter(data.releaseUnits, (unit) => {
		if (workCellFilterID) {
			return _.get(unit, "workCell.id") === workCellFilterID
		}
		return true
	})

	return (
		<>
			<Title>{`${data?.workFacilityName ?? "..."} Production Dashboard`}</Title>
			<Header
				left={data?.workFacilityName}
				right={
					<div className="text-sm">
						<select
							name="workCellID"
							className="py-1 text-sm"
							onChange={(evt) => {
								setWorkCellFilterID(evt.target.value)
							}}
						>
							<option value="">All work cells</option>
							{_.map(workCells, (workCell) => (
								<option key={workCell.id} value={workCell.id}>
									{workCell.name}
								</option>
							))}
						</select>
					</div>
				}
			></Header>
			<div className="mt-16 bg-gray-200 min-h-[calc(100vh-4rem)]">
				<table className="w-full bg-white">
					<thead>
						<tr>
							<Th>Scheduled</Th>
							<Th>Customer</Th>
							<Th>Job</Th>
							{!workCellFilterID && <Th>Work cell</Th>}
							<Th>Description</Th>
							<Th>Control codes</Th>
							<Th>Status</Th>
							<Th>Build type</Th>
							<Th>Tag/Unit</Th>
							<Th colSpan={2}>Time</Th>
						</tr>
					</thead>
					<tbody>
						{_.map(releaseUnits, (v) => {
							const name = { Completed: "CheckCircle", InProgress: "Cog", Canceled: "Canceled" }[
								v.status
							]
							const badgeStyle = clsx(
								"border",
								v.status === "Completed" && "border-green-700",
								v.status === "InProgress" && "border-yellow-800",
								v.status === "Canceled" || (v.status === "Pending" && "border-gray-500 "),
							)
							return [
								<tr key={v.id} className="border-white border-b">
									<Td status={v.status}>{formatDate(v.scheduled)}</Td>
									<Td status={v.status}>{<None value={_.get(v, "customer.name")} />}</Td>
									<Td status={v.status}>{<None value={_.get(v, "job.name")} />}</Td>
									{!workCellFilterID && (
										<Td status={v.status}>{<None value={_.get(v, "workCell.name")} />}</Td>
									)}
									<Td status={v.status}>{<None value={v.description} />}</Td>
									<Td status={v.status}>{<None value={v.controlCodes} />}</Td>
									<Td status={v.status}>
										<Badge className={badgeStyle}>{_.startCase(v.status)}</Badge>
									</Td>
									<Td status={v.status}>
										<Badge className={badgeStyle}>{v.buildType}</Badge>
									</Td>
									<Td status={v.status}>
										{_.has(v, "tag.referenceID") ? (
											`${_.get(v, "tag.referenceID")}-${v.unit}`
										) : (
											<None value={v.unit} />
										)}
									</Td>
									<Td status={v.status}>
										<Timer
											className="text-lg"
											from={v.status === "Completed" ? v.finished : undefined}
											started={v.started}
											enabled={
												!_.isEmpty(v.started) && _.includes(["InProgress", "Completed"], v.status)
											}
										/>
									</Td>
									<Td className="text-center" status={v.status}>
										{name && <Icon size="1x" name={name} spin={v.status === "InProgress"} />}
									</Td>
								</tr>,
								v.status === "InProgress" && (
									<tr key={`${v.id}-in-progress`}>
										<Td className="bg-yellow-100 bg-opacity-50 text-yellow-700">&nbsp;</Td>
										<Td
											className="bg-yellow-100 bg-opacity-50 text-yellow-700 py-0 px-0"
											colSpan={10}
										>
											<table className="">
												<tbody>
													{_.map(v.workTrackingStates, (wts) => {
														const workers = _.map(_.get(wts, "workers"), "name").sort().join(", ")
														const action = _.get(wts, "workAction.name")
														const started = _.get(wts, "started")
														const finished = _.get(wts, "finished")
														const mode = _.get(wts, "mode")
														return (
															<tr key={wts.id}>
																<Td>{workers}</Td>
																<Td>
																	<Badge className="border border-yellow-700">{action}</Badge>
																</Td>
																<Td>{formatTime(started)}</Td>
																<Td>
																	{finished ? (
																		formatTime(finished)
																	) : (
																		<div className="text-center">
																			<Icon name="Cog" spin />
																		</div>
																	)}
																</Td>
																<Td>
																	<Badge className="border border-yellow-700">
																		{_.capitalize(mode)}
																	</Badge>
																</Td>
																<Td>
																	<Timer
																		className="text-md"
																		from={
																			_.includes(["finished", "marked"], mode)
																				? finished
																				: undefined
																		}
																		started={started}
																		enabled={true}
																	/>
																</Td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</Td>
									</tr>
								),
							]
						})}
					</tbody>
				</table>
			</div>
		</>
	)
}
