import React from "react"
import _ from "lodash"

import { Input } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveContactParams } from "@app/api"
import { EmailsForm, PhoneNumbersForm } from "@app/forms"
import { useFormHelpers } from "@app/hooks"

interface IContactFormProps {
	defaultParams: SaveContactParams
	errors: { [key: string]: string }
	disabled?: boolean
	busy?: boolean
	onChange?: (params: SaveContactParams) => void
	referenceID?: string
}

export const ContactForm: React.FC<IContactFormProps> = (props) => {
	const { defaultParams, errors, disabled = false, busy = false, onChange, referenceID } = props

	const { t } = useSession()
	const { params, inputHandler, valueHandler } = useFormHelpers<SaveContactParams>(defaultParams)

	React.useEffect(() => {
		if (onChange) {
			onChange(_.cloneDeep(params))
		}
	}, [params])

	return (
		<>
			<div>
				{referenceID ? (
					<Input
						disabled={disabled || busy}
						readOnly={true}
						defaultValue={referenceID}
						hint={t("common.hints.referenceID")}
						label={t("common.labels.referenceID")}
						name="referenceID"
						error={_.get(errors, "referenceID")}
						onChange={inputHandler("referenceID")}
						type="text"
					/>
				) : null}
				<Input
					autoComplete="off"
					disabled={disabled || busy}
					autoFocus={params.id === ""}
					defaultValue={params.firstName}
					label={t("contact.labels.firstName")}
					name="firstName"
					error={_.get(errors, "firstName")}
					onChange={inputHandler("firstName")}
					type="text"
				/>
				<Input
					autoComplete="off"
					disabled={disabled || busy}
					defaultValue={params.lastName}
					label={t("contact.labels.lastName")}
					name="lastName"
					error={_.get(errors, "lastName")}
					onChange={inputHandler("lastName")}
					type="text"
				/>
				<Input
					autoComplete="off"
					disabled={disabled || busy}
					defaultValue={params.title}
					label={t("contact.labels.title")}
					name="title"
					error={_.get(errors, "title")}
					onChange={inputHandler("title")}
					type="text"
				/>
			</div>
			<div>
				<EmailsForm
					disabled={disabled || busy}
					errorsKey="emails"
					errors={errors}
					defaultParams={_.get(params, "emails", [])}
					onChange={valueHandler("emails")}
				/>
			</div>
			<div>
				<PhoneNumbersForm
					disabled={disabled || busy}
					errorsKey="phoneNumbers"
					errors={errors}
					defaultParams={_.get(params, "phoneNumbers", [])}
					onChange={valueHandler("phoneNumbers")}
				/>
			</div>
		</>
	)
}
