import _ from "lodash"

import { rpc, Batch, Cache, compactIDs } from "@app/api"

import type { Result } from "@app/api"
import type { User, Pagination, Entity } from "@app/domain"

export type SaveUserParams = {
	id?: string

	defaultTenantID?: number
	disabled: boolean
	email: string
	firstName: string
	lastName: string
	tenantIDs: number[]
	timezone: string

	permissions: string[]
}

export const newSaveUserParams = (user?: User): SaveUserParams => {
	return {
		id: _.get(user, "id", ""),

		defaultTenantID: _.get(user, "defaultTenantID"),
		disabled: _.get(user, "disabled", false),
		email: _.get(user, "email", ""),
		firstName: _.get(user, "firstName", ""),
		lastName: _.get(user, "lastName", ""),
		tenantIDs: _.get(user, "tenantIDs", []),
		timezone: _.get(user, "timezone", ""),

		permissions: _.get(user, "permissions", []),
	}
}

export type SaveUserResult = {
	users: User[]
}

export const SaveUser = async (params: SaveUserParams): Promise<Result<SaveUserResult>> => {
	const data = await rpc("SaveUser", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveUserResult>
}

export const NewPIN = async (params: {
	id: string
	pin: string
}): Promise<Result<SaveUserResult>> => {
	const data = await rpc("NewPIN", params, { minDuration: 300 })
	Cache.scan(data)
	return data as Result<SaveUserResult>
}

export type GetUsersParams = {
	ids?: string[]
	includeIDs?: string[]

	query?: string
	withoutCount?: boolean

	limit?: number
	offset?: number
}

export type GetUsersResult = {
	users: User[]
	pagination?: Pagination
}

export const GetUsers = async (params: GetUsersParams): Promise<Result<GetUsersResult>> => {
	const ids = _.get(params, "ids", [])
	if (_.size(_.keys(params)) === 1 && _.size(ids) === 1) {
		const id = _.first(ids)
		return Batch("GetUsers", "users", id).then((user?: Entity) => {
			return {
				ok: true,
				result: { users: _.compact([user as User]) },
			} as Result<GetUsersResult>
		}) as Promise<Result<GetUsersResult>>
	}
	const data = await rpc("GetUsers", {
		...params,
		ids: compactIDs(params.ids),
		includeIDs: compactIDs(params.includeIDs),
	})
	Cache.scan(data)
	return data as Result<GetUsersResult>
}
