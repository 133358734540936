import React from "react"
import _ from "lodash"

import { Button, FormActions, Input, Select } from "@app/components"
import { useSession } from "@app/contexts"
import { SaveSettingParams } from "@app/api"
import { AlertLevel } from "@app/domain"
import { useFormHelpers } from "@app/hooks"

import En from "@app/translations/en.json"

interface ISettingFormProps {
	busy?: boolean
	defaultParams: SaveSettingParams
	disabled?: boolean
	errors: { [key: string]: string }
	onSave: (params: SaveSettingParams) => void
}

export const SettingForm: React.FC<ISettingFormProps> = (props) => {
	const { defaultParams, disabled = false, busy = false, onSave, errors } = props

	const { t, addNotification } = useSession()
	const { params, inputHandler, selectHandler } = useFormHelpers<SaveSettingParams>(defaultParams)

	React.useEffect(() => {
		const count = _.size(errors)
		if (count > 0) {
			addNotification({
				alertLevel: AlertLevel.Warn,
				title: t("setting.notifications.failure.saving", count),
				subtitle: t("common.errors.tryAgain"),
			})
		}
	}, [errors])

	const submitHandler = async (e: React.FormEvent) => {
		e.preventDefault()
		onSave(params)
		return false
	}

	const groupOptions = _.map(En.setting.groupOptions, (label, value) => {
		return {
			value,
			label,
		}
	})

	const defaultGroupOption = _.find(groupOptions, (opt) => {
		return opt.value === _.get(params, "group", "")
	})

	return (
		<form method="post" onSubmit={submitHandler} noValidate>
			<div className="flex flex-col sm:flex-row">
				<div className="sm:w-1/2 lg:w-1/3">
					<Select
						defaultValue={defaultGroupOption}
						disabled={disabled || busy}
						isSearchable={true}
						label={t("setting.labels.group")}
						name="group"
						onChange={selectHandler("group")}
						options={groupOptions}
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						autoFocus={params.name === ""}
						defaultValue={params.name}
						label={t("setting.labels.name")}
						name="name"
						hint={t(`setting.hints.name.${params.group}`)}
						placeholder={t(`setting.placeholders.name.${params.group}`)}
						error={_.get(errors, "name")}
						onChange={inputHandler("name")}
						type="text"
					/>
					<Input
						autoComplete="off"
						disabled={disabled || busy}
						defaultValue={params.value}
						hint={t(`setting.hints.value.${params.group}`)}
						placeholder={t(`setting.placeholders.value.${params.group}`)}
						label={t("setting.labels.value")}
						name="value"
						error={_.get(errors, "value")}
						onChange={inputHandler("value")}
						type="text"
					/>
				</div>
			</div>
			<FormActions>
				<Button
					type="submit"
					label={t("setting.buttons.save")}
					errors={errors}
					disabled={disabled}
					busy={busy}
				/>
			</FormActions>
		</form>
	)
}
