import type { IconProp } from "@fortawesome/fontawesome-svg-core"

export enum AlertLevel {
	Danger,
	Info,
	Success,
	Warn,
}

export type Notification = {
	alertLevel: AlertLevel
	autoCloseTimeout: number
	created: number
	icon?: IconProp
	id: string
	subtitle?: string
	title: string
}
